import React, { useMemo, useCallback } from 'react';
import 'rxjs/add/operator/map';
import type {
	ActionMeta,
	OptionToFilter,
} from '@atlassian/jira-common-components-picker/src/model.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { filterOptionByLabelAndFilterValues } from '@atlassian/jira-issue-internal-field-select/src/common/custom-format/filter-option/index.tsx';
import SelectFieldView from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/index.tsx';
import type {
	ServerSuggestions,
	Option,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import type { JiraAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	stripUrlToIssueKey,
	getCurrentHostPattern,
} from '@atlassian/jira-issue-view-common-utils/src/strip-url-to-issue-key/index.tsx';
import { ISSUE_LINK_SEARCH } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { SelectItemType } from '../../issue-links-type/index.tsx';
import { fetchIssueSuggestions } from '../issue-link-server.tsx';
import messages from './messages.tsx';

export const filterIssueSuggestionByAppLink = (
	option: {
		data?: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			fullObject?: any;
			appLinkId?: string | undefined;
		};
	},
	remoteAppLinkId?: string,
) => {
	/* react-select will put the whole option in ['data'] property for filterOption()
	 * https://github.com/JedWatson/react-select/blob/397e95337f54074cf0705cb5b06ce8951be72f2a/packages/react-select/src/Select.js#L1309
	 */
	const isIssueSuggestionOption = Boolean(option.data && option.data.fullObject);
	if (isIssueSuggestionOption) {
		const issueAppLinkId = option.data?.appLinkId;
		return issueAppLinkId === remoteAppLinkId;
	}
	return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MultiSelectValueItemShape = any;

type Props = {
	issueKey: IssueKey;
	value: MultiSelectValueItemShape[];
	baseUrl: BaseUrl;
	remoteAppLink?: JiraAppLink;
	invalidMessage?: string;
	onChange: (arg1: MultiSelectValueItemShape[]) => void;
	debounceFetchSuggestionsTime?: number;
	issueLinkType: SelectItemType;
	portalElement?: HTMLElement | null | undefined;
};

export const IssueSearchSelect = ({
	value,
	issueKey,
	baseUrl,
	remoteAppLink,
	invalidMessage,
	onChange,
	debounceFetchSuggestionsTime = 0,
	issueLinkType,
	portalElement,
}: Props) => {
	const { formatMessage } = useIntl();

	const additionalAnalyticsAttributes = useMemo(
		() => ({
			isRemote: remoteAppLink !== undefined,
		}),
		[remoteAppLink],
	);

	/**
	 * To convert link pasted to Select search field (e.g. `https://jdog.jira-dev.com/browse/CS2-2`) to the issue key (e.g. `CS2-2`)
	 *
	 * To make Select field display value that matches the search we actually issued (`CS2-2`).
	 * This will have an effect of visually reducing a matching issue URL to just the issue key.
	 */
	const stripQueryValueToIssueKey = useCallback(
		(queryValue: string) => {
			const selectedAppLinkHost =
				remoteAppLink === undefined ? getCurrentHostPattern() : remoteAppLink.baseUrl;
			return stripUrlToIssueKey(selectedAppLinkHost, queryValue);
		},
		[remoteAppLink],
	);

	const filterIssueOptions = useCallback(
		(option: OptionToFilter, inputValue: string) =>
			// @ts-expect-error - TS2345 - Argument of type 'OptionToFilter' is not assignable to parameter of type '{ data?: { fullObject?: any; appLinkId?: string | undefined; } | undefined; }'.
			filterIssueSuggestionByAppLink(option, remoteAppLink?.id) &&
			filterOptionByLabelAndFilterValues(option, inputValue),
		[remoteAppLink?.id],
	);

	const handleOnChange = useCallback(
		(selectedValues: MultiSelectValueItemShape[], actionMeta?: ActionMeta<Option>): void => {
			if (!actionMeta || !actionMeta.action) {
				return;
			}

			onChange(selectedValues);
		},
		[onChange],
	);

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const handleOnDataRequest = useCallback((_: { isInitial: boolean }): void => {}, []);

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const handleOnDataLoaded = useCallback((_: { isInitial: boolean }): void => {}, []);

	const fetchSuggestions = useCallback(
		(query: string | IssueKey): Promise<ServerSuggestions> => {
			const heading: string = formatMessage(messages.openIssues);
			const noSuggestionsMessage: string = formatMessage(messages.noHistoryResults);

			// @ts-expect-error - TS2322 - Type 'Promise<{ heading: string; items: IssueLinkSuggestion[]; }[] | readonly [{ readonly items: readonly [{ readonly content: string; readonly isDisabled: true; readonly value: ""; }]; }]>' is not assignable to type 'Promise<ServerSuggestions>'.
			return fetchIssueSuggestions(baseUrl, issueKey, query, remoteAppLink)
				.map((items) => {
					if (items.length) {
						// items variable is of type IssueLinkSuggestions but must be coerced to become SelectValueShape
						// union of 2 types produces necessary overlap, excess types cause flow problems
						return [{ heading, items }];
					}

					return [
						{
							items: [
								{
									content: noSuggestionsMessage,
									isDisabled: true,
									value: '',
								},
							],
						},
					] as const;
				})
				.toPromise();
		},
		[baseUrl, formatMessage, issueKey, remoteAppLink],
	);

	return (
		<SelectFieldView
			ariaLabel={formatMessage(messages.searchAriaLabel, { linkType: issueLinkType.label })}
			fieldId={ISSUE_LINK_SEARCH}
			classNamePrefix="issue-links-search"
			value={value}
			fetchSuggestions={fetchSuggestions}
			debounceFetchSuggestionsTime={debounceFetchSuggestionsTime}
			onChangeMulti={handleOnChange}
			canCreateNewItem
			// @ts-expect-error - TS2322 - Type '(inputValue: string) => string' is not assignable to type '(arg1: string) => ReactElement<any, string | JSXElementConstructor<any>>'.
			formatCreateLabel={(inputValue) => `${inputValue} (${formatMessage(messages.exactKey)})`}
			placeholder={formatMessage(messages.searchPlaceholder)}
			invalidMessage={invalidMessage}
			changeQueryValue={stripQueryValueToIssueKey}
			isMulti
			autoFocus
			errorMessagePosition="bottom"
			onDataLoaded={handleOnDataLoaded}
			onDataRequest={handleOnDataRequest}
			inputId={ISSUE_LINK_SEARCH}
			additionalAnalyticsProps={additionalAnalyticsAttributes}
			filterOption={filterIssueOptions}
			portalElement={portalElement}
			spacing="default"
		/>
	);
};

export default IssueSearchSelect;
