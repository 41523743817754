import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Button = styled.button(
	{
		textAlign: 'left',
		color: token('color.text.subtlest', 'grey'),
		font: 'inherit',
		background: token('elevation.surface', 'white'),
		width: '100%',
		height: '40px',
		paddingLeft: token('space.250', '20px'),
		paddingRight: token('space.250', '20px'),
		paddingTop: token('space.150', '12px'),
		paddingBottom: token('space.150', '12px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${borderRadius}px`,
		boxSizing: 'border-box',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	() => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		border: `1px solid ${token('color.border.input', colors.N100)}`,
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Flex = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '14px',
	paddingTop: token('space.025', '2px'),
	letterSpacing: '-0.005em',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpinnerSpan = styled.span({
	paddingTop: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ServiceDeskSpinner = styled.div({
	textAlign: 'center',
});
