import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moreActionsButtonLabel: {
		id: 'issue-design-section.panel.heading.more-actions-dropdown-menu.more-actions-button-label',
		defaultMessage: 'More actions',
		description: 'Button to view more actions to manage the linked designs',
	},
	getDesignUpdatesButton: {
		id: 'issue-design-section.panel.heading.more-actions-dropdown-menu.get-design-updates-button',
		defaultMessage: 'Request design status updates',
		description: 'Button to request admin to enable receiving design updates',
	},
	getDesignUpdatesButtonForAdmin: {
		id: 'issue-design-section.panel.heading.more-actions-dropdown-menu.get-design-updates-button-for-admin',
		defaultMessage: 'Get design status updates',
		description: 'Button to enable receiving design updates',
	},
});
