import React, { useCallback, useMemo } from 'react';
import {
	useFragment,
	graphql,
	useMutation,
	useRelayEnvironment,
	type Environment,
	commitLocalUpdate,
} from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import { defaultTimeTrackingConfiguration } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import { AddModalWithAnalyticsAndIntl } from '@atlassian/jira-issue-view-activity/src/worklog/view/add-modal/index.tsx';
import type {
	OwnProps,
	StateProps,
} from '@atlassian/jira-issue-view-activity/src/worklog/view/add-modal/view.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { FormValues } from '@atlassian/jira-issue-view-common-types/src/log-time-modal-type.tsx';
import timeStringToSeconds from '@atlassian/jira-issue-view-common-utils/src/time-string/time-string-to-seconds/index.tsx';
import { useSoftRefreshCallbacks } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/use-connect-relay-field.tsx';
import { IssueViewTimeTrackingController } from '@atlassian/jira-issue-view-layout-time-tracking-utils/src/ui/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { mentionProviderSelector } from '@atlassian/jira-issue-view-services/src/mentions/mention-provider-selector.tsx';
import {
	addWorklogRequest,
	closeModal,
	updateTimeRemainingFailure,
	updateTimeRemainingSuccess,
} from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions.tsx';
import { loggedInUserTimeZoneSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector.tsx';
import { modalIsSavingSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector.tsx';
import { useDispatch } from '@atlassian/jira-react-redux/src/index.tsx';
import type {
	ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner$key,
	ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner$data,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner.graphql';
import type { ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation.graphql';

type LegacyProps = Pick<StateProps, 'timeZone' | 'mediaContext' | 'mentionProvider' | 'isSaving'>;

type AggValueShape = Pick<
	ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner$data,
	'originalEstimate' | 'timeSpent' | 'remainingEstimate'
>;

export type IssueViewTimeTrackingAddModalProps = {
	externalId: OwnProps['externalId'];
	activityProvider: OwnProps['activityProvider'];
	fragmentKey: ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner$key;
} & LegacyProps;

// Inner component exported for testing
export function IssueViewTimeTrackingAddModalInner(props: IssueViewTimeTrackingAddModalProps) {
	const data =
		useFragment<ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner$key>(
			graphql`
				fragment ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner on JiraTimeTrackingField {
					...ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController

					id
					fieldId
					type
					__id # Used in getCommitTimeTrackingLocally to update the field after a successful worklog addition
					__typename
					issue {
						id
					}
					originalEstimate {
						timeInSeconds
					}
					timeSpent {
						timeInSeconds
					}
					remainingEstimate {
						timeInSeconds
					}
					timeTrackingSettings {
						workingDaysPerWeek
						workingHoursPerDay
					}
				}
			`,
			props.fragmentKey,
		);

	const [commit, mutationIsSaving] =
		useMutation<ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation>(
			graphql`
				mutation ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation(
					$input: JiraUpdateTimeTrackingFieldInput!
				) @raw_response_type {
					jira {
						updateTimeTrackingField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
							success
							field {
								id
								remainingEstimate {
									timeInSeconds
								}
							}
						}
					}
				}
			`,
		);

	const env = useRelayEnvironment();
	const dispatch = useDispatch();

	const parseTime = useMemo(
		() =>
			timeStringToSeconds(
				data?.timeTrackingSettings?.workingDaysPerWeek ??
					defaultTimeTrackingConfiguration.daysPerWeek,
				data?.timeTrackingSettings?.workingHoursPerDay ??
					defaultTimeTrackingConfiguration.hoursPerDay,
			),
		[
			data?.timeTrackingSettings?.workingDaysPerWeek,
			data?.timeTrackingSettings?.workingHoursPerDay,
		],
	);

	const value = useMemo(
		() =>
			({
				originalEstimateSeconds: data?.originalEstimate?.timeInSeconds ?? undefined,
				timeSpentSeconds: data?.timeSpent?.timeInSeconds ?? undefined,
				remainingEstimateSeconds: data?.remainingEstimate?.timeInSeconds ?? undefined,
			}) satisfies TimeTrackingState,
		[data],
	);

	const softRefreshCallbacks = useSoftRefreshCallbacks<AggValueShape>({
		issueId: data?.issue?.id,
		fieldData: {
			fieldId: data?.fieldId,
			type: data.type,
			__typename: data.__typename,
		},
	});

	const onSubmit = useCallback(
		(values: FormValues, analyticsEvent: UIAnalyticsEvent) => {
			const { timeLogged, timeRemaining } = values || {};

			if (!timeLogged && timeRemaining) {
				const remainingEstimateInSeconds = parseTime(timeRemaining) || 0;
				const softRefreshValue = {
					originalEstimate: data.originalEstimate,
					timeSpent: data.timeSpent,
					remainingEstimate: {
						timeInSeconds: remainingEstimateInSeconds,
					},
				};
				softRefreshCallbacks.onSubmit(softRefreshValue);

				const handleFailure = () => {
					log.safeErrorWithoutCustomerData(
						'issue.activity.worklog.update-time-remaining',
						`Failed to update time remaining with input length: ${remainingEstimateInSeconds}`,
					);
					dispatch(updateTimeRemainingFailure());
					softRefreshCallbacks.onSubmitFailed();
				};

				commit({
					variables: {
						input: {
							id: data.id,
							remainingEstimate: {
								timeInSeconds: remainingEstimateInSeconds,
							},
						},
					},
					onCompleted: (res) => {
						if (res.jira?.updateTimeTrackingField?.success) {
							fireTrackAnalytics(analyticsEvent, {
								action: 'time-remaining-updated',
							});

							dispatch(updateTimeRemainingSuccess(parseTime(timeRemaining)));
							softRefreshCallbacks.onSubmitSucceeded(softRefreshValue);
						} else {
							handleFailure();
						}
					},
					onError: handleFailure,
					optimisticResponse: {
						jira: {
							updateTimeTrackingField: {
								success: true,
								field: {
									id: data.id,
									remainingEstimate: {
										timeInSeconds: remainingEstimateInSeconds,
									},
								},
							},
						},
					},
				});
			} else {
				dispatch(
					addWorklogRequest(values, analyticsEvent, getCommitTimeTrackingLocally(env, data.__id)),
				);
			}
		},
		[
			commit,
			data.__id,
			data.id,
			data.originalEstimate,
			data.timeSpent,
			dispatch,
			env,
			parseTime,
			softRefreshCallbacks,
		],
	);

	const onCancel = useCallback(() => {
		dispatch(closeModal());
	}, [dispatch]);

	// Combine the mutation loading state with the legacy Redux state for addWorklogRequest
	const isSaving = mutationIsSaving || props.isSaving;

	if (!data) {
		return null;
	}

	return (
		<IssueViewTimeTrackingController fragmentKey={data}>
			{({
				config,
				isClassicProject,
				isDone,
				shouldDisplayRollUpDataControl,
				rolledUpTimeTrackingValue,
			}) => (
				<AddModalWithAnalyticsAndIntl
					externalId={props.externalId}
					activityProvider={props.activityProvider}
					timeTrackingConfiguration={config}
					rolledUpTimeTrackingValue={rolledUpTimeTrackingValue}
					timeTrackingValue={value}
					isDone={isDone}
					isSaving={isSaving}
					shouldDisplayRollUpDataControl={shouldDisplayRollUpDataControl}
					isClassicProject={isClassicProject}
					onSubmit={onSubmit}
					onCancel={onCancel}
					// These fields are provided by mapStateToPropsFactory
					// Ideally they should come from Relay or shared hooks/context
					timeZone={props.timeZone}
					mediaContext={props.mediaContext}
					mentionProvider={props.mentionProvider}
				/>
			)}
		</IssueViewTimeTrackingController>
	);
}

type LocalCommitInput = {
	originalEstimateSeconds?: number | undefined;
	timeSpentSeconds?: number | undefined;
	remainingEstimateSeconds?: number | undefined;
};

// Function that we pass into the legacy Redux action, to have it locally update the time tracking field after a successful worklog addition
function getCommitTimeTrackingLocally(environment: Environment, timeTrackingFieldCacheId: string) {
	return (data: LocalCommitInput) =>
		commitLocalUpdate(environment, (store) => {
			const field = store.get(timeTrackingFieldCacheId);

			if (!field) {
				return;
			}

			if (data.originalEstimateSeconds !== null) {
				field
					.getLinkedRecord('originalEstimate')
					?.setValue(data.originalEstimateSeconds, 'timeInSeconds');
			}

			if (data.timeSpentSeconds !== null) {
				field.getLinkedRecord('timeSpent')?.setValue(data.timeSpentSeconds, 'timeInSeconds');
			}

			if (data.remainingEstimateSeconds !== null) {
				field
					.getLinkedRecord('remainingEstimate')
					?.setValue(data.remainingEstimateSeconds, 'timeInSeconds');
			}
		});
}

// Map redux state for the legacy fields that can't be resolved with Relay or hooks
const mapStateToPropsFactory = () => {
	return (state: State) => ({
		timeZone: loggedInUserTimeZoneSelector(state),
		mediaContext: mediaContextSelector(state),
		mentionProvider: mentionProviderSelector(state),
		isSaving: modalIsSavingSelector(state), // Used for the legacy addWorklogRequest
	});
};

export const IssueViewTimeTrackingAddModal = connect(
	mapStateToPropsFactory,
	{},
)(IssueViewTimeTrackingAddModalInner);
