import type { MessageDescriptor } from 'react-intl-next';

import type { EditorAppearance } from '@atlaskit/editor-common/types';
import type { Fragment } from '@atlaskit/editor-prosemirror/model';
import { type usePublish } from '@atlaskit/rovo-triggers';
import type { PromptEditorProps } from '@atlassian/generative-ai-modal/screens/UserInputCommandPalette';
import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import type { EditorPluginAIConfigItemMarkdown } from './config-items/config-items';
import type { Recommendation } from './pm-plugins/ai-proactive/api';
import { type ProactiveAIDocumentSGCheckerConfig } from './pm-plugins/ai-spelling-grammar/document-sg-checker';
import { type PromptRequestOptions } from './provider/prompt-requests/types';

/**
 * WARNING - consider removal or renames of these events breaking changes - as
 * they are now surfaced in the prebuilt
 */
export const EDITOR_PLUGIN_AI_PROVIDER_EVENTS = {
	/**
	 * @private
	 * @deprecated
	 * This could change within the next few weeks, as we are looking to rework
	 * the logic to better capture feedback collection when those requirements
	 * become clearer. The change is likely to be an (additive) breaking API
	 * change, in the form of a more specific, feedback-focused type on the
	 * provider with information about success/failure.
	 *
	 * When a thumbs up button is clicked
	 */
	THUMBS_UP: 'THUMBS_UP',
	/**
	 * @private
	 * @deprecated
	 * This could change within the next few weeks, as we are looking to rework
	 * the logic to better capture feedback collection when those requirements
	 * become clearer. The change is likely to be an (additive) breaking API
	 * change, in the form of a more specific, feedback-focused type on the
	 * provider with information about success/failure.
	 *
	 * When a thumbs down button is clicked
	 */
	THUMBS_DOWN: 'THUMBS_DOWN',

	/**
	 * Please check with Ethan/PM upon removing onExperienceEvent
	 * calls, to confirm it is no longer used in any ongoing experiments.
	 * Specifically `REVIEW_STATE_ENTERED` && `EXPERIENCE_COMPLETE`
	 */
	/**
	 * When a user enters ANY review state (initial/interrogate)
	 */
	REVIEW_STATE_ENTERED: 'REVIEW_STATE_ENTERED',
	/**
	 * When the experience is complete (e.g. clicked an action in the preview
	 * screen leading to insertion of ai content)
	 */
	EXPERIENCE_COMPLETE: 'EXPERIENCE_COMPLETE',
	/**
	 * Experimenting with prompt_with_user_input in the event we trigger a tour
	 * from deeper inside(?)
	 *
	 * At current stage, we may only have a single tour onload, and this may
	 * be unnecessary - but adding it in as flexible, and we can deprecate/leave
	 * unused if we don't need it.
	 *
	 * 2023.0530 - leaving this out for now, as we don't have a use case for it,
	 * and can add it with quick turnaround if it is needed.
	 */
	// PROMPT_WITH_USER_INPUT: 'PROMPT_WITH_USER_INPUT',
	/**
	 * Not surfacing a "STARTED_STATE" esque prompt, as we don't have a use case
	 * right now (triggering a tour from STARTED_STATE is not the right spot, as
	 * it could shortcut straight into loading from a summarise use case.)
	 */
	// STARTED_STATE: 'STARTED_STATE',
	// EXPERIENCE_START: 'EXPERIENCE_START',
	// EXPERIENCE_END: 'EXPERIENCE_END',
};
type OnExperienceEvents = typeof EDITOR_PLUGIN_AI_PROVIDER_EVENTS;

export type SelectionToolbarDropdownOption = {
	label: MessageDescriptor;
	configItem: EditorPluginAIConfigItemMarkdown<'range'>;
};

export type SelectionToolbarDropdownConfig = {
	id: string;
	label: MessageDescriptor;
	options: SelectionToolbarDropdownOption[];
	beta?: boolean;
};

export type SelectionToolbarPromptButton = {
	id: string;
	tooltip?: MessageDescriptor;
	configItem: EditorPluginAIConfigItemMarkdown<'range'>;
};

export type SelectionToolbarDropdownItem = {
	label: MessageDescriptor;
	icon?: () => React.ReactElement;
	configItem: EditorPluginAIConfigItemMarkdown<'range' | 'empty'>;
	beta?: boolean;
};

// EDF-1840
// Not used at the moment, but could be used in the future to signify
// users interacting with generic AI.
// type ConvoAIProvider = {
// 	type: 'convo-ai';
// };

type RovoAIProvider = {
	type: 'rovo';
	agent: Pick<EditorAgent, 'id' | 'name' | 'icon' | 'identityAccountId'>;
};

type AIProvider = RovoAIProvider;

export type AIGlobalOptIn = {
	/**
	 * Current status of editorPluginAI can be
	 * - 'enabled': AI features should be enabled
	 * - 'disabled-opt-in': AI features should show all entry points but when used, they should trigger an opt-in flow
	 * - 'disabled': AI features/ entry points should not be presented/available to users
	 */
	status: 'enabled' | 'disabled-opt-in' | 'disabled';
	/**
	 * Callback that triggers opt in flow when status is 'disabled-opt-in'
	 */
	triggerOptInFlow: () => void;
};

export type AiUsageDisclaimer = {
	text: MessageDescriptor;
	tooltip: MessageDescriptor;
	url?: string;
};

export type ProactiveAIConfig = {
	/**
	 * Determines whether the Proactive AI experience should be enabled or not.
	 * Will require proactiveAIApiUrl to be enabled.
	 */
	enabled: boolean;
	/**
	 * Endpoint for interacting with the proactive AI API
	 * Will default to fallback endpoint defined by the AI plugin
	 */
	apiUrl: string;

	/**
	 * The default state of whether proactive ai is toggled on/off
	 */
	defaultToggledState?: boolean;

	/**
	 * Provides debounced time and debounced maxWait for current chunks.
	 * And throttled time for non current chunks.
	 */
	timings: {
		currentChunks: number;
		currentChunksMaxWait: number;
		nonCurrentChunks: number;
	};

	/**
	 * Configuration for whole document S+G checker.
	 */
	documentSGChecker?: ProactiveAIDocumentSGCheckerConfig;
};

/**
 * Provide contextual information to control hashes of channelIDs for use with
 * assistance-service.
 */
export type ChannelVariableContext = {
	'rovo-agent': {
		cloudId: string;
		userId: string;
		contentURL: string;
	};
};

export type ActionSideEffects = { [action: string]: (args?: any) => void };
export type ActionOverrides = { [action: string]: (args?: any) => void };

export type EditorPluginAIProvider = {
	// Range experiences describe experiences that are linked to an ai experience range entry point
	// (ie. the ai experiences button in the comment toolbar)
	rangeConfig: {
		/**
		 * This config item is used as the default ai experience for range
		 * selections.
		 * A user can end up on it via
		 * - starting the base range config item (ie. via highlight toolbar)
		 * - selecting another range config item (can't think of example -- but like brainstorm for empty) -- and then clearing that action.
		 */
		// We'll need to add this suport in an upcoming IC where range selections will have a "free form" input
		baseGenerate: EditorPluginAIConfigItemMarkdown<'range'>;
		getSuggestions: ({}: { inputValue?: string }) => EditorPluginAIConfigItemMarkdown<'range'>[];
		getSelectionToolbarDropdowns?: () => SelectionToolbarDropdownConfig[];
	};

	// Empty experiences describe experiences that are linked to a slash command or quick insert
	// Right now -- when starting an empty experience from the toobar quick insert with a selection
	// There is unexpected behaviour where the selection gets the ai brand treatment.
	// We probably want to start the ai experience linked to a single point. (at the end of the
	// selection?)
	//
	emptyConfig: {
		/*
		 * This config item is used as the default ai experience for empty
		 * selections.
		 *
		 * A user can end up on it via
		 * - starting the empty config item (ie. via quick insert or slash command)
		 * - starting another empty generate action, and clearing the selection
		 */
		baseGenerate: EditorPluginAIConfigItemMarkdown<'empty'>;
		// Note -- at some stage -- we may want to filter suggestions that show up in the quick insert
		// this may make sense to add at the config item level rather than in the getSuggestion
		// EditorPluginAIConfigItemMarkdown?
		getSuggestions: ({}: { inputValue?: string }) => EditorPluginAIConfigItemMarkdown[];
		/**
		 * When disableQuickInsert is not enabled, the quick insert list will contain the
		 * `baseGenerate` config item and the config items returned from `getSuggestions({})`.
		 */
		disableQuickInsert?: boolean;
	};

	/**
	 * **Single endpoint** for interacting with the v2/intent schema based generative AI API
	 *
	 * Documented at
	 * https://hello.atlassian.net/wiki/spaces/CA3/pages/2631558196/Spec+Platformized+API+spec+for+generative+AI+endpoint
	 *
	 * @example
	 * ```ts
	 * const endpoint = `${window.location.origin}/gateway/api/editor-ai/v2/generative/ai`
	 * ```
	 */
	generativeAIApiUrl: string;

	/**
	 * This is a high-level toggle which product can use to define whether or not they allow Proactive AI features to
	 * be enabled on their platforms.
	 */
	allowProactiveAIFeatures?: boolean;

	/**
	 * Provides configuration options for Proactive AI.
	 */
	proactiveAIConfig: ProactiveAIConfig;

	/**
	 * **Required field for v2 generative AI API**
	 *
	 * Documented at
	 * https://hello.atlassian.net/wiki/spaces/CA3/pages/2749958432/Open+question+for+Option+4
	 *
	 */
	product:
		| 'CONFLUENCE'
		| 'ATLAS'
		| 'BITBUCKET'
		| 'TRELLO'
		| 'JSW'
		| 'JWM'
		| 'JSM'
		| 'JPD'
		| 'ELEVATE'
		| 'MERCURY'
		| 'CALIBER'
		/**
		 * Generic string here to allow for products to insert their own via the
		 * generic prebuilt option. Note that the product **must** be whitelisted
		 * in the BE for this to work.
		 */
		| string;

	/**
	 * Because we can't infer the editor appearance in parts of the AI plugin,
	 * we will turn this on for any prebuilts that want to opt-in to
	 * experiments for full page editors.
	 */
	isFullPageExperimentsEnabled?: boolean;

	/**
	 * Method to grab custom headers to be passed when making api requests via fetch.
	 * Receives the params fetch has been called with and should return a map of custom
	 * headers which will be spread onto the request.
	 *
	 * ie.
	 * ```ts
	 * getFetchCustomHeaders: () => ({
	 *   'Magic-header': getMagicHeaderFromExternalStore()
	 * })
	 * ```
	 */
	// Provided as function based on patterns seen in common data frameworks,
	// generally they provide it in case you are providing something like an auth
	// token via a custom header, and the auth token can get changed after setting
	// up the data framework.
	// Our initial need is only expected to be static
	// (we are adding it to help debug/support accessing the apis from atlas).
	getFetchCustomHeaders?: (input: RequestInfo, init?: RequestInit | undefined) => HeadersInit;

	/**
	 * Function to return contextual information to be used when generating
	 * channelIDs for assistance-service.
	 *
	 * Receives a purpose arg to indicate what context it is expecting, so
	 * product can return contextual information accordingly.
	 */
	getChannelVariables?: <T extends keyof ChannelVariableContext>(
		purpose: T,
	) => ChannelVariableContext[T] | null;

	/**
	 * For Rovo agents only at the moment.
	 * Side effects that products can pass through to be performed
	 * with certain actions. For example, Jira, in a specific editor, may
	 * pass through:
	 *
	 * ```
	 * { continueInChat: () => minimizeIssueModal() }
	 * ```
	 *
	 * This would be executed alongside the continueInChat action. (For now,
	 * these are manually wired up to where they might need to occur in editor
	 * AI code).
	 */
	actionSideEffects?: ActionSideEffects;

	/**
	 * For Rovo agents only at the moment.
	 * Overrides that products can pass through to be performed
	 * instead of an actions normal behaviour. For example, Jira, in a
	 * specific editor, may pass through:
	 *
	 * ```
	 * { continueInChat: () => openRovoNewTab() }
	 * ```
	 *
	 * This would be executed instead of the continueInChat action. (For now,
	 * these are manually wired up to where they might need to occur in editor
	 * AI code).
	 */
	actionOverrides?: ActionOverrides;

	/**
	 * Callback to handle product specific actions after an assistance-service
	 * agent has performed actions on the Editor document.
	 *
	 * @argument agent EditorAgent The agent which performed this action.
	 */
	onDocChangeByAgent?: (agent: EditorAgent) => void;

	/**
	 * Callback to allow Editor AI implementors to determine when a user has
	 * started interacting with an AI provider.
	 *
	 * @argument source 'command-palette' | 'rovo-chat' The source of the change
	 * @argument provider AIProvider The provider being interacted with
	 */
	onAIProviderChanged?: (source: 'command-palette' | 'rovo-chat', provider?: AIProvider) => void;

	/**
	 * @experimental ** EXPERIMENTAL! **
	 *
	 * A list of callbacks which are fired upon those triggers/lifecycle events
	 * inside of editor-plugin-ai.
	 *
	 * Keeping it as a simple list for now without a complex event/messaging
	 * system.
	 *
	 * @private
	 * @deprecated
	 *
	 * We own and are managing the integrations.
	 * It is scoped as private as there's no risk of ecosystems making use of it.
	 *
	 * Please migrate to handleFeedbackSubmission.
	 */
	onExperienceEvent?: {
		[key in keyof OnExperienceEvents]: () => void;
	};

	/**
	 *
	 * This defines the handler for the feedback submission,
	 * which is triggered when a user clicks on the feedback button.
	 *
	 * This handler receives metadata relevant to the the feedback, AI experience, and editor attributes.
	 *
	 * The products create their implementation of this function which must return a promise,
	 * indicating whether the feedback has been handled or not.
	 *
	 * @param feedbackMetadata Metadata about the feedback submission
	 * @returns
	 * - `{ status: 'submitted' }` if the feedback submission was successful
	 * - `{ status: 'discarded' }` if the feedback submission was discarded
	 * - `{ status: 'failed' }` if the feedback submission failed
	 *
	 */
	handleFeedbackSubmission?: (
		feedbackMetadata: FeedbackMetadata,
	) => Promise<{ status: 'submitted' } | { status: 'failed' } | { status: 'discarded' }>;

	/**
	 * This prevents the AI selection toolbar entry point from being rendered.
	 * The toolbar is currently only available in Confluence full page editor,
	 * but this option is available for future use cases where the toolbar may
	 * be rendered in other products.
	 * @default false
	 */
	disableAISelectionToolbar?: boolean;

	/**
	 * This option allows the product to hide interrogation flow on the preview screen.
	 */
	disableInterrogation?: boolean;

	/**
	 * This allows product consumers to wrap the primary toolbar AI button with their
	 * own custom component. This is useful for products like Trello that need to wrap the button
	 * in SpotlightTarget, SpotlightPulse or other changeboarding components.
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	AIButtonWrapper?: React.FC<{ children: React.ReactNode }>;

	/**
	 * This allows product consumers to wrap the primary toolbar AI S+G button with their
	 * own custom component
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	proactiveAIToolbarButtonWrapper?: React.FC<{ children: React.ReactNode }>;

	/**
	 * Products can create PromptEditor using creators exported by @atlassian/editor-ai-injected-editors.
	 * And they pass it to editor-plugin-ai thorugh this prop.
	 * It will be then passed to @atlassian/generative-ai-modal,
	 *  which will use to render Editor as prompt input.
	 */
	PromptEditor?: (props: PromptEditorProps) => JSX.Element;

	/**
	 * Let products override the default AI disclaimer text/link on the Preview/Response screen footer.
	 */
	aiUsageDisclaimer?: AiUsageDisclaimer;

	/**
	 * If this is set to true, the Rovo Agents will be enabled for the product.
	 *
	 * The default value depends on the product.
	 */
	isRovoEnabled?: boolean;
};

export type ProactiveFeedbackMetadata = {
	sentiment: 'good-recommendation' | 'bad-recommendation';
	getAIExperience?: (hasUserConsent?: boolean) => {
		/**
		 * originalParagraph, suggestionParagraph, recommendationId
		 * will only be available
		 * if hasUserConsent is true
		 */
		originalParagraph?: string;
		suggestedParagraph?: Fragment;
		recommendationId?: string;
	};
	editorAttributes: {
		product: EditorPluginAIProvider['product'];
	};
};

type SpellingGrammarFeedbackMetadata = {
	sentiment: 'good-suggestion' | 'bad-suggestion';
	getAIExperience?: (hasUserConsent?: boolean) => {
		/**
		 * originalText, suggestionText & selectedSentence
		 * will only be available
		 * if hasUserConsent is true
		 */
		originalText?: string;
		suggestionText?: string;
		selectedSentence?: string;
	};
	editorAttributes: {
		product: EditorPluginAIProvider['product'];
	};
};

type AIPanelFeedbackMetadata = {
	sentiment: 'unknown';
	getAIExperience?: (hasUserConsent?: boolean) => {
		hasAcceptableUseWarning?: boolean;
		/**
		 * markdownResponse will only be available
		 * if hasUserConsent is true
		 */
		markdownResponse?: string;
	};
	editorAttributes: {
		appearance: 'unknown';
		product: EditorPluginAIProvider['product'];
	};
};

export type PluginFeedbackMetadata = {
	sentiment: 'good' | 'bad';
	getAIExperience?: (hasUserConsent?: boolean) => {
		hasAcceptableUseWarning?: boolean;
		configItemTitle: string;
		lastTriggeredFrom?: string;
		/**
		 * userPrompt and markdownResponse will only be available
		 * if hasUserConsent is true
		 */
		userPrompt?: string;
		markdownResponse?: string;
		inputOutputDiffRatio?: string;
	};
	editorAttributes: {
		appearance: EditorAppearance;
		product: EditorPluginAIProvider['product'];
	};
};

/**
 * The `sentiment` parameter is being extracted to
 * avoid double typing in analyticsFlowTypes, specifically
 * userFeedbackSentiment
 */
export type FeedbackSentiment =
	| PluginFeedbackMetadata['sentiment']
	| AIPanelFeedbackMetadata['sentiment']
	| SpellingGrammarFeedbackMetadata['sentiment']
	| ProactiveFeedbackMetadata['sentiment'];

export type FeedbackMetadata =
	| PluginFeedbackMetadata
	| AIPanelFeedbackMetadata
	| SpellingGrammarFeedbackMetadata
	| ProactiveFeedbackMetadata;

/**
 *
 * Assuming a response shape of `{ text: string }`
 *
 * @example
 * ```json
 * {
 *    "useCase": "summary",
 *    "state": "loading",
 *    "partial": { "text": "The 2 faste" }
 * }
 * ```
 * @example
 * ```json
 * {
 *    "useCase": "summary",
 *    "state": "loaded",
 *    "partial": { "text": "The 2 fastest" }
 * }
 * ```
 * @example
 * ```json
 * {
 *    "useCase": "summary",
 *    "state": "failed",
 *    "reason": "network"
 * }
 * ```
 */
// Note -- the expectation is this type and the related StreamingResponseEntry
// will be collapsed when we migrate to a single endpoint for all use cases.
export type EditorPluginAIPromptResponseMarkdown =
	| { state: 'loading'; data: EditorPluginAIPromptResponseShapeMarkdown }
	| { state: 'loaded'; name?: string; data: EditorPluginAIPromptResponseShapeMarkdown }
	| { state: 'aup-violation'; statusCode: 451 }
	| {
			state: 'failed';
			reason: 'response-too-similar';
			statusCode: 208;
			data: { meta?: EditorPluginAIPromptResponseMeta };
	  }
	| { state: 'failed'; reason: 'free-generate-disabled'; statusCode: 9001 }
	| {
			state: 'failed';
			reason: 'rate-limited';
			retryAfter: number;
			statusCode?: number;
	  }
	| {
			state: 'failed';
			reason: 'backend-input-guard';
			guard: 'INPUT_EXCEEDS_TOKEN_LIMIT';
			statusCode?: number;
	  }
	| {
			state: 'failed';
			reason: 'backend-input-guard';
			guard: 'INPUT_TOO_SHORT_TO_SUMMARIZE' | 'INPUT_TOO_SHORT_TO_PROCESS';
			statusCode?: number;
			error?: string;
	  }
	| {
			state: 'failed';
			reason: 'network' | 'backend' | 'aborted' | 'parsing';
			statusCode?: number;
			error?: string;
	  }
	| {
			state: 'failed';
			apiName: 'assistance-service';
			guard: string;
			statusCode?: number;
			error?: string;
			retryAfter?: number;
	  }
	| {
			state: 'failed';
			apiName: 'assistance-service';
			guard: 'UNHANDLED_ERROR';
			statusCode?: number;
			error?: string;
	  };

export type EditorPluginAIPromptResponseMeta = {
	inputOutputDiffRatio: string;
	loadingStatus?: string;
};

export type ConvoAIResponseRovoAction = {
	key: string;
	invocationId: string;
	data: {
		suggestion: 'insert' | 'replace';
		content: string;
	};
};

export type EditorPluginAIPromptResponseShapeMarkdown = {
	type: 'markdown';
	content: string;
	meta?: EditorPluginAIPromptResponseMeta;
	rovoActions?: ConvoAIResponseRovoAction[];
};

export type StreamParsed = AsyncGenerator<EditorPluginAIPromptResponseMarkdown>;

// Oppurtunity for clean up here is to adjust what this takes to receive
// a shared type from the AIProvider which contains;
// - generativeAIApiUrl
// - getFetchCustomHeaders
// so that we aren't retyping things as they are passed through.
export type EditorPluginAIPromptRequestMarkdown = (
	requestOptions: PromptRequestOptions,
) => StreamParsed;

export type ConvoAIKnownMessageTemplate =
	| 'ANALYSING_QUERY'
	| 'CONTENT_SEARCH'
	| 'PAGE_HYDRATION'
	| 'NEXT_BEST_TASK'
	| 'WRITING';

export function isConvoAIKnownMessageTemplate(type: string): type is ConvoAIKnownMessageTemplate {
	return [
		'ANALYSING_QUERY',
		'CONTENT_SEARCH',
		'PAGE_HYDRATION',
		'NEXT_BEST_TASK',
		'WRITING',
	].includes(type);
}

export type EndExperience = (options?: { preserveEditorSelectionOnComplete?: boolean }) => void;

export type RovoActions = Partial<
	Record<EditorPluginAIConfigItemMarkdown['selectionType'], ConvoAIResponseRovoAction['data']>
>;

export type RovoPublish = ReturnType<typeof usePublish>;

export type EditorPluginAISharedState = {
	isProactiveEnabled: boolean;
	isSpellingGrammarEnabled: boolean;
	spellingGrammarErrorCount: number;
	recommendations: Recommendation[];
	selectedRecommendationId?: string;
	hoveredRecommendationId?: string;
};
