import type { ReactNode } from 'react';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueViewSection } from '../../common/types/issue/index.tsx';

export type PolarisRoute = {
	section: string;
	resource?: string;
};

export type PolarisQueryValidValues = {
	[key: string]: (arg1: string) => boolean;
};

export const DEFAULT_ISSUE_VIEW_SECTION_SIDEBAR: IssueViewSection = 'overview';
export const DEFAULT_ISSUE_VIEW_SECTION_FULLSCREEN: IssueViewSection = 'comments';

export const POLARIS_QUERY_ISSUE_VIEW_SIDEBAR = 'sidebar' as const;
export const POLARIS_QUERY_ISSUE_VIEW_SECTIONS: Record<IssueViewSection, boolean> = [
	'overview',
	'capture',
	'comments',
	'deliver',
	'history',
	'connections',
].reduce(
	(result, section) =>
		Object.assign(result, {
			[section]: true,
		}),
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	{} as Record<IssueViewSection, boolean>,
);

export const POLARIS_QUERY_VALID_VALUES: {
	[key: string]: (arg1: string) => boolean;
} = {
	selectedIssue: (value: string) => typeof value === 'string',
	issueViewLayout: (value: string) => value === POLARIS_QUERY_ISSUE_VIEW_SIDEBAR,
	// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'IssueViewSection'.
	issueViewSection: (value: string) => POLARIS_QUERY_ISSUE_VIEW_SECTIONS[value],
	fullscreen: (value: string) => value === 'true',
	viewCommentId: (value: string) => typeof value === 'string',
	previewRole: (value: string) => typeof value === 'string',
	landingViewRank: (value: string) => typeof value === 'string',
	openIdeaCreationModal: (value: string) => value === 'true',
};

export const POLARIS_QUERY_PARAM_DROP_KEYS: string[] = ['landingViewRank'];

export type PolarisQueryParams = {
	selectedIssue?: IssueKey;
	issueViewLayout?: typeof POLARIS_QUERY_ISSUE_VIEW_SIDEBAR;
	issueViewSection?: IssueViewSection;
	fullscreen?: 'true';
	viewCommentId?: string;
	previewRole?: string;
	landingViewRank?: string;
	openIdeaCreationModal?: 'true';
};

export type PolarisQuery = Partial<PolarisQueryParams>;

export type PolarisQueryKeys = keyof PolarisQuery;

export type RouteToParams =
	| Partial<{
			clearParameters: boolean;
			replaceInHistory: boolean;
			saveStorageRouteSlug: boolean;
	  }>
	| undefined;

export type PolarisRouter = {
	routeTo: (arg1: PolarisRoute, arg2?: RouteToParams) => void;
	setQuery: (arg1: PolarisQuery, arg2?: 'push' | 'replace') => void;
};

export type ExternalProps = {
	children: ReactNode;
	allowedSections: string[];
	defaultSection: string;
	defaultResource?: string;
	getAppUrl: (route: PolarisRoute) => string;
	queryValidValues: PolarisQueryValidValues;
	storageKey: string | undefined;
};

export type Props = ExternalProps & {
	query: Record<string, string>;
	section: string | undefined;
	resource: string | undefined;
	useNativePushMethod: (state: string) => void | Promise<void>;
	useNativeReplaceMethod: (state: string) => void | Promise<void>;
};

export type State = {
	containerProps: Props | undefined;
	prevContainerProps?: Props;
	routeFromStorage?: PolarisRoute;
};
