import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cancelButton: {
		id: 'issue-add-design-form.design-form.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Cancel button to remove the design section from the issue',
	},
	linkButton: {
		id: 'issue-add-design-form.design-form.link-button',
		defaultMessage: 'Link',
		description: 'Button to link the pasted URL to the current Jira issue',
	},
	authenticationFailedFlagMessage: {
		id: 'issue-add-design-form.design-form.authentication-failed-flag-message',
		defaultMessage:
			'Something went wrong while connecting {providerCount, plural, one {{singleProvider}} other {design provider}} to Jira',
		description:
			'Contents of a flag shown to the user indicating that an issue occurred during an authentication flow',
	},
	unexpectedErrorTitle: {
		id: 'issue-add-design-form.design-form.unexpected-error-title',
		defaultMessage: 'Something went wrong',
		description:
			'Title of a flag shown to the user indicating that an unexpected issue occurred internally when a user tries to link a design',
	},
	unexpectedErrorDescription: {
		id: 'issue-add-design-form.design-form.unexpected-error-description',
		defaultMessage: 'Something went wrong and we couldn’t connect to {providerName}.',
		description:
			'Contents of a flag shown to the user indicating that an unexpected issue occurred internally when a user tries to link a design',
	},
	linkFailedGenericDescription: {
		id: 'issue-add-design-form.design-form.link-failed-generic-description',
		defaultMessage: 'Check your URL and permissions and try again.',
		description: 'Contents of a flag shown to linking design to issue failed',
	},
	tryAgain: {
		id: 'issue-add-design-form.design-form.try-again',
		defaultMessage: 'Try again',
		description: 'The button that will retry linking the design',
	},
});
