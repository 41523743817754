import React, { useEffect } from 'react';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import { ConfluenceFeatureKeys } from '@atlassian/jira-confluence-integration-controls/src/constants.tsx';
import { useIsCrossSellEnabled } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-is-cross-sell-enabled/index.tsx';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { JIRA_ISSUE_LINK_PAGE } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import { CreatePageMenuItem } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/create-page-menu-item/index.tsx';
import { CreateWhiteboardMenuItem } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/create-whiteboard-menu-item/index.tsx';
import { createConfluenceContentMenuEntrypoint } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/entrypoint.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export const CreateConfluenceContentMenu = () => {
	const hasConfluenceAccess = useUserHasConfluenceAccess(JIRA_ISSUE_LINK_PAGE).hasAccess;
	const {
		entryPointActions: confluenceContentMenuActions,
		entryPointReferenceSubject: confluenceContentMenuSubject,
	} = useEntryPoint(createConfluenceContentMenuEntrypoint, {});

	const tenantHasConfluence = useTenantHasConfluence();

	const isCrossSellEnabled = useIsCrossSellEnabled(
		ConfluenceFeatureKeys.CREATE_MENU_CONFLUENCE_CONTENT,
	);

	const [embedConfluenceConfig] = UNSAFE_noExposureExp(
		'platform_embed-conf_jira-issue_cross-join_exp',
	);

	const embedConfluenceCohort = embedConfluenceConfig.get('cohort', 'not-enrolled');

	const [confluenceContentCrossflowConfig, confluenceContentCrossflowFireExposureEvent] =
		UNSAFE_noExposureExp('jira_create_confluence_content_crossflow');

	const confluenceContentCrossflowCohort = confluenceContentCrossflowConfig.get(
		'cohort',
		'not-enrolled',
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	// Embedded Confluence cross-join experiment begins
	useEffect(() => {
		if (!tenantHasConfluence) {
			if (ff('platform.embedded-confluence.jira-issue.create-confluence-content-crossflow')) {
				confluenceContentCrossflowFireExposureEvent();
			}
		}
	}, [
		hasConfluenceAccess,
		tenantHasConfluence,
		createAnalyticsEvent,
		confluenceContentCrossflowFireExposureEvent,
	]);

	useEffect(() => {
		const shouldRenderForCrossJoin =
			tenantHasConfluence &&
			ff('platform_embed-conf_jira-issue_create_cross-join') &&
			embedConfluenceCohort === 'experiment';
		const shouldRenderForCrossFlow =
			!tenantHasConfluence &&
			ff('platform.embedded-confluence.jira-issue.create-confluence-content-crossflow') &&
			confluenceContentCrossflowCohort === 'experiment';

		if (
			hasConfluenceAccess ||
			((shouldRenderForCrossJoin || shouldRenderForCrossFlow) && isCrossSellEnabled)
		) {
			confluenceContentMenuActions.load();
		}
	}, [
		hasConfluenceAccess,
		confluenceContentMenuActions,
		tenantHasConfluence,
		isCrossSellEnabled,
		embedConfluenceCohort,
		confluenceContentCrossflowCohort,
	]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={confluenceContentMenuSubject}
			id="confluenceContentMenuEntryPointContainer"
			packageName="@atlassian/jira-issue-create-confluence-content"
			teamName="confluence-better-together"
			runtimeProps={{
				appearance: 'link',
				children: (
					<>
						<CreatePageMenuItem />
						<CreateWhiteboardMenuItem />
					</>
				),
				hasConfluenceAccess,
				cacheOnlyAccessStatus: true,
				embeddedConfluenceSource: JIRA_ISSUE_LINK_PAGE,
			}}
		/>
	);
};
