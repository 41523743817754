import React, { useContext, type ReactNode, type ComponentType } from 'react';
import { xcss, Box } from '@atlaskit/primitives';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { FieldsCompactModeContext } from '@atlassian/jira-issue-view-layout-templates-compact-mode-provider/src/index.tsx';
import { StyledFieldHeading } from './styled.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	FieldHeadingTitle,
	FIELD_HEADING_TITLE_COMPONENT_SELECTOR,
	FieldHeadingIcons,
	FieldHeadingIconsContainer,
	FieldWrapper,
	FIELD_WRAPPER_COMPONENT_SELECTOR,
	SideBySideField,
	labelColumnWidthForGrouping as labelColumnWidth,
	BlankFieldHeading,
	MultilineFieldHeading,
	MultilineFieldHeadingTitle,
	MULTILINE_FIELD_HEADING_TITLE_COMPONENT_SELECTOR,
	DevPanelIconContainer,
	fieldHeadingSideBySideCss,
	FieldHeadingSideBySide,
	animationFadeIn,
	animationFadeOut,
	fieldHeadingIconsCss,
	FieldHeadingIconsVisibility,
	CustomFieldHeadingWithBottomMargin,
} from './styled';

const FieldHeadingContainer = ({
	fieldId,
	isCompact,
	children,
}: {
	fieldId: string;
	isCompact: boolean;
	children: ReactNode;
}) => {
	return (
		<Box
			xcss={[fieldHeadingContainerStyles, !isCompact && expandedStyles]}
			testId={`issue-field-heading-styled-field-heading.${fieldId}`}
		>
			<Box xcss={fieldHeadingContainerInnerStyles}>{children}</Box>
		</Box>
	);
};

const FieldHeadingContainerGate = componentWithFG(
	'issue_view_field_config_edit',
	FieldHeadingContainer,
	StyledFieldHeading,
);

type FieldHeadingProps = {
	fieldId?: string;
	children: ReactNode;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
};

export const FieldHeading: ComponentType<FieldHeadingProps> = ({
	fieldId = 'field',
	children,
	...rest
}: FieldHeadingProps) => {
	const isCompact = useContext(FieldsCompactModeContext);

	return (
		<FieldHeadingContainerGate
			data-testid={`issue-field-heading-styled-field-heading.${fieldId}`}
			isCompact={isCompact}
			fieldId={fieldId}
			{...rest}
		>
			{children}
		</FieldHeadingContainerGate>
	);
};

const fieldHeadingContainerStyles = xcss({
	position: 'relative',
	flexGrow: 1,
	boxSizing: 'border-box',
	paddingRight: 'space.250',
	minWidth: '120px',
	width: '40%',
	lineHeight: 1,
});

const fieldHeadingContainerInnerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: 'space.100',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
	borderRadius: '4px',
	height: '100%',
	':hover': {
		backgroundColor: 'color.background.neutral.hovered',
	},
});

const expandedStyles = xcss({
	maxWidth: '170px',
});
