import uuid from 'uuid/v4';

import { ACTIONS } from './states';
import type { AIProactivePluginState, ProactiveAction } from './states';
import { markRecommendationViewed } from './utils';

export const reducer = (
	pluginState: AIProactivePluginState,
	action: ProactiveAction,
): AIProactivePluginState => {
	switch (action.type) {
		case ACTIONS.UPDATE_PLUGIN_STATE:
			const availableRecommendationIds = new Set<string>(
				(action.data?.proactiveAIBlocks ?? pluginState.proactiveAIBlocks).reduce<string[]>(
					(acc, block) => acc.concat(block.recommendations?.map((r) => r.id) ?? []),
					[],
				),
			);
			return {
				...pluginState,
				...action.data,
				canProactiveContextPanelOpen: availableRecommendationIds.size > 0,
				availableRecommendationIds,
			};
		case ACTIONS.TOGGLE_PROACTIVE_ENABLED:
			return {
				...pluginState,
				toggleCount: pluginState.toggleCount + 1,
				isProactiveEnabled: !pluginState.isProactiveEnabled,
				...(pluginState.isProactiveEnabled && {
					docChangeCountUntilLimit: 0,
				}),
			};
		case ACTIONS.TOGGLE_PROACTIVE_CONTEXT_PANEL:
			return {
				...pluginState,
				isProactiveContextPanelOpen: !pluginState.isProactiveContextPanelOpen,
				analyticsAIInteractionId: uuid(),
				toggleCount: pluginState.toggleCount + 1,
			};
		case ACTIONS.SELECT_PROACTIVE_RECOMMENDATION:
			return {
				...pluginState,
				selectedRecommendationId: action.data.selectedRecommendationId,
				proactiveAIBlocks: markRecommendationViewed(
					pluginState,
					action.data.selectedRecommendationId,
				),
				hoveredRecommendationId: !pluginState.allowInlineHoverHighlightWhileRecommendationSelected
					? undefined
					: pluginState.hoveredRecommendationId,
			};
		// XXX: Chunks are no longer purged we we don't need the code for disabling the checker for those chunks
		// However I'm going to leave this here just in case we need to bring it back
		// case ACTIONS.DISABLE_NEED_SPELLING_AND_GRAMMAR:
		// 	return {
		// 		...pluginState,
		// 		proactiveAIBlocks: pluginState.proactiveAIBlocks?.map((block) => {
		// 			if (action.data.skippedChunkIds.includes(block.id)) {
		// 				return {
		// 					...block,
		// 					invalidated: false,
		// 					invalidatedForDocChecker: false,
		// 				};
		// 			}
		// 			return block;
		// 		}),
		// 	};
		// XXX: The new endpoint doesn't supply any metadata.
		// case ACTIONS.UPDATE_CHUNK_METADATA:
		// 	return {
		// 		...pluginState,
		// 		proactiveAIBlocks: pluginState.proactiveAIBlocks?.map((block) => {
		// 			if (action.data.chunkId === block.id) {
		// 				return {
		// 					...block,
		//					metadata: action.data.metadata,
		// 				};
		// 			}
		// 			return block;
		// 		}),
		// 	};
		// case ACTIONS.START_PROACTIVE_AI:
		// 	return {
		// 		...pluginState,
		// 		isProactiveEnabled: true,
		// 		toggleCount: pluginState.isProactiveEnabled
		// 			? pluginState.toggleCount
		// 			: pluginState.toggleCount + 1,
		// 	};
		default:
			return pluginState;
	}
};
