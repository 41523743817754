import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltip: {
		id: 'issue-field-edit-icon.tooltip',
		defaultMessage: 'Edit field',
		description: 'Tooltip when hovering over the edit field icon',
	},
	label: {
		id: 'issue-field-edit-icon.label',
		defaultMessage: 'Edit',
		description: 'Edit icon label',
	},
});
