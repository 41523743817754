import React from 'react';

import { useIntl } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { Stack, Text } from '@atlaskit/primitives';

import messages from './messages';

const ImageEmpty = () => (
	<svg
		width="160"
		height="160"
		viewBox="0 0 160 160"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g id="Empty state">
			<g id="Group 2">
				<g id="Group 1">
					<g id="Vector">
						<path
							id="Vector_2"
							d="M33.4893 141.764C33.4893 141.764 96.5819 149.267 129.055 141.423V27.1708C129.055 27.1708 77.5615 39.7852 33.4893 26.4893C33.4893 26.4893 34.1844 142.282 33.4893 141.771V141.764Z"
							fill="#82B536"
						/>
						<path
							id="Vector_3"
							d="M47.0103 66.4453H115.541"
							stroke="white"
							strokeWidth="3.41072"
							strokeMiterlimit="10"
						/>
						<path
							id="Vector_4"
							d="M47.0103 83.2373H115.541"
							stroke="white"
							strokeWidth="3.41072"
							strokeMiterlimit="10"
						/>
						<path
							id="Vector_5"
							d="M47.0103 100.022H115.541"
							stroke="white"
							strokeWidth="3.41072"
							strokeMiterlimit="10"
						/>
						<path
							id="Vector_6"
							d="M47.1191 117.81H73.6974"
							stroke="white"
							strokeWidth="3.41072"
							strokeMiterlimit="10"
						/>
					</g>
					<path
						id="Vector_7"
						d="M102.921 122.923L134.82 89.8684L146.617 100.735L100.281 140.299L78.4678 120.306L89.8107 107.279L102.916 122.928L102.921 122.923Z"
						fill="#1868DB"
					/>
					<path
						id="Intersect"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M129.055 95.8423L102.921 122.923L102.916 122.927L89.8107 107.279L78.4678 120.306L100.281 140.299L129.055 115.73V95.8423Z"
						fill="#09326C"
					/>
					<g id="Gestural Line/scribble 2">
						<path
							id="Vector_8"
							d="M115.263 49.0782C103.519 51.211 94.8538 45.7536 82.9928 50.2304C82.4392 48.313 81.0077 45.9119 78.8651 45.8767C80.6521 45.9207 83.5105 50.9296 80.6386 47.5742C78.9506 45.0191 71.573 48.1415 77.2266 45.9207C73.324 46.3912 69.7454 49.5004 67.1077 52.2665C62.7189 56.2068 66.0498 48.5813 62.8764 44.7465C58.3796 39.4166 44.7316 53.278 47.4819 53.2868C49.2329 51.5497 51.5826 50.4679 52.5549 48.5417C69.6734 37.1386 57.3218 58.2429 67.5173 54.7072C84.1722 40.0498 75.7547 53.3175 85.5316 52.4116C91.2303 47.0992 115.461 55.4284 115.267 49.0782H115.263Z"
							fill="white"
						/>
					</g>
					<g id="Gestural Line/eyelash 3">
						<path
							id="Vector_9"
							d="M30.4365 12.6115C30.542 12.3181 28.7189 8.42724 28.4141 9.75182C28.2413 10.2171 28.3688 10.5717 28.8144 10.58C28.0469 10.919 29.3228 11.4035 29.1625 11.8472C28.5688 11.8685 30.0342 12.6666 29.3956 12.6108C29.9852 17.1942 37.799 24.6012 31.616 14.6462C31.6564 15.7933 31.1609 14.8368 31.1535 14.3185C32.0544 14.8163 30.8889 12.9621 30.5416 12.6563C30.725 13.2793 29.9194 12.3896 30.4351 12.6139L30.4365 12.6115Z"
							fill="#101214"
						/>
						<path
							id="Vector_10"
							d="M25.8275 22.4433C25.6371 21.8768 21.0633 20.526 20.6082 20.3239C19.555 20.0734 19.8389 21.4186 20.6801 21.4222C20.8431 21.4939 22.0593 21.5672 21.6539 21.9652C22.2038 23.0266 24.2392 22.6437 24.6192 23.5623C24.8408 23.3391 25.2445 23.5913 25.3994 23.4668C25.4452 23.7583 25.8669 23.8134 25.8827 23.7363C27.1906 24.6798 27.9473 22.8658 26.361 22.5981C26.4083 22.5328 26.0707 22.2709 25.8312 22.4423L25.8275 22.4433Z"
							fill="#101214"
						/>
						<path
							id="Vector_11"
							d="M22.4109 39.1057C23.2009 39.4469 22.5042 39.5969 22.1203 39.4264C22.5789 38.8036 21.216 39.1724 20.9086 39.4228C21.0578 39.4132 21.1464 39.6527 20.8405 39.69C20.9545 39.0279 17.0944 39.5449 19.1078 40.5445C19.173 40.5311 19.1261 40.0315 19.2267 40.1726C19.2128 40.9435 19.7359 40.3197 19.8972 40.234C19.9532 40.6239 20.0594 40.2351 20.1271 40.3891C19.9995 40.9476 20.8461 39.9071 20.6901 40.4651C21.6922 41.114 23.2719 39.772 24.0557 40.3841C24.3853 39.4315 27.4022 40.2698 26.0589 38.7791C24.7868 38.6033 23.7598 38.5563 22.4109 39.1057Z"
							fill="#101214"
						/>
						<path
							id="Vector_12"
							d="M13.7248 31.3833C13.6052 32.6361 16.2087 31.9331 17.0031 32.3055C17.9809 32.8637 27.6997 33.7402 24.9085 31.7807C21.9649 31.5402 19.5934 30.8287 15.9877 30.9882C15.4 30.9713 13.519 30.5558 13.7248 31.3833Z"
							fill="#101214"
						/>
						<path
							id="Vector_13"
							d="M40.2534 16.0188C41.3411 16.5415 40.2784 17.7349 41.1867 18.253C42.887 18.976 42.0015 9.99791 40.4614 10.5518C39.2016 11.2069 40.7334 15.2871 40.2534 16.0188Z"
							fill="#101214"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export const EmptyState = () => {
	const { formatMessage } = useIntl();

	return (
		<Stack space="space.200" alignInline="center">
			<ImageEmpty />
			<Stack space="space.150" alignInline="center">
				<Heading color="color.text" size="small">
					{formatMessage(messages.emptyStateTitle)}
				</Heading>
				<Text align="center" color="color.text" size="large">
					{formatMessage(messages.emptyStateDescription)}
				</Text>
			</Stack>
		</Stack>
	);
};
