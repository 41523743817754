import React, { useCallback, useEffect, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import Heading from '@atlaskit/heading';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JiraIssueRelatedIssuesContainer } from '@atlassian/jira-ai-related-issues/src/controllers/context.tsx';
import { SuggestRelatedIssuesModalContainer } from '@atlassian/jira-ai-related-issues/src/index.tsx';
import { FindSimilarIssuesButton } from '@atlassian/jira-ai-related-issues/src/ui/suggest-related-issues-button/index.tsx';
import iframeRedirect from '@atlassian/jira-common-navigation/src/iframe-redirect/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import {
	TASK_FAIL,
	TASK_SUCCESS,
} from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import {
	useIsAiEnabledForIssue,
	useIsJiraIssue,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { GIC_LIFECYCLE_EVENTS } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/constants.tsx';
import { useGICLifeCycleEvents } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/main.tsx';
import { AsyncDevOpsSummaryContainer } from '@atlassian/jira-issue-dev-ops-summary/src/ui/dev-ops-summary-container/async.tsx';
import { DevOpsSummaryContainer as SyncDevOpsSummaryContainer } from '@atlassian/jira-issue-dev-ops-summary/src/ui/dev-ops-summary-container/index.tsx';
import { ItemLineCardGroup } from '@atlassian/jira-issue-item-line-card/src/ui/index.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import { APPLINK_REQ_AUTH } from '@atlassian/jira-issue-view-common-constants/src/remote-link-error-type.tsx';
import type { LinkedIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';
import {
	isLeftClickOnly,
	isMiddleClick,
	isCmdOrCtrlClick,
} from '@atlassian/jira-issue-view-common-utils/src/events/index.tsx';
import { renderInlineMessage } from '@atlassian/jira-issue-view-common-views/src/issue-line-card/view/inline-message/index.tsx';
import {
	SectionHeading,
	SectionHeadingIcons,
	SectionHeadingTitle,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import {
	useSmartCardProductType,
	useSmartCardIsAIEnabled,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import {
	COMMAND_PALETTE_CREATE_LINKED_ISSUE,
	ISSUE_VIEW_CREATE_LINKED_ISSUE,
} from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { ISSUE_LINK_SEARCH } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	fireUIAnalytics,
	ContextualAnalyticsData,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { type IssueId, toHref } from '@atlassian/jira-shared-types/src/general.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import AddIssueLinkButton from './add/add-button/index.tsx';
import transformIssueLink from './add/create-linked-issue-button/transformer.tsx';
import IssueLinksAddView from './add/index.tsx';
import { IssueLinkLineCard } from './issue-links-line-card/index.tsx';
import messages from './messages.tsx';
import type { LinkedIssueType, IssueLink, Props, LinkedIssueAndLinkId } from './types.tsx';
import { getUserHasPermissionToLink } from './utils.tsx';

const loadedIssueLinksCount = (issueLinks: Record<string, unknown[]> = {}) =>
	issueLinks
		? Object.keys(issueLinks).reduce(
				(totalLinksCount: number, typeName: string) =>
					issueLinks[typeName].length + totalLinksCount,
				0,
			)
		: 0;

const getIssueIdsForDevSummaries = (issueLinks: IssueLink): IssueId[] =>
	Object.values(issueLinks).flatMap((issueLinksGroup) =>
		issueLinksGroup
			// The `@atlassian/jira-issue-dev-ops-summary` package used for fetching/rendering
			// dev summary info doesn't support multiple cloud IDs. So ignore remote links here.
			.filter((issueLink) => !issueLink.isRemote)
			.map((issueLink) => issueLink.linkedIssue.id),
	);

export const DevOpsSummaryContainer = componentWithFG(
	'change_dev_ops_summary_to_sync',
	SyncDevOpsSummaryContainer,
	AsyncDevOpsSummaryContainer,
);

export const IssueLinksView = ({
	issueLinks = null,
	issueLinkTypes = [],
	isIssueViewComplete = false,
	onIssueLinkClicked = noop,
	onIssueLinkRendered = noop,
	onIssueLinkAddClick = noop,
	onIssueLinkAddClosed = noop,
	onRelatedIssuesTriggered = noop,
	onMountCreatePermissionFetching,
	displayAddIssueLinks,
	displayRelatedIssueSuggestion,
	createAnalyticsEvent,
	onAuthenticateApplink,
	linkActions,
	canAddIssueLinks,
	hasLinks,
	retryLinkedIssue,
	cancelLinkedIssue,
	projectType,
	baseUrl,
	issueKey,
	onLinkedIssueCreated,
	issueId,
	onEditIssue,
}: Props) => {
	const intl = useIntl();

	const linkedIssueKey: string = useIssueKey();
	const projectKey = useProjectKey(linkedIssueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);
	const smartCardProviderProduct = useSmartCardProductType();
	const isAIFeatureEnabled = useSmartCardIsAIEnabled();
	const isAiEnabledForIssue = useIsAiEnabledForIssue();
	const isJiraIssue = useIsJiraIssue();

	useEffect(() => {
		if (hasLinks) {
			// no need to dispatch fetch request if no links
			// @ts-expect-error - TS2345 - Argument of type 'IssueLink | null' is not assignable to parameter of type '{} | undefined'.
			onIssueLinkRendered(loadedIssueLinksCount(issueLinks));
		}
		onMountCreatePermissionFetching();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { subscribe } = useGICLifeCycleEvents();

	useEffect(
		() =>
			subscribe(GIC_LIFECYCLE_EVENTS.ISSUE_CREATE_SUCCESS, async (payload) => {
				if (
					payload.callbackPayload?.id !== ISSUE_VIEW_CREATE_LINKED_ISSUE &&
					payload.callbackPayload?.id !== COMMAND_PALETTE_CREATE_LINKED_ISSUE
				)
					return;

				const { createdIssueDetails } = payload.createdIssueData;
				const { sourceIssueId, baseUrl: callbackPayloadBaseUrl } = payload.callbackPayload.data;

				if (issueId !== sourceIssueId) return;

				const analyticsEvent = createAnalyticsEvent({
					action: 'created',
				});
				if (ff('corex-operandi-issue-view-additional-logging_l6hj8')) {
					onLinkedIssueCreated?.(
						transformIssueLink(
							createdIssueDetails,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							sourceIssueId as string,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							callbackPayloadBaseUrl as string,
						),
						createAnalyticsEvent({
							action: 'linkedToProject',
						}),
						analyticsEvent,
					);
				} else {
					onLinkedIssueCreated?.(
						transformIssueLink(
							createdIssueDetails,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							sourceIssueId as string,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							callbackPayloadBaseUrl as string,
						),
						analyticsEvent,
					);
				}
			}),
		[subscribe, onLinkedIssueCreated, createAnalyticsEvent, issueId],
	);

	const handleOnIssueClickNew = useCallback(
		(
			// @ts-expect-error - TS2322 - Type '{}' is not assignable to type 'LinkedIssue'.
			{
				id,
				issueKey: lIssueKey,
				issueLink,
				remoteIssueLinkError,
				projectType: targetProjectType = null,
				isRemote,
			}: LinkedIssue = {},
			event: MouseEvent,
		) => {
			if (isMiddleClick(event)) return;

			if (lIssueKey) {
				const isIssueNeedAuth = remoteIssueLinkError?.error === APPLINK_REQ_AUTH;

				if (onIssueLinkClicked) {
					// @ts-expect-error - TS2345 - Argument of type 'IssueLink | null' is not assignable to parameter of type '{} | undefined'.
					onIssueLinkClicked(id, targetProjectType, loadedIssueLinksCount(issueLinks));
				}
				if (isLeftClickOnly(event)) {
					if (isIssueNeedAuth) {
						const repairLinkHref = remoteIssueLinkError?.repairLink?.href;
						if (repairLinkHref && onAuthenticateApplink) {
							onAuthenticateApplink(toHref(repairLinkHref));
						}
					} else {
						issueLink && iframeRedirect(issueLink, event.metaKey, linkActions.push);
					}
				}
				if (isMiddleClick(event) || isCmdOrCtrlClick(event)) {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					!isIssueNeedAuth && issueLink && window.open(issueLink, '_blank');
				}

				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'card',
				});
				fireUIAnalytics(analyticsEvent, 'issueLinkedCard', {
					clickedIssueId: id,
					// @ts-expect-error - TS2345 - Argument of type 'IssueLink | null' is not assignable to parameter of type '{} | undefined'.
					linkedIssuesCount: loadedIssueLinksCount(issueLinks),
					issueNeedsAuthentication: isIssueNeedAuth,
					isRemote,
				});
			}
		},
		[createAnalyticsEvent, issueLinks, linkActions.push, onAuthenticateApplink, onIssueLinkClicked],
	);

	const onSuccess = useCallback(() => {
		sendExperienceAnalytics({
			wasExperienceSuccesful: true,
			action: TASK_SUCCESS,
			analyticsSource: 'jiraLinkedIssues',
			experience: 'addLinkedIssue',
			application: application ?? null,
			edition: edition ?? null,
		});
	}, [application, edition]);

	const onError = useCallback(
		(errorMessage?: string) => {
			sendExperienceAnalytics({
				wasExperienceSuccesful: false,
				action: TASK_FAIL,
				analyticsSource: 'jiraLinkedIssues',
				experience: 'addLinkedIssue',
				application: application ?? null,
				edition: edition ?? null,
				...(fg('add-error-message-in-add-linked-issue-event') && {
					additionalAttributes: {
						errorMessage: errorMessage || '',
					},
				}),
			});
		},
		[application, edition],
	);

	const renderIssueLinkInlineMessage = useCallback(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(id: string, isRemote: boolean, userHasPermissionToLink = true as Boolean) => {
			let inlineContentMessage = messages.issueLinkFailureContent;

			inlineContentMessage = userHasPermissionToLink
				? messages.issueLinkFailureContent
				: messages.issueLinkNoPermissionFailureContent;
			return renderInlineMessage(
				intl,
				intl.formatMessage(messages.issueLinkFailureHeader),
				intl.formatMessage(inlineContentMessage),
				(event, analyticsEvent) =>
					ff('corex-operandi-issue-view-additional-logging_l6hj8')
						? retryLinkedIssue(
								id,
								isRemote,
								createAnalyticsEvent({ action: 'succeeded', isRemote }),
								createAnalyticsEvent({ action: 'linkedToProject' }),
								onSuccess,
								onError,
								analyticsEvent.update({ isRemote }),
							)
						: retryLinkedIssue(
								id,
								isRemote,
								createAnalyticsEvent({ action: 'succeeded', isRemote }),
								analyticsEvent.update({ isRemote }),
								onSuccess,
								onError,
							),
				() => cancelLinkedIssue(id, isRemote),
				userHasPermissionToLink,
			);
		},
		[cancelLinkedIssue, createAnalyticsEvent, intl, onError, onSuccess, retryLinkedIssue],
	);

	const renderLineCardGroup = useCallback(
		(issues: LinkedIssueAndLinkId[], name: string) => (
			<SmartCardProvider
				product={smartCardProviderProduct}
				isAdminHubAIEnabled={isAIFeatureEnabled}
			>
				<ItemLineCardGroupWrapper>
					<ItemLineCardGroup
						items={issues}
						groupId={name}
						isInteractive={isIssueViewComplete}
						onClick={handleOnIssueClickNew}
					>
						{({ isHovering, isActive, isFocused, item: issue }) => {
							const handleClick = (event: MouseEvent) => handleOnIssueClickNew({ ...issue }, event);

							return (
								<IssueLinkLineCard
									isHovering={isHovering}
									isActive={isActive}
									isFocused={isFocused}
									issue={issue}
									groupId={name}
									isInteractive={isIssueViewComplete}
									onDelete={noop}
									onClick={handleClick}
									canAddIssueLinks={canAddIssueLinks}
									projectType={projectType}
									onEditIssue={onEditIssue}
								/>
							);
						}}
					</ItemLineCardGroup>
				</ItemLineCardGroupWrapper>
			</SmartCardProvider>
		),
		[
			canAddIssueLinks,
			handleOnIssueClickNew,
			isIssueViewComplete,
			onEditIssue,
			projectType,
			smartCardProviderProduct,
			isAIFeatureEnabled,
		],
	);

	const renderGroup = useCallback(
		(name: string, links: LinkedIssueType[]) => {
			const issues: LinkedIssueAndLinkId[] = links.map((link) => {
				const errorInlineMessage = link.hasError
					? renderIssueLinkInlineMessage(
							link.id,
							!!link.isRemote,
							getUserHasPermissionToLink(link.error),
						)
					: undefined;

				return {
					...link.linkedIssue,
					projectType: link.linkedIssue.projectType || null,
					isLoading: link.isLoading || false,
					hasError: link.hasError,
					errorInlineMessage,
					id: link.globalId || link.linkedIssue.id,
					isRemote: link.isRemote,
					globalId: link.isRemote ? link.globalId : undefined,
					remoteServerHostname: link.isRemote ? link.remoteServerHostname : undefined,
					remoteIssueLinkError: link.isRemote ? link.remoteIssueLinkError : undefined,
					linkId: link.id,
				};
			});

			return (
				<GroupContainer
					key={name}
					// eslint-disable-next-line jira/integration/test-id-by-folder-structure
					data-testid="issue.views.issue-base.content.issue-links.group-container"
				>
					<RelationshipHeading
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						data-testid="issue.issue-view.views.issue-base.content.issue-links.issue-links-view.relationship-heading"
					>
						{name}
					</RelationshipHeading>
					{renderLineCardGroup(issues, name)}
				</GroupContainer>
			);
		},
		[renderIssueLinkInlineMessage, renderLineCardGroup],
	);

	const canEnableRelatedIssues =
		isAiEnabledForIssue && canAddIssueLinks && isJiraIssue && fg('jira_ai_related_issue_enabled');

	const renderHeading = useCallback(
		() => (
			<SectionHeading>
				<Label htmlFor={ISSUE_LINK_SEARCH}>
					<SectionHeadingTitle>
						<FormattedMessage id="issue.issue-links-heading" defaultMessage="Linked issues" />
					</SectionHeadingTitle>
				</Label>
				<SectionHeadingIcons>
					{canAddIssueLinks && hasLinks && <AddIssueLinkButton onClick={onIssueLinkAddClick} />}
					{canEnableRelatedIssues ? (
						<Box xcss={relatedIssuesButtonStyles}>
							<FindSimilarIssuesButton />
						</Box>
					) : null}
				</SectionHeadingIcons>
			</SectionHeading>
		),
		[canAddIssueLinks, hasLinks, onIssueLinkAddClick, canEnableRelatedIssues],
	);

	const renderExistingLinks = useCallback(() => {
		const renderedGroups = (
			<div>
				{issueLinks &&
					Object.keys(issueLinks)
						.sort()
						.map((group) => renderGroup(group, issueLinks[group]))}
			</div>
		);
		return isDevopsFeatureDisabledInFedRamp() ? (
			renderedGroups
		) : (
			<DevOpsSummaryContainer
				issueIds={getIssueIdsForDevSummaries(issueLinks ?? {})}
				location="issue_view_linked_issues_list"
			>
				{renderedGroups}
			</DevOpsSummaryContainer>
		);
	}, [issueLinks, renderGroup]);

	const renderAddIssueLink = useCallback(
		() => (
			<UFOSegment name="issue-link-add">
				<AddContainer>
					<div>
						<IssueLinksAddView
							issueLinkTypes={issueLinkTypes}
							baseUrl={baseUrl}
							issueKey={issueKey}
							onClose={onIssueLinkAddClosed}
							issueLinks={issueLinks}
							onAddSuccess={onSuccess}
							onAddError={onError}
						/>
					</div>
				</AddContainer>
			</UFOSegment>
		),
		[baseUrl, issueKey, issueLinkTypes, issueLinks, onError, onIssueLinkAddClosed, onSuccess],
	);

	const displayIssueLinks = hasLinks && !isEmpty(issueLinks);
	const existingRelatedIssue = (links: IssueLink | null): string[] => {
		if (!isEmpty(links) && links['relates to']) {
			return links['relates to'].map((link: LinkedIssueType) => link.linkedIssueKey);
		}
		return [];
	};

	return (
		// this UFO event appears even when there are no linked issues. Used for reliabilty, performance metrics will be skewed
		<UFOSegment name="issue-view-linked-issues">
			<JSErrorBoundary
				id="issue.issue-view.activity.linked-issues"
				packageName="jiraLinkedIssues"
				teamName="bento"
				fallback="unmount"
			>
				{fg('jira_ai_related_issue_enabled') ? (
					<JiraIssueRelatedIssuesContainer>
						<ContextualAnalyticsData
							sourceName="linkedIssues"
							sourceType={SCREEN}
							attributes={{ location: 'jiraLinkedIssues' }}
						>
							{displayIssueLinks || displayAddIssueLinks || displayRelatedIssueSuggestion
								? renderHeading()
								: null}
							{displayIssueLinks ? (
								<UFOSegment name="issue-link-list">{renderExistingLinks()}</UFOSegment>
							) : null}
							{displayAddIssueLinks ? renderAddIssueLink() : null}
						</ContextualAnalyticsData>
						{canEnableRelatedIssues && (
							<Box xcss={relatedIssuesWrapperStyles}>
								<SuggestRelatedIssuesModalContainer
									issueKey={issueKey}
									existingIssues={existingRelatedIssue(issueLinks)}
									panelDisplay={displayRelatedIssueSuggestion}
									onRelatedIssuesTriggered={onRelatedIssuesTriggered}
								/>
							</Box>
						)}
					</JiraIssueRelatedIssuesContainer>
				) : (
					<ContextualAnalyticsData
						sourceName="linkedIssues"
						sourceType={SCREEN}
						attributes={{ location: 'jiraLinkedIssues' }}
					>
						{displayIssueLinks || displayAddIssueLinks ? renderHeading() : null}
						{displayIssueLinks ? (
							<UFOSegment name="issue-link-list">{renderExistingLinks()}</UFOSegment>
						) : null}
						{displayAddIssueLinks ? renderAddIssueLink() : null}
					</ContextualAnalyticsData>
				)}
			</JSErrorBoundary>
		</UFOSegment>
	);
};

IssueLinksView.displayName = 'IssueLinksView';

export default IssueLinksView;

const RelationshipHeading = (props: { 'data-testid': string; children: React.ReactNode }) => (
	<Heading as="h3" size="xxsmall">
		<span data-testid={props['data-testid']}>{props.children}</span>
	</Heading>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupContainer = styled.div({
	margin: `${token('space.100', '8px')} 0`,
	paddingRight: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:last-child)': {
		paddingBottom: token('space.100', '8px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddContainer = styled.div({
	margin: `${token('space.100', '8px')} 0`,
	paddingRight: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.label({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingBottom: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/use-primitives -- To migrate as part of go/ui-styling-standard
const ItemLineCardGroupWrapper = styled.div({
	marginTop: token('space.100', '8px'),
});

const relatedIssuesButtonStyles = xcss({
	marginLeft: 'space.075',
});

const relatedIssuesWrapperStyles = xcss({
	marginTop: 'space.200',
});
