/**
 * @generated SignedSource<<b0012ae1431a4c3636619fd04f438c8a>>
 * @relayHash 1110f6344967723bd961dd1588f58ccb
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 60a97dc53edbb06baaaee242e200cff2a4e41837100add638c2dfdde9a18433e

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CustomerServiceContextType = "DEFAULT" | "ISSUE" | "%future added value";
export type CustomerServiceFilterInput = {
  context: CustomerServiceContext;
};
export type CustomerServiceContext = {
  issueId?: string | null | undefined;
  type: CustomerServiceContextType;
};
export type panelViewEntitlementQuery$variables = {
  cloudId: string;
  entitlementId: string;
  filter: CustomerServiceFilterInput;
};
export type panelViewEntitlementQuery$data = {
  readonly customerService: {
    readonly entitlementById: {
      readonly __typename: "CustomerServiceEntitlement";
      readonly details: {
        readonly " $fragmentSpreads": FragmentRefs<"detailsView_servicedeskCustomerServiceIssueViewPanel">;
      };
    } | {
      readonly __typename: "QueryError";
      readonly extensions: ReadonlyArray<{
        readonly statusCode: number | null | undefined;
      }> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"throwOnQueryError_servicedeskCustomerServiceCommon">;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  };
};
export type panelViewEntitlementQuery = {
  response: panelViewEntitlementQuery$data;
  variables: panelViewEntitlementQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entitlementId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "entitlementId",
    "variableName": "entitlementId"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "identifier"
},
v8 = {
  "kind": "ScalarField",
  "name": "message"
},
v9 = {
  "kind": "LinkedField",
  "name": "extensions",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "name": "errorType"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "panelViewEntitlementQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v1/*: any*/),
          "concreteType": "CustomerServiceQueryApi",
          "kind": "LinkedField",
          "name": "customerService",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "entitlementById",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "details",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "detailsView_servicedeskCustomerServiceIssueViewPanel"
                          }
                        ]
                      }
                    ],
                    "type": "CustomerServiceEntitlement"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/)
                        ]
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "throwOnQueryError_servicedeskCustomerServiceCommon"
                      }
                    ],
                    "type": "QueryError"
                  }
                ]
              },
              "action": "THROW",
              "path": "customerService.entitlementById"
            }
          ]
        },
        "action": "THROW",
        "path": "customerService"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "panelViewEntitlementQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "CustomerServiceQueryApi",
        "kind": "LinkedField",
        "name": "customerService",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementById",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isCustomerServiceCustomDetailValuesQueryResult"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "CustomerServiceCustomDetailValue",
                            "kind": "LinkedField",
                            "name": "results",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "value"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "values"
                              },
                              {
                                "concreteType": "CustomerServiceCustomDetailType",
                                "kind": "LinkedField",
                                "name": "type",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "options"
                                  }
                                ]
                              },
                              {
                                "concreteType": "CustomerServiceCustomDetailConfigMetadata",
                                "kind": "LinkedField",
                                "name": "config",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "CustomerServiceCustomAttributeStyleConfiguration",
                                    "kind": "LinkedField",
                                    "name": "styleConfiguration",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "CustomerServiceCustomAttributeOptionsStyleConfiguration",
                                        "kind": "LinkedField",
                                        "name": "options",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "optionValue"
                                          },
                                          {
                                            "concreteType": "CustomerServiceCustomAttributeOptionStyle",
                                            "kind": "LinkedField",
                                            "name": "style",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "backgroundColour"
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "CustomerServiceCustomDetailValues"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "type": "QueryError"
                      }
                    ]
                  }
                ],
                "type": "CustomerServiceEntitlement"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v9/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "type": "QueryError"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v5/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "60a97dc53edbb06baaaee242e200cff2a4e41837100add638c2dfdde9a18433e",
    "metadata": {},
    "name": "panelViewEntitlementQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1ed86262e4f255a245e8ef6d9f642e5d";

export default node;
