import React from 'react';

import { useMachine } from '@xstate/react';

import {
	type AIExperienceMachineEvent,
	createAIExperienceMachine,
} from './get-ai-experience-service';
import type {
	AIExperienceMachineContext,
	AIExperienceMachineState,
	ExperienceMachineOptions,
} from './get-ai-experience-service';

export function useAIExperienceService(options: ExperienceMachineOptions) {
	const machineRef = React.useRef<ReturnType<typeof createAIExperienceMachine> | null>(null);

	if (machineRef.current === null) {
		machineRef.current = createAIExperienceMachine(options);
	}

	const [state, send] = useMachine(machineRef.current!);

	return {
		// In a later version of xstate -- the type gen means the machine
		// has good types, so these assertions are not required
		state: state.value as AIExperienceMachineState,
		context: state.context as AIExperienceMachineContext,
		// Out of the box -- the type of the event received by the xstate send
		// will allow any "payload".
		// ie. `send("correct event type", {incorrect: "event payload"})`
		//
		// To avoid related issues -- we've limited the type to only allow
		// sending the full event.
		// ie. `send({type: "correct event type", correct: "event payload"})`
		send: send as (event: AIExperienceMachineEvent) => void,
	};
}
