import { baseScopesRequiredPerField } from '@atlassian/jira-issue-adjustments/src/common/utils/filter-based-on-scopes/fields-scopes.tsx';
import { isAriResourceIdValid } from '../utils/is-ari-resource-id-valid/index.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string/index.tsx';

export type ParentSetPublicValue = string | null;
export type ParentGetPublicValue = { id: string; key: string } | null;

export const parentSetValueValidator = (value: unknown): value is ParentSetPublicValue =>
	value === null || isNotEmptyString(value);

export const validateParentWithAriSetValue = (value: unknown): value is ParentSetPublicValue =>
	value === null || (isNotEmptyString(value) && isAriResourceIdValid(value));

export const parentScopes = baseScopesRequiredPerField;
