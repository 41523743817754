import { fg } from '@atlassian/jira-feature-gating';
import { fireOperationalAnalyticsWithContext } from '@atlassian/jira-ui-modifications-analytics/src/common/utils/operational-analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import {
	selectReadyFieldsOld,
	selectReadyPublicDataForOnInit,
} from '../../hooks/use-ready-fields/utils.tsx';
import type { IssueAdjustmentsState, StoreContainerProps } from '../../types.tsx';
import { setError } from '../errors/index.tsx';
import type { FieldsInitialisationStatus } from './types.tsx';

const getTimeout = () => 50000;

const getFieldsInitializationTime = (startTime: number | null) =>
	startTime !== null ? performance.now() - startTime : null;

const isFieldInitialisationComplete = (status: FieldsInitialisationStatus) =>
	status === 'success' || status === 'timedOut';

export const startFieldsInitialisationTimeout =
	() =>
	({ setState, getState, dispatch }: StoreActionApi<IssueAdjustmentsState>) => {
		const { fieldsInitialization } = getState();

		if (
			fieldsInitialization.timeoutId === undefined &&
			fieldsInitialization.status === 'notStarted'
		) {
			const timeoutId = setTimeout(() => dispatch(failFieldInitilisation()), getTimeout());

			setState({
				fieldsInitialization: {
					startTime: performance.now(),
					timeoutId,
					status: 'inProgress',
				},
			});
		}
	};

export const failFieldInitilisation =
	() =>
	(
		{ getState, setState, dispatch }: StoreActionApi<IssueAdjustmentsState>,
		{ viewType, createAnalyticsEvent }: StoreContainerProps,
	) => {
		const { formData, experienceDataIsLoading, numberOfSupportedFields, fieldsInitialization } =
			getState();
		const { startTime, status } = fieldsInitialization;
		if (
			isFieldInitialisationComplete(status) ||
			(fg('forge_ui_modifications_for_screen_tabs')
				? selectReadyPublicDataForOnInit(getState(), { viewType }) !== null
				: selectReadyFieldsOld(getState(), undefined) !== null)
		) {
			return;
		}

		dispatch(setError({ errorCode: 'ON_INIT_FIELDS_INITIALIZATION_TIMEOUT' }));

		fireOperationalAnalyticsWithContext(
			createAnalyticsEvent({}),
			'fieldsInitialization timedOut',
			viewType,
			{
				previousStatus: status,
				configuredTimeout: getTimeout(),
				fieldsInitializationTime: getFieldsInitializationTime(startTime),
				initalizedFieldsCount: Object.keys(formData ?? {}).length,
				experienceDataIsLoading,
				supportedFieldsCount: numberOfSupportedFields,
			},
		);

		setState({
			fieldsInitialization: {
				...fieldsInitialization,
				status: 'timedOut',
			},
		});
	};

export const successFieldsInitalisation =
	() =>
	(
		{ getState, setState }: StoreActionApi<IssueAdjustmentsState>,
		{ viewType, createAnalyticsEvent }: StoreContainerProps,
	) => {
		const { status } = getState().fieldsInitialization;

		if (isFieldInitialisationComplete(status)) {
			return;
		}

		const { numberOfSupportedFields, fieldsInitialization } = getState();
		const { timeoutId, startTime } = fieldsInitialization;

		clearTimeout(timeoutId);
		fireOperationalAnalyticsWithContext(
			createAnalyticsEvent({}),
			'fieldsInitialization success',
			viewType,
			{
				previousStatus: status,
				fieldsInitializationTime: getFieldsInitializationTime(startTime),
				initalizedFieldsCount: numberOfSupportedFields,
			},
		);

		setState({
			fieldsInitialization: {
				...fieldsInitialization,
				status: 'success',
			},
		});
	};
