/**
 * @generated SignedSource<<ef429e13270131a0a6202f4e89e221ea>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type CustomerServiceCustomDetailTypeName = "BOOLEAN" | "DATE" | "EMAIL" | "MULTISELECT" | "NUMBER" | "PHONE" | "SELECT" | "TEXT" | "URL" | "USER" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails$data = {
  readonly config: {
    readonly styleConfiguration: {
      readonly options: ReadonlyArray<{
        readonly optionValue: string;
        readonly style: {
          readonly backgroundColour: string;
        };
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly name: string;
  readonly type: {
    readonly name: CustomerServiceCustomDetailTypeName;
    readonly options: ReadonlyArray<string> | null | undefined;
  };
  readonly value: string | null | undefined;
  readonly values: ReadonlyArray<string> | null | undefined;
  readonly " $fragmentType": "readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails";
};
export type readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails$key = {
  readonly " $data"?: readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails$data;
  readonly " $fragmentSpreads": FragmentRefs<"readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails",
  "selections": [
    {
      "kind": "RequiredField",
      "field": (v0/*: any*/),
      "action": "THROW",
      "path": "name"
    },
    {
      "kind": "ScalarField",
      "name": "value"
    },
    {
      "kind": "ScalarField",
      "name": "values"
    },
    {
      "concreteType": "CustomerServiceCustomDetailType",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": (v0/*: any*/),
          "action": "THROW",
          "path": "type.name"
        },
        {
          "kind": "ScalarField",
          "name": "options"
        }
      ]
    },
    {
      "concreteType": "CustomerServiceCustomDetailConfigMetadata",
      "kind": "LinkedField",
      "name": "config",
      "plural": false,
      "selections": [
        {
          "concreteType": "CustomerServiceCustomAttributeStyleConfiguration",
          "kind": "LinkedField",
          "name": "styleConfiguration",
          "plural": false,
          "selections": [
            {
              "concreteType": "CustomerServiceCustomAttributeOptionsStyleConfiguration",
              "kind": "LinkedField",
              "name": "options",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "optionValue"
                },
                {
                  "concreteType": "CustomerServiceCustomAttributeOptionStyle",
                  "kind": "LinkedField",
                  "name": "style",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "backgroundColour"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "CustomerServiceCustomDetailValue"
};
})();

(node as any).hash = "c7ab7ed7f6a31c62f05e83958c86874a";

export default node;
