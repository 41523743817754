import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import {
	INLINE,
	CHILD_ISSUE_LOZENGE,
} from '@atlassian/jira-issue-field-number/src/common/types.tsx';
import { useNumberField } from '@atlassian/jira-issue-field-number/src/services/index.tsx';
import { IssueNumberFieldBoundary } from '@atlassian/jira-issue-field-number/src/ui/async.tsx';
import type IssueNumberFieldType from '@atlassian/jira-issue-field-number/src/ui/index.tsx';
import { NumberView as IssueNumberFieldReadonly } from '@atlassian/jira-issue-field-number/src/ui/view/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { stopPropagation } from '../../common/utils.tsx';
import type { Props } from './types.tsx';

// TODO reevaluate phase - forPaint is simply for initial parity
// Exported only for dependency injection.
export const IssueNumberField = lazyForPaint<typeof IssueNumberFieldType>(
	() =>
		import(/* webpackChunkName: "async-issue-field-number" */ '@atlassian/jira-issue-field-number'),
);

export const Estimate = memo<Props>(({ issueKey, estimateFieldId }: Props) => {
	const [{ value }] = useNumberField({ issueKey, fieldKey: estimateFieldId });

	const fallback = <IssueNumberFieldReadonly isEditable value={value} />;

	return (
		<Container
			data-testid="issue-line-card.ui.estimate.estimate-field"
			role="button"
			onClick={stopPropagation}
			onFocus={stopPropagation}
		>
			<IssueNumberFieldBoundary packageName="issue" fallback={fallback}>
				<IssueNumberField
					issueKey={issueKey}
					fieldKey={estimateFieldId}
					fieldFocusStyle={CHILD_ISSUE_LOZENGE}
					fieldEditStyle={INLINE}
				/>
			</IssueNumberFieldBoundary>
		</Container>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div({
	paddingLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > div': {
		marginLeft: 0,
	},
});
