import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import { AsyncSelect } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import type { GroupOption, TeamFieldType } from '../types.tsx';
import MenuListComponent from './menu-list.tsx';
import messages from './messages.tsx';
import OptionComponent from './option.tsx';
import SingleValueComponent from './single-value.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MenuList = (props: any) => <MenuListComponent {...props} />;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SingleValue = (props: any) => <SingleValueComponent {...props} />;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Option = (props: any) => <OptionComponent {...props} />;

const GroupHeading = () => {
	const { formatMessage } = useIntl();
	return <OptionHeader>{formatMessage(messages.sharedTeam)}</OptionHeader>;
};

export type Props = {
	value: TeamFieldType | null;
	portalElement?: HTMLElement;
	menuPlacement?: string;
	// eslint-disable-next-line jira/react/handler-naming
	loadOptions: (inputValue: string) => Promise<GroupOption[]>;
	onChange: (value: TeamFieldType | null) => void;
	onConfirm: (value: TeamFieldType | null) => void;
};

export const EditableTeamField = ({
	value,
	portalElement,
	menuPlacement,
	loadOptions,
	onChange,
	onConfirm,
}: Props) => {
	const { formatMessage } = useIntl();

	const onChangeNew = useCallback(
		(val: TeamFieldType | null) => {
			onChange(val);
			onConfirm(val);
		},
		[onChange, onConfirm],
	);

	const debouncedLoadOptions = useMemo(
		() =>
			debounce((searchText: string, callback: (arg1: GroupOption[]) => void) => {
				loadOptions(searchText).then(callback, () => {
					callback([]);
				});
			}, 300),
		[loadOptions],
	);
	const ariaLabel =
		value && value.label ? value.label : formatMessage(messages.teamSelectAriaLabel);

	return (
		<AsyncSelect
			value={value}
			autoFocus
			defaultOptions
			menuPortalTarget={portalElement}
			// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'MenuPlacement | undefined'.
			menuPlacement={menuPlacement}
			components={{
				MenuList,
				Option,
				SingleValue,
				GroupHeading,
			}}
			onChange={onChangeNew}
			loadOptions={debouncedLoadOptions}
			defaultMenuIsOpen
			isClearable={value !== null}
			noOptionsMessage={() => formatMessage(messages.noMatches)}
			placeholder={formatMessage(messages.placeholder)}
			styles={defaultSelectStyles}
			cacheOptions
			aria-label={ariaLabel}
		/>
	);
};

export default EditableTeamField;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionHeader = styled.div({
	padding: `0 ${token('space.200', '16px')} ${token('space.100', '8px')} ${token(
		'space.200',
		'16px',
	)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.DN30),
	font: token('font.heading.xxsmall'),
	fontWeight: token('font.weight.bold'),
});
