import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	previousIssueTooltip: {
		id: 'issue-view-foundation.header.header-breadcrumbs.issue-navigator.previous-issue-tooltip',
		defaultMessage: 'Previous issue ‘{issueKey}’',
		description:
			'Tooltip content displayed when previous issue navigation button on issue view is hovered.',
	},
	nextIssueIssueTooltip: {
		id: 'issue-view-foundation.header.header-breadcrumbs.issue-navigator.next-issue-issue-tooltip',
		defaultMessage: 'Next issue ‘{issueKey}’',
		description:
			'Tooltip content displayed when next issue navigation button on issue view is hovered.',
	},
	canNotNavigateNextIssueTooltip: {
		id: 'issue-view-foundation.header.header-breadcrumbs.issue-navigator.can-not-navigate-next-issue-tooltip',
		defaultMessage: 'We can’t show any more issues. Go to your queue to view all issues.',
		description:
			'Tooltip content displayed when disabled next issue navigation button on issue view is hovered.',
	},
	canNotNavigatePrevioustIssueTooltip: {
		id: 'issue-view-foundation.header.header-breadcrumbs.issue-navigator.can-not-navigate-previoust-issue-tooltip',
		defaultMessage: 'We can’t show previous issues. Go to your queue to view all issues.',
		description:
			'Tooltip content displayed when disabled previous issue navigation button on issue view is hovered for pages other than first.',
	},
});
