import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ContainerControl = styled.ul({
	boxShadow: token(
		'elevation.shadow.raised',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 1px 1px ${colors.N50A}, 0 0 1px 1px ${colors.N40A}`,
	),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface.raised', colors.N10),
	borderRadius: '3px',
	padding: `${token('space.025', '2px')} 0`,
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ContainerExperiment = styled2.ul({
	boxShadow: token(
		'elevation.shadow.raised',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 1px 1px ${colors.N50A}, 0 0 1px 1px ${colors.N40A}`,
	),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface.raised', colors.N10),
	borderRadius: '3px',
	padding: `${token('space.025', '2px')} 0`,
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles
export const Container = styledComponentWithCondition(
	() => ff('compiled-react-migration-issue-view_zilee'),
	ContainerExperiment,
	ContainerControl,
);
