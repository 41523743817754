/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxFrag */

import { type ReactNode, useCallback, useState } from 'react';

import { jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { type MessageDescriptor } from 'react-intl-next';
import { useIntl } from 'react-intl-next';

import DropdownMenu from '@atlaskit/dropdown-menu';

import messages from './messages';
import { RefineDropdownItems } from './RefineDropdownItems';

export interface RefineDropdownBaseItem {
	icon?: ReactNode;
	title: MessageDescriptor;
	onClick?: () => void;
	children?: RefineDropdownBaseItem[];
}

export interface RefineDropdownClickableItem extends RefineDropdownBaseItem {
	icon: ReactNode;
	title: MessageDescriptor;
	children?: undefined;
	onClick: () => void;
}

export interface RefineDropdownSubMenuItem extends RefineDropdownBaseItem {
	icon: ReactNode;
	title: MessageDescriptor;
	children: Omit<RefineDropdownClickableItem, 'icon'>[];
	onClick?: undefined;
}

export type RefineDropdownItem = RefineDropdownClickableItem | RefineDropdownSubMenuItem;

interface Props {
	items: RefineDropdownItem[];
}

export function RefineDropdown({ items }: Props) {
	const { formatMessage } = useIntl();

	const [isOpened, setIsOpened] = useState(false);
	const [currentOpenedSubMenu, setCurrentOpenedSubMenu] =
		useState<RefineDropdownSubMenuItem | null>(null);

	const handleOpenChange = useCallback(({ isOpen }: { isOpen: boolean }) => {
		setIsOpened(isOpen);
		if (!isOpen) {
			setCurrentOpenedSubMenu(null);
		}
	}, []);

	const handleItemClick = useCallback(() => {
		setIsOpened(false);
		setCurrentOpenedSubMenu(null);
	}, []);

	const handleSubMenuOpenChanged = useCallback(
		(subMenu: RefineDropdownSubMenuItem, isOpen: boolean) => {
			setCurrentOpenedSubMenu((current) => {
				// Close the current opened sub menu if it's not the same as the one that was clicked
				if (current === subMenu && !isOpen) {
					return null;
				}

				return subMenu;
			});
		},
		[],
	);

	return (
		<DropdownMenu
			trigger={formatMessage(messages.refineButton)}
			testId="refine-dropdown"
			shouldRenderToParent
			isOpen={isOpened}
			onOpenChange={handleOpenChange}
		>
			<RefineDropdownItems
				items={items}
				onItemClick={handleItemClick}
				currentOpenedSubMenu={currentOpenedSubMenu}
				onSubMenuOpenChanged={handleSubMenuOpenChanged}
			/>
		</DropdownMenu>
	);
}
