import React from 'react';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { streamConvoAI } from '../../../provider/prompt-requests/convo-ai';
import { Icon } from '../../assets/icons/brainstorm';
import { createInsertAtCurrentPosition } from '../../config-item-actions/markdown';

import { messages } from './messages';

const SELECTION_TYPE = 'empty';

export const suggestResponseConfigItem = (issueId?: string) =>
	createEditorPluginAIConfigItemMarkdown({
		key: CONFIG_ITEM_KEYS.SUGGEST_REPONSE,
		title: messages.title,
		description: messages.description,
		selectionType: SELECTION_TYPE,
		getInitialContext: ({ intl }) => ({
			userLocale: intl.locale,
			intentSchemaId: 'brainstorm_intent_schema.json',
			document: '',
		}),
		icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
		triggerPromptRequest({ initialContext, promptInput, editorSchema, analyticsContext }) {
			return streamConvoAI(
				{
					aiSessionId: analyticsContext?.aiSessionId,
					customPrompt: promptInput!,
					userLocale: initialContext.userLocale,
					intentSchemaId: 'brainstorm_intent_schema.json',
					editorSchema,
					currentSelection: initialContext.document,
				},
				`/gateway/api/firebolt/1/agent-response/issues/${issueId}/stream`,
			);
		},
		isVisible: () => true,
		actions: [createInsertAtCurrentPosition({ appearance: 'primary' })],
		getBackendModel: () => {
			return 'assistance-service';
		},
		statusLozengeType: 'beta',
	});
