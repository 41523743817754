/* eslint-disable @atlassian/relay/unused-fields */
import React, { useCallback, useMemo, useState } from 'react';
import type { Dispatch } from 'redux';
import { fetchQuery, graphql, useFragment } from 'react-relay';
import { withAnalyticsContext } from '@atlaskit/analytics-next';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import type { Issue } from '@atlassian/jira-assign-issue-parent-modal/src/model/types.tsx';
import { TEAM_MANAGED_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { IssueParent } from '@atlassian/jira-issue-parent-services/src/services/types.tsx';
import { ISSUE_TYPE_HIERARCHY_LEVEL } from '@atlassian/jira-issue-shared-types/src/common/types/issue-hierarchy-type.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { epicModalOpenInteraction } from '@atlassian/jira-issue-view-common-utils/src/utils/performance-analytics/index.tsx';
import { ConnectRelayHOC } from '@atlassian/jira-issue-view-connect-relay/src/index.tsx';
import {
	parentCandidatesForExistingIssueLegacyAndAgg,
	useParentFieldUpdate,
} from '@atlassian/jira-issue-view-layout-parent-field/src/common/ui/issue-parent-legacy-and-agg/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	fetchIssueParentsWithInfoRequest,
	setParentRequest,
	setParentOptimistic,
	unsetParentRequest,
	showAssignIssueParentModal,
} from '@atlassian/jira-issue-view-store/src/actions/assign-issue-parent-modal-actions.tsx';
import {
	projectIdSelector,
	parentLevelIssuesSelector,
	isSimplifiedProjectSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { assignIssueParentModalIssuesSelector } from '@atlassian/jira-issue-view-store/src/selectors/assign-issue-parent-modal-selector.tsx';
import { issueTypesSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-types-selector.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type {
	issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$key,
	issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$data,
} from '@atlassian/jira-relay/src/__generated__/issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay.graphql';
import type {
	issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query,
	issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query$data,
} from '@atlassian/jira-relay/src/__generated__/issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query.graphql';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import IssueParentSwitcher, { type Props } from './view.tsx';

const mapStateToProps = (state: State) =>
	ff('relay-migration-issue-fields-parent_5p1ac')
		? {}
		: {
				projectId: projectIdSelector(state),
				issueTypes: issueTypesSelector(state),
				issues: assignIssueParentModalIssuesSelector(state),
				issueParents: parentLevelIssuesSelector(state),
				isSimplifiedProject: isSimplifiedProjectSelector(state),
			};

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
	ff('relay-migration-issue-fields-parent_5p1ac')
		? {
				onViewAllParentsClick: () => {
					epicModalOpenInteraction.start();
					dispatch(showAssignIssueParentModal());
				},
			}
		: {
				fetchIssueParents: () => {
					dispatch(fetchIssueParentsWithInfoRequest());
				},
				setIssueParent: (issues: Issue[], parentId?: IssueId) => {
					dispatch(parentId ? setParentRequest(issues, parentId) : unsetParentRequest(issues));
				},
				setIssueParentOptimistic: (issues: Issue[], parent: IssueParent | null) => {
					dispatch(setParentOptimistic(issues, parent, Promise.resolve()));
				},
				onViewAllParentsClick: () => {
					epicModalOpenInteraction.start();
					dispatch(showAssignIssueParentModal());
				},
			};

type RelayProps = {
	setIssueParent: (issues: Issue[], parentId?: IssueId) => void;
	setIssueParentOptimistic: (issues: Issue[], parent: IssueParent | null) => void;
	onFetchIssueParents: () => void;
	issueParents: IssueParent[];
};

const mapRelayToProps = (
	relayData: issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$data,
	{ setIssueParent, setIssueParentOptimistic, onFetchIssueParents, issueParents }: RelayProps,
): ReturnType<typeof mapStateToProps> &
	Omit<ReturnType<typeof mapDispatchToProps>, 'onViewAllParentsClick'> => {
	const project = relayData?.projectField?.project;

	const parentField = relayData?.parentIssueField;

	const parentIssue = parentField?.parentIssue;

	const issueType = relayData.issueTypeField?.issueType;

	return {
		// state
		projectId: parseInt(project?.projectId || '', 10),
		issueTypes: [
			{
				id: parseInt(issueType?.issueTypeId || '', 10),
				iconUrl: issueType?.avatar?.medium ?? '',
				name: issueType?.name || '',
				subtask: issueType?.hierarchy?.level === ISSUE_TYPE_HIERARCHY_LEVEL.SUBTASK_LEVEL,
			},
		],
		issues: [
			{
				id: relayData.id,
				key: relayData.key,
				parentId: parentIssue?.issueId || '',
			},
		],
		isSimplifiedProject: project?.projectStyle === TEAM_MANAGED_PROJECT,

		// from props
		issueParents,

		// dispatch
		setIssueParent,
		setIssueParentOptimistic,
		fetchIssueParents: onFetchIssueParents,
	};
};

const connectRelayToBreadcrumbs = ConnectRelayHOC(
	mapRelayToProps,
	mapStateToProps,
	mapDispatchToProps,
);

const IssueParentSwitcherOld = flowWithSafeComponent(
	withAnalyticsContext({ componentName: 'dropdownMenu' }),
	withFireUiAnalytics({
		onToggle: 'toggled',
		onClick: 'clicked',
	}),
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<{ projectId: number | null; issueTypes: IssueType[]; issues: Issue[]; issueParents: IssueParent[]; isSimplifiedProject: boolean; } & { fetchIssueParents: () => void; setIssueParent: (issues: Issue[], parentId?: string | undefined) => void; setIssueParentOptimistic: (issues: Issue[...' is not assignable to parameter of type 'FlowStep<WithUIAnalyticsEvent<PropsWithoutRef<PropsWithoutRef<LibraryManagedAttributes<Component, Props & WithContextProps>> & RefAttributes<...>>, { ...; }>, ConnectedComponent<...>>'.
	connect(mapStateToProps, mapDispatchToProps),
)(IssueParentSwitcher);

type IssueParentSwitcherExternalProps = Omit<Props, keyof ReturnType<typeof mapRelayToProps>>;

// exported for testing
export const IssueParentSwitcherConnected: (
	props: IssueParentSwitcherExternalProps & {
		relayData: issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$data;
		relayProps: RelayProps;
	},
) => React.ReactNode = flowWithSafeComponent(
	withAnalyticsContext({ componentName: 'dropdownMenu' }),
	withFireUiAnalytics({
		onToggle: 'toggled',
		onClick: 'clicked',
	}),
	connectRelayToBreadcrumbs,
)(IssueParentSwitcher);

type IssueParentSwitcherWithRelayProps = IssueParentSwitcherExternalProps & {
	issue?: issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$key; // TODO remove optional on relay-migration-issue-fields-parent_5p1ac cleanup
	component?: unknown; // TODO remove this prop on relay-migration-issue-fields-parent_5p1ac cleanup this was added to fix ts errors
};

const IssueParentSwitcherWithRelay = ({ issue, ...rest }: IssueParentSwitcherWithRelayProps) => {
	const data =
		// eslint-disable-next-line @atlassian/relay/query-restriction
		useFragment<issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$key>(
			graphql`
				fragment issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay on JiraIssue {
					id
					key
					issueId
					issueTypeField {
						issueType {
							name
							issueTypeId
							avatar {
								medium
							}
							hierarchy {
								level
							}
						}
					}
					parentIssueField {
						id
						fieldId
						type
						name
						parentIssue {
							id
							issueId
						}
					}
					projectField {
						name
						project {
							projectId
							projectStyle
						}
					}
				}
			`,
			// TODO cleanup eslint and ! when relay-migration-issue-fields-parent_5p1ac cleanup
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			issue!,
		);

	const [issueParentsAgg, setIssueParentsAgg] = useState<
		issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query$data | undefined
	>(undefined);
	const onFetchIssueParents = useCallback(async () => {
		const parentFieldId = data?.parentIssueField?.id;
		if (!parentFieldId) {
			return;
		}
		const issueParentsData =
			await fetchQuery<issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query>(
				getRelayEnvironment(),
				graphql`
					query issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query(
						$parentFieldId: ID!
					) {
						node(id: $parentFieldId) {
							... on JiraParentIssueField {
								parentCandidatesForExistingIssue(excludeDone: true, searchBy: "") {
									edges {
										node {
											...issueParentLegacyAndAgg_inlineData
										}
									}
								}
							}
						}
					}
				`,
				{
					parentFieldId,
				},
				{ fetchPolicy: 'store-or-network' },
			).toPromise();

		setIssueParentsAgg(issueParentsData);
	}, [data.parentIssueField]);

	const issueParentsAll = useMemo(
		() =>
			parentCandidatesForExistingIssueLegacyAndAgg(
				issueParentsAgg?.node?.parentCandidatesForExistingIssue,
			),
		[issueParentsAgg],
	);

	const issueParents: IssueParent[] = useMemo(
		() => issueParentsAll.map((issueParent) => issueParent.node.legacy),
		[issueParentsAll],
	);

	const issueId = data.issueId;
	const parentField = useMemo(() => data?.parentIssueField, [data?.parentIssueField]);

	const { onUpdate } = useParentFieldUpdate();

	const setIssueParent = useCallback(
		(issues: Issue[], parentId?: IssueId) => {
			if (!parentField) {
				return;
			}
			const newParent =
				issueParentsAll?.find((edge) => edge?.node?.agg?.issueId === parentId)?.node?.agg || null;

			onUpdate(
				issueId,
				{
					id: parentField?.id || '',
					fieldId: parentField?.fieldId || '',
					type: parentField?.type || '',
					name: parentField?.name || '',
				},
				newParent,
			);
		},
		[issueId, issueParentsAll, onUpdate, parentField],
	);

	const setIssueParentOptimistic = useCallback(
		(issues: Issue[], parent: IssueParent | null) => {
			const changedIssues = issues.filter(
				(changedIssue) => changedIssue.parentId !== parent?.id && changedIssue.id,
			);
			if (changedIssues.length === 0) {
				return;
			}

			return setIssueParent(issues, parent?.id);
		},
		[setIssueParent],
	);

	const relayProps = useMemo(
		() => ({
			setIssueParent,
			setIssueParentOptimistic,
			onFetchIssueParents,
			issueParents,
		}),
		[setIssueParent, setIssueParentOptimistic, onFetchIssueParents, issueParents],
	);

	if (!data) {
		return null;
	}
	return <IssueParentSwitcherConnected relayData={data} relayProps={relayProps} {...rest} />;
};

const IssueParentSwitcherExternal: (props: IssueParentSwitcherWithRelayProps) => React.ReactNode =
	componentWithCondition(
		() => ff('relay-migration-issue-fields-parent_5p1ac'),
		IssueParentSwitcherWithRelay,
		IssueParentSwitcherOld,
	);

export default IssueParentSwitcherExternal;
