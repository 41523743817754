import { styled } from '@compiled/react';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

const compactWidthPixels = `${gridSize * 14.5}px`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OriginalEstimateEditContainer = styled.div<{ isCompact: boolean }>({
	cursor: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isCompact }) => (isCompact ? compactWidthPixels : '100%'),
});
