import { useEffect } from 'react';
import type { Store } from 'redux';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { getFieldType } from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import { ChangeEventTypes } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/constants.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge/src';

export const useAnalyticsService = (store: Store<IssueState>) => {
	const [, { registerEventHandler }] = useIssueViewFieldUpdateEvents();
	const issueId = useIssueId();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const unregister = registerEventHandler('onChange', (event) => {
			if (event.type !== ChangeEventTypes.FIELD_CHANGED || event.issueId !== issueId) {
				return;
			}
			const field = store.getState()?.fields[event.fieldId];
			const fieldType = getFieldType(field) ?? '';
			getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(event.fieldId, {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType,
				},
			});
		});

		return unregister;
	}, [createAnalyticsEvent, issueId, registerEventHandler, store]);
};
