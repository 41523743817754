import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type { assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot$key as RootFragmentControlled } from '@atlassian/jira-relay/src/__generated__/assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot.graphql';
import type { assignToMe_issueFieldAssigneeAssignToMe_fragmentRef$key as AssigneeFragment } from '@atlassian/jira-relay/src/__generated__/assignToMe_issueFieldAssigneeAssignToMe_fragmentRef.graphql';
import type { assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot$key as RootFragment } from '@atlassian/jira-relay/src/__generated__/assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot.graphql';
import { ASSIGN_TO_ME_ANALYTICS } from './constants.tsx';
import messages from './messages.tsx';
import type {
	AssignToMePropsWithFragments,
	AssignToMeWithControlledAssigneeProps,
} from './types.tsx';

// Check for `null` to currectly display `Unassinged` option
const isValidSelectedUser = (user?: AggUser | null) => user === null || !!user;

/**
 * An "assign to me" link button
 * Takes the "currentAssigneeAccountId" as a prop and provides an "onClick" callback prop.
 *
 * For use in forms or other usecases where the current assignee is controllable state that will submitted later.
 * For instantly changing the current assignee and saving the result to the server, use AssignToMe instead
 */
export const AssignToMeWithControlledAssignee = (props: AssignToMeWithControlledAssigneeProps) => {
	const { onClick, projectKey, fragmentRefRoot, currentAssigneeId } = props;
	// #region Relay

	// query fragment to get current user
	const dataUser = useFragment<RootFragmentControlled>(
		graphql`
			fragment assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot on Query {
				me {
					user {
						id
						# eslint-disable-next-line @atlassian/relay/unused-fields
						accountStatus
						# eslint-disable-next-line @atlassian/relay/unused-fields
						name
						# eslint-disable-next-line @atlassian/relay/unused-fields
						picture
						# eslint-disable-next-line @atlassian/relay/unused-fields
						accountId
					}
				}
			}
		`,
		fragmentRefRoot,
	);
	// TODO: https://jplat.atlassian.net/browse/JIV-14354 replace this use with a fragment,
	const [{ canBeAssignedToIssues }] = useProjectPermissions(projectKey);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const currentUser = dataUser?.me?.user;
	const isAssigneeSameAsCurrentUser = currentAssigneeId === currentUser?.id;

	const handleClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: ASSIGN_TO_ME_ANALYTICS.ACTION,
				actionSubject: ASSIGN_TO_ME_ANALYTICS.ACTION_SUBJECT,
			}),
		);
		if (currentUser) {
			onClick(currentUser);
		}
	}, [createAnalyticsEvent, currentUser, onClick]);

	const { formatMessage } = useIntl();

	if (!canBeAssignedToIssues || isAssigneeSameAsCurrentUser) {
		return null;
	}

	return (
		<LinkText
			tabIndex={0}
			onClick={handleClick}
			role="button"
			data-testid="issue-field-assignee-assign-to-me.ui.assign-to-me.link"
		>
			{formatMessage(messages.assignToMe)}
		</LinkText>
	);
};

export const AssignToMe = (props: AssignToMePropsWithFragments) => {
	const { fragmentRef, fragmentRefRoot, selectedUser, ...restProps } = props;
	// query fragment to get the current Assignee

	const dataAssignee = useFragment<AssigneeFragment>(
		graphql`
			fragment assignToMe_issueFieldAssigneeAssignToMe_fragmentRef on JiraSingleSelectUserPickerField {
				user {
					id
				}
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	// query fragment to get current user
	const dataUser = useFragment<RootFragment>(
		graphql`
			fragment assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot on Query {
				...assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot
			}
		`,
		fragmentRefRoot,
	);

	// If the selectedUser is provided - we check by its value, otherwise we check by the query fragment.
	// In Issue view the fragment will be updated each time assignee is submitted, but it is not the case
	// for the Issue transition where we need to submit the whole form so the assigned user could be relfected
	// in the query fragment.
	const currentAssigneeId = isValidSelectedUser(selectedUser)
		? selectedUser?.id
		: dataAssignee?.user?.id;
	const { overriding } = useInlineEditFieldInjections();

	const isEditable = ff('relay-migration-issue-fields-assignee-ld', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(
				() => overriding.overrideIsEditable(dataAssignee?.fieldConfig?.isEditable || false),
				[dataAssignee?.fieldConfig?.isEditable, overriding],
			)
		: dataAssignee?.fieldConfig?.isEditable;
	if (!isEditable) {
		return null;
	}
	return (
		<AssignToMeWithControlledAssignee
			{...restProps}
			fragmentRefRoot={dataUser}
			currentAssigneeId={currentAssigneeId}
			isEditable={isEditable}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkText = styled.div({
	color: token('color.link', B400),
	cursor: 'pointer',
	marginTop: token('space.050', '4px'),
	'&:hover': {
		textDecoration: 'underline',
	},
	'&:active': {
		color: token('color.link.pressed', B400),
	},
});
