import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IssueNavigatorWrapper = styled.div({
	display: 'flex',
	marginLeft: `${token('space.negative.150', '-12px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& a': {
		padding: 0,
		height: 'auto',
		lineHeight: 'unset',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& span': {
		margin: 0,
		lineHeight: 'unset',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ButtonsSpacer = styled.div({
	width: `${token('space.100', '8px')}`,
});
