import React, { useCallback, useMemo, useState } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import Tooltip from '@atlaskit/tooltip';
import editMessages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import AssigneeEditViewEntryPoint from '@atlassian/jira-issue-field-assignee-editview-full/src/entrypoint.tsx';
import type { AssigneeEditViewEntryPointProps } from '@atlassian/jira-issue-field-assignee-editview-full/src/ui/assignee/types.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import { SingleUserPickerWithProfileCardReadView } from '@atlassian/jira-issue-field-single-user-picker-readview-full/src/ui/single-user-picker/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import { ASSIGNED_ISSUE_ACTION } from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import type { assignee_issueFieldAssignee_AssigneeField_Mutation as AssigneeMutation } from '@atlassian/jira-relay/src/__generated__/assignee_issueFieldAssignee_AssigneeField_Mutation.graphql';
import type { assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditView_fragmentRef$key as AssigneeFragment } from '@atlassian/jira-relay/src/__generated__/assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditView_fragmentRef.graphql';
import type { assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditViewWithIsEditable_fragmentRef$key as AssigneeWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditViewWithIsEditable_fragmentRef.graphql';
import messages from './messages.tsx';
import type {
	AssigneeInlineEditViewProps,
	AssigneeInlineEditViewWithIsEditableProps,
} from './types.tsx';

const isEqualUser = (a: AggUser | null, b: AggUser | null) => a?.id === b?.id;

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `AssigneeInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [AssigneeInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const AssigneeInlineEditViewWithIsEditable = ({
	attributes,
	spacing = 'compact',
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditing: startWithEditViewOpen = false,
	menuPosition,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	portalElement,
	onCancel,
	onEdit,
	isDisabledTooltipShown = false,
	projectKey,
	isEditable,
	readViewFitContainerHeight,
	isTruncated = true,
}: AssigneeInlineEditViewWithIsEditableProps) => {
	const { formatMessage } = useIntl();
	const { overriding } = useInlineEditFieldInjections();

	const isFieldEditable = ff('relay-migration-issue-fields-assignee-ld', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => overriding.overrideIsEditable(isEditable || false), [isEditable, overriding])
		: isEditable;

	// #region Relay
	const data = useFragment<AssigneeWithIsEditableFragment>(
		graphql`
			fragment assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditViewWithIsEditable_fragmentRef on JiraSingleSelectUserPickerField {
				id
				fieldId
				type
				name
				...singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerWithProfileCardReadView_fragmentRef
				user {
					# eslint-disable-next-line @atlassian/relay/unused-fields
					accountId
					name
					# eslint-disable-next-line @atlassian/relay/unused-fields
					picture
					# eslint-disable-next-line @atlassian/relay/unused-fields
					accountStatus
					id
				}
				fieldConfig {
					isRequired
				}
			}
		`,
		fragmentRef,
	);

	const { id: uniqueFieldId, fieldId, type, name, user, fieldConfig } = data;

	const fieldName = ff('relay-migration-issue-fields-assignee-ld', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => overriding.overrideLabel(name), [name, overriding])
		: name;

	const isFieldRequired = fieldConfig?.isRequired ?? false;

	const [commit] = useMutation<AssigneeMutation>(graphql`
		mutation assignee_issueFieldAssignee_AssigneeField_Mutation(
			$input: JiraUpdateSingleSelectUserPickerFieldInput!
		) @raw_response_type {
			jira {
				updateSingleSelectUserPickerField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						user {
							accountId
							name
							picture
							accountStatus
							id
						}
					}
				}
			}
		}
	`);
	// #endregion

	const initialValue = user ?? null;
	const [updatedValue, setUpdatedValue] = useState<AggUser | null>(initialValue);

	const handleSubmit = useCallback(
		(newUser: AggUser | null, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(newUser);
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id: newUser?.id ?? null,
						},
					},
				},
				onCompleted: (mutationData, errors) => {
					if (mutationData.jira?.updateSingleSelectUserPickerField?.success) {
						onSuccess();
					} else {
						// since we can only pass 1 error we just grab the first one if we can
						onFail(errors?.length ? new Error(errors[0].message) : undefined);
					}
				},
				onError(error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateSingleSelectUserPickerField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								user: newUser
									? {
											id: newUser.id,
											accountId: newUser.accountId,
											accountStatus: newUser.accountStatus,
											name: newUser.name,
											picture: newUser.picture,
											__typename: 'AtlassianAccountUser',
										}
									: null,
							},
						},
					},
				},
			});
		},
		[commit, uniqueFieldId, onSubmit],
	);

	const {
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChangeAndConfirm,
		hasServerValidationError,
		invalidMessage,
		isEditing,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType: type,
		initialValue,
		isValueEqual: isEqualUser,
		onCancel,
		onEdit,
		onSubmit: handleSubmit,
		onSubmitSucceeded,
		onSubmitFailed,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
	});

	// #region Read view
	const renderReadView = useCallback(
		() => (
			<SingleUserPickerWithProfileCardReadView
				fragmentRef={data}
				emptyUserLabel={formatMessage(messages.unassignedOption)}
				profileCardAction={ASSIGNED_ISSUE_ACTION}
				isTruncated={isTruncated}
			/>
		),
		[data, formatMessage, isTruncated],
	);
	// #endregion

	const getEditViewProps = (fieldProps: ValidationFieldProps): AssigneeEditViewEntryPointProps => ({
		...fieldProps,
		fieldId: uniqueFieldId,
		autoFocus: true,
		value: updatedValue,
		onChange: handleChangeAndConfirm,
		spacing,
		projectKey,
		fetchSuggestionsOnMount: false,
		portalElement,
		enablePeopleInvite: true,
		includeNoneOption: !isFieldRequired,
		isClearable: !isFieldRequired,
		menuPosition,
		label: fieldName,
	});

	const editButtonLabel = formatMessage(editMessages.extendedEditButtonLabel, {
		inputFieldValue: initialValue?.name ?? formatMessage(messages.unassignedOption),
		fieldName,
	});

	const inlineEditElement = (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewEntryPoint={AssigneeEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readView={renderReadView}
			hideActionButtons
			editButtonLabel={editButtonLabel}
			readViewFitContainerHeight={readViewFitContainerHeight}
		/>
	);

	return isDisabledTooltipShown && !isFieldEditable ? (
		<Tooltip
			content={formatMessage(editMessages.disabledReporterTooltip, {
				fieldName,
			})}
			position="mouse"
		>
			{inlineEditElement}
		</Tooltip>
	) : (
		inlineEditElement
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [AssigneeInlineEditViewProps](./types.tsx)
 */
export const AssigneeInlineEditView = ({ fragmentRef, ...props }: AssigneeInlineEditViewProps) => {
	const data = useFragment<AssigneeFragment>(
		graphql`
			fragment assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditView_fragmentRef on JiraSingleSelectUserPickerField {
				...assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<AssigneeInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
