import React, { useMemo } from 'react';
import { useShouldBeCompact } from '@atlassian/jira-issue-hooks/src/services/use-should-be-compact/index.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import FeedDisplay from './main.tsx';

type Props = {
	containerWidth?: number;
};

const ActivityFeed = ({ containerWidth }: Props) => {
	const { shouldBeCompact, setMeasuredRef, forceUpdate } = useShouldBeCompact(containerWidth, 15);

	const setActivityItemsRef: (arg1: HTMLElement | null) => void = useMemo(
		() => setMeasuredRef('activity-items'),
		[setMeasuredRef],
	);
	const setSmartRequestSummaryTriggerRef: (arg1: HTMLElement | null) => void = useMemo(
		() => setMeasuredRef('issue-smart-summary-trigger'),
		[setMeasuredRef],
	);
	const setActivitySortRef: (arg1: HTMLElement | null) => void = useMemo(
		() => setMeasuredRef('activity-sort'),
		[setMeasuredRef],
	);

	return (
		<UFOSegment name="issue-activity-feed">
			<FeedDisplay
				shouldDisplayDropdown={shouldBeCompact}
				setActivityItemsRef={setActivityItemsRef}
				setActivitySortRef={setActivitySortRef}
				setSmartRequestSummaryTriggerRef={setSmartRequestSummaryTriggerRef}
				onForceUpdateCompactState={forceUpdate}
			/>
		</UFOSegment>
	);
};

export default flowWithSafeComponent(withContainerWidth)(ActivityFeed);
