/**
 * @generated SignedSource<<552315ca9be4e6870b0e400b9eb46121>>
 * @relayHash a6093dd4ea94faa26bb9c22fd9ca2b03
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 670ffae3c1a67038a59ccc4e410f99c2ddefed4a918a9920318d52da797cf91a

import type { ConcreteRequest, Query } from 'relay-runtime';
export type ToolchainCheckAuthErrorCode = "PROVIDER_ERROR" | "%future added value";
export type services_providerAuthCheckQuery$variables = {
  cloudId: string;
  isCheckAuthV2Enabled: boolean;
  providerId: string;
};
export type services_providerAuthCheckQuery$data = {
  readonly devOps: {
    readonly toolchain: {
      readonly checkAuth?: {
        readonly __typename: string;
        readonly authorized: boolean;
        readonly grant?: {
          readonly authorizationEndpoint: string;
        } | null | undefined;
      } | null | undefined;
      readonly checkAuthV2?: {
        readonly __typename: "QueryError";
        readonly extensions: ReadonlyArray<{
          readonly errorCode?: ToolchainCheckAuthErrorCode | null | undefined;
        }> | null | undefined;
      } | {
        readonly __typename: "ToolchainCheck3LOAuth";
        readonly authorized: boolean;
        readonly grant: {
          readonly authorizationEndpoint: string;
        } | null | undefined;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
    } | null | undefined;
  };
};
export type services_providerAuthCheckQuery = {
  response: services_providerAuthCheckQuery$data;
  variables: services_providerAuthCheckQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCheckAuthV2Enabled"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "providerId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "providerId",
    "variableName": "providerId"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "authorized"
},
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v6 = {
  "concreteType": "ToolchainCheck3LOAuthGrant",
  "kind": "LinkedField",
  "name": "grant",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "authorizationEndpoint"
    }
  ]
},
v7 = {
  "condition": "isCheckAuthV2Enabled",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "args": (v3/*: any*/),
      "kind": "LinkedField",
      "name": "checkAuth",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v6/*: any*/)
          ],
          "type": "ToolchainCheck3LOAuth"
        }
      ]
    }
  ]
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    (v6/*: any*/)
  ],
  "type": "ToolchainCheck3LOAuth"
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "errorCode"
    }
  ],
  "type": "ToolchainCheckAuthErrorExtension"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "services_providerAuthCheckQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "DevOps",
          "kind": "LinkedField",
          "name": "devOps",
          "plural": false,
          "selections": [
            {
              "concreteType": "Toolchain",
              "kind": "LinkedField",
              "name": "toolchain",
              "plural": false,
              "selections": [
                (v7/*: any*/),
                {
                  "condition": "isCheckAuthV2Enabled",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "args": (v3/*: any*/),
                      "kind": "LinkedField",
                      "name": "checkAuthV2",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        (v8/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "extensions",
                              "plural": true,
                              "selections": [
                                (v9/*: any*/)
                              ]
                            }
                          ],
                          "type": "QueryError"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "devOps"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "services_providerAuthCheckQuery",
    "selections": [
      {
        "concreteType": "DevOps",
        "kind": "LinkedField",
        "name": "devOps",
        "plural": false,
        "selections": [
          {
            "concreteType": "Toolchain",
            "kind": "LinkedField",
            "name": "toolchain",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "condition": "isCheckAuthV2Enabled",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "args": (v3/*: any*/),
                    "kind": "LinkedField",
                    "name": "checkAuthV2",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v8/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "extensions",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v9/*: any*/)
                            ]
                          }
                        ],
                        "type": "QueryError"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "670ffae3c1a67038a59ccc4e410f99c2ddefed4a918a9920318d52da797cf91a",
    "metadata": {},
    "name": "services_providerAuthCheckQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f05c60ed0d9e0f2cb09fc0de81ab0971";

export default node;
