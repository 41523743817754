import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import messages from '../messages.tsx';

export const DisabledInlineReadView = () => (
	<DisabledInlineReadViewWrapper>
		<FormattedMessage
			{...messages.replacedByParentField}
			values={{
				// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
				strong: (chunks) => <strong>{chunks}</strong>,
			}}
		/>
	</DisabledInlineReadViewWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledInlineReadViewWrapper = styled.span({
	whiteSpace: 'pre-wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
});
