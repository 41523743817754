import { metrics } from '@atlassian/browser-metrics';
import { ff, getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { isInSample } from '@atlassian/jira-issue-sample-utils/src/common/utils/index.tsx';
import type { Agile } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import type {
	TransformedDataToLegacyGira,
	TransformedDataToLegacyGraphql,
} from '@atlassian/jira-issue-shared-types/src/common/types/transformed-data-type.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AggResponseData } from './common/types/issue-type.tsx';
import { transformAggUserPreferencesToLegacyGiraActivityFeedSortOrder } from './common/utils/activity-feed-sort-order-transformer.tsx';
import { transformAggAttachmentsToLegacyGira } from './common/utils/attachments-transformer.tsx';
import {
	transformAggResponseToLegacyChildIssues,
	transformServerChildIssueFieldsToAssociatedIssues,
} from './common/utils/children-issues-transformer.tsx';
import { transformAggCommentsToLegacyGira } from './common/utils/comments-transformer.tsx';
import { transformAggFieldsToLegacyFields } from './common/utils/fields-transformer.tsx';
import { transformAggIssueToHierarchyLegacyFields } from './common/utils/hierarchy-level-transformer.tsx';
import { transformAggIsArchivedToLegacyGira } from './common/utils/is-archived-transformer.tsx';
import { transformAggResponseToIssueLinkTypes } from './common/utils/issue-link-types-transformer.tsx';
import { transformAggResponseToIssueLinks } from './common/utils/issue-links-transformer.tsx';
import { transformAggUserPreferencesToLegacyGira } from './common/utils/my-preferences-transformer.tsx';
import {
	transformAggFieldsToLegacyParentFields,
	transformServerParentFieldsToParentIssue,
} from './common/utils/parent-issue-transformer.tsx';
import {
	getSystemAggJiraProject,
	transformAggProjectToProjectContext,
} from './common/utils/project-field-transformer.tsx';
import { getStatusesDeprecatedValue } from './common/utils/status-field-transformer.tsx';
import { transformAggResponseSubtasksConfig } from './common/utils/subtasks-config-transformer.tsx';
import { transformAggResponseToTimeTrackingConfig } from './common/utils/time-tracking-config-transformer.tsx';

const TIME_TO_TRANSFORM_AGG = 'issue-agg-field-transformers';
const timeToTransformAgg = metrics.custom({
	key: TIME_TO_TRANSFORM_AGG,
});

export const ISSUE_FIELDS_NOT_AVAILABLE = 'Issue fields are not available';

const transformAgile = (data: AggResponseData): Agile => {
	// epicLinkFieldId is a temporary solution until GIC supports the unified parent creation.
	const epicKey = data.jira?.epicLinkFieldKey || '';

	return {
		epic: { key: epicKey },
	};
};

// This function transforms AGG issue-view data to the legacy GraphQL format so we can feed it to the Redux store
// @ExportForTesting
export const transformAggToLegacyGraphql = (
	data: AggResponseData,
): TransformedDataToLegacyGraphql => {
	const issue = data.jira.issueByKey;

	if (!issue?.fields) {
		throw new Error(ISSUE_FIELDS_NOT_AVAILABLE);
	}

	const fieldsEdges = issue?.fields?.edges;

	const project = transformAggProjectToProjectContext(fieldsEdges);
	const isNextGenProject = project.isSimplified === true;

	const hierarchyLevelFields = transformAggIssueToHierarchyLegacyFields(issue);

	const fields = transformAggFieldsToLegacyFields(fieldsEdges);
	const parentFields = transformAggFieldsToLegacyParentFields(fieldsEdges);

	const { fieldsState: childrenIssueFields, childIssueMeta } =
		transformAggResponseToLegacyChildIssues(data, {
			issueHierarchyLevel: fields.issuetype?.value.hierarchyLevel ?? 0,
			isNextGenProject,
		});

	const { childrenIssues, subtasks } = transformServerChildIssueFieldsToAssociatedIssues(
		childrenIssueFields,
		{
			isTeamManagedProject: isNextGenProject,
			childIssueMeta,
		},
	);

	const agile = transformAgile(data);

	const issueId = issue?.issueId || '';
	const viewScreenId = issue?.screenId;
	const viewScreen = viewScreenId === null ? null : { viewScreenId };

	let isAiEnabledForIssue = {};
	if (
		/**
		 * The flag below corresponds with JIV-19348 where to unblock fixing a performance issue
		 * we are re-using an existing flag that is fresh and has no other usages.
		 * This flag is used to fetch isAIEnabled in the mainAggQuery
		 * LaunchDarkly flags are unable to be created, and Statsig is unsupported in the early script.
		 * issue.details.gira-agg-consistency-check-master-flag
		 */
		getMultivariateFeatureFlag('issue.details.gira-agg-consistency-check-master-flag', 'OLD', [
			'OLD',
			'NEW',
			'CHECK',
			'CHECK_RETURN_NEW',
		]) === 'NEW'
	) {
		const systemProject = getSystemAggJiraProject(fieldsEdges);
		isAiEnabledForIssue = {
			isAiEnabledForIssue:
				'isAIEnabled' in systemProject ? systemProject.isAIEnabled || false : false,
		};
	} else if (fg('jira-ai-issue-view-improve-issues-button')) {
		isAiEnabledForIssue = {
			isAiEnabledForIssue: data.jira.isAiEnabledForIssue,
		};
	}

	return {
		childIssuesLimit: data.jira.childIssuesLimit,
		jpdDeliveryIssueLinkTypeId: data.jira.jpdDeliveryIssueLinkTypeId,
		...isAiEnabledForIssue,
		project,
		issue: {
			fields: {
				...fields,
				...childrenIssueFields,
				...hierarchyLevelFields,
				...parentFields,
			},
			id: issueId,
			agile,
			tabs: {}, // Tabs information is coming from containersByType API in Gira instead
			...viewScreen,
		},
		createdDate: fields.created?.value || null,
		updatedDate: fields.updated?.value || null,
		statuses: getStatusesDeprecatedValue(fields),
		issueLinks: transformAggResponseToIssueLinks(data),
		subtasks,
		childrenIssues,
		parentIssue: transformServerParentFieldsToParentIssue(parentFields),
	};
};

export const transformAggResponseToLegacyGraphql = (data: AggResponseData) => {
	if (data === undefined || data === null) return null;

	try {
		if (isInSample()) {
			timeToTransformAgg.start();
		}
		const legacyGraphqlFields = transformAggToLegacyGraphql(data);
		timeToTransformAgg.stop();

		return legacyGraphqlFields;
	} catch (error: unknown) {
		timeToTransformAgg.cancel();
		throw error;
	}
};

const transformAggJiraSettingsToLegacyGira = (aggData: AggResponseData) => ({
	subtasksConfiguration: transformAggResponseSubtasksConfig(aggData),
	timeTrackingConfiguration: transformAggResponseToTimeTrackingConfig(aggData),
	issueLinkTypes: transformAggResponseToIssueLinkTypes(aggData),
});

const TIME_TO_TRANSFORM_AGG_TO_GIRA = 'issue-agg-to-gira-transform';

const timeToTransformAggToLegacyGira = metrics.custom({
	key: TIME_TO_TRANSFORM_AGG_TO_GIRA,
});

// This function transforms AGG issue-view data to the legacy Gira format so we can feed it to the Redux store
// @ExportForTesting
export const transformAggResponseToLegacyGira = (
	aggData: AggResponseData,
	projectKey: ProjectKey,
): TransformedDataToLegacyGira => {
	try {
		if (isInSample()) {
			timeToTransformAggToLegacyGira.start();
		}

		const transformedData = {
			jiraSettings: transformAggJiraSettingsToLegacyGira(aggData),
			isArchived: transformAggIsArchivedToLegacyGira(aggData),
			myPreferences: transformAggUserPreferencesToLegacyGira(aggData, projectKey),
			activitySortOrder: transformAggUserPreferencesToLegacyGiraActivityFeedSortOrder(aggData),
			attachments: transformAggAttachmentsToLegacyGira(aggData),
			...(ff('jiv-14067-issue.details.gira-agg-migration-comments_pzao9') ||
			ff('issue-jiv-14530-comments-consistency-check_x716d')
				? {
						comments: transformAggCommentsToLegacyGira(aggData),
					}
				: {}),
		};

		const issueId = aggData?.jira.issueByKey?.issueId;
		timeToTransformAggToLegacyGira.stop({
			customData: {
				issueId: issueId || '',
			},
		});

		return transformedData;
	} catch (error: unknown) {
		timeToTransformAgg.cancel();
		throw error;
	}
};
