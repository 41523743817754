import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	buttonLabel: {
		id: 'ai-work-breakdown.button-label',
		defaultMessage:
			'Suggest {issueType, select, tasks {subtasks} issues {child issues} other {child issues}}',
		description: 'Button text that triggers generating issues.',
	},
	buttonDefaultTooltipContent: {
		id: 'ai-work-breakdown.button-default-tooltip-content',
		defaultMessage:
			'Ask Atlassian Intelligence to suggest {issueType, select, tasks {subtasks} issues {child issues} other {child issues}} for you.',
		description: 'Tooltip button content that notifies the user what the button is for.',
	},
	buttonModalViewTooltipContent: {
		id: 'ai-work-breakdown.button-modal-view-tooltip-content',
		defaultMessage:
			'Ask Atlassian Intelligence to suggest {issueType, select, tasks {subtasks} issues {child issues} other {child issues}} for you. This will open in a full page view.',
		description:
			'Tooltip button content for modal view that notifies the user that they will be redirected to full page view.',
	},
	placeholderTextNonFinal: {
		id: 'ai-work-breakdown.placeholder-text-non-final',
		defaultMessage: 'Tell us how you want your issues generated, or hit Enter.',
		description:
			'Placeholder text in the Issue Breakdown prompt. This message is not yet finalised and will not be translated until the nonfinal suffix is removed from the message id',
	},

	footerTextPromptNonFinal: {
		id: 'ai-work-breakdown.footer-text-prompt-non-final',
		defaultMessage: 'Powered by Atlassian Intelligence',
		description:
			'Footer text in the Issue Breakdown prompt. This message is not yet finalised and will not be translated until the nonfinal suffix is removed from the message id',
	},
	labelCancel: {
		id: 'ai-work-breakdown.label-cancel',
		defaultMessage: 'Cancel',
		description:
			'CancelEsc\'s "Cancel" button label. This message is not yet finalised and will not be translated until the nonfinal suffix is removed from the message id',
	},
	acceptAll: {
		defaultMessage: 'Accept all',
		description: 'Text in button that will accept all ai suggested child issues',
		id: 'ai-work-breakdown.accept-all',
	},
	successFlagTitle: {
		id: 'ai-work-breakdown.success-flag-title',
		defaultMessage: 'Issues successfully created',
		description: 'When issues are created - title.',
	},
	successFlagDescription: {
		id: 'ai-work-breakdown.success-flag-description',
		defaultMessage: 'Your issues have been successfully created',
		description: 'When issues are create - description.',
	},
	errorFlagTitleOnItemNonFinal: {
		id: 'ai-work-breakdown.error-flag-title-on-item-non-final',
		defaultMessage: 'Project or issue ID was not provided.',
		description:
			'Appears when a project or issue id is not provided when clicking a suggested item.',
	},
	errorFlagDescriptionNonFinal: {
		id: 'ai-work-breakdown.error-flag-description-non-final',
		defaultMessage: 'Please try again later.',
		description: 'Generic description for the flag when an error occurs on issue breakdown.',
	},
	statusRetrievingSourceContextNonFinal: {
		id: 'ai-work-breakdown.status-retrieving-source-context-non-final',
		defaultMessage: 'Atlassian Intelligence is reading the parent issue...',
		description: 'Status description when loading on issue breakdown.',
	},
	statusRefiningSuggestedIssuesNonFinal: {
		id: 'ai-work-breakdown.status-refining-suggested-issues-non-final',
		defaultMessage: 'Atlassian Intelligence is refining suggestions...',
		description: 'Status description when loading on issue breakdown.',
	},
	statusSuggestingInitialIssuesNonFinal: {
		id: 'ai-work-breakdown.status-suggesting-initial-issues-non-final',
		defaultMessage: 'Atlassian Intelligence is generating suggestions...',
		description: 'Status description when loading on issue breakdown.',
	},
	statusRemovingDuplicateIssuesNonFinal: {
		id: 'ai-work-breakdown.status-removing-duplicate-issues-non-final',
		defaultMessage: 'Atlassian Intelligence is removing duplicates...',
		description: 'Status description when loading on issue breakdown.',
	},
	statusReformatingIssuesNonFinal: {
		id: 'ai-work-breakdown.status-reformating-issues-non-final',
		defaultMessage: 'Atlassian Intelligence is writing descriptions...',
		description: 'Status description when loading on issue breakdown.',
	},
	statusGeneratingDefaultMessageNonFinal: {
		id: 'ai-work-breakdown.status-generating-default-message-non-final',
		defaultMessage: 'Generating issue suggestions',
		description: 'Default status message text in the Issue Breakdown when generating child issues.',
	},
	createErrorFlagTitle: {
		id: 'ai-work-breakdown.create-error-flag-title',
		defaultMessage: "Couldn't create the issue",
		description: 'Title for error flag when issue create fails',
	},
	createErrorFlagDescription: {
		id: 'ai-work-breakdown.create-error-flag-description',
		defaultMessage: "We're having some trouble creating the issue. Refresh the page and try again.",
		description: 'Description for error flag when issue create fails',
	},
	suggestErrorMessageTitle: {
		defaultMessage: 'These suggestions have required fields',
		description: 'Error message title shown when suggested child issues have required fields',
		id: 'ai-work-breakdown.suggest-error-message-title',
	},
	suggestErrorMessageBody: {
		defaultMessage: 'Edit each suggestion and complete the required fields to create the issue',
		description: 'Error message text shown when suggested child issues have required fields',
		id: 'ai-work-breakdown.suggest-error-message-body',
	},
	bottomInputPrompt: {
		defaultMessage: 'What should I do next?',
		description:
			'Input prompt asking user for next AI prompt to refine ai work breakdown draftlist',
		id: 'ai-work-breakdown.bottom-input-prompt',
	},
});
