import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { animationFadeIn } from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import PinUnfilled from '@atlassian/jira-issue-field-pin/src/assets/pin-unfilled.tsx';
import {
	FIELD_PIN_BUTTON_COMPONENT_SELECTOR,
	UNPINNED_ICON_WRAPPER_COMPONENT_SELECTOR,
} from '@atlassian/jira-issue-field-pin/src/utils.tsx';

export const pinnedFieldHighlightWrapperSelectorName =
	'jira-issue-view-layout-templates-pinned-fields-banner-with-grouping-pinned-field-highlight-wrapper';
const PINNED_FIELD_HIGHLIGHT_WRAPPER_COMPONENT_SELECTOR = `[data-component-selector="${pinnedFieldHighlightWrapperSelectorName}"]`;

// eslint-disable-next-line @atlaskit/design-system/no-empty-styled-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PinnedFieldHighlightWrapper = styled.div({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BannerWrapper = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	marginBottom: token('space.100', '8px'),
	padding: `${token('space.100', '8px')} ${token('space.100', '8px')} ${token(
		'space.100',
		'8px',
	)} ${token('space.150', '12px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	border: `1px solid ${token('color.border', colors.N40)}`,
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`&:hover ~ ${PINNED_FIELD_HIGHLIGHT_WRAPPER_COMPONENT_SELECTOR} ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}`]:
		{
			visibility: 'visible',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			animation: `${animationFadeIn} 0.15s linear forwards`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			[`& ${UNPINNED_ICON_WRAPPER_COMPONENT_SELECTOR}`]: {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: token('color.icon', colors.N800),
			},
		},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BannerHeader = styled.div({
	marginTop: token('space.050', '4px'),
	marginBottom: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BannerMessage = styled.div({
	marginBottom: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BannerButtonContainer = styled.div({
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PinUnfilledStyled = styled(PinUnfilled)({
	verticalAlign: 'text-bottom',
});

/** empty div to stop contents flexing in the box */
// eslint-disable-next-line @atlaskit/design-system/no-empty-styled-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BannerWrapperRow = styled.div({});
