import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import defaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options.tsx';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { SessionId } from '@atlassian/jira-issue-analytics/src/common/types.tsx';
import {
	fireSuggestionsRequestSuccessEvent,
	fireSuggestionsRequestFailedEvent,
} from '@atlassian/jira-issue-analytics/src/services/suggestions/index.tsx';
import { RECOMMENDATIONS_LOCATION, RECOMMENDATIONS_URL } from '../common/constants.tsx';
import type {
	CollaborationGraphResponse,
	RecommendationsContext,
	RecommendationsExperience,
	SmartsKeyValue,
} from './types.tsx';

/**
 * Executes two promises and returns the result of the earliest Promise to resolve based on a timeout.
 *
 * @param thunk - Function that returns a promise. This will be raced against a default promise with the timeout passed.
 * @param defaultValue - Value that will be resolved if the thunk resolves slower than the timeout.
 * @param timeout - Timeout in milliseconds
 * @param fieldId - Identifier for the field used to fire analytics events if the default promise resolves first.
 * @param [sessionId] - Unique identifier for the session.
 * @param [createAnalyticsEvent] - Function used to create the analytics events
 *
 * @returns Promise that returns either the value from the thunk or the default value.
 */
export const executeWithTimeout = <Value,>(
	thunk: () => Promise<Value>,
	defaultValue: Value,
	timeout: number,
	fieldId: string,
	sessionId?: SessionId,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): Promise<Value> => {
	let id: ReturnType<typeof setTimeout>;

	const defaultPromise = new Promise<Value>((resolve) => {
		id = setTimeout(() => {
			log.safeWarnWithoutCustomerData(
				RECOMMENDATIONS_LOCATION,
				`CG has timeout after ${timeout}ms`,
			);
			fireSuggestionsRequestFailedEvent(fieldId, timeout, createAnalyticsEvent, {
				sessionId,
			});
			resolve(defaultValue);
		}, timeout);
	});

	const frsResponsePromise = thunk().then((value) => {
		if (id) {
			clearTimeout(id);
		}
		return value;
	});

	return Promise.race([defaultPromise, frsResponsePromise]);
};

/**
 * Function that calls the Collaboration Graph API returning recommendations for an Issue field based on a context list of key-value pairs.
 *
 * See {@link https://developer.atlassian.com/platform/collaboration-graph/rest/api-group-collaboration-graph-recommendations-api/#api-v2-recommend-fields-post}
 *
 * @param context - Request context passed to the Collaboration Graph API.
 * @param experience - Indicates where Collaboration Graph is being applied. It will be used for model selection and analytics.
 * @param sessionId - Unique identifier for the session.
 * @param createAnalyticsEvent - Function used to create the analytics events for the recommendations request.
 *
 * @returns Promise that returns the sorted context list.
 */
export const fetchRecommendations = async (
	context: RecommendationsContext,
	experience: RecommendationsExperience,
	sessionId?: SessionId,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): Promise<SmartsKeyValue[]> => {
	const { cloudId, contextList, fieldId, userId, issueId, projectId } = context;

	const startTime = performance.now();

	try {
		const { recommendedEntities } = await fetchJson<CollaborationGraphResponse>(
			RECOMMENDATIONS_URL,
			{
				...defaultOptions,
				method: 'POST',
				body: JSON.stringify({
					context: {
						fieldId,
						userId,
						product: 'jira',
						additionalContextList: contextList,
						containerId: projectId,
						objectId: issueId,
						tenantId: cloudId,
					},
					modelRequestParams: {
						experience,
						caller: 'jira',
					},
					requestingUserId: context.userId,
					sessionId,
				}),
			},
		);

		fireSuggestionsRequestSuccessEvent(fieldId, startTime, createAnalyticsEvent, {
			sessionId,
		});

		// Collaboration Graph returns entities by the order it was passed, so we need to sort them by score in descending order
		recommendedEntities.sort((a, b) => b.score - a.score);

		return recommendedEntities.map<SmartsKeyValue>(({ id, value }) => ({ value, key: id }));
	} catch (error) {
		fireSuggestionsRequestFailedEvent(fieldId, startTime, createAnalyticsEvent, {
			sessionId,
		});

		log.safeErrorWithoutCustomerData(
			RECOMMENDATIONS_LOCATION,
			`error while ranking ${fieldId} via CG`,
			error instanceof Error ? error : undefined,
		);

		return contextList;
	}
};
