import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	suggestConfluencePages: {
		id: 'ai-related-resources.suggest-confluence-pages',
		defaultMessage: 'Suggest Confluence pages',
		description: 'Prompt indicating that the AI is suggesting confluence pages',
	},
	discardSuggestions: {
		id: 'ai-related-resources.discard-suggestions',
		defaultMessage: 'Discard suggestions',
		description: 'Message to indicate that the button can initiate an action to descard suggestion',
	},
	relatedResourcesHeading: {
		id: 'ai-related-resources.related-resources-heading',
		defaultMessage: 'Related Resources',
		description: 'Title for Related resources',
	},
	generatingPageSuggestions: {
		id: 'ai-related-resources.generating-page-suggestions',
		defaultMessage: 'Generating page suggestions',
		description: 'Text shown when AI is generating suggestions for pages related to the jira issue',
	},
	pageSuggestionsCouldNotBeGenerated: {
		id: 'ai-related-resources.page-suggestions-could-not-be-generated-non-final',
		defaultMessage:
			"Atlassian Intelligence couldn't suggest confluence pages relevant for this issue ",
		description: 'Message shown when AI cannot suggest pages relevant to the issue',
	},
});
