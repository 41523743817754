import { statusNone } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	type Action,
	SHOW_INLINE_CREATE,
	HIDE_ADD_CHILD_ISSUE,
	SET_CHILD_PANEL_VIEW,
	CHILD_PANEL_VIEWS,
	SET_CHILD_CREATE_SUMMARY,
	TOGGLE_HIDE_DONE,
	SELECTED_ISSUE_TYPE_CHANGED,
	SET_FETCH_ISSUE_TYPES_STATUS,
} from './actions.tsx';
import type { UiState } from './types.tsx';

const defaultState: UiState = {
	addClickCount: 0,
	hideDone: false,
	selectedIssueType: null,
	childPanelView: CHILD_PANEL_VIEWS.InlineCreate,
	childCreateSummary: '',
	fetchIssueTypesStatus: statusNone,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: UiState = defaultState, action: Action): UiState => {
	switch (action.type) {
		case SHOW_INLINE_CREATE:
			return {
				...state,
				addClickCount: state.addClickCount + 1,
			};

		case HIDE_ADD_CHILD_ISSUE:
			return {
				...state,
				addClickCount: 0,
				childPanelView: CHILD_PANEL_VIEWS.InlineCreate,
				childCreateSummary: '',
			};

		case SET_CHILD_PANEL_VIEW:
			return {
				...state,
				// @ts-expect-error - TS2339 - Property 'view' does not exist on type '{ type: "state.ui.SET_CHILD_PANEL_VIEW"; view: string; } | { type: string; payload: FetchIssueTypesStatusState; }'.
				childPanelView: action.view,
			};

		case SET_CHILD_CREATE_SUMMARY:
			return {
				...state,
				// @ts-expect-error - TS2339 - Property 'summary' does not exist on type '{ type: "state.ui.SET_CHILD_CREATE_SUMMARY"; summary: string; } | { type: string; payload: FetchIssueTypesStatusState; }'.
				childCreateSummary: action.summary,
			};

		case TOGGLE_HIDE_DONE:
			return {
				...state,
				hideDone: !state.hideDone,
			};

		case SELECTED_ISSUE_TYPE_CHANGED:
			return {
				...state,
				// @ts-expect-error - TS2322 - Type 'FetchIssueTypesStatusState | ChildIssueType' is not assignable to type 'ChildIssueType | null'.
				selectedIssueType: action.payload,
			};

		case SET_FETCH_ISSUE_TYPES_STATUS:
			return {
				...state,
				fetchIssueTypesStatus: action.payload,
			};

		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(action as never);
			return state;
	}
};
