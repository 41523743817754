import defaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options-with-strict-mode.tsx';
import { performPutRequest, performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ResponseSuccess as UserFetched } from '@atlassian/jira-user-services/src/types.tsx';

export type CurrentAssignee = {
	// @ts-expect-error - TS2411 - Property 'fields' of type '{ assignee: (Partial<Diff<ResponseSuccess, Record<any, any>>> & { accountId: string | null; displayName: string; avatarUrls: { readonly "48x48"?: string | undefined; readonly "32x32"?: string | undefined; readonly "24x24"?: string | undefined; readonly "16x16"?: string | undefined; }; }) | null; }' is not assignable to 'string' index type 'string'.
	fields: {
		assignee:
			| // make all UserFetched fields optionnal
			(Partial<UserFetched> & {
					// only those ones are mandatory
					accountId: string | null;
					displayName: string;
					avatarUrls: {
						readonly ['48x48']?: string;
						readonly ['32x32']?: string;
						readonly ['24x24']?: string;
						readonly ['16x16']?: string;
					};
			  })
			| null;
	};
	[key: string]: string;
};

export const getAssignIssueUrl = (baseUrl: string, issueKey: string, sessionId?: string) =>
	sessionId
		? `${baseUrl}/rest/api/3/issue/${issueKey}/assignee?sessionId=${sessionId}`
		: `${baseUrl}/rest/api/3/issue/${issueKey}/assignee`;

// a null accountId tells the server we want the issue to be unassigned.
export const assignIssue = (
	baseUrl: string,
	issueKey: string,
	accountId: string | null,
	sessionId?: string,
): Promise<null> =>
	performPutRequest(getAssignIssueUrl(baseUrl, issueKey, sessionId), {
		...defaultOptions,
		body: JSON.stringify({
			accountId,
		}),
	});

export const getCurrentAssignee = (baseUrl: string, issueKey: string, sessionId?: string) =>
	sessionId
		? `${baseUrl}/rest/api/3/issue/${issueKey}?fields=assignee&sessionId=${sessionId}`
		: `${baseUrl}/rest/api/3/issue/${issueKey}?fields=assignee`;

// a -1 accountId tells the server we want the issue to be automatic so this is used to fetch actual assignee.
export const currentAssignee = (
	baseUrl: string,
	issueKey: string,
	sessionId?: string,
): Promise<CurrentAssignee> =>
	performGetRequest(getCurrentAssignee(baseUrl, issueKey, sessionId), {
		...defaultOptions,
	});
