type KnownAnalyticSourceIds = 'confluenceTitleToolbar' | string;

export type Unsubscribe = () => void;

export type ConfigType = 'rangeConfig' | 'emptyConfig';

const EventHubEvent = {
	StartPrompt: 'start prompt',
	ReplaceConfluenceTitle: 'replace title in confluence',
} as const;

export type EventHubEventUnionType = (typeof EventHubEvent)[keyof typeof EventHubEvent];

type BaseData = {
	analyticSourceId: KnownAnalyticSourceIds;
};

type ToEditorBaseData = BaseData & {
	targetEditorId: string | 'global';
};

type FromEditorBaseData = BaseData & {
	sourceEditorId: string;
};

export const EventHubPrompt = {
	SuggestTitle: 'suggest title in confluence title toolbar',
	CustomPrompt: 'custom prompt',
	IssueReformatter: 'issue reformatter',
	Summarize: 'summarize',
	ImproveWriting: 'improve writing',
	MakeShorter: 'make shorter',
	FixSpellingGrammar: 'fix spelling and grammar',
	ChangeToneToProfessional: 'change tone to professional',
	ChangeToneToNeutral: 'change tone to neutral',
	ChangeToneToEmpathetic: 'change tone to empathetic',
	ChangeToneToCasual: 'change tone to casual',
	ChangeToneToEducational: 'change tone to educational',
} as const;

export type AdjustSelectionInstruction = 'fullDocument' | 'keepCurrent';

type BaseStartPromptData = ToEditorBaseData & {
	adjustSelection?: AdjustSelectionInstruction;
};

export type StartPromptData = BaseStartPromptData &
	(
		| {
				prompt: (typeof EventHubPrompt)['SuggestTitle'];
		  }
		| {
				prompt: (typeof EventHubPrompt)['CustomPrompt'];
				promptText: string;
		  }
		| {
				prompt: (typeof EventHubPrompt)['IssueReformatter'];
				// this will always currently use the rangeConfig (i.e. fullDocument)
				// selectioning behaviour, so should not be set by consumers
				adjustSelection?: never;
		  }
		| {
				prompt: (typeof EventHubPrompt)['Summarize'];
		  }
		| {
				prompt: (typeof EventHubPrompt)['ImproveWriting'];
		  }
		| {
				prompt: (typeof EventHubPrompt)['MakeShorter'];
		  }
		| {
				prompt: (typeof EventHubPrompt)['FixSpellingGrammar'];
		  }
		| {
				prompt: (typeof EventHubPrompt)['ChangeToneToProfessional'];
		  }
		| {
				prompt: (typeof EventHubPrompt)['ChangeToneToNeutral'];
		  }
		| {
				prompt: (typeof EventHubPrompt)['ChangeToneToEmpathetic'];
		  }
		| {
				prompt: (typeof EventHubPrompt)['ChangeToneToCasual'];
		  }
		| {
				prompt: (typeof EventHubPrompt)['ChangeToneToEducational'];
		  }
	);

export type ReplaceConfluenceTitleData = FromEditorBaseData & { title: string };

export type ToEditorEventDataMap = {
	[EventHubEvent.StartPrompt]: StartPromptData;
};

export type FromEditorEventDataMap = {
	[EventHubEvent.ReplaceConfluenceTitle]: ReplaceConfluenceTitleData;
};

export type EventDataMap = ToEditorEventDataMap & FromEditorEventDataMap;

export type SubscribeParams<T extends keyof EventDataMap> = {
	event: T;
	callback: (data: EventDataMap[T]) => void;
};
