import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useDesignPanelStore } from '@atlassian/jira-issue-design-section-store/src/index.tsx';
import { VIEW_DESIGN_SECTION_EXPERIENCE } from '@atlassian/jira-issue-designs-observability/src/services/constants.tsx';
import {
	IssueDesignsExperienceStart,
	useIssueDesignsExperienceFail,
} from '@atlassian/jira-issue-designs-observability/src/services/index.tsx';
import type { ui_issueDesignSection_DesignPanelWrapper_issueViewRelayFragment$key } from '@atlassian/jira-relay/src/__generated__/ui_issueDesignSection_DesignPanelWrapper_issueViewRelayFragment.graphql';
import type { ui_issueDesignSection_DesignPanelWrapper_rootRelayFragment$key } from '@atlassian/jira-relay/src/__generated__/ui_issueDesignSection_DesignPanelWrapper_rootRelayFragment.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useBackfillRequiredDesigns } from '../services/fetch-backfill-required-designs/index.tsx';
import DesignPanel from './panel/index.tsx';

export type DesignPanelProps = {
	isExpanded: boolean;
	rootRelayFragment: ui_issueDesignSection_DesignPanelWrapper_rootRelayFragment$key;
	issueAri: string;
	issueViewRelayFragment: ui_issueDesignSection_DesignPanelWrapper_issueViewRelayFragment$key;
};

const DesignPanelWrapper = ({
	isExpanded,
	rootRelayFragment,
	issueAri,
	issueViewRelayFragment,
}: DesignPanelProps) => {
	const issueData =
		useFragment<ui_issueDesignSection_DesignPanelWrapper_issueViewRelayFragment$key>(
			graphql`
				fragment ui_issueDesignSection_DesignPanelWrapper_issueViewRelayFragment on JiraIssue {
					...panel_issueDesignSection_DesignPanel_issueViewRelayFragment
					issueId
					attachedDesignUrlV1: issuePropertyByKey(key: "attached-design-url")
					attachedDesignUrlV2: issuePropertyByKey(key: "attached-design-url-v2")
					designs @optIn(to: "GraphStoreIssueAssociatedDesign") {
						edges {
							node {
								... on DevOpsDesign {
									url
									__typename
								}
							}
						}
					}
				}
			`,
			issueViewRelayFragment,
		);

	const devOpsData = useFragment<ui_issueDesignSection_DesignPanelWrapper_rootRelayFragment$key>(
		graphql`
			fragment ui_issueDesignSection_DesignPanelWrapper_rootRelayFragment on Query {
				...panel_issueDesignSection_DesignPanel_rootRelayFragment
				devOps @optIn(to: "DevOpsSecurityInJira") {
					providers(id: $siteAri, providerTypes: [DESIGN]) {
						designProviders {
							supportedActions {
								checkAuth
								getEntityByUrl
							}
						}
					}
				}
			}
		`,
		rootRelayFragment,
	);

	const viewDesignSectionExperienceFail = useIssueDesignsExperienceFail({
		experience: VIEW_DESIGN_SECTION_EXPERIENCE,
	});

	const designEdges = useMemo(
		() => (issueData.designs?.edges ?? []).filter(Boolean),

		[issueData.designs?.edges],
	);
	const designData = useMemo(
		() => designEdges.map((edge) => edge.node).filter(Boolean),
		[designEdges],
	);

	/**
	 *  We only want to do backfill calculation on SPA issue transitions + initial issue load (manually opening an issue)
	 *  Otherwise, when mutations change the edge data or issue property data, the backfill logic will re-run with
	 *  buggy side-effects
	 */
	const designUrlsOnIssueLoad = useMemo(
		() =>
			designData
				.map((design) => (design.__typename === 'DevOpsDesign' ? design.url : null))
				.filter(Boolean),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[issueData.issueId],
	);
	const attachedDesignUrlV1OnIssueLoad = useMemo(
		() => issueData.attachedDesignUrlV1,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[issueData.issueId],
	);
	const attachedDesignUrlV2OnIssueLoad = useMemo(
		() => issueData.attachedDesignUrlV2,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[issueData.issueId],
	);

	const { backfillRequiredDesigns, clearBackfillRequiredDesigns, hasBackfillFinishedParsing } =
		useBackfillRequiredDesigns({
			attachedDesignUrlV1: attachedDesignUrlV1OnIssueLoad,
			attachedDesignUrlV2: attachedDesignUrlV2OnIssueLoad,
			designUrls: designUrlsOnIssueLoad,
		});

	const hasDesignsRequiringBackfill = backfillRequiredDesigns.length > 0;
	// Note: to be more safe we should check whether it's the same provider the designs-to-backfill are associated with,
	//  but I think it's ok to go with a "exist" check given the facts 1) there are many places in code assuming "figma" provider 2) we're getting rid of backfill soon
	const hasBackfillableDesignProvider =
		devOpsData.devOps?.providers?.designProviders?.some(
			(provider) =>
				provider?.supportedActions?.getEntityByUrl && provider?.supportedActions?.checkAuth,
		) ?? false;

	const shouldShowBackfillCard = hasDesignsRequiringBackfill && hasBackfillableDesignProvider;

	const hasDesignCard = designData.length > 0 || shouldShowBackfillCard;

	const [{ shouldShowAddDesignForm }] = useDesignPanelStore();

	const shouldShowDesignPanel = hasDesignCard || shouldShowAddDesignForm;

	return hasBackfillFinishedParsing && shouldShowDesignPanel ? (
		<UFOSegment name="issue-designs-panel">
			<IssueDesignsExperienceStart
				experience={VIEW_DESIGN_SECTION_EXPERIENCE}
				experienceId={VIEW_DESIGN_SECTION_EXPERIENCE}
			/>
			<JSErrorBoundary
				id="designPanelWrapper"
				packageName="jiraIssueDesignSection"
				teamName="helios-headcoach"
				onError={(location, error, errorAttributes) => {
					viewDesignSectionExperienceFail(location, error, {
						errorMessage: error.message,
						errorHash: errorAttributes.hash,
						teamName: errorAttributes.teamName,
					});
				}}
				fallback="unmount"
			>
				<DesignPanel
					issueViewRelayFragment={issueData}
					rootRelayFragment={devOpsData}
					isExpanded={isExpanded}
					issueAri={issueAri}
					backfillRequiredDesigns={backfillRequiredDesigns}
					onBackfillComplete={() => clearBackfillRequiredDesigns()}
				/>
			</JSErrorBoundary>
		</UFOSegment>
	) : null;
};

export default DesignPanelWrapper;
