import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { getColorMetadata } from '../color-picker/utils/index.tsx';

export type Props = {
	name: string;
	color: string | null;
	isInteractive?: boolean;
};

export const OptionTag = memo(({ name, color, isInteractive = true }: Props) => {
	const { textColor, backgroundColor: tagColor } = getColorMetadata(color);

	return (
		<Box
			xcss={[tagWrapperStyles, isInteractive && interactiveStyles]}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ color: isVisualRefreshEnabled() ? token('color.text') : textColor }}
			aria-label={name}
			testId="option-color-picker.option-tag.field-tag-wrapper"
		>
			<StyledTag color={tagColor}>{name}</StyledTag>
		</Box>
	);
});

const tagWrapperStyles = xcss({
	display: 'inline-flex',
	justifyContent: 'left',
	alignItems: 'center',
	transition: '0.3s',
	maxWidth: '100%',
	minWidth: '0',
	minHeight: '0',
	color: 'color.text',
});
const interactiveStyles = xcss({
	':hover': {
		cursor: 'pointer',
	},
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTagBase = styled.div<{ color: string }>({
	font: token('font.body'),
	lineHeight: '14px',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTagOld = styled(StyledTagBase)<{ color: string }>({
	borderRadius: token('border.radius.100', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ color }) => color,
	padding: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTagNew = styled(StyledTagBase)<{ color: string }>({
	borderRadius: token('border.radius.050', '3px'),
	backgroundColor: 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	border: ({ color }) => `1px solid ${color}`,
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
});

const StyledTag = componentWithCondition(isVisualRefreshEnabled, StyledTagNew, StyledTagOld);
