import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RespondersGlancePanelProps } from './common/types/index.tsx';
import type { RespondersInlineView as SyncRespondersInlineView } from './ui/inline/index.tsx';
import type { Props as RespondersInlineViewProps } from './ui/inline/types.tsx';
import type { RespondersGlancePanel as SyncRespondersGlancePanel } from './ui/responders-glance-panel/index.tsx';
import type { RespondersGlance as SyncRespondersGlance } from './ui/responders-glance/index.tsx';
import type { Props as RespondersGlanceProps } from './ui/responders-glance/types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const RespondersGlanceComponent = lazyForPaint<typeof SyncRespondersGlance>(
	() => import(/* webpackChunkName: "async-responders-glance" */ './ui/responders-glance'),
	{ ssr: false },
);

export const RespondersGlance = (props: RespondersGlanceProps) => (
	<ErrorBoundary id="async.responders.glance" packageName="atlassian/jira-responders">
		<Placeholder name="responders-glance-component" fallback={null}>
			<RespondersGlanceComponent {...props} />
		</Placeholder>
	</ErrorBoundary>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const RespondersGlancePanelComponent = lazyForPaint<typeof SyncRespondersGlancePanel>(
	() =>
		import(/* webpackChunkName: "async-responders-glance-panel" */ './ui/responders-glance-panel'),
	{ ssr: false },
);

export const RespondersGlancePanel = (props: RespondersGlancePanelProps) => (
	<ErrorBoundary id="async.responders.glance.panel" packageName="atlassian/jira-responders">
		<Placeholder name="responders-glance-panel-component" fallback={null}>
			<RespondersGlancePanelComponent {...props} />
		</Placeholder>
	</ErrorBoundary>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const RespondersInlineViewComponent = lazyForPaint<typeof SyncRespondersInlineView>(
	() => import(/* webpackChunkName: "async-responders-inline-view" */ './ui/inline'),
	{ ssr: false },
);

export const RespondersInlineView = (props: RespondersInlineViewProps) => (
	<ErrorBoundary id="async.responders.inline.view" packageName="atlassian/jira-responders">
		<Placeholder name="responders-glance-inline-view-component" fallback={null}>
			<RespondersInlineViewComponent {...props} />
		</Placeholder>
	</ErrorBoundary>
);
