import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useHandleAuthorization } from '@atlassian/jira-design-provider-auth-check/src/services/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { bulkUnlinkDropdownItem_issueDesignSection$key } from '@atlassian/jira-relay/src/__generated__/bulkUnlinkDropdownItem_issueDesignSection.graphql';
import messages from './messages.tsx';

interface Props {
	provider: bulkUnlinkDropdownItem_issueDesignSection$key;
	setDesignProviderIdForConfirmationDialog: (value: string) => void;
	providerCount: number;
}

export const BulkUnlinkDropdownItem = ({
	provider,
	providerCount,
	setDesignProviderIdForConfirmationDialog,
}: Props) => {
	const designProvider = useFragment(
		graphql`
			fragment bulkUnlinkDropdownItem_issueDesignSection on DevOpsDesignProvider {
				id @required(action: THROW)
				name @required(action: THROW)
			}
		`,
		provider,
	);
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleAuthorization = useHandleAuthorization();

	return (
		<DropdownItem
			key={designProvider.id}
			onClick={() => {
				try {
					handleAuthorization({
						providerName: designProvider.name,
						providerId: designProvider.id,
						providerCount,
						onSuccess: () => {
							setDesignProviderIdForConfirmationDialog(designProvider.id);
						},
					});
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					fireErrorAnalytics({
						event: createAnalyticsEvent({
							action: 'failed',
							actionSubject: 'designProviderAuth',
						}),
						error,
						meta: {
							teamName: 'helios-headcoach',
							id: 'designProviderAuth',
						},
						attributes: {
							providerId: designProvider.id,
						},
						sendToPrivacyUnsafeSplunk: true,
					});

					showFlag({
						type: 'error',
						title: formatMessage(messages.authFailedFlagMessage),
					});
				}
			}}
		>
			{formatMessage(messages.bulkUnlinkAllButtonLabel, {
				providerName: designProvider.name,
			})}
		</DropdownItem>
	);
};
