import React, { useState } from 'react';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import {
	TASK_FAIL,
	TASK_SUCCESS,
} from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension/index.tsx';
import { fireUiTriggerClickedEvent } from '@atlassian/jira-forge-ui-analytics/src/services/issue-glance/index.tsx';
import { TriggerAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/issue-glance/index.tsx';
import { ISSUE_GLANCE_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import { ExtensionTitle } from '@atlassian/jira-forge-ui-extension-title/src/ui/index.tsx';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { Glance } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { useIssueLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { analyticsSourceSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { forgeIssueDataSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector.tsx';
import MountAnimator from '@atlassian/jira-packages-components-animations/src/mount-animator.tsx';
import { MountEvent } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	ContentContainer,
	Content,
	RoundButtonContainer,
	RoundButton,
	SlideInAnimation,
	Icon,
} from '../../common/styled-glance-view.tsx';
import { Status } from './status.tsx';

type Props = Glance & {
	source: string | undefined;
	extensionData: ForgeUiIssueData;
	onOpenGlancePanel: (appKey: string, moduleKey: string) => void;
};

export const ForgeGlance = ({
	title,
	label,
	extension,
	icon,
	source,
	appKey,
	moduleKey,
	onOpenGlancePanel,
	extensionData,
}: Props) => {
	const [isHovering, setIsHovering] = useState(false);

	const eventHandlers = {
		onMouseEnter: () => setIsHovering(true),
		onMouseLeave: () => setIsHovering(false),
		onFocus: () => setIsHovering(true),
		onBlur: () => setIsHovering(false),
	};

	const openGlancePanel = () => {
		fireUiTriggerClickedEvent(extension.id, source, { moduleKey });
		onOpenGlancePanel(appKey, moduleKey);
	};
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	const { environmentType, environmentKey } = extension;

	const commonAnalyticAttributes = {
		experience: 'issueViewForgeGlanceFieldRender',
		analyticsSource: 'issueViewForgeGlanceFieldView',
		application: application ?? null,
		edition: edition ?? null,
	};
	return (
		<ErrorBoundary
			id="issue-view.context.ecosystem-glance-field-forge"
			onError={(location, error) =>
				sendExperienceAnalytics({
					...commonAnalyticAttributes,
					wasExperienceSuccesful: false,
					action: TASK_FAIL,
					additionalAttributes: {
						location,
						errorMessage: error.message,
					},
				})
			}
		>
			<FieldWrapper>
				<FieldHeading>
					<FieldHeadingTitle>
						<ExtensionTitle
							extensionName={title}
							extensionEnvType={environmentType}
							extensionEnvKey={environmentKey}
						/>
					</FieldHeadingTitle>
				</FieldHeading>
				<SideBySideField
					/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
					data-testid="issue.views.issue-base.context.ecosystem.forge.issue-glance.field"
				>
					<EnterEscapeHandler
						onEnter={openGlancePanel}
						data-testid="issue-view-base.context.ecosystem.forge.issue-glance.enter-escape-handler"
					>
						<ContentContainer
							onClick={openGlancePanel}
							tabIndex={0}
							{...eventHandlers}
							data-testid="issue-view-base.context.ecosystem.forge.issue-glance.container"
						>
							{icon != null && (
								<Icon>
									<AsyncIcon url={icon} width={24} height={24} />
								</Icon>
							)}
							<Content>{label}</Content>
							<Status
								extension={extension}
								extensionData={extensionData}
								appKey={appKey}
								moduleKey={moduleKey}
							/>
							{isHovering && (
								<MountAnimator mountAnimation={SlideInAnimation}>
									<RoundButtonContainer>
										<RoundButton>
											<ArrowRightIcon
												color="currentColor"
												spacing="spacious"
												LEGACY_size="medium"
												label=""
											/>
										</RoundButton>
									</RoundButtonContainer>
								</MountAnimator>
							)}
						</ContentContainer>
					</EnterEscapeHandler>
				</SideBySideField>
				<ForgeScreenEvent attributes={{ source, moduleKey, moduleType: ISSUE_GLANCE_MODULE }} />
			</FieldWrapper>
			<MountEvent
				onMount={() =>
					sendExperienceAnalytics({
						...commonAnalyticAttributes,
						wasExperienceSuccesful: true,
						action: TASK_SUCCESS,
					})
				}
			/>
		</ErrorBoundary>
	);
};

export const ForgeGlanceWithIssueLayout = (props: Props) => {
	const issueKey = useIssueKey();
	const [, { setIssueViewLayoutGlance }] = useIssueLayoutActions();

	return (
		<ForgeGlance
			{...props}
			onOpenGlancePanel={(appKey, moduleKey) => {
				setIssueViewLayoutGlance(issueKey, { appKey, moduleKey });
			}}
		/>
	);
};

export const ForgeGlanceWithAnalytics = (props: Props) => (
	<TriggerAnalyticsWrapper
		attributes={{
			source: props.source,
			...getAnalyticsAttributesFromExtension(props.extension),
		}}
	>
		<ForgeGlanceWithIssueLayout {...props} />
	</TriggerAnalyticsWrapper>
);

export default flowWithSafeComponent(
	connect(
		(state) => ({
			source: analyticsSourceSelector(state),
			extensionData: forgeIssueDataSelector(state),
		}),
		{},
	),
)(ForgeGlanceWithAnalytics);
