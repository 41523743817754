import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { BackfillDesign } from '../common/types.tsx';

const tryGetDesignIdFromFigmaUrl = (url: string): string | undefined => {
	try {
		const parsedUrl = new URL(url);

		if (parsedUrl.host !== 'www.figma.com')
			throw new Error(`Received URL that is not from the Figma domain, url: ${url}`);

		const pathComponents = parsedUrl.pathname.split('/');

		const filePathComponentIndex = pathComponents.findIndex(
			(x) => x === 'file' || x === 'proto' || x === 'board' || x === 'design',
		);
		const fileKey = pathComponents[filePathComponentIndex + 1];
		if (!fileKey) throw new Error(`Received invalid Figma URL: ${url}`);

		const branchPathComponentIndex = pathComponents.findIndex((x) => x === 'branch');
		const branchFileKey =
			branchPathComponentIndex >= 0 ? pathComponents[branchPathComponentIndex + 1] : undefined;

		const nodeId = parsedUrl.searchParams.get('node-id')?.replace('-', ':');

		return [branchFileKey ?? fileKey, nodeId].filter(Boolean).join(':');
	} catch (e) {
		fireErrorAnalytics({
			meta: {
				id: 'parseFigmaUrl',
				teamName: 'helios-headcoach',
			},
			error: new Error(`Failed to parse a Figma URL. Received value: ${url}`),
			sendToPrivacyUnsafeSplunk: true,
			skipSentry: true,
		});
		return undefined;
	}
};

export const getNotIngestedDesigns = (
	issuePropertyDesigns: BackfillDesign[],
	ingestedDesignUrls: string[],
) => {
	const ingestedDesignIds = new Set(
		ingestedDesignUrls.map((designUrl) => tryGetDesignIdFromFigmaUrl(designUrl)).filter(Boolean),
	);

	const notIngestedDesignsById = new Map<string, BackfillDesign>();

	for (const issuePropertyDesign of issuePropertyDesigns) {
		const issuePropertyDesignId = tryGetDesignIdFromFigmaUrl(issuePropertyDesign.url);

		if (
			issuePropertyDesignId &&
			!notIngestedDesignsById.has(issuePropertyDesignId) &&
			!ingestedDesignIds.has(issuePropertyDesignId)
		) {
			notIngestedDesignsById.set(issuePropertyDesignId, issuePropertyDesign);
		}
	}

	return Array.from(notIngestedDesignsById.values());
};

export const extractDesignName = (designUrl: string) => {
	let parsedUrl: URL | undefined;

	try {
		parsedUrl = new URL(designUrl);
	} catch (e) {
		return undefined;
	}

	const path = parsedUrl.pathname.split('/');

	/**
	 * URLs can be in the form of /file/{fileID}/fileName or /file/{fileID}/branch/{branchID}
	 * So the fileName may be in position 3 or 5 once the pathname is split based on if the URL
	 * is a branch URL or not
	 */
	if (path.length > 3) {
		let fileName = path[3];
		if (fileName === 'branch') fileName = path[5];

		if (fileName) {
			return decodeURIComponent(fileName).replace(/-/g, ' ');
		}
	}

	return undefined;
};
