import React from 'react';
import FindOutMore from './find-out-more/index.tsx';
import StartTour from './start-tour/index.tsx';

const AddToIssueDropdownGroup = (shouldShowStartTour: boolean, shouldShowFindOutMore: boolean) => {
	const renderDropdownItems = () => [
		...(shouldShowStartTour ? [<StartTour key="startTour" />] : []),
		...(shouldShowFindOutMore ? [<FindOutMore key="findOutMore" />] : []),
	];

	return {
		name: '',
		key: 'IssueActionsDropdownGroup',
		items: renderDropdownItems(),
	};
};

export default AddToIssueDropdownGroup;
