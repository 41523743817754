import {
  JiraCommentAriResourceOwner,
  JiraCommentAriResourceType
} from "./chunk-UWONVETB.mjs";
import {
  RegisteredAri
} from "./chunk-CLQNXEWF.mjs";
import {
  AriParser
} from "./chunk-HNZGDVUS.mjs";

// src/jira/comment/manifest.ts
var jiraCommentAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9-]+$"),
  resourceOwner: JiraCommentAriResourceOwner,
  resourceType: JiraCommentAriResourceType,
  resourceIdSlug: "{commentId}",
  resourceIdSegmentFormats: {
    commentId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/comment/index.ts
var JiraCommentAri = class _JiraCommentAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._commentId = opts.resourceIdSegmentValues.commentId;
  }
  get siteId() {
    return this._siteId;
  }
  get commentId() {
    return this._commentId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraCommentAriStaticOpts.qualifier,
      platformQualifier: jiraCommentAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraCommentAriStaticOpts.resourceOwner,
      resourceType: jiraCommentAriStaticOpts.resourceType,
      resourceId: `${opts.commentId}`,
      resourceIdSegmentValues: {
        commentId: opts.commentId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraCommentAriStaticOpts);
    return new _JiraCommentAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraCommentAriStaticOpts);
    return new _JiraCommentAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      commentId: this.commentId
    };
  }
};

export {
  JiraCommentAri
};
