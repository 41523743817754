import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	typeSelectAriaLabel: {
		id: 'issue-view-base.content.issue-links.issue-links-type.type-select-aria-label',
		defaultMessage: 'Select an action for your linked issues',
		description:
			'Aria-label for the linked issues type selector (i.e. is blocked by, is related to, etc)',
	},
});
