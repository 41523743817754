import React from 'react';
import RefreshIcon from '@atlaskit/icon/core/refresh';
import { LinkItem } from '@atlaskit/menu';
import { Text } from '@atlaskit/primitives';
import { MenuIcon } from '@atlassian/jira-automation-platform/src/common/ui/icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const RecurringIssueLinkItem = () => {
	const { formatMessage } = useIntl();

	return (
		<LinkItem
			testId="recurring-issue-action.ui.set-recurring"
			iconBefore={
				<MenuIcon>
					<RefreshIcon label={formatMessage(messages.menuItemText)} />
				</MenuIcon>
			}
		>
			<Text>{formatMessage(messages.menuItemText)}</Text>
		</LinkItem>
	);
};
