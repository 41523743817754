import React, { type ComponentType, useMemo, useRef, useEffect, useCallback } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIssueBreakdown } from '@atlassian/jira-ai-work-breakdown/src/controllers/context.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useChildIssuesLimit,
	useIsAiOptInEnabled,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { CreatedIssueWithIssueData } from '@atlassian/jira-issue-create-common-types/src/common/types/index.tsx';
import { GIC_LIFECYCLE_EVENTS } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/constants.tsx';
import { useGICLifeCycleEvents } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/main.tsx';
import type { ServerAssociatedIssue } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import {
	EXCEEDED_LIMIT_AFTER_LOAD,
	EXCEEDED_LIMIT_INITIAL_LOAD,
	WITHIN_LIMIT,
	type ChildIssuesLimitStatus,
	GIC_CALLBACK_PAYLOAD_INLINE_CREATE_ID,
} from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';
import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type.tsx';
import {
	type ChildIssue,
	getChildIssuesLimitExceededAnalyticsCount,
} from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';
import {
	FireScreenAnalytics,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	IssueKey,
	BaseUrl,
	ProjectId,
	IssueTypeId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import transfromServerChild from '../common/transform-server-child/index.tsx';
import {
	hasExceededIssuesLimitInitialLoad,
	hasExceededIssuesLimitAfterLoad as hasExceededIssuesLimitAfterLoadUtil,
} from '../common/util.tsx';
import { useChildPanelRenderTrigger } from '../hooks/child-panel-render-trigger/index.tsx';
import { useShouldTriggerAiSuggestedIssues } from '../hooks/main.tsx';
import type { ChildIssuesPanelType } from '../model/types.tsx';
import { CHILD_PANEL_VIEWS, type ChildPanelView } from '../state/ui/actions.tsx';
import { AiIssueBreakdownEntryPointContainer } from './ai-issue-breakdown/index.tsx';
import { ChildIssuesLimitFlag } from './child-issues-limit-flag/index.tsx';
import { ChildIssuesLimitPanel } from './child-issues-limit-panel/index.tsx';
import { AsyncConfigurableChildIssuesPanel } from './configurable-child-issues-panel/src/ui/async.tsx';
import type { AddChildEventHandler } from './heading/add-child/types.tsx';
import { HideDoneIconWithToolTip } from './hide-done-icon-with-tooltip/index.tsx';
import { getIsSubtaskCreateEnabled, useHideDoneChildIssues } from './utils.tsx';

// Only show progress summary when there are at least two child issues (or subtasks).
const PROGRESS_SUMMARY_MIN_ISSUE_COUNT = 2;

export type OwnProps = {
	hasExceededIssuesLimitAfterLoad: boolean;
	sourceName?: string;
	childIssuesLimitUrl: string;
	totalChildIssueCount: number;
	onAddChildClick: AddChildEventHandler | undefined;
	onIssueBreakdownClick: (() => void) | undefined;
};

type ConnectProps = {
	Heading: ComponentType<{
		onAddChildClick?: AddChildEventHandler;
		sourceName?: string;
		childIssuesLimitStatus: ChildIssuesLimitStatus;
		issueHierarchyLevel: number | undefined;
	}>;
	Issues: ComponentType<{
		hasExceededChildIssuesLimitOnLoadOrAfter: boolean;
	}>;
	InlineCreate: ComponentType<{
		inputId?: string;
	}>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ProgressSummary: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	AddExistingIssue: ComponentType<Record<any, any>>;
	allIssues: ChildIssue[];
	addClickCount?: number;
	childPanelView: ChildPanelView;
	incompleteIssues: ChildIssue[];
	childIssuesPanelType?: ChildIssuesPanelType;
	issueHierarchyLevel?: number;
	epicLinkFieldKey?: string | null;
	parentIssueKey?: IssueKey;
	projectId: ProjectId | null;
	parentIssueTypeId: IssueTypeId | null;
	baseUrl: BaseUrl;
	childIssueTypes: ChildIssueType[];
	issueTypeWithWorkflowValidators?: { [issueTypeId: string]: boolean };
	filterSubtasks: boolean | null;
	onFullDialogCreate: (optimsticId: string, childIssue: ChildIssue) => void;
	onSuggestedChildIssueCreateSuccess: (
		createdChild: CreatedIssueWithIssueData | ChildIssue,
	) => void;
};

export type Props = OwnProps & ConnectProps;

export const ChildIssuesPanel = ({
	hasExceededIssuesLimitAfterLoad,
	totalChildIssueCount,
	addClickCount = 0,
	Heading,
	Issues,
	InlineCreate,
	ProgressSummary,
	AddExistingIssue,
	allIssues,
	childPanelView,
	sourceName,
	childIssuesLimitUrl,
	onAddChildClick,
	onIssueBreakdownClick,
	childIssuesPanelType,
	issueHierarchyLevel,
	epicLinkFieldKey,
	parentIssueKey,
	parentIssueTypeId,
	projectId,
	baseUrl,
	childIssueTypes,
	issueTypeWithWorkflowValidators,
	onFullDialogCreate,
	onSuggestedChildIssueCreateSuccess,
	incompleteIssues,
	filterSubtasks,
}: Props) => {
	const initialTotalChildIssueCount = useRef<number>(totalChildIssueCount);

	const childIssuesLimitStatus = useMemo(() => {
		if (childIssuesLimitUrl) {
			return EXCEEDED_LIMIT_INITIAL_LOAD;
		}

		if (hasExceededIssuesLimitAfterLoad) {
			return EXCEEDED_LIMIT_AFTER_LOAD;
		}

		return WITHIN_LIMIT;
	}, [childIssuesLimitUrl, hasExceededIssuesLimitAfterLoad]);

	const shouldTriggerAiSuggestedIssues = useShouldTriggerAiSuggestedIssues();

	let whichChildPanelRenderTriggered;
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	let resetWhichChildPanelRenderTriggered = useCallback(() => {}, []);

	if (fg('jira-ai-issue-view-improve-issues-button')) {
		[{ whichChildPanelRenderTriggered }, { resetWhichChildPanelRenderTriggered }] =
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useChildPanelRenderTrigger();
	}

	const hasIssues = useMemo(() => allIssues.length > 0, [allIssues.length]);
	const hasExceededChildIssuesLimitInitialLoad = useMemo(
		() => hasExceededIssuesLimitInitialLoad(childIssuesLimitStatus),
		[childIssuesLimitStatus],
	);

	const hasExceededChildIssuesLimitAfterLoad = useMemo(
		() => hasExceededIssuesLimitAfterLoadUtil(childIssuesLimitStatus),
		[childIssuesLimitStatus],
	);

	const hasContent =
		hasExceededChildIssuesLimitInitialLoad ||
		hasIssues ||
		addClickCount > 0 ||
		shouldTriggerAiSuggestedIssues ||
		whichChildPanelRenderTriggered;

	const childIssuesLimit = useChildIssuesLimit();
	const approachingLimitCutOff = childIssuesLimit - 1;

	const startedBelowTheCutoff =
		!hasExceededChildIssuesLimitInitialLoad &&
		initialTotalChildIssueCount.current < approachingLimitCutOff;

	const isNowAtTheCutoff = totalChildIssueCount === approachingLimitCutOff;

	const isApproachingLimit = startedBelowTheCutoff && isNowAtTheCutoff;

	const { shouldShowHideDoneInfoIcon, completedIssuesCount } = useHideDoneChildIssues(
		allIssues,
		incompleteIssues,
	);

	const renderIssues = useMemo(() => {
		const progressSummary =
			!hasExceededChildIssuesLimitInitialLoad &&
			(expVal('jsw-nike_chin_milestone_1_experiment', 'isChildIssueEnabled', false)
				? true
				: allIssues.length >= PROGRESS_SUMMARY_MIN_ISSUE_COUNT) ? (
				<ProgressSummary />
			) : null;

		return allIssues.length > 0 ? (
			<>
				<ProgressSummaryWithHideDoneIconContainer>
					{progressSummary}
					{shouldShowHideDoneInfoIcon && (
						<HideDoneIconWithToolTip completedChildIssuesCount={completedIssuesCount} />
					)}
				</ProgressSummaryWithHideDoneIconContainer>
				{expVal('jsw-nike_chin_milestone_1_experiment', 'isChildIssueEnabled', false) ? (
					<UFOSegment name="issue-child-issues-table">
						<AsyncConfigurableChildIssuesPanel
							parentIssueTypeId={parentIssueTypeId}
							projectId={projectId}
							filterSubtasks={filterSubtasks}
						/>
					</UFOSegment>
				) : (
					<UFOSegment name="issue-child-issues-list">
						<Issues
							hasExceededChildIssuesLimitOnLoadOrAfter={
								hasExceededChildIssuesLimitAfterLoad || hasExceededChildIssuesLimitInitialLoad
							}
						/>
					</UFOSegment>
				)}
			</>
		) : null;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		shouldShowHideDoneInfoIcon,
		completedIssuesCount,
		allIssues.length,
		incompleteIssues.length,
		hasExceededChildIssuesLimitInitialLoad,
		hasExceededChildIssuesLimitAfterLoad,
	]);

	const { subscribe } = useGICLifeCycleEvents();
	const isSubtaskCreateEnabled = getIsSubtaskCreateEnabled(
		childIssuesPanelType,
		issueHierarchyLevel,
	);

	useEffect(
		() =>
			subscribe?.(GIC_LIFECYCLE_EVENTS.ISSUE_CREATE_SUCCESS, async (payload) => {
				if (payload.callbackPayload?.id !== GIC_CALLBACK_PAYLOAD_INLINE_CREATE_ID) return;
				const { createdIssueDetails } = payload.createdIssueData;

				const callbackData = payload.callbackPayload?.data;

				if (
					callbackData.parentIssueKey !== parentIssueKey ||
					callbackData.childIssuesPanelType !== childIssuesPanelType
				)
					return;

				if (
					isSubtaskCreateEnabled ||
					(epicLinkFieldKey && createdIssueDetails.fields[epicLinkFieldKey]) === parentIssueKey ||
					callbackData?.parentIssueKey === parentIssueKey
				) {
					onFullDialogCreate(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						callbackData.optimisticId as string,
						transfromServerChild(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							createdIssueDetails as unknown as ServerAssociatedIssue,
							baseUrl,
						),
					);
				}
			}),
		[
			subscribe,
			baseUrl,
			epicLinkFieldKey,
			onFullDialogCreate,
			parentIssueKey,
			childIssuesPanelType,
			isSubtaskCreateEnabled,
		],
	);

	const [{ currentStep }, { resetIssueBreakdownState, isIssueBreakdownOpen }] = ff(
		'jira-intelligent-issue-breakdown_18syl',
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIssueBreakdown()
		: [
				{ currentStep: 'defaultStep' },
				{
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					resetIssueBreakdownState: () => {},
					isIssueBreakdownOpen: () => false,
				},
			];

	const isAiOptInEnabled = ff('jira-intelligent-issue-breakdown_18syl')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsAiOptInEnabled()
		: false;

	const renderCreate = useMemo(() => {
		const isAiEnabledAndOpen =
			ff('jira-intelligent-issue-breakdown_18syl') && isAiOptInEnabled && isIssueBreakdownOpen();

		// Create panel should not render if AI issue create is open
		if (isAiEnabledAndOpen) return;

		if (addClickCount > 0) {
			switch (childPanelView) {
				case CHILD_PANEL_VIEWS.InlineCreate:
					return <InlineCreate inputId={sourceName} />;
				case CHILD_PANEL_VIEWS.AddExistingIssue:
					return <AddExistingIssue />;
				default:
					return null;
			}
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addClickCount, childPanelView, sourceName, currentStep, isIssueBreakdownOpen]);

	const onAddChildClickWithAiBreakdownEnabled = useCallback<AddChildEventHandler>(
		(e, analyticsEvent) => {
			if (isAiOptInEnabled) {
				resetIssueBreakdownState();
			}
			onAddChildClick && onAddChildClick(e, analyticsEvent);
		},
		[isAiOptInEnabled, onAddChildClick, resetIssueBreakdownState],
	);

	const childIssuesLimitExceededAnalyticsCount =
		getChildIssuesLimitExceededAnalyticsCount(childIssuesLimit);

	const renderAIWorkBreakdown = useMemo(() => {
		if (childIssueTypes.length === 0) return null;

		// killswitch
		if (!ff('jira-intelligent-issue-breakdown_18syl')) return null;

		const childIssueTypeWithWFValidator = childIssueTypes.map((issueType) => ({
			...issueType,
			hasWorkflowValidators:
				(issueTypeWithWorkflowValidators && issueTypeWithWorkflowValidators[issueType.id]) || false,
		}));
		return (
			<AiIssueBreakdownEntryPointContainer
				onInit={onIssueBreakdownClick}
				onCreate={onSuggestedChildIssueCreateSuccess}
				childIssueTypes={childIssueTypeWithWFValidator}
				isSubTaskCreationOpen={isSubtaskCreateEnabled}
				issueHierarchyLevel={issueHierarchyLevel}
				onDiscard={resetWhichChildPanelRenderTriggered}
			/>
		);
	}, [
		childIssueTypes,
		onIssueBreakdownClick,
		onSuggestedChildIssueCreateSuccess,
		isSubtaskCreateEnabled,
		issueHierarchyLevel,
		resetWhichChildPanelRenderTriggered,
		issueTypeWithWorkflowValidators,
	]);

	return hasContent ? (
		<ContextualAnalyticsData
			attributes={{
				childCount: hasExceededChildIssuesLimitInitialLoad
					? childIssuesLimitExceededAnalyticsCount
					: allIssues.length,
				isChildIssuesLimitPanelDisplayed: hasExceededChildIssuesLimitInitialLoad,
			}}
		>
			<FireScreenAnalytics actionSubjectId={sourceName} />
			<Heading
				onAddChildClick={
					ff('jira-intelligent-issue-breakdown_18syl')
						? onAddChildClickWithAiBreakdownEnabled
						: onAddChildClick
				}
				sourceName={sourceName}
				childIssuesLimitStatus={childIssuesLimitStatus}
				issueHierarchyLevel={issueHierarchyLevel}
			/>
			{hasExceededChildIssuesLimitInitialLoad && (
				<ChildIssuesLimitPanel
					hasExceededIssuesLimitInitialLoad={hasExceededChildIssuesLimitInitialLoad}
				/>
			)}
			<ChildIssuesLimitFlag
				isApproachingLimit={isApproachingLimit}
				childIssuesLimitStatus={childIssuesLimitStatus}
			/>
			{renderIssues}
			{renderCreate &&
			expVal('jsw-nike_chin_milestone_1_experiment', 'isChildIssueEnabled', false) ? (
				<CreateChildIssueContainer>{renderCreate}</CreateChildIssueContainer>
			) : (
				renderCreate
			)}
			{renderAIWorkBreakdown}
		</ContextualAnalyticsData>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressSummaryWithHideDoneIconContainer = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/use-primitives -- To migrate as part of go/ui-styling-standard
const CreateChildIssueContainer = styled.div({
	marginTop: token('space.100', '8px'),
});
