/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip';

import Enter from '../../assets/Enter';

import messages from './messages';

type Props = {
	disabled: boolean;
	tabIndex?: number;
};

export const SubmitButton = ({ disabled, tabIndex }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Tooltip content={formatMessage(messages.submitTooltip)} hideTooltipOnClick position="top">
			<IconButton
				label={formatMessage(messages.submitLabel)}
				testId="submit-icon-button"
				type="submit"
				isDisabled={disabled}
				icon={Enter}
				appearance="subtle"
				spacing="compact"
				tabIndex={tabIndex}
			/>
		</Tooltip>
	);
};
