import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props } from '../../confluence-placeholder-template-experiment/confluence-dummy-link/experiments/grw-1117-initial-crossjoin/view.tsx';
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const DummyConfluenceLinkCrossJoin = lazy(() =>
	import(
		/* webpackChunkName: "async-dummy-confluence-link-with-conf-aa" */ '../../confluence-placeholder-template-experiment/confluence-dummy-link/experiments/grw-1117-initial-crossjoin/view'
	).then((module) => module.DummyConfluenceLinkCrossJoin),
);

export default function Component(props: Props) {
	// this does not return a value but instead checks the statsig experiment and relevant criteria for targeting
	return <DummyConfluenceLinkCrossJoin {...props} />;
}

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ConfluenceContentPlaceholderCrossJoinModule = lazy(() =>
	import(
		/* webpackChunkName: "async-confluence-content-placeholder-experiment" */ '../../confluence-placeholder-template-experiment/confluence-dummy-link/experiments/grw-1117-placeholder-crossjoin'
	).then((module) => module.ConfluenceContentPlaceholderCrossJoin),
);

export function ConfluenceContentPlaceholderCrossJoin(props: Props) {
	return (
		<ErrorBoundary
			id="confluence-content-placeholder.crossjoin-experiment"
			packageName="ConfluenceContentPlaceholderCrossJoin"
		>
			<ConfluenceContentPlaceholderCrossJoinModule {...props} />
		</ErrorBoundary>
	);
}
