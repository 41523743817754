import gqlTagPolaris from 'graphql-tag';

export const LAST_VIEWED_FRAGMENT = gqlTagPolaris`
  fragment PolarisViewLastViewed on PolarisViewLastViewed {
    aaid
    timestamp
    account {
        __typename
        accountId
        name
        picture
    }
  }
`;
