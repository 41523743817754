import React from 'react';

import type { IntlShape } from 'react-intl-next';

import type {
	QuickInsertActionInsert,
	QuickInsertItem,
} from '@atlaskit/editor-common/provider-factory';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import { createOpenAIModalCommand } from '../pm-plugins/decoration/actions';
import type { AIGlobalOptIn } from '../types';
import { Logo } from '../ui/components/Logo/Logo';

import { type EditorPluginAIConfigItemMarkdown, getTranslatedItemTitle } from './config-items';

export function mapConfigItemsToQuickInsertItems({
	configItems,
	formatMessage,
	aiGlobalOptIn,
	editorView,
}: {
	configItems: EditorPluginAIConfigItemMarkdown[];
	formatMessage: IntlShape['formatMessage'];
	aiGlobalOptIn: AIGlobalOptIn;
	editorView: EditorView;
}): QuickInsertItem[] {
	const aiPriorities = 9000;

	const dispatch = editorView.dispatch;

	return (
		configItems
			// hide quickInsert items that shouldn't be visible (for e.g. because of
			// an empty document, or other config-defined logic)
			.filter((configItem) => {
				const isVisible = configItem.isVisible({ editorView });

				if (configItem.key.startsWith?.('Translate to')) {
					if (isVisible) {
						return true;
					}
					return false;
				}

				return isVisible;
			})
			.map((configItem) => ({
				priority: configItem.quickInsertPriority ?? aiPriorities,
				categories: ['AI'],
				title: getTranslatedItemTitle(configItem, formatMessage),
				description: formatMessage(configItem.description || configItem.title),
				keywords: ['ai', 'generate', getTranslatedItemTitle(configItem, formatMessage)],
				icon: () => {
					return configItem.icon ? (
						configItem.icon({ formatMessage, shownAt: 'quickInsert' })
					) : (
						<Logo />
					);
				},
				action: (insert: QuickInsertActionInsert, editorState: EditorState) => {
					// We set the openAIModalCommand to run on the next tick -- as it then
					// performs additional transactions to update the document

					requestAnimationFrame(() => {
						const openAIModalCommand = createOpenAIModalCommand({
							state: editorView.state,
							configItem,
							lastTriggeredFrom: 'quickInsert',
							aiGlobalOptIn,
						});

						openAIModalCommand(editorView.state, dispatch);
					});

					// Insert empty string to remove the typeahead raw text
					// close the quick insert immediately
					const transaction = insert('');
					return transaction;
				},
			}))
	);
}
