import { type MessageDescriptor } from 'react-intl-next';

import promptExamplesMessages from './prompt-examples/messages';
import quickTipsMessages from './quick-tips/messages';

const promptExamples: MessageDescriptor[] = Object.values(promptExamplesMessages);
const quickTips: MessageDescriptor[] = Object.values(quickTipsMessages);

const selectRandom = (arr: MessageDescriptor[]): MessageDescriptor => {
	const idx = Math.floor(Math.random() * arr.length);
	return arr[idx];
};

let lastSelectedItem: MessageDescriptor | null = null;

export const getPromptPlaceholder = ({ type }: { type: 'quick tip' | 'prompt example' }) => {
	let options: MessageDescriptor[] = [];

	if (type === 'prompt example') {
		options = promptExamples.slice();
	} else if (type === 'quick tip') {
		options = quickTips.slice();
	}

	if (lastSelectedItem) {
		options = options.filter((item) => item !== lastSelectedItem);
	}

	const selectedItem = selectRandom(options);
	lastSelectedItem = selectedItem;

	return selectedItem;
};
