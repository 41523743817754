import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from '../messages.tsx';

export const getVoteLabelForDropdown = (hasVoted: boolean, votes: number): MessageDescriptorV2 => {
	if (hasVoted) {
		return messages.voteCountWithSelfLabel;
	}
	return votes ? messages.voteCountWithoutSelfLabel : messages.noOneHasVotedLabel;
};

export const getLabelForToggleWithSelfVote = (): MessageDescriptorV2 =>
	messages.voteCountWithSelfLabelForToggle;

export const getLabelForToggleWithoutSelfVote = (votes: number): MessageDescriptorV2 =>
	votes ? messages.voteCountWithoutSelfLabelForToggle : messages.noOneHasVotedLabelForToggle;
