/**
 * @generated SignedSource<<eae19eea2da76a3897b45218e91d8484>>
 * @relayHash 4811d7e6ec80c56364de87842bfaccaa
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9770e2db957bce163abbd584e79a12ccc59a2a39e1339d88c1e1446f7138b3c4

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_DesignBackfillCardQuery } from './ui_DesignBackfillCardQuery.graphql';

const node: PreloadableConcreteRequest<ui_DesignBackfillCardQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "9770e2db957bce163abbd584e79a12ccc59a2a39e1339d88c1e1446f7138b3c4",
    "metadata": {},
    "name": "ui_DesignBackfillCardQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
