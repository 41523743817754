import { useCallback, useRef, useState } from 'react';

import { type ApiError, mapErrorToApiError } from '../api-error';

type UseStreamingMutationProps<TPayload, TChunk> = {
	mutationFn: (payload: TPayload) => Promise<AsyncGenerator<TChunk>>;
	onChunkReceived?: (chunk: TChunk) => void;
	onSuccess?: () => void;
	onError?: (error: ApiError) => void;
};

export const useStreamingMutation = <TPayload, TChunk>({
	mutationFn,
	onChunkReceived,
	onSuccess,
	onError,
}: UseStreamingMutationProps<TPayload, TChunk>): {
	error: ApiError | null;
	isMutating: boolean;
	mutate: (payload: TPayload) => void;
} => {
	const mutationFnRef = useRef(mutationFn);
	mutationFnRef.current = mutationFn;

	const onSuccessRef = useRef(onSuccess);
	onSuccessRef.current = onSuccess;

	const onErrorRef = useRef(onError);
	onErrorRef.current = onError;

	const onChunkReceivedRef = useRef(onChunkReceived);
	onChunkReceivedRef.current = onChunkReceived;

	const [error, setError] = useState<ApiError | null>(null);
	const [isMutating, setIsMutating] = useState<boolean>(false);

	const mutate = useCallback(async (payload: TPayload) => {
		setIsMutating(true);
		try {
			const generator = await mutationFnRef.current(payload);
			for await (const chunk of generator) {
				onChunkReceivedRef.current?.(chunk);
			}
			onSuccessRef.current?.();
		} catch (error: any) {
			const apiError = mapErrorToApiError(error);
			setError(apiError);
			onErrorRef.current?.(apiError);
		} finally {
			setIsMutating(false);
		}
	}, []);

	return { error, isMutating, mutate };
};
