/**
 * @generated SignedSource<<223443de73959511141d6f6228be5d05>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type inputField_issueAddDesignForm$data = {
  readonly designProviders: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null | undefined;
    readonly supportedActions: {
      readonly checkAuth: boolean | null | undefined;
      readonly getEntityByUrl: boolean | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "inputField_issueAddDesignForm";
};
export type inputField_issueAddDesignForm$key = {
  readonly " $data"?: inputField_issueAddDesignForm$data;
  readonly " $fragmentSpreads": FragmentRefs<"inputField_issueAddDesignForm">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "inputField_issueAddDesignForm",
  "selections": [
    {
      "concreteType": "DevOpsDesignProvider",
      "kind": "LinkedField",
      "name": "designProviders",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "id"
        },
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "concreteType": "DevOpsSupportedActions",
          "kind": "LinkedField",
          "name": "supportedActions",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "checkAuth"
            },
            {
              "kind": "ScalarField",
              "name": "getEntityByUrl"
            }
          ]
        }
      ]
    }
  ],
  "type": "DevOpsProviders"
};

(node as any).hash = "c9d7a93b496974c25eedd90a7828aead";

export default node;
