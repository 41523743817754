import React, { useCallback, useState } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	getSharedRestrictSprintsValueFromLocalStorage,
	setSharedRestrictSprintsValueInLocalStorage,
} from '@atlassian/jira-issue-field-sprint-editview-full/src/common/utils.tsx';
import { SprintInlineEditView } from '@atlassian/jira-issue-field-sprint-inline-edit-full/src/ui/sprint/index.tsx';
import type { AggJiraSprint } from '@atlassian/jira-issue-field-sprint-inline-edit-full/src/ui/sprint/types.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { ui_issueViewLayoutSprintField_IssueViewSprintField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSprintField_IssueViewSprintField.graphql';

export interface IssueViewSprintFieldProps {
	/** Defines which area of the Issue View the field will be displayed */
	area?: Area;
	/** This is a reference to the relay fragment of GraphQL type JiraPriorityField */
	fragmentKey: ui_issueViewLayoutSprintField_IssueViewSprintField$key;
}

export function IssueViewSprintField(props: IssueViewSprintFieldProps) {
	const { area, fragmentKey } = props;

	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);

	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment(
		graphql`
			fragment ui_issueViewLayoutSprintField_IssueViewSprintField on JiraSprintField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...sprint_issueFieldSprintInlineEditFull_SprintInlineEditView_fragmentRef

				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const [restrictSprints, setRestrictSprints] = useState(
		getSharedRestrictSprintsValueFromLocalStorage,
	);

	const handleRestrictSprints = useCallback((shouldRestrict: boolean) => {
		setRestrictSprints(shouldRestrict);
		setSharedRestrictSprintsValueInLocalStorage(shouldRestrict);
	}, []);

	const onSubmit = useCallback(
		(value: AggJiraSprint) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraSprint) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	return (
		<IssueViewFieldHeading
			area={area}
			fragmentKey={data}
			testId={`issue.issue-view-layout.issue-view-sprint-field.${data.fieldId}`}
		>
			<Box xcss={fieldWrapperStyles}>
				<SprintInlineEditView
					fragmentRef={data}
					projectKey={projectKey}
					onSubmitNew={onSubmit}
					onSubmitFailed={onSubmitFailed}
					onSubmitSucceededNew={onSubmitSucceeded}
					restrictSprints={restrictSprints}
					onRestrictSprints={handleRestrictSprints}
				/>
			</Box>
		</IssueViewFieldHeading>
	);
}

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});
