// import React from 'react';

import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import { CONFIG_ITEM_KEYS } from '../../config-items/config-item-keys';
import {
	createEditorPluginAIConfigItemMarkdown,
	isInitialContextSelectionRange,
} from '../../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../config-items/slice-or-node-to-markdown';
import { rovoChatWithAgent } from '../../provider/prompt-requests/rovo-chat';
import { type EditorPluginAIProvider } from '../../types';
import { createContinueInChat } from '../config-item-actions/agent';
import { createInsertAtCurrentPosition, createReplace } from '../config-item-actions/markdown';

import { messages } from './messages';

// import { AgentIcon } from '../assets/icons/agent-icon/AgentIcon';

export const createAgentConfigItem = <T extends 'empty' | 'range'>(
	selectionType: T,
	agent: EditorAgent,
	editorPluginAIProvider: EditorPluginAIProvider,
) => {
	const continueInChat = createContinueInChat({
		appearance: 'secondary',
		agent,
		product: editorPluginAIProvider.product,
		actionSideEffect: editorPluginAIProvider.actionSideEffects?.['continueInChat'],
		actionOverride: editorPluginAIProvider.actionOverrides?.['continueInChat'],
	});

	return createEditorPluginAIConfigItemMarkdown({
		key: CONFIG_ITEM_KEYS.ROVO_AGENT,
		title: messages.title,
		description: messages.description,
		selectionType,
		agent,

		// icon: () => (
		//   <AgentIcon
		//     size="small"
		//     agent={{ name: agent.name, named_id: agent.namedId }}
		//   />
		// ),
		getInitialContext: ({ editorView, positions, intl, updateIdMap, mentionMap }) => {
			const selection = sliceOrNodeToMarkdown({
				slice: editorView.state.doc.slice(positions[0], positions[1]),
				editorView,
				convertTo: 'markdown',
				updateIdMap,
				selectionType,
				mentionMap,
			});

			const document = sliceOrNodeToMarkdown({
				node: editorView.state.doc,
				editorView,
				convertTo: 'markdown',
				updateIdMap,
				selectionType,
				mentionMap,
			});

			return {
				selection: selection.markdown,
				document: document.markdown,
				userLocale: intl.locale,
				intentSchemaId: 'DISABLED',
			};
		},
		triggerPromptRequest({ initialContext, editorSchema, promptInput, promptInputADF }) {
			return rovoChatWithAgent({
				agent,
				customPrompt: promptInput || '',
				customPromptADF: promptInputADF,
				fullDocument: initialContext.document,
				currentSelection: isInitialContextSelectionRange(initialContext)
					? initialContext.selection
					: '',
			});
		},
		// Required, since hasInitialPreset guard in palette state machine
		// checks this property for whether to transition to 'preset label'
		// (agent palette mode) during initialisation.
		promptLabel: {
			defaultMessage: agent?.name,
			id: `fabric.editor.ai.config.item.${selectionType}.agent.${agent?.name.toLocaleLowerCase().replace(/\s+/g, '.') || 'label'}`,
		},
		promptHint: messages.promptHint,
		isVisible: () => true,
		getBackendModel: () => 'assistance-service',
		statusLozengeType: 'beta',
		disableInterrogation: () => false,
		actions:
			selectionType === 'empty'
				? [
						continueInChat,
						createInsertAtCurrentPosition({ appearance: 'primary', isRovoAgentAction: true }),
					]
				: [continueInChat, createReplace({ appearance: 'primary', isRovoAgentAction: true })],
		secondaryActions: [],
	});
};
