/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag React.Fragment
 */

import React, { type ComponentProps } from 'react';

import { jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import { IconTile } from '@atlaskit/icon';
// The linting is a false positive, this is a valid import
// eslint-disable-next-line @atlassian/tangerine/import/entry-points
import AkGoalIcon from '@atlaskit/icon/core/goal';
import KeyResultIcon from '@atlaskit/icon/core/key-result';
import ObjectiveIcon from '@atlaskit/icon/core/objective';
import { token } from '@atlaskit/tokens';

import {
	type GoalAppearance,
	type GoalStatus,
	type GoalStatusValue,
	type GoalTypeKeys,
	isGoalAppearance,
} from '../types';

import { isMessageId, type MessageId, messages } from './messages';

export interface GoalIconProps {
	appearance: GoalAppearance;
	size?: ComponentProps<typeof IconTile>['size'];
	localizedLabel?: GoalStatus['localizedLabel'];
	goalIconKey?: GoalTypeKeys;
	isVisualRefresh?: boolean;
	visualRefreshSpacing?: ComponentProps<typeof AkGoalIcon>['spacing'];
}

const appearanceToColorMap = {
	default: 'gray',
	menu: 'gray',
	off_track: 'red',
	at_risk: 'yellow',
	on_track: 'green',
} as const;

const appearanceToNewColorMap = {
	menu: token('color.icon'),
	default: token('color.icon.accent.gray'),
	off_track: token('color.icon.danger'),
	at_risk: token('color.icon.warning'),
	on_track: token('color.icon.success'),
} as const;

const keyToIconMap = {
	GOAL: AkGoalIcon,
	OBJECTIVE: ObjectiveIcon,
	KEY_RESULT: KeyResultIcon,
};

const appearanceToLabelMap = {
	menu: 'GenericIconLabel',
	default: 'GenericIconLabel',
	off_track: 'townsquare.api.goal-state.off-track',
	at_risk: 'townsquare.api.goal-state.at-risk',
	on_track: 'townsquare.api.goal-state.on-track',
} as const;

/**
 * The goal icon component is a wrapper which allows displaying a goal icon based on a semantic
 * appearance prop.
 */
export const GoalIcon = ({
	appearance,
	size,
	localizedLabel,
	goalIconKey: goalKey = 'GOAL',
	isVisualRefresh = false,
	visualRefreshSpacing = 'none',
}: GoalIconProps) => {
	const intl = useIntl();

	const labelMessageId: MessageId =
		localizedLabel && isMessageId(localizedLabel?.messageId)
			? localizedLabel.messageId
			: appearanceToLabelMap[appearance];

	const Icon = keyToIconMap[goalKey ?? 'GOAL'];
	return (
		<>
			{isVisualRefresh ? (
				<Icon
					color={appearanceToNewColorMap[appearance]}
					label={intl.formatMessage(messages[labelMessageId])}
					spacing={visualRefreshSpacing}
				/>
			) : (
				<IconTile
					size={size}
					icon={Icon}
					label={intl.formatMessage(messages[labelMessageId])}
					appearance={appearanceToColorMap[appearance]}
				/>
			)}
		</>
	);
};

/**
 * This is here to support backwards compatability with our old API. Where possible, source goal appearance
 * directly from the Atlassian GraphQL API: https://api.atlassian.com/graphql
 *
 * @param statusValue - The status of the goal. If you pass in an unsupported status, it will return the default appearance.
 */
export const normalizeGoalAppearance = (
	statusValue: GoalStatusValue | Lowercase<GoalStatusValue> | string,
): GoalAppearance => {
	const appearanceLowercase = statusValue.toLocaleLowerCase();

	return isGoalAppearance(appearanceLowercase) ? appearanceLowercase : 'default';
};
