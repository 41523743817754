import { defineMessages } from 'react-intl-next';

export default defineMessages({
	pasteSmartLinks: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.pasteSmartLinks',
		defaultMessage: 'Tip: You can paste Jira and Confluence links here',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	smartLinksAsk: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.smartLinksAsk',
		defaultMessage: 'Tip: Ask AI about any Jira or Confluence link you paste here',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	focusText: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.focusText',
		defaultMessage: 'Tip: Select the text you want AI to focus on',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	specificAreaChanges: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.specificAreaChanges',
		defaultMessage: 'Tip: Need changes in a specific area? Highlight it before prompting',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	detailedPrompts: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.detailedPrompts',
		defaultMessage: 'Tip: The more detailed your prompt, the better the answer',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	changeToneProfessional: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.changeToneProfessional',
		defaultMessage: 'Tip: Need a formal writing style? Use "Change tone to professional"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	translateLanguage: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.translateLanguage',
		defaultMessage: 'Tip: Need a translation? Try "Translate to Australian English"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	formattingRequests: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.formattingRequests',
		defaultMessage: 'Tip: You can request formatted outputs like tables or lists',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	confluenceUrls: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.confluenceUrls',
		defaultMessage:
			'Tip: Want to use info from Confluence? Paste a URL here so AI can reference it',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	improveWriting: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.improveWriting',
		defaultMessage: 'Tip: Use "Improve writing" to enhance clarity and flow',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	codeSnippets: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.codeSnippets',
		defaultMessage: 'Tip: You can ask AI to optimize or comment on code snippets',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	summarize: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.summarize',
		defaultMessage: 'Tip: Ask AI to "Summarize" to make content more succinct',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	generateOutline: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.generateOutline',
		defaultMessage: 'Tip: Try "Generate an outline" for a step by step summary of content',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	makeShorter: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.makeShorter',
		defaultMessage: 'Tip: Want a concise summary? Try "Make shorter"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	keyThemes: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.keyThemes',
		defaultMessage: 'Tip: Ask AI to "Identify key themes" in your text',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	todoList: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.todoList',
		defaultMessage: 'Tip: Generate a to-do list from notes using "Create action checkboxes"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	findActionItems: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.findActionItems',
		defaultMessage:
			'Tip: Use "Find Action Items" to let AI find tasks and create checkboxes next to them',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	questionAnswerFormat: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.questionAnswerFormat',
		defaultMessage: 'Tip: Generate an FAQ section by using "Convert to Q&A format"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	adoptRole: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.adoptRole',
		defaultMessage:
			'Tip: Ask AI to adopt a role, e.g. "Act as a reviewer and critique this content"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	mentionAudience: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.mentionAudience',
		defaultMessage:
			'Mention your audience so AI can adjust the writing level, e.g. "Explain to investors"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	combineCommands: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.combineCommands',
		defaultMessage:
			'Tip: To get more done, combine commands: "Summarize and translate into French"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	styleGuidelines: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.styleGuidelines',
		defaultMessage: 'Tip: Set clear style guidelines, e.g. "Use a formal tone"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	metaphorsAnalogies: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.metaphorsAnalogies',
		defaultMessage:
			'Tip: Try "Generate metaphors or analogies" to make complex ideas more relatable',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	detectInconsistencies: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.quickTips.detectInconsistencies',
		defaultMessage:
			'Tip: Try "Detect inconsistencies" to ensure your content is consistent and error-free',
		description: 'A tip for writing prompts in Editor AI modal',
	},
});
