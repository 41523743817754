/**
 * Feature flags convention: go/frontend-feature-flags
 */
import { getMultivariateFeatureFlag, ff } from '@atlassian/jira-feature-flagging';

export const INVITE_FROM_ASSIGN = 'invite.from.assign';

export const getInviteFromAssignCohort = (): string =>
	getMultivariateFeatureFlag(INVITE_FROM_ASSIGN, 'not-enrolled', [
		'control',
		'not-enrolled',
		'variation',
	]);

export const isInviteFromAssignEnabled = (): boolean => getInviteFromAssignCohort() === 'variation';

export const getInviteFromAssignModalCohort = () =>
	getMultivariateFeatureFlag('invite.from.assign.modal', 'not-enrolled', [
		'control',
		'not-enrolled',
		'variation',
	]);

export const isInviteFromAssignModalFeEnabled = () =>
	ff('invite.from.assign.modal.fe.event', false);
