import React, { useCallback, useLayoutEffect } from 'react';
import {
	FULL_ISSUE,
	EMBED_ISSUE,
	type AnalyticsSource,
	MOBILE_ISSUE,
} from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import { ISSUE_CRITICAL_DATA_GIRA_SUSPENSE } from '@atlassian/jira-issue-view-common-constants/src/mark-types.tsx';
import { useWaitForPrefetchData } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/index.tsx';
import type { IssueAppConfiguration } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode/src/index.tsx';
import { blockedOnBackendEntryLog } from '@atlassian/jira-issue-view/src/common/metrics/reporters/backend-blocking-time-reporter.tsx';
import { IssueAppWithRouteResources } from '@atlassian/jira-issue-view/src/views/issue-details/issue-app.tsx';
import { getIssueContainerMaxWidth } from '@atlassian/jira-issue-view/src/views/issue-details/issue-layout/constants.tsx';
import { isMobileAndInMVPExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import { useIssueViewRelayRouteResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-agg-data/index.tsx';
import { type IssueKey, toBaseUrl, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useRouterActions, usePathParam } from '@atlassian/react-resource-router';

import { FullPageIssueContainer } from '../common/styles/index.tsx';
import { getLegacyIssueViewUrl, addViewportMetaTag } from '../common/utils/index.tsx';

type OnIssueKeyChangeParams = {
	fromIssueKey: IssueKey;
	toIssueKey: IssueKey;
	meta: {
		location: string;
	};
};

type Props = {
	configuration?: IssueAppConfiguration;
	rapidViewId?: number;
};

export const MainIssueView = ({ configuration, rapidViewId }: Props) => {
	const tenantContext = useTenantContext();
	const [paramIssueKey] = usePathParam('issueKey');

	const { push } = useRouterActions();
	const [{ isInitialRender }] = useSpaStateTransition();

	/*
	 * Both useIssueViewRelayRouteResource and useWaitForPrefetchData are backed by route resources which:
	 *  - Are all `isBrowserOnly: true` resources (they never run in SSR)
	 *  - in an initial page load: the network request is kicked off from `src/entry/vendorless-entries/issue-app/index.tsx`
	 *  - in an SPA transition: the network request is kicked off normally via route resources' `getData` (or `getDataLoader`) methods triggered by the router itself when the result of `getKey` changes
	 */
	const { queryReference } = useIssueViewRelayRouteResource({ suspendWhenLoading: true }); // Suspends until an async bundle is fetched, does not block on data returning
	const { loading, promise } = useWaitForPrefetchData();
	blockedOnBackendEntryLog.initialiseBlockedOnBackendStateForUpdatedKey(paramIssueKey);
	if (isInitialRender) {
		blockedOnBackendEntryLog.markStartBackendBlockingTimestamp(ISSUE_CRITICAL_DATA_GIRA_SUSPENSE); // before we potentially suspend for gira data, lets mark that we're about to start blocking on the data. Suspense woun't resolve until after usePreloadedQuery in AppProvider though, so don't fire the data-available mark until then
		// IMPORTANT - for initial-load suspend until early-data is loaded, for transitions and preload errors just render
		const waitCondition = !__SERVER__ && loading;
		if (waitCondition) {
			// throw a promise for suspense boundary to catch
			throw promise;
		}
		blockedOnBackendEntryLog.markEndBackendBlockingTimestamp(ISSUE_CRITICAL_DATA_GIRA_SUSPENSE); // If we reach this point, either we never suspended (and the blocking duration will be effectively 0) or we did and we will capture the blocking time
	}

	const issueKeyFromRouter = toIssueKey(paramIssueKey || '');
	const projectKey = extractProjectKey(issueKeyFromRouter);
	const issueKey = toIssueKey(issueKeyFromRouter);
	let issueId: string | undefined;

	const { baseUrl, locale } = tenantContext;
	const { applicationKey, ...configWithoutAppKey } = configuration || {};

	const onIssueKeyChange = useCallback(
		(param: OnIssueKeyChangeParams) => {
			push(`${baseUrl}/browse/${param.toIssueKey}`);
		},
		[push, baseUrl],
	);

	const onIssueDelete = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.assign(`${baseUrl}/browse/${projectKey}`);
	}, [projectKey, baseUrl]);

	let analyticsSource: AnalyticsSource = FULL_ISSUE;

	const isEmbedMode = useIsEmbedMode();
	if (isEmbedMode) {
		analyticsSource = EMBED_ISSUE;
	}

	const isMobileExperienceEnabled = !isEmbedMode && isMobileAndInMVPExperiment();

	if (isMobileExperienceEnabled) {
		analyticsSource = MOBILE_ISSUE;
	}

	// add a viewport meta tag if:
	// 1. mobile experience mvp experiment is enabled (this include a check to target only mobile devices)
	// 2. viewport meta tag is not already present

	// the viewport metatag is being added as part of the same experiment in the the monolith
	// so this change is necessary to ensure the mobile experience works correctly when served by Bifrost
	// The useLayotuEffect will be removed once Statsig support is completed in Bifrost (~06/11/24)
	// https://hello.jira.atlassian.cloud/browse/KURO-7118
	useLayoutEffect(() => {
		if (!isMobileExperienceEnabled) {
			return;
		}

		addViewportMetaTag();
	}, [isMobileExperienceEnabled]);

	const issueViewContainer = (
		<FullPageIssueContainer>
			<IssueAppWithRouteResources
				{...configWithoutAppKey}
				issueKey={issueKey}
				// TODO: Remove this safely with a FG. https://hello.jira.atlassian.cloud/browse/KEN-1447
				// @ts-expect-error - TS2322 - Property 'issueId' does not exist on type 'IntrinsicAttributes & Pick<{ isSpaEnabled: boolean; issueMaxWidth?: number | undefined; shouldSetInitialFocus: boolean; shouldShowCloseButton?: boolean | undefined; shouldShowProjectLevelBreadcrumb?: boolean | undefined; shouldShowRootProjectsBreadcrumb: boolean; isScrollingHotkeysDisabled?: boolean | undefined; ret...'.
				issueId={issueId}
				baseUrl={toBaseUrl(baseUrl)}
				locale={locale}
				analyticsSource={analyticsSource}
				isSpaEnabled
				rapidViewId={rapidViewId}
				history={null}
				onIssueKeyChange={onIssueKeyChange}
				oldViewUrl={getLegacyIssueViewUrl()}
				onIssueDeleteSuccess={onIssueDelete}
				shouldSetInitialFocus
				shouldShowRootProjectsBreadcrumb={!isMobileExperienceEnabled}
				shouldShowProjectLevelBreadcrumb={!isMobileExperienceEnabled}
				isStickyHeaderOffset
				issueMaxWidth={getIssueContainerMaxWidth()}
				preloadedQuery={queryReference}
			/>
		</FullPageIssueContainer>
	);

	return <UFOSegment name="issue-view">{issueViewContainer}</UFOSegment>;
};
