import { hasFieldKeyDependingOnDeliveryData } from '@atlassian/jira-polaris-domain-field/src/delivery/index.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { startCurrentViewLoadExperience } from '../../../../common/utils/experience/index.tsx';
import { getDeliveryDataFetchError } from '../../selectors/meta.tsx';
import { getViewById, getViewBySlug } from '../../selectors/view/index.tsx';
import { type State, type Props, ViewSectionTypeViews } from '../../types.tsx';
import { resetDraft } from '../autosave/reset-draft/index.tsx';
import { loadViewComments } from '../comments/load-view-comments/index.tsx';
import { showStoredDeliveryDataFetchError } from '../delivery-data/index.tsx';
import { loadViewDescription } from '../description/load-view-description/index.tsx';
import { loadViewLastViewed } from '../last-viewed/index.tsx';
import { loadViewMarkers } from '../markers/index.tsx';
import { refreshIssueRanking } from '../refresh-view-rank/index.tsx';
import { refreshArrangementInformation } from '../timeline/arrangement-information/index.tsx';
import { trackAtlasAnalytics } from '../utils/atlas-analytics.tsx';

export const setCurrentViewFromRoute =
	(): Action<State, Props> =>
	({ dispatch, getState }, { currentViewSlug }) => {
		if (currentViewSlug && currentViewSlug !== getState().prevContainerProps?.currentViewSlug) {
			const view = getViewBySlug(getState(), currentViewSlug);
			if (!view) {
				return;
			}
			dispatch(setCurrentView(view.id, true));
		}
	};

export const setCurrentView =
	(id: string, ignoreRouting = false): Action<State, Props> =>
	({ dispatch, getState }, { router, fields }) => {
		const view = getViewById(getState(), id);

		if (view !== undefined) {
			const { viewId, uuid, slug } = view;

			if (viewId !== undefined) {
				trackAtlasAnalytics(view, fields);
				dispatch(loadViewLastViewed(view));
				dispatch(loadViewComments(viewId));
				dispatch(loadViewDescription(viewId));
				dispatch(refreshIssueRanking(viewId));
				if (view.kind === VIEW_KIND_TIMELINE) {
					dispatch(refreshArrangementInformation(viewId));
					if (uuid) {
						dispatch(loadViewMarkers(uuid));
					}
				}
				dispatch(resetDraft(viewId));
			}

			if (
				getDeliveryDataFetchError(getState()) &&
				hasFieldKeyDependingOnDeliveryData(view.fields, fields)
			) {
				dispatch(showStoredDeliveryDataFetchError());
			}

			startCurrentViewLoadExperience(view.kind);

			if (ignoreRouting) {
				return;
			}

			router.routeTo(
				{
					section: ViewSectionTypeViews,
					resource: slug,
				},
				{ clearParameters: false },
			);
		}
	};
