import { useCallback } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSearchCustomFieldKeys } from '@atlassian/jira-issue-field-base/src/services/custom-field-key-service/index.tsx';
import { useFlaggedField } from '@atlassian/jira-issue-field-flagged/src/services/main.tsx';
import { transformToStateValue } from '@atlassian/jira-issue-field-flagged/src/services/utils.tsx';
import { FLAGGED_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import messages from './messages.tsx';

const useToggleFlag = (
	itemKey: string,
	onClick?: (itemKey: string, event?: Event) => Promise<undefined> | undefined,
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();
	const { showFlag } = useFlagsService();
	const onFailure = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7006 - Parameter 'failedValue' implicitly has an 'any' type.
		(_, failedValue) => {
			showFlag({
				type: 'error',
				title: failedValue ? messages.addFlagFailedTitle : messages.removeFlagFailedTitle,
				description: failedValue
					? messages.addFlagFailedDescription
					: messages.removeFlagFailedDescription,
			});
		},
		[showFlag],
	);
	const [fieldKey] = useSearchCustomFieldKeys(issueKey, FLAGGED_CF_TYPE);
	const [{ value: isFlagged }, { saveValue }] = useFlaggedField({
		issueKey,
		fieldKey: fieldKey || '',
		// @ts-expect-error - TS2322 - Type '(_: any, failedValue: any) => void' is not assignable to type '(arg1: Error) => void'.
		onFailure,
	});

	return useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({});
		const newValue = transformToStateValue(!isFlagged);
		saveValue(newValue, null, analyticsEvent);
		onClick?.(itemKey);
	}, [onClick, itemKey, saveValue, isFlagged, createAnalyticsEvent]);
};

export default useToggleFlag;
