import React, { useState, useCallback, useMemo, useEffect, forwardRef, type Ref } from 'react';
import {
	graphql,
	useLazyLoadQuery,
	usePaginationFragment,
	type EntryPointProps,
} from 'react-relay';
import type { AtlaskitSelectRefType } from '@atlaskit/select/src/types';
import UserPicker, { type OptionData } from '@atlaskit/user-picker';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils/src/utils/component-with-condition';
import { SEARCH_DEBOUNCE_TIMEOUT } from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import { useSuspenselessRefetch } from '@atlassian/jira-issue-hooks/src/services/use-suspenseless-refetch/index.tsx';
import type { UserOption } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import { transformAggToUserOption } from '@atlassian/jira-issue-user-picker-edit-view/src/utils/transform-agg-to-user-option/index.tsx';
import { transformUserOptionToAgg } from '@atlassian/jira-issue-user-picker-edit-view/src/utils/transform-user-option-to-agg/index.tsx';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import type { people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentNew_suggestionsFragmentRef$key as PeopleFragmentNew } from '@atlassian/jira-relay/src/__generated__/people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentNew_suggestionsFragmentRef.graphql';
import type { people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef$key as PeopleFragmentOld } from '@atlassian/jira-relay/src/__generated__/people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef.graphql';
import peopleEditSearchRefetchQuery, {
	type people_peopleEditSearchRefetchQuery as PeopleEditRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/people_peopleEditSearchRefetchQuery.graphql';
import peopleSearchRefetchQuery, {
	type people_peopleSearchRefetchQuery as PeopleRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/people_peopleSearchRefetchQuery.graphql';
import type {
	PeopleEditViewProps,
	PeopleEditViewWithFieldOptionsFragmentProps,
	AggJiraPeopleConnection,
	PeopleEditViewWithFieldOptionsFragmentPropsOld,
} from './types.tsx';

export type UserOptionData = OptionData & { active?: boolean; accountId?: string; byline?: string };

export const transformToSuggestions = (users: AggJiraPeopleConnection): UserOption[] =>
	users?.edges
		?.map((edge) => edge?.node ?? null)
		.filter(Boolean)
		.map((edge): UserOption => transformAggToUserOption(edge)) || [];

const isRelayedEnabledPeopleEdit = () => ff('relay-migration-issue-fields-people');

export const PeopleEditViewWithFieldOptionsFragmentOld = ({
	fieldId,
	suggestionsFragmentRef,
	onChange,
	onClear,
	onBlur,
	onFocus,
	formatSuggestions,
	value,
	autoFocus = false,
	isDisabled = false,
	ariaLabel = '',
	ariaLabelledBy = '',
	inputId,
	appearance,
	menuPosition,
	hasDraggableParentComponent = false,
	fetchSuggestionsOnMount = false,
	portalElement,
}: PeopleEditViewWithFieldOptionsFragmentPropsOld) => {
	// #region Common state (might be handy for other components)
	const [searchByString, setSearchByString] = useState<string>('');

	// #region Relay
	// suggestions fragment with pagination and refetch
	const { data, refetch } = usePaginationFragment<PeopleRefetchQuery, PeopleFragmentOld>(
		graphql`
			fragment people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef on Query
			@refetchable(queryName: "people_peopleSearchRefetchQuery")
			@argumentDefinitions(
				id: { type: "ID!" }
				searchBy: { type: "String", defaultValue: "" }
				first: { type: "Int", defaultValue: 50 }
				after: { type: "String", defaultValue: null }
			) {
				node(id: $id) {
					... on JiraPeopleField {
						users(searchBy: $searchBy, first: $first, after: $after)
							@connection(key: "people_issueFieldPeopleEditviewFull_users") {
							edges {
								node {
									id
									accountId
									name
									# eslint-disable-next-line @atlassian/relay/unused-fields
									picture
									# eslint-disable-next-line @atlassian/relay/unused-fields
									accountStatus
									... on AtlassianAccountUser {
										# eslint-disable-next-line @atlassian/relay/unused-fields
										email
									}
								}
							}
						}
					}
				}
			}
		`,
		suggestionsFragmentRef,
	);

	const { users } = data?.node || {};

	const [suspenselessRefetchSuggestions, isLoading, lastFetchError] = useSuspenselessRefetch<
		PeopleRefetchQuery,
		PeopleFragmentOld
	>(peopleSearchRefetchQuery, refetch);

	const formattedSuggestions: UserOption[] = useMemo(() => {
		const suggestions = transformToSuggestions(users);
		return formatSuggestions
			? formatSuggestions({ suggestions, isLoading, searchByString, lastFetchError })
			: suggestions;
	}, [lastFetchError, formatSuggestions, searchByString, isLoading, users]);

	const [debouncedSuggestionsRefetcher] = useDebouncedCallback(
		suspenselessRefetchSuggestions,
		SEARCH_DEBOUNCE_TIMEOUT,
	);

	useEffect(() => {
		if (fetchSuggestionsOnMount) {
			suspenselessRefetchSuggestions({ id: fieldId, searchBy: '' });
		}
	}, [suspenselessRefetchSuggestions, fetchSuggestionsOnMount, fieldId]);

	// #endregion

	// #region Common callbacks for the selector
	const onSelectFocus = useCallback(() => {
		suspenselessRefetchSuggestions({
			id: fieldId,
			searchBy: searchByString || '',
		});
		onFocus?.();
	}, [fieldId, searchByString, suspenselessRefetchSuggestions, onFocus]);

	const onSearchByStringChangeFunction = useCallback(
		(newSearchByString?: string): void => {
			setSearchByString(newSearchByString || '');
			debouncedSuggestionsRefetcher({
				id: fieldId,
				searchBy: newSearchByString || '',
			});
		},
		[fieldId, debouncedSuggestionsRefetcher],
	);

	const onSuggestionSelect = useCallback(
		(updatedValue: UserOptionData | UserOptionData[] | null | undefined) => {
			if (!onChange) return;
			if (updatedValue === null) onChange(null);
			if (Array.isArray(updatedValue)) {
				const updatedArrayValue = updatedValue.map((user) =>
					transformUserOptionToAgg({
						name: user.name,
						id: user.id,
						accountId: user.accountId,
						active: !!user.active,
						avatarUrl: user.avatarUrl,
						byline: user.byline,
					}),
				);
				onChange(updatedArrayValue);
			}
		},
		[onChange],
	);

	return (
		<UserPicker
			value={value}
			autoFocus={autoFocus}
			options={formattedSuggestions}
			fieldId={fieldId}
			menuPortalTarget={portalElement}
			width="100%"
			placeholder=""
			appearance={appearance}
			isLoading={isLoading}
			isDisabled={isDisabled}
			onBlur={onBlur}
			onClear={onClear}
			onFocus={onSelectFocus}
			onInputChange={onSearchByStringChangeFunction}
			onChange={onSuggestionSelect}
			inputId={inputId}
			isMulti
			menuPosition={menuPosition}
			styles={defaultSelectStyles}
			ariaLabel={ariaLabel}
			ariaLabelledBy={ariaLabelledBy}
			UNSAFE_hasDraggableParentComponent={hasDraggableParentComponent}
		/>
	);
};

export const PeopleEditViewWithFieldOptionsFragmentNew = forwardRef(
	(
		{
			fieldId,
			suggestionsFragmentRef,
			onChange,
			onClear,
			onBlur,
			onFocus,
			formatSuggestions,
			value,
			autoFocus = false,
			isDisabled = false,
			ariaLabel = '',
			ariaLabelledBy = '',
			inputId,
			appearance,
			menuPosition,
			hasDraggableParentComponent = false,
			fetchSuggestionsOnMount = false,
			portalElement,
		}: PeopleEditViewWithFieldOptionsFragmentProps,
		ref: Ref<AtlaskitSelectRefType>,
	) => {
		// #region Common state (might be handy for other components)
		const [searchByString, setSearchByString] = useState<string>('');

		// #region Relay
		// suggestions fragment with pagination and refetch
		const { data, refetch } = usePaginationFragment<PeopleEditRefetchQuery, PeopleFragmentNew>(
			graphql`
				fragment people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentNew_suggestionsFragmentRef on Query
				@refetchable(queryName: "people_peopleEditSearchRefetchQuery")
				@argumentDefinitions(
					id: { type: "ID!" }
					searchBy: { type: "String", defaultValue: "" }
					first: { type: "Int", defaultValue: 50 }
					after: { type: "String", defaultValue: null }
				) {
					node(id: $id) {
						... on JiraPeopleField {
							users(searchBy: $searchBy, first: $first, after: $after)
								@connection(key: "people_issueFieldPeopleEditviewFull_users") {
								edges {
									node {
										id
										accountId
										name
										# eslint-disable-next-line @atlassian/relay/unused-fields
										picture
										# eslint-disable-next-line @atlassian/relay/unused-fields
										accountStatus
										... on AtlassianAccountUser {
											# eslint-disable-next-line @atlassian/relay/unused-fields
											email
										}
									}
								}
							}
						}
					}
				}
			`,
			suggestionsFragmentRef,
		);

		const { users } = data?.node || {};

		const [suspenselessRefetchSuggestions, isLoading, lastFetchError] = useSuspenselessRefetch<
			PeopleEditRefetchQuery,
			PeopleFragmentNew
		>(peopleEditSearchRefetchQuery, refetch);

		const formattedSuggestions: UserOption[] = useMemo(() => {
			const suggestions = transformToSuggestions(users);
			return formatSuggestions
				? formatSuggestions({ suggestions, isLoading, searchByString, lastFetchError })
				: suggestions;
		}, [lastFetchError, formatSuggestions, searchByString, isLoading, users]);

		const [debouncedSuggestionsRefetcher] = useDebouncedCallback(
			suspenselessRefetchSuggestions,
			SEARCH_DEBOUNCE_TIMEOUT,
		);

		useEffect(() => {
			if (fetchSuggestionsOnMount) {
				suspenselessRefetchSuggestions({ id: fieldId, searchBy: '' });
			}
		}, [suspenselessRefetchSuggestions, fetchSuggestionsOnMount, fieldId]);

		// #endregion

		// #region Common callbacks for the selector
		const onSelectFocus = useCallback(() => {
			suspenselessRefetchSuggestions({
				id: fieldId,
				searchBy: searchByString || '',
			});
			onFocus?.();
		}, [fieldId, searchByString, suspenselessRefetchSuggestions, onFocus]);

		const onSearchByStringChangeFunction = useCallback(
			(newSearchByString?: string): void => {
				setSearchByString(newSearchByString || '');
				debouncedSuggestionsRefetcher({
					id: fieldId,
					searchBy: newSearchByString || '',
				});
			},
			[fieldId, debouncedSuggestionsRefetcher],
		);

		const onSuggestionSelect = useCallback(
			(updatedValue: UserOptionData | UserOptionData[] | null | undefined) => {
				if (!onChange) return;
				if (updatedValue === null) onChange(null);
				if (Array.isArray(updatedValue)) {
					const updatedArrayValue = updatedValue.map((user) =>
						transformUserOptionToAgg({
							name: user.name,
							id: user.id,
							accountId: user.accountId,
							active: !!user.active,
							avatarUrl: user.avatarUrl,
							byline: user.byline,
						}),
					);
					onChange(updatedArrayValue);
				}
			},
			[onChange],
		);

		return (
			<UserPicker
				value={value}
				autoFocus={autoFocus}
				options={formattedSuggestions}
				fieldId={fieldId}
				menuPortalTarget={portalElement}
				width="100%"
				placeholder=""
				appearance={appearance}
				isLoading={isLoading}
				isDisabled={isDisabled}
				onBlur={onBlur}
				onClear={onClear}
				onFocus={onSelectFocus}
				onInputChange={onSearchByStringChangeFunction}
				onChange={onSuggestionSelect}
				inputId={inputId}
				isMulti
				menuPosition={menuPosition}
				styles={defaultSelectStyles}
				ariaLabel={ariaLabel}
				ariaLabelledBy={ariaLabelledBy}
				UNSAFE_hasDraggableParentComponent={hasDraggableParentComponent}
				ref={ref}
			/>
		);
	},
);

export const PeopleEditViewWithFieldOptionsFragment = componentWithCondition(
	isRelayedEnabledPeopleEdit,
	PeopleEditViewWithFieldOptionsFragmentNew,
	PeopleEditViewWithFieldOptionsFragmentOld,
);

const PeopleEditViewOld = (props: PeopleEditViewProps) => {
	const suggestionsData = useLazyLoadQuery<PeopleRefetchQuery>(
		graphql`
			query people_peopleSearchQuery($id: ID!) {
				...people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef
					@arguments(id: $id, searchBy: "")
			}
		`,
		{ id: props.fieldId },
		{ fetchPolicy: 'store-only' },
	);

	return (
		// @ts-expect-error - TS2322 - The expected type comes from property 'suggestionsFragmentRef' which is declared here on type 'IntrinsicAttributes & Omit<PeopleEditViewWithFieldOptionsFragmentProps & RefAttributes<AtlaskitSelectRefType>, "ref"> & PeopleEditViewWithFieldOptionsFragmentPropsOld & RefAttributes<...>'
		<PeopleEditViewWithFieldOptionsFragment {...props} suggestionsFragmentRef={suggestionsData} />
	);
};

const PeopleEditViewNew = forwardRef(
	(props: PeopleEditViewProps, ref: Ref<AtlaskitSelectRefType>) => {
		const suggestionsData = useLazyLoadQuery<PeopleEditRefetchQuery>(
			graphql`
				query people_peopleEditSearchQuery($id: ID!) {
					...people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentNew_suggestionsFragmentRef
						@arguments(id: $id, searchBy: "")
				}
			`,
			{ id: props.fieldId },
			{ fetchPolicy: 'store-only' },
		);

		return (
			<PeopleEditViewWithFieldOptionsFragment
				{...props}
				// @ts-expect-error - TS2322 - The expected type comes from property 'suggestionsFragmentRef' which is declared here on type 'IntrinsicAttributes & Omit<PeopleEditViewWithFieldOptionsFragmentProps & RefAttributes<AtlaskitSelectRefType>, "ref"> & PeopleEditViewWithFieldOptionsFragmentPropsOld & RefAttributes<...>'
				suggestionsFragmentRef={suggestionsData}
				ref={ref}
			/>
		);
	},
);

export const PeopleEditView = componentWithCondition(
	isRelayedEnabledPeopleEdit,
	PeopleEditViewNew,
	PeopleEditViewOld,
);

const PeopleEditViewEntryPoint = ({ props }: EntryPointProps<{}, {}, PeopleEditViewProps, {}>) => {
	return <PeopleEditView {...props} />;
};

export default PeopleEditViewEntryPoint;
