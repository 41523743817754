import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { ErrorBoundary } from '../../common/ui/error-boundary/index.tsx';
import {
	DevOpsSummaryExperienceStart,
	useDevOpsSummaryExperienceFail,
} from '../../services/experience-tracking/index.tsx';
import type { Props } from './types.tsx';

const LazyDevOpsSummaryContainer = lazyAfterPaint(() =>
	import(
		/* webpackChunkName: "async-dev-ops-summary-dev-ops-summary-container" */
		'./index'
	).then((module) => module.DevOpsSummaryContainer),
);

export const AsyncDevOpsSummaryContainer = (props: Props) => {
	const onFail = useDevOpsSummaryExperienceFail();
	return (
		<>
			<DevOpsSummaryExperienceStart location={props.location} />
			<ErrorBoundary fallback={() => <>{props.children}</>} onError={onFail}>
				<Placeholder name="dev-ops-summary-container" fallback={props.children || null}>
					<LazyDevOpsSummaryContainer {...props} />
				</Placeholder>
			</ErrorBoundary>
		</>
	);
};
