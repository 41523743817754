import { styled } from '@compiled/react';
import { colors, typography } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const headerWordWrap = {
	/* Style only for Google Chrome */
	'@media screen and (-webkit-min-device-pixel-ratio: 0)': {
		wordBreak: 'break-word',
	},
	/* Style only for Safari */
	'@media screen and (-webkit-transition)': {
		wordBreak: 'break-word',
	},
	/* Style only for Mozilla Firefox */
	'@-moz-document url-prefix()': {
		overflowWrap: 'anywhere',
	},
} as const;

// Hard coding H700 styles as Compiled cannot evaluate styles in typography
// because it depends on theme from a different API. Normally we would use
// import `Heading from '@atlaskit/heading'` as a workaround
// however it won't work here as it's used in `> textarea`
const summaryTextFontStyles = {
	fontSize: typography.headingSizes.h700.size,
	lineHeight: `${typography.headingSizes.h700.lineHeight}px`,
	fontWeight: 500,
	letterSpacing: '-0.01em',
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummaryHeading = styled.h1(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	summaryTextFontStyles,
	{
		width: '100%',
		margin: 0,
		padding: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text', colors.N800),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	headerWordWrap,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummaryHeadingNew = styled.h1({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '1em',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> textarea': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...summaryTextFontStyles,
		margin: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text', colors.N800),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...headerWordWrap,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		lineHeight: '28px !important',
		/* workaround for Firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=33654
           FF adds height to the TextArea to reserve place for scroll-bars.
           Setting overflow-x to hidden tells Firefox to not reserve any height for scroll bars.
           @atlaskit/textarea component has a custom script that always ensure that the textarea
           is shown fully hence there is no need for scrollbars.
        */
		overflowX: 'hidden',
	},
	width: '100%',
});
