import React, { useRef, useState, useEffect } from 'react';

type Props = {
	onVisibilityChange: (arg1: boolean) => void;
};

const Intersection = ({ onVisibilityChange }: Props) => {
	const [isIntersecting, setIsIntersecting] = useState(false);
	const observer = useRef<unknown>();
	const component = useRef<unknown>();

	const setupObserver = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (window.IntersectionObserver && component.current) {
			const handleIntersect = (entries: Array<IntersectionObserverEntry>) => {
				entries.forEach((entry) => {
					if (entry.target === component.current) {
						setIsIntersecting(entry.isIntersecting);
					}
				});
			};

			const options = {
				root: null,
				rootMargin: '0px',
				threshold: 0,
			};

			observer.current = new IntersectionObserver(handleIntersect, options).observe(
				// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Element'.
				component.current,
			);
		}
	};

	useEffect(() => {
		onVisibilityChange(isIntersecting);
	}, [isIntersecting, onVisibilityChange]);

	useEffect(() => {
		setupObserver();
		const node = component.current;

		return () => {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			observer.current?.unobserve(node);
		};
	}, []);

	// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
	return <div ref={component} />;
};

export default Intersection;
