import type { StringValue } from '@atlassian/jira-issue-field-summary-common/src/services/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export type IssueForFlagWithCommentModal = {
	readonly issueId: IssueId;
	readonly issueKey: string;
	readonly summary: StringValue;
	readonly type: FilterIssueType;
};
export type FilterIssueType = {
	readonly id: string;
	readonly name: string;
	readonly iconUrl: string;
};
export const FlagAction = {
	ADD: 'ADD',
	REMOVE: 'REMOVE',
} as const;
export type FlagAction = (typeof FlagAction)[keyof typeof FlagAction];
