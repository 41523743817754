import React, { useEffect, useMemo } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { issueEditorEntrypoint } from './entrypoint.tsx';
import type { OwnProps } from './index.tsx';

const entryPointParams = {};

export const LazyIssueEditor = (props: OwnProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		issueEditorEntrypoint,
		entryPointParams,
	);
	const runtimeProps = useMemo(() => props, [props]);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="lazyIssueEditor"
			packageName="issueViewCommon"
			errorFallback="unmount"
			teamName="team-bento"
			runtimeProps={runtimeProps}
		/>
	);
};
