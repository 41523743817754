import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { MyPreferences } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import type {
	JiraIssueViewAttachmentPanelViewMode,
	JiraIssueViewTimestampDisplayMode,
} from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AggResponseData } from '../types/issue-type.tsx';

/**
 * The logic for the following transformations is based on the AGG data fetchers that can be found in this directory:
 * https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-plugins/jira-graphql/issue-view-data-fetchers/core/src/main/java/com/atlassian/jira/graphql/gira/userpreferences
 */

const logError = (msg: string) =>
	log.safeErrorWithoutCustomerData('issue.agg-field-transformers.my-preferences', msg);

const toDismissedOrNull = (aggValue: boolean | null): 'dismissed' | null =>
	aggValue ? 'dismissed' : null;

const toBoolStrOrNull = (aggValue: boolean | null): 'true' | 'false' | null => {
	switch (aggValue) {
		case true:
			return 'true';
		case false:
			return 'false';
		default:
			return null;
	}
};

// Returns null if input is not a valid timestamp
const tryParseTimestamp = (timestampStr: string): string | null => {
	const parsed: number = Date.parse(timestampStr);

	return Number.isNaN(parsed) ? null : parsed.toString();
};

const toLegacyPinnedFieldsBannerLastInteracted = (
	aggIsDismissed: boolean | null,
	aggLastInteracted: string | null,
): string | null => {
	if (aggIsDismissed === null && aggLastInteracted === null) {
		return null;
	}

	if (aggIsDismissed === true) {
		return 'jira.user.issue.pinned-fields-banner.user_hidden_action';
	}

	if (aggLastInteracted) {
		const timestampOrNull = tryParseTimestamp(aggLastInteracted);

		timestampOrNull ||
			logError(
				`Couldn't parse timestamp string for pinned fields banner last interacted: '${aggLastInteracted}'`,
			);

		return timestampOrNull;
	}

	logError('Expected aggLastInteracted to be a non-empty string');
	return null;
};

const toLegacyAttachmentViewMode = (
	aggValue: JiraIssueViewAttachmentPanelViewMode | null,
): 'strip-view' | 'list-view' | null => {
	switch (aggValue) {
		case 'LIST_VIEW':
			return 'list-view';
		case 'STRIP_VIEW':
			return 'strip-view';
		default:
			return null;
	}
};

const toLegacyTimestampDisplayMode = (
	aggValue: JiraIssueViewTimestampDisplayMode | null,
): 'RELATIVE' | 'ABSOLUTE' | null => {
	switch (aggValue) {
		case 'ABSOLUTE':
		case 'RELATIVE':
			return aggValue;
		default:
			return null;
	}
};

export const transformAggUserPreferencesToLegacyGira = (
	aggResponse: AggResponseData,
	projectKey: ProjectKey,
): MyPreferences | undefined => {
	const aggUserPrefs = aggResponse.jira.userPreferences;

	if (!aggUserPrefs) return undefined;

	// @ts-expect-error - Type '{ [x: string]: string | null | undefined; 'jira.user.issue.child-issues-limit.best-practice-flag.dismissed': "dismissed" | null; ... 4 more ...; 'jira.user.issue.details.timestamp-display-setting': "RELATIVE" | ... 1 more ... | null; }' is not assignable to type 'MyPreferences'.
	return {
		'jira.user.issue.child-issues-limit.best-practice-flag.dismissed': toDismissedOrNull(
			// @ts-expect-error - Argument of type 'boolean | null | undefined' is not assignable to parameter of type 'boolean | null'.
			aggUserPrefs.isIssueViewChildIssuesLimitBestPracticeFlagDismissed,
		),
		'jira.user.issue.filter.hide-done-subtasks': toBoolStrOrNull(
			// @ts-expect-error - Argument of type 'boolean | null | undefined' is not assignable to parameter of type 'boolean | null'.
			aggUserPrefs.isIssueViewHideDoneChildIssuesFilterEnabled,
		),
		'jira.user.issue.pinned-fields-banner.lastinteracted': toLegacyPinnedFieldsBannerLastInteracted(
			// @ts-expect-error - Argument of type 'boolean | null | undefined' is not assignable to parameter of type 'boolean | null'.
			aggUserPrefs.isIssueViewPinnedFieldsBannerDismissed,
			aggUserPrefs.issueViewPinnedFieldsBannerLastInteracted,
		),
		'jira.user.issue.attachment.view': toLegacyAttachmentViewMode(
			// @ts-expect-error - Argument of type 'JiraIssueViewAttachmentPanelViewMode | null | undefined' is not assignable to parameter of type 'JiraIssueViewAttachmentPanelViewMode | null'.
			aggUserPrefs.issueViewAttachmentPanelViewMode,
		),
		[`jira.user.issue.details.pinned-fields.${projectKey}`]: aggUserPrefs.issueViewPinnedFields,
		'jira.user.issue.sidebar.resize-ratio': aggUserPrefs.issueViewSidebarResizeRatio,
		'jira.user.issue.details.timestamp-display-setting': toLegacyTimestampDisplayMode(
			// @ts-expect-error - Argument of type 'JiraIssueViewTimestampDisplayMode | null | undefined' is not assignable to parameter of type 'JiraIssueViewTimestampDisplayMode | null'.
			aggUserPrefs.issueViewTimestampDisplayMode,
		),
		'jira.user.issue.pinned-fields.banner.project': fg('jira_pin_field_configurability')
			? aggUserPrefs.issueViewDefaultPinnedFieldsBannerProject
			: '',
	};
};
