import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { SectionHeadingTitle } from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import {
	useProjectKey,
	useProjectType,
	useIsSimplifiedProject,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import messages from './messages.tsx';

type TitleProps = {
	title?: MessageDescriptor;
	/** Updated Title for former 'Issues in this epic' for Epic renaming beta */
	titleChildIssues?: MessageDescriptor;
};

const testId = 'issue.views.common.child-issues-panel.heading.title';

export const Title = ({ title = messages.title, titleChildIssues }: TitleProps) => {
	const { formatMessage } = useIntl();

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const isSimplified = useIsSimplifiedProject(projectKey);

	return (
		<SectionHeadingTitle data-testid={testId}>
			{projectType === 'software' && !isSimplified && titleChildIssues
				? formatMessage(titleChildIssues)
				: formatMessage(title)}
		</SectionHeadingTitle>
	);
};
