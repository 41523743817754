import React from 'react';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { LOAD_START_MARK_KEY } from './common/constants.tsx';
import type IssueRestrictionsDialog from './ui/index.tsx';
import type { Props } from './ui/types.tsx';

const LazyComponent = lazyForPaint<typeof IssueRestrictionsDialog>(
	() => import(/* webpackChunkName: "async-issue-restrictions" */ './index'),
);

export const AsyncIssueRestrictionComponent = (props: Props) => (
	<JSErrorBoundary id="async-issue-restrictions" packageName="issue-restrictions">
		<Placeholder
			name="issue-restrictions"
			fallback={<PerformanceMark metricKey={LOAD_START_MARK_KEY} />}
		>
			<LazyComponent {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default AsyncIssueRestrictionComponent;
