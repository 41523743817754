import head from 'lodash/head';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

const getUpdatedState = (state: State, fieldKey: FieldKey, label: string) => ({
	...state,
	fields: {
		...state.fields,
		[fieldKey]: {
			...state.fields[fieldKey],
			label,
		},
	},
});

export const updateLabel =
	(fieldKey: FieldKey, label: string, createAnalyticsEvent: CreateUIAnalyticsEvent) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ fieldRemote, ideaTypes }: Props,
	): Promise<boolean | Error> => {
		try {
			const state = getState();
			if (!state.fields[fieldKey] || state.fields[fieldKey].label === label) {
				return false;
			}

			const issueType = head(ideaTypes);
			if (issueType === undefined) {
				return false;
			}

			await fieldRemote.updateField({
				fieldKey,
				issueTypeId: parseInt(issueType.jiraIssueTypeId, 10),
				name: label,
			});

			setState(getUpdatedState(getState(), fieldKey, label));

			fireCompoundAnalyticsEvent.FieldConfig.labelChangedSuccess(createAnalyticsEvent({}), {
				issueFieldKey: fieldKey,
				issueFieldType: state.fields[fieldKey].type,
			});

			return true;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			fireCompoundAnalyticsEvent.FieldConfig.labelChangedFailure(createAnalyticsEvent({}));

			return e;
		}
	};
