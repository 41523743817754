import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { SingleUserPickerInlineEditView } from '@atlassian/jira-issue-field-single-user-picker-inline-edit-full/src/ui/single-user-picker/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutUserField_IssueViewUserField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutUserField_IssueViewUserField.graphql';

export interface IssueViewUserFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutUserField_IssueViewUserField$key;
}

export const IssueViewUserField = ({ area, fragmentKey }: IssueViewUserFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutUserField_IssueViewUserField$key>(
		graphql`
			fragment ui_issueViewLayoutUserField_IssueViewUserField on JiraSingleSelectUserPickerField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef
				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const onSubmit = useCallback(
		(value: AggUser | null) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggUser | null) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(() => {
		issueId && fieldChangeFailed(issueId, data.fieldId);
	}, [data.fieldId, fieldChangeFailed, issueId]);

	return (
		<IssueViewFieldHeading
			area={area}
			fragmentKey={data}
			testId={`issue.issue-view-layout.issue-view-user-field.${data.fieldId}.wrapper`}
		>
			<Box xcss={fieldWrapperStyles} testId={`issue.views.field.user.${data.fieldId}`}>
				<SingleUserPickerInlineEditView
					fragmentRef={data}
					onSubmit={onSubmit}
					onSubmitSucceeded={onSubmitSucceeded}
					onSubmitFailed={onSubmitFailed}
				/>
			</Box>
		</IssueViewFieldHeading>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});
