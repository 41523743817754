import React, { useCallback, useEffect, useState, useRef, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { useSmartLinkLifecycleAnalytics } from '@atlaskit/link-analytics';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { token } from '@atlaskit/tokens';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { smoothScrollIntoCenterIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import { webLinksAnalyticAttributes } from '@atlassian/jira-issue-view-common-views/src/smart-link-content/smart-link-analytics.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { getFavIconUrl, prependHttp, validateUrl } from '../util.tsx';
import WebLinkInlineFields from './inline-fields/index.tsx';
import messages from './messages.tsx';
import { StyledButton } from './styled.tsx';
import type { Props } from './types.tsx';

export const WebLinkCreateLinkView = ({
	linkedPagesCount,
	createLinkClickedCount,
	onSubmit,
	onCancel,
}: Props) => {
	const [href, setHref] = useState('');
	const [linkText, setLinkText] = useState('');
	const [isDisabled, setIsDisabled] = useState(true);
	const [isHrefValid, setisHrefValid] = useState(true);
	const linkAnalytics = useSmartLinkLifecycleAnalytics();

	// using previous values for properties passed down into functions which
	// were using prevProps
	const prevCreateLinkClickedCount = usePrevious<number>(createLinkClickedCount);
	const prevLinkedPagesCount = usePrevious<number>(linkedPagesCount);

	const childContainer = useRef<HTMLDivElement>(null);
	const { formatMessage } = useIntl();

	const focusOnInput = () => {
		if (childContainer.current !== null) {
			const input = childContainer.current.querySelector('input');
			if (input !== null) {
				input.focus({ preventScroll: true });
			}
		}
	};

	const updateDisabledState = useCallback((ref: string) => {
		const isDisabledLocal = isEmpty(ref) || !validateUrl(ref);
		setIsDisabled(isDisabledLocal);
	}, []);

	const scrollPanelIntoView = useCallback(() => {
		if (fg('quick_actions_menu_ga')) {
			setTimeout(() => {
				smoothScrollIntoCenterIfNeeded(childContainer.current);
				focusOnInput();
			}, 0);
		} else {
			smoothScrollIntoCenterIfNeeded(childContainer.current);
			focusOnInput();
		}
	}, [childContainer]);

	const mounted = useRef<boolean>(false);
	useEffect(() => {
		if (!mounted.current) {
			mounted.current = true;
			scrollPanelIntoView();
			updateDisabledState(href);
		} else {
			if (createLinkClickedCount !== prevCreateLinkClickedCount) {
				scrollPanelIntoView();
			}

			if (prevLinkedPagesCount !== undefined && linkedPagesCount > prevLinkedPagesCount) {
				setHref('');
				setisHrefValid(true);
				setLinkText('');
				setIsDisabled(true);
				focusOnInput();
			}
		}
	}, [
		createLinkClickedCount,
		href,
		linkedPagesCount,
		prevCreateLinkClickedCount,
		prevLinkedPagesCount,
		updateDisabledState,
		scrollPanelIntoView,
	]);

	const onHrefChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const hrefFromEvent = e.target.value;
			updateDisabledState(hrefFromEvent);
			setHref(hrefFromEvent);
			setisHrefValid(validateUrl(hrefFromEvent));
		},
		[updateDisabledState],
	);

	const onLinkTextChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			// there is a character limit on the endpoint of 255 for the title
			const linkTextFromEvent = e.target.value.slice(0, 255);
			if (linkTextFromEvent !== linkText) {
				updateDisabledState(href);
				setLinkText(linkTextFromEvent);
			}
		},
		[href, linkText, updateDisabledState],
	);

	const sendSmartLinkCreatedEvent = useCallback(
		(url: string) => {
			const details = { url };
			linkAnalytics?.linkCreated(details, null, webLinksAnalyticAttributes);
		},
		[linkAnalytics],
	);

	const handleOnSubmit = useCallback(() => {
		const trimmedHref = href.trim();
		const httpHref = prependHttp(trimmedHref);
		const iconUrl = getFavIconUrl(httpHref);

		onSubmit(httpHref, isEmpty(linkText.trim()) ? httpHref : linkText.trim(), iconUrl);
		sendSmartLinkCreatedEvent(httpHref);
	}, [href, linkText, onSubmit, sendSmartLinkCreatedEvent]);

	const closeAndReset = () => {
		setHref('');
		setisHrefValid(true);
		setLinkText('');
		setIsDisabled(true);
		onCancel();
	};

	const onEnter = isDisabled ? null : handleOnSubmit;
	return (
		<div ref={childContainer}>
			<EnterEscapeHandler onEscape={closeAndReset} onEnter={onEnter}>
				<WebLinkInlineFields
					href={href}
					linkText={linkText}
					isHrefValid={isHrefValid}
					onHrefChange={onHrefChange}
					onLinkTextChange={onLinkTextChange}
				/>
			</EnterEscapeHandler>
			<ButtonsContainer>
				<StyledButton
					appearance="primary"
					onClick={handleOnSubmit}
					isDisabled={isDisabled}
					/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
					testId="issue.views.issue-base.content.web-links.create-link.submit-button"
				>
					{formatMessage(messages.linkButton)}
				</StyledButton>
				<StyledButton
					appearance="subtle"
					onClick={closeAndReset}
					/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
					testId="issue.views.issue-base.content.web-links.create-link.cancel-button"
				>
					{formatMessage(messages.cancelButton)}
				</StyledButton>
			</ButtonsContainer>
		</div>
	);
};

const WebLinkCreateLinkViewWithSmartCardProvider = (props: Props) => (
	<SmartCardProvider>
		<WebLinkCreateLinkView {...props} />
	</SmartCardProvider>
);

export default WebLinkCreateLinkViewWithSmartCardProvider;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsContainer = styled.div({
	marginTop: token('space.100', '8px'),
	display: 'flex',
	justifyContent: 'flex-end',
});
