import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type LinkedAlertsLinkModal from './ui/link-modal/index.tsx';
import type LinkedAlertsField from './ui/linked-alerts-field/main.tsx';
import type { Props } from './ui/linked-alerts-field/types.tsx';
import type LinkedAlertsViewModal from './ui/view-modal/index.tsx';

const LazyAsyncLinkedAlertsField = lazyAfterPaint<typeof LinkedAlertsField>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-incident-management-linked-alerts-field" */ './ui/linked-alerts-field/main'
		),
);

export const AsyncLinkedAlertsField = (props: Props) => (
	<ErrorBoundary
		id="jira-servicedesk.incident-management.linked-alerts-field.async"
		packageName="AsyncLinkedAlertsField"
	>
		<Placeholder name="servicedesk-incident-management-linked-alerts-field" fallback={null}>
			<LazyAsyncLinkedAlertsField {...props} />
		</Placeholder>
	</ErrorBoundary>
);

const LazyAsyncLinkedAlertsViewModal = lazyAfterPaint<typeof LinkedAlertsLinkModal>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-incident-management-view-modal" */ './ui/view-modal/index'
		),
);

export const AsyncLinkedAlertsViewModal = () => (
	<ErrorBoundary
		id="jira-servicedesk.incident-management.linked-alerts-view-modal.async"
		packageName="AsyncLinkedAlertsViewModal"
	>
		<Placeholder name="servicedesk-incident-management-view-modal" fallback={null}>
			<LazyAsyncLinkedAlertsViewModal />
		</Placeholder>
	</ErrorBoundary>
);

const LazyAsyncLinkedAlertsLinkModal = lazyAfterPaint<typeof LinkedAlertsViewModal>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-incident-management-link-modal" */ './ui/link-modal/index'
		),
);

export const AsyncLinkedAlertsLinkModal = () => (
	<ErrorBoundary
		id="jira-servicedesk.incident-management.linked-alerts-link-modal.async"
		packageName="AsyncLinkedAlertsLinkModal"
	>
		<Placeholder name="servicedesk-incident-management-link-modal" fallback={null}>
			<LazyAsyncLinkedAlertsLinkModal />
		</Placeholder>
	</ErrorBoundary>
);
