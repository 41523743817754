import React from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import LikeIcon from '@atlaskit/icon/glyph/like';
import { colors } from '@atlaskit/theme';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import TransitiveNumber from '@atlassian/react-transitive-number';
import { FIELD_KEY } from '../../common/types.tsx';
import { useVoteField } from '../../services/vote-field-service/index.tsx';
import type { Props } from './types.tsx';

const VoteIcon = ({ isSelected = false, useVoteField: useVoteFieldFromProps }: Props) => {
	const issueKey = useIssueKey();

	const [fieldValue] = ff('relay-migration-issue-fields-votes_nsbqd')
		? [{}]
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useFieldValue({
				issueKey,
				fieldKey: FIELD_KEY,
			});
	const [{ value: voteValue }] = ff('relay-migration-issue-fields-votes_nsbqd')
		? (useVoteFieldFromProps || useVoteField)({})
		: [{ value: { votes: 0, hasVoted: false } }];

	const hasVoted = ff('relay-migration-issue-fields-votes_nsbqd')
		? voteValue.hasVoted
		: fieldValue.hasVoted;
	const votes = ff('relay-migration-issue-fields-votes_nsbqd') ? voteValue.votes : fieldValue.votes;

	return (
		<IconWrapper hasVoted={hasVoted} isSelected={isSelected}>
			<ButtonIcon>
				{hasVoted ? (
					<LikeIcon label="" size="medium" primaryColor={token('color.icon.selected', B400)} />
				) : (
					<LikeIcon label="" size="medium" />
				)}
			</ButtonIcon>
			<visibleForTesting.Counter
				data-testid="issue-field-voters.ui.icon.counter"
				hasVoters={votes !== 0}
			>
				<TransitiveNumber>{votes !== 0 ? votes : ''}</TransitiveNumber>
			</visibleForTesting.Counter>
		</IconWrapper>
	);
};

export default VoteIcon;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapperControl = styled.span<{ hasVoted: boolean; isSelected: boolean }>(
	{
		display: 'flex',
		alignItems: 'center',
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: any) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		props.hasVoted && !props.isSelected ? `color: ${token('color.icon.brand', colors.B500)};` : '',
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const IconWrapperExperiment = styled2.span<{ hasVoted: boolean; isSelected: boolean }>(
	{
		display: 'flex',
		alignItems: 'center',
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: any) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		props.hasVoted && !props.isSelected ? `color: ${token('color.icon.brand', colors.B500)};` : '',
);

const IconWrapper = styledComponentWithCondition(
	() => ff('compiled-react-migration-issue-view_u9myz'),
	IconWrapperExperiment,
	IconWrapperControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonIcon = styled2.span({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles
const CounterControl = styled.span<{ hasVoters?: boolean }>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: props.hasVoters ? '15px' : '0',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles
const CounterExperiment = styled2.span<{ hasVoters?: boolean }>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: props.hasVoters ? '15px' : '0',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	Counter: styledComponentWithCondition(
		() => ff('compiled-react-migration-issue-view_u9myz'),
		CounterExperiment,
		CounterControl,
	),
};
