/** @jsx jsx */
import React, { type ReactNode, useMemo, useCallback, type ReactElement } from 'react';
import { css, styled, jsx } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { ErrorMessage } from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import editMessages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIsModal } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import UserPickerView from '@atlassian/jira-issue-user-picker/src/main.tsx';
import type {
	UserOption,
	EmptyOption,
	UserOptionValue,
	Props as UserPickerProps,
} from '@atlassian/jira-issue-user-picker/src/types.tsx';
import WithProfileCardNext from '@atlassian/jira-issue-view-internal-profilecard-next/src/index.tsx';
import { ASSIGNEE_TYPE, REPORTER_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	REPORTED_ISSUE_ACTION,
	ASSIGNED_ISSUE_ACTION,
	VIEW_PROFILE_ACTION,
} from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import type { Actions } from '@atlassian/jira-profilecard-next/src/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import addIcon from './assets/add.png';
import InlineEditContainer from './internal/styled/inline-edit-container.tsx';
import ReadViewContainer, {
	NameWrapper,
	UserStatus,
	readViewContainerSelectorName,
} from './internal/styled/read-view-container.tsx';
import messages from './messages.tsx';

const AvatarFocusRingStyles = css({
	outlineOffset: '0',
	borderRadius: '50%',
	'&:focus-within': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		outline: `${token('space.025', '2px')} solid ${token('color.border.focused', colors.B100)}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span:focus': {
		outline: 'none',
	},
});

export type Props = {
	isMobile?: boolean;
	isEditable: boolean;
	isEditing: boolean;
	issueKey?: IssueKey; // TODO - make issueKey mandatory in BENTO-11149,
	label?: string;
	initialOptions?: UserOption[];
	emptyOption?: EmptyOption;
	invalidMessage?: string | null;
	value?: UserOptionValue;
	// eslint-disable-next-line jira/react/handler-naming
	customReadView?: (value: UserOptionValue) => ReactElement;
	renderAppEditView?: () => ReactElement;
	titleView?: ReactNode;
	// eslint-disable-next-line jira/react/handler-naming
	fieldContentWrapper?: (arg1: { children: ReactNode; topMargin?: number }) => ReactElement;
	portalElement?: HTMLElement;
	enablePeopleInvite?: boolean;
	// eslint-disable-next-line jira/react/handler-naming
	fetchSuggestions: (query: string, sessionId?: string) => Promise<UserOption[]>;
	showPinButton?: boolean;
	belowFieldView?: ReactNode;
	// Used to add additional analytics context for where the picker is used
	fieldId: string;
	onChange: (arg1: UserOptionValue) => void;
	onEditRequest: () => void;
	onConfirm: () => void;
	onCancel: () => void;
	emailLabel?: string;
	suggestEmailsForDomain?: string;
	allowEmail?: boolean;
	onCreateOption?: UserPickerProps['onCreateOption'];
};

export const UserInlineEditView = ({
	isMobile = false,
	label = '',
	initialOptions = [],
	emptyOption = null,
	invalidMessage = null,
	value = null,
	isEditing,
	isEditable,
	fieldId,
	titleView = label,
	showPinButton,
	belowFieldView,
	onCancel,
	customReadView,
	issueKey,
	fieldContentWrapper: FieldContentWrapper,
	portalElement,
	fetchSuggestions,
	onConfirm,
	enablePeopleInvite,
	onEditRequest,
	onChange,
	emailLabel,
	suggestEmailsForDomain,
	allowEmail,
	onCreateOption,
	renderAppEditView: renderAppEditViewFromProps,
}: Props) => {
	const { formatMessage } = useIntl();

	const isModal = useIsModal();

	const getActions = useCallback((): Actions[] | undefined => {
		if (fieldId === REPORTER_TYPE) {
			return [VIEW_PROFILE_ACTION, REPORTED_ISSUE_ACTION];
		}
		if (fieldId === ASSIGNEE_TYPE) {
			return [VIEW_PROFILE_ACTION, ASSIGNED_ISSUE_ACTION];
		}
		return undefined;
	}, [fieldId]);

	const getInvitePeopleOption = useCallback((): EmptyOption => {
		const { isAdmin } = getTenantContext_DEPRECATED_DO_NOT_USE();

		return {
			name: formatMessage(isAdmin ? messages.invitePeopleOptionAdmin : messages.invitePeopleOption),
			avatarUrl: addIcon,
		};
	}, [formatMessage]);

	const handleOnChange = useCallback(
		(lValue: UserOptionValue) => {
			onChange(lValue);
		},
		[onChange],
	);

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const handleOnDataRequest = useCallback((_isInitial?: boolean) => {}, []);

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const handleOnDataLoaded = useCallback((_isInitial = true, _resultsLength = 0) => {}, []);

	const handleOnEditRequested = useCallback(() => {
		onEditRequest();
	}, [onEditRequest]);

	const renderReadView = useCallback(() => {
		if (customReadView) {
			return customReadView(value);
		}

		const lValue = value || emptyOption || { avatarUrl: '', name: '' };

		return (
			<ReadViewContainer
				data-testId="read-view-container"
				data-component-selector={readViewContainerSelectorName}
			>
				<div css={AvatarFocusRingStyles}>
					<WithProfileCardNext
						userAccount={lValue}
						analyticsData={{ trigger: fieldId }}
						position="left-start"
						actions={getActions()}
					>
						<AvatarWrapper>
							<Avatar borderColor="transparent" src={lValue.avatarUrl} size="small" />
						</AvatarWrapper>
					</WithProfileCardNext>
				</div>
				<Box xcss={avatarPaddingStyles} />
				<NameWrapper data-testid={`issue.views.field.user.${fieldId}.name.wrapper`}>
					<span>{lValue.name}</span>
					{/* @ts-expect-error - TS2339 - Property 'active' does not exist on type '{ avatarUrl?: string | undefined; name: string; accountId?: string | undefined; }'. */}
					{lValue?.active === false && (
						<UserStatus>{`(${formatMessage(messages.deactivated)})`}</UserStatus>
					)}
				</NameWrapper>
			</ReadViewContainer>
		);
	}, [customReadView, emptyOption, fieldId, formatMessage, getActions, value]);

	const renderReadViewWithContentWrapper = useCallback(() => {
		if (FieldContentWrapper) {
			return <FieldContentWrapper>{renderReadView()}</FieldContentWrapper>;
		}
		return renderReadView();
	}, [FieldContentWrapper, renderReadView]);

	const renderEditViewInviteModalExp = useCallback(() => {
		const userPickerProps = {
			portalElement,
			emptyOption,
			initialOptions,
			fetchSuggestions,
			fieldId,
			value,
			onCancel,
			onSelection: onConfirm,
			width: '100%',
			onDataRequest: handleOnDataRequest,
			onDataLoaded: handleOnDataLoaded,
			onChange: handleOnChange,
			enablePeopleInvite,
			invitePeopleOption: enablePeopleInvite === true ? getInvitePeopleOption() : null,
			isIssueViewModal: isModal,
			emailLabel,
			suggestEmailsForDomain,
			allowEmail,
			onCreateOption,
		};

		return <UserPickerView {...userPickerProps} />;
	}, [
		portalElement,
		emptyOption,
		initialOptions,
		fetchSuggestions,
		fieldId,
		value,
		onCancel,
		onConfirm,
		handleOnDataRequest,
		handleOnDataLoaded,
		handleOnChange,
		enablePeopleInvite,
		getInvitePeopleOption,
		isModal,
		emailLabel,
		suggestEmailsForDomain,
		allowEmail,
		onCreateOption,
	]);

	const renderAppEditView = useMemo(() => {
		if (renderAppEditViewFromProps && fg('forge_cf_inline_edit_on_issue_view')) {
			return renderAppEditViewFromProps;
		}
		return null;
	}, [renderAppEditViewFromProps]);

	const renderEditView = renderAppEditView || renderEditViewInviteModalExp;

	const editButtonLabel = useMemo(() => {
		if (!isEditable) {
			return formatMessage(editMessages.disabledEditButtonLabel, {
				fieldName: label,
			});
		}

		if (value?.name) {
			return formatMessage(editMessages.extendedEditButtonLabel, {
				inputFieldValue: value.name,
				fieldName: label,
			});
		}

		if (emptyOption?.name) {
			return formatMessage(editMessages.extendedEditButtonLabel, {
				inputFieldValue: emptyOption.name,
				fieldName: label,
			});
		}

		return formatMessage(editMessages.editButtonLabel, {
			fieldName: label,
		});
	}, [emptyOption, formatMessage, isEditable, label, value]);

	return (
		<FieldWrapper data-testid={`issue.views.field.user.${fieldId}.wrapper`}>
			<FieldHeading fieldId={fieldId}>
				<FieldHeadingTitle>{titleView}</FieldHeadingTitle>
				{issueKey !== undefined && fieldId !== undefined && (
					<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={label} />
				)}
				{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
			</FieldHeading>
			<SideBySideField
				isEditing={isEditing}
				fixedHeight={!customReadView}
				hasTooltip
				data-testid={`issue.views.field.user.${fieldId}`}
			>
				{invalidMessage != null ? (
					<ErrorMessage key={`${fieldId}-error-message`}>{invalidMessage}</ErrorMessage>
				) : null}
				<Tooltip
					content={
						!isEditable
							? formatMessage(editMessages.disabledReporterTooltip, {
									fieldName: label,
								})
							: null
					}
					position="mouse"
				>
					<EnterEscapeHandler onEscape={onCancel}>
						<InlineEditContainer isEditable={isEditable}>
							<FieldInlineEditStateLess
								editView={isEditable ? renderEditView : () => null}
								readView={renderReadViewWithContentWrapper}
								hideActionButtons
								isEditing={isEditing}
								isEditable={isEditable}
								onEdit={handleOnEditRequested}
								readViewFitContainerWidth={!isMobile}
								editButtonLabel={editButtonLabel}
								confirmButtonLabel={formatMessage(editMessages.confirmButtonLabel, {
									fieldName: label,
								})}
								cancelButtonLabel={formatMessage(editMessages.cancelButtonLabel, {
									fieldName: label,
								})}
							/>
							{belowFieldView}
						</InlineEditContainer>
					</EnterEscapeHandler>
				</Tooltip>
			</SideBySideField>
		</FieldWrapper>
	);
};

const avatarPaddingStyles = xcss({
	paddingRight: 'space.100',
});

UserInlineEditView.displayName = 'UserInlineEditView';

export default UserInlineEditView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div({
	paddingTop: token('space.0', '0px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& span[role='img']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.neutral', colors.N30)} !important`,
	},
});
