import React, { useCallback, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import InlineDialog from '@atlaskit/inline-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import TextArea from '@atlaskit/textarea';
import { colors } from '@atlaskit/theme';
import { Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { SingleLineTextHeadingEditViewProps } from './types.tsx';

export const SingleLineTextHeadingEditView = ({
	onChange,
	value,
	invalidMessage,
	isInvalid,
	invalidMessageDialogPlacement,
	editViewInputWrapperTestId,
	label,
}: SingleLineTextHeadingEditViewProps) => {
	const handleOnChange = useCallback(
		(event: ChangeEvent<HTMLTextAreaElement>) => {
			onChange?.(event.target.value);
		},
		[onChange],
	);

	return (
		<InlineDialog
			content={invalidMessage}
			isOpen={isInvalid && !!invalidMessage}
			placement={invalidMessageDialogPlacement}
		>
			<EditViewContainer>
				<Box xcss={headingWrapperStyles}>
					<TextAreaWrapper data-testid={editViewInputWrapperTestId}>
						<TextArea
							autoFocus
							isInvalid={isInvalid}
							value={value || ''}
							onChange={handleOnChange}
							minimumRows={1}
							aria-label={label}
						/>
					</TextAreaWrapper>
				</Box>
				{invalidMessage && (
					<WarningIcon size="medium" primaryColor={token('color.icon.warning', Y300)} label="" />
				)}
			</EditViewContainer>
		</InlineDialog>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	lineHeight: 1,
	maxWidth: '100%',
	wordBreak: 'break-word',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		position: 'absolute',
		right: token('space.100', '8px'),
	},
});

const textAreaStyle = {
	font: token('font.heading.large'),
	letterSpacing: '-0.01em',
	margin: 0,
	color: token('color.text', colors.N800),
	overflowWrap: 'break-word',
	lineHeight: '28px !important',
	/* workaround for Firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=33654
           FF adds height to the TextArea to reserve place for scroll-bars.
           Setting overflow-x to hidden tells Firefox to not reserve any height for scroll bars.
           @atlaskit/textarea component has a custom script that always ensure that the textarea
           is shown fully hence there is no need for scrollbars.
        */
	overflowX: 'hidden',
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextAreaWrapper = styled.h1({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'> textarea': textAreaStyle,
});

const headingWrapperStyles = xcss({
	width: '100%',
});
