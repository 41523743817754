import 'rxjs/add/operator/map';
import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson$, {
	type JiraFetchOptions,
} from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';

import {
	checkEmptyResponse,
	getGraphQlData,
} from '@atlassian/jira-providers-issue/src/source/issue-express/graphql-util.tsx';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { isJsmTimelineEnabled } from '../feature-flags.tsx';
import { jiraCommentVisibility } from './gira/comments/graphql.tsx';
import { transformData, transformGiraData } from './gira/comments/index.tsx';

const LOG_LOCATION = 'issue.comment.fetch.epic.graphql';

const CREATED_ORDER_DESCENDING = '-created';

export const getSortedCommentsGiraQuery = ({
	startAt,
	maxResults,
	orderBy,
	commentId,
	commentAmount,
}: {
	startAt: number;
	maxResults?: number;
	orderBy?: string;
	commentId?: string;
	commentAmount?: number;
}) => {
	const firstParameter = `first: ${startAt}`;

	const orderByParameter =
		orderBy !== null && orderBy !== undefined ? `, orderBy: "${orderBy}"` : '';

	const commentIdParameter =
		commentId !== null && commentId !== undefined ? `, commentId: "${commentId}"` : '';

	const commentAmountParameter =
		commentIdParameter !== '' && commentAmount !== null && commentAmount !== undefined
			? `, commentAmount: ${commentAmount}`
			: '';

	const maxResultsParameter =
		commentIdParameter === '' && maxResults !== null && maxResults !== undefined
			? `, maxResults: ${maxResults}`
			: '';

	const commentSourceParameter = ff('jira_threaded_comments_prereq_631yd')
		? ', requestSource: "web"'
		: '';

	return `query issueViewCommentPageLoadQuery($issueKey: String!) {
       viewIssue(issueKey: $issueKey) {
           comments (${firstParameter}${maxResultsParameter}${commentAmountParameter}${orderByParameter}${commentIdParameter}${commentSourceParameter}) {
               nodes {
                   id
                   author {
                       accountId
                       displayName
                       avatarUrl
                   }
                   updateAuthor {
                       accountId
                       displayName
                       avatarUrl
                   }
                    ${jiraCommentVisibility}
                   created
                   updated
                   jsdPublic
                   bodyAdf
                   jsdAuthorCanSeeRequest
				   ${ff('jira_threaded_comments_prereq_631yd') ? 'isDeleted' : ''}
				   ${ff('jira_threaded_comments_prereq_631yd') ? 'parentId' : ''}
				   ${ff('jira_threaded_comments_prereq_631yd') ? 'replies' : ''}
                   ${isJsmTimelineEnabled() ? 'eventOccurredAt' : ''}
                   ${isJsmTimelineEnabled() ? 'jsdIncidentActivityViewHidden' : ''}
               }
               startIndex
               totalCount
			   ${ff('jira_threaded_comments_prereq_631yd') ? 'rootCommentsCount' : ''}
           }
       }
     }
   `;
};

export const sortedFieldDataOptionsGira = ({
	issueKey,
	maxResults,
	startAt,
	orderBy,
	commentId,
	commentAmount,
}: {
	issueKey: string;
	startAt: number;
	maxResults?: number;
	orderBy?: string;
	commentId?: string;
	commentAmount?: number;
}): JiraFetchOptions => ({
	method: 'POST',
	body: JSON.stringify({
		query: getSortedCommentsGiraQuery({
			maxResults,
			startAt,
			orderBy,
			commentId,
			commentAmount,
		}),
		variables: {
			issueKey,
		},
	}),
});

export const fetchMoreCommentsFromGira = ({
	baseUrl,
	issueKey,
	maxResults,
	startAt = 0,
	loadedComments = 0,
	commentsStartIndex = 0,
	commentId,
	type,
}: {
	baseUrl: BaseUrl;
	issueKey: string;
	maxResults: number;
	startAt?: number;
	loadedComments?: number;
	commentsStartIndex?: number;
	commentId?: string;
	type?: string;
}) =>
	fetchJson$(
		`${baseUrl}/rest/gira/1/`,
		sortedFieldDataOptionsGira({
			issueKey,
			maxResults,
			startAt,
			orderBy: CREATED_ORDER_DESCENDING,
			commentId,
		}),
	)
		// @ts-expect-error - TS2345 - Argument of type '<T extends ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>(response: GraphqlResponse<T>) => {    data: T;    errors: unknown;}' is not assignable to parameter of type '(value: unknown, index: number) => { data: ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse; errors: unknown; }'.
		.map(checkEmptyResponse)
		// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'GraphqlResponse<ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>'.
		.map((response) => getGraphQlData(LOG_LOCATION, response))
		.map((data) =>
			transformGiraData(
				// @ts-expect-error - TS2339 - Property 'viewIssue' does not exist on type 'ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse'.
				data.viewIssue,
				loadedComments,
				commentsStartIndex,
				type,
				CREATED_ORDER_DESCENDING,
			),
		);

export const fetchSortedCommentsFromGira = ({
	baseUrl,
	issueKey,
	maxResults,
}: {
	baseUrl: BaseUrl;
	issueKey: string;
	maxResults: number;
}) =>
	fetchJson$(
		`${baseUrl}/rest/gira/1/`,
		sortedFieldDataOptionsGira({
			issueKey,
			maxResults,
			startAt: 0,
			orderBy: CREATED_ORDER_DESCENDING,
			commentId: undefined,
		}),
	)
		// @ts-expect-error - TS2345 - Argument of type '<T extends ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>(response: GraphqlResponse<T>) => {    data: T;    errors: unknown;}' is not assignable to parameter of type '(value: unknown, index: number) => { data: ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse; errors: unknown; }'.
		.map(checkEmptyResponse)
		// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'GraphqlResponse<ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>'.
		.map((response) => getGraphQlData(LOG_LOCATION, response))
		.map((data) =>
			// @ts-expect-error - TS2339 - Property 'viewIssue' does not exist on type 'ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse'.
			transformGiraData(data.viewIssue, 0, 0, undefined, CREATED_ORDER_DESCENDING),
		);

export const fetchFocusedCommentFromGira = ({
	baseUrl,
	issueKey,
	commentId,
	commentAmount,
}: {
	baseUrl: BaseUrl;
	issueKey: string;
	commentId: string;
	commentAmount: number;
}) =>
	fetchJson$(
		`${baseUrl}/rest/gira/1/`,
		sortedFieldDataOptionsGira({
			issueKey,
			commentAmount,
			startAt: 0,
			orderBy: CREATED_ORDER_DESCENDING,
			commentId,
		}),
	)
		// @ts-expect-error - TS2345 - Argument of type '<T extends ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>(response: GraphqlResponse<T>) => {    data: T;    errors: unknown;}' is not assignable to parameter of type '(value: unknown, index: number) => { data: ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse; errors: unknown; }'.
		.map(checkEmptyResponse)
		// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'GraphqlResponse<ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>'.
		.map((response) => getGraphQlData(LOG_LOCATION, response))
		// @ts-expect-error - TS2339 - Property 'viewIssue' does not exist on type 'ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse'.
		.map((data) => transformData(data.viewIssue));
