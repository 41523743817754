import React, { Component } from 'react';
import { styled } from '@compiled/react';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	SideBySideField,
	FieldWrapper,
	FieldHeadingTitle,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import {
	InlineEditContainer,
	ReadViewContainer,
	READ_VIEW_CONTAINER_SELECTOR,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { DisabledInlineReadView } from '../epic/disabled-read-view/index.tsx';
import EditableField from './editable/index.tsx';
import type { ParentFieldType } from './types.tsx';

export type Props = {
	isEditable: boolean;
	isEditing: boolean;
	isMobile: boolean;
	issueKey?: IssueKey; // TODO - make issueKey mandatory in BENTO-11149,
	hasEpic: boolean;
	value: ParentFieldType;
	// eslint-disable-next-line jira/react/handler-naming
	fetchSuggestions: (query: string) => Promise<ParentFieldType[]>;
	portalElement?: HTMLElement;
	menuPlacement?: string;
	noValueText: string;
	fieldId: string;
	showPinButton?: boolean;
	label: string;
	onChange: (arg1: ParentFieldType | null) => void;
	onCancel: () => void;
	onConfirm: () => void;
	onEditRequest: () => void;
};

type State = {
	value: ParentFieldType;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditParentContainer = styled(InlineEditContainer)<{ isEditable: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) =>
		!isEditable && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			[READ_VIEW_CONTAINER_SELECTOR]: {
				lineHeight: 1,
				padding: '2px 0',
			},
		},
);

InlineEditParentContainer.displayName = 'InlineEditParentContainer';

// eslint-disable-next-line jira/react/no-class-components
export default class ParentField extends Component<Props, State> {
	shouldShowError = (): boolean =>
		this.props.hasEpic && this.props.value?.hasEpicLinkFieldDependency;

	renderEditableField() {
		const { onChange, onConfirm, fetchSuggestions, value, portalElement, menuPlacement } =
			this.props;

		// Using an extra span to explicitly mark this as not included by our selector.
		// We are using the custom wrapper + selector as a workaround until we can move
		// to using inline edits in compact mode.
		// See: https://jdog.jira-dev.com/browse/BENTO-2979
		return !this.shouldShowError() ? (
			<span>
				<EditableField
					value={value}
					loadOptions={fetchSuggestions}
					portalElement={portalElement}
					menuPlacement={menuPlacement}
					onChange={onChange}
					onConfirm={onConfirm}
				/>
			</span>
		) : null;
	}

	renderReadView = () => {
		return (
			<ReadViewContainer>
				<DisabledInlineReadView />
			</ReadViewContainer>
		);
	};

	render() {
		const {
			isEditing,
			onConfirm,
			onCancel,
			onEditRequest,
			isMobile,
			fieldId,
			label,
			showPinButton,
			issueKey,
		} = this.props;

		// The Parent Link field is being deprecated in favour of the Parent field
		// Regardless of whether it is editable or not, we should block the user from actually editing it
		// And render a read view that indicates to the user that they should use the Parent field instead.
		return (
			<FieldWrapper>
				<FieldHeading>
					<FieldHeadingTitle>{label}</FieldHeadingTitle>
					{issueKey !== undefined && fieldId !== undefined && (
						<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={label} />
					)}
					{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
				</FieldHeading>
				<SideBySideField isEditing={isEditing}>
					<EnterEscapeHandler onEscape={onCancel}>
						<InlineEditParentContainer isEditable={false}>
							<FieldInlineEditStateLess
								isLabelHidden
								label={label}
								readView={this.renderReadView()}
								editView={null}
								// @ts-expect-error - TS2322 - Property 'disableEditViewFieldBase' does not exist on type 'IntrinsicAttributes & FieldInlineEditStateLessProps<unknown>'.
								disableEditViewFieldBase
								areActionButtonsHidden
								isEditing={isEditing}
								onConfirm={onConfirm}
								onCancel={onCancel}
								onEditRequested={onEditRequest}
								isFitContainerWidthReadView={!isMobile}
								isEditable={false}
							/>
						</InlineEditParentContainer>
					</EnterEscapeHandler>
				</SideBySideField>
			</FieldWrapper>
		);
	}
}
