/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import { type DocNode } from '@atlaskit/adf-schema';
import Button from '@atlaskit/button/new';
import type {
	Command,
	FloatingToolbarConfig,
	FloatingToolbarHandler,
	FloatingToolbarItem,
	FloatingToolbarSeparator,
} from '@atlaskit/editor-common/types';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import { NodeSelection } from '@atlaskit/editor-prosemirror/state';
import { findDomRefAtPos, findParentNode } from '@atlaskit/editor-prosemirror/utils';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';
import { akEditorFloatingPanelZIndex } from '@atlaskit/editor-shared-styles';
import CoreCloseIcon from '@atlaskit/icon/core/close';
import CoreMoreIcon from '@atlaskit/icon/core/show-more-horizontal';
import CoreThumbsDownIcon from '@atlaskit/icon/core/thumbs-down';
import CoreThumbsUpIcon from '@atlaskit/icon/core/thumbs-up';
import { fg } from '@atlaskit/platform-feature-flags';
import { ReactRenderer } from '@atlaskit/renderer';
import { RendererActionsContext } from '@atlaskit/renderer/actions';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { token } from '@atlaskit/tokens';
import { LogoSvg } from '@atlassian/generative-ai-modal/assets/Logo';

import { highlightSentence, unhighlightSentence } from '../pm-plugins/ai-spelling-grammar/commands';
import {
	insertSuggestionWithAnalytics,
	removeSuggestionWithAnalytics,
} from '../pm-plugins/ai-spelling-grammar/commands-with-analytics';
import { getPluginState as getSpellingAndGrammarPluginState } from '../pm-plugins/ai-spelling-grammar/plugin-factory';
import { type ProactiveAISentence } from '../pm-plugins/ai-spelling-grammar/states';
import {
	getBlockOrSentenceFromDiffObject,
	getSelectedDiffObject,
} from '../pm-plugins/ai-spelling-grammar/utils';
import { createOpenAIModalCommand } from '../pm-plugins/decoration/actions';
import { getPluginState as getDecorationPluginState } from '../pm-plugins/decoration/decoration-plugin-factory';
import { getAIExperienceButton } from '../pm-plugins/floating-toolbar-button/floating-toolbar-button';
import { improveWriting } from '../prebuilt/config-items-range/enhance/enhance';
import type { AIGlobalOptIn, EditorPluginAIProvider } from '../types';
import { getSupportedNodesForAIModal } from '../utils/ai-button';

import { AIFloatingToolbarFooter } from './floating-toolbar-footer/AIFloatingToolbarFooter';
import { messages } from './messages';
import { separator } from './seperator';

const jsonTransformer = new JSONTransformer();

type IconProps = { label: string; iconColor?: string };

const CloseIcon = ({ label }: IconProps) => (
	<CoreCloseIcon label={label} color={token('color.icon', '#44546F')} spacing="spacious" />
);
const MoreIcon = ({ label }: IconProps) => (
	<CoreMoreIcon label={label} spacing="spacious" color="currentColor" />
);

const ImproveSentenceIcon = ({ label }: IconProps) => {
	return <LogoSvg alt={label} />;
};

export const getFloatingToolbarConfig =
	({
		aiGlobalOptIn,
		editorPluginAIProvider,
	}: {
		aiGlobalOptIn: AIGlobalOptIn;
		editorPluginAIProvider: EditorPluginAIProvider;
	}): FloatingToolbarHandler =>
	(state, intl, providerFactory, processedConfigs) => {
		const pluginState = getSpellingAndGrammarPluginState(state) || {};
		const { isSpellingAndGrammarEnabled, selectedID, splitParagraphIntoSentences } = pluginState;

		const { product, handleFeedbackSubmission } = editorPluginAIProvider;

		if (isSpellingAndGrammarEnabled && state.selection.from === state.selection.to) {
			const decorationPluginState = getDecorationPluginState(state);
			if (decorationPluginState.modalDecorationSet === DecorationSet.empty) {
				const selectedDiff = getSelectedDiffObject(pluginState, state.selection.to);
				if (selectedDiff && selectedID) {
					const blockOrSentenceFromDiffObject = getBlockOrSentenceFromDiffObject(
						pluginState,
						selectedDiff,
					);
					const sentenceItems: FloatingToolbarConfig['items'] = [];

					if (splitParagraphIntoSentences && blockOrSentenceFromDiffObject) {
						/**
						 * If splitParagraphIntoSentences is true,
						 * blockOrSentenceFromDiffObject will return a ProactiveAISentence
						 * We use blockOrSentenceFromDiffObject to get extra metadata about
						 * the sentence for analytics
						 */
						const sentence = blockOrSentenceFromDiffObject as ProactiveAISentence;
						const openAIModalAndUnhighlightSentenceCommand: Command = (state, dispatch) =>
							createOpenAIModalCommand({
								state,
								configItem: improveWriting,
								aiGlobalOptIn,
								positions: {
									startPos: sentence.from,
									endPos: sentence.to,
								},
								lastTriggeredFrom: 'proactiveAIFloatingToolbar',
							})(state, dispatch) && // If modal opens, remove the hover sentence highlight
							unhighlightSentence()(state, dispatch);

						sentenceItems.push(separator(), {
							id: 'editor.ai.sg-improve-sentence',
							type: 'button',
							testId: 'ak-editor-ai-suggestion-toolbar-improve-sentence-button',
							icon: ImproveSentenceIcon,
							onClick: openAIModalAndUnhighlightSentenceCommand,
							onMouseEnter: highlightSentence(sentence),
							onMouseLeave: unhighlightSentence(),
							onFocus: highlightSentence(sentence),
							onBlur: unhighlightSentence(),
							title: intl.formatMessage(messages.improveSentenceToolbarButtonLabel),
							showTitle: true,
						});
					}

					const dropdownOptions = [
						{
							title: intl.formatMessage(messages.correctSuggestion),
							icon: (
								<CoreThumbsUpIcon
									label="thumbsupIcon"
									spacing="spacious"
									color={token('color.icon', '#44546F')}
								/>
							),
							onClick: () => {
								if (!handleFeedbackSubmission || !blockOrSentenceFromDiffObject) {
									return false;
								}
								handleFeedbackSubmission({
									sentiment: 'good-suggestion',
									getAIExperience: (hasUserConsent) => ({
										...(hasUserConsent
											? {
													originalText: selectedDiff.originalText,
													suggestionText: selectedDiff.text,
													selectedSentence: blockOrSentenceFromDiffObject.text,
												}
											: {}),
									}),
									editorAttributes: { product },
								});
								return true;
							},
						},
						{
							title: intl.formatMessage(messages.incorrectSuggestion),
							icon: (
								<CoreThumbsDownIcon
									label="thumbsdownIcon"
									spacing="spacious"
									color={token('color.icon', '#44546F')}
								/>
							),
							onClick: () => {
								if (!handleFeedbackSubmission || !blockOrSentenceFromDiffObject) {
									return false;
								}
								handleFeedbackSubmission({
									sentiment: 'bad-suggestion',
									getAIExperience: (hasUserConsent) => ({
										...(hasUserConsent
											? {
													originalText: selectedDiff.originalText,
													suggestionText: selectedDiff.text,
													selectedSentence: blockOrSentenceFromDiffObject.text,
												}
											: {}),
									}),
									editorAttributes: { product },
								});
								return true;
							},
						},
					];

					const nodeType = [
						state.schema.nodes.paragraph,
						state.schema.nodes.text,
						state.schema.nodes.heading,
						state.schema.nodes.taskItem,
						state.schema.nodes.decisionItem,
					];

					const { from, text, replacement, hasInlineNodes } = selectedDiff;
					const textToolbarItemButton: FloatingToolbarItem<Command> = {
						id: 'editor.ai.sg-suggestion',
						type: 'button',
						showTitle: true,
						testId: 'ak-editor-ai-suggestion-toolbar-suggestion-button',
						onClick: insertSuggestionWithAnalytics({
							schema: state.schema,
							selectedDiff,
							blockOrSentenceFromDiffObject,
							aiInteractionID: selectedID,
						}),
						title: text,
						focusEditoronEnter: true,
					};

					const toolbarItemButton: FloatingToolbarItem<Command> =
						hasInlineNodes && replacement && fg('platform_editor_ai_proactive_ai_md_plus')
							? {
									type: 'custom',
									render: (view) => {
										if (!view || !replacement) {
											return;
										}
										const { nodes } = view.state.schema;
										const { doc, paragraph } = nodes;

										const replacementDoc = doc.createAndFill(
											{},
											paragraph.createAndFill({}, replacement),
										);
										if (!replacementDoc) {
											return;
										}
										return (
											<Button
												spacing="compact"
												appearance="subtle"
												onClick={() => {
													insertSuggestionWithAnalytics({
														schema: state.schema,
														selectedDiff: selectedDiff,
														blockOrSentenceFromDiffObject,
														aiInteractionID: selectedID,
													})(view.state, view.dispatch);
												}}
											>
												<RendererActionsContext>
													<ReactRenderer
														document={jsonTransformer.encode(replacementDoc) as DocNode}
														useSpecBasedValidator
														dataProviders={providerFactory}
													/>
												</RendererActionsContext>
											</Button>
										);
									},
									fallback: [textToolbarItemButton],
								}
							: textToolbarItemButton;

					const items: FloatingToolbarConfig['items'] = [
						toolbarItemButton,
						...sentenceItems,
						separator(),
						{
							id: 'editor.ai.sg-dismiss',
							type: 'button',
							testId: 'ak-editor-ai-suggestion-toolbar-dismiss-button',
							icon: CloseIcon,
							onClick: removeSuggestionWithAnalytics({
								schema: state.schema,
								selectedDiff,
								blockOrSentenceFromDiffObject,
								aiInteractionID: selectedID,
							}),
							title: intl.formatMessage(messages.dismissSuggestion),
							focusEditoronEnter: true,
						},
						separator(),
						{
							type: 'dropdown',
							title: 'More options',
							id: 'editor.ai.sg-dropdown',
							icon: MoreIcon,
							options: dropdownOptions,
							hideExpandIcon: true,
							alignDropdownWithToolbar: false,
							dropdownWidth: 200, // Width of the dropdown at the moment hardcoded to 200px
							footer: <AIFloatingToolbarFooter />,
						},
					];

					return {
						title: 'Spelling & Grammar controls',
						getDomRef: (editorView) =>
							findDomRefAtPos(from, editorView.domAtPos.bind(editorView)) as HTMLElement,
						nodeType,
						zIndex: akEditorFloatingPanelZIndex + 1, // Place the context menu slightly above the others
						items,
						scrollable: false,
						align: 'left',
					};
				}
			}
		}

		//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
		const { selection } = state;
		const { from, to } = selection;
		const isNodeSelection = selection instanceof NodeSelection;
		const isCellSelection = selection.toJSON().type === 'cell';
		const getParentSupportedForAIButton = () => {
			const supportedNodesForAI = getSupportedNodesForAIModal(state);

			if (isNodeSelection) {
				return supportedNodesForAI.has(selection.node.type) ? selection.node : undefined;
			} else {
				const parentNodeFindResult = findParentNode((node) => supportedNodesForAI.has(node.type))(
					selection,
				);
				return parentNodeFindResult?.node;
			}
		};
		const parentSupportedForAIButton = getParentSupportedForAIButton();
		if ((from === to || isNodeSelection || isCellSelection) && parentSupportedForAIButton) {
			if (
				editorExperiment('platform_editor_ai_ai_button_block_elements', 'test', { exposure: true })
			) {
				const canAddAIButton = isNodeSelection || parentSupportedForAIButton;
				const nodeType = parentSupportedForAIButton?.type;
				if (canAddAIButton && nodeType) {
					const aiIconButton = getAIExperienceButton({
						intl,
						rangeBaseGenerate: editorPluginAIProvider.rangeConfig.baseGenerate,
						hideTitle: true,
						aiGlobalOptIn,
						triggeredFor: nodeType,
					});

					const addAIButtonToConfig = (title: string) => {
						const toolbarConfig = processedConfigs?.find((config) => config.title === title);
						const items = Array.isArray(toolbarConfig?.items) ? toolbarConfig?.items : [];
						if (toolbarConfig && items.length) {
							const withAIItems = [
								...items.slice(0, items.length - 3),
								aiIconButton,
								{
									type: 'separator',
								} as FloatingToolbarSeparator,
								...items.slice(items.length - 3, items.length),
							];
							/**
							 * Updating items of table, panel, layout or expand config item.
							 * Injecting updated items in same config object.
							 */
							toolbarConfig.items = withAIItems;
							return toolbarConfig;
						}
					};

					/**
					 * Here we are updating items of table, panel, expand and layout
					 * 	floaingToolbarConfig.
					 * This will only work when editor-plugin-ai is added after these plugins.
					 * Proper solution will be implemented when outcome
					 * 	of https://hello.atlassian.net/wiki/spaces/EDITOR/pages/4166466469/Editor+RFC+058+Easier+way+to+insert+items+in+floating+toolbar
					 *  RFC is finalised.
					 */
					const toolbarConfig =
						addAIButtonToConfig('Panel floating controls') ||
						addAIButtonToConfig('Table floating controls') ||
						addAIButtonToConfig('Layout floating controls') ||
						addAIButtonToConfig('Expand toolbar');

					if (toolbarConfig) {
						return toolbarConfig;
					}
				}
			}
		}

		return;
	};
