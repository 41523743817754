import React, { useState, useCallback, type FocusEvent } from 'react';
import Select from '@atlaskit/select';
import { formatOptionLabel } from '@atlassian/jira-issue-field-select-base/src/ui/format-option-label/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import type { Props } from '../../../common/types.tsx';

export const ProjectEditView = (props: Props) => {
	const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

	const {
		value,
		options,
		onChange,
		onFocus,
		onBlur,
		placeholder = '',
		autoFocus,
		isInvalid,
		spacing = 'compact',
		tabSelectsValue = false,
	} = props;

	const onSelectFocus = useCallback(
		(e: FocusEvent<HTMLElement>): void => {
			setMenuIsOpen(true);
			onFocus && onFocus(e);
		},
		[onFocus],
	);
	const onSelectBlur = useCallback(
		(e: FocusEvent<HTMLElement>): void => {
			setMenuIsOpen(false);
			onBlur && onBlur(e);
		},
		[onBlur],
	);

	return (
		<Select
			value={value}
			options={options}
			placeholder={placeholder}
			// @ts-expect-error - TS2322 - Type '((value: Option | null, _: undefined, analyticsEvent: UIAnalyticsEventInterface) => void) | undefined' is not assignable to type '((value: Option | null, action: ActionMeta<Option>) => void) | undefined'.
			onChange={onChange}
			onFocus={onSelectFocus}
			onBlur={onSelectBlur}
			autoFocus={autoFocus}
			formatOptionLabel={formatOptionLabel}
			isClearable
			hideSelectedOptions
			menuIsOpen={menuIsOpen}
			spacing={spacing}
			styles={defaultSelectStyles}
			// @ts-expect-error - TS2322 - Type '"error" | null' is not assignable to type 'ValidationState | undefined'.
			validationState={isInvalid === true ? 'error' : null}
			tabSelectsValue={tabSelectsValue}
		/>
	);
};
