import { AUTH_WINDOW_CLOSED_ERROR_TYPE } from './constants.tsx';
import type { AuthErrorType, AuthMessageData } from './types.tsx';

export class AuthError extends Error {
	public constructor(
		public readonly message: string,
		public readonly type?: AuthErrorType,
	) {
		super(message);
		this.name = 'AuthError';
		this.type = type;
		this.message = message;
	}
}

// Copy/pasted from @atlaskit/outbound-auth-flow-client package due to data.type mismatch.
export const auth = (startUrl: string, windowFeatures?: string): Promise<void> =>
	new Promise((resolve, reject) => {
		let authWindow: Window | null = null;
		let authWindowInterval: number;

		const handleAuthWindowMessage = (event: MessageEvent<AuthMessageData>) => {
			if (event.source !== authWindow) {
				return;
			}
			const { data } = event;
			if (typeof data !== 'object') {
				return;
			}

			if (data.type === 'auth:success') {
				finish();
				resolve();
				return;
			}

			finish();
			reject(new AuthError(data.message, data.errorType));
		};

		const handleAuthWindowInterval = () => {
			if (authWindow && authWindow.closed) {
				finish();
				reject(new AuthError('The auth window was closed', AUTH_WINDOW_CLOSED_ERROR_TYPE));
			}
		};

		const start = () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('message', handleAuthWindowMessage);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			authWindow = window.open(startUrl, startUrl, windowFeatures);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			authWindowInterval = window.setInterval(handleAuthWindowInterval, 500);
		};

		const finish = () => {
			clearInterval(authWindowInterval);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('message', handleAuthWindowMessage);
			if (authWindow) {
				authWindow.close();
				authWindow = null;
			}
		};

		start();
	});
