export type Emoji = {
	shortName: string;
	text: string;
};

export type CannedComment = {
	buttonTextKey:
		| 'looksGoodButton'
		| 'needHelpButton'
		| 'blockerButton'
		| 'clarifyButton'
		| 'onTrackButton'
		| 'takingALookButton'
		| 'onItButton';
	commentKey: 'looksGood' | 'needHelp' | 'blocker' | 'clarify' | 'onTrack' | 'takingALook' | 'onIt';
	emoji: Emoji;
};
export const CANNED_COMMENTS_KEY: CannedComment[] = [
	{
		buttonTextKey: 'looksGoodButton',
		commentKey: 'looksGood',
		emoji: { shortName: ':tada:', text: '🎉' },
	},
	{
		buttonTextKey: 'needHelpButton',
		commentKey: 'needHelp',
		emoji: { shortName: ':wave:', text: '👋' },
	},
	{
		buttonTextKey: 'blockerButton',
		commentKey: 'blocker',
		emoji: { shortName: ':no_entry:', text: '⛔' },
	},
	{
		buttonTextKey: 'clarifyButton',
		commentKey: 'clarify',
		emoji: { shortName: ':mag:', text: '🔍' },
	},
	{
		buttonTextKey: 'onTrackButton',
		commentKey: 'onTrack',
		emoji: { shortName: ':check_mark:', text: '✅' },
	},
];

export const ASSIGNED_USER_CANNED_COMMENTS_KEY: CannedComment[] = [
	{
		buttonTextKey: 'takingALookButton',
		commentKey: 'takingALook',
		emoji: { shortName: ':wave:', text: '👋' },
	},
	{
		buttonTextKey: 'onItButton',
		commentKey: 'onIt',
		emoji: { shortName: ':check_mark:', text: '✅' },
	},
	{
		buttonTextKey: 'clarifyButton',
		commentKey: 'clarify',
		emoji: { shortName: ':mag:', text: '🔍' },
	},
];

export const CANNED_COMMENTS_TYPE = {
	DEFAULT: 'default',
	ASSIGNED_USER: 'assignedUser',
};

export const APP_IDENTIFIER = 'jira-canned-comments';
