import {
	type Action,
	createStore,
	createContainer,
	createStateHook,
	createActionsHook,
	createHook,
} from '@atlassian/react-sweet-state';

export type RelatedIssue = {
	id: string;
	issueKey: string;
	issueLink: string;
};

export type ResourceSuggestion = {
	resource: RelatedIssue;
	isLinking: boolean;
	errorMessage?: unknown;
	resourceStatus: 'DRAFT' | 'ACCEPTED' | 'DISCARDED';
};

export type Step = 'HIDDEN' | 'DRAFT_DISPLAY' | 'ERROR_DISPLAY';
export const steps = {
	hidden: 'HIDDEN',
	draftDisplay: 'DRAFT_DISPLAY',
	errorDisplay: 'ERROR_DISPLAY',
} as const;

// State of the Related issues context
type State = {
	currentStep: Step;
	resourceSuggestions?: ResourceSuggestion[];
	isLoadingSuggestions: boolean;
	errorMessage: unknown | null;
	displayLinkedIssuesPanelCallback?: (arg: boolean) => void;
	existingIssues: string[];
};

const initialState: State = {
	currentStep: steps.hidden,
	resourceSuggestions: undefined,
	isLoadingSuggestions: false,
	errorMessage: null,
	existingIssues: [],
};

const setRelatedIssuesStep =
	(step: Step): Action<State> =>
	({ setState }) => {
		setState({ currentStep: step });
	};

const setRelatedIssuesDraftStep =
	(): Action<State> =>
	({ setState }) => {
		setState({ currentStep: steps.draftDisplay });
	};

const setLoadingSuggestion =
	(loading: boolean): Action<State> =>
	({ setState }) => {
		setState({ isLoadingSuggestions: loading });
	};

const isRelatedIssuesOpen =
	(): Action<State, void, Boolean> =>
	({ getState }) => {
		const { currentStep } = getState();
		return currentStep === steps.draftDisplay;
	};

const setExistingIssues =
	(issues: string[]): Action<State> =>
	({ setState }) => {
		setState({ existingIssues: issues });
	};

const setResourceSuggestion =
	(resources: RelatedIssue[]): Action<State> =>
	({ setState }) => {
		const resourceSuggestions: ResourceSuggestion[] = resources.map((resource) => ({
			resource,
			isLinking: false,
			resourceStatus: 'DRAFT',
		}));
		setState({ resourceSuggestions });
	};

const setErrorMessage =
	(message: unknown): Action<State> =>
	({ setState }) => {
		setState({ errorMessage: message });
	};

const acceptSuggestions =
	(): Action<State> =>
	({ setState }) => {
		setState({
			resourceSuggestions: [],
		});
	};

const discardSuggestion =
	(): Action<State> =>
	({ setState }) => {
		setState({
			resourceSuggestions: [],
		});
	};

const discardAllSuggestions =
	(): Action<State> =>
	({ getState, setState }) => {
		const { displayLinkedIssuesPanelCallback } = getState();
		displayLinkedIssuesPanelCallback?.(false);
		setState({ currentStep: steps.hidden });
	};

const resetState =
	(): Action<State> =>
	({ setState }) => {
		setState(initialState);
	};

const setDisplayLinkedIssuesPanelCallback =
	(cb: (arg: boolean) => void): Action<State> =>
	({ setState }) => {
		setState({ displayLinkedIssuesPanelCallback: cb });
	};

const actions = {
	isRelatedIssuesOpen,
	acceptSuggestions,
	discardSuggestion,
	discardAllSuggestions,
	setRelatedIssuesStep,
	setRelatedIssuesDraftStep,
	setLoadingSuggestion,
	resetState,
	setExistingIssues,
	setResourceSuggestion,
	setErrorMessage,
	setDisplayLinkedIssuesPanelCallback,
};
type ActionsType = typeof actions;

export const JiraIssueRelatedIssuesContainer = createContainer();
export const Store = createStore<State, ActionsType>({
	initialState,
	actions,
	name: 'jira-ai-related-issues',
	containedBy: JiraIssueRelatedIssuesContainer,
});

export const useRelatedIssues = createHook(Store);

export const useRelatedIssuesState = createStateHook(Store);

export const useRelatedIssuesActions = createActionsHook(Store);
