import React, { useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

export const GoalsCount = ({ goalCount }: { goalCount: number }) => {
	const numberOfGoals = useMemo(() => (goalCount > 1 ? `+${goalCount - 1}` : ''), [goalCount]);
	return (
		numberOfGoals && (
			<Box as="span" xcss={countStyles}>
				{numberOfGoals}
			</Box>
		)
	);
};

const countStyles = xcss({
	backgroundColor: 'color.background.disabled',
	fontWeight: 400,
	borderRadius: 'border.radius',
	lineHeight: 0.8632,
	padding: 'space.050',
	pointerEvents: 'none',
});
