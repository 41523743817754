import React, { type ComponentType } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';

const logLocationPrefix = 'issue.error-boundary.';
const componentErrorMessage = 'An uncaught error has occurred in a React component';

const getDisplayName = (UnsafeComponent: ComponentType) =>
	UnsafeComponent.displayName || UnsafeComponent.name || 'UnsafeComponent';

export class ErrorHandler {
	constructor(componentName: string) {
		this.logLocation = `${logLocationPrefix}${componentName}`;
	}

	logLocation: string;

	handleError = (error: Error, _componentStack?: string) =>
		log.safeErrorWithoutCustomerData(this.logLocation, componentErrorMessage, error);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	callSafely = <T extends (...args: any[]) => any>(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		thisArg: Record<any, any>,
		fn: T,
		...args: Parameters<T>
	) => {
		try {
			return fn && fn.apply(thisArg, args);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			this.handleError(e);
		}
		return null;
	};
}

const makeSafe = (
	UnsafeComponent: ComponentType,
	FallbackComponent: ComponentType<unknown> | null | undefined,
	componentNameOverride?: string,
) => {
	const componentName = componentNameOverride || getDisplayName(UnsafeComponent);

	const getDisplayNamePrefix = () => {
		if (componentNameOverride) {
			return 'Fallback';
		}
		if (FallbackComponent) {
			return 'SafeWithFallback';
		}
		return 'Safe';
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const SafeComponent = ({ ...props }: any) => (
		<ErrorBoundary
			prefixOverride="issue"
			id={`${getDisplayNamePrefix()}(${componentName})`}
			render={() => {
				if (!FallbackComponent) {
					return null;
				}
				// We should also catch errors in the fallback component
				const SafeFallback = makeSafe(FallbackComponent, null, componentName);
				return <SafeFallback {...props} />;
			}}
		>
			<UnsafeComponent {...props} />
		</ErrorBoundary>
	);
	SafeComponent.displayName = `${getDisplayNamePrefix()}(${componentName})`;

	return SafeComponent;
};

/**
 * Because the behaviour of `SafeComponent` is to render the default component
 * first if a re-render is triggered by a parent, the fallback's lifecycle methods
 * for updating will never be called - so only `componentWillMount`, (initial) `render`,
 * `componentDidMount`, and `componentWillUnmount` will fire as expected.
 *
 * N.B. A warning will be shown in the console if unsupported lifecycle methods are detected.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Fallback = ComponentType<any>;

type Options = {
	FallbackComponent?: Fallback;
};

/**
 * @deprecated Use an error boundary instead.
 *
 * Makes a React component safe by catching all errors thrown in the
 * render and lifecycle methods. The caught errors are logged
 * remotely and a fallback component can be rendered if provided,
 * otherwise the component is taken out of the render tree by
 * returning null in the SafeComponent render method.
 *
 * In the component stack, if we take the passed component's `displayName` to be, for e.g., 'ComponentDisplayName',
 * this component will appear as:
 *  - `Safe(ComponentDisplayName)` if there is no fallback provided
 *  - `SafeWithFallback(ComponentDisplayName)` if a fallback was provided and the default has not had an error
 *  - `Fallback(ComponentDisplayName)` if a fallback was provided and the default has had an error
 *
 * N.B. If a parent's re-render causes this component to re-render, the behaviour
 * will be as if it was an initial render regardless of whether the
 * fallback was rendered or not.
 *
 * Usage without a fallback:
 *
 *  - With `flowWithSafeComponent`:
 *  ```
 flowWithSafeComponent([
 connect(...),
 ])(MyComponent);
 ```
 *
 *  - With `_.flow`:
 *  ```
 flow(
 safeComponent(),
 connect(...),
 safeComponent(), //in case our mapXToY functions fail
 )(MyComponent);
 ```
 *
 *  - Invoking it directly:
 *  ```
 safeComponent()(connect(...)(safeComponent()(MyComponent)));
 injectIntl(safeComponent()(MyComponent));
 ```
 *
 * Usage with a fallback:
 *
 * `const Fallback = MyClassComponentFallback`
 * or
 * `const Fallback = props => "Functional component" //this can be used to render the fallback based on props e.g. from Connect`
 *
 *  - With `flowWithSafeComponent`:
 *  ```
 flowWithSafeComponent(
 [
 connect(...),
 ],
 Fallback
 )(MyComponent);
 ```
 *
 *  - With `_.flow`:
 *  ```
 flow(
 safeComponent(Fallback),
 connect(...),
 safeComponent(Fallback),
 )(MyComponent);
 ```
 *
 *  - Invoking it directly:
 *  ```
 safeComponent()(
 connect({
 ...
 })(safeComponent(Fallback)(MyComponent)),
 Fallback
 );

 injectIntl(safeComponent(Fallback)(MyComponent));
 ```
 */
export const safeComponent =
	(options: Options = {}) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	<T extends ComponentType<any>>(UnsafeComponent: T): T =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		makeSafe(UnsafeComponent, options.FallbackComponent) as T;
