import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import type { Positions } from './config-items';

/**
 * If there is an empty selection (no content is selected) then we
 * return a slice that contains the entire document. Else if there is a
 * range selection then we return a slice that contains the range selection.
 */
export function getSlice(editorView: EditorView, positions: Positions) {
	// If the positions are the same, then we know we don't have a normal range
	// selection, so we want to send the full document
	if (positions[0] === positions[1]) {
		return editorView.state.doc.slice(0, editorView.state.doc.content.size);

		// If the positions are different then we want to send in the positions as
		// this will be a selection the user made
	} else {
		return editorView.state.doc.slice(positions[0], positions[1]);
	}
}
