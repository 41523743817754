import type { DocNode } from '@atlaskit/adf-schema';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { FieldRollupOperation } from '@atlassian/jira-polaris-domain-field/src/rollup/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import type { SortMode } from '@atlassian/jira-polaris-domain-view/src/sort/types.tsx';
import type { PolarisTimelineMode } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type {
	ViewLayoutType,
	ViewKind,
} from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';

export type UpdateSort = {
	order: 'ASC' | 'DESC';
	field: FieldKey;
};

export const FILTER_KIND = {
	FIELD_IDENTITY: 'FIELD_IDENTITY',
	FIELD_NUMERIC: 'FIELD_NUMERIC',
	TEXT: 'TEXT',
	INTERVAL: 'INTERVAL',
} as const;

type FilterKindType = (typeof FILTER_KIND)[keyof typeof FILTER_KIND];

export type UpdateFilter = {
	kind: FilterKindType;
	field?: FieldKey;
	values: {
		value?: number | null;
		text?: string | null;
		operator?:
			| 'EQ'
			| 'NEQ'
			| 'GT'
			| 'GTE'
			| 'LT'
			| 'LTE'
			| 'END_AFTER_NOW'
			| 'END_BEFORE_NOW'
			| 'START_AFTER_NOW'
			| 'START_BEFORE_NOW';
	}[];
};

export type UpdateMatrixAxis = {
	field: FieldKey;
	dimension: string;
	fieldOptions: GroupValue[] | undefined;
	reversed: boolean | undefined;
};

export type UpdateMatrixConfig = {
	axes: UpdateMatrixAxis[];
};

export type UpdateTimelineConfig = {
	startDateField: FieldKey | undefined;
	dueDateField: FieldKey | undefined;
	mode: PolarisTimelineMode;
	startTimestamp: string | undefined;
	endTimestamp: string | undefined;
	summaryCardField: FieldKey | undefined;
};

export type UpdateTableColumnSize = {
	field: FieldKey;
	size: number;
};

export type UpdateFieldRollup = {
	field: FieldKey;
	rollup: FieldRollupOperation;
};

type ViewRequest = {
	name: string;
	description: DocNode | null;
	fields: FieldKey[];
	hidden?: FieldKey[];
	groupBy: FieldKey | null;
	verticalGroupBy: FieldKey | null;
	hideEmptyGroups: boolean;
	hideEmptyColumns: boolean;
	groupValues: GroupValue[];
	verticalGroupValues: GroupValue[];
	filter: UpdateFilter[];
	sort: UpdateSort[];
	sortMode?: SortMode;
	fieldRollups?: UpdateFieldRollup[];
	tableColumnSizes?: UpdateTableColumnSize[];
	matrixConfig?: UpdateMatrixConfig | null;
	timelineConfig?: UpdateTimelineConfig | null;
	enabledAutoSave: boolean;
	layoutType: ViewLayoutType | null;
};

export type CreateViewRequest = ViewRequest & {
	projectAri: string;
	kind: ViewKind;
};

export type UpdateViewRequest = ViewRequest & {
	uuid?: string;
	viewId: string;
	emoji: string | undefined;
	lastViewedTimestamp: string | null | undefined;
	lastCommentsViewedTimestamp: string | null | undefined;
};

export type Stakeholders = {
	accountIds: string[];
	emails: string[];
	groupIds: string[];
};
