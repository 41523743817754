import React, { useMemo } from 'react';
import AddIcon from '@atlaskit/icon/core/migration/add';
import AttachmentIcon from '@atlaskit/icon/core/migration/attachment';
import GlobeIcon from '@atlaskit/icon/core/migration/globe--world';
import PageIcon from '@atlaskit/icon/core/migration/page';
import { getCommandPaletteIssueActionsPriority } from '@atlassian/jira-command-palette-common/src/common/utils/get-command-palette-issue-actions-priority/index.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { COMMAND_PALETTE_REGISTRY_IDS } from '@atlassian/jira-command-palette-registry/src/common/constants/registry/index.tsx';
import { useRegisterCommands } from '@atlassian/jira-command-palette-registry/src/controllers/use-register-commands/index.tsx';
import {
	type Command,
	CommandActionType,
} from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { messages } from './messages.tsx';

type Props = {
	onAddAttachmentClick: () => void;
	onAddAUrlClick: () => void;
	onLinkConfluencePageClick: () => void;
	onAddChildCommand: () => void;
	isAttachCommandEnabled: boolean;
	isAddAUrlEnabled: boolean;
	isLinkConfluencePageEnabled: boolean;
	isAddChildEnabled: boolean;
};

export const useRegisterQuickAddInCommandPalette = ({
	onAddAttachmentClick,
	onAddAUrlClick,
	onLinkConfluencePageClick,
	onAddChildCommand,
	isAttachCommandEnabled,
	isAddAUrlEnabled,
	isLinkConfluencePageEnabled,
	isAddChildEnabled,
}: Props) => {
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();

	const { getKeywords } = useCommandKeywords();

	const addAttachmentCommand = useMemo(
		(): Command => ({
			id: `issue-add-attachment-${issueKey}`,
			name: formatMessage(messages.addAttachment),
			keywords: getKeywords('addAttachmentSynonyms'),
			primaryAction: {
				type: CommandActionType.PERFORM,
				perform: () => {
					onAddAttachmentClick();
				},
			},
			section: getCommandPaletteIssueActionsHeader(issueKey),
			priority: getCommandPaletteIssueActionsPriority('SUB_HEADER_ITEMS'),
			components: {
				LeftIcon: () => (
					<AttachmentIcon color="currentColor" label={formatMessage(messages.addAttachment)} />
				),
			},
			analytics: {
				action: 'addAttachment',
			},
		}),
		[formatMessage, getKeywords, issueKey, onAddAttachmentClick],
	);

	const addAUrlCommand = useMemo(
		(): Command => ({
			id: `issue-add-a-url-${issueKey}`,
			name: formatMessage(messages.addAUrl),
			keywords: getKeywords?.('addAUrlSynonyms'),
			primaryAction: {
				type: CommandActionType.PERFORM,
				perform: () => setTimeout(onAddAUrlClick, 0),
			},
			section: getCommandPaletteIssueActionsHeader(issueKey),
			priority: getCommandPaletteIssueActionsPriority('SUB_HEADER_ITEMS'),
			components: {
				LeftIcon: () => <GlobeIcon color="currentColor" label={formatMessage(messages.addAUrl)} />,
			},
			analytics: {
				action: 'addIssueUrl',
			},
		}),
		[formatMessage, getKeywords, issueKey, onAddAUrlClick],
	);

	const linkConfluencePageCommand = useMemo(
		(): Command => ({
			id: `issue-link-confluence-page-${issueKey}`,
			name: formatMessage(messages.linkConfluencePage),
			keywords: getKeywords?.('linkConfluencePageSynonyms'),
			primaryAction: {
				type: CommandActionType.PERFORM,
				perform: () =>
					setTimeout(() => {
						onLinkConfluencePageClick();
					}, 0),
			},
			section: getCommandPaletteIssueActionsHeader(issueKey),
			priority: getCommandPaletteIssueActionsPriority('SUB_HEADER_ITEMS'),
			components: {
				LeftIcon: () => (
					<PageIcon color="currentColor" label={formatMessage(messages.linkConfluencePage)} />
				),
			},
			analytics: {
				action: 'linkConfluencePage',
			},
		}),
		[formatMessage, getKeywords, issueKey, onLinkConfluencePageClick],
	);

	const addChildCommand = useMemo(
		() => ({
			id: `issue-add-child-issue-${issueKey}`,
			name: formatMessage(messages.addChild),
			keywords: formatMessage(messages.addChildSynonyms),
			primaryAction: {
				type: CommandActionType.PERFORM,
				perform: () => onAddChildCommand(),
			},
			components: {
				LeftIcon: () => <AddIcon color="currentColor" label={formatMessage(messages.addChild)} />,
			},
			section: getCommandPaletteIssueActionsHeader(issueKey),
			priority: getCommandPaletteIssueActionsPriority('SUB_HEADER_ITEMS'),
			analytics: {
				action: 'addChild',
			},
		}),
		[formatMessage, issueKey, onAddChildCommand],
	);

	const commands: Command[] = [
		...(isAttachCommandEnabled ? [addAttachmentCommand] : []),
		...(isAddAUrlEnabled ? [addAUrlCommand] : []),
		...(isLinkConfluencePageEnabled ? [linkConfluencePageCommand] : []),
		...(isAddChildEnabled ? [addChildCommand] : []),
	];

	useRegisterCommands(COMMAND_PALETTE_REGISTRY_IDS.QUICK_ADD, commands, [
		issueKey,
		isAttachCommandEnabled,
		isAddAUrlEnabled,
		isLinkConfluencePageEnabled,
		addAttachmentCommand,
		addAUrlCommand,
		linkConfluencePageCommand,
		addChildCommand,
	]);
};
