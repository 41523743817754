import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { streamConvoAI } from '../../../provider/prompt-requests/convo-ai';
import { streamXPGenAI } from '../../../provider/prompt-requests/xp-gen-ai';
import { Icon } from '../../assets/icons/brainstorm';
import { createInsertAtCurrentPosition } from '../../config-item-actions/markdown';

import { messages } from './messages';

const SELECTION_TYPE = 'empty';

export const brainstormConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.BRAINSTORM,
	title: messages.title,
	description: messages.description,
	selectionType: SELECTION_TYPE,
	getInitialContext: ({ intl }) => ({
		userLocale: intl.locale,
		intentSchemaId: 'brainstorm_intent_schema.json',
		document: '',
	}),
	promptLabel: messages.promptLabel,
	promptHint: messages.promptHint,
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	triggerPromptRequest({ initialContext, promptInput, editorSchema, analyticsContext }) {
		if (fg('platform_editor_ai_assistance_service')) {
			return streamConvoAI({
				aiSessionId: analyticsContext?.aiSessionId,
				customPrompt: promptInput!,
				userLocale: initialContext.userLocale,
				intentSchemaId: 'brainstorm_intent_schema.json',
				editorSchema,
				currentSelection: initialContext.document,
			});
		}

		return streamXPGenAI({
			customPrompt: promptInput!,
			userLocale: initialContext.userLocale,
			intentSchemaId: 'brainstorm_intent_schema.json',
			editorSchema,
			contextList: [{ type: 'text', entity: '', relationship: undefined }],
		});
	},
	isVisible: () => true,
	actions: [createInsertAtCurrentPosition({ appearance: 'primary' })],
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});
