import debounce from 'lodash/debounce';
// import throttle from 'lodash/throttle';
// import uuid from 'uuid/v4';

import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import type { EditorState, Transaction } from '@atlaskit/editor-prosemirror/state';
import { TextSelection } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { type MentionNameDetails } from '@atlaskit/mention';

import type { ProactiveAISuggestionAEP } from '../../analytics/types';
import { addAnalytics, createUnifiedAnalyticPayload } from '../../analytics/utils';
import { type ProactiveAIConfig } from '../../types';
import { type ParagraphChunk } from '../../utils/diff-match-patch/utils';

import { fetchAIParagraphs } from './api';
import { fireAPIErrorWithAnalytics, fireAPIReceivedAnalytics } from './commands-with-analytics';
import { ANALYTICS_ACTION_SUBJECT_ID } from './constants';
import {
	ProactiveDecorations,
	regenerateAllDecorations,
	regenerateHoverDecorations,
} from './decorations';
import { createCommand, getPluginState } from './plugin-factory';
import type { ProactiveAIBlock } from './states';
import { ACTIONS } from './states';
import {
	getAllRecommendations,
	getBlockFromRecommendationId,
	hasRecommendation,
	removeRecommendationFromBlocks,
	syncProactiveRecommendations,
} from './utils';

export interface TriggerContext {
	view: EditorView;
	locale: string;
	getMentionNameDetails?: (id: string) => Promise<MentionNameDetails | undefined>;
}

export const insertRecommendation = (
	recommendationId: string,
	triggeredFrom: 'contextPanel' | 'preview',
) =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const { decorationSet, insertionCount, proactiveAIBlocks } = pluginState;

			if (!proactiveAIBlocks?.length || !hasRecommendation(pluginState, recommendationId)) {
				return false;
			}

			const updatedProactiveAIBlocks = removeRecommendationFromBlocks(
				proactiveAIBlocks,
				recommendationId,
			);

			// TODO: We need to determine if have should close the context panel (if it's open) if we have no more recommendaitons
			// left after this action is performed.

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					// Upon inserting a suggestion we need to ensure that that the insertion widget is removed.
					decorationSet: regenerateAllDecorations({
						decorationSet,
						tr: state.tr,
						pluginState: {
							...pluginState,
							proactiveAIBlocks: updatedProactiveAIBlocks,
						},
					}),
					proactiveAIBlocks: updatedProactiveAIBlocks,
					insertionCount: insertionCount + 1,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			const pluginState = getPluginState(state);

			if (hasRecommendation(pluginState, recommendationId)) {
				const { block, recommendation } = getBlockFromRecommendationId(
					pluginState,
					recommendationId,
				);

				if (block && recommendation) {
					const { from, to } = block;
					const { transformContent, transformAction, transformType } = recommendation;

					if (transformContent) {
						return addAnalytics({
							editorState: state,
							addToHistory: true,
							tr: addAnalytics({
								editorState: state,
								addToHistory: true,
								tr,
								payload: createUnifiedAnalyticPayload(
									'actioned',
									pluginState.analyticsAIInteractionId,
									recommendation.transformAction,
									true,
									{
										aiResultAction: 'recommendationAccepted',
									},
								),
							}),
							payload: {
								action: 'inserted',
								actionSubject: 'editorPluginAI',
								actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
								attributes: {
									aiInteractionID: pluginState.analyticsAIInteractionId,
									triggeredFrom,
									transformAction,
									transformType,
								},
								eventType: EVENT_TYPE.TRACK,
							} as ProactiveAISuggestionAEP,
						})
							.setMeta('suggestionAccepted', true)
							.replaceWith(from, to, transformContent.content);
					}
				}
			}

			return tr;
		},
	);

export const removeRecommendation = (
	recommendationId: string,
	triggeredFrom: 'contextPanel' | 'preview',
) =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const { decorationSet, proactiveAIBlocks, dismissedCount } = pluginState;
			if (!proactiveAIBlocks?.length || !hasRecommendation(pluginState, recommendationId)) {
				return false;
			}

			const updatedProactiveAIBlocks = removeRecommendationFromBlocks(
				proactiveAIBlocks,
				recommendationId,
			);

			// TODO: We need to determine if have should close the context panel (if it's open) if we have no more recommendaitons
			// left after this action is performed.

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					// dismissedWords: dismissedWords.add(originalText),
					decorationSet: regenerateAllDecorations({
						decorationSet,
						tr: state.tr,
						pluginState: {
							...pluginState,
							proactiveAIBlocks: updatedProactiveAIBlocks,
						},
					}),
					proactiveAIBlocks: updatedProactiveAIBlocks,
					dismissedCount: dismissedCount + 1,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			const pluginState = getPluginState(state);

			const { recommendation } = getBlockFromRecommendationId(pluginState, recommendationId);

			if (recommendation) {
				const { transformAction, transformType } = recommendation;
				return addAnalytics({
					editorState: state,
					addToHistory: false,
					tr: addAnalytics({
						editorState: state,
						addToHistory: false,
						tr,
						payload: createUnifiedAnalyticPayload(
							'dismissed',
							pluginState.analyticsAIInteractionId,
							recommendation.transformAction,
							true,
						),
					}),
					payload: {
						action: 'dismissed',
						actionSubject: 'editorPluginAI',
						actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
						attributes: {
							aiInteractionID: pluginState.analyticsAIInteractionId,
							triggeredFrom,
							transformAction,
							transformType,
						},
						eventType: EVENT_TYPE.TRACK,
					} as ProactiveAISuggestionAEP,
				});
			}

			return tr.setMeta('addToHistory', false);
		},
	);

export const toggleProactiveAISuggestionDisplay = (triggeredFrom: string) =>
	createCommand(
		() => ({ type: ACTIONS.TOGGLE_PROACTIVE_CONTEXT_PANEL }),
		(tr: Transaction, state: EditorState) => {
			const pluginState = getPluginState(state);
			const { isProactiveContextPanelOpen } = pluginState;
			return addAnalytics({
				editorState: state,
				addToHistory: false,
				tr,
				payload: {
					action: 'toggled',
					actionSubject: 'editorPluginAI',
					actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
					attributes: {
						toggledToValue: !isProactiveContextPanelOpen,
						triggeredFrom,
					},
					eventType: EVENT_TYPE.TRACK,
				},
			});
		},
	);

export const closeProactiveAISuggestionDisplay = (triggeredFrom: string) =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const { isProactiveContextPanelOpen } = pluginState;
			// A close command will only toggle the context panl shut if it is already open. This is to provide controls
			// for auto-closing the menu and only firing analytics if the action occured.
			if (!isProactiveContextPanelOpen) {
				return false;
			}
			return { type: ACTIONS.TOGGLE_PROACTIVE_CONTEXT_PANEL };
		},
		(tr: Transaction, state: EditorState) => {
			const pluginState = getPluginState(state);
			const { isProactiveContextPanelOpen } = pluginState;
			return addAnalytics({
				editorState: state,
				addToHistory: false,
				tr,
				payload: {
					action: 'toggled',
					actionSubject: 'editorPluginAI',
					actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
					attributes: {
						toggledToValue: !isProactiveContextPanelOpen,
						triggeredFrom,
					},
					eventType: EVENT_TYPE.TRACK,
				},
			});
		},
	);

export const hoverRecommendation = (recommendationId: string) =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const {
				decorationSet,
				proactiveAIBlocks,
				allowInlineHoverHighlightWhileRecommendationSelected,
				selectedRecommendationId,
			} = pluginState;

			if (!proactiveAIBlocks?.length || !hasRecommendation(pluginState, recommendationId)) {
				return false;
			}

			if (!allowInlineHoverHighlightWhileRecommendationSelected && !!selectedRecommendationId) {
				return false;
			}

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					// Upon inserting a suggestion we need to ensure that that the insertion widget is removed.
					decorationSet: regenerateHoverDecorations({
						decorationSet,
						tr: state.tr,
						pluginState: {
							...pluginState,
							hoveredRecommendationId: recommendationId,
						},
					}),
					hoveredRecommendationId: recommendationId,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			return tr.setMeta('addToHistory', false);
		},
	);

export const clearHoverRecommendation = () =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const { decorationSet, hoveredRecommendationId } = pluginState;

			if (!hoveredRecommendationId) {
				return false;
			}

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					// Upon inserting a suggestion we need to ensure that that the insertion widget is removed.
					decorationSet: regenerateHoverDecorations({
						decorationSet,
						tr: state.tr,
						pluginState: {
							...pluginState,
							hoveredRecommendationId: undefined,
						},
					}),
					hoveredRecommendationId: undefined,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			return tr.setMeta('addToHistory', false);
		},
	);

/**
 * This will move the users text selection to the block containing the recommendation. We don't need to bother
 * updated the selection decorations because the plugin-factory handleSelectionChange will take care of that.
 */
export const selectRecommendation = (recommendationId: string) =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const { proactiveAIBlocks, selectedRecommendationId } = pluginState;

			if (
				recommendationId === selectedRecommendationId ||
				!proactiveAIBlocks?.length ||
				!hasRecommendation(pluginState, recommendationId)
			) {
				return false;
			}

			return {
				type: ACTIONS.SELECT_PROACTIVE_RECOMMENDATION,
				data: {
					selectedRecommendationId: recommendationId,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			const pluginState = getPluginState(state);
			const { decorationSet } = pluginState;

			const { recommendation } = getBlockFromRecommendationId(pluginState, recommendationId);

			if (recommendation) {
				const inlineDeco = decorationSet.find(undefined, undefined, (spec) => {
					return (
						spec.key === ProactiveDecorations.RECOMMENDATION &&
						spec.blockId === recommendation.chunkId
					);
				})?.[0];
				if (inlineDeco) {
					tr.setSelection(TextSelection.create(tr.doc, inlineDeco.from + 1));
				}
			}

			return tr.setMeta('addToHistory', false);
		},
	);

/**
 * Clear the selected recommendation if any.
 */
export const clearSelectRecommendation = () =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const { selectedRecommendationId } = pluginState;

			if (!selectedRecommendationId) {
				return false;
			}

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					selectedRecommendationId: undefined,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			const pluginState = getPluginState(state);
			const { decorationSet, selectedRecommendationId } = pluginState;

			if (selectedRecommendationId) {
				const { recommendation } = getBlockFromRecommendationId(
					pluginState,
					selectedRecommendationId,
				);

				if (recommendation) {
					const inlineDeco = decorationSet.find(undefined, undefined, (spec) => {
						return (
							spec.key === ProactiveDecorations.RECOMMENDATION &&
							spec.blockId === recommendation.chunkId
						);
					})?.[0];
					if (inlineDeco) {
						// Put the cursor right after the end of the block to remove selection.
						tr.setSelection(TextSelection.create(tr.doc, inlineDeco.to + 1));
					}
				}
			}

			return tr.setMeta('addToHistory', false);
		},
	);

// export const startProactiveAI = () => createProactiveCommand(ACTIONS.START_PROACTIVE_AI);

// export const toggleProactiveAI = (toggleCount: number) =>
// 	createProactiveCommand(ACTIONS.TOGGLE_PROACTIVE_ENABLED, toggleCount);

// const analyticsPayload: EditorAIAnalyticEventPayload = {
// 	// 		action: 'toggled',
// 	// 		actionSubject: 'editorPluginAI',
// 	// 		actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
// 	// 		attributes: {
// 	// 			...getCommonAnalyticsAttributes(),
// 	// 			toggledCount: proactiveToggleCount,
// 	// 			initialToggleState,
// 	// 			totalSuggestions: totalSuggestionsOnPage,
// 	// 			totalAcceptedSuggestions: insertionCount,
// 	// 			totalDismissedSuggestions: dismissedWords,
// 	// 			triggeredFrom,
// 	// 		},
// 	// 		eventType: EVENT_TYPE.TRACK,
// 	// 	};
// 	// 	const operation = operationType === 'start' ? startProactiveAI : toggleProactiveAI;
// 	// 	const operationWithAnalytics = withAnalytics({ payload: analyticsPayload })(
// 	// 		operation(proactiveToggleCount),
// 	// 	);
// 	// 	return operationWithAnalytics;
// 	// };

// 	const tr = addAnalytics({
// 		editorState: view.state,
// 		tr: view.state.tr,
// 		payload: {
// 			action: 'toggled',
// 			actionSubject: 'editorPluginAI',
// 			actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
// 			attributes: {
// 				// ...getCommonAnalyticsAttributes(),
// 				// meanDuration,
// 				// medianDuration,
// 				// minDuration,
// 				// maxDuration,
// 				// totalSuggestions,
// 				// totalAcceptedSuggestions,
// 				// totalDismissedSuggestions,
// 			},
// 			eventType: EVENT_TYPE.TRACK,
// 		},
// 	});

// export const toggleProactiveAISuggestionDisplay = (toggleCount: number) =>
// createProactiveCommand(ACTIONS.TOGGLE_PROACTIVE_CONTEXT_PANEL, toggleCount);

// export const disableNeedSpellingAndGrammar = (
// 	skippedChunkIds: Array<ParagraphChunk['id']>,
// 	transform?: (tr: Transaction, state: EditorState) => Transaction,
// ) =>
// 	createCommand(
// 		() => {
// 			return {
// 				type: ACTIONS.DISABLE_NEED_SPELLING_AND_GRAMMAR,
// 				data: {
// 					skippedChunkIds,
// 				},
// 			};
// 		},
// 		(tr: Transaction, state: EditorState) => {
// 			if (transform) {
// 				return transform(tr, state).setMeta('addToHistory', false);
// 			}
// 			return tr.setMeta('addToHistory', false);
// 		},
// 	);

export const fireAPIError = (transform?: (tr: Transaction, state: EditorState) => Transaction) =>
	createCommand(ACTIONS.API_ERROR, (tr: Transaction, state: EditorState) => {
		if (transform) {
			return transform(tr, state).setMeta('addToHistory', false);
		}
		return tr.setMeta('addToHistory', false);
	});

// export const highlightSentence = (sentence: ProactiveAISentence) =>
// 	createCommand(
// 		(state) => {
// 			const { decorationSet } = getPluginState(state);

// 			return {
// 				type: ACTIONS.UPDATE_PLUGIN_STATE,
// 				data: {
// 					decorationSet: decorationSet.add(state.doc, [
// 						createInlineDecoration(sentence.from, sentence.to),
// 					]),
// 				},
// 			};
// 		},
// 		(tr) => tr.setMeta('addToHistory', false),
// 	);

// export const unhighlightSentence = () =>
// 	createCommand(
// 		(state) => {
// 			const { decorationSet } = getPluginState(state);
// 			return {
// 				type: ACTIONS.UPDATE_PLUGIN_STATE,
// 				data: {
// 					decorationSet: decorationSet.remove(
// 						decorationSet.find(undefined, undefined, (spec) => spec.key === 'inlineDecoration'),
// 					),
// 				},
// 			};
// 		},
// 		(tr) => tr.setMeta('addToHistory', false),
// 	);

/**
 * This command is used to show a notification about new recommendations.
 */
export const updateNewRecommendationsNotificationState = (showNotification: boolean) =>
	createCommand(
		(state) => {
			const { showNewRecommendationsNotification } = getPluginState(state);

			// Avoid a transaction if notification state is not changing.
			if (!!showNewRecommendationsNotification === showNotification) {
				return false;
			}

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					showNewRecommendationsNotification: showNotification,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			return tr.setMeta('addToHistory', false);
		},
	);

export const updateChunksWithRecommendations = async (
	chunks: ParagraphChunk[],
	context: TriggerContext,
) => {
	const { view } = context;
	const pluginState = getPluginState(view.state);
	const { proactiveAIApiUrl, product } = pluginState;

	const requestGenerator = fetchAIParagraphs(proactiveAIApiUrl, product, context)(chunks);

	if (!requestGenerator) {
		return;
	}

	for await (const responseState of requestGenerator) {
		switch (responseState.state) {
			case 'cached':
			case 'parsed': {
				const recommendations = responseState.recommendations;

				const newPluginState = getPluginState(view.state);
				const { decorationSet, proactiveAIBlocks } = newPluginState;

				if (!proactiveAIBlocks?.length) {
					break;
				}

				// const paragraphChunkMap = generateParagraphChunkMap(proactiveAIBlocks);

				// const recommendationMap = recommendations.reduce<Map<string, Recommendation[]>>((map, recommendation) => {
				// 	return map.set(recommendation.chunkId, [(...map.get(recommendation.chunkId) ?? []), recommendation]);
				// }, new Map<string, Recommendation[]>());

				// const chunksDiffObjectsById = getDiffObjectsForSuggestions({
				// 	editorView: view,
				// 	suggestions,
				// 	paragraphChunkMap,
				// });

				// This is a collection of chunk ids which are missing from the recommendations.
				const chunkIdsMissingRecommendations = chunks.reduce((acc, chunk) => {
					if (!recommendations.some((r) => r.chunkId === chunk.id)) {
						acc.add(chunk.id);
					}
					return acc;
				}, new Set<string>());

				const updatedProactiveAIBlocks = syncProactiveRecommendations(
					newPluginState,
					recommendations,
					chunkIdsMissingRecommendations,
				);

				// const initiateAnalytics = initiateProactiveAIWithAnalytics(uuid());

				const updatePluginCommand = createCommand(
					() => {
						return {
							type: ACTIONS.UPDATE_PLUGIN_STATE,
							data: {
								proactiveAIBlocks: updatedProactiveAIBlocks,
								// Show a notification when we receive new recommendations.
								showNewRecommendationsNotification: recommendations.length > 0,
								// isFirstInitiatedEventSent: true,
								decorationSet: regenerateAllDecorations({
									decorationSet,
									tr: view.state.tr,
									pluginState: {
										...newPluginState,
										proactiveAIBlocks: updatedProactiveAIBlocks,
									},
								}),
							},
						};
					},
					(tr: Transaction, state: EditorState) => {
						return tr.setMeta('addToHistory', false);
					},
				);
				updatePluginCommand(view.state, view.dispatch);

				/**
				 * If isFirstInitiatedEventSent is true, we have already sent the initialEvent
				 * so we don't need to send it again.
				 */
				// isFirstInitiatedEventSent
				// ? updatePluginCommand(view.state, view.dispatch)
				// : initiateAnalytics(updatePluginCommand)(view.state, view.dispatch);

				break;
			}
			case 'trackedDuration': {
				const newPluginState = getPluginState(view.state);
				const { insertionCount, dismissedCount } = newPluginState;
				// Below is analytics for receiving step from S+G API.
				fireAPIReceivedAnalytics({
					view,
					duration: responseState.duration,
					totalSuggestions: getAllRecommendations(newPluginState).length,
					totalAcceptedSuggestions: insertionCount,
					totalDismissedSuggestions: dismissedCount,
				});
				break;
			}
			// case 'receivedChunkMetadata': {
			// 	createCommand(
			// 		() => {
			// 			return {
			// 				type: ACTIONS.UPDATE_CHUNK_METADATA,
			// 				data: {
			// 					chunkId: responseState.chunkId,
			// 					metadata: responseState.metadata,
			// 				},
			// 			};
			// 		},
			// 		(tr: Transaction) => {
			// 			return tr.setMeta('addToHistory', false);
			// 		},
			// 	)(view.state, view.dispatch);
			// 	break;
			// }
			case 'failed': {
				if (responseState.failedChunkIds.length > 0) {
					fireAPIErrorWithAnalytics({
						view,
						failedChunkIds: responseState.failedChunkIds,
						errors: responseState.errors,
						reason: responseState.reason,
						statusCode: responseState.statusCode,
					})(view.state, view.dispatch);
				}
				break;
			}
			// case 'purged': {
			// 	if (responseState.purgedChunkIds.length) {
			// 		disableCheckForPurgedChunksWithAnalytics({
			// 			purgedChunkIds: responseState.purgedChunkIds,
			// 			totalParts: responseState.totalParts,
			// 			totalPurgedParts: responseState.totalPurgedParts,
			// 		})(view.state, view.dispatch);
			// 	}
			// }
		}
	}

	// Stop the notifications soon after the last notification arrives.
	stopNewRecommendationsNotification(view);
};

const stopNewRecommendationsNotification = debounce(
	(view: EditorView) => updateNewRecommendationsNotificationState(false)(view.state, view.dispatch),
	3000, // 3 seconds notification interval suggested by design.
);

const isSelectionInBlock = (from: number, to: number, block: ProactiveAIBlock) => {
	return (block.from <= from && block.to >= from) || (block.from <= to && block.to >= to);
};

const getBlocksNeedingCheck = (state: EditorState, currentBlocks: boolean) => {
	const { from, to } = state.selection;
	const { proactiveAIBlocks } = getPluginState(state);

	return proactiveAIBlocks
		.filter((block) => !!block.invalidated)
		.filter((block) => {
			const selectionInBlock = isSelectionInBlock(from, to, block);
			return currentBlocks ? selectionInBlock : !selectionInBlock;
		});
};

/**
 * Wait Y (Y > X) seconds before triggering S+G prompt for current blocks.
 * That means,
 *  Author starts updating block and keeps updating for sometime.
 *  Then Author pauses for Y (Y > X) seconds then trigger S+G prompt.
 */
const triggerCheckForCurrentBlocks = async (context: TriggerContext) => {
	/**
	 * If selection is overlap with block and it's been more than Y seconds
	 *  since it was last updated then send it for S+G prompt.
	 * Ignore non current blocks, as they will be handled separately.
	 */
	const chunks = getBlocksNeedingCheck(context.view.state, true);

	if (chunks.length) {
		await updateChunksWithRecommendations(chunks, context);
	}
};

/**
 * Wait X seconds before triggering S+G prompt for non current blocks.
 * That means,
 *  Author starts updating block, till cursor (or selection) is in the block,
 *    and S+G prompt hasn't been run since last update then,
 *    trigger S+G prompts after X seconds.
 */
const triggerCheckForNonCurrentBlocks = async (context: TriggerContext) => {
	const chunks = getBlocksNeedingCheck(context.view.state, false);

	if (chunks.length) {
		await updateChunksWithRecommendations(chunks, context);
	}
};

export const createTriggerProactiveCheck = (timings: ProactiveAIConfig['timings']) => {
	// XXX: This way a throttle, however i've changed this to a debounce for proactive because they want it to be less responsive
	const triggerForNonCurrentBlocksThrottled = debounce(
		triggerCheckForNonCurrentBlocks,
		timings.nonCurrentChunks,
	);

	const triggerForCurrentBlocksDebounced = debounce(
		triggerCheckForCurrentBlocks,
		timings.currentChunks,
		{ maxWait: timings.currentChunksMaxWait },
	);

	const triggerProactiveCheck = (context: TriggerContext) => {
		timings.nonCurrentChunks >= 0 && triggerForNonCurrentBlocksThrottled(context);
		timings.currentChunks >= 0 && triggerForCurrentBlocksDebounced(context);
	};

	const cancelDebouncedAndThrottledCheck = () => {
		triggerForNonCurrentBlocksThrottled.cancel();
		triggerForCurrentBlocksDebounced.cancel();
	};

	return {
		triggerProactiveCheck,
		cancelDebouncedAndThrottledCheck,
	};
};
