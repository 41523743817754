import React, { useCallback, useMemo, useState, type ComponentPropsWithoutRef } from 'react';
import { css, styled } from '@compiled/react';
import { useFragment, graphql, useMutation } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { fontSize, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import { DateTimeEditView } from '@atlassian/jira-issue-field-date-time-editview-full/src/ui/date-time/index.tsx';
import { DateTimeReadView } from '@atlassian/jira-issue-field-date-time-readview-full/src/ui/date-time/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import {
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	ReadViewContainer as StandardReadViewContainer,
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	InlineEditContainer as StandardInlineEditContainer,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	dateTime_issueFieldDateTime_DateTimeField_Mutation as DateTimeMutation,
	dateTime_issueFieldDateTime_DateTimeField_Mutation$rawResponse as DateTimeMutationResponse,
} from '@atlassian/jira-relay/src/__generated__/dateTime_issueFieldDateTime_DateTimeField_Mutation.graphql';
import type { dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditView$key as DateTimeFragment } from '@atlassian/jira-relay/src/__generated__/dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditView.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';
import type { Props, JiraDateTimePickerValue } from './types.tsx';

export const ACTION_SUBJECT = 'dateTimeFieldInlineEdit';

/**
 * DateTimeInlineEditView Component will handle the switching behaviour between the 'readView' and 'editView' components
 * @param props {@link DateTimeInlineEditView}
 *
 * we also want to finalise if we need areActionButtonsHidden here or not
 * */

export const DateTimeInlineEditView = ({
	fragmentRef,
	isEditing: startWithEditViewOpen = false,
	onChange: onChangeCallback,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	timeZone,
}: Props) => {
	// #region Relay
	const data = useFragment<DateTimeFragment>(
		graphql`
			fragment dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditView on JiraDateTimePickerField {
				...dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView
				id
				dateTime
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);
	const { id: uniqueFieldId, fieldConfig, dateTime } = data;
	const [commit] = useMutation<DateTimeMutation>(graphql`
		mutation dateTime_issueFieldDateTime_DateTimeField_Mutation(
			$input: JiraUpdateDateTimeFieldInput!
		) @raw_response_type {
			jira {
				updateDateTimeField(input: $input) {
					success
					errors {
						message
					}
					field {
						dateTime
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { overriding } = useInlineEditFieldInjections();
	const [isEditing, setIsEditing] = useOptionallyControlledEditingState(
		startWithEditViewOpen,
		uniqueFieldId,
	);
	// @ts-expect-error - Argument of type 'string | null | undefined' is not assignable to parameter of type 'JiraDateTimePickerValue | (() => JiraDateTimePickerValue)'.
	const [newValue, setNewValue] = useState<JiraDateTimePickerValue>(dateTime);
	const [error, setError] = useState<Error | null>(null);
	const isFieldEditable = ff('relay-migration-issue-fields-date-time_eaqd2')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(
				() => overriding.overrideIsEditable(fieldConfig?.isEditable || false),
				[fieldConfig?.isEditable, overriding],
			)
		: fieldConfig?.isEditable || false;
	// #endregion

	// #region Handle new value
	const onEditRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setError(null);
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
		},
		[setError, setIsEditing],
	);
	const handleErrors = useCallback(
		(incomingError: Error) => {
			setError(incomingError);
			setIsEditing(true);
			// @ts-expect-error - Argument of type 'string | null | undefined' is not assignable to parameter of type 'SetStateAction<JiraDateTimePickerValue>'.
			setNewValue(dateTime);
		},
		[setError, setIsEditing, setNewValue, dateTime],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setError(null);
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
		},
		[setError, setIsEditing],
	);
	const handleNewValue = useCallback(
		(newDateTime: JiraDateTimePickerValue) => {
			if (ff('relay-migration-issue-fields-date-time_eaqd2')) {
				onSubmit?.(newDateTime);
			}

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							datetime: newDateTime ?? null,
							operation: 'SET',
						},
					},
				},

				onCompleted(mutationData) {
					if (mutationData.jira?.updateDateTimeField) {
						const { success, errors: responseErrors } = mutationData.jira?.updateDateTimeField;
						if (success) {
							if (ff('relay-migration-issue-fields-date-time_eaqd2')) {
								onSubmitSucceeded?.(newDateTime);
							}
							return;
						}

						if (ff('relay-migration-issue-fields-date-time_eaqd2')) {
							onSubmitFailed?.();
						}

						if (responseErrors != null && responseErrors.length > 0) {
							handleErrors(
								new Error(
									responseErrors[0].message != null ? responseErrors[0].message : undefined,
								),
							);
						}
					}
				},
				onError(incomingError) {
					handleErrors(incomingError);
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				optimisticResponse: {
					jira: {
						updateDateTimeField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								dateTime: newDateTime,
							},
						},
					},
				} as DateTimeMutationResponse,
			});
			setIsEditing(false);
			onChangeCallback?.(newDateTime);
		},
		[
			commit,
			handleErrors,
			onChangeCallback,
			onSubmit,
			onSubmitFailed,
			onSubmitSucceeded,
			setIsEditing,
			uniqueFieldId,
		],
	);
	const onConfirm = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			handleNewValue(newValue);
			fireUIAnalytics(analyticsEvent);
		},
		[newValue, handleNewValue],
	);
	const onChange = useCallback(
		(value: JiraDateTimePickerValue) => {
			setError(null);
			setNewValue(value);
		},
		[setNewValue],
	);

	// #region Read view
	const ReadViewContainerToUse = ff('relay-migration-issue-fields-date-time_eaqd2')
		? StandardReadViewContainer
		: ReadViewContainer;

	const renderReadView = () => (
		<ReadViewContainerToUse data-testid="issue-field-date-time-inline-edit-full.ui.date-time.read-view">
			<DateTimeReadView fragmentRef={data} timeZone={timeZone} />
		</ReadViewContainerToUse>
	);
	// #endregion
	// #region Edit view
	const renderEditView = () => {
		return (
			<>
				<EditViewContainer data-testid="issue-field-date-time-inline-edit-full.ui.date-time.edit-view">
					<DateTimeEditView value={newValue} onChange={onChange} timeZone={timeZone} />
				</EditViewContainer>
			</>
		);
	};
	// #endregion

	const InlineEditContainerToUse = ff('relay-migration-issue-fields-date-time_eaqd2')
		? StandardInlineEditContainer
		: InlineEditContainer;

	return (
		<ErrorBoundary id="Field(DateTime)" prefixOverride="issue">
			{/* @ts-expect-error Types of parameters 'analyticsEvent' and 'e' are incompatible */}
			<EnterEscapeHandler onEscape={onCancelRequest}>
				{error && (
					<ErrorFlag
						description={messages.errorMessage}
						error={error}
						title={messages.errorTitle}
					/>
				)}
				<InlineEditContainerToUse isEditable={isFieldEditable}>
					<FieldInlineEditStateLess
						testId="issue-field-date-time-inline-edit-full.ui.date-time.field-date-time"
						isEditing={isEditing}
						areActionButtonsHidden
						actionSubject={ACTION_SUBJECT}
						isEditable={isFieldEditable}
						readView={renderReadView}
						editView={renderEditView}
						onCancel={onCancelRequest}
						onConfirm={onConfirm}
						onEdit={onEditRequest}
						defaultValue={null}
					/>
				</InlineEditContainerToUse>
			</EnterEscapeHandler>
		</ErrorBoundary>
	);
};

const readViewContainerSelectorName = 'jira-issue-field-date-time-read-view-container';
const READ_VIEW_CONTAINER_COMPONENT_SELECTOR = `[data-component-selector="${readViewContainerSelectorName}"]`;

const ReadViewContainer = (props: ComponentPropsWithoutRef<typeof ReadViewContainerComponent>) => (
	<ReadViewContainerComponent data-component-selector={readViewContainerSelectorName} {...props} />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values
	zIndex: () => (isVisualRefreshEnabled() ? 200 : 300),
	position: 'relative',
});

const nonEditableStylesExperimentStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[READ_VIEW_CONTAINER_COMPONENT_SELECTOR]: {
		paddingLeft: token('space.075', '6px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditContainer = styled.div<{ isEditable: boolean }>(
	{
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			minHeight: '32px',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => (!isEditable ? nonEditableStylesExperimentStyles : undefined),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainerComponent = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: (gridSize * 2.5) / fontSize,
});
