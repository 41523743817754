import { useCallback } from 'react';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';

const useMoveIssue = (
	itemKey: string,
	onClick?: (itemKey: string, event?: Event) => Promise<undefined> | undefined,
) => {
	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const [issueType] = useFieldValue({ issueKey, fieldKey: 'issuetype' });
	const isSubtask = issueType?.subtask;
	const href = isSubtask
		? `/secure/MoveSubTaskChooseOperation!default.jspa?id=${issueId || ''}`
		: `/secure/MoveIssue!default.jspa?id=${issueId || ''}`;

	return useCallback(() => {
		onClick?.(itemKey);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.assign(href);
	}, [onClick, itemKey, href]);
};

export default useMoveIssue;
