import head from 'lodash/head';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const rankOptions =
	(fieldKey: FieldKey, rankedOptionIds: string[]) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, projectId, createAnalyticsEvent, fieldRemote, ideaTypes }: Props,
	) => {
		const state = getState();
		const previousOptions = state.fields[fieldKey].options;
		if (previousOptions === undefined) {
			return;
		}

		const newOptions = rankedOptionIds
			.map((id) => previousOptions.find((option) => option.jiraOptionId === id))
			.filter(Boolean);

		const fields = {
			...state.fields,
			[fieldKey]: {
				...state.fields[fieldKey],
				options: newOptions,
			},
		};

		const issueTypeId = head(ideaTypes)?.jiraIssueTypeId;

		setState({ fields });

		if (projectId !== undefined && issueTypeId !== undefined) {
			fieldRemote
				.updateFieldOptionRank({
					fieldKey,
					rankedOptionIds: newOptions.map((option) => option.jiraOptionId),
					issueTypeId,
				})
				.then(() => {
					fireCompoundAnalyticsEvent.FieldConfig.optionRankChanged(createAnalyticsEvent({}), {
						issueFieldKey: fieldKey,
						issueFieldType: fields[fieldKey].type,
					});
				})
				.catch(onFieldUpdateFailed);
		}
	};
