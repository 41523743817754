/**
 * @generated SignedSource<<b76158a330561b99b97727d378cb4b4c>>
 * @relayHash 55b5de84691ee6cc4a5bfe9108927760
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1844ac401fa4b089525762564bded4edd363ab27c36f2ad75dc753f554cc47e3

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToolchainDisassociateEntitiesErrorCode = "UNKNOWN_ASSOCIATION" | "%future added value";
export type ToolchainProviderType = "BUILD" | "DEPLOYMENT" | "DESIGN" | "DEVOPS_COMPONENTS" | "DEV_INFO" | "DOCUMENTATION" | "FEATURE_FLAG" | "OPERATIONS" | "REMOTE_LINKS" | "SECURITY" | "%future added value";
export type ToolchainDisassociateEntitiesInput = {
  cloudId: string;
  disassociations: ReadonlyArray<ToolchainDisassociateEntityInput>;
  providerId: string;
  providerType?: ToolchainProviderType | null | undefined;
};
export type ToolchainDisassociateEntityInput = {
  fromId: string;
  toEntityId: string;
};
export type bulkUnlinkConfirmationModalunlinkConfirmationModal_disassociateDesignEntityMutation$variables = {
  input: ToolchainDisassociateEntitiesInput;
};
export type bulkUnlinkConfirmationModalunlinkConfirmationModal_disassociateDesignEntityMutation$data = {
  readonly devOps: {
    readonly toolchain: {
      readonly disassociateEntities: {
        readonly errors: ReadonlyArray<{
          readonly extensions: {
            readonly __typename: "ToolchainDisassociateEntitiesErrorExtension";
            readonly errorCode: ToolchainDisassociateEntitiesErrorCode | null | undefined;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          } | null | undefined;
          readonly message: string | null | undefined;
        }> | null | undefined;
        readonly fromEntities: ReadonlyArray<{
          readonly attachedDesignUrlV1: AGG$JSON | null | undefined;
          readonly attachedDesignUrlV2: AGG$JSON | null | undefined;
          readonly id: string;
        } | null | undefined> | null | undefined;
        readonly success: boolean;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type bulkUnlinkConfirmationModalunlinkConfirmationModal_disassociateDesignEntityMutation = {
  response: bulkUnlinkConfirmationModalunlinkConfirmationModal_disassociateDesignEntityMutation$data;
  variables: bulkUnlinkConfirmationModalunlinkConfirmationModal_disassociateDesignEntityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "message"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "errorCode"
    }
  ],
  "type": "ToolchainDisassociateEntitiesErrorExtension"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "concreteType": "JiraIssue",
  "kind": "LinkedField",
  "name": "fromEntities",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "alias": "attachedDesignUrlV1",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "attached-design-url"
        }
      ],
      "kind": "ScalarField",
      "name": "issuePropertyByKey",
      "storageKey": "issuePropertyByKey(key:\"attached-design-url\")"
    },
    {
      "alias": "attachedDesignUrlV2",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "attached-design-url-v2"
        }
      ],
      "kind": "ScalarField",
      "name": "issuePropertyByKey",
      "storageKey": "issuePropertyByKey(key:\"attached-design-url-v2\")"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "bulkUnlinkConfirmationModalunlinkConfirmationModal_disassociateDesignEntityMutation",
    "selections": [
      {
        "concreteType": "DevOpsMutation",
        "kind": "LinkedField",
        "name": "devOps",
        "plural": false,
        "selections": [
          {
            "concreteType": "ToolchainMutation",
            "kind": "LinkedField",
            "name": "toolchain",
            "plural": false,
            "selections": [
              {
                "args": (v1/*: any*/),
                "concreteType": "ToolchainDisassociateEntitiesPayload",
                "kind": "LinkedField",
                "name": "disassociateEntities",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "concreteType": "MutationError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bulkUnlinkConfirmationModalunlinkConfirmationModal_disassociateDesignEntityMutation",
    "selections": [
      {
        "concreteType": "DevOpsMutation",
        "kind": "LinkedField",
        "name": "devOps",
        "plural": false,
        "selections": [
          {
            "concreteType": "ToolchainMutation",
            "kind": "LinkedField",
            "name": "toolchain",
            "plural": false,
            "selections": [
              {
                "args": (v1/*: any*/),
                "concreteType": "ToolchainDisassociateEntitiesPayload",
                "kind": "LinkedField",
                "name": "disassociateEntities",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "concreteType": "MutationError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v6/*: any*/)
                            ],
                            "type": "BulkMutationErrorExtension"
                          }
                        ]
                      }
                    ]
                  },
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "1844ac401fa4b089525762564bded4edd363ab27c36f2ad75dc753f554cc47e3",
    "metadata": {},
    "name": "bulkUnlinkConfirmationModalunlinkConfirmationModal_disassociateDesignEntityMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a01fc16ec90b2e936c5475a2ae1a8711";

export default node;
