/**
 * @generated SignedSource<<5320f9b183842c47ee92a635be2ded92>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designs$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly __typename: "DevOpsDesign";
      readonly __id: string;
      readonly id?: string;
      readonly providerId?: string;
    };
  } | null | undefined>;
  readonly " $fragmentType": "bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designs";
};
export type bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designs$key = {
  readonly " $data"?: bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designs$data;
  readonly " $fragmentSpreads": FragmentRefs<"bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designs">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designs",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "GraphStoreSimplifiedIssueAssociatedDesignEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "__typename"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "ScalarField",
                        "name": "id"
                      },
                      "action": "THROW",
                      "path": "edges.node.id"
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "ScalarField",
                        "name": "providerId"
                      },
                      "action": "THROW",
                      "path": "edges.node.providerId"
                    }
                  ],
                  "type": "DevOpsDesign"
                },
                (v0/*: any*/)
              ]
            },
            "action": "THROW",
            "path": "edges.node"
          }
        ]
      },
      "action": "THROW",
      "path": "edges"
    },
    (v0/*: any*/)
  ],
  "type": "GraphStoreSimplifiedIssueAssociatedDesignConnection"
};
})();

(node as any).hash = "3ba4ec52039de423d0e45bd8e8202a88";

export default node;
