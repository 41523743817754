import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const LabelsEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-labels-edit-view" */ './ui/labels'),
	),
	getPreloadProps: () => ({}),
});

export default LabelsEditViewEntryPoint;
