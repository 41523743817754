import { useCallback, useState } from 'react';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useProjectId, useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	DescriptionKey,
	SummaryKey,
} from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import { convertAdfToText } from '@atlassian/jira-rich-content/src/common/convert-adf-to-text/index.tsx';
import { useRelatedIssuesState, type RelatedIssue } from '../../controllers/context.tsx';

type Data = {
	data: RelatedIssue[] | undefined;
	error: unknown | undefined;
	loading: boolean;
};

type RelatedIssuesResponse = {
	issue_id: string;
	issue_key: string;
	issue_link: string;
};

const RELATED_ISSUES_API = '/gateway/api/assist/ai/v2/ai-feature/jirasimilarissues';

const parseData = (input: {
	ai_feature_output: { issues: RelatedIssuesResponse[] };
}): RelatedIssue[] => {
	return input?.ai_feature_output?.issues?.map((issue) => ({
		id: issue.issue_id,
		issueKey: issue.issue_key,
		issueLink: issue.issue_link,
	}));
};

export const useFetchRelatedIssue = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectId = useProjectId(projectKey);
	const [descriptionValue] = useFieldValue({
		issueKey,
		fieldKey: DescriptionKey,
	});

	const [summaryValue] = useFieldValue({
		issueKey,
		fieldKey: SummaryKey,
	});
	const { existingIssues } = useRelatedIssuesState();

	const [state, setState] = useState<Data>({
		data: undefined,
		error: undefined,
		loading: false,
	});

	const fetchSuggestion = useCallback(async () => {
		const payload = {
			ai_feature_input: {
				issue_key: issueKey,
				issue_summary: summaryValue,
				issue_description: convertAdfToText(descriptionValue),
				project_id: projectId,
				existing_issues: existingIssues,
			},
		};
		try {
			setState({
				error: undefined,
				data: undefined,
				loading: true,
			});
			const result = await performPostRequest(RELATED_ISSUES_API, {
				headers: {
					'Content-Type': 'application/json',
					'X-Product': 'jira',
					'X-Experience-Id': 'jira-similar-issues',
				},
				body: JSON.stringify(payload),
			});
			setState({
				error: undefined,
				data: parseData(result),
				loading: false,
			});
			return result;
		} catch (error) {
			setState({
				error,
				data: undefined,
				loading: false,
			});
			return undefined;
		}
	}, [descriptionValue, existingIssues, issueKey, projectId, summaryValue]);

	return {
		data: state.data,
		error: state.error,
		loading: state.loading,
		fetchSuggestion,
	};
};
