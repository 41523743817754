import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	acceptAll: {
		id: 'ai-related-issues.related-issues-draft-list.accept-all',
		defaultMessage: 'Accept all suggestions',
		description: 'Text for button which accepts all the ai provided suggestions',
	},
	close: {
		id: 'ai-related-issues.related-issues-draft-list.close',
		defaultMessage: 'Close',
		description: 'Text for button which closes the ai provided suggestions',
	},
	giveFeedback: {
		id: 'ai-related-issues.related-issues-draft-list.give-feedback',
		defaultMessage: 'Give feedback',
		description: 'Text for button used to provide feedback',
	},
	link: {
		id: 'ai-related-issues.related-issues-draft-list.link',
		defaultMessage: 'Link',
		description: 'Text for button used to link related issue',
	},
	remove: {
		id: 'ai-related-issues.related-issues-draft-list.remove',
		defaultMessage: 'Remove',
		description: 'Text for button used to remove related issue',
	},
});
