import React, { useMemo, type ComponentType } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { IssueBreakdownButton } from '@atlassian/jira-ai-work-breakdown/src/ui/issue-breakdown-button/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIsAiOptInEnabled } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import type { ChildIssuesLimitStatus } from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import {
	SectionHeading,
	SectionHeadingIcons,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { hasExceededIssuesLimitInitialLoad } from '../../common/util.tsx';
import { useIsAiEnabled } from '../utils.tsx';
import type { AddChildEventHandler } from './add-child/types.tsx';

// This number was calculated by hand to see when buttons started to overflow
const SINGLE_COLUMN_MAX_WIDTH = 440;

export type Props = {
	isSortingAvailable: boolean;
	Title: ComponentType;
	Meatballs: ComponentType<{
		childIssuesLimitStatus: ChildIssuesLimitStatus;
		isSortingAvailable?: boolean;
	}>;
	AddChild: ComponentType<{
		onClick?: AddChildEventHandler;
	}>;
	SortByMenu: ComponentType<{
		childIssuesLimitStatus: ChildIssuesLimitStatus;
	}>;
	allIssues: ChildIssue[];
	supportsIssueCreation: boolean | undefined;
	sourceName?: string;
	childIssuesLimitStatus: ChildIssuesLimitStatus;
	onAddChildClick: AddChildEventHandler | undefined;
	issueHierarchyLevel: number | undefined;
	containerWidth: number;
};

function Heading(props: Props) {
	const {
		Title,
		Meatballs,
		AddChild,
		SortByMenu,
		supportsIssueCreation,
		allIssues,
		onAddChildClick,
		sourceName,
		isSortingAvailable,
		childIssuesLimitStatus,
		issueHierarchyLevel,
		containerWidth,
	} = props;
	const isMeatballMenuEnabled =
		hasExceededIssuesLimitInitialLoad(childIssuesLimitStatus) || allIssues.length > 0;

	const isAiEnabled = useIsAiEnabled();

	const isAiOptInEnabled = ff('jira-intelligent-issue-breakdown_18syl')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsAiOptInEnabled()
		: false;

	const isAnonymousUser = ff('jira-intelligent-issue-breakdown_18syl')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsAnonymous()
		: false;

	const renderIssueBreakdownButton = useMemo(() => {
		return (
			<SpotlightManager>
				<SpotlightTarget name="suggestIssuesSpotlight">
					<IssueBreakdownButton
						issueHierarchyLevel={issueHierarchyLevel}
						isCompact={
							(!!containerWidth && containerWidth <= SINGLE_COLUMN_MAX_WIDTH) ||
							expVal('jsw-nike_chin_milestone_1_experiment', 'isChildIssueEnabled', false)
						}
					/>
				</SpotlightTarget>

				<EngagementSpotlight engagementId="suggestIssuesSpotlight" />
			</SpotlightManager>
		);
	}, [containerWidth, issueHierarchyLevel]);

	const oldSectionHeadingIcons = () => {
		return (
			<SectionHeadingIcons>
				{isSortingAvailable && <SortByMenu childIssuesLimitStatus={childIssuesLimitStatus} />}
				{isMeatballMenuEnabled ? (
					<Meatballs childIssuesLimitStatus={childIssuesLimitStatus} />
				) : null}
				{supportsIssueCreation ? <AddChild onClick={onAddChildClick} /> : null}

				{ff('jira-intelligent-issue-breakdown_18syl') &&
				!isAnonymousUser &&
				supportsIssueCreation &&
				isAiEnabled &&
				isAiOptInEnabled ? (
					<Box xcss={oldIssueBreakdownButtonStyles}>{renderIssueBreakdownButton}</Box>
				) : null}
			</SectionHeadingIcons>
		);
	};

	const sectionHeadingIcons = () => {
		return (
			<SectionHeadingIcons>
				{ff('jira-intelligent-issue-breakdown_18syl') &&
				!isAnonymousUser &&
				supportsIssueCreation &&
				isAiEnabled &&
				isAiOptInEnabled ? (
					<Box xcss={newIssueBreakdownButtonStyles}>{renderIssueBreakdownButton}</Box>
				) : null}
				{isMeatballMenuEnabled ? (
					<Meatballs childIssuesLimitStatus={childIssuesLimitStatus} isSortingAvailable />
				) : null}
				{supportsIssueCreation ? <AddChild onClick={onAddChildClick} /> : null}
			</SectionHeadingIcons>
		);
	};

	const ConfigurableChildIssueHeading = () => {
		return (
			<Box xcss={marginBottomStyles}>
				<SectionHeading>
					{}
					<label htmlFor={sourceName}>
						<Title />
					</label>
					{sectionHeadingIcons()}
				</SectionHeading>
			</Box>
		);
	};

	const OldChildIssueHeading = () => {
		return (
			<SectionHeading>
				{}
				<label htmlFor={sourceName}>
					<Title />
				</label>
				{oldSectionHeadingIcons()}
			</SectionHeading>
		);
	};

	return expVal('jsw-nike_chin_milestone_1_experiment', 'isChildIssueEnabled', false)
		? ConfigurableChildIssueHeading()
		: OldChildIssueHeading();
}

export default withContainerWidth(Heading);

const oldIssueBreakdownButtonStyles = xcss({
	marginLeft: 'space.075',
});

const newIssueBreakdownButtonStyles = xcss({
	marginRight: 'space.075',
});

const marginBottomStyles = xcss({
	marginBottom: 'space.100',
});

Heading.defaultProps = {
	isSortingAvailable: false,
	supportsIssueCreation: false,
	allIssues: [],
};
