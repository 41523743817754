/**
 * @generated SignedSource<<143933e43728a042dc12582cb6e25df4>>
 * @relayHash 1a23c39202e083ad4fc44d3d2b24bf53
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 58f95eb34f0d376ca3b1c44f9cf15204cd40c2b249d7d11f82d30685dfe9adde

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type InvokeExtensionInput = {
  async?: boolean | null | undefined;
  contextIds: ReadonlyArray<string>;
  entryPoint?: string | null | undefined;
  extensionDetails?: ExtensionDetailsInput | null | undefined;
  extensionId?: string | null | undefined;
  payload: AGG$JSON;
  schema?: string | null | undefined;
};
export type ExtensionDetailsInput = {
  definitionId: string;
  extensionKey: string;
};
export type dynamicPropertiesMutation$variables = {
  input: InvokeExtensionInput;
};
export type dynamicPropertiesMutation$data = {
  readonly invokeExtension: {
    readonly errors: ReadonlyArray<{
      readonly extensions: {
        readonly statusCode: number | null | undefined;
      } | null | undefined;
      readonly message: string | null | undefined;
    }> | null | undefined;
    readonly response: {
      readonly body: AGG$JSON;
    } | null | undefined;
    readonly success: boolean;
  } | null | undefined;
};
export type dynamicPropertiesMutation = {
  response: dynamicPropertiesMutation$data;
  variables: dynamicPropertiesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "InvocationResponsePayload",
  "kind": "LinkedField",
  "name": "response",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "body"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "message"
},
v5 = {
  "kind": "ScalarField",
  "name": "statusCode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "dynamicPropertiesMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "InvokeExtensionResponse",
        "kind": "LinkedField",
        "name": "invokeExtension",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "dynamicPropertiesMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "InvokeExtensionResponse",
        "kind": "LinkedField",
        "name": "invokeExtension",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  },
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "id"
                      }
                    ],
                    "type": "BulkMutationErrorExtension"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "58f95eb34f0d376ca3b1c44f9cf15204cd40c2b249d7d11f82d30685dfe9adde",
    "metadata": {},
    "name": "dynamicPropertiesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d8ddf6e5c2dae3430b192a70c44d0079";

export default node;
