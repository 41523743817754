/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { TOOLBAR_ACTION_SUBJECT_ID } from '@atlaskit/editor-common/analytics';
import { useSharedPluginState } from '@atlaskit/editor-common/hooks';
import { separatorStyles, wrapperStyle } from '@atlaskit/editor-common/styles';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import { ToolbarButton } from '@atlaskit/editor-common/ui-menu';
import type { WidthPlugin } from '@atlaskit/editor-plugin-width';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import { fg } from '@atlaskit/platform-feature-flags';
import { token } from '@atlaskit/tokens';
import Loading from '@atlassian/generative-ai-modal/assets/Loading';
import { LogoSvg as AtlassianIntelligenceLogo } from '@atlassian/generative-ai-modal/assets/Logo';
import sharedMessages from '@atlassian/generative-ai-modal/utils/messages';

import { svgIconStyles } from './styles';
import { useEditorToolbarChangeDetector } from './useEditorToolbarChangeDetector';

type Props = {
	onClick: () => void;
	disabled: boolean;
	selected?: boolean;
	loading?: boolean;
	api?: PublicPluginAPI<[WidthPlugin]> | undefined;
	ButtonWrapper?: React.ComponentType<{ children: React.ReactNode }>;
	view?: EditorView;
	proactive?: boolean;
};

// This const is derived from the breakpoint where the toolbar hides its icons. It is used to hide the text in the AI button.
// Derived from values from platform/packages/editor/editor-core/src/ui/Appearance/FullPage/MainToolbar.tsx
const AI_BUTTON_WIDTH_BREAKPOINT = 1076;

/**
 * @deprecated This method is deprecated and should be cleaned up once all products using AI
 * are on composable editor.
 */
const deprecatedGetWidthState = (view: EditorView) => {
	const editorWidthPlugin = view.state.plugins.find(
		(plugin) => (plugin as any).key === 'widthPlugin$',
	);

	return { widthState: editorWidthPlugin?.getState(view.state) };
};

const shouldShowSeparator = (node: HTMLSpanElement | null) => {
	if (!node) {
		return false;
	}

	const prevSeparator = node.previousElementSibling?.querySelector(
		`:scope > span:last-child:empty`,
	);
	const prevSeparatorVisible = prevSeparator && prevSeparator.clientWidth > 0;

	return !prevSeparatorVisible;
};

export const AtlassianIntelligenceToolbarButton = ({
	onClick,
	disabled,
	selected,
	loading,
	ButtonWrapper,
	api,
	view,
	proactive,
}: Props) => {
	const { formatMessage } = useIntl();

	const title = formatMessage(
		proactive ? sharedMessages.aiProactiveDescriptor : sharedMessages.aiUniversalDescriptor,
	);
	const [showSeparator, setShowSeperator] = useState(false);

	const { widthState } = useSharedPluginState(api, ['width']);

	let shouldShowLabel = widthState?.width ? widthState.width >= AI_BUTTON_WIDTH_BREAKPOINT : true;

	if (!widthState?.width && view) {
		const { widthState: deprecatedWidthState } = deprecatedGetWidthState(view);
		shouldShowLabel = deprecatedWidthState?.width >= AI_BUTTON_WIDTH_BREAKPOINT;
	}

	const callbackRef = (node: HTMLSpanElement | null) => {
		const result = shouldShowSeparator(node);
		setShowSeperator(result);
	};

	const button = (
		<ToolbarButton
			onClick={onClick}
			buttonId={TOOLBAR_ACTION_SUBJECT_ID.AI}
			aria-label={title}
			testId="atlassian-intelligence-toolbar-button"
			title={title}
			iconBefore={
				loading ? (
					<Loading alt={''} />
				) : // eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
				fg('platform-visual-refresh-icons') ? (
					<AtlassianIntelligenceIcon
						color={
							disabled ? token('color.icon.disabled', '#091E424F') : token('color.icon', '#44546F')
						}
						label="atlassian-intelligence-toolbar-button-icon"
						spacing="spacious"
					/>
				) : (
					<AtlassianIntelligenceLogo alt={''} isDisabled={disabled} />
				)
			}
			disabled={disabled}
			selected={selected ?? false}
		>
			{shouldShowLabel &&
				formatMessage(
					proactive
						? sharedMessages.aiProactiveCallToAction
						: sharedMessages.aiUniversalShortCallToAction,
				)}
		</ToolbarButton>
	);

	const toolbarMutationCount = useEditorToolbarChangeDetector(ButtonWrapper);

	const wrapped = ButtonWrapper ? (
		<ButtonWrapper key={`${toolbarMutationCount}-key-wrapper`}>{button}</ButtonWrapper>
	) : (
		button
	);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<span ref={callbackRef} css={[wrapperStyle, svgIconStyles]}>
			{showSeparator && (
				/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */
				<span data-testid="ai-toolbar-button-seperator" css={separatorStyles} />
			)}
			{wrapped}
		</span>
	);
};
