export const EMBEDDED_CONFLUENCE_ISSUE_VIEW_CROSS_JOIN_EXPERIMENT_KEY =
	'platform_embed-conf_jira-issue_cross-join_exp';

export const EMBEDDED_CONFLUENCE_ISSUE_VIEW_CREATE_CONFLUENCE_CONTENT_CROSS_FLOW_KEY =
	'jira_create_confluence_content_crossflow';

export const EMBEDDED_CONFLUENCE_ISSUE_VIEW_SIDE_BY_SIDE_MODELESS_EP_EXP_KEY =
	'jira_issue_view_side_by_side_modeless_ep_exp';
export const EMBEDDED_CONFLUENCE_ISSUE_VIEW_SIDE_BY_SIDE_MODELESS_EP_EXP_KEY_AA =
	'jira_issue_view_side_by_side_modeless_ep_aa_exp';

export const EMBEDDED_CONFLUENCE_ISSUE_VIEW_AUTO_LINK_PAGES_ON_LINK_PASTE_KEY =
	'jira-issue_auto-link-pages-on-link-paste';
export const EMBEDDED_CONFLUENCE_ISSUE_VIEW_AUTO_LINK_PAGES_ON_LINK_PASTE_KEY_AA =
	'jira-issue_auto-link-pages-on-link-paste_aa';

export const COHORT = 'cohort';
export const NOT_ENROLLED_COHORT = 'not-enrolled';
export const EXPERIMENT_COHORT = 'experiment';
export const CONTROL_COHORT = 'control';
