import { invokeAgent } from '@atlassian/jira-ai-assistance-service-client/src/services/invoke-agent/index.tsx';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import {
	type Action,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import { createConfluencePageLinkRequest } from './link-confluence-page-api.tsx';

export type RelatedResource = {
	id: string;
	url: string;
};

export type ResourceSuggestion = {
	resource: RelatedResource;
	isLinking: boolean;
	errorMessage?: string;
	resourceStatus: 'DRAFT' | 'ACCEPTED' | 'DISCARDED';
};

// State of the Related resources context
type State = {
	resourceSuggestions?: ResourceSuggestion[];
	isLoadingSuggestions: boolean;
	errorMessage: string | null;
};
const initialState: State = {
	resourceSuggestions: undefined,
	isLoadingSuggestions: false,
	errorMessage: null,
};

export type ConfluencePageSet = (ConfluencePage | FailedRemoteLink)[];
export type ContainerProps = {
	issueId: string | undefined;
	issueKey: string | undefined;
	onCloseAISuggestions: () => void;
	onCreateConfluencePageLinkSuccess: (confluencePageLink: ConfluencePage | FailedRemoteLink) => {
		type: string;
		payload: { confluencePageLink: ConfluencePage | FailedRemoteLink };
	};
	linkedConfluencePages?: ConfluencePageSet;
	mentionedConfluencePages?: ConfluencePageSet;
};

const hasNoDrafts = (suggestions: ResourceSuggestion[] | undefined): boolean => {
	return (
		suggestions !== undefined &&
		suggestions.filter((suggestion) => suggestion.resourceStatus === 'DRAFT').length === 0
	);
};

const isPageError = (page: ConfluencePage | FailedRemoteLink): page is FailedRemoteLink =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(page as FailedRemoteLink).error !== undefined;

const getlinkedConfluenceUrls = (
	linkedConfluencePages: ConfluencePageSet | undefined,
	mentionedConfluencePages: ConfluencePageSet | undefined,
): Set<String> => {
	const urlSet = new Set<string>();
	[...(linkedConfluencePages || []), ...(mentionedConfluencePages || [])].forEach((page) => {
		const url = isPageError(page) ? page?.link?.href : page.href;
		urlSet.add(url);
	});
	return urlSet;
};

const acceptSuggestions =
	(linkResourcesRequest: RelatedResource[]): Action<State, ContainerProps> =>
	async (
		{ getState, setState },
		{
			onCloseAISuggestions,
			issueId,
			onCreateConfluencePageLinkSuccess,
			linkedConfluencePages,
			mentionedConfluencePages,
		},
	) => {
		const linkedUrls = getlinkedConfluenceUrls(linkedConfluencePages, mentionedConfluencePages);

		// Set isLinking to True
		const idsToBeLinked = linkResourcesRequest.map((res) => res.id);
		const resourceSuggestionsLinking = getState().resourceSuggestions?.map((res) =>
			idsToBeLinked.includes(res.resource.id) ? { ...res, isLinking: true } : res,
		);
		setState({
			resourceSuggestions: resourceSuggestionsLinking,
		});

		// Call api to create the links
		linkResourcesRequest.forEach(async (request) => {
			const isSuccess = await (async () => {
				try {
					if (linkedUrls.has(request.url)) {
						// the requested url is already linked to the issue
						// then consider linking to be successful so that it goes to accepted state
						return true;
					}
					const createdLink = await createConfluencePageLinkRequest({
						issueId: issueId || '',
						pageHref: request.url,
					});
					onCreateConfluencePageLinkSuccess(createdLink);
					return true;
				} catch (error) {
					return false;
				}
			})();

			const resourceSuggestionsAfterLinking: ResourceSuggestion[] | undefined =
				getState().resourceSuggestions?.map((res: ResourceSuggestion) => {
					if (request.id === res.resource.id) {
						return {
							...res,
							isLinking: false,
							resourceStatus: isSuccess ? 'ACCEPTED' : res.resourceStatus,
							errorMessage: !isSuccess
								? 'Error occured while linking resource to issue'
								: undefined,
						};
					}
					return res;
				});
			setState({
				resourceSuggestions: resourceSuggestionsAfterLinking,
			});
			if (hasNoDrafts(resourceSuggestionsAfterLinking)) {
				onCloseAISuggestions();
			}
		});
	};

const discardSuggestion =
	(resourceToBeDiscarded: RelatedResource): Action<State, ContainerProps> =>
	({ getState, setState }, { onCloseAISuggestions }) => {
		const resourceSuggestionsAfterDiscarding: ResourceSuggestion[] | undefined =
			getState().resourceSuggestions?.map((res: ResourceSuggestion) =>
				resourceToBeDiscarded.id === res.resource.id
					? { ...res, resourceStatus: 'DISCARDED' }
					: res,
			);
		setState({
			resourceSuggestions: resourceSuggestionsAfterDiscarding,
		});

		if (hasNoDrafts(resourceSuggestionsAfterDiscarding)) {
			onCloseAISuggestions();
		}
	};

const discardAllSuggestions = (): Action<State, ContainerProps> => (_, containerProps) => {
	containerProps.onCloseAISuggestions();
};

export const actions = { acceptSuggestions, discardSuggestion, discardAllSuggestions };
type ActionsType = typeof actions;

export const JiraIssueRelatedResourcesContainer = createContainer<ContainerProps>({
	displayName: 'JiraIssueRelatedResourcesContainer',
});
export const Store = createStore<State, ActionsType, ContainerProps>({
	initialState,
	actions,
	name: 'jira-ai-related-resources',
	containedBy: JiraIssueRelatedResourcesContainer,
	handlers: {
		onInit:
			() =>
			async ({ setState }, { issueKey }) => {
				setState({
					isLoadingSuggestions: true,
				});

				try {
					const result = await invokeAgent('jira_issue_related_resources_agent', {
						experienceId: 'jira-issue-related-resources',
						agent_input_context: { issue_key: issueKey || '' },
					});
					setState({
						isLoadingSuggestions: false,
						resourceSuggestions: (result?.content?.suggested_resources || []).map(
							(suggestedResource) => {
								return { resource: suggestedResource, isLinking: false, resourceStatus: 'DRAFT' };
							},
						),
						errorMessage: null,
					});
				} catch (error) {
					setState({
						isLoadingSuggestions: false,
						errorMessage: 'Error occured while fetching suggestions',
					});
				}
			},
	},
});

export const useRelatedResources = createHook(Store);
