import { fetchQuery, graphql } from 'react-relay';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags';
import { toFlagId } from '@atlassian/jira-flags/src/services/types';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type {
	services_providerAuthCheckQuery$data as Response,
	ToolchainCheckAuthErrorCode,
} from '@atlassian/jira-relay/src/__generated__/services_providerAuthCheckQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { AUTH_WINDOW_CLOSED_ERROR_TYPE } from './constants.tsx';
import messages from './messages.tsx';
import type { CheckAuthProps, TriggerAuthFlowArgs, HandleAuthArgs } from './types.tsx';
import { auth } from './utils.tsx';

export const checkAuth = async ({
	cloudId,
	providerId,
	isCheckAuthV2Enabled,
}: CheckAuthProps): Promise<Response> =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	fetchQuery(
		getRelayEnvironment(),
		graphql`
			query services_providerAuthCheckQuery(
				$cloudId: ID!
				$providerId: String!
				$isCheckAuthV2Enabled: Boolean!
			) {
				devOps @required(action: THROW) {
					toolchain @optIn(to: "Toolchain") {
						checkAuth(cloudId: $cloudId, providerId: $providerId) @skip(if: $isCheckAuthV2Enabled) {
							authorized
							__typename
							... on ToolchainCheck3LOAuth {
								grant {
									authorizationEndpoint
								}
							}
						}
						checkAuthV2(cloudId: $cloudId, providerId: $providerId)
							@include(if: $isCheckAuthV2Enabled) {
							__typename
							... on ToolchainCheck3LOAuth {
								authorized
								grant {
									authorizationEndpoint
								}
							}
							... on QueryError {
								extensions {
									... on ToolchainCheckAuthErrorExtension {
										errorCode
									}
								}
							}
						}
					}
				}
			}
		`,
		{
			cloudId,
			providerId,
			isCheckAuthV2Enabled,
		},
	).toPromise() as Promise<Response>;

export const useTriggerAuthFlow = () => {
	const { showFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	return ({
		grantAuthUrl,
		providerName,
		providerCount,
		flagId,
		onSuccess,
		onFailure,
	}: TriggerAuthFlowArgs) =>
		auth(grantAuthUrl)
			.then(() => {
				onSuccess?.();
			})
			.catch((error) => {
				onFailure?.();

				if (error?.type !== AUTH_WINDOW_CLOSED_ERROR_TYPE) {
					showFlag({
						key: flagId ? toFlagId(flagId) : undefined,
						type: 'error',
						title: formatMessage(messages.authenticationFailedFlagTitle),
						description: formatMessage(messages.authenticationFailedFlagDescription, {
							providerCount,
							singleProvider: providerName,
						}),
						isAutoDismiss: true,
					});

					fireErrorAnalytics({
						event: createAnalyticsEvent({
							action: 'failed',
							actionSubject: 'designProviderAuth',
						}),
						error,
						meta: {
							teamName: 'helios-headcoach',
							id: 'designProviderAuth',
						},
						sendToPrivacyUnsafeSplunk: true,
					});
				}

				throw error;
			});
};

export const AUTH_ERROR_FLAG_ID = 'authError';

export const getCheckAuthExpectedErrorFlagMessages = (
	errorCode?: ToolchainCheckAuthErrorCode | null,
): { flagTitle: MessageDescriptor; flagDescription: MessageDescriptor } => {
	switch (errorCode) {
		case 'PROVIDER_ERROR':
			return {
				flagTitle: messages.authenticationFailedFlagTitle,
				flagDescription: messages.authenticationFailedFlagDescription,
			};
		default:
			return {
				flagTitle: messages.authenticationFailedFlagTitle,
				flagDescription: messages.authenticationFailedFlagDescription,
			};
	}
};

export const useHandleAuthorization = () => {
	const triggerAuthFlow = useTriggerAuthFlow();
	const cloudId = useCloudId();
	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();
	return async ({
		providerId,
		providerName,
		providerCount,
		onSuccess,
		onFailure,
	}: HandleAuthArgs) => {
		const response = await checkAuth({
			cloudId,
			providerId,
			isCheckAuthV2Enabled: fg('jira-issue-view-designs-check-auth-v2'),
		});

		if (fg('jira-issue-view-designs-check-auth-v2')) {
			switch (response.devOps?.toolchain?.checkAuthV2?.__typename) {
				case 'QueryError': {
					const flagMessages = getCheckAuthExpectedErrorFlagMessages(
						response.devOps?.toolchain?.checkAuthV2?.extensions?.[0]?.errorCode,
					);
					showFlag({
						key: AUTH_ERROR_FLAG_ID,
						type: 'error',
						title: formatMessage(flagMessages.flagTitle),
						description: formatMessage(flagMessages.flagDescription, {
							providerCount,
							singleProvider: providerName,
						}),
						isAutoDismiss: true,
					});
					break;
				}
				case 'ToolchainCheck3LOAuth': {
					if (response?.devOps?.toolchain?.checkAuthV2?.authorized) {
						onSuccess?.();
					} else {
						const grantAuthUrl =
							response?.devOps.toolchain?.checkAuthV2?.grant?.authorizationEndpoint;

						if (grantAuthUrl) {
							await triggerAuthFlow({
								grantAuthUrl,
								providerName,
								providerCount,
								flagId: AUTH_ERROR_FLAG_ID,
								onSuccess,
								onFailure,
							});
							break;
						} else {
							showFlag({
								key: AUTH_ERROR_FLAG_ID,
								type: 'error',
								title: formatMessage(messages.authenticationFailedFlagTitle),
								description: formatMessage(messages.authenticationFailedFlagDescription, {
									providerCount,
									singleProvider: providerName,
								}),
								isAutoDismiss: true,
							});
						}
					}
					break;
				}
				default: {
					showFlag({
						key: AUTH_ERROR_FLAG_ID,
						type: 'error',
						title: formatMessage(messages.authenticationFailedFlagTitle),
						description: formatMessage(messages.authenticationFailedFlagDescription, {
							providerCount,
							singleProvider: providerName,
						}),
						isAutoDismiss: true,
					});
				}
			}
		} else {
			const authorized = response?.devOps?.toolchain?.checkAuth?.authorized;
			if (authorized) {
				onSuccess?.();
			} else {
				const grantAuthUrl = response?.devOps.toolchain?.checkAuth?.grant?.authorizationEndpoint;

				if (grantAuthUrl) {
					await triggerAuthFlow({
						grantAuthUrl,
						providerName,
						providerCount,
						flagId: AUTH_ERROR_FLAG_ID,
						onSuccess,
						onFailure,
					});
				} else {
					showFlag({
						key: AUTH_ERROR_FLAG_ID,
						type: 'error',
						title: formatMessage(messages.authenticationFailedFlagTitle),
						description: formatMessage(messages.authenticationFailedFlagDescription, {
							providerCount,
							singleProvider: providerName,
						}),
						isAutoDismiss: true,
					});
				}
			}
		}
	};
};
