import { useCallback, useEffect, useState } from 'react';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { BackfillDesign } from '../../common/types.tsx';
import { extractDesignName, getNotIngestedDesigns } from '../utils.tsx';

const extractAttachedDesignUrlPropertyValue = (
	value: string | null | undefined,
): BackfillDesign | undefined =>
	value ? { name: extractDesignName(value), url: value } : undefined;

// 'attached-design-url-v2' is expected to be a JSON stringified array of objects.
const extractAttachedDesignUrlV2PropertyValue = (
	value: string | null | undefined,
): BackfillDesign[] => (value ? JSON.parse(value) : []);

/**
 * Validates the issue property values for `attached-design-url` & `attached-design-url-v2`
 * and diffs with the given design URLs to find any designs that have not been ingested yet.
 * @returns A list of not ingested Figma design URLs
 */
export const useBackfillRequiredDesigns = ({
	designUrls,
	attachedDesignUrlV1,
	attachedDesignUrlV2,
}: {
	designUrls: string[];
	attachedDesignUrlV1: string | null | undefined;
	attachedDesignUrlV2: string | null | undefined;
}): {
	backfillRequiredDesigns: BackfillDesign[];
	clearBackfillRequiredDesigns: () => void;
	hasBackfillFinishedParsing: boolean;
} => {
	const issueId = useIssueId();
	const [hasFinishedParsing, setHasFinishedParsing] = useState(false);
	const [backfillRequiredDesigns, setBackfillRequiredDesigns] = useState<BackfillDesign[]>([]);

	const clearBackfillRequiredDesigns = useCallback(() => {
		setBackfillRequiredDesigns([]);
	}, []);

	useEffect(() => {
		// For SPA transitions, the issue remains open but the issueId changes
		// This is to ensure the slate is reset for the new issue load.
		if (issueId) {
			clearBackfillRequiredDesigns();
			setHasFinishedParsing(false);
		}
	}, [issueId, clearBackfillRequiredDesigns]);

	useEffect(() => {
		const issuePropertyDesignV1 = extractAttachedDesignUrlPropertyValue(attachedDesignUrlV1);
		const issuePropertyDesignsV2 = extractAttachedDesignUrlV2PropertyValue(attachedDesignUrlV2);
		if (issuePropertyDesignV1) issuePropertyDesignsV2.push(issuePropertyDesignV1);

		const notIngestedDesigns = getNotIngestedDesigns(issuePropertyDesignsV2, designUrls);

		setBackfillRequiredDesigns(notIngestedDesigns);
		setHasFinishedParsing(true);
	}, [designUrls, attachedDesignUrlV1, attachedDesignUrlV2]);

	return {
		backfillRequiredDesigns,
		clearBackfillRequiredDesigns,
		hasBackfillFinishedParsing: hasFinishedParsing,
	};
};
