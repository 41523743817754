import React, { Fragment, type ReactNode } from 'react';
import { AsyncSuggestedResourcesPanel } from '@atlassian/jira-aiops-suggested-resources/src/ui/suggestion-panel/async.tsx';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import { DevOpsAppRecommendationContextProvider as DevOpsAppRecommendationProvider } from '@atlassian/jira-dev-ops-app-recommendations-context-provider/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { toForgeKeyWithDate } from '@atlassian/jira-forge-ui-utils/src/utils/connect/index.tsx';
import AttachmentsView from '@atlassian/jira-issue-view-base/src/content/attachment/panel/index.tsx';
import AutomationDiscoveryContainerPanel from '@atlassian/jira-issue-view-base/src/content/automation/index.tsx';
import ChildrenIssuesView from '@atlassian/jira-issue-view-base/src/content/children-issues/index.tsx';
import ConfluenceContentView from '@atlassian/jira-issue-view-base/src/content/confluence-content/index.tsx';
import * as PerfMarks from '@atlassian/jira-issue-view-base/src/content/constants.tsx';
import {
	AsyncDesignPanel,
	SyncDesignPanel,
} from '@atlassian/jira-issue-view-base/src/content/designs/index.tsx';
import { AsyncDevOpsAppRecommendationPanel } from '@atlassian/jira-issue-view-base/src/content/dev-ops-recommendations/index.tsx';
import EpicIssuesView from '@atlassian/jira-issue-view-base/src/content/epic-issues/index.tsx';
import { IncidentInvestigationPanel } from '@atlassian/jira-issue-view-base/src/content/incident-investigation-panel/index.tsx';
import IssueLinksView from '@atlassian/jira-issue-view-base/src/content/issue-links/index.tsx';
import LinkedAlertsModals from '@atlassian/jira-issue-view-base/src/content/linked-alerts-modals/index.tsx';
import { LoomPanel } from '@atlassian/jira-issue-view-base/src/content/loom-videos/index.tsx';
import ObjectRelatedRequestsPanel from '@atlassian/jira-issue-view-base/src/content/object-related-requests-panel/index.tsx';
import JiraOpsAgentDiscoverabilityPanel from '@atlassian/jira-issue-view-base/src/content/ops-agent-discoverability-panel/index.tsx';
import PlaybooksPanel from '@atlassian/jira-issue-view-base/src/content/playbooks/index.tsx';
import PortfolioChildIssuesView from '@atlassian/jira-issue-view-base/src/content/portfolio-child-issues/index.tsx';
import WrappedProformaFormsPanelView from '@atlassian/jira-issue-view-base/src/content/proforma-forms/index.tsx';
import SubtasksView from '@atlassian/jira-issue-view-base/src/content/subtasks/index.tsx';
import type { Props } from '@atlassian/jira-issue-view-base/src/content/types.tsx';
import { ConnectedView as WebLinksConnectedView } from '@atlassian/jira-issue-view-base/src/content/web-links/index.tsx';
import { withGICHook } from '@atlassian/jira-issue-view-base/src/content/with-gic-hook/index.tsx';
import { FORGE_ENTITY_TYPE } from '@atlassian/jira-issue-view-common-constants/src/ecosystem-constants.tsx';
import {
	ContentItemsWrapper,
	IssueSectionWrapper,
} from '@atlassian/jira-issue-view-common-styles/src/issue-layout.tsx';
import type { Panel as ForgePanel } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { isDevOpsAppRecommenderEnabled } from '@atlassian/jira-issue-view-common/src/feature-flags.tsx';
import ConnectContentPanel, {
	type ContentPanel,
} from '@atlassian/jira-issue-view-ecosystem/src/connect/content-panel-view.tsx';
import ForgeContentPanel from '@atlassian/jira-issue-view-ecosystem/src/forge/content-panel-view.tsx';
import { JPDIdeasPanel } from '@atlassian/jira-issue-view-jpd-ideas/src/ui/index.tsx';
import { ContentTemplateRenderer } from '@atlassian/jira-issue-view-layout-templates/src/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	contextSelector,
	isAnonymousSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	isCompletedLoadingSelector,
	isEpicSelector,
	isServiceDeskSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { jpdDeliverLinkTypeIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/jpd-delivery-link-id-selector.tsx';
import { ecosystemAddedContentPanelsSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector.tsx';
import {
	shouldRenderChildPanelSelector,
	shouldRenderPortfolioChildPanelSelector,
	shouldRenderSubtaskPanelSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/child-panel-ui-selector.tsx';
import { isIssueLinksEnabledSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector.tsx';
import { showLinkIdeasInputSelector } from '@atlassian/jira-issue-view-store/src/selectors/link-idea-selector.tsx';
import { additionalWebLinksSectionsSelector } from '@atlassian/jira-issue-view-store/src/selectors/web-links-selector.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { FireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIsJCS } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs/index.tsx';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import SmartRelatedIssuesPanel from '@atlassian/jira-smart-related-issues-panel/src/ui/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import DeprecatedReduxConnectedView from './content-items-view-class.tsx';
import { EcosystemIssueViewErrorBoundary } from './ecosystem-issue-view-error-boundary.tsx';

const DevOpsAppRecommendationContextProvider = componentWithCondition(
	isDevOpsAppRecommenderEnabled,
	DevOpsAppRecommendationProvider,
	({ children }: { children: ReactNode }) => <>{children}</>,
);

export const ContentItems: React.FC<Props> = ({
	isComplete = false,
	isCompactMode = false,
	issueKey,
	isEpic = false,
	isAnonymousUser = false,
	hasIssueLinksEntity = false,
	shouldRenderChildrenIssues,
	shouldRenderPortfolioChildrenIssues,
	shouldRenderSubtasks,
	isServiceDesk,
	issueViewRelayFragment,
	jpdDeliveryIssueLinkTypeId,
	shouldShowLinkIdeaInput,
	contentPanels = [],
	additionalWebLinksSections = [],
	projectType,
	context,
	rootRelayFragment,
}) => {
	const isJCS = useIsJCS();
	const maybeWrapInSectionWrapper = (component: ReactNode) => {
		if (!component) {
			return component;
		}

		return <IssueSectionWrapper isCompactMode={isCompactMode}>{component}</IssueSectionWrapper>;
	};

	const renderEcosystemContentPanels = () => {
		let application = null;
		let edition = null;

		if (projectType) {
			application = getApplicationForProject(projectType);

			if (application && context && context.appEditions) {
				edition = getEdition(application, context.appEditions);
			}
		}

		const panels = contentPanels.map((panel: ForgePanel | ContentPanel) => {
			if (panel.type === FORGE_ENTITY_TYPE) {
				const { name, appKey, moduleKey, dateAdded, panelInstanceId, extension } =
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					panel as any;

				const panelKey = toForgeKeyWithDate(appKey, moduleKey, panelInstanceId, dateAdded);
				return (
					<UFOSegment name="issue-content-forge-panel" key={panel.name}>
						<EcosystemIssueViewErrorBoundary
							application={application}
							edition={edition}
							experience="issueViewContentItemForgePanel"
							key={panel.name}
						>
							<ForgeContentPanel
								key={panelKey}
								panelKey={panelKey}
								name={name}
								appKey={appKey}
								moduleKey={moduleKey}
								extension={extension}
								isCompactMode={isCompactMode}
								externalId="issue.ecosystem.content-panel.forge"
							/>
						</EcosystemIssueViewErrorBoundary>
					</UFOSegment>
				);
			}

			if (
				panel.moduleKey.includes('linked-goals-content-panel') &&
				expVal('native_goals_on_issue_view', 'isGoalsFieldEnabled', false)
			) {
				return null;
			}

			return (
				<UFOSegment name="issue-content-connect-panel" key={panel.name}>
					<EcosystemIssueViewErrorBoundary
						application={application}
						edition={edition}
						experience="issueViewContentItemConnectPanel"
						key={panel.name}
					>
						<ConnectContentPanel
							key={panel.name}
							name={panel.name}
							appKey={panel.appKey}
							moduleKey={panel.moduleKey}
						/>
					</EcosystemIssueViewErrorBoundary>
				</UFOSegment>
			);
		});

		return <>{panels}</>;
	};

	const renderAdditionalWebLinksSections = () => {
		return (
			<>
				{additionalWebLinksSections.length > 0 && (
					<FireTrackAnalytics
						eventName="additionalWebLinksSections shown"
						componentName="additionalWebLinksSections"
					/>
				)}
				{additionalWebLinksSections.map(({ applicationId, applicationName }) => (
					<Fragment key={applicationId}>
						<UFOSegment name="issue-content-additional-web-links">
							{maybeWrapInSectionWrapper(
								<WebLinksConnectedView
									applicationId={applicationId}
									applicationName={applicationName}
								/>,
							)}
						</UFOSegment>
					</Fragment>
				))}
			</>
		);
	};

	const issueLinks = hasIssueLinksEntity ? <IssueLinksView /> : null;

	const subtasks = shouldRenderSubtasks ? (
		<UFOSegment name="issue-subtasks">
			<SubtasksView key={issueKey} />
		</UFOSegment>
	) : null;

	const childrenIssues = shouldRenderChildrenIssues ? (
		<UFOSegment name="issue-child-issues">
			<ChildrenIssuesView key={issueKey} />
		</UFOSegment>
	) : null;

	const jpdPanel = ff('polaris.jpd-panel-in-issue-view', false) ? (
		<JPDIdeasPanel
			jpdDeliveryIssueLinkTypeId={jpdDeliveryIssueLinkTypeId}
			shouldShowAddIssueLinks={shouldShowLinkIdeaInput}
			issueViewRelayFragment={issueViewRelayFragment}
		/>
	) : null;

	const loomPanel = fg('loom_crossflow_enablement_in_jira') ? <LoomPanel /> : null;

	const portfolioChildIssues = shouldRenderPortfolioChildrenIssues ? (
		// @ts-expect-error TS274 missing analyticsSource, issueTypes, fetchIssueTypesStatus, onAddChildClick, and 3 more.
		<PortfolioChildIssuesView key={issueKey} />
	) : null;

	const epicIssues = isEpic ? (
		<UFOSegment name="issue-epics-issues">
			<EpicIssuesView key={issueKey} />
		</UFOSegment>
	) : null;

	const attachments = isComplete ? <AttachmentsView /> : null;

	const devOpsAppRecommendation = isDevOpsAppRecommenderEnabled() ? (
		<Placeholder name="devOpsAppRecommendation" fallback={null}>
			<AsyncDevOpsAppRecommendationPanel />
		</Placeholder>
	) : null;

	let designPanel = null;
	if (!isAnonymousUser) {
		if (ff('make_designs_panel_in_issue_view_synchronous')) {
			designPanel = (
				<SyncDesignPanel
					issueViewRelayFragment={issueViewRelayFragment}
					rootRelayFragment={rootRelayFragment}
				/>
			);
		} else {
			designPanel = <AsyncDesignPanel />;
		}
	}

	return (
		<UFOSegment name="issue-content-items">
			<ContentItemsWrapper isCompactMode={isCompactMode}>
				<DevOpsAppRecommendationContextProvider>
					<PerformanceMark metricKey={PerfMarks.DESCRIPTION_BEGIN_MARK_KEY} />
					<ContentTemplateRenderer issueViewRelayFragment={issueViewRelayFragment} />
					<PerformanceMark metricKey={PerfMarks.DESCRIPTION_END_MARK_KEY} />
					<PerformanceMark metricKey={PerfMarks.ATTACHMENTS_BEGIN_MARK_KEY} />
					{maybeWrapInSectionWrapper(attachments)}
					<PerformanceMark metricKey={PerfMarks.ATTACHMENTS_END_MARK_KEY} />

					<PerformanceMark metricKey={PerfMarks.DESIGNS_BEGIN_MARK_KEY} />
					{maybeWrapInSectionWrapper(designPanel)}
					<PerformanceMark metricKey={PerfMarks.DESIGNS_END_MARK_KEY} />
					<PerformanceMark metricKey={PerfMarks.DEV_OPS_APP_RECOMMENDATIONS_BEGIN_MARK_KEY} />
					<UFOSegment name="issue-content-dev-ops-app-recomendations">
						{maybeWrapInSectionWrapper(devOpsAppRecommendation)}
					</UFOSegment>
					<PerformanceMark metricKey={PerfMarks.DEV_OPS_APP_RECOMMENDATIONS_END_MARK_KEY} />
					{ff('polaris.jpd-panel-in-issue-view', false) ? (
						<>
							<PerformanceMark metricKey={PerfMarks.JPD_IDEAS_BEGIN_MARK_KEY} />
							<UFOSegment name="issue-content-jpd-ideas">
								{maybeWrapInSectionWrapper(jpdPanel)}
							</UFOSegment>
							<PerformanceMark metricKey={PerfMarks.JPD_IDEAS_END_MARK_KEY} />
						</>
					) : null}

					{fg('loom_crossflow_enablement_in_jira') ? (
						<>
							<PerformanceMark metricKey={PerfMarks.LOOM_PANEL_BEGIN_MARK_KEY} />
							{maybeWrapInSectionWrapper(loomPanel)}
							<PerformanceMark metricKey={PerfMarks.LOOM_PANEL_END_MARK_KEY} />
						</>
					) : null}

					<PerformanceMark metricKey={PerfMarks.EPICS_BEGIN_MARK_KEY} />
					{maybeWrapInSectionWrapper(epicIssues)}
					<PerformanceMark metricKey={PerfMarks.EPICS_END_MARK_KEY} />

					<PerformanceMark metricKey={PerfMarks.CHILDREN_ISSUES_BEGIN_MARK_KEY} />
					{maybeWrapInSectionWrapper(childrenIssues)}
					<PerformanceMark metricKey={PerfMarks.CHILDREN_ISSUES_END_MARK_KEY} />

					<PerformanceMark metricKey={PerfMarks.PORTFOLIO_CHILD_ISSUES_BEGIN_MARK_KEY} />
					<UFOSegment name="issue-content-portfolio-child-issues">
						{maybeWrapInSectionWrapper(portfolioChildIssues)}
					</UFOSegment>
					<PerformanceMark metricKey={PerfMarks.PORTFOLIO_CHILD_ISSUES_END_MARK_KEY} />

					<PerformanceMark metricKey={PerfMarks.SUBTASKS_BEGIN_MARK_KEY} />
					{maybeWrapInSectionWrapper(subtasks)}
					<PerformanceMark metricKey={PerfMarks.SUBTASKS_END_MARK_KEY} />

					<PerformanceMark metricKey={PerfMarks.ISSUE_LINKS_BEGIN_MARK_KEY} />
					{maybeWrapInSectionWrapper(issueLinks)}
					<PerformanceMark metricKey={PerfMarks.ISSUE_LINKS_END_MARK_KEY} />

					{isServiceDesk && (
						<>
							<PerformanceMark metricKey={PerfMarks.INCIDENT_INVESTIGATIONS_BEGIN_MARK_KEY} />
							<UFOSegment name="issue-content-incident-investigations">
								{maybeWrapInSectionWrapper(<IncidentInvestigationPanel />)}
							</UFOSegment>
							<PerformanceMark metricKey={PerfMarks.INCIDENT_INVESTIGATIONS_END_MARK_KEY} />

							{!isJCS && fg('automation_discoverability_in_jsm') && (
								<>
									<PerformanceMark
										metricKey={PerfMarks.AUTOMATION_DISCOVERY_PANEL_BEGIN_MARK_KEY}
									/>
									<UFOSegment name="issue-content-automation-discovery">
										{maybeWrapInSectionWrapper(<AutomationDiscoveryContainerPanel />)}
									</UFOSegment>
									<PerformanceMark metricKey={PerfMarks.AUTOMATION_DISCOVERY_PANEL_END_MARK_KEY} />
								</>
							)}

							{fg('playbooks-in-jsm') && (
								// TODO: https://ifountain.atlassian.net/browse/BIF-1447 --> This placement is subject to change. Finalize the placement of Playbooks in Issue View
								<>
									<PerformanceMark metricKey={PerfMarks.PLAYBOOKS_PANEL_BEGIN_MARK_KEY} />
									<UFOSegment name="issue-content-playbooks">
										{maybeWrapInSectionWrapper(<PlaybooksPanel />)}
									</UFOSegment>
									<PerformanceMark metricKey={PerfMarks.PLAYBOOKS_PANEL_END_MARK_KEY} />
								</>
							)}
						</>
					)}
					{fg('ops_agent_discoverability_panel_issue_view') && (
						<>
							<PerformanceMark metricKey={PerfMarks.IT_OPS_DISCOVERABILITY_BEGIN_MARK_KEY} />
							<UFOSegment name="incident-itops-discoverability">
								{maybeWrapInSectionWrapper(<JiraOpsAgentDiscoverabilityPanel />)}
							</UFOSegment>
							<PerformanceMark metricKey={PerfMarks.IT_OPS_DISCOVERABILITY_END_MARK_KEY} />
						</>
					)}

					<PerformanceMark metricKey={PerfMarks.OBJECT_RELATED_REQUESTS_BEGIN_MARK_KEY} />
					<UFOSegment name="issue-content-object-related-requests">
						{maybeWrapInSectionWrapper(<ObjectRelatedRequestsPanel />)}
					</UFOSegment>
					<PerformanceMark metricKey={PerfMarks.OBJECT_RELATED_REQUESTS_END_MARK_KEY} />

					{fg('incident_suggested_resources') ? (
						<>
							<PerformanceMark metricKey={PerfMarks.SUGGESTED_RESOURCES_BEGIN_MARK_KEY} />
							{maybeWrapInSectionWrapper(<AsyncSuggestedResourcesPanel />)}
							<PerformanceMark metricKey={PerfMarks.SUGGESTED_RESOURCES_END_MARK_KEY} />
						</>
					) : null}

					<PerformanceMark metricKey={PerfMarks.SMART_RELATED_ISSUES_BEGIN_MARK_KEY} />
					<UFOSegment name="issue-content-smart-related-issues">
						{maybeWrapInSectionWrapper(<SmartRelatedIssuesPanel />)}
					</UFOSegment>
					<PerformanceMark metricKey={PerfMarks.SMART_RELATED_ISSUES_END_MARK_KEY} />

					<PerformanceMark metricKey={PerfMarks.CONFLUENCE_PAGES_BEGIN_MARK_KEY} />
					{maybeWrapInSectionWrapper(<ConfluenceContentView />)}
					<PerformanceMark metricKey={PerfMarks.CONFLUENCE_PAGES_END_MARK_KEY} />

					<PerformanceMark metricKey={PerfMarks.WEB_LINKS_BEGIN_MARK_KEY} />
					{maybeWrapInSectionWrapper(<WebLinksConnectedView />)}

					<PerformanceMark metricKey={PerfMarks.WEB_LINKS_END_MARK_KEY} />

					<PerformanceMark metricKey={PerfMarks.ADDITIONAL_WEB_LINKS_BEGIN_MARK_KEY} />
					{renderAdditionalWebLinksSections()}
					<PerformanceMark metricKey={PerfMarks.ADDITIONAL_WEB_LINKS_END_MARK_KEY} />

					<PerformanceMark metricKey={PerfMarks.ECOSYSTEM_CONTENT_BEGIN_MARK_KEY} />
					{maybeWrapInSectionWrapper(renderEcosystemContentPanels())}

					<PerformanceMark metricKey={PerfMarks.ECOSYSTEM_CONTENT_END_MARK_KEY} />

					<PerformanceMark metricKey={PerfMarks.LINKED_ALERTS_BEGIN_MARK_KEY} />
					<UFOSegment name="issue-content-linked-alerts">
						{maybeWrapInSectionWrapper(<LinkedAlertsModals />)}
					</UFOSegment>
					<PerformanceMark metricKey={PerfMarks.LINKED_ALERTS_END_MARK_KEY} />

					<PerformanceMark metricKey={PerfMarks.PROFORMA_FORMS_BEGIN_MARK_KEY} />
					<UFOSegment name="issue-content-proforma-forms">
						{maybeWrapInSectionWrapper(<WrappedProformaFormsPanelView issueKey={issueKey} />)}
					</UFOSegment>
					<PerformanceMark metricKey={PerfMarks.PROFORMA_FORMS_END_MARK_KEY} />
				</DevOpsAppRecommendationContextProvider>
			</ContentItemsWrapper>
		</UFOSegment>
	);
};

ContentItems.displayName = 'ContentItems';

const ReduxConnectedView = connect(
	(state: State) => ({
		issueKey: issueKeySelector(state),
		contentPanels: ecosystemAddedContentPanelsSelector(state),
		isComplete: isCompletedLoadingSelector(state),
		// potentially this is always coming back false and we're rendering child issues another way. we can check via UFOSegment issue-epics-issues once it starts tracking
		isEpic: isEpicSelector(state),
		isAnonymousUser: isAnonymousSelector(state),
		hasIssueLinksEntity: isIssueLinksEnabledSelector(state),
		jpdDeliveryIssueLinkTypeId: jpdDeliverLinkTypeIdSelector(state),
		shouldShowLinkIdeaInput: showLinkIdeasInputSelector(state),
		shouldRenderChildrenIssues: shouldRenderChildPanelSelector(state),
		shouldRenderSubtasks: shouldRenderSubtaskPanelSelector(state),
		shouldRenderPortfolioChildrenIssues: shouldRenderPortfolioChildPanelSelector(state),
		additionalWebLinksSections: additionalWebLinksSectionsSelector(state),
		isServiceDesk: isServiceDeskSelector(state),
		projectType: projectTypeSelector(state),
		context: contextSelector(state),
	}),
	{},
)(withGICHook(ContentItems));

export const ContentItemsView = componentWithFG(
	'content_items_view_functional_component',
	ReduxConnectedView,
	DeprecatedReduxConnectedView,
);
