import { fg } from '@atlaskit/platform-feature-flags';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import type { EditorPluginAIProvider, ProactiveAIConfig } from '../../types';

export const getConfiguration = (
	editorPluginAIProvider: EditorPluginAIProvider,
): {
	isProactiveAISupported: boolean;
	proactiveAIConfig: ProactiveAIConfig;
} => {
	// NOTE: We need to ensure the feature gate is evaluated last. This is beacuse if the product hasn't enabled
	// the feature then we don't want to hit the gate check since it's not possible for that product to have
	// the feature.
	let isProactiveAISupported =
		!!editorPluginAIProvider.isFullPageExperimentsEnabled &&
		!!editorPluginAIProvider.proactiveAIConfig?.apiUrl &&
		!!editorPluginAIProvider.proactiveAIConfig?.enabled &&
		!!fg('platform_editor_ai_proactive_spelling_and_grammar');

	let proactiveAIConfig = editorPluginAIProvider.proactiveAIConfig;

	// We should only eval the experiment if the previous feature gate passed
	if (isProactiveAISupported) {
		const isDefaultOnCohort = editorExperiment(
			'editor_ai_-_proactive_ai_spelling_and_grammar',
			'default-on',
			{ exposure: true },
		);
		const isDefaultOffCohort = editorExperiment(
			'editor_ai_-_proactive_ai_spelling_and_grammar',
			'default-off',
		);

		const isEnabled = isDefaultOnCohort || isDefaultOffCohort;

		if (!isEnabled) {
			isProactiveAISupported = false;
		} else {
			proactiveAIConfig = {
				...proactiveAIConfig,
				defaultToggledState: isDefaultOnCohort,
				documentSGChecker: proactiveAIConfig?.documentSGChecker
					? {
							...proactiveAIConfig?.documentSGChecker,
							enabled: isDefaultOffCohort,
						}
					: undefined,
			};
		}
	}

	return {
		isProactiveAISupported,
		proactiveAIConfig,
	};
};
