import React, { useRef } from 'react';
import { Text } from '@atlaskit/primitives';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import messages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import {
	MultilineFieldHeading,
	MultilineFieldHeadingTitle,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import {
	ScrollableArea,
	TextContentArea,
} from '@atlassian/jira-issue-view-common-styles/src/issue-value.tsx';
import {
	CONTENT,
	type Area,
	CONTENT_REQUEST,
} from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import InlineEditStateless from '@atlassian/jira-issue-view-common/src/component/inline-edit/index.tsx';
import {
	SectionHeading,
	SectionHeadingTitle,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import { removeTrailingNewLineCharacters } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	EmptyValueWrapper,
	InlineEditContainer,
	ScrollableAreaWrapper,
	TextContentAreaWrapper,
	TextareaWrapper,
} from './styled.tsx';
import TextAreaField, { MAX_ROWS_CONTEXT } from './text-area-field-view.tsx';

type Props = {
	isConfirmOnBlurDisabled?: boolean;
	isEditable?: boolean;
	isEditing?: boolean;
	issueKey?: IssueKey; // TODO make mandatory when cleaning up isFieldDescriptionEnabled,
	value?: string;
	label?: string;
	noValueText?: string | null;
	invalidMessage?: string | null;
	fieldId: string;
	area: Area;
	onEditRequest: () => void;
	onCancel: () => void;
	onChange: (arg1: string) => void;
	onConfirm: () => void;
};

export const TextAreaInlineEditView = ({
	isEditable = false,
	isEditing = false,
	isConfirmOnBlurDisabled = false,
	value = '',
	label = '',
	noValueText = null,
	invalidMessage = null,
	fieldId,
	area,
	onEditRequest,
	onCancel,
	onChange,
	onConfirm,
	issueKey,
}: Props) => {
	const { formatMessage } = useIntl();

	const handleOnConfirm = () => {
		if (value !== null) {
			onChange(removeTrailingNewLineCharacters(value));
		}
		onConfirm();
	};

	const readView = useRef<unknown>();

	const renderReadView = () => {
		if (value && value.trim().length !== 0) {
			if (area === CONTENT || area === CONTENT_REQUEST) {
				return (
					<TextContentAreaWrapper>
						<TextContentArea
							data-testid="issue-internal-fields.text-area.text-content-area"
							// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'
							ref={readView}
						>
							{value && removeTrailingNewLineCharacters(value)}
						</TextContentArea>
					</TextContentAreaWrapper>
				);
			}
			return (
				<ScrollableAreaWrapper isEditable={isEditable}>
					{/* @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type '((instance: any) => void) | RefObject<HTMLElement | SVGElement | Component<{}, {}, any>> | undefined'. */}
					<ScrollableArea ref={readView} maxRows={MAX_ROWS_CONTEXT} tabIndex={0}>
						{value}
					</ScrollableArea>
				</ScrollableAreaWrapper>
			);
		}
		return (
			<EmptyValueWrapper isEditable={isEditable}>
				<Text color="color.text.subtlest">{noValueText}</Text>
			</EmptyValueWrapper>
		);
	};

	const renderEditView = () => {
		let scrollRows;
		if (readView.current) {
			// @ts-expect-error - TS2339 - Property 'scrollHeight' does not exist on type 'unknown'.
			const { scrollHeight } = readView.current;
			// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Element'.

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const style = window.getComputedStyle(readView.current);
			const lineHeight = Number.parseFloat(style.getPropertyValue('line-height'));
			scrollRows = Math.round(scrollHeight / lineHeight);
		}
		return (
			<TextAreaField
				value={value}
				onChange={onChange}
				invalidMessage={invalidMessage}
				scrollRows={scrollRows}
				area={area}
			/>
		);
	};

	const getField = () => (
		<EnterEscapeHandler onEscape={onCancel}>
			<InlineEditContainer
				isEditable={isEditable}
				isEditing={isEditing}
				// @ts-expect-error - TS2322 - Type '{ children: Element; isEditable: boolean; isEditing: boolean; isContentArea: boolean; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { ...; }, any>, any, any>> & Readonly<...> & Readonly<...>'.
				isContentArea={area === CONTENT}
			>
				<InlineEditStateless
					isLabelHidden
					label={label}
					isEditable={isEditable}
					readView={renderReadView()}
					editView={isEditable ? renderEditView() : null}
					disableEditViewFieldBase
					isEditing={isEditing}
					onConfirm={handleOnConfirm}
					onCancel={onCancel}
					onEditRequested={onEditRequest}
					isFitContainerWidthReadView
					isConfirmOnBlurDisabled={isConfirmOnBlurDisabled}
					editButtonLabel={formatMessage(messages.editButtonLabel, {
						fieldName: label,
					})}
					confirmButtonLabel={formatMessage(messages.confirmButtonLabel, {
						fieldName: label,
					})}
					cancelButtonLabel={formatMessage(messages.cancelButtonLabel, {
						fieldName: label,
					})}
				/>
			</InlineEditContainer>
		</EnterEscapeHandler>
	);
	const showPinButton = getShowPinButton(area);

	return (
		<TextareaWrapper>
			{area === CONTENT ? (
				<SectionHeading leftAlign>
					<SectionHeadingTitle>{label}</SectionHeadingTitle>
					{issueKey !== undefined && fieldId !== undefined && (
						// @ts-expect-error label is required, please pass it through when working on this code.
						<FieldDescription issueKey={issueKey} fieldKey={fieldId} />
					)}
				</SectionHeading>
			) : (
				<MultilineFieldHeading>
					<MultilineFieldHeadingTitle>{label}</MultilineFieldHeadingTitle>
					{issueKey !== undefined && fieldId !== undefined && (
						// @ts-expect-error label is required, please pass it through when working on this code.
						<FieldDescription issueKey={issueKey} fieldKey={fieldId} />
					)}
					{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
				</MultilineFieldHeading>
			)}
			{getField()}
		</TextareaWrapper>
	);
};

export default TextAreaInlineEditView;
