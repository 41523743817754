import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { ISSUE_HEADER_SHADOW_HEIGHT } from '@atlassian/jira-issue-view-sticky-header-container/src/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderContainer = styled.div({
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface', colors.N0),
	outline: 0,
	minHeight: '40px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderContentContainer = styled.div<{ maxWidth: string | number | undefined }>({
	display: 'flex' /* needed for 3rd party glance panels, e.g. "Who's looking" */,
	alignItems: 'center',
	position: 'relative',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: `inset 0 ${-ISSUE_HEADER_SHADOW_HEIGHT}px 0 0 ${token('color.border', colors.N30)}`,
	marginLeft: token('space.negative.100', '-8px'),
	paddingLeft: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'none'),
	'&:hover': {
		cursor: 'pointer',
	},
	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		boxShadow: `inset 0 0 0 ${ISSUE_HEADER_SHADOW_HEIGHT}px ${token(
			'color.border.focused',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			colors.B100,
		)}`,
		outline: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PanelContentContainerInner = styled.div<{ maxWidth: string | number | undefined }>({
	height: '100%',
	/* display: block; will not prevent Collapsing margins between parent and child elements. Which will cause scrollbar to be shown for small windows (if this element's child has margin top or bottom set) */
	display: 'inline-block',
	width: '100%',
	/* using "width:" will show scrollbar on expanding in glance panel */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'none'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RoundButton = styled.button({
	display: 'flex',
	height: '28px',
	width: '28px',
	border: 'none',
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('color.background.neutral', colors.N30),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N60),
	alignItems: 'center',
	paddingLeft: token('space.025', '2px'),
	marginLeft: token('space.050', '4px'),
	'&:hover': {
		cursor: 'pointer',
	},
});
