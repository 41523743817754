import React, {
	type ReactNode,
	useState,
	useCallback,
	type SyntheticEvent,
	type ReactElement,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { SimpleTag as Tag, type AppearanceType, type TagColor } from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import Tooltip from '@atlaskit/tooltip';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import checkForHref from '@atlassian/jira-common-components-html-view/src/check-for-href.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import WithProfileCard from '@atlassian/jira-issue-view-internal-profilecard-next/src/index.tsx';
import { Link } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

type ItemBase = {
	elemBefore?: ReactNode;
	color?: TagColor;
	href?: string;
};

type ItemWithContent = ItemBase & {
	content: string;
	value: string;
};

type ItemWithLabel = ItemBase & {
	label: string;
	key: string;
};

export type Item = ItemWithContent | ItemWithLabel;

type Props = {
	isUserTag?: boolean;
	renderTagItem?: (item: Item) => ReactElement;
	renderTagTooltipContent?: (item: Item) => ReactElement;
	shouldHideLinks?: boolean;
	value?: Item[] | null;
	noValueText?: string | null;
	tagAppearance?: string;
	truncateValues?: boolean;
	onClick?: () => void;
};

type TagItemProps = {
	isUserTag?: boolean;
	shouldHideLinks?: boolean;
	tagAppearance?: AppearanceType;
	item: Item;
};

type ShowHideProps = {
	isShowingMore: boolean;
	// eslint-disable-next-line jira/react/handler-naming
	handleShowHideMore: (event: SyntheticEvent<HTMLElement>) => void;
	// eslint-disable-next-line jira/react/handler-naming
	handleTagMouseDown: (event: SyntheticEvent<HTMLElement>) => void;
	tagAppearance: AppearanceType;
	value: Item[] | null;
};

export const SHOW_MORE_THRESHOLD = 8;

const ShowHide = ({
	handleShowHideMore,
	handleTagMouseDown,
	tagAppearance,
	isShowingMore,
	value,
}: ShowHideProps) => {
	const { formatMessage } = useIntl();

	return (
		<div
			onClick={handleShowHideMore}
			role="presentation"
			onMouseDown={handleTagMouseDown}
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue-view.common.tag.tag-view.show-hide-more"
		>
			<Tag
				appearance={tagAppearance}
				text={
					isShowingMore
						? formatMessage(messages.showLess)
						: formatMessage(messages.showAll, {
								peopleCount: value?.length,
							})
				}
				href="#"
			/>
		</div>
	);
};

const TagItem = ({
	isUserTag = false,
	shouldHideLinks = false,
	tagAppearance = 'default',
	item,
}: TagItemProps) => {
	const CustomTag = (
		<Tag
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			testId="issue.views.common.tag.tag-item"
			appearance={tagAppearance}
			// @ts-expect-error - TS2339 - Property 'content' does not exist on type 'Item'. | TS2339 - Property 'content' does not exist on type 'Item'. | TS2339 - Property 'label' does not exist on type 'Item'.
			text={item.content !== undefined ? item.content : item.label}
			color={item.color}
			href={shouldHideLinks ? undefined : item.href}
			linkComponent={Link}
			elemBefore={item.elemBefore}
		/>
	);

	// User Tags
	if (isUserTag) {
		return (
			<WithProfileCard
				// @ts-expect-error - TS2322 - Type 'Item' is not assignable to type 'ExternalUser'.
				userAccount={item}
				analyticsData={{ trigger: 'user-tag' }}
				position="left-start"
			>
				{CustomTag}
			</WithProfileCard>
		);
	}

	return CustomTag;
};

export const TagView = ({
	isUserTag = false,
	renderTagItem,
	renderTagTooltipContent,
	shouldHideLinks = false,
	value = null,
	noValueText = null,
	tagAppearance = 'default',
	truncateValues = false,
	onClick,
}: Props) => {
	const [isShowingMore, setIsShowingMore] = useState(false);

	const handleTagSelected = useCallback(
		(e: SyntheticEvent<HTMLElement>) => {
			// @ts-expect-error - TS2559 - Type 'EventTarget' has no properties in common with type 'ElementToCheck'.
			if (checkForHref(e.target, e.currentTarget)) {
				e.stopPropagation();
				if (onClick) {
					onClick();
				}
			}
		},
		[onClick],
	);

	const handleTagMouseDown = useCallback((e: SyntheticEvent<HTMLElement>) => {
		e.preventDefault();
		// @ts-expect-error - TS2559 - Type 'EventTarget' has no properties in common with type 'ElementToCheck'.
		if (checkForHref(e.target, e.currentTarget)) {
			e.stopPropagation();
		}
	}, []);

	const handleShowHideMore = useCallback(
		(e: SyntheticEvent<HTMLElement>) => {
			e.preventDefault();
			e.stopPropagation();
			setIsShowingMore(!isShowingMore);
		},
		[isShowingMore],
	);

	if (value && !isEmpty(value)) {
		const newValue =
			truncateValues && !isShowingMore && value.length > SHOW_MORE_THRESHOLD
				? value.slice(0, SHOW_MORE_THRESHOLD)
				: value;
		return (
			<Box xcss={tagWrapperStyles}>
				<TagGroup>
					{newValue.map((item) => {
						const tooltipContent =
							// eslint-disable-next-line no-nested-ternary
							ff('compass-components-in-jira-components_xw42u') &&
							typeof renderTagTooltipContent === 'function'
								? renderTagTooltipContent(item)
								: // @ts-expect-error - TS2339 - Property 'content' does not exist on type 'Item'. | TS2339 - Property 'content' does not exist on type 'Item'.
									item.content !== undefined && item.content.length > 20
									? // @ts-expect-error - TS2339 - Property 'content' does not exist on type 'Item'.
										item.content
									: '';

						return (
							<Tooltip
								content={tooltipContent}
								// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'Item'. | TS2339 - Property 'key' does not exist on type 'Item'. | TS2339 - Property 'value' does not exist on type 'Item'.
								key={item.key != null ? item.key : item.value}
							>
								<div
									onClick={handleTagSelected}
									onMouseDown={handleTagMouseDown}
									onKeyPress={handleTagSelected}
									// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'Item'. | TS2339 - Property 'key' does not exist on type 'Item'. | TS2339 - Property 'value' does not exist on type 'Item'.
									key={item.key != null ? item.key : item.value}
									role="presentation"
								>
									{ff('compass-components-in-jira-components_xw42u', false) &&
									typeof renderTagItem === 'function' ? (
										renderTagItem(item)
									) : (
										<TagItem
											isUserTag={isUserTag}
											shouldHideLinks={shouldHideLinks}
											tagAppearance={tagAppearance === 'default' ? 'default' : 'rounded'}
											item={item}
										/>
									)}
								</div>
							</Tooltip>
						);
					})}
					{truncateValues && value.length > SHOW_MORE_THRESHOLD && (
						<ShowHide
							handleShowHideMore={handleShowHideMore}
							handleTagMouseDown={handleTagMouseDown}
							isShowingMore={isShowingMore}
							tagAppearance={tagAppearance === 'default' ? 'default' : 'rounded'}
							value={value}
						/>
					)}
				</TagGroup>
			</Box>
		);
	}

	return <Text color="color.text.subtlest">{noValueText}</Text>;
};

const TagViewWithAnalytics = ComponentWithAnalytics('tagView', {
	onClick: 'clicked',
})(TagView);

export default TagViewWithAnalytics;

const tagWrapperStyles = xcss({
	margin: 'space.negative.050',
	width: '100%',
});
