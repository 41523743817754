import React from 'react';

import type { IntlShape, MessageDescriptor } from 'react-intl-next';

import ChangeToneIcon from '@atlaskit/icon/core/microphone';
import { fg } from '@atlaskit/platform-feature-flags';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { token } from '@atlaskit/tokens';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../../config-items/slice-or-node-to-markdown';
import { streamConvoAI } from '../../../provider/prompt-requests/convo-ai';
import {
	type ChangeToneBusinessCasualIntentSchema,
	type ChangeToneEducationalIntentSchema,
	type ChangeToneEmpatheticIntentSchema,
	type ChangeToneNeutralIntentSchema,
	type ChangeToneProfessionalIntentSchema,
} from '../../../provider/prompt-requests/types';
import { streamXPGenAI } from '../../../provider/prompt-requests/xp-gen-ai';
import { Icon as CasualIcon } from '../../assets/icons/toneCasual';
import { Icon as EducationalIcon } from '../../assets/icons/toneEducational';
import { Icon as EmpatheticIcon } from '../../assets/icons/toneEmpathetic';
import { Icon as NeutralIcon } from '../../assets/icons/toneNeutral';
import { Icon as ProfessionalIcon } from '../../assets/icons/toneProfessional';
import type { IconShownAt } from '../../assets/icons/types';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';
import { getSlice } from '../utils';

import { messages } from './messages';

const SELECTION_TYPE = 'range';

function createChangeToneOption({
	key,
	title,
	description,
	intentSchemaId,
	icon,
	shortTitle,
}: {
	key: CONFIG_ITEM_KEYS;
	title: MessageDescriptor;
	shortTitle: MessageDescriptor;
	description: MessageDescriptor;
	intentSchemaId:
		| ChangeToneBusinessCasualIntentSchema
		| ChangeToneEducationalIntentSchema
		| ChangeToneEmpatheticIntentSchema
		| ChangeToneNeutralIntentSchema
		| ChangeToneProfessionalIntentSchema;
	icon?: ({
		formatMessage,
		shownAt,
	}: {
		formatMessage: IntlShape['formatMessage'];
		shownAt: IconShownAt;
	}) => JSX.Element;
}) {
	return createEditorPluginAIConfigItemMarkdown({
		key,
		title,
		description,
		selectionType: SELECTION_TYPE,
		nestingConfig: {
			parentTestId: 'change-tone-to',
			parentTitle: editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')
				? messages.nestingParentTitlePostGA
				: messages.nestingParentTitle,
			shortTitle,
			icon: () => (
				<ChangeToneIcon label="" spacing="spacious" color={token('color.icon', '#44546F')} />
			),
		},
		showInRefineDropdown: true,
		getInitialContext: ({ editorView, positions, intl, updateIdMap, mentionMap }) => {
			const { markdown, contentStatistics } = sliceOrNodeToMarkdown({
				slice: getSlice(editorView, positions),
				editorView,
				convertTo: 'markdown-plus',
				updateIdMap,
				selectionType: SELECTION_TYPE,
				mentionMap,
			});
			return {
				selection: markdown,
				userLocale: intl.locale,
				intentSchemaId,
				contentStatistics,
			};
		},
		triggerPromptRequest({ initialContext, editorSchema, analyticsContext, latestPromptResponse }) {
			if (fg('platform_editor_ai_assistance_service')) {
				return streamConvoAI({
					aiSessionId: analyticsContext?.aiSessionId,
					userLocale: initialContext.userLocale,
					intentSchemaId: intentSchemaId,
					editorSchema,
					fullDocument: initialContext.document,
					draftSelectedContentOverride: editorExperiment(
						'platform_editor_ai_refine_response_button',
						true,
					)
						? latestPromptResponse
						: undefined,
					currentSelection: initialContext.selection,
				});
			}

			return streamXPGenAI({
				userLocale: initialContext.userLocale,
				intentSchemaId,
				editorSchema,
				contextList: [
					{
						type: 'ADF_MARKDOWN_V1',
						entity: initialContext.selection,
						relationship: 'SELECTION',
					},
				],
			});
		},
		isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
		icon,
		actions: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
		getBackendModel: () => {
			if (fg('platform_editor_ai_assistance_service')) {
				return 'assistance-service';
			}

			return 'xp-gen-ai';
		},
	});
}

export const neutralTone = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_NEUTRAL,
	title: messages.neutralToneTitle,
	shortTitle: messages.neutralToneShortTitle,
	description: messages.neutralToneDescription,
	intentSchemaId: 'change_tone_neutral_intent_schema.json',
	icon: ({ shownAt }) => <NeutralIcon shownAt={shownAt} />,
});
export const professionalTone = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_PROFESSIONAL,
	title: messages.professionalToneTitle,
	shortTitle: messages.professionalToneShortTitle,
	description: messages.professionalToneDescription,
	intentSchemaId: 'change_tone_professional_intent_schema.json',
	icon: ({ shownAt }) => <ProfessionalIcon shownAt={shownAt} />,
});
export const casualTone = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_CASUAL,
	title: messages.casualToneTitle,
	shortTitle: messages.casualToneShortTitle,
	description: messages.casualToneDescription,
	intentSchemaId: 'change_tone_business_casual_intent_schema.json',
	icon: ({ shownAt }) => <CasualIcon shownAt={shownAt} />,
});
export const educationalTone = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_EDUCATIONAL,
	title: messages.educationalToneTitle,
	shortTitle: messages.educationalToneShortTitle,
	description: messages.educationalToneDescription,
	intentSchemaId: 'change_tone_educational_intent_schema.json',
	icon: ({ shownAt }) => <EducationalIcon shownAt={shownAt} />,
});
export const empatheticTone = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_EMPATHETIC,
	title: messages.empatheticToneTitle,
	shortTitle: messages.empatheticToneShortTitle,
	description: messages.empatheticToneDescription,
	intentSchemaId: 'change_tone_empathetic_intent_schema.json',
	icon: ({ shownAt }) => <EmpatheticIcon shownAt={shownAt} />,
});
