import React, { useCallback } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { useIntl } from '@atlassian/jira-intl';
import { TRIGGER_POINT_KEY_ISSUE_VIEW } from '@atlassian/jira-issue-archival-modal/src/constants.tsx';
import { useArchiveIssueModalActions } from '@atlassian/jira-issue-archival-modal/src/controllers/main.tsx';
import { useIssueKey, useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ArchiveIssueButtonItem = ({
	onClick,
	setDropdownItemRef,
	itemKey,
	isSelected = false,
	onIssueDeleteSuccess,
	onClose,
}: Props) => {
	const issueKey = useIssueKey();
	const issueId = useIssueId();
	const { formatMessage } = useIntl();

	const { openArchiveIssueModal } = useArchiveIssueModalActions();

	const handleClick = useCallback(() => {
		const onIssueArchiveSuccess = () => {
			if (issueKey && issueId) onIssueDeleteSuccess?.({ issueKey, issueId });
		};
		openArchiveIssueModal({
			issueKey,
			issueId,
			onIssueArchiveSuccess,
			onCloseIssueViewModal: onClose,
			triggerPointKey: TRIGGER_POINT_KEY_ISSUE_VIEW,
		});
		onClick?.(itemKey);
	}, [openArchiveIssueModal, issueKey, issueId, onClose, onClick, itemKey, onIssueDeleteSuccess]);

	return (
		<ButtonItem
			key="archive"
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
			onClick={handleClick}
		>
			{formatMessage(messages.archiveIssue)}
		</ButtonItem>
	);
};

export default ArchiveIssueButtonItem;
