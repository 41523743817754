import { fg } from '@atlassian/jira-feature-gating';
import { AUTOMATIC_ID } from '@atlassian/jira-issue-adjustments/src/common/constants.tsx';
import { FieldValueLookupError } from '@atlassian/jira-issue-adjustments/src/common/errors/field-value-lookup-error.tsx';
import type { PublicToInternalPropertyTransformerContext } from '@atlassian/jira-issue-adjustments/src/common/types/index.tsx';
import type {
	LookupUser,
	LookupUsers,
} from '@atlassian/jira-issue-adjustments/src/common/types/lookup.tsx';
import { baseScopesRequiredPerField } from '@atlassian/jira-issue-adjustments/src/common/utils/filter-based-on-scopes/fields-scopes.tsx';
import { getAutomaticUserOption } from '@atlassian/jira-issue-field-assignee/src/common/utils.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string/index.tsx';
import { singleValueLookup } from '../utils/single-value-lookup/index.tsx';

export type AssigneeInternalValue = null | {
	accountId: string;
	displayName: string;
	avatarUrls: {
		['48x48']?: string;
		[key: string]: string | undefined;
	};
};

export type AssigneePublicSetValue = null | string;

export type AssigneePublicGetValue = null | {
	accountId: string;
};

export const assigneeSetValueValidator = (value: unknown): value is AssigneePublicSetValue =>
	value === null || isNotEmptyString(value);

export const assigneeSetValueTransformer = (
	value: AssigneePublicSetValue,
	lookupValues: LookupUsers | undefined,
	context: PublicToInternalPropertyTransformerContext,
): AssigneeInternalValue => {
	if (value === null) {
		return null;
	}

	if (fg('getautomaticuseroption_refactoring_for_jsm')) {
		const { intl } = context;

		if (value === AUTOMATIC_ID && intl) {
			// @ts-expect-error deprecated intl shape in getAutomaticUserOption
			const automaticUserOption = getAutomaticUserOption(intl);

			return {
				accountId: automaticUserOption.accountId ?? AUTOMATIC_ID,
				displayName: automaticUserOption.displayName,
				avatarUrls: { '48x48': '' },
			};
		}
	}

	const foundValue = singleValueLookup<LookupUser>(value, lookupValues, 'accountId');

	if (!foundValue) {
		throw new FieldValueLookupError();
	}

	const { accountId, name: displayName, picture } = foundValue;

	return {
		accountId,
		displayName,
		avatarUrls: { '48x48': picture },
	};
};

// Sometimes the assignee value contains extra properties that we want to filter out
export const assigneeGetValueTransformer = (
	value: AssigneeInternalValue,
): AssigneePublicGetValue => {
	if (value === null) {
		return null;
	}
	const { accountId } = value;
	return { accountId };
};

export const assigneeScopes = {
	...baseScopesRequiredPerField,
	granular: [...baseScopesRequiredPerField.granular, 'read:user:jira'],
	coarse: [...baseScopesRequiredPerField.coarse, 'read:jira-user'],
};
