/**
 * @generated SignedSource<<918f530851da42838781dd5b410122bf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type DevOpsDesignStatus = "NONE" | "READY_FOR_DEVELOPMENT" | "UNKNOWN" | "%future added value";
export type DevOpsDesignType = "CANVAS" | "FILE" | "GROUP" | "NODE" | "OTHER" | "PROTOTYPE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type unlinkConfirmationModal_issueDesignSection_UnlinkDesignConfirmationModal$data = {
  readonly __id: string;
  readonly displayName: string;
  readonly id: string;
  readonly providerId: string;
  readonly status: DevOpsDesignStatus | null | undefined;
  readonly type: DevOpsDesignType | null | undefined;
  readonly " $fragmentType": "unlinkConfirmationModal_issueDesignSection_UnlinkDesignConfirmationModal";
};
export type unlinkConfirmationModal_issueDesignSection_UnlinkDesignConfirmationModal$key = {
  readonly " $data"?: unlinkConfirmationModal_issueDesignSection_UnlinkDesignConfirmationModal$data;
  readonly " $fragmentSpreads": FragmentRefs<"unlinkConfirmationModal_issueDesignSection_UnlinkDesignConfirmationModal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "unlinkConfirmationModal_issueDesignSection_UnlinkDesignConfirmationModal",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "providerId"
      },
      "action": "THROW",
      "path": "providerId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "displayName"
      },
      "action": "THROW",
      "path": "displayName"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "status"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "DevOpsDesign"
};

(node as any).hash = "98262dd3e4a3d359a9055a2264572886";

export default node;
