/**
 * @generated SignedSource<<fc78aff83af3b483bc1aca7d2e30e24c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$data = {
  readonly __typename: "JiraCheckboxesField";
  readonly description: string | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly fieldOptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isDisabled: boolean | null | undefined;
        readonly optionId: string;
        readonly value: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly selectedOptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly optionId: string;
        readonly value: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField";
};
export type ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$key = {
  readonly " $data"?: ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v2 = {
  "kind": "ScalarField",
  "name": "value"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    },
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "selectedOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v0/*: any*/),
                  "action": "NONE",
                  "path": "selectedOptions.edges.node.id"
                },
                {
                  "kind": "RequiredField",
                  "field": (v1/*: any*/),
                  "action": "NONE",
                  "path": "selectedOptions.edges.node.optionId"
                },
                {
                  "kind": "RequiredField",
                  "field": (v2/*: any*/),
                  "action": "NONE",
                  "path": "selectedOptions.edges.node.value"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "fieldOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v0/*: any*/),
                  "action": "NONE",
                  "path": "fieldOptions.edges.node.id"
                },
                {
                  "kind": "RequiredField",
                  "field": (v1/*: any*/),
                  "action": "NONE",
                  "path": "fieldOptions.edges.node.optionId"
                },
                {
                  "kind": "RequiredField",
                  "field": (v2/*: any*/),
                  "action": "NONE",
                  "path": "fieldOptions.edges.node.value"
                },
                {
                  "kind": "ScalarField",
                  "name": "isDisabled"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraCheckboxesField"
};
})();

(node as any).hash = "c292990871dd2305362a1c17e8a4e931";

export default node;
