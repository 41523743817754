import { fg } from '@atlassian/jira-feature-gating';
import { filterHasFieldProperty } from '@atlassian/jira-polaris-domain-view/src/filter/utils.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';

// After updating a view, we need to make sure that we remove temporary filter / sortBy for field X if we have a permanent filter / sortBy for field X
const removeOverwrittenTemporaryData = (oldView: View, newView: View): View['temporaryData'] => {
	const newTemporaryFilters = oldView.temporaryData?.filter?.filter(
		(temporaryFilter) =>
			!newView.filter.some(
				(permanentFilter) =>
					filterHasFieldProperty(permanentFilter) &&
					filterHasFieldProperty(temporaryFilter) &&
					permanentFilter.field === temporaryFilter.field,
			),
	);

	const newTemporarySortBy = oldView.temporaryData?.sortBy?.filter(
		(temporarySortBy) =>
			!newView.sortBy?.some(
				(permanentSortBy) => permanentSortBy.fieldKey === temporarySortBy.fieldKey,
			),
	);

	return {
		filter: newTemporaryFilters,
		sortBy: newTemporarySortBy,
	};
};

export const upsertView = (views: View[], newView: View): View[] => {
	let isExisting = false;
	const updatedViews = views.map((view) => {
		if (view.viewId === newView.viewId) {
			isExisting = true;
			return {
				...newView,
				id: view.id,
				fetchCommentsTimestamp: view.fetchCommentsTimestamp,
				selectedIssues: view.selectedIssues,
				collapsedSwimlanes: view.collapsedSwimlanes,
				issueRanking: view.issueRanking,
				lastViewed: view.lastViewed,
				comments: view.comments,
				isEditingTitle: view.isEditingTitle,
				commentsLoaded: view.commentsLoaded,
				temporaryData: fg('polaris_just-for-you')
					? removeOverwrittenTemporaryData(view, newView)
					: undefined,
				// real-time events don't contain the last viewed timestamp
				newView: newView.lastCommentsViewedTimestamp || view.lastCommentsViewedTimestamp,
			};
		}
		return view;
	});

	return isExisting ? updatedViews : [...updatedViews, newView];
};

export const isViewAriInViewSets = (viewAri: string, viewSets: ViewSet[]): boolean =>
	viewSets.some((viewSet) => {
		if (viewSet.views.some((view) => view.viewId === viewAri)) {
			return true;
		}

		return viewSet.viewSets ? isViewAriInViewSets(viewAri, viewSet.viewSets) : false;
	});
