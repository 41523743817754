import React from 'react';
import { AsyncConferenceCallField } from '@atlassian/jira-conference-call/src/async.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { canUseServiceDeskAgentFeaturesSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import type { Props } from './types.tsx';

export const ConferenceCallField = ({ isVisible = true, area, fieldId }: Props) => (
	<ErrorBoundary>
		{isVisible && (
			<AsyncConferenceCallField fieldId={fieldId} showPinButton={getShowPinButton(area)} />
		)}
	</ErrorBoundary>
);

export default connect(
	(state) => ({
		isVisible: canUseServiceDeskAgentFeaturesSelector(state),
	}),
	{},
)(ConferenceCallField);
