import { type Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import { type Transaction } from '@atlaskit/editor-prosemirror/state';

import { ExtensionKeys } from '../../actions/types';

import { type LocalIdsPanelTypes } from './types';

const isEditableAIPanel = (node: PMNode): boolean => {
	return (
		node.type?.name === 'bodiedExtension' &&
		[ExtensionKeys.AISummaryBodiedExtension, ExtensionKeys.AIActionItemsBodiedExtension].includes(
			node.attrs?.extensionKey,
		)
	);
};

const canHostAIPanel = (node: PMNode): boolean => {
	return node.type?.name === 'layoutSection' || node.type?.name === 'layoutColumn';
};

export const getNewlyEditedAIPanels = ({
	transactions,
	knownEditedPanelIds,
}: {
	transactions: readonly Transaction[];
	knownEditedPanelIds: LocalIdsPanelTypes;
}): LocalIdsPanelTypes => {
	const changed: PMNode[] = [];
	transactions.forEach((tr) => {
		if (tr.docChanged && !tr.getMeta?.('appendedTransaction')) {
			let olds: PMNode[] = [];
			let news: PMNode[] = [];

			tr.before.descendants((node) => {
				if (isEditableAIPanel(node)) {
					olds.push(node);
				} else if (canHostAIPanel(node)) {
					return true;
				}
				return false;
			});

			tr.doc.descendants((node) => {
				if (isEditableAIPanel(node)) {
					news.push(node);
				} else if (canHostAIPanel(node)) {
					return true;
				}
				return false;
			});
			if (olds.length !== news.length) {
				return;
			}
			olds.forEach((old, i) => {
				if (!old.content.eq(news[i].content)) {
					changed.push(news[i]);
				}
			});
		}
	});
	if (changed.length) {
		return changed.reduce<LocalIdsPanelTypes>((accum, node) => {
			if (!knownEditedPanelIds[node.attrs.localId]) {
				accum[node.attrs.localId] = {
					extensionKey: node.attrs.extensionKey,
					extensionType: 'com.atlassian.ai-blocks',
				};
			}
			return accum;
		}, {});
	} else {
		return {};
	}
};
