import React from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { ChildIssuesPanelSkeleton } from '@atlassian/jira-skeletons/src/ui/child-issues-panel/components/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type TConfigurableChildIssuesPanel from './index.tsx';
import type { ConfigurableChildIssuesPanelProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyConfigurableChildIssuesPanel = lazy<typeof TConfigurableChildIssuesPanel>(
	() => import(/* webpackChunkName: "async-configurable-child-issues-panel" */ './index'),
	{ ssr: false },
);

export const AsyncConfigurableChildIssuesPanel = (props: ConfigurableChildIssuesPanelProps) => (
	<JSErrorBoundary
		id="AsyncConfigurableChildIssuesPanel"
		packageName="jiraIssueViewCommonViews"
		teamName="bento"
		fallback={() => <></>}
	>
		<Placeholder name="ConfigurableChildIssuesPanel" fallback={<ChildIssuesPanelSkeleton />}>
			<LazyConfigurableChildIssuesPanel {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
