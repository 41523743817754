import React, { useCallback } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { UserValue } from '../../../common/types.tsx';
import { ASSIGN_TO_ME_ANALYTICS } from '../../../constants.tsx';
import messages from '../../../messages.tsx';
import useAssigneeField from '../../../services/use-assignee-field/index.tsx';

export type AssignToMeButtonProps = {
	issueKey: IssueKey;
	value?: UserValue;
	onUpdate?: (value: unknown) => void;
	onFailure?: (error: Error) => void;
};

export const AssignToMeButton = ({
	issueKey,
	value,
	onUpdate,
	onFailure,
}: AssignToMeButtonProps) => {
	const { formatMessage } = useIntl();
	const {
		data: { user },
	} = useCurrentUser();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { saveValue }] = useAssigneeField({
		issueKey,
		onSuccess: onUpdate,
		onFailure,
	});
	const projectKey = useProjectKey();
	const [{ canBeAssignedToIssues, canAssignIssues }] = useProjectPermissions(projectKey);

	const handleClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: ASSIGN_TO_ME_ANALYTICS.ACTION,
				actionSubject: ASSIGN_TO_ME_ANALYTICS.ACTION_SUBJECT,
			}),
		);
		const { isAdmin, isAnonymous, isSiteAdmin, siteAdminStatus, userFullname, ...transformedUser } =
			{
				...user,
				// @ts-expect-error - TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
				avatarUrls: user.avatarUrls ? user.avatarUrls : {},
				displayName:
					// @ts-expect-error - TS2339 - Property 'displayName' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'displayName' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
					user.displayName !== null && user.displayName !== undefined
						? // @ts-expect-error - TS2339 - Property 'displayName' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
							user.displayName
						: user.userFullname,
			};
		saveValue(transformedUser, null, createAnalyticsEvent({}));
	}, [createAnalyticsEvent, saveValue, user]);

	const escapeHandler = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document && document.activeElement) {
			// @ts-expect-error - TS2339 - Property 'blur' does not exist on type 'Element'.

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.activeElement.blur();
		}
	}, []);

	// Do not render the button if the issue is already assigned to the logged in user
	if (value?.accountId === user.accountId) return null;

	// Do not render the button if the logged in user cannot be assigned to issues
	if (!canBeAssignedToIssues) return null;

	// Do not render the button if the user does not have permissions to assign issues
	if (!canAssignIssues) return null;

	return (
		<EnterEscapeHandler onEnter={handleClick} onEscape={escapeHandler}>
			<LinkText tabIndex={0} onClick={handleClick} role="button">
				{formatMessage(messages.assignToMe)}
			</LinkText>
		</EnterEscapeHandler>
	);
};

export default AssignToMeButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkTextControl = styled.div({
	color: token('color.link', B400),
	font: token('font.body.UNSAFE_small'),
	cursor: 'pointer',
	marginTop: token('space.050', '4px'),
	marginLeft: token('space.100', '8px'),
	'&:hover': {
		textDecoration: 'underline',
	},
	'&:active': {
		color: token('color.link.pressed', B400),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const LinkTextExperiment = styled2.div({
	color: token('color.link', B400),
	font: token('font.body.UNSAFE_small'),
	cursor: 'pointer',
	marginTop: token('space.050', '4px'),
	marginLeft: token('space.100', '8px'),
	'&:hover': {
		textDecoration: 'underline',
	},
	'&:active': {
		color: token('color.link.pressed', B400),
	},
});

const LinkText = styledComponentWithCondition(
	() => ff('compiled-react-migration-issue-view_zilee'),
	LinkTextExperiment,
	LinkTextControl,
);
