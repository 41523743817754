import React from 'react';

import type { IntlShape, MessageDescriptor } from 'react-intl-next';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../../config-items/slice-or-node-to-markdown';
import { streamIssueReformatterConvoAI } from '../../../provider/prompt-requests/issue-reformatter-convo-ai';
import { Icon as PageIcon } from '../../assets/icons/issueReformatter';
import type { IconShownAt } from '../../assets/icons/types';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';
import { getSlice } from '../utils';

import { messages } from './messages';

type AgentInput = {
	issue_summary: string;
	issue_type_label?: string;
};

const SELECTION_TYPE = 'range';

export type GetAdditionalProps = () => Partial<{
	issue_summary?: string;
	issue_type_label?: string;
}>;

function createOption({
	key,
	title,
	description,
	icon,
	getAdditionalProps,
}: {
	key: CONFIG_ITEM_KEYS;
	title: MessageDescriptor;
	description: MessageDescriptor;
	icon?: ({
		formatMessage,
		shownAt,
	}: {
		formatMessage: IntlShape['formatMessage'];
		shownAt: IconShownAt;
	}) => JSX.Element;
	getAdditionalProps: GetAdditionalProps;
}) {
	return createEditorPluginAIConfigItemMarkdown({
		key,
		title,
		description,
		selectionType: SELECTION_TYPE,
		getInitialContext: ({ editorView, positions, intl, updateIdMap, mentionMap }) => {
			const selection = sliceOrNodeToMarkdown({
				slice: getSlice(editorView, positions),
				editorView,
				convertTo: 'markdown-plus',
				updateIdMap,
				selectionType: SELECTION_TYPE,
				mentionMap,
			});

			const document = sliceOrNodeToMarkdown({
				node: editorView.state.doc,
				editorView,
				convertTo: 'markdown-plus',
				updateIdMap,
				selectionType: SELECTION_TYPE,
				mentionMap,
			});

			return {
				selection: selection.markdown,
				document: document.markdown,
				userLocale: intl.locale,
				intentSchemaId: 'issue_reformatter_intent_schema.json',
				contentStatistics: selection.contentStatistics,
			};
		},
		triggerPromptRequest({ initialContext, editorSchema, analyticsContext }) {
			return streamIssueReformatterConvoAI({
				aiSessionId: analyticsContext?.aiSessionId,
				userLocale: initialContext.userLocale,
				editorSchema,
				currentSelection: initialContext.selection,
				fullDocument: initialContext.document,
				additionalPayload: {
					...getAdditionalProps?.(),
				} as AgentInput,
			});
		},
		isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
		icon,
		actions: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
		getBackendModel: () => 'assistance-service',
		statusLozengeType: 'beta',
	});
}

type Options = {
	getAdditionalProps: GetAdditionalProps;
};

export const reformatWithTemplateConfigItem = (options: Options) =>
	createOption({
		key: CONFIG_ITEM_KEYS.ADD_STRUCTURE,
		title: messages.improveDescription,
		description: messages.reformatWithTemplateDescription,
		icon: ({ shownAt }) => <PageIcon shownAt={shownAt} />,
		getAdditionalProps: options.getAdditionalProps,
	});
