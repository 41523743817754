/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { styled, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import { ConfluenceFeatureKeys } from '@atlassian/jira-confluence-integration-controls/src/constants.tsx';
import { EmbeddedConfluenceSidepanelContainer } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import { useIsCrossSellEnabled } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-is-cross-sell-enabled/index.tsx';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { JIRA_ISSUE_QUICK_ACTIONS } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import { EMBEDDED_CONFLUENCE_ISSUE_VIEW_CREATE_CONFLUENCE_CONTENT_CROSS_FLOW_KEY } from '@atlassian/jira-issue-create-confluence-content/src/services/experiments/index.tsx';
import { CreatePageMenuItem } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/create-page-menu-item/index.tsx';
import { CreateWhiteboardMenuItem } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/create-whiteboard-menu-item/index.tsx';
import { createConfluenceContentMenuEntrypoint } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/entrypoint.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { ISSUE_TYPE } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { IssueViewNudgeExperiment } from './issue-view-nudge-experiment/main.tsx';

type CreateConfluenceContentMenuProps = {
	appearance?: string;
	isQuickActionsListView?: boolean;
	quickActionsListViewContainer?: { current: HTMLDivElement | null };
	setQuickActionListItem?: (category: string, name: string, newValues: object) => void;
};

const EMBEDDED_CONFLUENCE_ISSUE_VIEW_CROSS_FLOW_PULSE_LIMIT = 1; // When a user is first allocated to the experiment

const ISSUE_TYPES_TO_EXCLUDE_FOR_CROSS_FLOW_EXP = ['Bug', 'Epic', 'Story'];

const ContentMenu = ({
	appearance,
	isQuickActionsListView,
	quickActionsListViewContainer,
	setQuickActionListItem,
}: CreateConfluenceContentMenuProps) => {
	const {
		entryPointActions: confluenceContentMenuActions,
		entryPointReferenceSubject: confluenceContentMenuSubject,
	} = useEntryPoint(createConfluenceContentMenuEntrypoint, {});

	const confluenceAccess = useUserHasConfluenceAccess(JIRA_ISSUE_QUICK_ACTIONS).hasAccess;
	const tenantHasConfluence = useTenantHasConfluence();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isCrossSellEnabled = useIsCrossSellEnabled(ConfluenceFeatureKeys.CREATE_MENU_TOP_ACTIONS);

	const [embedConfluenceConfig] = UNSAFE_noExposureExp(
		'platform_embed-conf_jira-issue_cross-join_exp',
	);
	const embedConfluenceCohort = embedConfluenceConfig.get('cohort', 'not-enrolled');

	const [confluenceContentCrossflowConfig, confluenceContentCrossflowFireExposureEvent] =
		UNSAFE_noExposureExp('jira_create_confluence_content_crossflow');

	const confluenceContentCrossflowCohort = confluenceContentCrossflowConfig.get(
		'cohort',
		'not-enrolled',
	);

	// Embedded Confluence cross-join experiment begins
	useEffect(() => {
		if (!tenantHasConfluence) {
			if (ff('platform.embedded-confluence.jira-issue.create-confluence-content-crossflow')) {
				confluenceContentCrossflowFireExposureEvent();
			}
		}
	}, [
		confluenceAccess,
		tenantHasConfluence,
		createAnalyticsEvent,
		confluenceContentCrossflowFireExposureEvent,
	]);

	useEffect(() => {
		const shouldRenderForCrossJoin =
			tenantHasConfluence &&
			ff('platform_embed-conf_jira-issue_create_cross-join') &&
			embedConfluenceCohort === 'experiment';
		const shouldRenderForCrossFlow =
			!tenantHasConfluence &&
			ff('platform.embedded-confluence.jira-issue.create-confluence-content-crossflow') &&
			confluenceContentCrossflowCohort === 'experiment';

		if (
			confluenceAccess ||
			((shouldRenderForCrossJoin || shouldRenderForCrossFlow) && isCrossSellEnabled)
		) {
			confluenceContentMenuActions.load();
		}
	}, [
		confluenceAccess,
		confluenceContentMenuActions,
		tenantHasConfluence,
		isCrossSellEnabled,
		embedConfluenceCohort,
		confluenceContentCrossflowCohort,
	]);

	const [open, setOpen] = useState(false);

	const componentInternals = (
		<JiraEntryPointContainer
			entryPointReferenceSubject={confluenceContentMenuSubject}
			id="confluenceContentMenuEntryPointContainer"
			packageName="@atlassian/jira-issue-create-confluence-content"
			teamName="confluence-better-together"
			runtimeProps={{
				children: (
					<>
						<CreatePageMenuItem
							isQuickActionsListView={
								(ff('issue_view_quick_actions_revamp_l8mbx') || fg('quick_actions_menu_ga')) &&
								isQuickActionsListView
							}
							{...(fg('quick_actions_menu_ga') ? { setQuickActionListItem } : {})}
						/>
						<CreateWhiteboardMenuItem
							isQuickActionsListView={
								(ff('issue_view_quick_actions_revamp_l8mbx') || fg('quick_actions_menu_ga')) &&
								isQuickActionsListView
							}
							{...(fg('quick_actions_menu_ga') ? { setQuickActionListItem } : {})}
						/>
					</>
				),
				// eslint-disable-next-line jira/ff/no-preconditioning
				isOpenByDefault: fg('quick_actions_menu_ga') && isQuickActionsListView ? true : open,
				isQuickActionsListView,
				quickActionsListViewContainer: !fg('quick_actions_menu_ga')
					? quickActionsListViewContainer
					: undefined,
				hasConfluenceAccess: confluenceAccess,
				appearance,
				embeddedConfluenceSource: JIRA_ISSUE_QUICK_ACTIONS,
			}}
		/>
	);

	const issueKey = useIssueKey();
	const [issueTypeId] = useFieldValue({ issueKey, fieldKey: ISSUE_TYPE });
	const issueType = issueTypeId?.name ?? '';

	if (
		(ff('issue_view_quick_actions_revamp_l8mbx') || fg('quick_actions_menu_ga')) &&
		isQuickActionsListView
	) {
		return componentInternals;
	}

	const shouldShowNudgeSpotlight =
		!tenantHasConfluence &&
		ff('platform.embedded-confluence.jira-issue.create-confluence-content-crossflow') &&
		confluenceContentCrossflowCohort === 'experiment' &&
		ISSUE_TYPES_TO_EXCLUDE_FOR_CROSS_FLOW_EXP.indexOf(issueType) === -1;

	/*
	   To determine which experiment to nudge for, the key indicator is whether or not the tenant has confluence
	   The criteria for being enrolled in the JSW_XFLOW_NUDGE_EXP is that a user has not returned to Confluence after 3
	   days of provisioning it. So it would come into picture when the tenant does have Confluence. However, for the
	   EMBEDDED_CONFLUENCE_ISSUE_VIEW_CREATE_CONFLUENCE_CONTENT_CROSS_FLOW_KEY experiment the user is only enrolled when
	   tenant doesn't have confluence. So we check for this condition first, and if it does not apply, then we default it
	   to the JSW_XFLOW_NUDGE_EXP experiment and pulse limit value.
	*/
	const experimentToShowNudgeFor =
		EMBEDDED_CONFLUENCE_ISSUE_VIEW_CREATE_CONFLUENCE_CONTENT_CROSS_FLOW_KEY;

	const nudgePulseLimit = !tenantHasConfluence
		? EMBEDDED_CONFLUENCE_ISSUE_VIEW_CROSS_FLOW_PULSE_LIMIT
		: -1;
	return (
		<ButtonWrapper>
			{shouldShowNudgeSpotlight ? (
				<IssueViewNudgeExperiment
					experimentKey={experimentToShowNudgeFor}
					onClick={() => setOpen(!open)}
					issueType={issueType}
					pulseLimit={nudgePulseLimit}
					embeddedConfluenceSource={!tenantHasConfluence ? JIRA_ISSUE_QUICK_ACTIONS : undefined}
				>
					{componentInternals}
				</IssueViewNudgeExperiment>
			) : (
				componentInternals
			)}
		</ButtonWrapper>
	);
};

export const CreateConfluenceContentMenu = (props: CreateConfluenceContentMenuProps) => {
	const [sidePanelEmbeddedConfluenceConfig] = UNSAFE_noExposureExp(
		'jira_issue_view_side_by_side_modeless_ep_exp',
	);
	const isSidePanelFFEnabled =
		sidePanelEmbeddedConfluenceConfig.get('cohort', 'not-enrolled') === 'experiment';

	return isSidePanelFFEnabled ? (
		<EmbeddedConfluenceSidepanelContainer>
			<ContentMenu {...props} />
		</EmbeddedConfluenceSidepanelContainer>
	) : (
		<ContentMenu {...props} />
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.span({
	marginRight: token('space.100', '8px'),
});
