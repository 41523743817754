/**
 * Feature flags convention: go/frontend-feature-flags. Please ensure integration tests are still working afterwards.
 */

import {
	NOT_ENROLLED,
	CONTROL,
	VARIATION,
} from '@atlassian/jira-common-util-get-experiment-group/src/index.tsx';
import { getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';

export const INVITE_FROM_ASSIGN = 'invite.from.assign';
export const getInviteFromAssignCohort = (): string =>
	getMultivariateFeatureFlag(INVITE_FROM_ASSIGN, NOT_ENROLLED, [NOT_ENROLLED, CONTROL, VARIATION]);
