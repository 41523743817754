import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Flex, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const CreateStatusButton = () => {
	const { formatMessage } = useIntl();

	// TODO: send analytics and open modal
	const handleClick = () => null;

	return (
		<Flex xcss={flexContainerStyles}>
			<StyledButton
				appearance="subtle"
				shouldFitContainer
				interactionName="issue.fields.status.create-status.popup.open"
				onClick={handleClick}
			>
				{formatMessage(messages.createStatus)}
			</StyledButton>
		</Flex>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const StyledButton = styled(Button)({
	alignItems: 'center',
	textAlign: 'left',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
	color: token('color.text', colors.N900),
	padding: `10px ${token('space.150', '12px')}`,
	height: '100%',
});

const flexContainerStyles = xcss({
	flexGrow: 1,
	height: token('space.500'),
});
