import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { checkAssistanceServiceFreeGenerateFg } from '@atlassian/editor-ai-common/utils/check-assistance-service-fg';
import sharedMessages from '@atlassian/generative-ai-modal/utils/messages';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../../config-items/slice-or-node-to-markdown';
import { streamConvoAI } from '../../../provider/prompt-requests/convo-ai';
import type { ContextList } from '../../../provider/prompt-requests/types';
import { streamXPGenAI } from '../../../provider/prompt-requests/xp-gen-ai';
import { createInsertAtCurrentPosition } from '../../config-item-actions/markdown';

import { default as Icon } from './Icon';
import { messages } from './messages';

const SELECTION_TYPE = 'empty';

export const generateContentConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.FREE_GENERATE,
	title: sharedMessages.aiButtonTitle,
	description: sharedMessages.aiUniversalLongCallToAction,
	promptHint: messages.promptHint,
	selectionType: SELECTION_TYPE,
	getInitialContext: ({ editorView, intl, updateIdMap, mentionMap }) => {
		const { markdown, contentStatistics } = sliceOrNodeToMarkdown({
			node: editorView.state.doc,
			editorView,
			convertTo: 'markdown-plus',
			updateIdMap,
			selectionType: SELECTION_TYPE,
			mentionMap,
		});
		return {
			document: markdown,
			userLocale: intl.locale,
			intentSchemaId: 'custom_free_generate_intent_schema.json',
			contentStatistics,
		};
	},
	icon: ({ formatMessage }) => <Icon alt={formatMessage(sharedMessages.aiButtonTitle)} />,
	// No prompt label is required -- as this is intended to be set via the
	// the baseGenerate
	triggerPromptRequest({
		initialContext,
		promptInput,
		latestPromptResponse,
		editorSchema,
		analyticsContext,
	}) {
		// This config item is overloaded to support both the interrogation prompt and the free generate prompt
		// we distinguish which one we're in by checking if we have a latestPromptResponse
		let contextList: ContextList;

		if (latestPromptResponse) {
			// If we have a latestPromptResponse, we don't want to add the document to the contextList
			// as it's an interrogation prompt
			contextList = [
				{
					type: 'ADF_MARKDOWN_V1',
					entity: latestPromptResponse,
					relationship: 'PREVIOUS_RESPONSE',
				},
			];
		} else {
			contextList = !initialContext.document
				? []
				: [
						{
							type: 'ADF_MARKDOWN_V1',
							entity: initialContext.document,
							relationship: 'FULL_CONTENT',
						},
					];
		}

		// Use Convo AI api for free generate if flag is on and it's not interrogate
		// Have kept this separate to interrogate in case they need to be toggled separately
		// for whatever reason (eg. generate works well but interrogate does not)
		if (!latestPromptResponse && checkAssistanceServiceFreeGenerateFg()) {
			return streamConvoAI({
				aiSessionId: analyticsContext?.aiSessionId,
				customPrompt: promptInput!,
				userLocale: initialContext.userLocale,
				intentSchemaId: 'custom_free_generate_intent_schema.json',
				editorSchema,
				fullDocument: initialContext.document,
			});
		}

		// Use Convo AI api for interrogate if flag is on
		if (latestPromptResponse && fg('platform_editor_ai_interrogate_with_convo_ai')) {
			return streamConvoAI({
				aiSessionId: analyticsContext?.aiSessionId,
				customPrompt: promptInput!,
				userLocale: initialContext.userLocale,
				intentSchemaId: 'custom_with_interrogation_intent_schema.json',
				editorSchema,
				draftSelectedContentOverride: latestPromptResponse,
				fullDocument: initialContext.document,
			});
		}

		// Else use xp-gen-ai api
		return streamXPGenAI({
			customPrompt: promptInput!,
			userLocale: initialContext.userLocale,
			intentSchemaId: latestPromptResponse
				? 'custom_with_interrogation_intent_schema.json'
				: 'custom_free_generate_intent_schema.json',
			editorSchema,
			contextList,
		});
	},
	isVisible: () => true,
	actions: [createInsertAtCurrentPosition({ appearance: 'primary' })],
	quickInsertPriority: Number.NEGATIVE_INFINITY,
	getBackendModel: (isInterrogate) => {
		if (isInterrogate && fg('platform_editor_ai_interrogate_with_convo_ai')) {
			return 'assistance-service';
		}

		if (!isInterrogate && checkAssistanceServiceFreeGenerateFg()) {
			return 'assistance-service';
		}

		// If we're not interrogating, we use xp-gen-ai
		return 'xp-gen-ai';
	},
});
