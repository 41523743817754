import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const QuickAddWrapper = styled.div({
	marginBottom: token('space.050', '4px'),
	marginTop: token('space.050', '4px'),
	padding: `${token('space.050', '4px')} 0`,
	display: 'inline-flex',
});
