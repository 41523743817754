import React, { memo, useEffect, useState } from 'react';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { convertWikiToAdfAsync } from '@atlassian/jira-platform-convert-wiki-to-adf/src/async.tsx';
import type { FieldDescriptionProps } from '../types.tsx';
import InfoIcon from './assets/info-icon.tsx';
import PopupDescription, { FieldDescriptionWrapper } from './popup/index.tsx';

export const FieldDescription = memo<FieldDescriptionProps>(
	({ issueKey, fieldKey, label = '' }: FieldDescriptionProps) => {
		const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
		const [adfDescription, setAdfDescription] = useState<JSONDocNode | null>(null);
		const fieldDescription = fieldConfig?.description || null;

		useEffect(() => {
			fieldDescription &&
				convertWikiToAdfAsync(fieldDescription).then((convertedAdfValue) => {
					setAdfDescription(convertedAdfValue);
				});
		}, [fieldDescription]);

		if (!adfDescription) {
			return null;
		}

		return (
			<FieldDescriptionWrapper>
				<PopupDescription adfDescription={adfDescription} label={label}>
					<InfoIcon />
				</PopupDescription>
			</FieldDescriptionWrapper>
		);
	},
);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	FieldDescriptionWrapper,
	FieldDescriptionWrapperControl,
	FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR,
} from './popup';

export default FieldDescription;
