import { createSelector } from 'reselect';
import {
	CHILDREN_ISSUES_PANEL,
	CLASSIC_SUBTASKS,
	CLASSIC_PROJECT_EPIC_CHILDREN,
	type ChildIssuesPanelType,
} from '../../model/types.tsx';
import { contextSelector } from '../selectors.tsx';
import { getSelectedIssueType, isFetchIssueTypesComplete } from '../ui/selectors.tsx';

export const getBaseUrl = createSelector(contextSelector, (contextState) => contextState.baseUrl);

export const getLocale = createSelector(contextSelector, (contextState) => contextState.locale);

export const getChildIssuesPanelType = createSelector(
	contextSelector,
	(contextState) => contextState.childIssuesPanelType,
);

export const getIsClassicSubtaskPanel = createSelector(
	getChildIssuesPanelType,
	(childIssuesPanelType) =>
		childIssuesPanelType ? childIssuesPanelType === CLASSIC_SUBTASKS : false,
);

export const getIsClassicProjectEpicPanel = createSelector(
	getChildIssuesPanelType,
	(childIssuesPanelType) =>
		childIssuesPanelType ? childIssuesPanelType === CLASSIC_PROJECT_EPIC_CHILDREN : false,
);

export const getIsChildrenIssuesPanel = createSelector(
	getChildIssuesPanelType,
	(childIssuesPanelType) =>
		childIssuesPanelType ? childIssuesPanelType === CHILDREN_ISSUES_PANEL : false,
);

export const getSupportsIssueCreation = createSelector(
	contextSelector,
	(contextState) => contextState.supportsIssueCreation,
);

export const isAnonymousUser = createSelector(
	contextSelector,
	(contextState) => contextState.user === null || contextState.user === '',
);

export const getRequiresFullCreateByIssueType = createSelector(
	contextSelector,
	getSelectedIssueType,
	isFetchIssueTypesComplete,
	isAnonymousUser,
	(context, selectedIssueType, fetchIssueTypesComplete, anonymousUser) => {
		const requiresFullCreateByIssueType =
			selectedIssueType?.id && context.requiresFullCreateByIssueType[selectedIssueType.id];

		return anonymousUser || requiresFullCreateByIssueType || !fetchIssueTypesComplete;
	},
);

export const getIssueTypeWFValidators = createSelector(
	contextSelector,
	isFetchIssueTypesComplete,
	(context, fetchIssueTypesComplete) =>
		fetchIssueTypesComplete ? context.requiresFullCreateByIssueType : undefined,
);

export const getParentKey = createSelector(
	contextSelector,
	(contextState) => contextState.parentKey,
);

export const getOnEditAssociatedIssue = createSelector(
	contextSelector,
	(contextState) => contextState.onEditAssociatedIssue,
);

export const getParentId = createSelector(contextSelector, (contextState) => contextState.parentId);

export const getParentIssueTypeId = createSelector(
	contextSelector,
	(contextState) => contextState.parentIssueTypeId,
);

export const getFilterSubtasks = createSelector(
	contextSelector,
	(contextState) => contextState.filterSubtasks,
);

export const getCustomFieldIdRank = createSelector(
	contextSelector,
	(contextState) => contextState.customFieldIdRank,
);

export const getEpicLinkFieldKey = createSelector(
	contextSelector,
	(contextState) => contextState.epicLinkFieldKey,
);

export const getProjectId = createSelector(
	contextSelector,
	(contextState) => contextState.projectId,
);

const getMessages = createSelector(contextSelector, (contextState) => contextState.messages || {});

// @ts-expect-error - TS2339 - Property 'title' does not exist on type '{}'.
export const getTitle = createSelector(getMessages, (messages) => messages.title);

// @ts-expect-error - TS2339 - Property 'titleChildIssues' does not exist on type '{}'.
export const getChildTitle = createSelector(getMessages, (messages) => messages.titleChildIssues);

export const getNoValueText = createSelector(
	getMessages,
	// @ts-expect-error - TS2339 - Property 'noValueText' does not exist on type '{}'.
	(messages) => messages.noValueText || null,
);

export const getOnNavigateToNewIssue = createSelector(
	contextSelector,
	(contextState) => contextState.onNavigateToNewIssue,
);

export const getOnChangeCallback = createSelector(
	contextSelector,
	(contextState) => contextState.onChange,
);

export const getIsIssueViewComplete = createSelector(
	contextSelector,
	(contextState) => contextState.isIssueViewComplete,
);

export const getChildIssuesPanelProjectType = createSelector(
	contextSelector,
	(contextState) => contextState.projectType,
);

export const getIsSimplifiedProject = createSelector(
	contextSelector,
	(contextState) => contextState.isSimplifiedProject,
);

export const getChildIssuesPanelApplication = createSelector(
	contextSelector,
	(contextState) => contextState.application,
);

export const getChildIssuesPanelEdition = createSelector(
	contextSelector,
	(contextState) => contextState.edition,
);

// extracted logic in use by selector below so we can use it from hooks as well
export const isWhitelistedPanelForSorting = (panelType: ChildIssuesPanelType | null) =>
	panelType === CHILDREN_ISSUES_PANEL || panelType === CLASSIC_PROJECT_EPIC_CHILDREN;

export const isCompatibleSortingPanel = createSelector(
	getChildIssuesPanelType,
	isWhitelistedPanelForSorting,
);
