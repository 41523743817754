import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const updateFieldFormula =
	(fieldKey: FieldKey, formula: DynamicFieldFormula) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ ideaTypes, fieldRemote, createAnalyticsEvent }: Props,
	): Promise<Field> => {
		const currentField = getState().fields[fieldKey];

		fireTrackAnalytics(createAnalyticsEvent({}), 'issueFields updated', {
			updatedItems: [{ name: 'formula' }],
			issueFieldKey: currentField.key,
			issueFieldType: currentField.type,
		});

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const issueTypeId = ideaTypes![0].jiraIssueTypeId;
		await fieldRemote.updateFieldConfiguration({
			fieldKey,
			issueTypeId,
			oldConfiguration: currentField.formula ? { polarisFormula: currentField.formula } : {},
			configuration: { polarisFormula: formula },
		});

		const newField = {
			...currentField,
			formula,
		};
		setState({
			fields: {
				...getState().fields,
				[fieldKey]: newField,
			},
		});

		return newField;
	};
