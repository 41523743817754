import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	matchProviderError: {
		id: 'issue-add-design-form.design-form.input-field.match-provider-error',
		defaultMessage:
			'Please paste a valid {providerCount, plural, one {{singleProvider}} other {design}} URL',
		description: 'Error text for unable to match a design URL with a known design provider',
	},
	designFormAssociationError: {
		id: 'issue-add-design-form.design-form.input-field.design-form-association-error',
		defaultMessage: 'Unable to link this design to the issue. Please try again later.',
		description: 'Error text for the design URL input field',
	},
	designFormLabel: {
		id: 'issue-add-design-form.design-form.input-field.design-form-label',
		defaultMessage: 'Link a {providerCount, plural, one {{singleProvider}} other {design}} URL',
		description: 'Label for the design URL input field',
	},
});
