import React from 'react';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import MetaDate from '@atlassian/jira-issue-meta-date/src/main.tsx';
import { CREATED_DATE_TEST_ID } from '../../common/constants.tsx';
import messages from './messages.tsx';

type Props = {
	date: string;
};

export const CreatedDate = ({ date }: Props) => {
	const { formatMessage } = useIntl();
	return date !== null && date !== '' ? (
		<ErrorBoundary>
			<MetaDate
				testId={CREATED_DATE_TEST_ID}
				date={date}
				text={formatMessage(messages.createdLabel)}
			/>
		</ErrorBoundary>
	) : null;
};
