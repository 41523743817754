import React from 'react';

import { useSharedPluginState } from '@atlaskit/editor-common/hooks';
import { type Command, type ExtractInjectionAPI } from '@atlaskit/editor-common/types';
import { type EditorState } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';

import type { AIPlugin } from '../editor-plugin-ai';
import { type Recommendation } from '../pm-plugins/ai-proactive/api';
import {
	clearHoverRecommendation,
	hoverRecommendation,
	insertRecommendation,
	removeRecommendation,
	selectRecommendation,
	toggleProactiveAISuggestionDisplay,
} from '../pm-plugins/ai-proactive/commands';
import { getPluginState as getProactivePluginState } from '../pm-plugins/ai-proactive/plugin-factory';
import { selectFirstSuggestion } from '../pm-plugins/ai-spelling-grammar/commands';
import { getPluginState as getDecorationPluginState } from '../pm-plugins/decoration/decoration-plugin-factory';

import { RecommendationPanel } from './recommendation-panel';

export const getContextPanel =
	(getEditorView?: () => EditorView | undefined) =>
	(api: ExtractInjectionAPI<AIPlugin> | undefined) =>
	(state: EditorState) => {
		const proactivePluginState = getProactivePluginState(state);
		if (!proactivePluginState) {
			return null;
		}

		const { isProactiveEnabled, isProactiveContextPanelOpen } = proactivePluginState;
		if (!isProactiveEnabled || !isProactiveContextPanelOpen) {
			return null;
		}

		const decorationPluginState = getDecorationPluginState(state);
		if (decorationPluginState.modalDecorationSet !== DecorationSet.empty) {
			return null;
		}

		const editorView = getEditorView?.();
		return <ContextPanel api={api} editorView={editorView} />;
	};

type ContextPanelProps = {
	api: ExtractInjectionAPI<AIPlugin> | undefined;
	editorView: EditorView | undefined;
};

const ContextPanel = ({ api, editorView }: ContextPanelProps) => {
	const { aiExperienceState } = useSharedPluginState(api, ['aiExperience']);

	if (!aiExperienceState) {
		return null;
	}

	const {
		isSpellingGrammarEnabled,
		spellingGrammarErrorCount,
		recommendations,
		selectedRecommendationId,
		hoveredRecommendationId,
	} = aiExperienceState;

	if (!recommendations) {
		return null;
	}

	const triggerLocation = 'contextPanel';
	const runCommand = (command: Command) => {
		if (!editorView) {
			return;
		}
		const { state, dispatch } = editorView;
		command(state, dispatch);
	};

	const onRecommendationApply = ({ recommendation }: { recommendation: Recommendation }) => {
		runCommand(insertRecommendation(recommendation.id, triggerLocation));
	};

	const onRecommendationDismiss = ({ recommendation }: { recommendation: Recommendation }) => {
		runCommand(removeRecommendation(recommendation.id, triggerLocation));
	};

	const onRecommendationSelect = ({ recommendation }: { recommendation: Recommendation }) => {
		runCommand(selectRecommendation(recommendation.id));
	};

	const onRecommendationHover = ({ recommendation }: { recommendation: Recommendation }) => {
		runCommand(hoverRecommendation(recommendation.id));
	};

	const onRecommendationLeave = ({ recommendation }: { recommendation: Recommendation }) => {
		runCommand(clearHoverRecommendation());
	};

	const onPanelClose = () => {
		runCommand(toggleProactiveAISuggestionDisplay(triggerLocation));
	};

	const onSpellingGrammar = () => {
		runCommand(selectFirstSuggestion());
	};

	return (
		<RecommendationPanel
			isSpellingGrammarEnabled={isSpellingGrammarEnabled}
			spellingGrammarErrorCount={spellingGrammarErrorCount}
			recommendations={recommendations}
			hoveredRecommendationId={hoveredRecommendationId}
			selectedRecommendationId={selectedRecommendationId}
			onRecommendationApply={onRecommendationApply}
			onRecommendationDismiss={onRecommendationDismiss}
			onRecommendationSelect={onRecommendationSelect}
			onRecommendationHover={onRecommendationHover}
			onRecommendationLeave={onRecommendationLeave}
			onSpellingGrammar={onSpellingGrammar}
			onPanelClose={onPanelClose}
		/>
	);
};
