import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// This is a workaround to remove the negative margin applied by single-select for its focus border
// Eventually this can be removed when inline-edit is composed within components themselves,
// but without it, the focus border is cutoff by the inline-edit.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EditWrapper = styled.div({
	margin: `${token('space.025', '2px')} -1px ${token('space.025', '2px')} ${token(
		'space.025',
		'2px',
	)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CheckboxWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-start',
	padding: `${token('space.100', '8px')} ${token('space.150', '12px')}
              ${token('space.100', '8px')} ${token('space.150', '10px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `${token('space.025', '2px')} solid ${token('color.border', colors.N30)}`,
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionDescriptionWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
	font: token('font.body.UNSAFE_small'),
	marginTop: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Description = styled.span({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
});
