import { type RefObject, useEffect } from 'react';
import { useIsAiOptInEnabled } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in/src/index.tsx';
import { useRovoEntitlementResource } from '@atlassian/jira-router-resources-rovo-entitlement/src/services/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import {
	createStore,
	createActionsHook,
	createStateHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import { useUserHasConfluenceAccess } from '../use-has-confluence-access/index.tsx';
import { actions, type Actions } from './actions.tsx';
import type { State } from './types.tsx';

const Store = createStore<State, Actions>({
	initialState: {
		containerRef: { current: null },
		activePopup: undefined,
		selectedText: undefined,
	},
	actions,
	name: 'highlight-actions-store',
});

export const HighlightActionsContainer = createContainer(Store);

const getTriggerPosition = (state: State) => state.triggerPosition;

export const useTriggerPosition = createStateHook(Store, {
	selector: getTriggerPosition,
});

const getSelectedText = (state: State) => state.selectedText;
export const useSelectedText = createStateHook(Store, {
	selector: getSelectedText,
});

export const useHighlightActions = createActionsHook(Store);

export const useHighlightActionsState = createStateHook(Store);

const getActivePopup = (state: State) => state.activePopup;
export const useActivePopup = createStateHook(Store, {
	selector: getActivePopup,
});

export const useSetHighlightContainerRef = (ref: RefObject<HTMLDivElement | null>) => {
	const { setContainerRef } = useHighlightActions();
	useEffect(() => {
		setContainerRef(ref);
	}, [ref, setContainerRef]);
};

export const useIsHighlightEnabled = (): { highlightEnabled: boolean } => {
	const rovoEntitlement = useRovoEntitlementResource();
	const hasConfluenceAccess = useUserHasConfluenceAccess().hasAccess;
	const isAnonymous = useIsAnonymous();

	const isPremium = useIsPremium();
	const isAiOptInEnabled = useIsAiOptInEnabled();
	const isAIFeatureEnabled = isPremium && isAiOptInEnabled;

	const isRovoEnabled = rovoEntitlement?.data?.isRovoEnabled ?? false;

	return {
		highlightEnabled: hasConfluenceAccess && !isAnonymous && (isAIFeatureEnabled || isRovoEnabled),
	};
};
