import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { doc, p } from '@atlaskit/adf-utils/builders';
import {
	type SuggestedCommentRelyAgentProduct,
	PRODUCT_TYPES,
} from '@atlassian/jira-ai-assistance-service-client/src/services/invoke-agent/suggested-comment-reply-agent/types.tsx';
import {
	type CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';

import type { Emoji } from './constants.tsx';

// NOTE:
// previously we tried to utilise doc(p(emoji(emo), ` ${message}`));
// however, we need to have emoji provider on this to get accurate emoji representation.
// fallback to emoji font icon for now.
// if this change, please update and refactor the useCannedCommentsAnalytics (we use string match for some analytics)
export const getCannedCommentContent = (message: string, emo: Emoji): ADF =>
	doc(p(`${emo.text} ${message}`));

export const getProductReferrerKey = (
	projectType: typeof SOFTWARE_PROJECT | typeof CORE_PROJECT,
): SuggestedCommentRelyAgentProduct => {
	return projectType === SOFTWARE_PROJECT ? PRODUCT_TYPES.JSW : PRODUCT_TYPES.JWM;
};
