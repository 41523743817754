import React, { useEffect, useRef } from 'react';
import uuid from 'uuid';
import type { AIEventBaseAttributes } from '@atlassian/ai-analytics/src/events/AIEvents';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { useRelatedIssues } from './controllers/context.tsx';
import { SuggestRelatedIssuesModal } from './ui/index.tsx';

type RelatedIssuesAttributes = AIEventBaseAttributes & { singleInstrumentationId: string };

const AI_EVENT_ATTRIBUTES: AIEventBaseAttributes = {
	aiFeatureName: 'jiraIssueRelatedIssues',
	isAIFeature: 1,
	proactiveGeneratedAI: 0,
	userGeneratedAI: 1,
};

export const SuggestRelatedIssuesModalContainer = ({
	issueKey,
	existingIssues,
	panelDisplay,
	onRelatedIssuesTriggered,
}: {
	issueKey: string;
	existingIssues: string[];
	panelDisplay?: boolean;
	onRelatedIssuesTriggered: (arg: boolean) => void;
}) => {
	const singleInstrumentationId = useRef(uuid());
	const [
		{ currentStep },
		{
			setDisplayLinkedIssuesPanelCallback,
			setExistingIssues,
			discardAllSuggestions,
			setRelatedIssuesDraftStep,
			resetState,
		},
	] = useRelatedIssues();
	const attributes: RelatedIssuesAttributes = {
		...AI_EVENT_ATTRIBUTES,
		singleInstrumentationId: singleInstrumentationId.current,
	};

	useEffect(() => {
		resetState();
	}, [issueKey, resetState]);

	useEffect(() => {
		if (panelDisplay) {
			setRelatedIssuesDraftStep();
		}
	}, [panelDisplay, setRelatedIssuesDraftStep]);

	useEffect(() => {
		setDisplayLinkedIssuesPanelCallback(onRelatedIssuesTriggered);
	}, [onRelatedIssuesTriggered, setDisplayLinkedIssuesPanelCallback]);

	useEffect(() => {
		setExistingIssues(existingIssues);
	}, [setExistingIssues, existingIssues]);

	return (
		<ContextualAnalyticsData attributes={attributes}>
			<SuggestRelatedIssuesModal
				currentStep={currentStep}
				onDiscardAllSuggestions={discardAllSuggestions}
			/>
		</ContextualAnalyticsData>
	);
};
