import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noAssignee: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.no-assignee',
		defaultMessage: 'None Assigned',
		description: 'Avatar display text when no assignee',
	},
	showMoreButton: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.show-more-button',
		defaultMessage: 'Show More',
		description: 'Show more similar incidents button label',
	},
	affectedServices: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.affected-services',
		defaultMessage: 'Affected services',
		description: 'Affected services header label',
	},
	pir: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.pir',
		defaultMessage: 'PIR',
		description: 'Pir header label',
	},
	viewPir: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.view-pir',
		defaultMessage: 'View PIR',
		description: 'Label to shadow Pir link',
	},
	helpful: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.helpful',
		defaultMessage: 'Helpful',
		description: 'Tooltip message for like button',
	},
	notHelpful: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.not-helpful',
		defaultMessage: 'Not helpful',
		description: 'Tooltip message for dislike button',
	},
	noSimilarIncidents: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.no-similar-incidents',
		defaultMessage: 'There are no similar incidents for this issue.',
		description: 'Empty state for similar incidents container',
	},
	dropdownMessagesfirstItem: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.dropdown-messagesfirst-item',
		defaultMessage: 'Not relevant',
		description: 'Dislike reason first label',
	},
	dropdownMessageSecondItem: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.dropdown-message-second-item',
		defaultMessage: 'Not informative',
		description: 'Dislike reason second label',
	},
	dropdownMessagesThirdItem: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.dropdown-messages-third-item',
		defaultMessage: 'Not recent enough',
		description: 'Dislike reason third label',
	},
	dropdownMessagesFourthItem: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.dropdown-messages-fourth-item',
		defaultMessage: 'Other',
		description: 'Dislike reason fourth label',
	},
	textAreaPlaceHolder: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.text-area-place-holder',
		defaultMessage: 'Tell us why this wasn’t helpful?',
		description: 'Placeholder for feedback modal text area',
	},
	dislikeModalTitle: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.dislike-modal-title',
		defaultMessage: 'Didn’t find this helpful?',
		description: 'Dislike modal title',
	},
	none: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.none',
		defaultMessage: 'None',
		description: 'None label for empty state',
	},
	dislikeModalCloseButton: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.dislike-modal-close-button',
		defaultMessage: 'Close',
		description: 'Label for close button for dislike modal',
	},
	dislikeModalSubmitButton: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.dislike-modal-submit-button',
		defaultMessage: 'Submit',
		description: 'Label for submit button for dislike modal',
	},
	incidentIcon: {
		id: 'smart-related-issues-panel.similar-incidents.similar-incident-card.incident-icon',
		defaultMessage: 'Incident icon',
		description: 'Label for incident icon',
	},
});
