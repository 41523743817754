// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { token } from '@atlaskit/tokens';

import { Breakpoint } from '../../hooks/useElementBreakpoints';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const formContainerStyles = css({
	display: 'flex',
	justifyContent: 'flex-start',
	flex: 1,
	alignItems: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const formStyles = css({
	// We use '&&' to increase specificity against 'form' element styles in Jira's new issue create modal
	// otherwise the display value is overriden (and the prompt header elements are inadvertently laid out
	// horizontally instead).
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'&&': {
		// by default, form elements seem to pick up 12px
		// top margin so we clear it
		margin: 0,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const formPaddingTopStyles = css({
	paddingTop: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const formInnerStyles = css({
	display: 'flex',
	flex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"&[data-multiline='true']": {
		display: 'block',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const formInnerPaddingTopStyles = css({
	paddingTop: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const buttonWrapperStyles = css({
	float: 'right',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"&[data-multiline='true']": {
		paddingTop: token('space.100', '8px'),
	},
});

export const logoContainerStyles = (breakpoint: Breakpoint) =>
	css({
		display: 'flex',
		flexDirection: 'row',
		marginRight: token('space.050', '4px'),
		alignItems: 'center',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		alignSelf: breakpoint <= Breakpoint.Small ? 'self-start' : 'initial',
	});
