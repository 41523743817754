/**
 * @generated SignedSource<<b0c259327c2547068a91ac7d6797831c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type heading_issueDesignSection_DesignsHeading_designs$data = {
  readonly edges: ReadonlyArray<{
    readonly __typename: "GraphStoreSimplifiedIssueAssociatedDesignEdge";
  } | null | undefined>;
  readonly " $fragmentSpreads": FragmentRefs<"bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designs" | "designForm_issueAddDesignForm_AddDesignForm_designs">;
  readonly " $fragmentType": "heading_issueDesignSection_DesignsHeading_designs";
};
export type heading_issueDesignSection_DesignsHeading_designs$key = {
  readonly " $data"?: heading_issueDesignSection_DesignsHeading_designs$data;
  readonly " $fragmentSpreads": FragmentRefs<"heading_issueDesignSection_DesignsHeading_designs">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "heading_issueDesignSection_DesignsHeading_designs",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "designForm_issueAddDesignForm_AddDesignForm_designs"
    },
    {
      "kind": "FragmentSpread",
      "name": "bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designs"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "GraphStoreSimplifiedIssueAssociatedDesignEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "__typename"
          }
        ]
      },
      "action": "THROW",
      "path": "edges"
    }
  ],
  "type": "GraphStoreSimplifiedIssueAssociatedDesignConnection"
};

(node as any).hash = "5cc0c1431507fedf376730deadd9de2e";

export default node;
