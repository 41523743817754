import { baseScopesRequiredPerField } from '@atlassian/jira-issue-adjustments/src/common/utils/filter-based-on-scopes/fields-scopes.tsx';

export const labelsRequiredScopes = {
	granular: [...baseScopesRequiredPerField.granular, 'read:label:jira'],
	coarse: [...baseScopesRequiredPerField.coarse],
};

export type LabelsPublicValue = string[];
export type LabelsInternalValue = {
	value: string;
	label: string;
}[];

export const labelsSetValueValidator = (value: unknown): value is LabelsPublicValue =>
	typeof value === 'object' &&
	value !== null &&
	Array.isArray(value) &&
	value.every((label) => typeof label === 'string' && label !== '');

export const labelsSetValueTransformer = (publicValue: LabelsPublicValue): LabelsInternalValue =>
	publicValue.map((label) => ({ value: label, label }));

export const labelsGetValueTransformer = (internalValue: LabelsInternalValue): LabelsPublicValue =>
	internalValue.map(({ value }) => value);
