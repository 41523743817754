import React from 'react';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { Props as IssueCascadingSelectFieldProps } from '@atlassian/jira-issue-field-cascading-select/src/common/types.tsx';
import IssueCascadingSelectField from '@atlassian/jira-issue-field-cascading-select/src/ui/main.tsx';
import {
	type Area,
	CONTENT,
	CONTENT_REQUEST,
} from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { withFieldViewExperienceTracker } from '@atlassian/jira-issue-view-experience-tracking/src/view-experience/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	issueKeySelector,
	isMobileSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { fieldNameSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { errorSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { CASCADING_SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';

const CascadingSelectView = (props: IssueCascadingSelectFieldProps) => {
	const { issueKey, fieldKey, label } = props;
	const [fieldValue] = useFieldValue({
		issueKey,
		fieldKey,
	});

	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);

	return (
		<IssueCascadingSelectField
			{...props}
			allowedValues={(fieldConfig && fieldConfig.allowedValues) || []}
			isEditable={(fieldConfig && fieldConfig.isEditable) || false}
			label={(fieldConfig && fieldConfig.title) || label}
			initialValue={fieldValue}
		/>
	);
};

export default flowWithSafeComponent(
	connect(
		(
			state: State,
			{
				fieldId,
				area,
			}: {
				fieldId: string;
				area: Area;
			},
		) => ({
			fieldKey: fieldId,
			fieldType: CASCADING_SELECT_CF_TYPE,
			error: errorSelector(state),
			issueKey: issueKeySelector(state),
			isMobile: isMobileSelector(state),
			label: fieldNameSelector(fieldId)(state),
			areOptionsOnSameRow: area === CONTENT || area === CONTENT_REQUEST,
			showPinButton: getShowPinButton(area),
		}),
		{},
	),
)(withFieldViewExperienceTracker()(CascadingSelectView));
