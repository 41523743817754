import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	bulkUnlinkAllButtonLabel: {
		id: 'issue-design-section.panel.heading.more-actions-dropdown-menu.bulk-unlink-dropdown-item.bulk-unlink-all-button-label',
		defaultMessage: 'Unlink all {providerName} designs from issue',
		description: 'The button label to remove all the linked designs from the issue.',
	},
	authFailedFlagMessage: {
		id: 'issue-design-section.panel.heading.more-actions-dropdown-menu.bulk-unlink-dropdown-item.auth-failed-flag-message',
		defaultMessage: 'Authentication failed with the design application',
		description:
			'Error message to display when the authentication fails with the 3rd party design provdier.',
	},
});
