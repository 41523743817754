import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fieldCanBeHidden } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetFieldByKey } from '../../selectors/field.tsx';
import type { Props, State } from '../../types.tsx';

export const updateSystemFieldHidden =
	(fieldKey: FieldKey, hidden: boolean) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, ideaTypes, createAnalyticsEvent }: Props,
	): Promise<void> => {
		try {
			const field = createGetFieldByKey(fieldKey)(getState());
			if (!field || !fieldCanBeHidden(field.type)) {
				return;
			}

			const issueType = ideaTypes?.[0];
			if (issueType === undefined) {
				return;
			}

			const configuration = {
				...field.configuration,
				hidden,
			};

			await fieldRemote.updateFieldConfiguration({
				oldConfiguration: field.configuration ?? {},
				configuration,
				fieldKey: field.key,
				issueTypeId: issueType.jiraIssueTypeId,
			});

			const fields = { ...getState().fields };
			fields[fieldKey] = {
				...fields[fieldKey],
				configuration,
			};
			setState({ fields });

			fireTrackAnalytics(createAnalyticsEvent({}), 'issueFields updated', {
				issueFieldKey: fieldKey,
				issueFieldType: field.type,
				updatedItems: [{ name: 'hidden' }],
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			onFieldUpdateFailed(err);
			throw err;
		}
	};
