import type {
	User as Voter,
	ServerRestUser as User,
} from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';

export const LOGGED_IN_USER_ACTION = {
	ADDED: 'ADDED',
	REMOVED: 'REMOVED',
} as const;

export type LoggedInUserAction = keyof typeof LOGGED_IN_USER_ACTION | undefined;

export type VotersData = {
	voters: Voter[];
	loggedInUserAction: LoggedInUserAction;
};

export type State = VotersData;

export type StoreApi = StoreActionApi<State>;

export type VoterResponse =
	| {
			voters: User[];
	  }
	| undefined;
