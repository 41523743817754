// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'issue.delete.confirm',
		defaultMessage: 'Delete',
		description: 'Label for the issue actions item to delete the issue',
	},
	deleteHeading: {
		id: 'issue.delete-heading',
		defaultMessage: 'Delete {issueKey}?',
		description: 'Heading for the delete modal to delete the issue',
	},
	deleteWithSubtasksHeading: {
		id: 'issue.delete-with-subtasks-heading',
		defaultMessage: 'Delete {issueKey} and its subtasks?',
		description: 'Heading for the delete modal to delete the issue when it has subtasks',
	},
	deleteWithChildrenHeading: {
		id: 'issue.delete-with-children-heading',
		defaultMessage: 'Delete {issueKey} and its child issues?',
		description: 'Heading for the delete modal to delete the issue when it has child issues',
	},
	cancel: {
		id: 'issue.delete.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for the button to cancel deleting the issue',
	},
	deleteWarning: {
		id: 'issue.delete-warning',
		defaultMessage:
			"You're about to permanently delete this issue, its comments and attachments, and all of its data.",
		description: '',
	},
	deleteWithSubtasksWarning: {
		id: 'issue.delete-with-subtasks-warning',
		defaultMessage:
			"You're about to permanently delete this issue and all its subtasks, its comments and attachments, and all of its data. You should move any subtasks you don't want to delete to another parent issue.",
		description: '',
	},
	deleteWithChildrenWarning: {
		id: 'issue.delete-with-children-warning',
		defaultMessage:
			"You're about to permanently delete this issue and all its child issues, its comments and attachments, and all of its data. You should move any child issues you don't want to delete to another parent issue.",
		description: '',
	},
	deleteRecommendation: {
		id: 'issue.delete-recommendation',
		defaultMessage: "If you're not sure, you can resolve or close this issue instead.",
		description: '',
	},
});
