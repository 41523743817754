import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

export const COMMENTS = 'ANY_COMMENT' as const;
export const HISTORY = 'HISTORY' as const;
export const WORK_LOG = 'WORK_LOG' as const;

// JSM Approvals
export const APPROVAL = 'APPROVAL' as const;
export const HIDDEN_ENTRY_GROUP = 'HIDDEN_CUSTOM_ENTRIES' as const;

// Additional Opsgenie filters
export const RESPONDER_ALERT_ENTRY_GROUP = 'RESPONDER_ALERT' as const;
export const INCIDENT_ENTRY_GROUP = 'INCIDENT' as const;
export const ICC_SESSION_LIFECYCLE_ENTRY_GROUP = 'ICC_SESSION_LIFECYCLE' as const;
export const ICC_SESSION_DETAILS_ENTRY_GROUP = 'ICC_SESSION_DETAILS' as const;
export const STAKEHOLDER_UPDATES_ENTRY_GROUP = 'STAKEHOLDER_UPDATE' as const;

export type IncidentActivityFeedEntryId =
	| typeof COMMENTS
	| typeof HISTORY
	| typeof WORK_LOG
	| typeof RESPONDER_ALERT_ENTRY_GROUP
	| typeof INCIDENT_ENTRY_GROUP
	| typeof ICC_SESSION_LIFECYCLE_ENTRY_GROUP
	| typeof ICC_SESSION_DETAILS_ENTRY_GROUP
	| typeof STAKEHOLDER_UPDATES_ENTRY_GROUP
	| typeof APPROVAL
	| typeof HIDDEN_ENTRY_GROUP;

export type FilterDropdownOption = {
	id: IncidentActivityFeedEntryId;
	i18nMessage: MessageDescriptor;
};

export type FilterDropdownOptions = Record<string, FilterDropdownOption>;
