import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import { transformAppLink } from '@atlassian/jira-issue-view-common-views/src/app-link/app-link-select/utils.tsx';
import messages from './messages.tsx';
import ConfluenceSearchService from './services/search/index.tsx';
import type { Props } from './types.tsx';
import AppLinksSelect from './ui/app-link/index.tsx';
import type { SelectItemAppLink } from './ui/app-link/types.tsx';
import ConfluenceSearchSelect from './ui/search/index.tsx';
import ConfluenceSpaceSelect from './ui/spaces/index.tsx';

const SINGLE_COLUMN_MAX_WIDTH = 480;

const transformAppLinkForSelect = (
	confluenceAppLinks: ConfluenceAppLink[],
): SelectItemAppLink[] => {
	const remoteLinks = confluenceAppLinks.map<SelectItemAppLink>((item) => transformAppLink(item));
	return [...remoteLinks];
};

const ConfluenceSearchGroupView = (props: Props) => {
	const {
		linkedPages,
		mentionedPages,
		recentOptions,
		validationState,
		validationMessage,
		onSelect,
		onEnter,
		isAutoFocusable,
		link,
		confluenceAppLinks,
		isLoading,
		onChange,
		containerWidth,
	} = props;

	const [appId, setAppId] = useState<string>(
		confluenceAppLinks.length > 0 ? confluenceAppLinks[0].id : '',
	);
	const [spaceKey, setSpaceKey] = useState<string>('');
	const { formatMessage } = useIntl();

	const onSpaceKeyChange = (newSpaceKey: string) => {
		setSpaceKey(newSpaceKey); //  SpaceKey;
	};

	const isCompact =
		containerWidth !== undefined ? containerWidth <= SINGLE_COLUMN_MAX_WIDTH : false;

	const showConfluenceAppLinkSelect = confluenceAppLinks.length > 1;

	const confluenceAppLinksForSelect: SelectItemAppLink[] =
		transformAppLinkForSelect(confluenceAppLinks);

	const [confluenceAppLink, setConfluenceAppLink] = useState<SelectItemAppLink>();

	const onConfluenceAppLinkChange = (item: SelectItemAppLink) => {
		setAppId(item.fullObject.id);
		setConfluenceAppLink(item);
		setSpaceKey('');
	};

	const searchContainer = useRef<HTMLInputElement>(null);

	const focusOnSearchInput = () => {
		if (searchContainer.current instanceof HTMLElement) {
			const input = searchContainer.current.querySelector('input');
			if (input !== null) {
				input.focus();
			}
		}
	};

	useEffect(() => {
		if (isAutoFocusable) {
			focusOnSearchInput();
		}
	}, [isAutoFocusable]);

	return (
		<JSErrorBoundary
			id="confluence-search"
			packageName="jiraIssueViewConfluence"
			fallback="unmount"
		>
			<AddContainer>
				<InputsContainer isCompact={isCompact}>
					{showConfluenceAppLinkSelect && (
						<SelectContainer isCompact={isCompact}>
							<AppLinksSelect
								selectItems={confluenceAppLinksForSelect}
								value={confluenceAppLink}
								containerWidth={containerWidth}
								onChange={onConfluenceAppLinkChange}
								selectType="ConfluenceAppLinksSelect"
							/>
						</SelectContainer>
					)}
					<SelectContainer isCompact={isCompact}>
						<ConfluenceSpaceSelect
							onSpaceKeyChange={onSpaceKeyChange}
							spaceKey={spaceKey}
							appId={appId}
							isCompact={isCompact}
						/>
					</SelectContainer>
					<ConfluenceSearchContainer ref={searchContainer}>
						<ConfluenceSearchService
							appId={appId}
							spaceKey={spaceKey}
							linkedPages={linkedPages}
							mentionedPages={mentionedPages}
							recentOptions={recentOptions}
							labels={{
								recentPages: formatMessage(messages.defaultRecentPages),
								matchingPages: formatMessage(messages.defaultMatchingPages),
							}}
						>
							{({
								fetchSuggestions,
								options,
								loading: isLoadingOptions,
								error,
								type,
								clearState: clearSearchState,
							}) => (
								<ConfluenceSearchSelect
									spaceKey={spaceKey}
									isLoading={isLoading}
									isLoadingOptions={isLoadingOptions}
									options={options}
									onChange={onChange}
									validationState={validationState}
									validationMessage={validationMessage}
									onSelect={onSelect}
									onEnter={onEnter}
									link={link}
									fetchSuggestions={fetchSuggestions}
									optionsError={error}
									resultType={type}
									clearSearchState={clearSearchState}
								/>
							)}
						</ConfluenceSearchService>
					</ConfluenceSearchContainer>
				</InputsContainer>
			</AddContainer>
		</JSErrorBoundary>
	);
};

export default withContainerWidth(ConfluenceSearchGroupView);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddContainer = styled.div({
	margin: `${token('space.100', '8px')} 0`,
	paddingRight: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InputsContainer = styled.div<{ isCompact: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: ({ isCompact }) => (isCompact ? 'column' : 'row'),
	justifyContent: 'space-around',
	alignContent: 'stretch',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		marginTop: token('space.100', '8px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> :not(:last-child)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		marginRight: ({ isCompact }) => (isCompact ? '0' : `${token('space.100', '8px')}`),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectContainer = styled.div<{ isCompact: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	maxWidth: ({ isCompact }) => (isCompact ? 'none' : `${gridSize * 25}px`),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isCompact }) => (isCompact ? '100%' : '27%'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 17}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfluenceSearchContainer = styled.div({
	flex: 1,
	flexBasis: 'auto',
});
