import {
	SOFTWARE_PROJECT,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	toHref,
	type IssueKey,
	type BaseUrl,
	type Href,
	type ProjectKey,
	type IssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';

const JIRA_ISSUE_KEY_REGEX = /^([A-Z][A-Z0-9]+)-([0-9]*)$/i;
const parseProjectKey = (query: string) => {
	if (query) {
		const parsedGroups = JIRA_ISSUE_KEY_REGEX.exec(query);
		if (parsedGroups && parsedGroups.length === 3) {
			return parsedGroups[1];
		}
	}
	return undefined;
};

export const getIssueHtmlUrl = (baseUrl: BaseUrl, issueKey: IssueKey): Href =>
	toHref(`${baseUrl}/browse/${issueKey}`);

export const getProjectUrl = (
	baseUrl: BaseUrl,
	projectKey: ProjectKey,
	type?: ProjectType,
	boardId?: string,
	isSimplified?: boolean,
): Href => {
	if (type === SOFTWARE_PROJECT && boardId) {
		if (isSimplified) {
			return `${baseUrl}/jira/software/projects/${projectKey}/boards/${boardId}`;
		}
		return `${baseUrl}/jira/software/c/projects/${projectKey}/boards/${boardId}`;
	}
	return toHref(`${baseUrl}/browse/${projectKey}`);
};

export const getIssueApiUrl = (baseUrl: BaseUrl, issueKey: IssueKey): Href =>
	toHref(`${baseUrl}/rest/api/2/issue/${issueKey}`);

export const getSearchUrl = (baseUrl: BaseUrl): Href => toHref(`${baseUrl}/rest/api/2/search`);

export const getSearchV3Url = (baseUrl: BaseUrl): Href => toHref(`${baseUrl}/rest/api/3/search`);

export const getIssueLinksUrl = (baseUrl: BaseUrl, issueKey: IssueKey): Href =>
	toHref(`${baseUrl}/rest/api/2/issue/${issueKey}?fields=issuelinks`);

export const getIssueLinksBaseUrl = (baseUrl: BaseUrl, issueKey: IssueKey): Href =>
	toHref(`${baseUrl}/rest/api/2/issue/${issueKey}`);

export const getIssueLinkTypeUrl = (baseUrl: BaseUrl): Href =>
	toHref(`${baseUrl}/rest/api/2/issueLinkType`);

const getIssuePickerUrl = (
	baseUrl: BaseUrl,
	currentJQL: string,
	issueKey: IssueKey,
	query: string,
): Href =>
	toHref(
		`${baseUrl}/rest/api/2/issue/picker?currentIssueKey=${issueKey}&showSubTasks=true&showSubTaskParent=true&query=${encodeURIComponent(
			query,
		)}&currentJQL=${encodeURIComponent(currentJQL)}`,
	);

// next-gen JSD issues may not have the resolution field and instead use statusCategory,
// but all others (classic JSD/JSW, next-gen JSW) prefer resolution
const unresolvedAndOrder =
	'(resolution = Unresolved or statusCategory != Done) ORDER BY priority DESC, updated DESC';

export const getUnresolvedIssueHistoryUrlForLinking = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	query: string,
): Href => {
	let currentJQL = `project in projectsWhereUserHasPermission("Link Issues") AND ${unresolvedAndOrder}`;
	const projectKey = parseProjectKey(query);
	if (projectKey) {
		currentJQL = `project = "${projectKey.toUpperCase()}" AND ${currentJQL}`;
	}

	return getIssuePickerUrl(baseUrl, currentJQL, issueKey, query);
};

export const getAllIssuesHistoryUrl = (
	baseUrl: BaseUrl,
	currentIssueKey: IssueKey,
	queryIssueKey: IssueKey,
): Href => {
	let currentJQL = '';
	const projectKey = parseProjectKey(queryIssueKey);
	if (projectKey) {
		currentJQL = `project = "${projectKey.toUpperCase()}" ORDER BY priority DESC, updated DESC`;
	}
	return getIssuePickerUrl(baseUrl, currentJQL, currentIssueKey, queryIssueKey);
};

export const getProjectCreateMetaUrl = (baseUrl: BaseUrl, projectKey: ProjectKey): Href =>
	toHref(
		`${baseUrl}/rest/api/2/issue/createmeta?projectKeys=${projectKey}&expand=projects.issuetypes.fields`,
	);

export const getSaveIssueLinksUrl = (baseUrl: BaseUrl): Href =>
	toHref(`${baseUrl}/rest/api/2/issueLink`);

export const getSaveRemoteIssueLinksUrl = (
	baseUrl: BaseUrl,
	currentIssueIdOrKey: IssueKey | IssueId,
): Href =>
	toHref(`${baseUrl}/rest/remoteJiraIssueLink/1/remoteJira/${currentIssueIdOrKey}/createIssueLink`);

export const getGraphQlUrl = (baseUrl: BaseUrl): Href => toHref(`${baseUrl}/rest/graphql/1/`);

export const getConfluenceAppLinksUrl = (baseUrl: BaseUrl): Href =>
	toHref(`${baseUrl}/rest/confluenceIssueLink/1/confluence/applink`);

export const getJiraAppLinksUrl = (baseUrl: BaseUrl, issueKey: IssueKey): Href =>
	toHref(
		`${baseUrl}/rest/issueLinkAppLink/1/appLink/info?type=com.atlassian.applinks.api.application.jira.JiraApplicationType&issueIdOrKey=${issueKey}`,
	);

const getRemoteIssuePickerUrl = (
	baseUrl: BaseUrl,
	currentJQL: string,
	query: string,
	appLinkId: string,
): Href =>
	toHref(
		`${baseUrl}/rest/remoteJiraIssueLink/1/remoteJira/picker?showSubTasks=true&showSubTaskParent=true&query=${encodeURIComponent(
			query,
		)}&currentJQL=${encodeURIComponent(currentJQL)}&appId=${appLinkId}`,
	);

export const getRemoteUnresolvedIssueHistoryUrlForLinking = (
	baseUrl: BaseUrl,
	query: string,
	appId: string,
): Href => {
	let currentJQL = unresolvedAndOrder;
	const projectKey = parseProjectKey(query);
	if (projectKey) {
		currentJQL = `project = "${projectKey.toUpperCase()}" AND ${currentJQL}`;
	}

	return getRemoteIssuePickerUrl(baseUrl, currentJQL, query, appId);
};

export const getRemoteAllIssuesHistoryUrl = (
	baseUrl: BaseUrl,
	queryIssueKey: IssueKey,
	appLinkId: string,
) => {
	let currentJQL = '';
	const projectKey = parseProjectKey(queryIssueKey);
	if (projectKey) {
		currentJQL = `project = "${projectKey.toUpperCase()}" ORDER BY priority DESC, updated DESC`;
	}
	return getRemoteIssuePickerUrl(baseUrl, currentJQL, queryIssueKey, appLinkId);
};

export const getRemoteIssueLinksUrl = (baseUrl: BaseUrl): Href =>
	toHref(`${baseUrl}/rest/remoteJiraIssueLink/1/remoteJira/picker`);
