import React, { useCallback, useState, type ChangeEvent } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import NumberEditViewEntryPoint from '@atlassian/jira-issue-field-number-editview-full/src/entrypoint.tsx';
import type { NumberEditViewProps } from '@atlassian/jira-issue-field-number-editview-full/src/ui/number/types.tsx';
import { StoryPointEstimateReadView } from '@atlassian/jira-issue-field-story-point-estimate-readview-full/src/ui/story-point-estimate/index.tsx';
import type { storyPointEstimate_issueFieldStoryPointEstimate_StoryPointEstimateField_Mutation as StoryPointEstimateMutation } from '@atlassian/jira-relay/src/__generated__/storyPointEstimate_issueFieldStoryPointEstimate_StoryPointEstimateField_Mutation.graphql';
import type { storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditView_fragmentRef$key as StoryPointEstimateFragment } from '@atlassian/jira-relay/src/__generated__/storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditView_fragmentRef.graphql';
import type { storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef$key as StoryPointEstimateWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef.graphql';
import type {
	StoryPointEstimateInlineEditViewProps,
	StoryPointEstimateInlineEditViewWithIsEditableProps,
	NumberValue,
} from './types.tsx';

export const StoryPointEstimateInlineEditViewIsEditable = ({
	attributes,
	spacing = 'compact',
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable: isFieldEditable,
	isEditing: startWithEditViewOpen,
	onSubmit,
	onSubmitSucceeded,
	onSubmitFailed,
	readViewFitContainerHeight,
}: StoryPointEstimateInlineEditViewWithIsEditableProps) => {
	// #region Relay
	const data = useFragment<StoryPointEstimateWithIsEditableFragment>(
		graphql`
			fragment storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef on JiraNumberField {
				...storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView
				id
				type
				name
				number
			}
		`,
		fragmentRef,
	);

	const { id: uniqueFieldId, type, name: fieldName, number: initialValue } = data;

	const [commit] = useMutation<StoryPointEstimateMutation>(graphql`
		mutation storyPointEstimate_issueFieldStoryPointEstimate_StoryPointEstimateField_Mutation(
			$input: JiraUpdateNumberFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateNumberField(input: $input) {
					success
					errors {
						message
					}
					field {
						id
						number
					}
				}
			}
		}
	`);
	// #endregion

	const [updatedValue, setUpdatedValue] = useState<NumberValue>(initialValue);

	const handleSubmit = useCallback(
		(value: NumberValue, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(value);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							number: value,
						},
					},
				},
				onCompleted: (mutationData) => {
					if (mutationData.jira?.updateNumberField) {
						const { success, errors: responseErrors, field } = mutationData.jira.updateNumberField;

						if (success && field != null) {
							onSuccess();
						} else {
							const errorMessage = responseErrors?.[0]?.message;

							onFail(
								new Error(errorMessage || 'Relay error encountered while updating number field'),
								// Display server error message in flag if it exists
								errorMessage ?? undefined,
							);
						}
					}
				},
				onError: (incomingError) => {
					onFail(incomingError);
				},
				optimisticResponse: {
					jira: {
						updateNumberField: {
							success: true,
							errors: [],
							field: {
								id: uniqueFieldId,
								number: value != null ? value : null,
							},
						},
					},
				},
			});
		},
		[commit, onSubmit, uniqueFieldId],
	);

	const {
		handleCancel,
		handleEdit,
		handleChange,
		handleConfirm,
		hasServerValidationError,
		invalidMessage,
		isEditing,
	} = useFieldInlineEditActions({
		attributes,
		fieldId: uniqueFieldId,
		fieldName,
		fieldType: type,
		initialValue,
		onSubmit: handleSubmit,
		onSubmitSucceeded,
		onSubmitFailed,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
	});

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const typedValue =
				event.target.value === '' ? null : parseFloat(Number(event.target.value).toFixed(3));
			handleChange(typedValue);
		},
		[handleChange],
	);

	const renderReadView = useCallback(
		() => <StoryPointEstimateReadView fragmentRef={data} />,
		[data],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): NumberEditViewProps => ({
		...fieldProps,
		spacing,
		label: fieldName,
		defaultValue: updatedValue,
		isInvalid: invalidMessage != null || hasServerValidationError,
		onChange,
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewEntryPoint={NumberEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage ?? undefined}
			isEditable={isFieldEditable}
			isEditing={isEditing || hasServerValidationError}
			keepEditViewOpenOnBlur={hasServerValidationError}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [StoryPointEstimateInlineEditViewProps](./types.tsx)
 */
export const StoryPointEstimateInlineEditView = ({
	fragmentRef,
	...props
}: StoryPointEstimateInlineEditViewProps) => {
	// #region Relay
	const data = useFragment<StoryPointEstimateFragment>(
		graphql`
			fragment storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditView_fragmentRef on JiraNumberField {
				...storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef
				isEditableInIssueView
			}
		`,
		fragmentRef,
	);
	// #endregion

	return (
		<StoryPointEstimateInlineEditViewIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.isEditableInIssueView ?? false}
		/>
	);
};
