import { fg } from '@atlaskit/platform-feature-flags';

import { deriveProactiveAIApiUrl } from '../../prebuilt/utils';
import type { EditorPluginAIProvider, ProactiveAIConfig } from '../../types';

export const getConfiguration = (
	editorPluginAIProvider: EditorPluginAIProvider,
): {
	isProactiveAISupported: boolean;
	proactiveAIConfig: ProactiveAIConfig;
} => {
	const { proactiveAIConfig, isFullPageExperimentsEnabled, allowProactiveAIFeatures } =
		editorPluginAIProvider;

	const isProactiveAISupported =
		!!isFullPageExperimentsEnabled &&
		!!allowProactiveAIFeatures &&
		!!fg('platform_editor_ai_proactive_visual_formatting');

	return {
		isProactiveAISupported,
		proactiveAIConfig: {
			...proactiveAIConfig,
			enabled: isProactiveAISupported,
			defaultToggledState: false,
			apiUrl: deriveProactiveAIApiUrl(),
			timings: {
				// disabling the current chunk checking
				currentChunks: -1,
				currentChunksMaxWait: 30000,
				nonCurrentChunks: 5000,
			},
			// We don't want a full page spell checker for this proative plugin
			documentSGChecker: undefined,
		},
	};
};
