import React, { useCallback } from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { AiIcon } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useRelatedIssuesActions } from '../../controllers/context.tsx';
import { useRelatesToIssueLinkType } from '../../utils/index.tsx';
import messages from '../messages.tsx';

export const FindSimilarIssuesButton = () => {
	const { formatMessage } = useIntl();

	const hasRelatesToLinkType = useRelatesToIssueLinkType();
	const { setRelatedIssuesDraftStep } = useRelatedIssuesActions();

	const triggerDraftListSuggestion = useCallback(() => {
		setRelatedIssuesDraftStep();
	}, [setRelatedIssuesDraftStep]);

	const isDisabled = !hasRelatesToLinkType;

	return (
		<Box xcss={buttonStyles}>
			<Button
				spacing="compact"
				iconBefore={(iconProps) => (
					<AiIcon
						{...iconProps}
						label={formatMessage(messages.findSimilarIssues)}
						size="small"
						isDisabled={isDisabled}
					/>
				)}
				onClick={triggerDraftListSuggestion}
				isDisabled={isDisabled}
			>
				{formatMessage(messages.findSimilarIssues)}
			</Button>
		</Box>
	);
};

const buttonStyles = xcss({
	marginInlineStart: 'space.075',
});
