import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris/src/index.tsx';
import type { PolarisViewLastViewed } from '@atlassian/jira-polaris-domain-view/src/last-viewed/types.tsx';
import { LAST_VIEWED_FRAGMENT } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/last-viewed/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { ViewNotFoundError } from '../../../common/errors/index.tsx';

type ViewLastViewed = {
	subject: string; // Ari
	lastViewed: PolarisViewLastViewed[];
};

export const GET_VIEW_LAST_VIEWED_QUERY = gqlTagPolaris`
query jira_polaris_GetViewLastViewedQuery($view: ID!) {
    polarisView(id: $view) {
        id
        lastViewed {
            ...PolarisViewLastViewed
        }
    }
  }

${LAST_VIEWED_FRAGMENT}`;

export const fetchViewLastViewed = async (
	apolloClient: PolarisApolloClient,
	viewAri: string,
): Promise<ViewLastViewed> => {
	const result = await apolloClient.query({
		query: GET_VIEW_LAST_VIEWED_QUERY,
		variables: {
			view: viewAri,
		},
		fetchPolicy: 'network-only',
	});

	const { data, errors } = result;
	if (errors !== undefined) {
		throw new Error(`last-viewed.fetch-error:${errors.map((e) => e.message).join(', ')}`);
	}

	if (!data || data.polarisView === undefined || data.polarisView === null) {
		throw new ViewNotFoundError('last-viewed.fetch-error: no data or no data node');
	} else {
		return {
			subject: viewAri,
			lastViewed: data.polarisView.lastViewed || [],
		};
	}
};
