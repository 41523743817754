/**
 * @generated SignedSource<<b452e9df182e5747de78cebf3ce2959e>>
 * @relayHash 54821f2ce0db2d1ba6de7e01ec14b860
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e291987f4b2f3c06a39fa1847bccf79e6c0a181764a535dbcd391aa5e7f53c2b

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type mainGoalsPopupContentQuery$variables = {
  cloudId: string;
  issueAri: string;
};
export type mainGoalsPopupContentQuery$data = {
  readonly townsquare: {
    readonly allWorkspacesForOrg: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly cloudId: string;
          readonly name: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"issueGoals_AtlassianGoalPicker">;
};
export type mainGoalsPopupContentQuery = {
  response: mainGoalsPopupContentQuery$data;
  variables: mainGoalsPopupContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "cloudId"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "issueAri"
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v4/*: any*/)
],
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "kind": "InlineFragment",
  "selections": (v8/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v10 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v11 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v12 = [
  "id"
],
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  },
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "mainGoalsPopupContentQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "issueGoals_AtlassianGoalPicker"
      },
      {
        "concreteType": "TownsquareQueryApi",
        "kind": "LinkedField",
        "name": "townsquare",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "TownsquareWorkspaceConnection",
            "kind": "LinkedField",
            "name": "allWorkspacesForOrg",
            "plural": false,
            "selections": [
              {
                "concreteType": "TownsquareWorkspaceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "TownsquareWorkspace",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mainGoalsPopupContentQuery",
    "selections": [
      {
        "concreteType": "GraphStore",
        "kind": "LinkedField",
        "name": "graphStore",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "GraphStoreSimplifiedJiraEpicContributesToAtlasGoalConnection",
            "kind": "LinkedField",
            "name": "jiraEpicContributesToAtlasGoal",
            "plural": false,
            "selections": [
              {
                "concreteType": "GraphStoreSimplifiedJiraEpicContributesToAtlasGoalEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v7/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "url"
                          },
                          {
                            "concreteType": "TownsquareStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "value"
                              },
                              {
                                "concreteType": "TownsquareLocalizationField",
                                "kind": "LinkedField",
                                "name": "localizedLabel",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "messageId"
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "TownsquareGoal"
                      },
                      (v9/*: any*/)
                    ]
                  },
                  (v10/*: any*/),
                  (v7/*: any*/)
                ]
              },
              (v11/*: any*/)
            ]
          },
          {
            "args": (v5/*: any*/),
            "filters": (v12/*: any*/),
            "handle": "connection",
            "key": "LinkedGoals_jiraEpicContributesToAtlasGoal",
            "kind": "LinkedHandle",
            "name": "jiraEpicContributesToAtlasGoal"
          },
          {
            "alias": "issueGoalPicker",
            "args": (v13/*: any*/),
            "concreteType": "GraphStoreSimplifiedJiraEpicContributesToAtlasGoalConnection",
            "kind": "LinkedField",
            "name": "jiraEpicContributesToAtlasGoal",
            "plural": false,
            "selections": [
              {
                "concreteType": "GraphStoreSimplifiedJiraEpicContributesToAtlasGoalEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v8/*: any*/),
                        "type": "TownsquareGoal"
                      },
                      (v9/*: any*/)
                    ]
                  },
                  (v10/*: any*/),
                  (v7/*: any*/)
                ]
              },
              (v11/*: any*/)
            ]
          },
          {
            "alias": "issueGoalPicker",
            "args": (v13/*: any*/),
            "filters": (v12/*: any*/),
            "handle": "connection",
            "key": "Goals_issueGoalPicker",
            "kind": "LinkedHandle",
            "name": "jiraEpicContributesToAtlasGoal"
          }
        ]
      },
      {
        "concreteType": "TownsquareQueryApi",
        "kind": "LinkedField",
        "name": "townsquare",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "TownsquareWorkspaceConnection",
            "kind": "LinkedField",
            "name": "allWorkspacesForOrg",
            "plural": false,
            "selections": [
              {
                "concreteType": "TownsquareWorkspaceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "TownsquareWorkspace",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e291987f4b2f3c06a39fa1847bccf79e6c0a181764a535dbcd391aa5e7f53c2b",
    "metadata": {},
    "name": "mainGoalsPopupContentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "465827f41dc8a072858c56967c88337f";

export default node;
