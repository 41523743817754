import React, { useEffect, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import { isSafeUrl } from '@atlaskit/adf-schema';
import { Field, ErrorMessage } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import { useDesignPanelStore } from '@atlassian/jira-issue-design-section-store/src/index.tsx';
import type { inputField_issueAddDesignForm$key } from '@atlassian/jira-relay/src/__generated__/inputField_issueAddDesignForm.graphql';
import { DesignFormLinkState, DesignAppConfig } from '../../../common/constants.tsx';
import messages from './messages.tsx';

type InputFieldProps = {
	inputFieldData: inputField_issueAddDesignForm$key | null;
	autoFocus?: boolean;
};

const validateDesignLink = (url: string | undefined) => {
	if (url && !isSafeUrl(url)) {
		return DesignFormLinkState.INVALID_URL;
	}

	return undefined;
};

const callWhenIdle = (callback: () => void) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (typeof window.requestIdleCallback === 'function') {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.requestIdleCallback(callback);
	} else {
		setTimeout(callback, 0);
	}
};

const InputField = ({ inputFieldData }: InputFieldProps) => {
	const { formatMessage } = useIntl();
	const [{ wasAddDesignButtonJustClicked }, { resetWasAddDesignButtonJustClicked }] =
		useDesignPanelStore();

	const ref = useRef<HTMLInputElement>();
	const data = useFragment(
		graphql`
			fragment inputField_issueAddDesignForm on DevOpsProviders {
				designProviders {
					id
					name
					supportedActions {
						checkAuth
						getEntityByUrl
					}
				}
			}
		`,
		inputFieldData,
	);

	// focus on input field once, on receiving button-just-clicked signal
	useEffect(() => {
		if (wasAddDesignButtonJustClicked) {
			// queue a low-priority executed-last macro task to secure focus
			callWhenIdle(() => {
				ref.current?.scrollIntoView?.(); // scrollIntoView doesn't exist on HTMLInputElement jest-dom
				ref.current?.focus();
				resetWasAddDesignButtonJustClicked();
			});
		}
	}, [wasAddDesignButtonJustClicked, resetWasAddDesignButtonJustClicked]);

	const associateEntitySupportedProviders =
		data?.designProviders?.filter(
			(provider) =>
				provider?.supportedActions?.getEntityByUrl && provider?.supportedActions?.checkAuth,
		) ?? [];
	const numberOfDesignProviders = associateEntitySupportedProviders.length;
	const providerId = associateEntitySupportedProviders?.[0]?.id;

	return (
		<Field
			aria-required
			name="designUrl"
			label={formatMessage(messages.designFormLabel, {
				providerCount: numberOfDesignProviders,
				singleProvider: data?.designProviders?.[0]?.name,
			})}
			defaultValue=""
			validate={(url) => validateDesignLink(url)}
		>
			{({ fieldProps, error }) => (
				<>
					<TextField
						testId="issue-add-design-form.ui.design-form.input-field.design-form-url-input"
						{...fieldProps}
						placeholder={
							numberOfDesignProviders === 1 && providerId
								? DesignAppConfig.find(({ providerDomain }) =>
										providerId.startsWith(providerDomain),
									)?.designLinkPlaceholder
								: ''
						}
						ref={ref}
					/>
					{error === DesignFormLinkState.INVALID_URL && (
						<ErrorMessage>
							{formatMessage(messages.matchProviderError, {
								providerCount: numberOfDesignProviders,
								singleProvider: data?.designProviders?.[0]?.name,
							})}
						</ErrorMessage>
					)}
				</>
			)}
		</Field>
	);
};

export default InputField;
