import { type MouseEvent, useCallback, useRef, useState } from 'react';
import type { InlineEditContext } from '../../common/types.tsx';
import type { InlineEditContainerReturn } from './types.tsx';

const DRAG_THRESHOLD = 5;

const isLinkOrButton = (element: HTMLElement): boolean =>
	element.closest('a') != null || element.closest('button') != null;

/**
 * Utility hook that returns props and stateful data to be used when rendering the read view container. These props will
 * allow the container element to be clicked to transition to the edit view and manage edit button focus states.
 */
export const useInlineEditReadViewContainer = ({
	onEdit,
}: InlineEditContext): InlineEditContainerReturn => {
	const startX = useRef(0);
	const startY = useRef(0);
	const [isEditButtonFocused, setEditButtonFocused] = useState(false);

	const mouseHasMovedAfterMouseDown = useCallback(
		(event: { clientX: number; clientY: number }) =>
			Math.abs(startX.current - event.clientX) >= DRAG_THRESHOLD ||
			Math.abs(startY.current - event.clientY) >= DRAG_THRESHOLD,
		[],
	);

	const onClick = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			const element = event.target;
			/** If a link or button is clicked in the read view, default action should be taken */
			if (
				element instanceof HTMLElement &&
				!isLinkOrButton(element) &&
				!mouseHasMovedAfterMouseDown(event)
			) {
				event.preventDefault();
				onEdit();
			}
		},
		[mouseHasMovedAfterMouseDown, onEdit],
	);

	const onMouseDown = useCallback((event: MouseEvent<HTMLElement>) => {
		startX.current = event.clientX;
		startY.current = event.clientY;
	}, []);

	const onEditButtonBlur = useCallback(() => setEditButtonFocused(false), []);
	const onEditButtonFocus = useCallback(() => setEditButtonFocused(true), []);

	return {
		isEditButtonFocused,
		onClick,
		onMouseDown,
		onEditButtonBlur,
		onEditButtonFocus,
	};
};
