import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import CloudImageComponent from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/clouds/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import cloud from './assets/cloud.svg';
import messages from './messages.tsx';

const NoAttachmentsWithCentralisedIllustrations = () => {
	const { formatMessage } = useIntl();
	return (
		<EmptyState
			header={formatMessage(messages.emptyStateTitle)}
			description={formatMessage(messages.emptyStateDescription)}
			renderImage={getAkEmptyStateRenderImageFn(CloudImageComponent)}
		/>
	);
};

const NoAttachmentsWithoutCentralisedIllustrations = () => {
	const { formatMessage } = useIntl();
	return (
		<EmptyState
			imageUrl={cloud}
			header={formatMessage(messages.emptyStateTitle)}
			description={formatMessage(messages.emptyStateDescription)}
		/>
	);
};

const NoAttachments = componentWithFG(
	'move_issue_view_assets_into_central_location',
	NoAttachmentsWithCentralisedIllustrations,
	NoAttachmentsWithoutCentralisedIllustrations,
);

export default NoAttachments;
