import type { ReactNode } from 'react';
import type { CreateUIAnalyticsEvent, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type {
	IssueLinkType,
	SimilarIssueLink,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { SaveRemoteIssueLinkPayload } from '@atlassian/jira-issue-shared-types/src/common/types/remote-issue-link-add-type.tsx';
import type {
	JiraAppLink,
	JiraAppLinkFetchStatus,
} from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { NewIssueLinks } from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { SelectItemType } from '../issue-links-type/index.tsx';
import type { SelectItemAppLink } from '../jira-app-links/types.tsx';
import type { IssueLink } from '../types.tsx';
import type { IssueLinkSuggestion } from './issue-link-server.tsx';

export type NewIssueLinksType = IssueLinkSuggestion & {
	tag?: {
		elemBefore: ReactNode;
	};
	__isNew__?: boolean;
};

type DispatchProps = {
	onAdd: (
		issueLinks: NewIssueLinks,
		requestAnalyticsEvent: UIAnalyticsEvent | null,
		maybeAnalyticEventOrPostAnalyticsEvent: UIAnalyticsEvent,
		onAddSuccess: (() => void) | undefined,
		onAddError: ((errorMessage?: string) => void) | undefined,
		analyticsEvent?: UIAnalyticsEvent,
	) => void;
	onAddRemote: (
		saveRemoteIssueLinkPayload: SaveRemoteIssueLinkPayload,
		requestAnalyticsEvent: UIAnalyticsEvent,
		onAddSuccess: (() => void) | undefined,
		onAddError: ((errorMessage?: string) => void) | undefined,
		analyticsEvent: UIAnalyticsEvent,
	) => void;
	// eslint-disable-next-line jira/react/handler-naming
	fetchJiraAppLinks: () => void;
	onClearRelatedIssueLink: () => void;
};

type StateProps = {
	canCreateLinkedIssue: boolean;
	canLinkRemoteIssues: boolean;
	quickAddClickedCount: number;
	quickAddClickedIssue?: SimilarIssueLink;
	isOpenedFromJsmSimilarIssues: boolean;
	jiraAppLinksFetchStatus: JiraAppLinkFetchStatus;
	jiraAppLinks: JiraAppLink[];
};

// other props injected through flowWithSafeComponent
type AdditionalProps = {
	intl: IntlShape;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	containerWidth: number | null;
	linkIssueRef?: HTMLElement;
};

export type ComponentProps = {
	issueKey: IssueKey;
	issueLinkTypes: IssueLinkType[];
	issueLinks: IssueLink | null;
	baseUrl: BaseUrl;
	onClose: () => void;
	onAddSuccess?: (() => void) | undefined;
	onAddError?: ((errorMessage?: string) => void) | undefined;
};

export type Props = DispatchProps & StateProps & AdditionalProps & ComponentProps;

export const SELECTION_INVALID = 'invalid';
export const SELECTION_UNAUTHENTICATED = 'unauthenticated';
export const SELECTION_SELF_LINK = 'self_link';
export const SELECTION_OTHER_APPLINK = 'other_applink';
export type InvalidSelection = {
	value: string;
	jiraAppLink?: JiraAppLink;
	reason:
		| typeof SELECTION_INVALID
		| typeof SELECTION_UNAUTHENTICATED
		| typeof SELECTION_SELF_LINK
		| typeof SELECTION_OTHER_APPLINK;
};

export type State = {
	newIssueLinks: NewIssueLinksType[];
	issueLinkType: SelectItemType;
	isDisabled: boolean;
	invalidSelections: string[];
	invalidSelectionsWithReason: InvalidSelection[];
	jiraAppLink: SelectItemAppLink;
	createReciprocalLink: boolean;
};

export type NewIssueLinkWrapper = {
	issueLink: NewIssueLinksType;
	appLink?: SelectItemAppLink | null;
	issueKey: string;
};
