import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const riskInsightsPanelExperience = new UFOExperience('jsm-risk-insights-panel', {
	performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	type: ExperienceTypes.Load,
});

export const summaryTabLoadExperience = new UFOExperience(
	'jsm-risk-insights-panel-summary-tab-load-experience',
	{
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
		type: ExperienceTypes.Load,
	},
);
export const changesTabLoadExperience = new UFOExperience(
	'jsm-risk-insights-panel-changes-tab-load-experience',
	{
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
		type: ExperienceTypes.Load,
	},
);
export const incidentsTabLoadExperience = new UFOExperience(
	'jsm-risk-insights-panel-incidents-tab-load-experience',
	{
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
		type: ExperienceTypes.Load,
	},
);
export const failuresTabLoadExperience = new UFOExperience(
	'jsm-risk-insights-panel-failures-tab-load-experience',
	{
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
		type: ExperienceTypes.Load,
	},
);
