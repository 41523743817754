import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import ThumbsUpIcon from '@atlaskit/icon/core/migration/thumbs-up--like';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { FIELD_KEY } from '@atlassian/jira-issue-field-voters/src/common/types.tsx';
import { IssueVoters } from '@atlassian/jira-issue-field-voters/src/main.tsx';
import { VotersListContainer } from '@atlassian/jira-issue-field-voters/src/services/voters-list-service/context.tsx';
import type { Vote as VoteValue } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import loadingWrapper from '@atlassian/jira-issue-view-common-utils/src/loading-component-wrapper/index.tsx';
import { VOTES } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { IssueViewVotesField } from '@atlassian/jira-issue-view-layout-votes-field/src/ui/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	errorSelector,
	isLoadingSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { fieldUpdated } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import type { voters_issueViewFoundation_VotersItemNew$key } from '@atlassian/jira-relay/src/__generated__/voters_issueViewFoundation_VotersItemNew.graphql';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import type { Props, StateProps } from './types.tsx';

const DisabledVoteIcon = () =>
	loadingWrapper(
		<VoteIconContainer>
			<ThumbsUpIcon
				LEGACY_size="medium"
				spacing="spacious"
				color={token('color.icon.disabled', colors.N30)}
				label=""
				testId="issue-view-foundation.header.voters.like-icon"
			/>
		</VoteIconContainer>,
	);

const VotersItemOld = ({ hasError, isIssueLoading, onFieldUpdated }: Props) => {
	const issueKey = useIssueKey();
	const loggedInAccountId = useAccountId();
	const [value] = useFieldValue({
		issueKey,
		fieldKey: FIELD_KEY,
	});

	if (hasError || !loggedInAccountId) {
		return null;
	}

	if (isIssueLoading || !value) {
		return <DisabledVoteIcon />;
	}

	return (
		<ErrorBoundary id="issue.details.vote-action">
			<VotersListContainer scope={issueKey}>
				<IssueVoters onFieldUpdated={onFieldUpdated} />
			</VotersListContainer>
		</ErrorBoundary>
	);
};

const VotersItemNew = ({
	hasError,
	isIssueLoading,
	onFieldUpdated,
	votesField,
}: Props & { votesField: voters_issueViewFoundation_VotersItemNew$key }) => {
	const issueKey = useIssueKey();
	const loggedInAccountId = useAccountId();
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<voters_issueViewFoundation_VotersItemNew$key>(
		graphql`
			fragment voters_issueViewFoundation_VotersItemNew on JiraVotesField {
				id
				...ui_issueViewLayoutVotesField_IssueViewVotesField
			}
		`,
		votesField,
	);

	if (hasError || !loggedInAccountId) {
		return null;
	}

	if (isIssueLoading || !data?.id) {
		return <DisabledVoteIcon />;
	}

	return (
		<ErrorBoundary id="issue.details.vote-action">
			<VotersListContainer scope={issueKey}>
				<IssueViewVotesField onFieldUpdated={onFieldUpdated} votesField={data} />
			</VotersListContainer>
		</ErrorBoundary>
	);
};

const VotersItem = componentWithCondition(
	() => ff('relay-migration-issue-fields-votes_nsbqd'),
	VotersItemNew,
	VotersItemOld,
);

export const Voters = connect(
	(state: State): StateProps => ({
		hasError: !!errorSelector(state),
		isIssueLoading: isLoadingSelector(state),
	}),
	(dispatch) => ({
		onFieldUpdated: (value: VoteValue) => {
			dispatch(fieldUpdated<VoteValue>(VOTES, value));
		},
	}),
)(VotersItem);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VoteIconContainer = styled.div({
	display: 'flex',
	padding: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
});
