/* eslint-disable react-hooks/rules-of-hooks */
import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
	type ReactNode,
} from 'react';
import noop from 'lodash/noop';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { inlineFieldConfigEntryPoint } from './inlineFieldConfigEntryPoint.tsx';

export type AddFieldConfigState = 'closed' | 'adding' | 'edit';

export type EditFieldConfigState = {
	mode: AddFieldConfigState;
	fieldId: string | null;
};

type EditFieldConfigContextValue = {
	editFieldConfigState: EditFieldConfigState;
	setEditFieldConfigState: (args: EditFieldConfigState) => void;
	onEditFieldConfigClick: (fieldId: string) => void;
	inlineFieldConfigEntryPointReferenceSubject: EntryPointReferenceSubject<
		typeof inlineFieldConfigEntryPoint
	> | null;
	inlineFieldConfigEntryPointActions: EntryPointActions | null;
};

export const EditFieldConfigContext = createContext<EditFieldConfigContextValue>({
	editFieldConfigState: {
		mode: 'closed',
		fieldId: null,
	},
	setEditFieldConfigState: noop,
	onEditFieldConfigClick: noop,
	inlineFieldConfigEntryPointReferenceSubject: null,
	inlineFieldConfigEntryPointActions: null,
});

export const ConditionalEditFieldConfigContextProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	if (!fg('issue_view_field_config_edit')) {
		return <>{children}</>;
	}

	const [editFieldConfigState, setEditFieldConfigState] = useState<{
		mode: AddFieldConfigState;
		fieldId: string | null;
	}>({
		mode: 'closed',
		fieldId: null,
	});

	const {
		entryPointReferenceSubject: inlineFieldConfigEntryPointReferenceSubject,
		entryPointActions: inlineFieldConfigEntryPointActions,
	} = useEntryPoint(
		inlineFieldConfigEntryPoint,
		useMemo(() => ({}), []),
	);

	const onEditFieldConfigClick = useCallback(
		(fieldId: string) => {
			inlineFieldConfigEntryPointActions.load();
			setEditFieldConfigState({
				mode: 'edit',
				fieldId,
			});
		},
		[inlineFieldConfigEntryPointActions],
	);

	return (
		<EditFieldConfigContext.Provider
			value={{
				editFieldConfigState,
				setEditFieldConfigState,
				onEditFieldConfigClick,
				inlineFieldConfigEntryPointReferenceSubject,
				inlineFieldConfigEntryPointActions,
			}}
		>
			{children}
		</EditFieldConfigContext.Provider>
	);
};

export const useEditFieldConfigContext = () => useContext(EditFieldConfigContext);
