import type { MessageDescriptor } from 'react-intl-next';

import type { TransformAction } from '../../pm-plugins/ai-proactive/api';

import messages from './messages';

export type TransformActionDescriptors = {
	description: MessageDescriptor;
	title: MessageDescriptor;
};

const defaultDetails: TransformActionDescriptors = {
	title: messages.recommendationDefaultTitle,
	description: messages.recommendationDefaultDescription,
};

const detailsByConversionType: Record<TransformAction, TransformActionDescriptors> = {
	CONVERT_TO_TABLE: {
		title: messages.recommendationConvertToTableTitle,
		description: messages.recommendationConvertToTableDescription,
	},
	CONVERT_TO_NOTE_PANEL: {
		title: messages.recommendationConvertToNotePanelTitle,
		description: messages.recommendationConvertToNotePanelDescription,
	},
	CONVERT_TO_INFO_PANEL: {
		title: messages.recommendationConvertToInfoPanelTitle,
		description: messages.recommendationConvertToInfoPanelDescription,
	},
	CONVERT_TO_WARN_PANEL: {
		title: messages.recommendationConvertToWarnPanelTitle,
		description: messages.recommendationConvertToWarnPanelDescription,
	},
	CONVERT_TO_ERROR_PANEL: {
		title: messages.recommendationConvertToErrorPanelTitle,
		description: messages.recommendationConvertToErrorPanelDescription,
	},
	CONVERT_TO_SUCCESS_PANEL: {
		title: messages.recommendationConvertToSuccessPanelTitle,
		description: messages.recommendationConvertToSuccessPanelDescription,
	},
};

export const getTransformActionDescriptors = (
	action: TransformAction,
): TransformActionDescriptors => detailsByConversionType[action] ?? defaultDetails;
