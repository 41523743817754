import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import type {
	IssueLinks,
	IssueLinkDirection,
	IssueLinkType,
	NormalizedIssueLinks,
	NormalizedLinkedIssues,
	SimilarIssueLink,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

// FETCH_LINKED_ISSUES_SUCCESS
export const FETCH_LINKED_ISSUES_SUCCESS = 'FETCH_LINKED_ISSUES_SUCCESS' as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchLinkedIssuesSuccess = (issuesMap: any) => ({
	type: FETCH_LINKED_ISSUES_SUCCESS,
	payload: issuesMap,
});

export type FetchLinkedIssuesSuccess = ReturnType<typeof fetchLinkedIssuesSuccess>;

// FETCH_LINKED_ISSUES_FAILURE
export const FETCH_LINKED_ISSUES_FAILURE = 'FETCH_LINKED_ISSUES_FAILURE' as const;

export const fetchLinkedIssuesFailure = (err: Error) => ({
	type: FETCH_LINKED_ISSUES_FAILURE,
	payload: err,
});

export type FetchLinkedIssuesFailure = ReturnType<typeof fetchLinkedIssuesFailure>;

// DELETE_LINKED_ISSUE_REQUEST
export const DELETE_LINKED_ISSUE_REQUEST = 'DELETE_LINKED_ISSUE_REQUEST' as const;
export const DELETE_LINKED_ISSUE_REQUEST_WITHOUT_FETCH =
	'DELETE_LINKED_ISSUE_REQUEST_WITHOUT_FETCH' as const;

export const deleteLinkedIssueRequest = (
	linkedIssueId: string,
	application: Application | undefined,
	edition: ApplicationEdition | undefined,
	analyticsEvent?: AnalyticsEvent,
) => ({
	type: DELETE_LINKED_ISSUE_REQUEST,
	payload: {
		linkedIssueId,
		analyticsEvent,
		application,
		edition,
	},
});
export const deleteLinkedIssueRequestWithoutFetch = (
	linkedIssueId: string,
	application: Application | undefined,
	edition: ApplicationEdition | undefined,
	analyticsEvent?: AnalyticsEvent,
) => ({
	type: DELETE_LINKED_ISSUE_REQUEST_WITHOUT_FETCH,
	payload: {
		linkedIssueId,
		analyticsEvent,
		application,
		edition,
	},
});

export type DeleteLinkedIssueRequest = ReturnType<typeof deleteLinkedIssueRequest>;
export type DeleteLinkedIssueRequestWithoutFetch = ReturnType<
	typeof deleteLinkedIssueRequestWithoutFetch
>;

// DELETE_LINKED_ISSUE_SUCCESS
export const DELETE_LINKED_ISSUE_SUCCESS = 'DELETE_LINKED_ISSUE_SUCCESS' as const;

export const deleteLinkedIssueSuccess = (
	linkedIssueId: string,
	meta: {
		issueId: IssueId;
		direction: IssueLinkDirection;
	},
) => ({
	type: DELETE_LINKED_ISSUE_SUCCESS,
	payload: linkedIssueId,
	meta,
});

export type DeleteLinkedIssueSuccess = ReturnType<typeof deleteLinkedIssueSuccess>;

// DELETE_LINKED_ISSUE_FAILURE
export const DELETE_LINKED_ISSUE_FAILURE = 'DELETE_LINKED_ISSUE_FAILURE' as const;

export const deleteLinkedIssueFailure = (linkedIssueId: string) => ({
	type: DELETE_LINKED_ISSUE_FAILURE,
	payload: linkedIssueId,
});

export type DeleteLinkedIssueFailure = ReturnType<typeof deleteLinkedIssueFailure>;

export const DELETE_LINKED_ISSUE_FAILURE_NO_FLAG = 'DELETE_LINKED_ISSUE_FAILURE_NO_FLAG' as const;

export const deleteLinkedIssueFailureNoFlags = (linkedIssueId: string) => ({
	type: DELETE_LINKED_ISSUE_FAILURE_NO_FLAG,
	payload: linkedIssueId,
});

export type DeleteLinkedIssueFailureNoFlags = ReturnType<typeof deleteLinkedIssueFailureNoFlags>;

// SAVE_LINKED_ISSUE_REQUEST
export const SAVE_LINKED_ISSUE_REQUEST = 'SAVE_LINKED_ISSUE_REQUEST' as const;
export const SAVE_LINKED_ISSUE_REQUEST_WITHOUT_FETCH =
	'SAVE_LINKED_ISSUE_REQUEST_WITHOUT_FETCH' as const;

export type NewIssueLinks = {
	optimisticId: string;
	linkedIssueKey: string;
	issueLinkType: IssueLinkType;
	issueLinks: IssueLinks;
	isOpenedFromJsmSimilarIssues: boolean;
};

export const saveLinkedIssueRequest = (
	newIssueLinks: NewIssueLinks,
	requestAnalyticsEvent: AnalyticsEvent | null,
	analyticsEvent: AnalyticsEvent,
	onSuccess: (() => void) | undefined,
	onError: ((errorMessage?: string) => void) | undefined,
	postAnalyticsEvent?: AnalyticsEvent,
) => ({
	type: SAVE_LINKED_ISSUE_REQUEST,
	payload: {
		newIssueLinks,
		analyticsEvent,
		requestAnalyticsEvent,
		postAnalyticsEvent,
		onSuccess,
		onError,
	},
});

export const saveLinkedIssueRequestWithoutFetch = (newIssueLinks: NewIssueLinks) => ({
	type: SAVE_LINKED_ISSUE_REQUEST_WITHOUT_FETCH,
	payload: {
		newIssueLinks,
	},
});

export type SaveLinkedIssueRequest = ReturnType<typeof saveLinkedIssueRequest>;
export type SaveLinkedIssueRequestWithoutFetch = ReturnType<
	typeof saveLinkedIssueRequestWithoutFetch
>;

// SAVE_LINKED_ISSUE_SUCCESS
export const SAVE_LINKED_ISSUE_SUCCESS = 'SAVE_LINKED_ISSUE_SUCCESS' as const;

export const saveLinkedIssueSuccess = (
	optimisticId: string,
	analyticsEvent: AnalyticsEvent,
	issueKey?: string,
	postAnalyticsEvent?: AnalyticsEvent,
) => ({
	type: SAVE_LINKED_ISSUE_SUCCESS,
	payload: { optimisticId, issueKey, analyticsEvent, postAnalyticsEvent },
});

export type SaveLinkedIssueSuccess = ReturnType<typeof saveLinkedIssueSuccess>;

// SAVE_LINKED_ISSUE_FAILURE
export const SAVE_LINKED_ISSUE_FAILURE = 'SAVE_LINKED_ISSUE_FAILURE' as const;

export const saveLinkedIssueFailure = (
	optimisticId: string,
	error: string,
	analyticsEvent?: AnalyticsEvent,
) => ({
	type: SAVE_LINKED_ISSUE_FAILURE,
	payload: { optimisticId, error, analyticsEvent },
});

export type SaveLinkedIssueFailure = ReturnType<typeof saveLinkedIssueFailure>;

// SAVE_LINKED_ISSUE_RETRY
export const SAVE_LINKED_ISSUE_RETRY = 'SAVE_LINKED_ISSUE_RETRY' as const;

export const saveLinkedIssueRetry = (
	optimisticId: string,
	requestAnalyticsEvent: AnalyticsEvent,
	analyticsEvent: AnalyticsEvent,
	onSuccess: (() => void) | undefined,
	onError: ((errorMessage?: string) => void) | undefined,
	postAnalyticsEvent?: AnalyticsEvent,
) => ({
	type: SAVE_LINKED_ISSUE_RETRY,
	payload: {
		optimisticId,
		analyticsEvent,
		requestAnalyticsEvent,
		postAnalyticsEvent,
		onSuccess,
		onError,
	},
});

export const saveLinkedIssueRetryOld = (optimisticId: string) => ({
	type: SAVE_LINKED_ISSUE_RETRY,
	payload: { optimisticId },
});

export type SaveLinkedIssueRetry = ReturnType<typeof saveLinkedIssueRetry>;

// SAVE_LINKED_ISSUE_CANCEL
export const SAVE_LINKED_ISSUE_CANCEL = 'SAVE_LINKED_ISSUE_CANCEL' as const;

export const saveLinkedIssueCancel = (optimisticId: string) => ({
	type: SAVE_LINKED_ISSUE_CANCEL,
	payload: optimisticId,
});

export type SaveLinkedIssueCancel = ReturnType<typeof saveLinkedIssueCancel>;

// FETCH_LINKED_ISSUES_DATA_SUCCESS
export const FETCH_LINKED_ISSUES_DATA_SUCCESS = 'FETCH_LINKED_ISSUES_DATA_SUCCESS' as const;

export const fetchLinkedIssuesDataSuccess = (
	issueLinks: IssueLinks,
	linkedIssues: NormalizedLinkedIssues,
	optimisticIds: string[],
	optimisticLinks: NormalizedIssueLinks,
) => ({
	type: FETCH_LINKED_ISSUES_DATA_SUCCESS,
	payload: { issueLinks, linkedIssues, optimisticIds, optimisticLinks },
});

export type FetchLinkedIssuesDataSuccess = ReturnType<typeof fetchLinkedIssuesDataSuccess>;

// FETCH_LINKED_ISSUES_DATA_FAILURE
export const FETCH_LINKED_ISSUES_DATA_FAILURE = 'FETCH_LINKED_ISSUES_DATA_FAILURE' as const;

export const fetchLinkedIssuesDataFailure = (optimisticIds: string[], error: Error) => ({
	type: FETCH_LINKED_ISSUES_DATA_FAILURE,
	payload: { optimisticIds, error },
});

export type FetchLinkedIssuesDataFailure = ReturnType<typeof fetchLinkedIssuesDataFailure>;

// ADD_ISSUE_LINK_CLICKED
export const ADD_ISSUE_LINK_CLICKED = 'ADD_ISSUE_LINK_CLICKED' as const;

export const addIssueLinkClick = (linkedIssue?: SimilarIssueLink) => ({
	type: ADD_ISSUE_LINK_CLICKED,
	payload: { linkedIssue, isOpenedFromJsmSimilarIssues: !!linkedIssue },
});

export type AddIssueLinkClick = ReturnType<typeof addIssueLinkClick>;

// AI_LINKED_ISSUE_SUGGESTION
export const AI_LINKED_ISSUE_SUGGESTION = 'AI_LINKED_ISSUE_SUGGESTION' as const;

export const onAILinkedIssueSuggestion = (isOpen: boolean) => ({
	type: AI_LINKED_ISSUE_SUGGESTION,
	payload: { isOpen },
});

export type OnAILinkedIssueSuggestion = ReturnType<typeof onAILinkedIssueSuggestion>;

// CLEAR_RELATED_ISSUE_LINK
export const CLEAR_RELATED_ISSUE_LINK = 'CLEAR_RELATED_ISSUE_LINK' as const;

export const clearRelatedIssueLink = () => ({ type: CLEAR_RELATED_ISSUE_LINK });

export type ClearRelatedIssueLink = ReturnType<typeof clearRelatedIssueLink>;

// ADD_ISSUE_LINK_CLOSED
export const ADD_ISSUE_LINK_CLOSED = 'ADD_ISSUE_LINK_CLOSED' as const;

export const closeAddIssueLink = () => ({ type: ADD_ISSUE_LINK_CLOSED });

export type CloseAddIssueLink = ReturnType<typeof closeAddIssueLink>;

// FETCH_CREATE_LINKED_ISSUE_PERMISSION_REQUEST
export const FETCH_CREATE_LINKED_ISSUE_PERMISSION_REQUEST =
	'FETCH_CREATE_LINKED_ISSUE_PERMISSION_REQUEST' as const;

export const fetchCreateLinkedIssuePermissionRequest = () => ({
	type: FETCH_CREATE_LINKED_ISSUE_PERMISSION_REQUEST,
});

export type FetchCreateLinkedIssuePermissionRequest = ReturnType<
	typeof fetchCreateLinkedIssuePermissionRequest
>;

// SET_CREATE_LINKED_ISSUE_PERMISSION
export const SET_CREATE_LINKED_ISSUE_PERMISSION = 'SET_CREATE_LINKED_ISSUE_PERMISSION' as const;

export const setCreateLinkedIssuePermission = (canCreateLinkedIssue: boolean) => ({
	type: SET_CREATE_LINKED_ISSUE_PERMISSION,
	payload: {
		canCreateLinkedIssue,
	},
});

export type SetCreateLinkedIssuePermission = ReturnType<typeof setCreateLinkedIssuePermission>;

// CREATE_LINKED_ISSUE_SUCCESS
export const CREATE_LINKED_ISSUE_SUCCESS = 'CREATE_LINKED_ISSUE_SUCCESS' as const;

export const createLinkedIssueSuccess = (newIssueLinks: IssueLinks) => ({
	type: CREATE_LINKED_ISSUE_SUCCESS,
	payload: {
		newIssueLinks,
	},
});

export type CreateLinkedIssueSuccess = ReturnType<typeof createLinkedIssueSuccess>;

export type IssueLinksAction =
	| FetchLinkedIssuesSuccess
	| FetchLinkedIssuesFailure
	| DeleteLinkedIssueRequest
	| DeleteLinkedIssueFailure
	| DeleteLinkedIssueSuccess
	| SaveLinkedIssueRequest
	| SaveLinkedIssueSuccess
	| SaveLinkedIssueFailure
	| SaveLinkedIssueRetry
	| SaveLinkedIssueCancel
	| FetchLinkedIssuesDataSuccess
	| FetchLinkedIssuesDataFailure
	| FetchCreateLinkedIssuePermissionRequest
	| SetCreateLinkedIssuePermission
	| CreateLinkedIssueSuccess
	| AddIssueLinkClick
	| CloseAddIssueLink
	| DeleteLinkedIssueFailureNoFlags;
