/** @jsx jsx */
import React, { useState, type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

type Props = {
	popup?: {
		renderContent: () => ReactNode;
	};
	children: ReactNode;
};

export const WithPopup = ({ popup, children }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const onClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const element = event.target as HTMLElement;
		/** If a link is clicked in the read view, default action should be taken */
		if (element.tagName.toLowerCase() !== 'a') {
			event.preventDefault();
			setIsOpen(!isOpen);
		}
	};

	return popup?.renderContent ? (
		<Popup
			placement="top"
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			content={popup.renderContent}
			trigger={(triggerProps) => (
				<Box xcss={hoverBoxStyles}>
					<button {...triggerProps} css={editButtonStyles} type="button" onClick={onClick}>
						{children}
					</button>
				</Box>
			)}
		/>
	) : (
		<>{children}</>
	);
};

const editButtonStyles = css({
	margin: token('space.0', '0px'),
	padding: token('space.0', '0px'),
	appearance: 'none',
	background: 'transparent',
	lineHeight: 1,
	textAlign: 'inherit',
	width: '100%',
	outline: '0',
	border: '0',
	borderRadius: 'border.radius',
});

const hoverBoxStyles = xcss({
	borderRadius: 'border.radius',
	transition: 'background 0.2s',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});
