import React, { useCallback, forwardRef, useEffect } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
// eslint-disable-next-line jira/restricted/@atlaskit+flag
import Flag from '@atlaskit/flag';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { Y200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fromFlagId, useFlagsService, toFlagId } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	useChildIssuesLimit,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { CHILD_ISSUES_LIMIT_BEST_PRACTICE_FLAG_DISMISSED } from '@atlassian/jira-issue-fetch-services-common/src/types.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import {
	type ChildIssuesLimitStatus,
	DISMISSED,
	IN_PRODUCT_HELP,
	HREF,
} from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';
import { TIME_TRACKING } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import {
	fireUIAnalytics,
	FireScreenAnalytics,
	ContextualAnalyticsData,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { hasExceededIssuesLimitInitialLoad } from '../../common/util.tsx';
import { useChildIssuesLimitHelp } from '../../services/use-child-issues-limit-help/index.tsx';
import {
	ButtonLinkWithShortcutIcon,
	type ButtonLinkWithShortcutIconProps,
} from '../button-link-with-shortcut-icon/index.tsx';
import messages from './messages.tsx';

export const TEST_ID = 'issue.views.common.child-issues-panel.child-issues-limit-flag';
const LEARN_MORE_LINK_TEST_ID = `${TEST_ID}.learn-more-link`;

export const FlagActionLinkComponent = forwardRef<HTMLElement, ButtonLinkWithShortcutIconProps>(
	(props, ref) => (
		// @ts-expect-error - TS2322 - Type '((instance: ButtonLinkWithShortcutIconProps | null) => void) | MutableRefObject<ButtonLinkWithShortcutIconProps | null> | null' is not assignable to type '{ current: HTMLElement | null; } | ((arg1: HTMLElement | null) => unknown) | undefined'.
		<ButtonLinkWithShortcutIcon {...props} innerRef={ref} testId={LEARN_MORE_LINK_TEST_ID} />
	),
);

type Props = {
	isApproachingLimit?: boolean;
	childIssuesLimitStatus: ChildIssuesLimitStatus;
};

export const FLAG_ID = toFlagId('child-issues-limit-flag');
const ANALYTICS_SOURCE_NAME = 'childIssuesLimitFlag';

export const ChildIssuesLimitFlag = ({
	isApproachingLimit = false,
	childIssuesLimitStatus,
}: Props) => {
	const { formatMessage } = useIntl();
	const childIssuesLimitHelp = useChildIssuesLimitHelp();
	const { showFlag, dismissFlag, hasFlag } = useFlagsService();

	const onLearnMoreLinkClick = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type.
		(_, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'learnMore');
			if (childIssuesLimitHelp.type === IN_PRODUCT_HELP) {
				childIssuesLimitHelp.openInProductHelp();
			}
		},
		[childIssuesLimitHelp],
	);

	// Ensure the flag is no longer displayed after the user dismisses it the first time
	const [isDismissed, { setValue }] = useUserPreferencesValue(
		CHILD_ISSUES_LIMIT_BEST_PRACTICE_FLAG_DISMISSED,
	);

	const onDismissed = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'close');
			setValue(DISMISSED);
			dismissFlag(FLAG_ID);
		},
		[dismissFlag, setValue],
	);

	const issueKey = useIssueKey();
	const [{ value: fieldConfig, loading }] = useFieldConfig(issueKey, TIME_TRACKING);
	const hasTimetracking = Boolean(fieldConfig);

	const childIssuesLimit = useChildIssuesLimit();

	const withTimeTrackingDescription = formatMessage(messages.withTimetrackingDescription, {
		limit: childIssuesLimit,
	});
	const withoutTimeTrackingDescription = formatMessage(messages.withoutTimetrackingDescription, {
		limit: childIssuesLimit,
	});
	const title = formatMessage(messages.title, {
		limit: childIssuesLimit,
	});

	const description = hasTimetracking
		? withTimeTrackingDescription
		: withoutTimeTrackingDescription;

	useEffect(() => {
		if (
			!loading &&
			// isDismissed is null when users have not dismissed the onboarding
			// it will be set to `dismissed` when the user dismisses via the X button.
			isDismissed !== DISMISSED &&
			!hasFlag?.(FLAG_ID) &&
			(isApproachingLimit || hasExceededIssuesLimitInitialLoad(childIssuesLimitStatus))
		) {
			showFlag({
				id: FLAG_ID,
				render: () => (
					<ContextualAnalyticsData sourceName={ANALYTICS_SOURCE_NAME} sourceType={SCREEN}>
						<FireScreenAnalytics actionSubjectId={ANALYTICS_SOURCE_NAME} />
						<Flag
							id={fromFlagId(FLAG_ID)}
							icon={
								<WarningIcon
									color={token('color.icon.warning', Y200)}
									LEGACY_primaryColor={token('color.icon.warning', Y200)}
									label=""
								/>
							}
							title={title}
							description={description}
							onDismissed={(_, analyticsEvent) => {
								onDismissed(analyticsEvent);
							}}
							// @ts-expect-error - TS2322 - Type 'ForwardRefExoticComponent<HTMLElement & RefAttributes<ButtonLinkWithShortcutIconProps>>' is not assignable to type 'ComponentType<CustomThemeButtonProps> | undefined'.
							linkComponent={FlagActionLinkComponent}
							actions={[
								{
									content: formatMessage(messages.learnMoreLink),
									...(childIssuesLimitHelp.type === HREF
										? {
												href: childIssuesLimitHelp.href,
												onClick: onLearnMoreLinkClick,
												target: '_blank',
											}
										: {
												onClick: onLearnMoreLinkClick,
											}),
								},
							]}
							testId={TEST_ID}
						/>
					</ContextualAnalyticsData>
				),
			});
		}
	}, [
		childIssuesLimitHelp.href,
		childIssuesLimitHelp.type,
		childIssuesLimitStatus,
		description,
		formatMessage,
		hasFlag,
		isApproachingLimit,
		isDismissed,
		loading,
		onDismissed,
		onLearnMoreLinkClick,
		showFlag,
		title,
	]);

	return null;
};
ChildIssuesLimitFlag.displayName = 'ChildIssuesLimitFlag';
