import {
	AGILITY_BACKLOG,
	BUSINESS_LIST,
	FULL_ISSUE,
	GLOBAL_ISSUE_NAVIGATOR_ISSUE,
	ISSUE_NAVIGATOR,
	SIMPLE_SOFTWARE_BOARD,
	SOFTWARE_BACKLOG_ISSUE,
	SOFTWARE_ISSUE,
} from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { LOOKUP_SOURCE_USERS_ASYNC_DATA } from '@atlassian/jira-issue-adjustments/src/common/constants.tsx';
import type { FieldType } from '@atlassian/jira-issue-adjustments/src/common/types/field.tsx';
import type {
	AnalyticsResult,
	ViewConfiguration,
} from '@atlassian/jira-issue-adjustments/src/common/types/index.tsx';
import {
	ASSIGNEE_TYPE,
	DESCRIPTION_TYPE,
	FIX_VERSIONS_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	NUMBER_CF_TYPE,
	PRIORITY_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	SELECT_CF_TYPE,
	SUMMARY_TYPE,
	TEXT_AREA_CF_TYPE,
	TEXT_CF_TYPE,
	URL_CF_TYPE,
	LABELS_TYPE,
	DATE_CF_TYPE,
	DATETIME_CF_TYPE,
	USER_CF_TYPE,
	REPORTER_TYPE,
	MULTI_USER_CF_TYPE,
	COMPONENTS_TYPE,
	PARENT_TYPE,
	CASCADING_SELECT_CF_TYPE,
	PEOPLE_CF_TYPE,
	AFFECTS_VERSIONS_TYPE,
	STATUS_TYPE,
	TIME_ESTIMATE_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	cancelTTI,
	markFieldsRegistered,
	markIframeLoad,
	markOnInitResolved,
	stopTTI,
} from '@atlassian/jira-ui-modifications-analytics/src/common/utils/performance-analytics/issue-view.tsx';
import {
	assigneeGetValueTransformer,
	assigneeSetValueTransformer,
	assigneeSetValueValidator,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/assignee.tsx';
import { validateCascadingSelectSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/cascading-select.tsx';
import { validateComponentsSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/components.tsx';
import {
	datePickerGetValueTransformer,
	datePickerSetValueValidator,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/date-picker.tsx';
import {
	validateDateTimePickerSetValue,
	transformDateTimePickerGetValue,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/date-time-picker.tsx';
import { validateMultiCheckboxSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/multi-checkbox.tsx';
import { validateMultiSelectSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/multi-select.tsx';
import { validateMultiUserPickerSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/multi-user-picker.tsx';
import { validateNumberValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/number.tsx';
import {
	validateOriginalEstimate,
	transformGetValueOriginalEstimate,
	transformSetValueOriginalEstimate,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/original-estimate.tsx';
import { parentSetValueValidator } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/parent.tsx';
import { validatePeopleSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/people.tsx';
import { validatePrioritySetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/priority.tsx';
import { radioButtonsSetValueValidator } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/radio-buttons.tsx';
import { reporterSetValueValidator } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/reporter.tsx';
import { selectWithAriSetValueValidator } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/select.tsx';
import {
	transformSingleLineTextToPublicValue,
	validateSingleLineTextValue,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/single-line-text.tsx';
import { userPickerSetValueValidator } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/user-picker.tsx';
import { validateVersionValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/versions.tsx';
import { getCommonFieldValidators } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/utils/get-common-field-validators/index.tsx';
import {
	transformSelectGetOptionsVisibility,
	transformSelectSetOptionsVisibility,
	transformSetOptionsVisibilityAriIssueView,
	transformSetOptionsVisibilityForFrontendFilteringWithARIs,
	validateSetOptionsVisibility,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/utils/options-visibility/index.tsx';
import { assigneeIssueViewScopes } from './fields/assignee.tsx';
import {
	transformSetValueCascadingSelect,
	transformGetValueCascadingSelect,
	cascadingSelectIssueViewScopes,
} from './fields/cascading-select.tsx';
import {
	transformComponentsGetValue,
	transformComponentsSetValue,
	componentsIssueViewScopes,
} from './fields/components.tsx';
import { datePickerIssueViewScopes } from './fields/date-picker.tsx';
import { dateTimePickerIssueViewScopes } from './fields/date-time-picker.tsx';
import {
	descriptionIssueViewScopes,
	transformRichTextToPublicValue,
	validateRichText,
} from './fields/description.tsx';
import { labelsRequiredScopes, validateLabelsSetValue } from './fields/labels.tsx';
import {
	multiCheckboxGetValueTransformer,
	multiCheckboxScopes,
	multiCheckboxSetValueTransformer,
} from './fields/multi-checkbox.tsx';
import {
	multiSelectScopes,
	transformMultiSelectGetValue,
	transformMultiSelectSetValue,
} from './fields/multi-select.tsx';
import {
	transformMultiUserPickerGetValue,
	transformMultiUserPickerSetValue,
} from './fields/multi-user-picker.tsx';
import { numberIssueViewScopes } from './fields/number.tsx';
import { originalEstimateIssueViewScopes } from './fields/original-estimate.tsx';
import {
	paragraphGetValueTransformer,
	paragraphSetValueTransformer,
	validateParagraphSetValue,
} from './fields/paragraph.tsx';
import {
	transformParentGetValue,
	transformParentSetValue,
	parentIssueViewScopes,
} from './fields/parent.tsx';
import {
	peopleIssueViewScopes,
	transformPeopleGetValue,
	transformPeopleSetValue,
} from './fields/people.tsx';
import {
	priorityIssueViewScopes,
	transformPriorityGetValue,
	transformPrioritySetValue,
	transformSetOptionsVisibilityAriPriority,
} from './fields/priority.tsx';
import {
	radioButtonsGetValueTransformer,
	radioButtonsIssueViewScopes,
	radioButtonsSetValueTransformer,
} from './fields/radio-buttons.tsx';
import {
	selectGetValueTransformer,
	selectIssueViewScopes,
	selectSetValueTransformer,
} from './fields/select.tsx';
import { singleLineTextIssueViewScopes } from './fields/single-line-text.tsx';
import {
	statusIssueViewScopes,
	transformStatusGetValue,
	transformStatusSetValue,
	validateStatusSetValue,
} from './fields/status.tsx';
import { summaryIssueViewScopes } from './fields/summary.tsx';
import { urlIssueViewScopes } from './fields/url.tsx';
import {
	versionsRequiredScopes,
	transformVersionToInternalValue,
	transformVersionToPublicValue,
} from './fields/versions.tsx';
import messages from './messages.tsx';

export class IssueViewViewConfiguration implements ViewConfiguration {
	private analytics: AnalyticsResult = {
		stopTTI,
		cancelTTI,
		markIframeLoad,
		markFieldsRegistered,
		markOnInitResolved,
	};

	getSupportedFieldsConfiguration() {
		const commonFieldValidators = getCommonFieldValidators();

		return {
			[SUMMARY_TYPE]: {
				publicShapeValidators: {
					isReadOnly: commonFieldValidators.isReadOnly,
					value: validateSingleLineTextValue,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: transformSingleLineTextToPublicValue,
				},
				/**
				 * On the Issue View cannot differentiate between change and blur since only change is being observed.
				 * 'change' on the Issue View means e.g. that user "blurs" from a text field or change the selection in a select field.
				 */
				emitChangeOn: 'change',
				requiredScopes: summaryIssueViewScopes,
			},
			[DESCRIPTION_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateRichText,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: transformRichTextToPublicValue,
				},
				emitChangeOn: 'change',
				requiredScopes: descriptionIssueViewScopes,
			},
			[ASSIGNEE_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: assigneeSetValueValidator,
				},
				publicToInternalTransformers: {
					value: assigneeSetValueTransformer,
				},
				internalToPublicTransformers: {
					value: assigneeGetValueTransformer,
				},
				emitChangeOn: 'change',
				lookupSource: LOOKUP_SOURCE_USERS_ASYNC_DATA,
				requiredScopes: assigneeIssueViewScopes,
			},
			[PRIORITY_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validatePrioritySetValue,
					optionsVisibility: validateSetOptionsVisibility,
				},
				publicToInternalTransformers: {
					value: transformPrioritySetValue,
					optionsVisibility: ff('relay-migration-issue-fields-priority_ri9vi')
						? transformSetOptionsVisibilityAriPriority
						: transformSelectSetOptionsVisibility,
				},
				internalToPublicTransformers: {
					value: transformPriorityGetValue,
					optionsVisibility: transformSelectGetOptionsVisibility,
				},
				emitChangeOn: 'change',
				requiredScopes: priorityIssueViewScopes,
				propertyMapping: {
					optionsVisibility: ff('relay-migration-issue-fields-priority_ri9vi')
						? 'fieldOptionsFilter'
						: 'allowedValuesOverrides',
				},
			},
			[TEXT_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateSingleLineTextValue,
					description: commonFieldValidators.description,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: transformSingleLineTextToPublicValue,
				},
				emitChangeOn: 'change',
				requiredScopes: singleLineTextIssueViewScopes,
			},
			[URL_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateSingleLineTextValue,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: transformSingleLineTextToPublicValue,
				},
				emitChangeOn: 'change',
				requiredScopes: urlIssueViewScopes,
			},
			[TEXT_AREA_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateParagraphSetValue,
					description: commonFieldValidators.description,
				},
				publicToInternalTransformers: {
					value: paragraphSetValueTransformer,
				},
				internalToPublicTransformers: {
					value: paragraphGetValueTransformer,
				},
				emitChangeOn: 'change',
				requiredScopes: descriptionIssueViewScopes,
			},
			[MULTI_SELECT_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateMultiSelectSetValue,
					optionsVisibility: validateSetOptionsVisibility,
				},
				publicToInternalTransformers: {
					value: transformMultiSelectSetValue,
					optionsVisibility: transformSetOptionsVisibilityAriIssueView,
				},
				internalToPublicTransformers: {
					value: transformMultiSelectGetValue,
					optionsVisibility: transformSelectGetOptionsVisibility,
				},
				emitChangeOn: 'change',
				requiredScopes: multiSelectScopes,
				propertyMapping: {
					optionsVisibility: 'fieldOptionsFilter',
				},
			},
			[SELECT_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: selectWithAriSetValueValidator,
					description: commonFieldValidators.description,
					optionsVisibility: validateSetOptionsVisibility,
				},
				publicToInternalTransformers: {
					value: selectSetValueTransformer,
					optionsVisibility: transformSetOptionsVisibilityAriIssueView,
				},
				internalToPublicTransformers: {
					value: selectGetValueTransformer,
					optionsVisibility: transformSelectGetOptionsVisibility,
				},
				emitChangeOn: 'change',
				requiredScopes: selectIssueViewScopes,
				propertyMapping: {
					optionsVisibility: 'fieldOptionsFilter',
				},
			},
			[MULTI_CHECKBOXES_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateMultiCheckboxSetValue,
					optionsVisibility: validateSetOptionsVisibility,
				},
				publicToInternalTransformers: {
					value: multiCheckboxSetValueTransformer,
					optionsVisibility: ff('relay-migration-issue-fields-checkbox-select_ytl5n')
						? transformSetOptionsVisibilityForFrontendFilteringWithARIs
						: transformSelectSetOptionsVisibility,
				},
				internalToPublicTransformers: {
					value: multiCheckboxGetValueTransformer,
					optionsVisibility: transformSelectGetOptionsVisibility,
				},
				emitChangeOn: 'change',
				requiredScopes: multiCheckboxScopes,
				propertyMapping: {
					optionsVisibility: 'allowedValuesOverrides',
				},
			},
			[RADIO_BUTTONS_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					description: commonFieldValidators.description,
					value: radioButtonsSetValueValidator,
					optionsVisibility: validateSetOptionsVisibility,
				},
				publicToInternalTransformers: {
					value: radioButtonsSetValueTransformer,
					optionsVisibility: ff('relay-migration-issue-fields-radio_6gqb3')
						? transformSetOptionsVisibilityForFrontendFilteringWithARIs
						: transformSelectSetOptionsVisibility,
				},
				internalToPublicTransformers: {
					value: radioButtonsGetValueTransformer,
					optionsVisibility: transformSelectGetOptionsVisibility,
				},
				emitChangeOn: 'change',
				requiredScopes: radioButtonsIssueViewScopes,
				propertyMapping: {
					optionsVisibility: 'allowedValuesOverrides',
				},
			},
			[FIX_VERSIONS_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					description: commonFieldValidators.description,
					value: validateVersionValue,
				},
				publicToInternalTransformers: {
					value: transformVersionToInternalValue,
				},
				internalToPublicTransformers: {
					value: transformVersionToPublicValue,
				},
				emitChangeOn: 'change',
				requiredScopes: versionsRequiredScopes,
			},
			[AFFECTS_VERSIONS_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					description: commonFieldValidators.description,
					value: validateVersionValue,
				},
				publicToInternalTransformers: {
					value: transformVersionToInternalValue,
				},
				internalToPublicTransformers: {
					value: transformVersionToPublicValue,
				},
				emitChangeOn: 'change',
				requiredScopes: versionsRequiredScopes,
			},
			[NUMBER_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateNumberValue,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {},
				emitChangeOn: 'change',
				requiredScopes: numberIssueViewScopes,
			},
			[LABELS_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateLabelsSetValue,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {},
				emitChangeOn: 'change',
				requiredScopes: labelsRequiredScopes,
			},
			[DATE_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: datePickerSetValueValidator,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: datePickerGetValueTransformer,
				},
				emitChangeOn: 'change',
				requiredScopes: datePickerIssueViewScopes,
			},
			[DATETIME_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateDateTimePickerSetValue,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: transformDateTimePickerGetValue,
				},
				emitChangeOn: 'change',
				requiredScopes: dateTimePickerIssueViewScopes,
			},
			[USER_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: userPickerSetValueValidator,
				},
				publicToInternalTransformers: {
					value: assigneeSetValueTransformer,
				},
				internalToPublicTransformers: {
					value: assigneeGetValueTransformer,
				},
				emitChangeOn: 'change',
				lookupSource: LOOKUP_SOURCE_USERS_ASYNC_DATA,
				requiredScopes: assigneeIssueViewScopes,
			},
			[REPORTER_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: reporterSetValueValidator,
				},
				publicToInternalTransformers: {
					value: assigneeSetValueTransformer,
				},
				internalToPublicTransformers: {
					value: assigneeGetValueTransformer,
				},
				emitChangeOn: 'change',
				lookupSource: LOOKUP_SOURCE_USERS_ASYNC_DATA,
				requiredScopes: assigneeIssueViewScopes,
			},
			[MULTI_USER_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateMultiUserPickerSetValue,
				},
				publicToInternalTransformers: {
					value: transformMultiUserPickerSetValue,
				},
				internalToPublicTransformers: {
					value: transformMultiUserPickerGetValue,
				},
				emitChangeOn: 'change',
				lookupSource: LOOKUP_SOURCE_USERS_ASYNC_DATA,
				requiredScopes: assigneeIssueViewScopes,
			},
			[COMPONENTS_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateComponentsSetValue,
				},
				publicToInternalTransformers: {
					value: transformComponentsSetValue,
				},
				internalToPublicTransformers: {
					value: transformComponentsGetValue,
				},
				emitChangeOn: 'change',
				requiredScopes: componentsIssueViewScopes,
			},
			[PARENT_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					description: commonFieldValidators.description,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: parentSetValueValidator,
				},
				publicToInternalTransformers: { value: transformParentSetValue },
				internalToPublicTransformers: {
					value: transformParentGetValue,
				},
				emitChangeOn: 'change',
				requiredScopes: parentIssueViewScopes,
			},
			[CASCADING_SELECT_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateCascadingSelectSetValue,
					description: commonFieldValidators.description,
				},
				publicToInternalTransformers: {
					value: transformSetValueCascadingSelect,
				},
				internalToPublicTransformers: {
					value: transformGetValueCascadingSelect,
				},
				emitChangeOn: 'change',
				requiredScopes: cascadingSelectIssueViewScopes,
			},
			[PEOPLE_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					description: commonFieldValidators.description,
					value: validatePeopleSetValue,
				},
				publicToInternalTransformers: {
					value: transformPeopleSetValue,
				},
				internalToPublicTransformers: {
					value: transformPeopleGetValue,
				},
				emitChangeOn: 'change',
				lookupSource: LOOKUP_SOURCE_USERS_ASYNC_DATA,
				requiredScopes: peopleIssueViewScopes,
			},
			[STATUS_TYPE]: {
				publicShapeValidators: {
					value: validateStatusSetValue,
				},
				publicToInternalTransformers: { value: transformStatusSetValue },
				internalToPublicTransformers: {
					value: transformStatusGetValue,
				},
				emitChangeOn: 'change',
				requiredScopes: statusIssueViewScopes,
			},
			[TIME_ESTIMATE_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					description: commonFieldValidators.description,
					value: validateOriginalEstimate,
				},
				publicToInternalTransformers: {
					value: transformSetValueOriginalEstimate,
				},
				internalToPublicTransformers: {
					value: transformGetValueOriginalEstimate,
				},
				emitChangeOn: 'change',
				requiredScopes: originalEstimateIssueViewScopes,
			},
		} as const;
	}

	getSupportedFieldTypes() {
		const supportedFieldTypes: FieldType[] = [
			SUMMARY_TYPE,
			DESCRIPTION_TYPE,
			ASSIGNEE_TYPE,
			PRIORITY_TYPE,
			URL_CF_TYPE,
			TEXT_CF_TYPE,
			MULTI_CHECKBOXES_CF_TYPE,
			TEXT_AREA_CF_TYPE,
			SELECT_CF_TYPE,
			MULTI_SELECT_CF_TYPE,
			RADIO_BUTTONS_CF_TYPE,
			FIX_VERSIONS_TYPE,
			NUMBER_CF_TYPE,
			DATE_CF_TYPE,
			DATETIME_CF_TYPE,
			LABELS_TYPE,
			USER_CF_TYPE,
			REPORTER_TYPE,
			COMPONENTS_TYPE,
			MULTI_USER_CF_TYPE,
			PARENT_TYPE,
			AFFECTS_VERSIONS_TYPE,
			PEOPLE_CF_TYPE,
			STATUS_TYPE,
		];

		if (fg('ui-modifications-cascading-select-field-support')) {
			supportedFieldTypes.push(CASCADING_SELECT_CF_TYPE);
		}

		if (fg('uim-orginal-estimate-field-support_ugzqt')) {
			supportedFieldTypes.push(TIME_ESTIMATE_TYPE);
		}

		return supportedFieldTypes;
	}

	getSupportedProjectTypes() {
		return [SOFTWARE_PROJECT];
	}

	getConsentMessage() {
		return messages.uimIssueViewConsentMessage;
	}

	getAnalytics() {
		return this.analytics;
	}

	getSupportedTriggerPointsConfiguration() {
		return new Set([
			FULL_ISSUE,
			SOFTWARE_ISSUE,
			SOFTWARE_BACKLOG_ISSUE,
			SIMPLE_SOFTWARE_BOARD,
			AGILITY_BACKLOG,
			GLOBAL_ISSUE_NAVIGATOR_ISSUE,
			ISSUE_NAVIGATOR,
			BUSINESS_LIST,
		] as const);
	}

	isScreenTabsSupportEnabled = () => true;
}
