import React, { useCallback, type ReactElement } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { ButtonItem } from '@atlaskit/menu';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension/index.tsx';
import { fireUiTriggerClickedEvent } from '@atlassian/jira-forge-ui-analytics/src/services/issue-action/index.tsx';
import { TriggerAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/issue-action/index.tsx';
import { ExtensionTitle } from '@atlassian/jira-forge-ui-extension-title/src/ui/index.tsx';
import type { Action } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
// eslint-disable-next-line camelcase
import { withStoreForIssueViewShortcutActions_DO_NOT_USE } from '@atlassian/jira-issue-view-common-views/src/store-provider/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { forgeIssueActionExec } from '@atlassian/jira-issue-view-store/src/actions/forge-actions.tsx';
import { analyticsSourceSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import type { ShortcutComponentProps } from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';

type OwnProps = ShortcutComponentProps & Action;
type Props = OwnProps & {
	source: string | undefined;
	execForgeAction: (action: Action) => void;
};

export const ForgeActionView = ({
	onClick,
	execForgeAction,
	source,
	setDropdownItemRef,
	itemKey,
	label,
	isSelected,
	...action
}: Props) => {
	const handleOnClick = useCallback(() => {
		fireUiTriggerClickedEvent(action.extension.id, source);
		execForgeAction(action);
		onClick?.(itemKey);
	}, [source, execForgeAction, onClick, action, itemKey]);

	return (
		<DropdownItem key={action.id} onClick={handleOnClick}>
			<ExtensionTitle
				extensionName={action.title}
				extensionEnvType={action.extension.environmentType}
				extensionEnvKey={action.extension.environmentKey}
			/>
			<ForgeScreenEvent
				attributes={{
					source,
					...getAnalyticsAttributesFromExtension(action.extension),
				}}
			/>
		</DropdownItem>
	);
};

export const ForgeActionButtonItemView = ({
	onClick,
	execForgeAction,
	source,
	setDropdownItemRef,
	itemKey,
	label,
	isSelected = false,
	...action
}: Props) => {
	const handleOnClick = useCallback(() => {
		fireUiTriggerClickedEvent(action.extension.id, source);
		execForgeAction(action);
		onClick?.(itemKey);
	}, [source, execForgeAction, onClick, action, itemKey]);

	return (
		<ButtonItem
			key={action.id}
			isSelected={isSelected}
			onClick={handleOnClick}
			role="menuitem"
			ref={setDropdownItemRef}
		>
			<ExtensionTitle
				extensionName={action.title}
				extensionEnvType={action.extension.environmentType}
				extensionEnvKey={action.extension.environmentKey}
			/>
			<ForgeScreenEvent
				attributes={{
					source,
					...getAnalyticsAttributesFromExtension(action.extension),
				}}
			/>
		</ButtonItem>
	);
};

export const ForgeActionViewWithAnalytics = (props: Props) => (
	<TriggerAnalyticsWrapper
		attributes={{
			source: props.source,
			...getAnalyticsAttributesFromExtension(props.extension),
		}}
	>
		<ForgeActionView {...props} />
	</TriggerAnalyticsWrapper>
);

const getForgeViewConnectedWithStore = (Component: (arg1: Props) => ReactElement) =>
	flowWithSafeComponent(
		connect(
			(state) => ({
				source: analyticsSourceSelector(state),
			}),
			{
				execForgeAction: forgeIssueActionExec,
			},
		),
	)((props: Props) => (
		<TriggerAnalyticsWrapper
			attributes={{
				source: props.source,
				...getAnalyticsAttributesFromExtension(props.extension),
			}}
		>
			<Component {...props} />
		</TriggerAnalyticsWrapper>
	));

const ForgeActionViewConnectedWithoutStore = getForgeViewConnectedWithStore(ForgeActionView);

export const ForgeActionViewConnected = withStoreForIssueViewShortcutActions_DO_NOT_USE<OwnProps>(
	ForgeActionViewConnectedWithoutStore,
);

const ForgeActionButtonItemViewConnectedWithoutStore =
	getForgeViewConnectedWithStore(ForgeActionButtonItemView);

export const ForgeActionButtonItemViewConnected =
	withStoreForIssueViewShortcutActions_DO_NOT_USE<OwnProps>(
		ForgeActionButtonItemViewConnectedWithoutStore,
	);

export const getForgeAction = (props: Action) => ({
	component: (shortcutProps: ShortcutComponentProps) => (
		<ErrorBoundary packageName="issue" id="forge-items">
			<ForgeActionViewConnected {...props} {...shortcutProps} />
		</ErrorBoundary>
	),
	dialogComponent: (shortcutProps: ShortcutComponentProps) => (
		<ErrorBoundary packageName="issue" id="forge-items">
			<ForgeActionButtonItemViewConnected {...props} {...shortcutProps} />
		</ErrorBoundary>
	),
	eventName: props.id,
	key: props.id,
	isAllowed: true,
	label: props.title,
});
