import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showMoreButton: {
		id: 'smart-related-issues-panel.similar-incidents.show-more-button',
		defaultMessage: 'View More',
		description: 'Show more similar incidents button label',
	},
	noSimilarIncidents: {
		id: 'smart-related-issues-panel.similar-incidents.no-similar-incidents',
		defaultMessage: 'There are no similar incidents for this issue.',
		description: 'Empty state for similar incidents container',
	},
});
