import React, { useMemo } from 'react';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import { getCommandPaletteIssueActionsPriority } from '@atlassian/jira-command-palette-common/src/common/utils/get-command-palette-issue-actions-priority/index.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { useCommandPaletteShortcut } from '@atlassian/jira-command-palette-common/src/controllers/use-command-palette-shortcut/index.tsx';
import type { ActiveKeyMapWithCommandPalette } from '@atlassian/jira-command-palette-common/src/ui/command-shortcuts/types.tsx';
import {
	ROUTE_NAMES_LEGACY_GIN,
	ROUTE_NAMES_LEGACY_GIN_ISSUE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { useIsModal, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldIsEditingActions } from '@atlassian/jira-issue-field-editing-store/src/index.tsx';
import {
	externalAddCommentAction,
	externalAssignToMeAction,
	externalEditFieldAction,
	transformExternalAction,
} from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import {
	ASSIGNEE_TYPE,
	LABELS_TYPE,
	TIME_TRACKING_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	ISSUE_ACTION_SHORTCUT_ADD_COMMENT,
	ISSUE_ACTION_SHORTCUT_ADD_TIME_TRACKING,
	ISSUE_ACTION_SHORTCUT_ASSIGN_ISSUE,
	ISSUE_ACTION_SHORTCUT_ASSIGN_TO_ME,
	ISSUE_ACTION_SHORTCUT_CHANGE_STATUS,
	KEYBOARD_SHORTCUT_PRIORITY,
	ISSUE_ACTION_SHORTCUT_EDIT_LABELS,
	ISSUE_ACTION_SHORTCUT_SHARE,
	ISSUE_ACTION_SHORTCUT_LEFT_PANEL,
	ISSUE_ACTION_SHORTCUT_RIGHT_PANEL,
	SHORTCUT_TRIGGER_ID_CHANGE_STATUS,
	SHORTCUT_TRIGGER_KEYS_SHARE,
} from '../constant.tsx';
import { messages } from '../messages.tsx';
import type {
	TriggerShortcutAction,
	TriggerComponentWithoutStore,
	TriggerShortcutPanelFocus,
} from '../types.tsx';
import { useAssignToMeOrUnassign } from './assign-to-me-or-unassign/index.tsx';
import { useIssueActionsPermissions } from './issue-actions-permissions.tsx';

export const useIssueActionsShortcuts = (
	triggerShortcutAction: TriggerShortcutAction,
	triggerShortcutComponent: TriggerComponentWithoutStore | null | undefined,
	triggerShortcutPanelFocus: TriggerShortcutPanelFocus,
): ActiveKeyMapWithCommandPalette => {
	const currentRoute = useCurrentRoute();

	const { setFieldEditingState } = useFieldIsEditingActions();

	const { formatMessage } = useIntl();

	const issueKey = useIssueKey();

	const { canAssign, isCurrentUserAssigned, canAddComments } = useIssueActionsPermissions();

	const isModal = useIsModal();

	const { keyMap: commandPaletteKeyMap } = useCommandPaletteShortcut();

	const { getKeywords } = useCommandKeywords();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { handleAssignToMeOrUnassign } = useAssignToMeOrUnassign();

	return useMemo(() => {
		const legacyGinRouteNames: string[] | undefined = [
			ROUTE_NAMES_LEGACY_GIN,
			ROUTE_NAMES_LEGACY_GIN_ISSUE,
		];

		const sectionId = getCommandPaletteIssueActionsHeader(issueKey);
		return {
			[ISSUE_ACTION_SHORTCUT_ASSIGN_ISSUE]: {
				callback: () => {
					if (ff('relay-migration-issue-fields-assignee-ld')) {
						setFieldEditingState(ASSIGNEE_TYPE, true);
					} else {
						const editAssignee = transformExternalAction(externalEditFieldAction(ASSIGNEE_TYPE));
						triggerShortcutAction(editAssignee, 'assignIssue', ISSUE_ACTION_SHORTCUT_ASSIGN_ISSUE);
					}
				},
				label: <FormattedMessage {...messages.editAssignee} />,
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
			[ISSUE_ACTION_SHORTCUT_ASSIGN_TO_ME]: {
				callback: () => {
					if (ff('relay-migration-issue-fields-assignee-ld')) {
						handleAssignToMeOrUnassign();
					} else {
						const assignToMe = transformExternalAction(externalAssignToMeAction());
						triggerShortcutAction(assignToMe, 'assignToMe', ISSUE_ACTION_SHORTCUT_ASSIGN_TO_ME);
					}

					fireTrackAnalytics(createAnalyticsEvent({}), 'field updated', {
						fieldKey: ASSIGNEE_TYPE,
					});
				},
				label: <FormattedMessage {...messages.assignToMe} />,
				priority: KEYBOARD_SHORTCUT_PRIORITY,
				...(canAssign
					? {
							registerInCommandPalette: {
								id: `issue.views.issue-details.keyboard-shortcuts.${issueKey}.${
									isCurrentUserAssigned ? 'unassign' : 'assign'
								}`,
								name: formatMessage(
									isCurrentUserAssigned ? messages.unassignFromMe : messages.assignToMe,
								),
								keywords: getKeywords('assignToMeSynonyms'),
								priority: getCommandPaletteIssueActionsPriority('OLD_ISSUE_FIELDS'),
								components: {
									LeftIcon: () => (
										<UserAvatarCircleIcon
											label={formatMessage(
												isCurrentUserAssigned ? messages.unassignFromMe : messages.assignToMe,
											)}
										/>
									),
								},
								section: sectionId,
								analytics: {
									action: isCurrentUserAssigned ? 'unassign' : 'assignToMe',
								},
							},
						}
					: undefined),
			},
			[ISSUE_ACTION_SHORTCUT_EDIT_LABELS]: {
				callback: () => {
					setFieldEditingState(LABELS_TYPE, true);
					const editLabels = transformExternalAction(externalEditFieldAction(LABELS_TYPE));
					triggerShortcutAction(editLabels, 'editLabels', ISSUE_ACTION_SHORTCUT_EDIT_LABELS);
				},
				label: <FormattedMessage {...messages.editLabels} />,
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
			[ISSUE_ACTION_SHORTCUT_ADD_COMMENT]: {
				callback: () => {
					const addComment = transformExternalAction(externalAddCommentAction());
					triggerShortcutAction(addComment, 'commentIssue', ISSUE_ACTION_SHORTCUT_ADD_COMMENT);
				},
				label: (
					<FormattedMessage
						{...(expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.commentOnIssueIssueTermRefresh
							: messages.commentOnIssue)}
					/>
				),
				priority: KEYBOARD_SHORTCUT_PRIORITY,
				...(canAddComments && {
					registerInCommandPalette: {
						id: 'issue.views.issue-details.keyboard-shortcuts.add-comment',
						name: formatMessage(messages.addComment),
						keywords: getKeywords('addCommentSynonyms'),
						priority: getCommandPaletteIssueActionsPriority('ADD_COMMENT'),
						components: {
							LeftIcon: () => <CommentIcon label={formatMessage(messages.addComment)} />,
						},
						section: sectionId,
						analytics: {
							action: 'addComment',
						},
					},
				}),
			},
			[ISSUE_ACTION_SHORTCUT_CHANGE_STATUS]: {
				callback: () => {
					triggerShortcutComponent?.(
						SHORTCUT_TRIGGER_ID_CHANGE_STATUS,
						'changeStatus',
						ISSUE_ACTION_SHORTCUT_CHANGE_STATUS,
					);
				},
				label: <FormattedMessage {...messages.changeStatus} />,
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
			[ISSUE_ACTION_SHORTCUT_ADD_TIME_TRACKING]: {
				callback: () => {
					const addTimeTracking = transformExternalAction(
						externalEditFieldAction(TIME_TRACKING_TYPE),
					);
					triggerShortcutAction(
						addTimeTracking,
						'timetracking',
						ISSUE_ACTION_SHORTCUT_ADD_TIME_TRACKING,
					);
				},
				label: <FormattedMessage {...messages.openWorklogModal} />,
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
			...(currentRoute &&
				legacyGinRouteNames &&
				// Only register the "shift+s" shortcut if route is not global issue navigator (conflicts with the other "s" shortcut on Windows)
				// This can be removed once GIN is replaced with NIN. See https://hello.atlassian.net/browse/DEE-1224
				!legacyGinRouteNames.includes(currentRoute?.name) && {
					[ISSUE_ACTION_SHORTCUT_SHARE]: {
						callback: () => {
							// Share field is not yet defined in src/packages/platform/ui/field-config/src/index.js
							// So no external action is fired.

							// Guard can be removed once FF is removed and this param is mandatory.
							if (triggerShortcutComponent) {
								triggerShortcutComponent(
									'shareDropdownTriggerCount',
									'shareIssue',
									SHORTCUT_TRIGGER_KEYS_SHARE,
								);
							}
						},
						label: <FormattedMessage {...messages.openCompalDialog} />,
						priority: KEYBOARD_SHORTCUT_PRIORITY,
					},
				}),
			[ISSUE_ACTION_SHORTCUT_LEFT_PANEL]: {
				callback: () => {
					triggerShortcutPanelFocus(ISSUE_ACTION_SHORTCUT_LEFT_PANEL, 'scrollIssueLeft');
				},
				label: (
					<FormattedMessage
						{...(expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.switchSelectedAreaLeftIssueTermRefresh
							: messages.switchSelectedAreaLeft)}
					/>
				),
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
			[ISSUE_ACTION_SHORTCUT_RIGHT_PANEL]: {
				callback: () => {
					triggerShortcutPanelFocus(ISSUE_ACTION_SHORTCUT_RIGHT_PANEL, 'scrollIssueRight');
				},
				label: (
					<FormattedMessage
						{...(expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.switchSelectedAreaRightIssueTermRefresh
							: messages.switchSelectedAreaRight)}
					/>
				),
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
			...(!!isModal && commandPaletteKeyMap),
		};
	}, [
		issueKey,
		canAssign,
		isCurrentUserAssigned,
		formatMessage,
		getKeywords,
		canAddComments,
		currentRoute,
		isModal,
		commandPaletteKeyMap,
		setFieldEditingState,
		triggerShortcutAction,
		createAnalyticsEvent,
		handleAssignToMeOrUnassign,
		triggerShortcutComponent,
		triggerShortcutPanelFocus,
	]);
};

export default useIssueActionsShortcuts;
