import React, { type KeyboardEventHandler, useState, useCallback, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import keycode from 'keycode';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import IssuePicker from '@atlassian/jira-issue-picker/src/ui/index.tsx';
import { smoothScrollIntoCenterIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type { IssueId, IssueKey, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

export type Props = {
	issueKey: IssueKey | null;
	initialValue?: string;
	jql: string;
	projectId: ProjectId | null;
	addClickCount: number;
	onAdd: (issue: {
		issueId: IssueId;
		issueKey: IssueKey;
		issueSummary: string;
		issueTypeIconUrl: string;
	}) => void;
	onCancel: () => void;
	onCreate: (inputValue: string) => void;
};

export const AddExistingIssue = ({
	issueKey,
	initialValue = '',
	jql,
	projectId,
	addClickCount,
	onAdd,
	onCancel,
	onCreate,
}: Props) => {
	const intl = useIntl();

	const [selectedIssue, setSelectedIssue] = useState<
		| {
				issueId: IssueId;
				issueKey: IssueKey;
				issueSummary: string;
				issueTypeIconUrl: string;
		  }
		| undefined
	>();
	const [isDisabled, setIsDisabled] = useState(true);

	const previousAddClickCount = usePrevious(addClickCount);

	const childContainer = useRef<HTMLDivElement | null>(null);
	const issuePicker = useRef<IssuePicker | null>(null);

	const focusPicker = useCallback(() => {
		issuePicker.current?.focus?.();
	}, []);

	const resetPicker = useCallback(() => {
		issuePicker.current?.reset();
	}, []);

	const onAddClicked = useCallback(() => {
		if (selectedIssue) {
			onAdd(selectedIssue);
			resetPicker();
		}
	}, [onAdd, resetPicker, selectedIssue]);

	const handleOnChange = useCallback(
		(issue: {
			issueId: IssueId;
			issueKey: IssueKey;
			issueSummary: string;
			issueTypeIconUrl: string;
		}) => {
			setSelectedIssue(issue);
			setIsDisabled(false);
		},
		[],
	);

	const onClearClicked = useCallback(() => {
		setSelectedIssue(undefined);
		setIsDisabled(true);
	}, []);

	const onCancelClicked = useCallback(() => {
		onClearClicked();
		onCancel();
	}, [onCancel, onClearClicked]);

	// AK Select Component's select on enter behaviour is broken if we use EnterEscapeHandler to handle enter key,
	// So we have to handle it separately here
	const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
		(event) => {
			if (event.keyCode === keycode('enter')) {
				onAddClicked();
				event.stopPropagation();
			}
		},
		[onAddClicked],
	);

	useEffect(() => {
		smoothScrollIntoCenterIfNeeded(childContainer.current);
	}, []);

	useEffect(() => {
		if (addClickCount !== previousAddClickCount) {
			smoothScrollIntoCenterIfNeeded(childContainer.current);
			focusPicker();
		}
	}, [addClickCount, focusPicker, previousAddClickCount]);

	if (!issueKey || !projectId) {
		return null;
	}

	const ariaLabel = intl.formatMessage(messages.ariaLabelForAddExistingIssue);

	return (
		<EnterEscapeHandler onEscape={onCancelClicked}>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div onKeyDown={onKeyDown} ref={childContainer}>
				<IssuePicker
					ref={issuePicker}
					initialValue={initialValue}
					issueKey={issueKey}
					projectId={projectId}
					jql={jql}
					onChange={handleOnChange}
					onClear={onClearClicked}
					intl={intl}
					onCreate={onCreate}
					label={ariaLabel}
				/>
				<ButtonsContainer>
					<StyledButton appearance="primary" onClick={onAddClicked} isDisabled={isDisabled}>
						{intl.formatMessage(messages.childLinkButton)}
					</StyledButton>
					<StyledButton appearance="subtle" onClick={onCancelClicked}>
						{intl.formatMessage(messages.childCancelButton)}
					</StyledButton>
				</ButtonsContainer>
			</div>
		</EnterEscapeHandler>
	);
};

export default AddExistingIssue;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	marginLeft: `${token('space.100', '8px')} !important`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsContainer = styled.div({
	marginTop: token('space.100', '8px'),
	display: 'flex',
	justifyContent: 'flex-end',
});
