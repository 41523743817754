import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import { EmptyValueStyleWrapper } from './styled.tsx';

type Props = {
	emptyText?: string;
};

export const EmptyFieldText = ({ emptyText }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<EmptyValueStyleWrapper>
			{emptyText ?? formatMessage(messages.emptyFieldText)}
		</EmptyValueStyleWrapper>
	);
};
