import React from 'react';
import { styled } from '@compiled/react';
import { BlockedAppsIssueInfoBanner as BlockedAppsBanner } from '@atlassian/jira-blocked-apps-info-banner/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { IssueViewDataClassification } from '@atlassian/jira-issue-field-data-classification/src/ui/index.tsx';
import { REACT_KEY_SUMMARY } from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import ColorField from '@atlassian/jira-issue-view-foundation/src/color/index.tsx';
import SummaryField from '@atlassian/jira-issue-view-foundation/src/summary/index.tsx';
import { IssueViewColor } from '@atlassian/jira-issue-view-layout-color-field/src/ui/index.tsx';
import { IssueViewSummaryField } from '@atlassian/jira-issue-view-layout-summary-field/src/ui/index.tsx';
import { ReadOnlyIssueInfo } from '@atlassian/jira-read-only-issue-info/src/main.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	issueViewRelayFragment,
}: {
	// TODO Decomp JIV-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
}) => (
	<>
		<BlockedAppsBanner />
		<ReadOnlyIssueInfo />
		<IssueViewDataClassification issueViewRelayFragment={issueViewRelayFragment ?? null} />
		<FoundationContentWrapper data-testid="issue-view-layout-templates-default.ui.foundation-content.foundation-content-wrapper">
			{ff('relay-migration-issue-fields-color_wrbay') ? (
				<IssueViewColor issueViewRelayFragment={issueViewRelayFragment ?? null} />
			) : (
				<ColorField />
			)}
			{ff('relay-migration-issue-fields-summary_eog1v') ? (
				<IssueViewSummaryField
					key={REACT_KEY_SUMMARY}
					fragmentKey={issueViewRelayFragment ?? null}
				/>
			) : (
				<SummaryFieldWrapper key={REACT_KEY_SUMMARY}>
					<SummaryField />
				</SummaryFieldWrapper>
			)}
		</FoundationContentWrapper>
	</>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FoundationContentWrapper = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	minWidth: 0,
	width: '100%',
});
FoundationContentWrapper.displayName = 'FoundationContentWrapper';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryFieldWrapper = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		display: 'flex',
		flex: '1 1 auto',
	},
});
SummaryFieldWrapper.displayName = 'SummaryFieldWrapper';
