// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sortByLabel: {
		id: 'issue.views.common.child-issues-panel.heading.sort-by.label',
		defaultMessage: 'Order by {option}',
		description: 'Label for a dropdown menu where users can select a sorting option',
	},
	created: {
		id: 'issue.views.common.child-issues-panel.heading.created.label',
		defaultMessage: 'Created',
		description: 'Label for a menu option that sorts the issues by created date',
	},
	key: {
		id: 'issue.views.common.child-issues-panel.heading.key.label',
		defaultMessage: 'Key',
		description: 'Label for a menu option that sorts the issues by issue key',
	},
	priority: {
		id: 'issue.views.common.child-issues-panel.heading.priority.label',
		defaultMessage: 'Priority',
		description: 'Label for a menu option that sorts the issues by priority',
	},
	status: {
		id: 'issue.views.common.child-issues-panel.heading.status.label',
		defaultMessage: 'Status',
		description: 'Label for a menu option that sorts the issues by status',
	},
	assignee: {
		id: 'issue.views.common.child-issues-panel.heading.assignee.label',
		defaultMessage: 'Assignee',
		description: 'Label for a menu option that sorts the issues by assignee display name',
	},
	ranking: {
		id: 'issue.views.common.child-issues-panel.heading.ranking.label',
		defaultMessage: 'Rank',
		description: 'Label for a menu option that sorts the issues by rank',
	},
	defaultOptionLabel: {
		id: 'issue.views.common.child-issues-panel.heading.default-option.label',
		defaultMessage: '{option} (default)',
		description: 'Label for a menu option that sorts the issues by the default sort order',
	},
	exceededChildIssuesLimit: {
		id: 'issue.views.common.child-issues-panel.heading.sort-by.exceeded-child-issues-limit',
		defaultMessage: 'To order more than 100 child issues, select ··· > View in search.',
		description: 'Label for sort by button tooltip tells users they are over the limit',
	},
});
