import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import type { ConfluenceWhiteboard } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { applinkAuthenticationRequest } from '@atlassian/jira-issue-view-store/src/actions/applink-authentication-actions.tsx';
import {
	linkConfluencePageClosed,
	createConfluencePageLinkRequest,
	createConfluencePageLinkModified,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import {
	confluenceWhiteboardLinkRequest,
	fetchConfluenceWhiteboardSuccess,
	confluenceWhiteboardLinkRequestFailed,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-whiteboards-actions.tsx';
import { fetchRecentlyVisitedConfluencePagesRequest } from '@atlassian/jira-issue-view-store/src/actions/fetch-recently-visited-confluence-pages-actions.tsx';
import { confluenceAppLinksSelector } from '@atlassian/jira-issue-view-store/src/selectors/confluence-app-links-selector.tsx';
import {
	createLinkedPageClickedCountSelector,
	isCreateLinkedPageLoadingSelector,
	isCreateLinkedPageInvalidSelector,
	linkedConfluencePagesCountSelector,
	createLinkedPageErrorMessageSelector,
	linkedConfluencePagesPagesSelector,
	mentionedConfluencePagesPagesSelector,
	createLinkedPageRepairLinkSelector,
	linkedConfluenceWhiteboardsCountSelector,
	isCreateLinkedWhiteboardLoadingSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/confluence-content-selector.tsx';
import { recentlyVisitedConfluencePagesSelector } from '@atlassian/jira-issue-view-store/src/selectors/recently-visited-confluence-pages-selector.tsx';
import { toHref, type Href } from '@atlassian/jira-shared-types/src/general.tsx';
import ConfluencePageCreateLinkView from './view.tsx';

const connectedProps = {
	onCancelClickAnalytics: 'cancelLinkConfluencePage',
	onEscapeKeyPressedAnalytics: 'cancelLinkConfluencePage',
};

const ConfluencePageCreateLink = flowWithSafeComponent(
	ComponentWithAnalytics('button', {
		onSubmitClickAnalytics: 'clicked',
		onCancelClickAnalytics: 'clicked',
	}),
	ComponentWithAnalytics('keyboardShortcut', {
		onEnterKeyPressedAnalytics: 'pressed',
		onEscapeKeyPressedAnalytics: 'pressed',
	}),
	ComponentWithAnalytics('confluencePagesCreateLinkView', {
		onSubmit: 'onSubmit',
	}),
	withFireUiAnalytics(connectedProps),
	ComponentWithAnalytics('recentlyVisitedPages', {
		onSelectAnalytics: 'selected',
	}),
	withFireUiAnalytics({
		onSelectAnalytics: 'selectConfluencePage',
	}),
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<{ linkedPagesCount: number; linkedWhiteboardsCount: number; errorMessage: string; isInvalid: boolean; isLoading: boolean | undefined; createLinkClickedCount: number; recentlyViewedPages: ConfluencePage[]; confluenceAppLinks: ConfluenceAppLink[]; linkedPages: (ConfluencePage | Fail...' is not assignable to parameter of type 'FlowStep<WithUIAnalyticsEvent<PropsWithoutRef<Omit<{ [K in keyof PropsWithRef<PropsWithoutRef<Omit<{ [K in keyof PropsWithRef<Omit<{ [K in keyof PropsWithRef<Omit<{ [K in keyof Props]: K extends "onSubmitClickAnalytics" | "onCancelClickAnalytics" ? (...args: [...Parameters<Props[K]>, UIAnalyticsEvent]) => ReturnType...'.
	connect(
		(state: State) => ({
			linkedPagesCount: linkedConfluencePagesCountSelector(state),
			linkedWhiteboardsCount: linkedConfluenceWhiteboardsCountSelector(state),
			errorMessage: createLinkedPageErrorMessageSelector(state),
			isInvalid: isCreateLinkedPageInvalidSelector(state),
			isLoading:
				isCreateLinkedPageLoadingSelector(state) || isCreateLinkedWhiteboardLoadingSelector(state),
			createLinkClickedCount: createLinkedPageClickedCountSelector(state),
			recentlyViewedPages: recentlyVisitedConfluencePagesSelector(state),
			confluenceAppLinks: confluenceAppLinksSelector(state),
			linkedPages: linkedConfluencePagesPagesSelector(state),
			mentionedPages: mentionedConfluencePagesPagesSelector(state),
			repairLink: createLinkedPageRepairLinkSelector(state),
		}),
		(dispatch) => ({
			onInvalidLinkModified: () => dispatch(createConfluencePageLinkModified()),
			onCancel: () => dispatch(linkConfluencePageClosed()),
			onSubmit: (
				link: string,
				triggerInviteExperiment: () => void,
				analyticsEvent: UIAnalyticsEvent,
			) =>
				dispatch(
					createConfluencePageLinkRequest(toHref(link), analyticsEvent, triggerInviteExperiment),
				),
			requestRecentlyViewedPages: () => dispatch(fetchRecentlyVisitedConfluencePagesRequest()),
			onAuthenticateApplink: (href: Href) => dispatch(applinkAuthenticationRequest(href)),
			onCreateWhiteboardLinkRequest: () => dispatch(confluenceWhiteboardLinkRequest()),
			onCreateWhiteboardLinkRequestFailed: () => dispatch(confluenceWhiteboardLinkRequestFailed()),
			onFetchConfluenceWhiteboardSuccess: (whiteboard: ConfluenceWhiteboard) =>
				dispatch(fetchConfluenceWhiteboardSuccess(whiteboard)),
		}),
	),
)(ConfluencePageCreateLinkView);

export default ConfluencePageCreateLink;
