import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { type EditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { suggestConfigItemForConfluenceTitleToolbar } from '../../../prebuilt/config-items-range/suggest/suggest';
import { type EditorPluginAIProvider } from '../../../types';
import {
	type AdjustSelectionInstruction,
	type ConfigType,
	EventHubPrompt,
	type StartPromptData,
} from '../types';

const getConfigFromProvider = (
	editorPluginAIProvider: EditorPluginAIProvider,
	configType: ConfigType,
	key: CONFIG_ITEM_KEYS,
) => {
	const provider = editorPluginAIProvider[configType];

	if (key === CONFIG_ITEM_KEYS.FREE_GENERATE) {
		return provider.baseGenerate;
	} else {
		const configItem = provider
			.getSuggestions({ inputValue: '' })
			?.find((suggestion) => suggestion.key === key);
		return configItem;
	}
};

const getConfigTypeByAdjustSelection = (
	adjustSelection?: AdjustSelectionInstruction,
): ConfigType => {
	switch (adjustSelection) {
		case 'fullDocument': {
			return 'rangeConfig';
		}
		case 'keepCurrent': {
			return 'rangeConfig';
		}
		default: {
			return 'emptyConfig';
		}
	}
};

export const getConfigItem = (
	prompt: StartPromptData['prompt'],
	editorPluginAIProvider: EditorPluginAIProvider,
	adjustSelection?: AdjustSelectionInstruction,
): EditorPluginAIConfigItemMarkdown | undefined => {
	switch (prompt) {
		case EventHubPrompt.SuggestTitle: {
			return suggestConfigItemForConfluenceTitleToolbar;
		}

		case EventHubPrompt.CustomPrompt: {
			const freeGenerate = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.FREE_GENERATE,
			);
			return freeGenerate;
		}

		case EventHubPrompt.IssueReformatter: {
			const issueReformatter = getConfigFromProvider(
				editorPluginAIProvider,
				'rangeConfig',
				CONFIG_ITEM_KEYS.ADD_STRUCTURE,
			);
			return issueReformatter;
		}

		case EventHubPrompt.Summarize: {
			const summarize = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.SUMMARISE_WRITING,
			);
			return summarize;
		}

		case EventHubPrompt.ImproveWriting: {
			const improveWriting = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.IMPROVE_WRITING,
			);
			return improveWriting;
		}

		case EventHubPrompt.MakeShorter: {
			const makeShorter = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.MAKE_SHORTER,
			);
			return makeShorter;
		}

		case EventHubPrompt.FixSpellingGrammar: {
			const fixSpellingGrammar = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.FIX_SPELLING_AND_GRAMMAR,
			);
			return fixSpellingGrammar;
		}

		case EventHubPrompt.ChangeToneToProfessional: {
			const professionalTone = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.CHANGE_TONE_TO_PROFESSIONAL,
			);
			return professionalTone;
		}

		case EventHubPrompt.ChangeToneToNeutral: {
			const neutralTone = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.CHANGE_TONE_TO_NEUTRAL,
			);
			return neutralTone;
		}

		case EventHubPrompt.ChangeToneToEmpathetic: {
			const empatheticTone = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.CHANGE_TONE_TO_EMPATHETIC,
			);
			return empatheticTone;
		}

		case EventHubPrompt.ChangeToneToCasual: {
			const empatheticTone = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.CHANGE_TONE_TO_CASUAL,
			);
			return empatheticTone;
		}

		case EventHubPrompt.ChangeToneToEducational: {
			const empatheticTone = getConfigFromProvider(
				editorPluginAIProvider,
				getConfigTypeByAdjustSelection(adjustSelection),
				CONFIG_ITEM_KEYS.CHANGE_TONE_TO_EDUCATIONAL,
			);
			return empatheticTone;
		}

		default: {
			return undefined;
		}
	}
};
