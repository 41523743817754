import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	description: {
		id: 'command-palette-discoverability.issue-actions-spotlight.content.description',
		defaultMessage: `Press <strong>{isMac, select,
            true {Cmd + K}
            other {Ctrl + K}
        }</strong> on your keyboard to try the command palette.`,
		description:
			'Description for spotlight content that explains how to open the command palette using the keyboard.',
	},
	title: {
		id: 'command-palette-discoverability.issue-actions-spotlight.content.title',
		defaultMessage: 'Update issues even faster',
		description: 'Title to explain the spotlight content',
	},
	trynowButton: {
		id: 'command-palette-discoverability.issue-actions-spotlight.content.trynow-button',
		defaultMessage: 'Try now',
		description: 'Button text for try now action',
	},
	dismissButton: {
		id: 'command-palette-discoverability.issue-actions-spotlight.content.dismiss-button',
		defaultMessage: 'Dismiss',
		description: 'Button text for dismiss action',
	},
});
