import type { ParentFieldType } from '@atlassian/jira-issue-internal-fields/src/parent/types.tsx';
import type { ServerParentLink } from '@atlassian/jira-issue-shared-types/src/common/types/parent-issue-type.tsx';

const defaultStateValue = {
	data: null,
	hasEpicLinkFieldDependency: false,
	showField: false,
} as const;

type SuggestionOption = {
	issues: {
		issueKey: number; // issueKey here is the number e.g. '123' part of 'ABC-123',
		issueSummary: string;
		issueTypeId: number;
		projectId: number;
	}[];
	projects: {
		id: number;
		key: string;
	}[];
	issueTypes: {
		iconUrl: string;
		id: number;
	}[];
};

const toItem = (issue: ServerParentLink, baseUrl: string) => ({
	hasEpicLinkFieldDependency: issue.hasEpicLinkFieldDependency,
	showField: issue.showField,
	value: issue.data && issue.data.key ? issue.data.key : null,
	data: issue.data
		? {
				key: issue.data.key,
				value: issue.data.key,
				summary: issue.data.summary,
				url: `${baseUrl}/browse/${issue.data.key}`,
				label: `${issue.data.key} ${issue.data.summary}`,
				issueType: issue.data.issueType
					? {
							iconUrl: `${baseUrl}${issue.data.issueType.iconUrl}`,
							id: String(issue.data.issueType.id),
						}
					: issue.data.issueType,
			}
		: null,
});

export const transformFromStateValue = (
	// Value returned from state can be void -> Fallback required
	stateValue: ServerParentLink = defaultStateValue,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	transformerContext: any,
) => toItem(stateValue, transformerContext.baseUrl);

export const transformToStateValue = (value: ParentFieldType) => value;

export const transformSuggestions = (
	suggestionsFromServer: SuggestionOption,
	baseUrl: string,
	// Initial value returned from pendingState selector can be void -> Fallback required
	initialValue: {
		hasEpicLinkFieldDependency: boolean;
		showField: boolean;
	} = {
		hasEpicLinkFieldDependency: false,
		showField: false,
	},
) => {
	if (
		!suggestionsFromServer ||
		!suggestionsFromServer.issues ||
		suggestionsFromServer.issues.length <= 0
	) {
		return [];
	}

	const { projects } = suggestionsFromServer;
	const avatars = suggestionsFromServer.issueTypes;

	const formattedData: ParentFieldType[] = suggestionsFromServer.issues.map((issue) => {
		const projectKey = projects.filter((p) => p.id === issue.projectId)[0].key;
		const issueKey = `${projectKey}-${issue.issueKey}`; // e.g. ABC-123
		const { iconUrl } = avatars.filter((icon) => +icon.id === issue.issueTypeId)[0];

		return {
			data: {
				key: issueKey,
				summary: issue.issueSummary,
				value: issueKey,
				label: `${issueKey} ${issue.issueSummary}`,
				issueType: {
					id: String(issue.issueTypeId),
					iconUrl: `${baseUrl}${iconUrl}`,
				},
				url: `${baseUrl}/browse/${issueKey}`,
			},
			hasEpicLinkFieldDependency: initialValue.hasEpicLinkFieldDependency,
			showField: initialValue.showField,
			value: issueKey,
		};
	});
	return formattedData;
};
