// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	uneditableIssueType: {
		id: 'issue.details.uneditable-issue-type',
		defaultMessage: '{issueTypeName} - You cannot edit this issue type in this project.',
		description: 'Tooltip content for changing uneditable issue type.',
	},
});
