import React, { useCallback, useMemo } from 'react';

import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { useSubscribe } from '@atlaskit/rovo-triggers';
import { convertMarkdownToProsemirror } from '@atlassian/ai-model-io/convert-markdown-to-prosemirror';
import { useAgentsList } from '@atlassian/conversation-assistant-agent';
import { type EditorAgent, getEditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import {
	beforeDispatchAIRovoAgentTransaction,
	insertAIContentAtCurrentPosition,
	replaceWithAIContent,
} from '../../../prebuilt/config-item-actions/action-utils';
import { mapToConvoAIProductName } from '../../../provider/prompt-requests/utils/get-convoai-supported-product-name';
import { type EditorPluginAIProvider } from '../../../types';

import { isLastActiveEditorView, useTrackLastActiveEditorView } from './utils';

type SubscribeParams = Parameters<typeof useSubscribe>;

export const SubscribeToRovo = ({
	editorView,
	product,
	onDocChangeByAgent,
	onAIProviderChanged,
}: {
	editorView: EditorView;
	product: EditorPluginAIProvider['product'];
	onDocChangeByAgent: EditorPluginAIProvider['onDocChangeByAgent'];
	onAIProviderChanged: EditorPluginAIProvider['onAIProviderChanged'];
}) => {
	const agents = useAgentsList({
		product: mapToConvoAIProductName(product),
	});

	const topic = useMemo<SubscribeParams[0]>(
		() => ({
			topic: 'ai-mate',
		}),
		[],
	);

	useTrackLastActiveEditorView(editorView);

	const callback = useCallback<SubscribeParams[1]>(
		(payload) => {
			// TODO: Define the appropriate payloads on Rovo's end
			// and handle them accordingly.
			// if (payload.type === 'chat-open') {
			// 	onAIProviderChanged();
			// }

			if (payload.type === 'editor-suggestion' && isLastActiveEditorView(editorView)) {
				const { state } = editorView;

				switch (payload.data.mode) {
					case 'insert': {
						const { pmFragment } = convertMarkdownToProsemirror({
							markdown: payload.data.content,
							schema: state.schema,
							idMap: {},
						});

						const tr = insertAIContentAtCurrentPosition({
							editorView,
							positions: [state.selection.from, state.selection.to],
							pmFragment,
						});
						beforeDispatchAIRovoAgentTransaction(tr);
						editorView.dispatch(tr);
						editorView.focus();
						break;
					}
					case 'replace': {
						const { pmFragment } = convertMarkdownToProsemirror({
							markdown: payload.data.content,
							schema: state.schema,
							idMap: {},
						});

						const tr = replaceWithAIContent({
							editorView,
							positions: [state.selection.from, state.selection.to],
							pmFragment,
							options: { replaceDocOnEmptySelection: false },
						});
						beforeDispatchAIRovoAgentTransaction(tr);
						editorView.dispatch(tr);
						editorView.focus();
						break;
					}
				}

				const matchedAgent = agents.find((agent) => agent.id === payload.data.agentId);

				if (matchedAgent && onDocChangeByAgent) {
					const agent: EditorAgent = getEditorAgent(matchedAgent);
					onDocChangeByAgent(agent);
				}
			}
		},
		[agents, onDocChangeByAgent, editorView],
	);
	useSubscribe(topic, callback);

	return <></>;
};
