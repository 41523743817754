import { SET_VALUE_ARRAY_SIZE_LIMIT } from '@atlassian/jira-issue-adjustments/src/common/constants.tsx';
import { isAriResourceIdValid } from '../utils/is-ari-resource-id-valid/index.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string/index.tsx';

export type MultiCheckboxSetPublicValue = string[];

export const validateMultiCheckboxSetValue = (
	value: unknown,
): value is MultiCheckboxSetPublicValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every(isNotEmptyString);

export const validateMultiCheckboxWithAriSetValue = (
	value: unknown,
): value is MultiCheckboxSetPublicValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every((val) => isNotEmptyString(val) && isAriResourceIdValid(val));
