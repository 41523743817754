import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { ff } from '@atlassian/jira-feature-flagging';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import VoteButtonRefresh from '../../common/vote-button-refresh/index.tsx';
import { VOTERS_UI_STYLED_BUTTON } from '../../constants.tsx';
import VoteIcon from '../icon/index.tsx';
import { StyledButton } from './styled.tsx';
import type { Props } from './types.tsx';

export const VoteButton = ({
	onClick,
	// @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'string'.
	tooltip = null,
	isSelected = false,
	isDisabled = false,
	ariaLabel = '',
	...rest
}: Props) => (
	<Tooltip content={tooltip} position="top">
		{isVisualRefreshEnabled() ? (
			<VoteButtonRefresh
				testId={VOTERS_UI_STYLED_BUTTON}
				onClick={onClick}
				isSelected={isSelected}
				label={ariaLabel}
				aria-expanded={isSelected}
				isDisabled={isDisabled}
				{...(ff('relay-migration-issue-fields-votes_nsbqd') ? { ...rest } : {})}
			/>
		) : (
			<StyledButton
				testId={VOTERS_UI_STYLED_BUTTON}
				onClick={onClick}
				isSelected={isSelected}
				appearance="subtle"
				spacing="default"
				aria-label={ariaLabel}
				aria-expanded={isSelected}
				isDisabled={isDisabled}
			>
				<VoteIcon
					isSelected={isSelected}
					{...(ff('relay-migration-issue-fields-votes_nsbqd') ? { ...rest } : {})}
				/>
			</StyledButton>
		)}
	</Tooltip>
);

export default VoteButton;
