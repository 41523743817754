import React from 'react';
import { styled } from '@compiled/react';
import { IconTile } from '@atlaskit/icon';
import Blog24Icon from '@atlaskit/icon-object/glyph/blog/24';
import Page24Icon from '@atlaskit/icon-object/glyph/page/24';
import PageIcon from '@atlaskit/icon/core/page';
import QuotationMarkIcon from '@atlaskit/icon/core/quotation-mark';
import { token } from '@atlaskit/tokens';
import { FormattedDate } from '@atlassian/jira-intl';
import { FormattedRelativeV2 as FormattedRelative } from '@atlassian/jira-intl/src/v2/formatted-relative.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import {
	CONFLUENCE_BLOGPOST,
	type ConfluencePage,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import { Ellipsis } from '@atlassian/jira-issue-view-common-styles/src/issue-value.tsx';
import type { Href } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { ConfluenceResult } from '../../confluence-search-view/common/types.tsx';
import messages from './messages.tsx';
import { SpaceWrapper, TitleWrapper, DotWrapper } from './styled.tsx';

type SelectProps = {
	additionalProps?: {
		type: ConfluenceResult;
		spaceKey?: string;
	};
};
type Props = {
	page: ConfluencePage | null;
	selectProps?: SelectProps;
	onSelect?: (href: Href) => void;
};

// Exporting for testing purpose
export const SpaceAndTimeDisplay = ({
	page,
	parent,
}: {
	page: ConfluencePage | null;
	parent?: SelectProps;
}) => {
	const { formatMessage } = useIntl();
	const { lastModified = 0, space } = page || {};
	const withUpdatedLabel = (formattedDate: string) => (
		<>
			{formatMessage(messages.updated)} {formattedDate}
		</>
	);

	const { additionalProps = {} } = parent || {};
	// @ts-expect-error - TS2339 - Property 'type' does not exist on type '{}'. | TS2339 - Property 'spaceKey' does not exist on type '{}'.
	const { type = 'search', spaceKey = '' } = additionalProps;
	let relativeTime = null;
	const spaceComponent = spaceKey === '' ? <Ellipsis>{space}</Ellipsis> : null;
	if (type !== 'recent') {
		if (lastModified > 0) {
			// @ts-expect-error - TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
			const daysElapsed = (Date.now() - new Date(lastModified)) / (1000 * 60 * 60 * 24);
			if (daysElapsed < 8) {
				relativeTime = (
					<Ellipsis>
						<FormattedRelative value={lastModified}>{withUpdatedLabel}</FormattedRelative>
					</Ellipsis>
				);
			} else {
				relativeTime = (
					<Ellipsis>
						<FormattedDate year="numeric" month="short" day="2-digit" value={lastModified}>
							{withUpdatedLabel}
						</FormattedDate>
					</Ellipsis>
				);
			}
		} else {
			relativeTime = <Ellipsis>{formatMessage(messages.recent)}</Ellipsis>;
		}
	}
	return (
		<>
			{spaceComponent}
			{spaceComponent !== null && relativeTime !== null ? <DotWrapper>•</DotWrapper> : null}
			{relativeTime}
		</>
	);
};

export default function ConfluencePageItem(props: Props) {
	if (!props.page) {
		return null;
	}
	const { title, type, href } = props.page;

	return (
		<PageItemContainer onClick={() => props.onSelect && props.onSelect(href)}>
			<IconContainer>
				{/* eslint-disable-next-line no-nested-ternary */}
				{!isVisualRefreshEnabled() ? (
					type === CONFLUENCE_BLOGPOST ? (
						// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
						<Blog24Icon label="" />
					) : (
						// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
						<Page24Icon label="" />
					)
				) : (
					<IconTile
						icon={type === CONFLUENCE_BLOGPOST ? QuotationMarkIcon : PageIcon}
						label=""
						appearance="blueBold"
						shape="square"
						size="24"
					/>
				)}
			</IconContainer>
			<ContentContainerWithEllipsis>
				<TitleWrapper>
					<Ellipsis>{title}</Ellipsis>
				</TitleWrapper>
				<SpaceWrapper>
					<SpaceAndTimeDisplay page={props.page} parent={props.selectProps} />
				</SpaceWrapper>
			</ContentContainerWithEllipsis>
		</PageItemContainer>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageItemContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	height: '34px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div({
	marginRight: token('space.100', '8px'),
	lineHeight: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainerWithEllipsis = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	overflow: 'hidden',
});
