import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import type { WorklogEntitiesState } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import type {
	ConfluencePage,
	ConfluencePages,
	ConfluenceWhiteboards,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { Agile } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import type { Tabs } from '@atlassian/jira-issue-shared-types/src/common/types/layout-item-type.tsx';
import type {
	IssueLinks,
	IssueLinkTypes,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { ParentIssue } from '@atlassian/jira-issue-shared-types/src/common/types/parent-issue-type.tsx';
import type { ServerRestPriority } from '@atlassian/jira-issue-shared-types/src/common/types/priority-type.tsx';
import type { ProjectStatus } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import type { RemoteIssueLinksState } from '@atlassian/jira-issue-shared-types/src/common/types/remote-issue-link-type.tsx';
import type { ServerRestStatus } from '@atlassian/jira-issue-shared-types/src/common/types/status-type.tsx';
import type { MyPreferences } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import type { Permissions } from '@atlassian/jira-issue-view-common-constants/src/permissions.tsx';
import type {
	Href,
	IssueId,
	IssueKey,
	IssueTypeId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { ApplicationRole } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import type { ActivityFeedUiState } from './activity-item-type.tsx';
import type { AssignIssueParentModalUiState } from './assign-issue-parent-modal-type.tsx';
import type { AttachmentsUiState } from './attachment-type.tsx';
import type { CardCover } from './card-cover-type.tsx';
import type { ChangeboardingUiState } from './changeboarding-type.tsx';
import type { ChildIssue } from './children-issues-type.tsx';
import type { CloneModalState } from './clone-modal-type.tsx';
import type { CommentsUiState, PersistedCommentsById } from './comment-type.tsx';
import type { ContextState } from './context-type.tsx';
import type { DraftsUiState } from './drafts-type.tsx';
import type { EcosystemState } from './ecosystem-types.tsx';
import type { FlagsUiState } from './flag-type.tsx';
import type { ForgeState } from './forge-types.tsx';
import type { GlanceUiState } from './glance-type.tsx';
import type { InvestigateIncidentUi } from './investigate-incident-type.tsx';
import type { IssueDeletionUiState } from './issue-deletion-type.tsx';
import type { IssueRemoteData } from './issue-remote-data-type.tsx';
import type { JiraSettingsType } from './jira-settings-type.tsx';
import type { IssueLoadingStage } from './loading-stage-type.tsx';
import type { LoomState } from './loom-type.tsx';
import type { MediaContext } from './media-context-type.tsx';
import type { ProformaState } from './proforma-type.tsx';
import type { Validators } from './project-validator-type.tsx';
import type { RequestPanelMetadata } from './request-panel-metadata-type.tsx';
import type { ShareAsCommentState } from './share-as-comment-type.tsx';
import type { ShortcutDialogUiState } from './shortcut-dialog.tsx';
import type { SubtaskState } from './subtask-type.tsx';
import type { TimestampDisplaySetting } from './timestamp-display-setting-type.tsx';
import type { UiConfigState } from './ui-config-type.tsx';
import type { UsersByName } from './user-type.tsx';
import type { ViewMountStatusUiState } from './view-mount-status-type.tsx';
import type { WebLinks } from './web-links-type.tsx';
import type { WorklogUiState } from './worklog-type.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Agile as AgileState, ParentIssue };

export type ParentIssueType = {
	readonly iconUrl: string;
	readonly id?: IssueTypeId; // Some graphql retrieval does not retrieve the id
	readonly name?: string;
	readonly avatarId?: number;
	readonly description?: string;
	readonly hierarchyLevel?: number;
	readonly self?: string;
	readonly subtask?: boolean;
};

export type IssueParentOption = {
	readonly id: string;
	readonly key: string;
	readonly fields: {
		readonly summary: string;
		readonly issuetype: ParentIssueType;
		readonly priority: ServerRestPriority;
		readonly status: ServerRestStatus;
	};
	readonly expand?: string;
	readonly self?: string;
};

// Response from server
export type IssueParentsResponse = {
	readonly issues: IssueParentOption[];
	readonly maxResults?: number;
	readonly startAt?: number;
	readonly total?: number;
};

export type IssueParentsWithMessage = {
	candidates: IssueParent[];
	message?: string;
};

// Representation of an issue parent after retrieval
export type IssueParent = {
	readonly id: IssueId;
	readonly key: IssueKey;
	readonly summary: string;
	readonly issueType: ParentIssueType;
	readonly color?: Color;
	readonly isDone?: boolean;
	readonly statusCategoryId?: string;
};

export const statusNone = 'NONE' as const;
export const statusPending = 'PENDING' as const;
export const statusComplete = 'COMPLETE' as const;
export const statusFailed = 'FAILED' as const;

export type FetchIssueTypesStatusState =
	| typeof statusNone
	| typeof statusPending
	| typeof statusComplete
	| typeof statusFailed;

type AuthoredTimestamp = {
	authorId: string;
	date: Date;
};

export type GraphQlIssueState = {
	id: string;
	viewScreenId: number;
	fields: FieldsState;
	tabs: Tabs;
};

export type FieldMeta = {
	required: boolean;
	hasDefaultValue: boolean;
};

export type IssueType = {
	id: number;
	iconUrl: string;
	name: string;
	subtask: boolean;
	fields?: {
		[key: string]: FieldMeta;
	};
};

export type Project = {
	projectId: number;
	projectName: string;
	projectKey: string;
	projectType: ProjectType;
	projectStatus?: ProjectStatus;
	isSimplified: boolean;
	issueTypes: IssueType[];
	avatarUrl: string;
	boardId?: string | undefined;
};

export type ChildrenIssuesState = ChildIssue[];

export type ChildPanelUiState = {
	childIssueQuickAddClickCount: number;
	issueInEpicQuickAddClickCount: number;
	subtaskQuickAddClickCount: number;
};

type Subtask = {
	[key: string]: SubtaskState;
};

export type Subtasks = Subtask[];

export type AppLinkAuthorisation = {
	baseUrl: string;
	authUrl: Href;
	requireCredentials: boolean;
};

export type AppLink = {
	id: string;
	name: string;
};

export type JiraAppLink = AppLink & AppLinkAuthorisation;

export type ConfluenceAppLink = AppLink & AppLinkAuthorisation;

export type MutableAppLink = JiraAppLink | ConfluenceAppLink;

export type JiraAppLinkFetchStatus = 'NOT_LOADED' | 'LOADING' | 'LOADED' | 'ERROR';

export type JiraAppLinksState = {
	status: JiraAppLinkFetchStatus;
	remoteLinks: JiraAppLink[];
};

export type IssueContentPanels = string[];

export type JpdIdeasState = {
	showLinkIdeaInput: boolean;
};

export type EntitiesState = Readonly<{
	applicationRoles?: ApplicationRole[]; // remove with isUseAppPermissionsFromTenantContextEnabled,
	cardCover: CardCover;
	childrenIssues: ChildrenIssuesState;
	comments: PersistedCommentsById;
	commentsStartIndex: number;
	confluenceAppLinks: ConfluenceAppLink[];
	confluencePages: ConfluencePages;
	confluenceWhiteboards: ConfluenceWhiteboards;
	createdDate?: AuthoredTimestamp;
	forge: ForgeState;
	ecosystem: EcosystemState;
	issue: GraphQlIssueState | null;
	issueContentPanels: IssueContentPanels;
	issueLinkTypes?: IssueLinkTypes;
	issueLinks: IssueLinks;
	issueRemoteData: IssueRemoteData;
	issueTypes: IssueType[];
	loadedComments: number;
	parentIssue: ParentIssue | null;
	parentLevelIssues: IssueParent[];
	permissions: Permissions;
	project?: Project;
	refreshedOn: number;
	requestPanelMetadata: RequestPanelMetadata;
	subtasks: Subtasks;
	jiraSettings: JiraSettingsType;
	totalComments: number;
	updatedDate?: AuthoredTimestamp;
	users: UsersByName;
	webLinks: WebLinks;
	worklog: WorklogEntitiesState;
	jiraAppLinks?: JiraAppLinksState;
	remoteIssueLinks: RemoteIssueLinksState;
	myPreferences?: MyPreferences;
	loom: LoomState;
	jpdIdeas: JpdIdeasState;
	jpdDeliveryIssueLinkTypeId?: string | null;
}>;

type EcosystemUiState = {
	glance: GlanceUiState | null;
};

export type IssueTypeUiState = {
	shouldShowIssueTypeList: boolean;
};
export type ErrorMessageUi = {
	traceId: string;
	errorMessage: string;
	endpoint: string;
	statusCode: number;
} | null;

export type UiState = Readonly<{
	activityFeed: ActivityFeedUiState;
	assignIssueParentModal: AssignIssueParentModalUiState;
	attachments: AttachmentsUiState;
	changeboarding: ChangeboardingUiState;
	childPanel: ChildPanelUiState;
	cloneModal: CloneModalState;
	comments: CommentsUiState;
	drafts: DraftsUiState;
	ecosystem: EcosystemUiState;
	error: string | null;
	errorMessage: ErrorMessageUi;
	fetchIssueTypesStatus: FetchIssueTypesStatusState;
	flags: FlagsUiState;
	doNotUseIsLoadingPromise: () => Promise<unknown> | null;
	investigateIncident: InvestigateIncidentUi;
	issueDeletion: IssueDeletionUiState;
	loadingStage: IssueLoadingStage;
	recentlyVisitedConfluencePages: ConfluencePage[];
	timestampDisplaySetting: TimestampDisplaySetting;
	uiConfig: UiConfigState;
	viewMountStatus: ViewMountStatusUiState;
	worklog: WorklogUiState;
	issueType: IssueTypeUiState;
	shortcutDialog: ShortcutDialogUiState;
	proforma: ProformaState;
}>;

export type ServerTimingState = {
	[key: string]: number;
};

export type TimingState = {
	server: ServerTimingState;
};

export type State = Readonly<{
	agile: Agile;
	context: ContextState;
	entities: EntitiesState;
	fields: FieldsState;
	mediaContext: MediaContext;
	shareAsComment: ShareAsCommentState;
	timing: TimingState;
	ui: UiState;
	validators: Validators;
}>;
