/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import RetryIcon from '@atlaskit/icon/glyph/retry';
import Tooltip from '@atlaskit/tooltip';

import messages from './messages';

type Props = {
	onClick: (event: React.MouseEvent) => void;
	tabIndex?: number;
	appearance?: IconButtonProps['appearance'];
	spacing?: IconButtonProps['spacing'];
};

export const RetryIconButton = ({ onClick, tabIndex, appearance, spacing }: Props) => {
	const { formatMessage } = useIntl();
	const tooltip = formatMessage(messages.retryPrompt);

	return (
		<Tooltip content={tooltip} position="top">
			<IconButton
				testId="retry-prompt-button"
				type="button"
				onClick={onClick}
				icon={RetryIcon}
				label={tooltip}
				appearance={appearance}
				spacing={spacing}
				tabIndex={tabIndex}
			/>
		</Tooltip>
	);
};
