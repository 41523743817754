import React from 'react';
import {
	IssueAdjustmentsAsync,
	type IssueAdjustmentsAsyncProps,
} from '@atlassian/jira-issue-adjustments/src/ui/uim-root-async/index.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export type UiModificationsJiraCoreProps = Omit<
	IssueAdjustmentsAsyncProps,
	'cloudId' | 'activationId'
>;

export const UiModificationsJiraCore = (props: UiModificationsJiraCoreProps) => {
	const cloudId = useCloudId();
	const activationId = useActivationId();

	return <IssueAdjustmentsAsync {...props} cloudId={cloudId} activationId={activationId} />;
};
