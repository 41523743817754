import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { UrlInlineEditView } from '@atlassian/jira-issue-field-url-inline-edit-full/src/ui/url/index.tsx';
import type { AggJiraUrl } from '@atlassian/jira-issue-field-url-inline-edit-full/src/ui/url/types.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutUrlField_IssueViewUrlField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutUrlField_IssueViewUrlField.graphql';

export interface IssueViewUrlFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutUrlField_IssueViewUrlField$key;
}

export const IssueViewUrlField = ({ area, fragmentKey }: IssueViewUrlFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutUrlField_IssueViewUrlField$key>(
		graphql`
			fragment ui_issueViewLayoutUrlField_IssueViewUrlField on JiraUrlField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...url_issueFieldUrlInlineEditFull_UrlInlineEditView
				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const onSubmit = useCallback(
		(uiAnalyticsEvent: UIAnalyticsEvent, value: AggJiraUrl) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraUrl) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	return (
		<IssueViewFieldHeading
			area={area}
			fragmentKey={data}
			testId={`issue.issue-view-layout.issue-view-url-field.${data.fieldId}`}
		>
			<Box xcss={fieldWrapperStyles}>
				<UrlInlineEditView
					fragmentRef={data}
					onUpdate={onSubmitSucceeded}
					onUpdateFailed={onSubmitFailed}
					onConfirm={onSubmit}
				/>
			</Box>
		</IssueViewFieldHeading>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
	marginTop: 'space.negative.100',
});
