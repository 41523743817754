import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	fireTrackAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetFieldByKey } from '../../selectors/field.tsx';
import type { Props, State } from '../../types.tsx';

export const updateDeliveryDateSelectedField =
	(
		fieldKey: FieldKey,
		selectedFieldKey: string | undefined,
		contextAnalyticsEvent: UIAnalyticsEvent,
	) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ fieldRemote, ideaTypes }: Props,
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	): Promise<Error | void> => {
		const state = getState();
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const currentField = createGetFieldByKey(fieldKey)(state)!;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const issueTypeId = ideaTypes![0].jiraIssueTypeId;

		const oldConfiguration = currentField.configuration ? currentField.configuration : {};
		const newConfiguration = { ...currentField.configuration };

		if (selectedFieldKey) {
			newConfiguration.customfieldKey = selectedFieldKey;
		} else {
			delete newConfiguration.customfieldKey;
		}

		const fields = { ...getState().fields };
		fields[fieldKey] = {
			...fields[fieldKey],
			configuration: newConfiguration,
		};
		setState({ fields });

		await fieldRemote.updateFieldConfiguration({
			fieldKey,
			issueTypeId,
			oldConfiguration,
			configuration: newConfiguration,
		});

		fireTrackAnalytics(contextAnalyticsEvent, 'issueFields updated', {
			issueFieldKey: fieldKey,
			issueFieldType: currentField.type,
			updatedItems: [{ name: 'customfieldKey' }],
		});
	};
