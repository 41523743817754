import { useCallback } from 'react';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';

const useConvertToSubtask = (
	itemKey: string,
	onClick?: (itemKey: string, event?: Event) => Promise<undefined> | undefined,
) => {
	const issueId = useIssueId();
	const href = `/secure/ConvertIssue.jspa?id=${issueId || ''}`;

	return useCallback(() => {
		onClick?.(itemKey);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.assign(href);
	}, [onClick, itemKey, href]);
};

export default useConvertToSubtask;
