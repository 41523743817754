import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssueType } from '../../project/types.tsx';
import { getFieldMappings } from '../selectors/fields.tsx';
import type { State, Props } from '../types.tsx';
import type { FieldMapping } from './field-mapping/types.tsx';

type IsMatchingConnectionFieldFilter = {
	fieldKey: FieldKey;
	localIssueId: string;
	state: State;
	props?: Props;
	skipEmptyFilter?: boolean;
	issueType: IssueType | IssueTypeFieldValue | undefined; // TODO: make issueType required once issue types are implemented
};

export const isMatchingConnectionFieldFilter = ({
	fieldKey,
	localIssueId,
	state,
	props,
	skipEmptyFilter = false,
	issueType,
}: IsMatchingConnectionFieldFilter) => {
	const fieldMappings = getFieldMappings(state, props);

	const issueFilters = fieldMappings[fieldKey].field?.configuration?.issueFilters || [];

	if (!issueFilters.length) {
		return skipEmptyFilter;
	}

	return issueFilters.some((filter) => {
		const filterFieldMapping = fieldMappings[filter.fieldKey];
		const filterField = filterFieldMapping?.field;

		if (!filterFieldMapping || !filterField) {
			return false;
		}

		if (filterField.type === FIELD_TYPES.SINGLE_SELECT) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const value = (filterFieldMapping as FieldMapping<OptionFieldValue>).valueAccessor(
				state,
				props,
				localIssueId,
			);

			const optionValue = filterField?.options?.find(
				(option) => option.jiraOptionId === value?.id,
			)?.value;

			return optionValue && filter.values.includes(optionValue);
		}

		if (filterField.type === FIELD_TYPES.ISSUE_TYPE) {
			return issueType && filter.values.includes(issueType.name);
		}

		// In case of unsupported field used in filter ignore it
		return false;
	});
};
