/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import { token } from '@atlaskit/tokens';

/**
 * @deprecated Use the `AtlassianIntelligenceLogo` component instead.
 */
export const LogoSvg = ({
	alt,
	isDisabled = false,
	className,
	size = 24,
	isPadded = true,
	style = {},
}: {
	alt: string;
	isDisabled?: boolean;
	isPadded?: boolean;
	/**
	 * @deprecated
	 * AI Logo no longer has a gradient
	 */
	colorStart?: string;
	/**
	 * @deprecated
	 * AI Logo no longer has a gradient
	 */
	colorStop?: string;
	className?: string;
	size?: number;
	style?: React.CSSProperties;
}) => {
	const stroke = isDisabled
		? token('color.icon.disabled', '#091E424F')
		: token('color.icon', '#44546F');

	const commonProps = {
		width: size,
		height: size,
		className,
		role: alt === '' ? 'presentation' : undefined,
		xmlns: 'http://www.w3.org/2000/svg',
		style,
	};

	return (
		<svg {...commonProps} viewBox={isPadded ? '0 0 24 24' : '0 0 16 16'}>
			{alt && <title>{alt}</title>}
			{isPadded ? (
				<React.Fragment>
					<path d="M14.5 14.5L17.5 17.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="square" />
					<path d="M9.5 14.5L6.5 17.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="square" />
					<path d="M9.5 9.5L6.5 6.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="square" />
					<path d="M14.5 9.5L17.5 6.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="square" />
					<path d="M12.0001 16V19" stroke={stroke} strokeWidth="1.5" />
					<path d="M8.00012 12L5.00012 12" stroke={stroke} strokeWidth="1.5" />
					<path d="M12.0001 8.00002L12.0001 4.99999" stroke={stroke} strokeWidth="1.5" />
					<path d="M16.0002 12L19.0002 12" stroke={stroke} strokeWidth="1.5" />
				</React.Fragment>
			) : (
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7.24999 4V1H8.74999V4H7.24999ZM5.46966 6.53033L1.46966 2.53033L2.53032 1.46967L6.53032 5.46967L5.46966 6.53033ZM9.46966 5.46967L13.4697 1.46967L14.5303 2.53033L10.5303 6.53033L9.46966 5.46967ZM0.999992 7.25H3.99999V8.75H0.999992V7.25ZM12 7.25H15V8.75H12V7.25ZM1.46966 13.4697L5.46966 9.46967L6.53032 10.5303L2.53032 14.5303L1.46966 13.4697ZM13.4697 14.5303L9.46966 10.5303L10.5303 9.46967L14.5303 13.4697L13.4697 14.5303ZM7.24999 15V12H8.74999V15H7.24999Z"
					fill="currentColor"
				/>
			)}
		</svg>
	);
};
