import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import memoizeOne from 'memoize-one';
import Button from '@atlaskit/button/custom-theme-button';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import { getMediaTypeFromMimeType } from '@atlaskit/media-common';
import type { MediaTableItem } from '@atlaskit/media-table';
import { toHumanReadableMediaSize } from '@atlaskit/media-ui';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { fg } from '@atlassian/jira-feature-gating';
import type { IntlShapeV2 as Intl } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useCanDeleteAttachment } from '@atlassian/jira-issue-attachments-base/src/controllers/index.tsx';
import { GoToButton } from '@atlassian/jira-issue-attachments-base/src/ui/goto-button/index.tsx';
import { listView } from '@atlassian/jira-issue-attachments-base/src/ui/types.tsx';
import { issueAttachmentsDeleteModalEntrypoint } from '@atlassian/jira-issue-attachments-base/src/ui/with-issue-attachments-delete/entrypoint.tsx';
import { isAttachmentNumberApproachLimit } from '@atlassian/jira-issue-attachments-limit/src/common/utils.tsx';
import {
	ORDER_FIELD,
	ATTACHMENT_PARENT_ISSUE,
} from '@atlassian/jira-issue-attachments-store/src/common/constants.tsx';
import type {
	Attachment,
	AttachmentId,
	AttachmentParent,
	OrderField,
} from '@atlassian/jira-issue-attachments-store/src/common/types.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { formatWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	DATE_COLUMN_WIDTH,
	DELETE_OR_GOTO_COLUMN_WIDTH,
	DOWNLOAD_COLUMN_WIDTH,
	LIST_VIEW_COLUMN_KEYS,
	SIZE_COLUMN_WIDTH,
	WIDTH_TO_HIDE_SIZE_COLUMN,
	ROW_HIGHLIGHT_CLASSNAME,
	TABLE_ROW_CLASSNAME,
} from './constants.tsx';
import DeleteButton from './delete-button/index.tsx';
import LockIcon from './lock-icon/index.tsx';
import messages from './messages.tsx';
import NameCell from './name-cell-temporary/index.tsx';
import type { ListViewColumnKey } from './types.tsx';

const createNameCell = (
	name: string,
	mimetype: string | null | undefined,
	isInternalComment: boolean,
	{ formatMessage }: Intl,
) =>
	fg('thor_attachment_table_a11y') ? (
		<>
			<VisuallyHidden testId="issue-attachments-table.ui.visually-hidden">
				{`${name} ${isInternalComment ? `- ${formatMessage(messages.internalOnlyLabel)}` : ''}`}
			</VisuallyHidden>
			<NoWrap aria-hidden>
				<NameCell
					text={name}
					mediaType={getMediaTypeFromMimeType(mimetype == null ? '' : mimetype)}
					startFixedChars={3}
					endFixedChars={4}
					icon={isInternalComment ? <LockIcon /> : undefined}
				/>
			</NoWrap>
		</>
	) : (
		<div
			role="listitem"
			aria-label={`${name} ${
				isInternalComment ? `- ${formatMessage(messages.internalOnlyLabel)}` : ''
			}`}
		>
			<NoWrap aria-hidden>
				<NameCell
					text={name}
					mediaType={getMediaTypeFromMimeType(mimetype == null ? '' : mimetype)}
					startFixedChars={3}
					endFixedChars={4}
					icon={isInternalComment ? <LockIcon /> : undefined}
				/>
			</NoWrap>
		</div>
	);

const createDeleteOrGoToCell = (
	attachmentId: AttachmentId,
	attachmentParent: AttachmentParent,
	attachmentParentId: string,
	onScrollToComments: () => void,
	onScrollToWorklogs: () => void,
	isInModal: boolean,
	issueKey: IssueKey,
) => {
	if (attachmentParent === ATTACHMENT_PARENT_ISSUE) {
		// no permission to delete attachments
		return <DeleteAttachment attachmentId={attachmentId} />;
	}

	// don't show goto button if list view is in modal
	if (isInModal) {
		return null;
	}
	// otherwise in COMMENT/WORKLOG or descritpion/custom field
	return (
		<DeleteOrGoToCellWrapper>
			<GoToButton
				attachmentParent={attachmentParent}
				attachmentParentId={attachmentParentId}
				attachmentView={listView}
				onScrollToComments={onScrollToComments}
				onScrollToWorklogs={onScrollToWorklogs}
				issueKey={issueKey}
				attachmentId={attachmentId}
			/>
		</DeleteOrGoToCellWrapper>
	);
};

const DeleteAttachment = ({ attachmentId }: { attachmentId: string }) => {
	const canDeleteAttachmentMap = useCanDeleteAttachment();
	const entryPointProps = useMemo(
		() => ({
			attachmentView: listView,
			deletingAttachmentId: attachmentId,
		}),
		[attachmentId],
	);
	const modalProps = useMemo(() => ({ width: 'small' }), []);
	if (!canDeleteAttachmentMap[attachmentId]) {
		return null;
	}
	return (
		<DeleteOrGoToCellWrapper>
			<ModalEntryPointPressableTrigger
				entryPoint={issueAttachmentsDeleteModalEntrypoint}
				entryPointProps={entryPointProps}
				modalProps={modalProps}
				useInternalModal
				interactionName="issue-attachment-table-delete-attachment"
			>
				{({ ref }) => (
					<DeleteButton ref={ref} attachmentId={attachmentId} onDeleteAttachment={noop} />
				)}
			</ModalEntryPointPressableTrigger>
		</DeleteOrGoToCellWrapper>
	);
};
/**
 * Download button on attachment table is from Media attachment table. The button and media api will
 * be broken when JWT token is too long. This custom download button is a fallback when original button is broken.
 */
export const createCustomDownload = (id: string) => (
	<Button
		testId={`issue-attachments-table.ui.custom-download.${id}`}
		appearance="subtle"
		href={`/rest/api/3/attachment/content/${id}`}
		iconAfter={<DownloadIcon label="download" />}
		onClick={(event) => event.stopPropagation()}
		theme={(current, themeProps) => ({
			buttonStyles: {
				...current(themeProps).buttonStyles,
				minWidth: 'max-content',
				'&:hover': {
					background: token('color.background.neutral.hovered', N40),
				},
			},
			spinnerStyles: current(themeProps).spinnerStyles,
		})}
	/>
);

export const getShouldShowSizeColumn = (containerWidth?: number) =>
	containerWidth === undefined || containerWidth >= WIDTH_TO_HIDE_SIZE_COLUMN;

export const transformAttachments = memoizeOne(
	(
		attachments: Attachment[],
		totalCount: number,
		containerWidth: number | undefined,
		locale: string,
		onScrollToWorklogs: () => void,
		onScrollToComments: () => void,
		isInModal: boolean,
		issueKey: IssueKey,
		intl: Intl,
		isJSM: boolean,
	): MediaTableItem[] => {
		const shouldShowSizeColumn = getShouldShowSizeColumn(containerWidth);
		const shouldShowCustomDownload = isAttachmentNumberApproachLimit(totalCount);

		return attachments.map((attachment: Attachment) => {
			const isInternalAttachment = attachment.hasRestrictedParent;
			const isHighlightEnabled = isInternalAttachment && isJSM;

			return {
				data: {
					file: createNameCell(
						attachment.filename,
						attachment.mimetype,
						isInternalAttachment,
						intl,
					),
					date: formatWithLocale(
						new Date(attachment.createdDate * 1000),
						'dd MMM yyyy h:mmaaa',
						locale,
					),
					...(shouldShowSizeColumn ? { size: toHumanReadableMediaSize(attachment.filesize) } : {}),
					deleteOrGoto: createDeleteOrGoToCell(
						attachment.id,
						attachment.parentName,
						attachment.parentId,
						onScrollToComments,
						onScrollToWorklogs,
						isInModal,
						issueKey,
					),
					...(shouldShowCustomDownload
						? { customDownload: createCustomDownload(attachment.id) }
						: {}),
				},
				identifier: {
					mediaItemType: 'file',
					id: attachment.mediaApiFileId,
				},
				rowProps: {
					className: isHighlightEnabled ? ROW_HIGHLIGHT_CLASSNAME : '',
				},
			};
		});
	},
);

export const getColumns = (
	totalCount: number,
	containerWidth: number | undefined,
	{ formatMessage }: Intl,
) => ({
	cells: [
		{
			key: LIST_VIEW_COLUMN_KEYS.FILE,
			content: <NoWrap>{formatMessage(messages.fileName)}</NoWrap>,
			isSortable: true,
		},
		...(getShouldShowSizeColumn(containerWidth)
			? [
					{
						key: LIST_VIEW_COLUMN_KEYS.SIZE,
						content: formatMessage(messages.size),
						isSortable: true,
						inlineStyles: {
							width: `${SIZE_COLUMN_WIDTH}px`,
						},
					},
				]
			: []),
		{
			key: LIST_VIEW_COLUMN_KEYS.DATE,
			content: formatMessage(messages.date),
			isSortable: true,
			inlineStyles: {
				width: `${DATE_COLUMN_WIDTH}px`,
			},
		},
		{
			key: LIST_VIEW_COLUMN_KEYS.DELETE_OR_GOTO,
			content: <VisuallyHidden>{formatMessage(messages.delete)}</VisuallyHidden>,
			inlineStyles: {
				width: `${DELETE_OR_GOTO_COLUMN_WIDTH}px`,
			},
			width: 10,
		},
		{
			key: isAttachmentNumberApproachLimit(totalCount)
				? LIST_VIEW_COLUMN_KEYS.CUSTOM_DOWNLOAD
				: LIST_VIEW_COLUMN_KEYS.DOWNLOAD,
			content: <VisuallyHidden>{formatMessage(messages.download)}</VisuallyHidden>,
			inlineStyles: {
				width: `${DOWNLOAD_COLUMN_WIDTH}px`,
			},
		},
	],
});

export const getOrderFieldFromColumnKey = (columnKey: ListViewColumnKey) => {
	switch (columnKey) {
		case LIST_VIEW_COLUMN_KEYS.SIZE:
			return ORDER_FIELD.FILESIZE;
		case LIST_VIEW_COLUMN_KEYS.FILE:
			return ORDER_FIELD.FILENAME;
		default:
			return ORDER_FIELD.CREATED;
	}
};

export const getColumnKeyFromOrderField = (orderField: OrderField) => {
	switch (orderField) {
		case ORDER_FIELD.FILESIZE:
			return LIST_VIEW_COLUMN_KEYS.SIZE;
		case ORDER_FIELD.FILENAME:
			return LIST_VIEW_COLUMN_KEYS.FILE;
		case ORDER_FIELD.CREATED:
			return LIST_VIEW_COLUMN_KEYS.DATE;
		default:
			// The orderField must be matched
			return undefined;
	}
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoWrap = styled.span({
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeleteOrGoToCellWrapper = styled.div({
	/** Cancel out cell horizontal padding coming from the media table */
	marginTop: 0,
	marginBottom: 0,
	marginLeft: token('space.negative.100', '-8px'),
	marginRight: token('space.negative.100', '-8px'),
	minWidth: 'calc(100% + 16px)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`.${TABLE_ROW_CLASSNAME} &`]: {
		opacity: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`.${TABLE_ROW_CLASSNAME}:hover &, .${TABLE_ROW_CLASSNAME}:focus &, .${TABLE_ROW_CLASSNAME}:focus-within &`]:
		{
			opacity: 1,
		},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	NoWrap,
};
