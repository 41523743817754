import { SET_VALUE_ARRAY_SIZE_LIMIT } from '@atlassian/jira-issue-adjustments/src/common/constants.tsx';
import { baseScopesRequiredPerField } from '@atlassian/jira-issue-adjustments/src/common/utils/filter-based-on-scopes/fields-scopes.tsx';
import { isAriResourceIdValid } from '../utils/is-ari-resource-id-valid/index.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string/index.tsx';

export type PublicGetValue = {
	id: string;
	value: string;
};

export type MultiSelectPublicSetValue = string[];

export const validateMultiSelectSetValue = (value: unknown): value is MultiSelectPublicSetValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every(isNotEmptyString);

export const validateMultiSelectWithAriSetValue = (
	value: unknown,
): value is MultiSelectPublicSetValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every((val) => isNotEmptyString(val) && isAriResourceIdValid(val));

export const multiSelectScopes = baseScopesRequiredPerField;
