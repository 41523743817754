import { defineMessages } from 'react-intl-next';

const MESSAGE_IDS = [
	'townsquare.api.goal-state.on-track',
	'townsquare.api.goal-state.off-track',
	'townsquare.api.goal-state.at-risk',
	'townsquare.api.goal-state.pending',
	'townsquare.api.goal-state.completed',
	'townsquare.api.goal-state.cancelled',
	'townsquare.api.goal-state.paused',
	'GenericIconLabel',
] as const;

export type MessageId = (typeof MESSAGE_IDS)[number];

export const isMessageId = (value: string): value is MessageId =>
	(MESSAGE_IDS as ReadonlyArray<string>).includes(value);

export const messages = defineMessages({
	GenericIconLabel: {
		id: 'Goals.icon.label.generic',
		defaultMessage: 'Goal icon',
		description:
			'A generic label for the goal icon, for when a label is not supplied and cannot be inferred from the appearance prop.',
	},
	'townsquare.api.goal-state.on-track': {
		id: 'Goals.icon.label.on-track',
		defaultMessage: 'On track',
		description: 'The label for the "on track" goal status',
	},
	'townsquare.api.goal-state.off-track': {
		id: 'Goals.icon.label.off-track',
		defaultMessage: 'Off track',
		description: 'The label for the "off track" goal status',
	},
	'townsquare.api.goal-state.at-risk': {
		id: 'Goals.icon.label.at-risk',
		defaultMessage: 'At risk',
		description: 'The label for the "at risk" goal status',
	},
	'townsquare.api.goal-state.pending': {
		id: 'Goals.icon.label.pending',
		defaultMessage: 'Pending',
		description: 'The label for the "pending" goal status',
	},
	'townsquare.api.goal-state.completed': {
		id: 'Goals.icon.label.completed',
		defaultMessage: 'Completed',
		description: 'The label for the "completed" goal status',
	},
	'townsquare.api.goal-state.cancelled': {
		id: 'Goals.icon.label.cancelled',
		defaultMessage: 'Cancelled',
		description: 'The label for the "cancelled" goal status',
	},
	'townsquare.api.goal-state.paused': {
		id: 'Goals.icon.label.paused',
		defaultMessage: 'Paused',
		description: 'The label for the "paused" goal status',
	},
});
