import {
	type ConversationStarter,
	getConversationStarters,
	type StaticAgentConversationStarter,
} from '@atlaskit/rovo-agent-components';
import { type usePublish } from '@atlaskit/rovo-triggers';
import { type Agent } from '@atlassian/conversation-assistant-service';
// Testing data until we start to load it from client.
export { ChatAgent, CommsCrafterAgent, JobWriterAgent, A11yMateAgent } from './mock-agents';

export type EditorAgent = Pick<
	Agent,
	'id' | 'name' | 'description' | 'creator' | 'visibility' | 'icon'
> & {
	// These are from Agent, just renamed to camel case.
	namedId: Agent['named_id'];
	systemPromptTemplate: Agent['system_prompt_template'];
	creatorType: Agent['creator_type'];
	isDefault: Agent['is_default'];
	identityAccountId?: Agent['identity_account_id'];
	externalConfigReference?: Agent['external_config_reference'];

	// Additional fields we can populate on client side
	// color: string;
	// iconInitials: string;
	conversationStarters: (ConversationStarter | StaticAgentConversationStarter)[];

	// Populated when creating agent config items in UserInputCommandPaletteWithLogic.
} & (
		| { channelId: string; publish: ReturnType<typeof usePublish> }
		| { channelId?: never; publish?: never }
	);

/**
 * Pulls out the necessary fields from the raw agent data received to create an EditorAgent.
 * We can use this to populate additional fields like color, iconInitials etc.
 *
 * @param agent
 */
export const getEditorAgent = (agent: Agent): EditorAgent => {
	const {
		id,
		name,
		description,
		named_id,
		creator_type,
		system_prompt_template,
		creator,
		visibility,
		is_default,
		identity_account_id,
		icon,
	} = agent;

	return {
		id,
		name,
		description,
		namedId: named_id,
		creatorType: creator_type,
		systemPromptTemplate: system_prompt_template,
		creator: creator ?? undefined,
		visibility,
		isDefault: is_default,
		identityAccountId: identity_account_id,
		icon: icon,
		conversationStarters: getConversationStarters({
			isAgentDefault: is_default,
			userDefinedConversationStarters: agent.user_defined_conversation_starters?.map((starter) => {
				return {
					message: starter,
					type: 'user-defined',
				};
			}),
		}).combinedConversationStarters,
		externalConfigReference: agent.external_config_reference,
	};
};
