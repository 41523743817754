import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const collectionGlyph = (props: CustomGlyphProps) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="#F1F2F4" />
		<rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#DCDFE4" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.4 8.00002L10.8 6.40002H8.00005C7.11639 6.40002 6.40005 7.11637 6.40005 8.00002V10.8553C5.59259 11.0734 5.05989 11.9044 5.26273 12.7665L6.10981 16.3667C6.27986 17.0894 6.92479 17.6002 7.66727 17.6002H15.79C15.7957 17.6002 15.8013 17.6001 15.807 17.6H16C16.8837 17.6 17.6 16.8837 17.6 16V9.60002C17.6 8.71637 16.8837 8.00002 16 8.00002H12.4ZM16.4 15.9002V9.60002C16.4 9.37911 16.221 9.20002 16 9.20002H12.4C12.0818 9.20002 11.7766 9.0736 11.5515 8.84855L10.303 7.60002H8.00005C7.77913 7.60002 7.60005 7.77911 7.60005 8.00002V10.8H13.9328C14.6753 10.8 15.3202 11.3108 15.4903 12.0336L16.4 15.9002ZM6.8202 12H13.9328C14.1184 12 14.2797 12.1277 14.3222 12.3084L15.2849 16.4002H7.66727C7.48165 16.4002 7.32042 16.2725 7.27791 16.0918L6.43083 12.4916C6.37175 12.2406 6.56226 12 6.8202 12Z"
			fill="#626F86"
		/>
	</svg>
);
