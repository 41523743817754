import React, { forwardRef } from 'react';
import { styled } from '@compiled/react';
import { visuallyHiddenStyles } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../../common/messages.tsx';
import type { Props } from './types.tsx';

/**
 * Edit button that is visually hidden from the UI (but still keyboard accessible) to be rendered within the read view
 * of an inline edit component to allow the user to transition to the edit view.
 */
export const EditButtonHidden = forwardRef<HTMLButtonElement, Props>(
	({ context: { onEdit }, label, onBlur, onFocus }, ref) => {
		const { formatMessage } = useIntl();
		const finalEditButtonLabel = label ?? formatMessage(messages.editButtonLabel);
		return (
			<HiddenButton
				aria-label={finalEditButtonLabel}
				onBlur={onBlur}
				onFocus={onFocus}
				onClick={onEdit}
				ref={ref}
				type="button"
			/>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const HiddenButton = styled.button(visuallyHiddenStyles);
