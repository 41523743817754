import React from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import useToggleFlag from './hook.tsx';
import type { Props } from './types.tsx';

export const FlagIssueActionView = ({ onClick, itemKey, label }: Props) => {
	const toggleFlag = useToggleFlag(itemKey, onClick);

	return (
		<DropdownItem
			key="flagIssue"
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.foundation.issue-actions.add-to-issue-dropdown-group.flag.flag-issue-action-view"
			onClick={toggleFlag}
		>
			{label}
		</DropdownItem>
	);
};

export default FlagIssueActionView;
