import React from 'react';
import { useLazyLoadQuery, usePreloadedQuery, type PreloadedQuery } from 'react-relay';
import { JiraSiteAri } from '@atlassian/ari/jira/site';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import { ISSUE_AGG_OPERATION_NAME } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { getMainIssueViewAggQueryVariables } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/utils.tsx';
import { smartRepliesOptInStatusResources } from '@atlassian/jira-issue-smart-replies-preferences/src/index.tsx';
import { ISSUE_CRITICAL_DATA_AGG_SUSPENSE } from '@atlassian/jira-issue-view-common-constants/src/mark-types.tsx';
import { useLazyLoadingResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/lazy-load-resource-manager/index.tsx';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import { IssueViewFetchError } from '@atlassian/jira-issue-view-errors/src/common/utils/issue-view-fetch-error/index.tsx';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import type { AGGError } from '@atlassian/jira-relay-errors/src/index.tsx';
import issueAggQuery, {
	type mainIssueAggQuery as mainIssueAggQueryType,
	type mainIssueAggQuery$data as mainIssueAggQueryResponse,
} from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import { usePrefetchableResource } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-init-fetch-resource/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useResource } from '@atlassian/react-resource-router/src/resources/controllers/use-resource';
import { getTraceIds } from '@atlassian/relay-traceid';
import { blockedOnBackendEntryLog } from '../../../../common/metrics/reporters/backend-blocking-time-reporter.tsx';
import { useIssueRouteResourceManager } from './route-resources/index.tsx';

const extractFirstErrorFromRelayError = (error: Error) => {
	if (!('source' in error)) {
		return null;
	}

	const errorSource = error.source;
	const errors =
		typeof errorSource === 'object' &&
		errorSource != null &&
		'errors' in errorSource &&
		errorSource.errors;
	const firstError: AGGError | null = Array.isArray(errors) ? errors[0] : null;

	const statusCode = firstError?.extensions?.statusCode;
	const message = firstError?.message;
	return statusCode ? { statusCode, message } : null;
};

/**
 * Handler for errors caught when using a Relay hook
 * @throws original error with an added traceId
 * @param err the caught error
 */
const updateAndRethrowRelayQueryError = (err: unknown): void => {
	if (!(err instanceof Error)) {
		return;
	}
	const issueAggQueryTraceIds = getTraceIds(ISSUE_AGG_OPERATION_NAME);
	const lastTraceIdIndex = issueAggQueryTraceIds.length - 1;
	const traceId = issueAggQueryTraceIds[lastTraceIdIndex];

	const error = extractFirstErrorFromRelayError(err);
	const userExperienceDefaultMessage = 'CouldntConnectToIssueScreen';
	if (error) {
		if (fg('issue-view-add-userexperience-attribute-taskfail')) {
			throw new IssueViewFetchError(
				new FetchError(error.statusCode, error.message),
				fg('issue-view-added-endpoint-attribute-in-taskfail') ? ISSUE_AGG_OPERATION_NAME : '',
				traceId,
				true,
				error.statusCode >= 500 ? userExperienceDefaultMessage : 'unknownExperience',
			);
		} else {
			throw new IssueViewFetchError(
				new FetchError(error.statusCode, error.message),
				fg('issue-view-added-endpoint-attribute-in-taskfail') ? ISSUE_AGG_OPERATION_NAME : '',
				traceId,
				true,
			);
		}
	} else {
		throw new IssueViewFetchError(err, 'RelayErrorWithoutStatusCode', traceId, true);
	}
};

type IssueKeyAndChildrenPropsProps = {
	issueKey: string;
	children: (childrenProps: {
		prefetchedResourceManager: ResourceManager;
		issueAggQueryData: mainIssueAggQueryResponse;
	}) => JSX.Element;
};

/**
 * See {@link IssueViewCriticalQueryPreloader}
 */
const IssueViewCriticalQueryPreloaderInner = ({
	issueKey,
	children,
}: IssueKeyAndChildrenPropsProps) => {
	blockedOnBackendEntryLog.initialiseBlockedOnBackendStateForUpdatedKey(issueKey);
	blockedOnBackendEntryLog.markStartBackendBlockingTimestamp(ISSUE_CRITICAL_DATA_AGG_SUSPENSE);
	const cloudId = useCloudId();
	const projectKey = extractProjectKey(issueKey);
	const siteAri = JiraSiteAri.create({ siteId: cloudId }).toString();

	let issueAggQueryData;
	let prefetchedResourceManager;
	try {
		// This will internally kick off a relay fetch in the render phase (alongside a call to gira)
		prefetchedResourceManager = useLazyLoadingResourceManager(issueKey);

		// We expect Relay to internally dedupe AGG fetches from useLazyLoadingResourceManager with this useLazyLoadQuery
		// This will then suspend on the AGG response
		issueAggQueryData = useLazyLoadQuery<mainIssueAggQueryType>(
			issueAggQuery,
			{
				cloudId,
				issueKey,
				projectKey,
				siteAri,
				...getMainIssueViewAggQueryVariables(),
			},
			{ fetchPolicy: 'store-and-network' },
		);
	} catch (err) {
		updateAndRethrowRelayQueryError(err);
		throw err;
	}

	blockedOnBackendEntryLog.markEndBackendBlockingTimestamp(ISSUE_CRITICAL_DATA_AGG_SUSPENSE);

	return children({ issueAggQueryData, prefetchedResourceManager });
};

/**
 * Wrapper component for the {@link AppProvider} which takes a children-render-function
 * and provides it with a relay fragment and prefetchedResourceManager.
 * Triggers loading of issue view data fetches as soon as the issueKey changes.
 * Throws suspense for some of the data (AGG, not the gira fetch).
 *
 * Alternative to {@link IssueViewRouteResourceConsumer}, this is for use in
 * issue-view apps without route-resources wired up.
 * */
const IssueViewCriticalQueryPreloaderWithoutSmartRepliesResource = (
	props: IssueKeyAndChildrenPropsProps,
) => (
	<JiraRelayEnvironmentProvider>
		<IssueViewCriticalQueryPreloaderInner {...props} />
	</JiraRelayEnvironmentProvider>
);

/**
 * Wrapper component for the {@link AppProvider} which takes a children-render-function
 * and provides it with a relay fragment and prefetchedResourceManager.
 * Triggers prefetching of Smart Replies preferences resources before fetching other issue resources.
 * Triggers loading of issue view data fetches as soon as the issueKey changes.
 * Throws suspense for some of the data (AGG, not the gira fetch).
 *
 * Alternative to {@link IssueViewRouteResourceConsumer}, this is for use in
 * issue-view apps without route-resources wired up.
 * */
const IssueViewCriticalQueryPreloaderWithSmartRepliesResource = (
	props: IssueKeyAndChildrenPropsProps,
) => {
	usePrefetchableResource(useResource(smartRepliesOptInStatusResources), true);
	return (
		<JiraRelayEnvironmentProvider>
			<IssueViewCriticalQueryPreloaderInner {...props} />
		</JiraRelayEnvironmentProvider>
	);
};

export const IssueViewCriticalQueryPreloader = componentWithFG(
	'smart_replies_m1_jira_labs',
	IssueViewCriticalQueryPreloaderWithSmartRepliesResource,
	IssueViewCriticalQueryPreloaderWithoutSmartRepliesResource,
);

/**
 * Wrapper component for the {@link AppProvider} which takes a children-render-function
 * and provides it with a relay fragment and prefetchedResourceManager.
 * Subscribes to route-resources for issue view data.
 * Throws suspense for some of the data (AGG, not the gira fetch).
 *
 * Alternative to {@link IssueViewCriticalQueryPreloader}, this will not preload data
 * if the route resources it subscribes to are not wired up.
 * */
export const IssueViewRouteResourceConsumer = ({
	issueKey,
	preloadedQuery,
	children,
}: {
	issueKey: string;
	preloadedQuery: PreloadedQuery<mainIssueAggQueryType>;
	children: (props: {
		prefetchedResourceManager: ResourceManager;
		issueAggQueryData: mainIssueAggQueryResponse;
	}) => JSX.Element;
}) => {
	blockedOnBackendEntryLog.initialiseBlockedOnBackendStateForUpdatedKey(issueKey);
	blockedOnBackendEntryLog.markStartBackendBlockingTimestamp(ISSUE_CRITICAL_DATA_AGG_SUSPENSE);
	let issueAggQueryData;
	try {
		// This will trigger suspense if the data has not yet arrived.
		issueAggQueryData = usePreloadedQuery<mainIssueAggQueryType>(issueAggQuery, preloadedQuery);
	} catch (err) {
		updateAndRethrowRelayQueryError(err);
		throw err;
	}
	blockedOnBackendEntryLog.markEndBackendBlockingTimestamp(ISSUE_CRITICAL_DATA_AGG_SUSPENSE);
	const prefetchedResourceManager = useIssueRouteResourceManager({
		issueKey,
		preloadedQuery,
	});

	return children({ issueAggQueryData, prefetchedResourceManager });
};
