import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'issue-field-classic-story-point.error-title',
		defaultMessage: "We couldn't set the story point value",
		description:
			'Error message informing the user that updating the story points estimation field failed',
	},
	errorMessage: {
		id: 'issue-field-classic-story-point.error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Error message when save fails',
	},
	negativeNumbersNotAllowed: {
		id: 'issue-field-classic-story-point.negative-numbers-not-allowed',
		defaultMessage: "Time for some positivity. Story point estimates can't be negative.",
		description: '',
	},
});
