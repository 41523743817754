// This component deals exclusively with the creation of new comments.
// For editing, there is a separate version available from @atlassian/jira-issue-comment-base

import React, { type ComponentPropsWithoutRef, type ComponentType } from 'react';
import { CannedCommentsPlaceHolderWithClick } from '@atlassian/jira-canned-comments/src/ui/canned-comments-placeholder/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { LazyIssueEditor } from '@atlassian/jira-issue-view-common/src/component/editor/issue-editor.tsx';
import { useHandleQuickReply } from '@atlassian/jira-quick-reply/src/controllers/use-handle-quick-reply/index.tsx';
import { CommentsPlaceholder } from './comments-placeholder.tsx';
import { enhanceIssueCommentEditor, type IssueCommentEditorPropsT } from './common.tsx';

type SmartRepliesType = {
	isSmartRepliesEnabled?: boolean;
};

type QuickRepliesType = {
	isQuickRepliesEnabled?: boolean;
};

export type IssueCommentEditorProps = IssueCommentEditorPropsT<typeof LazyIssueEditor> &
	SmartRepliesType &
	QuickRepliesType;

const IssueEditorWithExperiment = ({
	isSmartRepliesEnabled,
	isQuickRepliesEnabled,
	...props
}: ComponentPropsWithoutRef<typeof LazyIssueEditor> & SmartRepliesType & QuickRepliesType) => {
	const { hasQuickReply } = isQuickRepliesEnabled
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useHandleQuickReply(props.onChange)
		: { hasQuickReply: false };

	// Statsig Experiment exposure gets fired here
	if (!hasQuickReply && isSmartRepliesEnabled && expVal('smart_replies_m1', 'isEnabled', false)) {
		if (!props.isExpanded) {
			return (
				<JSErrorBoundary
					id="smartRepliesCannedCommentPlacholder"
					packageName="jiraIssueViewActivityComment"
					teamName="Jlove-Makkuro"
					fallback={() => <LazyIssueEditor {...props} />}
				>
					<CannedCommentsPlaceHolderWithClick
						onChange={props.onChange}
						onExpanded={props.onExpanded}
						collapsedPlaceholder={props.collapsedPlaceholder}
					/>
				</JSErrorBoundary>
			);
		}
		return <LazyIssueEditor {...props} />;
	}

	if (!props.isExpanded) {
		return (
			<CommentsPlaceholder
				placeholder={props.collapsedPlaceholder}
				onFocus={props.onExpanded}
				onClick={props.onExpanded}
			/>
		);
	}

	return <LazyIssueEditor {...props} />;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default enhanceIssueCommentEditor(
	IssueEditorWithExperiment,
) as ComponentType<IssueCommentEditorProps>;
