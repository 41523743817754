import noop from 'lodash/noop';
import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { toBaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	SET_CONTEXT,
	SET_ONCHANGE,
	REQUIRES_FULL_CREATE_CHECK_SUCCESS,
	REQUIRES_FULL_CREATE_CHECK_FAILED,
	SET_IS_ISSUE_VIEW_COMPLETE,
	type SetContextAction,
	type SetOnChangeAction,
	type RequiresFullCreateCheckSuccessAction,
	type RequiresFullCreateCheckFailedAction,
	type SetIsIssueViewCompleteAction,
} from './actions.tsx';
import type { ContextState } from './types.tsx';

type Action =
	| SetContextAction
	| SetOnChangeAction
	| RequiresFullCreateCheckSuccessAction
	| RequiresFullCreateCheckFailedAction
	| SetIsIssueViewCompleteAction;

const defaultState: ContextState = {
	baseUrl: toBaseUrl(''),
	locale: defaultLocale,
	user: null,
	childIssuesPanelType: null,
	supportsIssueCreation: false,
	requiresFullCreate: false,
	requiresFullCreateByIssueType: {},
	parentKey: null,
	customFieldIdRank: null,
	epicLinkFieldKey: null,
	parentId: null,
	projectId: null,
	parentIssueTypeId: null,
	messages: null,
	onNavigateToNewIssue: noop,
	onChange: noop,
	isIssueViewComplete: false,
	projectType: null,
	application: null,
	edition: null,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onEditAssociatedIssue: () => {},
	isSimplifiedProject: false,
	filterSubtasks: null,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ContextState = defaultState, action: Action): ContextState => {
	switch (action.type) {
		case SET_CONTEXT:
			return {
				...state,
				...action.payload,
			};

		case SET_ONCHANGE:
			return {
				...state,
				onChange: action.payload,
			};

		case REQUIRES_FULL_CREATE_CHECK_SUCCESS:
			return {
				...state,
				...action.payload,
			};

		case REQUIRES_FULL_CREATE_CHECK_FAILED:
			return {
				...state,
				requiresFullCreate: true,
				requiresFullCreateByIssueType: {},
			};

		case SET_IS_ISSUE_VIEW_COMPLETE:
			return {
				...state,
				isIssueViewComplete: action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
