import React, { Component, type ReactElement, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { fg } from '@atlassian/jira-feature-gating';
import type { IntlShape } from '@atlassian/jira-intl';
import { IssueEditorBoundary as IssueCommentEditorBoundary } from '@atlassian/jira-issue-view-common/src/component/editor/async.tsx';
import type { RenderCustomSecondaryToolbar } from '@atlassian/jira-issue-view-common/src/component/editor/index.tsx';
import ServiceDeskCommentEditorContainer from '@atlassian/jira-issue-view-servicedesk-editor-container/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import messages from '../messages.tsx';
import type { ConnectedEditorProps } from './comment-editor/index.tsx';
import Renderer from './comment-renderer/index.tsx';

export type OwnProps = {
	canEdit: boolean;
	isEditing: boolean;
	bodyAdf?: ADF;
	draftValue?: ADF;
	commentId: string;
	secondaryToolbarComponents?: ReactElement[];
	onSave: (arg1: ADF) => void;
	onCancel: () => void;
	onChange: (arg1: ADF) => void;
	onPaste: (arg1: string) => void;
	onBlur: () => void;
	onHideSave: () => void;
};
type IntlProps = {
	intl: IntlShape;
};
export type StateProps = {
	isServiceDesk: boolean;
};
export type Props = OwnProps & StateProps & IntlProps;
// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueCommentEditor = lazyForPaint<ComponentType<ConnectedEditorProps>>(
	() => import(/* webpackChunkName: "async-editor-view" */ './comment-editor'),
);
// eslint-disable-next-line jira/react/no-class-components
export class CommentContent extends Component<Props> {
	static displayName = 'CommentContent';

	static defaultProps = {
		isServiceDesk: false,
		onBlur: noop,
		onSave: noop,
		onCancel: noop,
		onChange: noop,
		onPaste: noop,
		onHideSave: noop,
	};

	getEditValue = () => this.props.draftValue || this.props.bodyAdf;

	getIssueCommentEditor = ({
		assistiveLabel = '',
		renderCustomSecondaryToolbar,
	}: {
		assistiveLabel: string;
		renderCustomSecondaryToolbar?: RenderCustomSecondaryToolbar;
	}) => {
		const editorProps: ConnectedEditorProps = {
			isExpanded: true,
			isExpandedByDefault: true,
			shouldFocus: true,
			defaultValue: this.getEditValue(),
			secondaryToolbarComponents: this.props.secondaryToolbarComponents,
			onBlur: this.props.onBlur,
			onSave: this.props.onSave,
			onCancel: this.props.onCancel,
			onChange: this.props.onChange,
			onPaste: this.props.onPaste,
			onHideSave: this.props.onHideSave,
		};
		return (
			<IssueCommentEditorBoundary
				packageName="issue"
				fallback={
					<Renderer
						key={`comment-${this.props.commentId}`}
						adf={this.props.bodyAdf}
						canEdit={this.props.canEdit}
						commentId={this.props.commentId}
					/>
				}
			>
				<IssueCommentEditor
					{...editorProps}
					{...(assistiveLabel && { assistiveLabel })}
					{...(renderCustomSecondaryToolbar &&
						fg('jcs_issue_layout_eap') && { renderCustomSecondaryToolbar })}
				/>
			</IssueCommentEditorBoundary>
		);
	};

	renderEditor = () =>
		this.props.isServiceDesk ? (
			<ServiceDeskCommentEditorContainer isExpanded commentId={this.props.commentId}>
				{this.getIssueCommentEditor}
			</ServiceDeskCommentEditorContainer>
		) : (
			this.getIssueCommentEditor({
				assistiveLabel: this.props.intl.formatMessage(messages.comment),
			})
		);

	render() {
		return (
			<EditorContainer>
				{this.props.isEditing ? (
					this.renderEditor()
				) : (
					<Renderer
						key={`comment-${this.props.commentId}`}
						adf={this.props.bodyAdf}
						canEdit={this.props.canEdit}
						commentId={this.props.commentId}
					/>
				)}
			</EditorContainer>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EditorContainer = styled.div({
	/* 100% is needed to make the text wrap in IE11 - without it,
     the editor stays on a single line and grows horizontally. */
	flex: '1 1 100%',
	/* See: https://css-tricks.com/flexbox-truncated-text/ */
	minWidth: 0,
	/* override the font size of add comment placeholder */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	input: {
		font: 'inherit',
	},
});
