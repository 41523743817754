import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	archive: {
		id: 'issue-view-foundation.issue-actions.issue-manipulation-dropdown-group.archive-issue.archive',
		defaultMessage: 'Archive',
		description: 'Label for the issue actions item to archive the issue',
	},
	archiveIssue: {
		id: 'issue-view-foundation.issue-actions.issue-manipulation-dropdown-group.archive-issue.archive-issue',
		defaultMessage: 'Archive issue',
		description: 'Label for the issue actions in the dot (.) shortcut item to archive the issue',
	},
});
