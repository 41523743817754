import React from 'react';
import flow from 'lodash/flow';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { deleteWebLinkRequest } from '@atlassian/jira-issue-view-store/src/actions/web-links-actions.tsx';
import type { OwnProps } from './types.tsx';
import WebLinkLineCardGroup from './view.tsx';

export const ReduxConnectedView = flow(
	ComponentWithAnalytics('webLinkCard', {
		onClick: 'clicked',
		onDelete: 'deleted',
	}),
	connect(null, {
		onDelete: deleteWebLinkRequest,
	}),
)(WebLinkLineCardGroup);

export const ConnectedView = (props: OwnProps) => (
	<ErrorBoundary id="^web-links-card-group">
		<ReduxConnectedView {...props} />
	</ErrorBoundary>
);

export default ConnectedView;
