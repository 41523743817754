import { getATLContextDomain } from '@atlassian/atl-context';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';

export const getIdentityLoginURL = (
	environment: Environment | null,
	currentLocationHref: string,
) => {
	const identityBaseUrl =
		environment === 'prod' || environment === null
			? getATLContextDomain('id')
			: getATLContextDomain('id', ['stg', 'commercial']);

	return `https://${identityBaseUrl}/login?application=jira&continue=${encodeURI(currentLocationHref)}`;
};

/**
 * This is used specifically for JPD and must redirect to the identity login screen, not to the jira login screen
 */
export const redirectToIdentity = (
	environment: Environment | null,
	{ location }: Window = window,
) => {
	location.replace(getIdentityLoginURL(environment, location.href));
};
