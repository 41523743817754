/**
 * @generated SignedSource<<3223fa01282a39e27d8a2959f533cc58>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type designForm_issueAddDesignForm_AddDesignForm_providers$data = {
  readonly designProviders: ReadonlyArray<{
    readonly handledDomainName: string | null | undefined;
    readonly id: string;
    readonly name: string;
    readonly supportedActions: {
      readonly checkAuth: boolean | null | undefined;
      readonly getEntityByUrl: boolean | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"inputField_issueAddDesignForm">;
  readonly " $fragmentType": "designForm_issueAddDesignForm_AddDesignForm_providers";
};
export type designForm_issueAddDesignForm_AddDesignForm_providers$key = {
  readonly " $data"?: designForm_issueAddDesignForm_AddDesignForm_providers$data;
  readonly " $fragmentSpreads": FragmentRefs<"designForm_issueAddDesignForm_AddDesignForm_providers">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "designForm_issueAddDesignForm_AddDesignForm_providers",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "inputField_issueAddDesignForm"
    },
    {
      "concreteType": "DevOpsDesignProvider",
      "kind": "LinkedField",
      "name": "designProviders",
      "plural": true,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "kind": "ScalarField",
            "name": "id"
          },
          "action": "THROW",
          "path": "designProviders.id"
        },
        {
          "kind": "RequiredField",
          "field": {
            "kind": "ScalarField",
            "name": "name"
          },
          "action": "THROW",
          "path": "designProviders.name"
        },
        {
          "kind": "ScalarField",
          "name": "handledDomainName"
        },
        {
          "concreteType": "DevOpsSupportedActions",
          "kind": "LinkedField",
          "name": "supportedActions",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "getEntityByUrl"
            },
            {
              "kind": "ScalarField",
              "name": "checkAuth"
            }
          ]
        }
      ]
    }
  ],
  "type": "DevOpsProviders"
};

(node as any).hash = "f5fdecb69f8a74258b5d8b3870a13c61";

export default node;
