import React, { useCallback, useEffect } from 'react';

import { useIntl } from 'react-intl-next';

import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { ExtractInjectionAPI } from '@atlaskit/editor-common/types';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { type PreviewButton, PreviewScreen } from '@atlassian/generative-ai-modal/screens/Preview';

import { createUnifiedAnalyticPayload, useFireAIAnalyticsEvent } from '../../analytics/utils';
import type { AIPlugin } from '../../editor-plugin-ai';
import {
	clearSelectRecommendation,
	insertRecommendation,
	removeRecommendation,
} from '../../pm-plugins/ai-proactive/commands';
import type { AIProactivePluginState } from '../../pm-plugins/ai-proactive/states';
import { getBlockFromRecommendationId } from '../../pm-plugins/ai-proactive/utils';
import type {
	EditorPluginAIProvider,
	FeedbackMetadata,
	ProactiveFeedbackMetadata,
} from '../../types';
import { convertPMFragmentToPreview } from '../../ui/convert-markdown-to-preview';
import { getTransformActionDescriptors } from '../../utils/proactive/get-transform-action-descriptors';
import { messages } from '../messages';

import { copyResponseToClipboard } from './utils/copy-response';

type ProactivePreviewScreenWithLogicProps = {
	aiProactivePluginState: AIProactivePluginState;
	editorView: EditorView;
	providerFactory: ProviderFactory;
	api: ExtractInjectionAPI<AIPlugin> | undefined;
	editorPluginAIProvider: EditorPluginAIProvider;
};

export function ProactivePreviewScreenWithLogic({
	aiProactivePluginState,
	editorView,
	providerFactory,
	api,
	editorPluginAIProvider,
}: ProactivePreviewScreenWithLogicProps) {
	const fireAIAnalyticsEvent = useFireAIAnalyticsEvent();
	const { formatMessage } = useIntl();
	const { selectedRecommendationId, analyticsAIInteractionId } = aiProactivePluginState;
	const { handleFeedbackSubmission, product } = editorPluginAIProvider;

	const { block, recommendation } = selectedRecommendationId
		? getBlockFromRecommendationId(aiProactivePluginState, selectedRecommendationId)
		: { block: undefined, recommendation: undefined };

	useEffect(() => {
		if (recommendation?.transformAction) {
			fireAIAnalyticsEvent({
				payload: createUnifiedAnalyticPayload(
					'viewed',
					analyticsAIInteractionId,
					recommendation.transformAction,
					true,
				),
			});
		}
	}, [fireAIAnalyticsEvent, analyticsAIInteractionId, recommendation?.transformAction]);

	const onClose = useCallback(() => {
		clearSelectRecommendation()(editorView.state, editorView.dispatch);
	}, [editorView.dispatch, editorView.state]);

	if (!selectedRecommendationId || !block || !recommendation) {
		return null;
	}

	const pmFragment = recommendation.transformContent.content;
	const { title } = getTransformActionDescriptors(recommendation.transformAction);

	const preview = convertPMFragmentToPreview({
		schema: editorView.state.schema,
		pmFragment: pmFragment,
		showTelepointer: false,
		providerFactory: providerFactory,
	});

	const proactiveRecommendationButtons: PreviewButton[] = [
		{
			label: formatMessage(messages.dismissProactiveRecommentation),
			appearance: 'cancel',
			onClick: () => {
				if (!recommendation) {
					return null;
				}
				removeRecommendation(recommendation.id, 'preview')(editorView.state, editorView.dispatch);
			},
		},
		{
			label: formatMessage(messages.insertProactiveRecommentation),
			appearance: 'primary',
			onClick: () => {
				if (!recommendation) {
					return null;
				}
				insertRecommendation(recommendation.id, 'preview')(editorView.state, editorView.dispatch);
			},
		},
	];

	const fireResponseFeedbackEvent = (sentiment: FeedbackMetadata['sentiment']) => {
		fireAIAnalyticsEvent({
			payload: {
				action: 'click',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'responseFeedbackButton',
				attributes: {
					sentiment,
					product,
					configItemTitle: 'proactive-recommendation',
				},
				eventType: EVENT_TYPE.UI,
			},
		});
	};

	const handleFeedbackButtonClick = async (sentiment: ProactiveFeedbackMetadata['sentiment']) => {
		fireResponseFeedbackEvent(sentiment);
		if (!handleFeedbackSubmission) {
			return false;
		}
		await handleFeedbackSubmission({
			sentiment,
			getAIExperience: (hasUserConsent) => ({
				...(hasUserConsent
					? {
							originalParagraph: block.text,
							suggestedParagraph: pmFragment,
							recommendationId: recommendation.id,
						}
					: {}),
			}),
			editorAttributes: { product },
		});
	};

	return (
		<PreviewScreen
			staticPromptTitle={formatMessage(title)}
			hideHorizontalRule={true}
			showBetaLozenge={true}
			preview={preview}
			disableActions={false}
			disableInterrogation={true}
			schema={editorView.state.schema}
			providerFactory={providerFactory}
			onCopy={() => {
				fireAIAnalyticsEvent({
					payload: {
						action: 'click',
						actionSubject: 'editorPluginAI',
						actionSubjectId: 'copyButton',
						attributes: {
							experienceType: 'proactive-recommendation',
						},
						eventType: EVENT_TYPE.UI,
					},
				});
				copyResponseToClipboard({ editorView, pmFragment });
			}}
			showBack={false}
			onGoodResponseButtonClick={() => handleFeedbackButtonClick('good-recommendation')}
			onBadResponseButtonClick={() => handleFeedbackButtonClick('bad-recommendation')}
			onClose={onClose}
			applications={proactiveRecommendationButtons}
			isInputActive={false}
			actionButtonsLayoutMode={'compact'}
		/>
	);
}
