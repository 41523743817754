import { ff } from '@atlassian/jira-feature-flagging';

export const isModernGlobalIssueCreateTriggerEnabled = () =>
	ff('project.config.pce.modern.global-issue-create.trigger', false);

export const isIssueViewEndUserRequestTrialEnabled = () =>
	ff('issue-view-placeholder-end-user-request-modal_myc7n', false);

export const isModernGICEnabledForSubtask = () => isModernGlobalIssueCreateTriggerEnabled();

export const isRespondersFieldAvailableInAllProjectsEnabled = () =>
	ff('responders-field-in-all-projects-issues-ui', false);

export const isItsmAgniAddedAttributeToLinkedIssueAddedEventEnabled = () =>
	ff('itsm-agni-added-attribute-to-linkedissue-added-event', false);
