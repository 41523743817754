import { useCallback, useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import {
	useAnalyticsEvents,
	fireTrackAnalytics,
	type ActionSubjectAndAction,
} from '@atlassian/jira-product-analytics-bridge';
import type { AnalyticsAttributesUntyped } from '@atlassian/jira-product-analytics-bridge/src/types';
import {
	useApplication,
	useEdition,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { SubscriptionError } from '../../services/issue-breakdown/types.tsx';

export const aiwbAnalyticsAttrs = {
	aiFeatureName: 'intelligentWorkBreakdown',
	isAIFeature: 1,
	proactiveAIGenerated: 0,
	userGeneratedAI: 1,
} as const;

type Params = {
	channelId?: string | null;
};

export const IMPROVE_ISSUE_DROPDOWN_ID = 'improveIssueDropdown';
export const ISSUE_BREAKDOWN_BUTTON_ID = 'issueBreakdownButton';

export const useAiWorkBreakDownAnalytics = (params: Params = {}) => {
	const { channelId } = params;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const actions = useMemo(() => {
		const analyticsEvent = createAnalyticsEvent({});
		const defaultAttrs = { ...aiwbAnalyticsAttrs, singleInstrumentationID: channelId };
		return {
			analyticsEvent,
			fireTrack: (name: ActionSubjectAndAction, id: string, attrs?: AnalyticsAttributesUntyped) =>
				fireTrackAnalytics(analyticsEvent, name, id, { ...defaultAttrs, ...attrs }),
		};
	}, [channelId, createAnalyticsEvent]);
	return actions;
};

type Fns = { sendSuccessEvent: Function; sendFailedEvent: Function };
export const useAiWorkBreakDownExperienceAnalytics = (): Fns => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	const analyticsSource = 'jiraSuggestedChildIssue';
	const experience = 'aiIssueBreakdown';

	// Currently we are sending notEnoughInformation, noFurtherSuggestions and unethicalContent as success event.
	// We want to track these valid errors.
	const sendSuccessEvent = useCallback(
		(validError: SubscriptionError) => {
			sendExperienceAnalytics({
				experience,
				wasExperienceSuccesful: true,
				analyticsSource,
				application: application ?? null,
				edition: edition ?? null,
				additionalAttributes: { validError },
			});
		},
		[application, edition],
	);

	const sendFailedEvent = useCallback(
		(error: SubscriptionError, errorMessage?: string | null, statusCode?: number | null) => {
			sendExperienceAnalytics({
				experience,
				wasExperienceSuccesful: false,
				analyticsSource,
				application: application ?? null,
				edition: edition ?? null,
				additionalAttributes: {
					error,
					...(fg('ai_work_breakdown_gql_error_fields_query')
						? {
								statusCode: statusCode ?? 'No status code',
								jiraErrorMessage: errorMessage ?? 'No jira error message',
							}
						: {}),
				},
			});
		},
		[application, edition],
	);

	return { sendSuccessEvent, sendFailedEvent };
};
