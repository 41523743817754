/**
 * ISSUE RESTRICTION IN ISSUE VIEW Screen
 * Details can be found here -
 * https://hello.atlassian.net/wiki/spaces/JPCE/pages/613261740/LDR+View+Issue+API+changes+for+supporting+Issue+Security+in+Next-Gen
 * https://hello.atlassian.net/wiki/spaces/JPCE/pages/574029166/Issue+restrictions+E2E+journey
 */

import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import AsyncIssueRestrictions from '@atlassian/jira-issue-restrictions/src/async.tsx';
import { ISSUE } from '@atlassian/jira-issue-restrictions/src/common/types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { applicationEditionSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/application-selector.tsx';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	errorSelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	type Value as IssueRestrictionValue,
	currentIssueRestrictionSelector,
	isIssueRestrictionDisplaySelector,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/issue-restriction-selector.tsx';
import { isSubtaskSelector } from '@atlassian/jira-issue-view-store/src/selectors/subtasks-selector.tsx';
import type { issueRestriction_issueViewFoundation_IssueRestrictionWrapper$key } from '@atlassian/jira-relay/src/__generated__/issueRestriction_issueViewFoundation_IssueRestrictionWrapper.graphql';
import { toProjectKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Props, RestrictionType } from './types.tsx';

const restrictionType: RestrictionType = ISSUE;

const IssueRestrictionWrapperNew = ({ hasError, edition, fragmentKey }: Props) => {
	// This ESLint rule seems to think that this is a shared file. Remove after FF cleanup.
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<issueRestriction_issueViewFoundation_IssueRestrictionWrapper$key>(
		graphql`
			fragment issueRestriction_issueViewFoundation_IssueRestrictionWrapper on JiraIssue {
				key

				issueTypeField {
					issueType {
						hierarchy {
							level
						}
					}
				}
				projectField @required(action: LOG) {
					project @required(action: LOG) {
						key @required(action: LOG)
						canSetIssueRestriction
					}
				}

				issueRestriction: fieldByIdOrAlias(idOrAlias: "issuerestriction")
					@optIn(to: "JiraIssueFieldByIdOrAlias") {
					... on JiraIssueRestrictionField {
						selectedRolesConnection {
							edges {
								node {
									roleId
								}
							}
						}
					}
				}
			}
		`,
		fragmentKey ?? null,
	);

	const currentIssueRestriction = useMemo(
		() =>
			(data?.issueRestriction?.selectedRolesConnection?.edges ?? []).reduce<
				IssueRestrictionValue[]
			>((acc, edge) => {
				if (edge?.node?.roleId) {
					acc.push({ restrictionValue: edge.node.roleId });
				}

				return acc;
			}, []) ?? [],
		[data?.issueRestriction?.selectedRolesConnection?.edges],
	);

	if (!data) {
		return null;
	}

	const issueKey = data.key;
	const projectKey = data.projectField.project.key;
	const isSubtask = data.issueTypeField?.issueType?.hierarchy?.level === -1;

	// Has a field, and can set issue restriction
	const isIssueRestrictionDisplayForIssue =
		data.issueRestriction && data.projectField?.project?.canSetIssueRestriction;

	if (!hasError && isIssueRestrictionDisplayForIssue) {
		if (!isSubtask || currentIssueRestriction.length > 0) {
			return (
				<AsyncIssueRestrictions
					entityKey={toIssueKey(issueKey)}
					currentRestrictions={currentIssueRestriction}
					restrictionType={restrictionType}
					projectKeyOrId={toProjectKey(projectKey)}
					edition={edition}
					isSubtask={isSubtask}
				/>
			);
		}
	}
	return null;
};

const IssueRestrictionWrapperOld = ({
	currentIssueRestriction,
	isIssueRestrictionDisplayForIssue,
	hasError,
	issueKey,
	projectKey,
	edition,
	isSubtask,
}: Props) => {
	if (!hasError && isIssueRestrictionDisplayForIssue) {
		if (!isSubtask || currentIssueRestriction.length > 0) {
			return (
				<AsyncIssueRestrictions
					entityKey={toIssueKey(issueKey)}
					currentRestrictions={currentIssueRestriction}
					restrictionType={restrictionType}
					projectKeyOrId={toProjectKey(projectKey)}
					edition={edition}
					isSubtask={isSubtask}
				/>
			);
		}
	}
	return null;
};

const IssueRestrictionWrapper = componentWithCondition(
	() => ff('relay-migration-issue-fields-issue-restrictions_yudid'),
	IssueRestrictionWrapperNew,
	IssueRestrictionWrapperOld,
);

export const IssueRestriction = flowWithSafeComponent(
	connect(
		(state: State) => ({
			currentIssueRestriction: currentIssueRestrictionSelector(state),
			isIssueRestrictionDisplayForIssue: isIssueRestrictionDisplaySelector(state),
			hasError: !!errorSelector(state),
			// FIXME: JIV-17455. This field can be null
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			projectKey: projectKeySelector(state)!,
			issueKey: issueKeySelector(state),
			// FIXME: JIV-17455. This field can be null
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			edition: applicationEditionSelector(state)!,
			isSubtask: isSubtaskSelector(state),
		}),
		{},
	),
)(IssueRestrictionWrapper);
