import type { PayloadError } from 'relay-runtime';
import type { MessageDescriptor } from '@atlassian/jira-intl';
import type { ToolchainAssociateEntitiesErrorCode } from '@atlassian/jira-relay/src/__generated__/designForm_associateDesignEntityMutation.graphql';
import messages from './messages.tsx';

export const getErrorFromPayload = (errors: PayloadError[]): Error => {
	const errorMessages = errors.map((error) => error.message).join(', ');
	return new Error(errorMessages);
};

export const getLinkDesignExpectedErrorFlagMessages = (
	errorCode?: ToolchainAssociateEntitiesErrorCode | null,
): { flagTitle?: MessageDescriptor; flagDescription?: MessageDescriptor } => {
	switch (errorCode) {
		case 'ENTITY_REJECTED':
			return {
				flagTitle: messages.designRejectedFlagTitle,
				flagDescription: messages.designRejectedFlagDescription,
			};
		case 'ENTITY_URL_INVALID':
			return {
				flagTitle: messages.designUrlInvalidFlagTitle,
				flagDescription: messages.designUrlInvalidFlagDescription,
			};
		case 'PROVIDER_INPUT_INVALID':
			return {
				flagTitle: messages.designUrlInvalidFlagTitle,
				flagDescription: messages.designUrlInvalidFlagDescription,
			};
		case 'PROVIDER_ENTITY_FETCH_FORBIDDEN':
			return {
				flagTitle: messages.linkDesignForbiddenFlagTitle,
				flagDescription: messages.linkDesignForbiddenFlagDescription,
			};
		case 'PROVIDER_ENTITY_NOT_FOUND':
			return {
				flagTitle: messages.designUrlNotFoundFlagTitle,
				flagDescription: messages.designUrlNotFoundFlagDescription,
			};
		case 'PROVIDER_ERROR':
			return {
				flagTitle: messages.designProviderErrorFlagTitle,
				flagDescription: messages.designProviderErrorFlagDescription,
			};
		default:
			return { flagTitle: undefined, flagDescription: undefined };
	}
};
