import React from 'react';
import Button, { IconButton } from '@atlaskit/button/new';
import LikeIcon from '@atlaskit/icon/glyph/like';
import { Text } from '@atlaskit/primitives';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import TransitiveNumber from '@atlassian/react-transitive-number';
import { useVoteField } from '../../services/vote-field-service/index.tsx';
import { FIELD_KEY, type UseVoteField } from '../types.tsx';

type VoteButtonProps = {
	testId: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	isSelected: boolean;
	label: string;
	ariaExpanded?: boolean;
	isDisabled?: boolean;
	useVoteField?: UseVoteField;
};

const VoteButtonRefresh = ({
	testId,
	isSelected,
	isDisabled,
	label,
	ariaExpanded,
	onClick,
	useVoteField: useVoteFieldFromProps,
}: VoteButtonProps) => {
	const issueKey = useIssueKey();

	const [fieldValue] = ff('relay-migration-issue-fields-votes_nsbqd')
		? [{}]
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useFieldValue({
				issueKey,
				fieldKey: FIELD_KEY,
			});
	const [{ value: voteValue }] = ff('relay-migration-issue-fields-votes_nsbqd')
		? (useVoteFieldFromProps || useVoteField)({})
		: [{ value: { votes: 0, hasVoted: false } }];

	const hasVoted = ff('relay-migration-issue-fields-votes_nsbqd')
		? voteValue.hasVoted
		: fieldValue.hasVoted;
	const votes = ff('relay-migration-issue-fields-votes_nsbqd') ? voteValue.votes : fieldValue.votes;

	return votes !== 0 ? (
		<Button
			testId={testId}
			isSelected={hasVoted}
			appearance="default"
			spacing="default"
			iconBefore={() => (
				<LikeIcon
					testId="issue-field-voters.common.vote-button-refresh.like-icon"
					label=""
					size="medium"
					{...(hasVoted ? { primaryColor: token('color.icon.selected', B400) } : {})}
				/>
			)}
			aria-label={label}
			aria-expanded={ariaExpanded}
			isDisabled={isDisabled}
			onClick={onClick}
		>
			<Text
				testId="issue-field-voters.common.vote-button-refresh.counter"
				{...(hasVoted ? { color: 'color.text.selected' } : {})}
			>
				<TransitiveNumber>{votes !== 0 ? votes : ''}</TransitiveNumber>
			</Text>
		</Button>
	) : (
		<IconButton
			testId={testId}
			icon={() => (
				<LikeIcon
					testId="issue-field-voters.common.vote-button-refresh.like-icon"
					label={label}
					size="medium"
				/>
			)}
			isSelected={isSelected}
			label={label}
			aria-expanded={ariaExpanded}
			isDisabled={isDisabled}
			onClick={onClick}
		/>
	);
};

export default VoteButtonRefresh;
