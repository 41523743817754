import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	uimAppsConflictFlagTitle: {
		id: 'ui-modifications-view-issue-view.entry-point.notification-flags.hooks.use-notification-flag-config.uim-apps-conflict-flag-title',
		defaultMessage: 'UI modifications conflict',
		description: 'Title displayed on the flag when UI modifications misconfiguration was detected',
	},
	uimAppsConflictFlagDescription: {
		id: 'ui-modifications-view-issue-view.entry-point.notification-flags.hooks.use-notification-flag-config.uim-apps-conflict-flag-description',
		defaultMessage:
			'Multiple UI modification apps are trying to change the same field property. If you see unexpected changes to a field, report them to your Jira admin.',
		description:
			'Description displayed on the flag when UI modifications misconfiguration was detected',
	},
	uimMultipleAppsFlagTitle: {
		id: 'ui-modifications-view-issue-view.entry-point.notification-flags.hooks.use-notification-flag-config.uim-multiple-apps-flag-title',
		defaultMessage: 'Multiple UI modifications',
		description:
			'Title displayed on the flag when UI modifications multiple apps in the context were detected',
	},
	uimMultipleAppsFlagDescription: {
		id: 'ui-modifications-view-issue-view.entry-point.notification-flags.hooks.use-notification-flag-config.uim-multiple-apps-flag-description',
		defaultMessage:
			'We have added support for multiple UI modification apps. Your admin has installed more than one app that can make changes to this screen.',
		description:
			'Description displayed on the flag when UI modifications multiple apps in the context were detected',
	},
});
