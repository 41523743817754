import React from 'react';
import {
	type JSErrorBoundaryProps,
	JSErrorBoundary,
} from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';

export const HighlightActionsErrorBoundary = ({
	id,
	children,
	fallback,
	onError,
}: Partial<JSErrorBoundaryProps> & { id: string }) => (
	<JSErrorBoundary
		id={id}
		packageName="jiraHighlightActions"
		fallback={fallback || 'unmount'}
		teamName="crocs"
		onError={onError}
	>
		{children}
	</JSErrorBoundary>
);
