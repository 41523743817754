import React, { useCallback, useEffect } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { getStatusTransitions } from '@atlassian/jira-issue-field-status/src/services/status-transitions-service/index.tsx';
import { useStatusField } from '@atlassian/jira-issue-field-status/src/services/use-status-service/index.tsx';
import IssueHeader from '@atlassian/jira-issue-view-common/src/component/issue-header/index.tsx';
import { useAddToIssueActions } from '@atlassian/jira-issue-view-foundation/src/issue-actions/add-to-issue-dropdown-group/index.tsx';
import { useIssueManipulationActions } from '@atlassian/jira-issue-view-foundation/src/issue-actions/issue-manipulation-dropdown-group/index.tsx';
import getItemList from '@atlassian/jira-issue-view-foundation/src/quick-add/quick-add-items/item-list/index.tsx';
import { useLinkAlertsModalActions } from '@atlassian/jira-linked-alerts/src/services/main.tsx';
import { SERVICEDESK_INCIDENT_MANAGEMENT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useForms } from '@atlassian/jira-proforma-panel-in-issue-view-services/src/services/forms-status-service/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useShortcuts } from '@atlassian/jira-shortcuts-dialog/src/controllers/shortcuts-service/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import {
	quickAddItemsToShortcutOptions,
	transitionItemsToShortcutOptions,
	useClickActions,
	getExtraActions,
} from './utils.tsx';

const FIELDS_SHORTCUTS_KEY = 'fields';
const ISSUE_ACTIONS_SHORTCUTS_KEY = 'issueActions';
const ISSUE_WORKFLOW_SHORTCUTS_KEY = 'workflow';

const RegisterShortcutsDialogHelper = ({
	canCreateAttachment,
	shouldShowCreateSubtaskButton,
	shouldShowIssueInEpicButton,
	canCreateIssueLink,
	canEditIssues,
	onAddAttachmentClick,
	onCreateSubtaskClick,
	onCreateIssueInEpicClick,
	onChildCreateClick,
	supportsChildCreation,
	isServiceDesk,
	isAnonymousUser,
	canLinkConfluencePage,
	onLinkConfluencePageClick,
	onInvestigateIncidentClick,
	canAddWebLinks,
	onAddWebLinkClick,
	onAddIssueLinkClick,
	onChangeWorkflow,
	onAddComment,
	ecosystemActions,
	forgeIssueActions,
	hasParent,
	practices,
	onClose,
	onIssueDeleteSuccess,
	onLinkIdeaClick,
}: Props) => {
	const { formatMessage } = useIntl();
	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [permissions] = useProjectPermissions(projectKey);
	const [incidentManagement] = useFieldValue({
		fieldKey: SERVICEDESK_INCIDENT_MANAGEMENT_TYPE,
		issueKey,
	});
	const hasIssue = !!issueKey && !!issueId;

	const [
		{ isShortcutsDialogVisible },
		{ registerShortcuts, setShortcutsLoading, setHasError, setHeader, dropShortcutsByKeys },
	] = useShortcuts();

	const { onAssignToMeLinkClick, onAddCommentLinkClick, onWorkflowSelected } = useClickActions({
		onChangeWorkflow,
		onAddComment,
	});

	const [
		{
			value: { hasProjectForms },
		},
		{ onClickAddFormButton },
	] = useForms({
		issueKey: toIssueKey(issueKey),
	});
	const shouldShowProformaForms = hasProjectForms === true;

	const [, { openLinkAlertsModal: onLinkAlertsClick }] = useLinkAlertsModalActions();
	const addToIssueActions = useAddToIssueActions(
		ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w')
			? forgeIssueActions ?? []
			: ecosystemActions ?? [],
	);
	const issueManipulationActions = useIssueManipulationActions(
		hasParent,
		onIssueDeleteSuccess,
		onClose,
	);

	const [{ value: currentStatus }] = useStatusField({
		issueKey,
	});

	const registerIssueViewShortcuts = useCallback(async () => {
		const quickAddItemList = getItemList({
			formatMessage,
			canCreateAttachment,
			canEditIssues,
			shouldShowCreateSubtaskButton,
			shouldShowIssueInEpicButton,
			canCreateIssueLink,
			onAddAttachmentClick,
			onCreateSubtaskClick,
			onCreateIssueInEpicClick,
			onChildCreateClick,
			supportsChildCreation,
			isServiceDesk,
			isAnonymousUser,
			canLinkConfluencePage,
			onLinkConfluencePageClick,
			onInvestigateIncidentClick,
			incidentManagement,
			onLinkAlertsClick,
			canAddWebLinks,
			onAddWebLinkClick,
			onAddIssueLinkClick,
			onLinkIdeaClick,
			practices,
		});
		const quickAddActions = quickAddItemsToShortcutOptions(quickAddItemList);

		const canEdit = permissions.canEditIssues;
		const extraActions = getExtraActions({
			onAddCommentLinkClick,
			onAssignToMeLinkClick,
			onClickAddFormButton,
			shouldShowProformaForms,
			formatMessage,
			canEdit,
		});

		const issueActions = {
			key: ISSUE_ACTIONS_SHORTCUTS_KEY,
			label: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.issueActionsSectionLabelIssueTermRefresh
					: messages.issueActionsSectionLabel,
			),
			isLoaded: true,
			weight: 2,
			options: [
				...quickAddActions,
				...extraActions,
				...addToIssueActions,
				...issueManipulationActions,
			],
		};
		const result = await getStatusTransitions(issueKey);

		const issueWorkflow = {
			key: ISSUE_WORKFLOW_SHORTCUTS_KEY,
			label: formatMessage(messages.issueWorkflowsSectionLabel),
			isLoaded: true,
			weight: 3,
			options: result
				? transitionItemsToShortcutOptions(result.transitions, currentStatus, onWorkflowSelected)
				: [],
		};

		if (!result) {
			setHasError(true);
		}

		// @ts-expect-error - TS2322 - Type '{ key: string; label: string; isLoaded: boolean; weight: number; options: (ShortcutOption | { key: string; label: string; onClick: (() => void) | undefined; })[]; }' is not assignable to type 'OptionGroupType'.
		registerShortcuts([issueActions, issueWorkflow]);
	}, [
		formatMessage,
		canCreateAttachment,
		canEditIssues,
		shouldShowCreateSubtaskButton,
		shouldShowIssueInEpicButton,
		canCreateIssueLink,
		onAddAttachmentClick,
		onCreateSubtaskClick,
		onCreateIssueInEpicClick,
		onChildCreateClick,
		supportsChildCreation,
		isServiceDesk,
		isAnonymousUser,
		canLinkConfluencePage,
		onLinkConfluencePageClick,
		onInvestigateIncidentClick,
		incidentManagement,
		onLinkAlertsClick,
		canAddWebLinks,
		onAddWebLinkClick,
		onAddIssueLinkClick,
		practices,
		permissions.canEditIssues,
		onAddCommentLinkClick,
		onAssignToMeLinkClick,
		onClickAddFormButton,
		shouldShowProformaForms,
		addToIssueActions,
		issueManipulationActions,
		issueKey,
		currentStatus,
		onWorkflowSelected,
		registerShortcuts,
		onLinkIdeaClick,
		setHasError,
	]);

	useEffect(
		() => () =>
			dropShortcutsByKeys([
				FIELDS_SHORTCUTS_KEY,
				ISSUE_ACTIONS_SHORTCUTS_KEY,
				ISSUE_WORKFLOW_SHORTCUTS_KEY,
			]),
		[dropShortcutsByKeys],
	);

	useEffect(() => {
		if (hasIssue && isShortcutsDialogVisible) {
			setHeader(<IssueHeader />, issueKey);
			setShortcutsLoading(true);
			registerIssueViewShortcuts();
		}
		return () => {
			setHeader(null);
		};
	}, [
		hasIssue,
		isShortcutsDialogVisible,
		registerIssueViewShortcuts,
		setShortcutsLoading,
		setHeader,
		issueKey,
	]);

	return null;
};

export default RegisterShortcutsDialogHelper;
