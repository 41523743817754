import React, { useCallback, useMemo } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import LikeIcon from '@atlaskit/icon/glyph/like';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import {
	getLabelForToggleWithoutSelfVote,
	getLabelForToggleWithSelfVote,
} from '../../common/utils.tsx';
import messages from '../../messages.tsx';
import { useVoteField } from '../../services/vote-field-service/index.tsx';
import VoteButton from '../button/index.tsx';
import type { Props } from './types.tsx';

export const VoteToggle = ({
	onClick,
	isDropdownItem = false,
	disableVotingProps = { isDisabled: false },
	onFieldUpdated,
	useVoteField: useVoteFieldFromProps,
	...rest
}: Props) => {
	const { isDisabled, tooltips } = disableVotingProps;
	const { formatMessage } = useIntl();
	const [{ value }, { toggleValue }] = (
		ff('relay-migration-issue-fields-votes_nsbqd')
			? useVoteFieldFromProps || useVoteField
			: useVoteField
	)({ onFieldUpdated });

	const { buttonLabel, disabledTooltip, ariaLabel } = useMemo(
		() =>
			value.hasVoted
				? {
						buttonLabel: formatMessage(messages.removeVoteLabel),
						disabledTooltip: tooltips?.removeVote,
						ariaLabel: formatMessage(getLabelForToggleWithSelfVote(), {
							votes: value.votes,
						}),
					}
				: {
						buttonLabel: formatMessage(messages.addVoteLabel),
						disabledTooltip: tooltips?.addVote,
						ariaLabel: formatMessage(getLabelForToggleWithoutSelfVote(value.votes), {
							votes: value.votes,
						}),
					},
		[value.hasVoted, value.votes, formatMessage, tooltips?.removeVote, tooltips?.addVote],
	);

	const toggleVote = useCallback(() => {
		onClick?.();
		toggleValue(isDropdownItem);
	}, [isDropdownItem, onClick, toggleValue]);

	if (isDropdownItem) {
		const itemAriaLabel = {
			'aria-label': !isDisabled || disabledTooltip === undefined ? undefined : disabledTooltip,
		};

		return (
			<div role="menu">
				<Tooltip
					testId="issue-field-voters.ui.vote-toggle.tooltip"
					content={!isDisabled || disabledTooltip === undefined ? null : disabledTooltip}
				>
					<StyledDropdownItem
						elemBefore={
							value.hasVoted ? (
								<LikeIcon label="" size="medium" />
							) : (
								<LikeIcon
									label=""
									size="medium"
									primaryColor={token('color.icon.selected', B400)}
								/>
							)
						}
						onClick={toggleVote}
						isDisabled={isDisabled}
						{...itemAriaLabel}
					>
						{buttonLabel}
					</StyledDropdownItem>
				</Tooltip>
			</div>
		);
	}

	return (
		<VoteButton
			tooltip={isDisabled ? disabledTooltip : buttonLabel}
			onClick={toggleVote}
			ariaLabel={isDisabled ? disabledTooltip : ariaLabel}
			isDisabled={isDisabled}
			{...(ff('relay-migration-issue-fields-votes_nsbqd')
				? { useVoteField: useVoteFieldFromProps, ...rest }
				: {})}
		/>
	);
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Props } from './types';
export default VoteToggle;
// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledDropdownItemControl = styled(DropdownItem)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&': {
		padding: `${token('space.100', '8px')} ${token('space.150', '12px')} 7px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-unsafe-style-overrides
const StyledDropdownItemExperiment = styled2(DropdownItem)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&': {
		padding: `${token('space.100', '8px')} ${token('space.150', '12px')} 7px`,
	},
});

const StyledDropdownItem = styledComponentWithCondition(
	() => ff('compiled-react-migration-issue-view_u9myz'),
	StyledDropdownItemExperiment,
	StyledDropdownItemControl,
);
