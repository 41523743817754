import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unexpectedErrorTitle: {
		id: 'issue-design-common.error-flags.unexpected-error-title',
		defaultMessage: 'Something went wrong',
		description:
			'Title of a flag shown to the user indicating that an unexpected issue occurred internally when a user tries to link a design',
	},
	unexpectedErrorDescription: {
		id: 'issue-design-common.error-flags.unexpected-error-description',
		defaultMessage: 'Something went wrong and we couldn’t connect to {providerName}',
		description:
			'Contents of a flag shown to the user indicating that an unexpected issue occurred internally when a user tries to link a design',
	},
	linkFailedGenericDescription: {
		id: 'issue-design-common.error-flags.link-failed-generic-description',
		defaultMessage: 'Check your URL and permissions and try again.',
		description: 'Contents of a flag shown to linking design to issue failed',
	},
	backfillSyncFailedErrorTitle: {
		id: 'issue-design-common.error-flags.backfill-sync-failed-error-title',
		defaultMessage:
			"Some designs couldn't be linked to the issue. Check you have access to {providerName} and try again.",
		description:
			'Description on a flag explaining that there were failures while syncing designs from Figma to Jira.',
	},
	designRejectedFlagTitle: {
		id: 'issue-design-common.error-flags.design-rejected-flag-title',
		defaultMessage: 'The design could not be linked',
		description:
			'Contents of a flag shown when a user tries to link a design but the request fails and is rejected',
	},
	designRejectedFlagDescription: {
		id: 'issue-design-common.error-flags.design-rejected-flag-description',
		defaultMessage: 'An error occurred when linking the design.',
		description:
			'Description of a flag shown when a user tries to link a design but the request fails and is rejected',
	},
	designProviderErrorFlagTitle: {
		id: 'issue-design-common.error-flags.design-provider-error-flag-title',
		defaultMessage: 'Unable to link design',
		description:
			'Title of an error flag shown when a user cannot link a design because something went wrong with the design provider',
	},
	designProviderErrorFlagDescription: {
		id: 'issue-design-common.error-flags.design-provider-error-flag-description',
		defaultMessage: 'Something went wrong with {providerName}. Please try again later.',
		description:
			'Description of an error flag shown when a user cannot link a design because something went wrong with the design provider',
	},
	linkDesignForbiddenFlagTitle: {
		id: 'issue-design-common.error-flags.link-design-forbidden-flag-title',
		defaultMessage: 'Insufficient permissions to link design',
		description:
			'Title of an error flag shown when a user cannot link a design due to insufficient permissions',
	},
	linkDesignForbiddenFlagDescription: {
		id: 'issue-design-common.error-flags.link-design-forbidden-flag-description',
		defaultMessage:
			'You do not have the required permissions in {providerName} to link this design.',
		description:
			'Description of an error flag shown when a user cannot link a design due to insufficient permissions',
	},
	designUrlInvalidFlagTitle: {
		id: 'issue-design-common.error-flags.design-url-invalid-flag-title',
		defaultMessage: 'Invalid URL',
		description:
			'Title of an error flag shown when a user provides a design URL to link which is invalid',
	},
	designUrlInvalidFlagDescription: {
		id: 'issue-design-common.error-flags.design-url-invalid-flag-description',
		defaultMessage: 'The URL you provided is invalid. Please check the URL and try again.',
		description:
			'Description of an error flag shown when a user cannot link a design because the URL is invalid',
	},
	designUrlNotFoundFlagTitle: {
		id: 'issue-design-common.error-flags.design-url-not-found-flag-title',
		defaultMessage: 'Design not found',
		description:
			'Title of an error flag shown when a user cannot link a design because the entity URL is not found from the provider',
	},
	designUrlNotFoundFlagDescription: {
		id: 'issue-design-common.error-flags.design-url-not-found-flag-description',
		defaultMessage:
			'Design could not be linked because it was not found in {providerName}. Please check the URL and try again.',
		description:
			'Description of an error flag when a user cannot link a design because the entity URL is not found from the provider',
	},
});
