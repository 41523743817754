import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type DevPanel from './view/index.tsx';
import type { Props } from './view/types.tsx';

const LazyDevPanel = lazyForPaint<typeof DevPanel>(
	() => import(/* webpackChunkName: "async-dev-panel" */ './view/index'),
);

export const AsyncDevPanel = (props: Props) => (
	<Placeholder name="async-dev-panel" fallback={<></>}>
		<LazyDevPanel {...props} />
	</Placeholder>
);
