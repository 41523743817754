import React, { memo } from 'react';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { AsyncAutomationPanelView } from './async.tsx';

const AutomationDiscoveryContainerPanel = () => {
	return (
		<JSErrorBoundary
			id="AsyncAutomationDiscoveryContainerPanel"
			packageName="jiraIssueViewBase"
			fallback="unmount"
		>
			<Placeholder
				name="automation-discovery-container-panel"
				fallback={<SkeletonCompiled width="100%" height="68px" />}
			>
				<AsyncAutomationPanelView />
			</Placeholder>
		</JSErrorBoundary>
	);
};

export default memo(AutomationDiscoveryContainerPanel);
