import type { RulesLogic } from '@atlassian/jira-polaris-lib-json-logic/src/index.tsx';
import type { FieldKey } from '../field/types.tsx';
import type { ValueRuleOperator } from './constants.tsx';

export type LocalDecorationId = string;

type CreateDecorationReturn = {
	id: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	node: Record<any, any>;
};

export type BackendIdPromise = Promise<CreateDecorationReturn>;

export type ValueOperator =
	| typeof ValueRuleOperator.EQ
	| typeof ValueRuleOperator.GT
	| typeof ValueRuleOperator.LT
	| typeof ValueRuleOperator.GTE
	| typeof ValueRuleOperator.LTE;

export type ValueRule = {
	value: string;
	operator: ValueOperator;
};

export type ValueDecorationDetails = {
	/**
	 * As there is a backend-roundtrip necessary for creating a decoration, the backend-id might not be available yet,
	 * in that case, subsequent updates should wait for the backend-id to be returned for the create-request.
	 */
	backendId?: BackendIdPromise;
	/**
	 * Generated local decoration id for optimistic updates - uniquely identifies this ValueDecoration in the UI.
	 * Id is generated for every item returned by the backend or when a new decoration value is created.
	 */
	localDecorationId: LocalDecorationId;
	emoji: string | undefined;
	backgroundColor: string | undefined;
	highlightContainer: boolean | undefined;
};

export type ValueDecorationRules = ValueDecorationDetails & {
	rules: ValueRule[];
};

export type ValueDecorationLogic = ValueDecorationDetails & {
	logic: RulesLogic;
};

const isObject = (value: unknown): value is Record<string, unknown> =>
	typeof value === 'object' && value !== null;

export const isValueDecorationRules = (
	valueDecoration: unknown,
): valueDecoration is ValueDecorationRules =>
	isObject(valueDecoration) && Array.isArray(valueDecoration.rules);

export type ValueDecoration = ValueDecorationRules | ValueDecorationLogic;
export type FieldValueDecorations = Record<FieldKey, ValueDecoration[]>;
