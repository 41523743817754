import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	ConnectionFieldConfiguration,
	FieldKey,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetFieldByKey } from '../../selectors/field.tsx';
import type { Props, State } from '../../types.tsx';

type Filter = {
	fieldKey: FieldKey;
	values: string[];
};

export const updateConnectionFieldConfiguration =
	(fieldKey: FieldKey, filter: Filter) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, ideaTypes }: Props,
	): Promise<void> => {
		const state = getState();
		const currentField = createGetFieldByKey(fieldKey)(state);
		const issueTypeId = ideaTypes?.[0].jiraIssueTypeId;

		if (!issueTypeId || !currentField) {
			return;
		}

		try {
			const newConfiguration: ConnectionFieldConfiguration = {
				issueFilters: filter.values.length
					? [
							{
								fieldKey: filter.fieldKey,
								values: filter.values,
								operator: 'IN',
							},
						]
					: [],
			};

			setState({
				fields: {
					...state.fields,
					[fieldKey]: {
						...currentField,
						configuration: newConfiguration,
					},
				},
			});

			await fieldRemote.updateFieldConfiguration({
				fieldKey,
				issueTypeId,
				oldConfiguration: currentField.configuration || {},
				configuration: newConfiguration,
			});
		} catch (error) {
			setState({
				fields: {
					...state.fields,
					[fieldKey]: currentField,
				},
			});

			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics(
						'polaris.error.controllers.field.actions.update-field-configuration.connection',
						error,
					),
				);

				onFieldUpdateFailed(error);
			}
		}
	};
