import React from 'react';

import { Inline, Stack, xcss } from '@atlaskit/primitives';

import { type Recommendation } from '../pm-plugins/ai-proactive/api';

import { EmptyState } from './empty-state';
import { HeadingItem, RecommendationItem, SpellingGrammarItem } from './recommendation-item';

const panelContainerStyles = xcss({
	paddingInlineStart: 'space.0',
	height: '100%',
	width: '100%',
});

type RecommendationPanelProps = {
	recommendations: Recommendation[];
	hoveredRecommendationId: string | undefined;
	selectedRecommendationId: string | undefined;
	isSpellingGrammarEnabled: boolean;
	spellingGrammarErrorCount?: number;
	onRecommendationApply: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationDismiss: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationSelect: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationHover: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationLeave: ({ recommendation }: { recommendation: Recommendation }) => void;
	onPanelClose: () => void;
	onSpellingGrammar: () => void;
};
const emptyStateStyles = xcss({
	flex: 1,
});
export const RecommendationPanel = (props: RecommendationPanelProps) => {
	const {
		recommendations,
		hoveredRecommendationId,
		selectedRecommendationId,
		isSpellingGrammarEnabled,
		spellingGrammarErrorCount,
		onRecommendationApply,
		onPanelClose,
		onRecommendationDismiss,
		onRecommendationSelect,
		onSpellingGrammar,
		onRecommendationHover,
		onRecommendationLeave,
	} = props;
	return (
		<Stack xcss={panelContainerStyles}>
			<HeadingItem onClose={onPanelClose}></HeadingItem>
			{recommendations?.length > 0 || (spellingGrammarErrorCount ?? 0) > 0 ? (
				<>
					{isSpellingGrammarEnabled && (spellingGrammarErrorCount ?? 0) > 0 && (
						<SpellingGrammarItem
							errorCount={spellingGrammarErrorCount ?? 0}
							onClick={onSpellingGrammar}
						></SpellingGrammarItem>
					)}
					{recommendations?.map((r) => (
						<RecommendationItem
							key={r.id}
							id={r.id}
							action={r.transformAction}
							isViewed={r.isViewed}
							isHovered={r.id === hoveredRecommendationId}
							isSelected={r.id === selectedRecommendationId}
							onApply={() => onRecommendationApply({ recommendation: r })}
							onDismiss={() => onRecommendationDismiss({ recommendation: r })}
							onClick={() => onRecommendationSelect({ recommendation: r })}
							onMouseEnter={() => onRecommendationHover({ recommendation: r })}
							onMouseLeave={() => onRecommendationLeave({ recommendation: r })}
							onPreview={() => onRecommendationSelect({ recommendation: r })}
						></RecommendationItem>
					))}
				</>
			) : (
				<Inline xcss={emptyStateStyles} alignBlock="center">
					<EmptyState />
				</Inline>
			)}
		</Stack>
	);
};
