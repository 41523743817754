import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';
import { setCurrentViewAccessLevel } from './access-level/index.tsx';
import { setAutosaveEnabled, setDraft, saveUnsavedChanges } from './autosave/index.tsx';
import { resetDraft } from './autosave/reset-draft/index.tsx';
import {
	cloneView,
	cloneCurrentView,
	saveCurrentUnsavedViewAsNew,
	saveCurrentViewWithTemporaryDataAsNew,
} from './clone/index.tsx';
import { resizeFieldColumn, setHoveredFieldKeyForBulkEdit } from './column/index.tsx';
import {
	createViewComment,
	updateViewComment,
	deleteViewComment,
	seenViewComments,
} from './comments/index.tsx';
import { loadViewComments } from './comments/load-view-comments/index.tsx';
import { createNewView } from './create/index.tsx';
import { setCurrentView } from './current/index.tsx';
import { deleteView, deleteViewFromState } from './delete/index.tsx';
import { handleDeliveryDataFetchError } from './delivery-data/index.tsx';
import { changeViewDescription } from './description/index.tsx';
import { setIsExporting, setIsReadyToExport } from './export/index.tsx';
import { setRollupFieldsValue } from './fields-rollups/index.tsx';
import {
	removeFieldFromView,
	setViewColumns,
	sortViewField,
	hideViewField,
	toggleViewField,
} from './fields/index.tsx';
import {
	clearAllFilters,
	clearAllTemporaryFilters,
	updateFieldFilter,
	clearFieldFilter,
	clearFieldOrNumericFilter,
	updateNumericFilter,
	updateQuickSearchFilter,
	updateFilters,
} from './filter/index.tsx';
import { updateIntervalFilter } from './filter/interval/index.tsx';
import {
	setGroupBy,
	setVerticalGroupBy,
	setGroupValues,
	setVerticalGroupValues,
	setHideEmptyGroups,
	setHideEmptyColumns,
} from './group/index.tsx';
import { clearSelection, setSelection, toggleSelection } from './issue-selection/index.tsx';
import { loadViewLastViewed, updateLastViewedState } from './last-viewed/index.tsx';
import { setLayoutType } from './layout/index.tsx';
import { loadViews, loadView } from './load/index.tsx';
import {
	loadViewMarkers,
	createViewMarker,
	updateViewMarker,
	deleteViewMarker,
} from './markers/index.tsx';
import {
	setFieldValues,
	setFieldValuesReverseOrder,
} from './matrix-options/field-options/index.tsx';
import { setMatrixXAxis, setMatrixYAxis, setMatrixZAxis } from './matrix-options/index.tsx';
import { switchMatrixXAndYAxis } from './matrix-options/switch-axes/index.tsx';
import { setHasManageCurrentViewPermission } from './permissions/index.tsx';
import { rankViews, refreshViewsRanks } from './rank/index.tsx';
import {
	createSection,
	deleteSection,
	saveSection,
	deleteSectionFromState,
	loadSection,
} from './sections/index.tsx';
import { setForceReloadViewOnNextUpdate } from './set-force-reload/index.tsx';
import { setViewSets } from './set/index.tsx';
import {
	moveIssuesToTopOfCurrentView,
	moveIssueInCurrentView,
	setPermanentSortFieldsOfCurrentView,
	setTemporarySortFieldsOfCurrentView,
	setSortModeOfCurrentViewToProjectRank,
	setSortModeOfCurrentViewToViewRank,
	insertIssueAtIndexInCurrentView,
} from './sort/index.tsx';
import { toogleSwimlane, toggleAllSwimlanes, initSwimlanes } from './swimlanes/index.tsx';
import { syncViewsWithNavigation } from './sync/index.tsx';
import { clearViewTemporaryData, applyViewTemporaryData } from './temporary-data/index.tsx';
import {
	refreshArrangementInformation,
	updateArrangementInformation,
	setArrangementInformation,
} from './timeline/arrangement-information/index.tsx';
import {
	setTimelineMode,
	setStartDateField,
	setEndDateField,
	setStartTimelineIntervalDate,
	setEndTimelineIntervalDate,
	setSummaryCardField,
} from './timeline/index.tsx';
import {
	renameCurrentView,
	setEditCurrentViewTitle,
	renameView,
	addViewEmoji,
	addCurrentViewEmoji,
} from './title/index.tsx';
import { setUnsavedChanges } from './unsaved-changes/index.tsx';

export const actions = {
	setGroupBy,
	setHideEmptyColumns,
	updateFilters,
	setHideEmptyGroups,
	setStartDateField,
	setEndDateField,
	setStartTimelineIntervalDate,
	setEndTimelineIntervalDate,
	setVerticalGroupBy,
	setVerticalGroupValues,
	setGroupValues,
	setFieldValues,
	setFieldValuesReverseOrder,
	setViewColumns,
	sortViewField,
	hideViewField,
	toggleViewField,
	removeFieldFromView,
	setPermanentSortFieldsOfCurrentView,
	setTemporarySortFieldsOfCurrentView,
	setSortModeOfCurrentViewToProjectRank,
	setSortModeOfCurrentViewToViewRank,
	moveIssueInCurrentView,
	moveIssuesToTopOfCurrentView,
	insertIssueAtIndexInCurrentView,
	setCurrentView,
	deleteView,
	deleteViewFromState,
	renameView,
	addViewEmoji,
	renameCurrentView,
	addCurrentViewEmoji,
	rankViews,
	refreshViewsRanks,
	setEditCurrentViewTitle,
	cloneView,
	cloneCurrentView,
	saveCurrentUnsavedViewAsNew,
	saveCurrentViewWithTemporaryDataAsNew,
	createNewView,
	updateFieldFilter,
	clearFieldFilter,
	clearFieldOrNumericFilter,
	updateNumericFilter,
	updateQuickSearchFilter,
	clearAllFilters,
	clearAllTemporaryFilters,
	clearSelection,
	toggleSelection,
	setSelection,
	changeViewDescription,
	toogleSwimlane,
	toggleAllSwimlanes,
	initSwimlanes,
	createSection,
	saveSection,
	deleteSection,
	deleteSectionFromState,
	loadSection,
	loadViewComments,
	createViewComment,
	updateViewComment,
	deleteViewComment,
	seenViewComments,
	loadViewLastViewed,
	updateLastViewedState,
	resizeFieldColumn,
	setMatrixXAxis,
	setMatrixYAxis,
	setMatrixZAxis,
	switchMatrixXAndYAxis,
	setTimelineMode,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
	setIsExporting,
	setIsReadyToExport,
	setUnsavedChanges,
	refreshArrangementInformation,
	updateArrangementInformation,
	setArrangementInformation,
	updateIntervalFilter,
	setAutosaveEnabled,
	resetDraft,
	setDraft,
	saveUnsavedChanges,
	setHoveredFieldKeyForBulkEdit,
	setRollupFieldsValue,
	loadView,
	setLayoutType,
	handleDeliveryDataFetchError,
	setSummaryCardField,
	syncViewsWithNavigation,
	loadViewMarkers,
	createViewMarker,
	updateViewMarker,
	deleteViewMarker,
	setForceReloadViewOnNextUpdate,
	setHasManageCurrentViewPermission,
	setCurrentViewAccessLevel,
	applyViewTemporaryData,
	clearViewTemporaryData,
} as const;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export { actions as default, setViewSets, loadViews };
