import React, { useCallback, useMemo, useRef, type ReactNode, type RefObject } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { SmartCardProvider as Provider } from '@atlaskit/link-provider';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type {
	OptimisticallyDeletedWebLink,
	SmartWebLink,
} from '@atlassian/jira-issue-view-common-types/src/web-links-type.tsx';
import {
	useSmartCardProductType,
	useSmartCardIsAIEnabled,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { fireOperationalAnalytics, MountEvent } from '@atlassian/jira-product-analytics-bridge';
import type { WebLinkId } from '@atlassian/jira-shared-types/src/general.tsx';
import SmartLinkCard from '../smart-link-content/index.tsx';
import { JiraSmartLinkLocations } from '../smart-link-content/smart-link-analytics.tsx';
import { Container } from '../smart-link-content/styled.tsx';
import { deleteWebLinkDialogEntryPoint } from './delete-modal/entrypoint.tsx';
import type { Props } from './types.tsx';

export const EntryPointWrapper = ({
	children,
	entryPointProps,
}: {
	children: ReactNode;
	entryPointProps: {
		onClose: () => void;
		onDelete: () => void;
	};
}) => {
	const modalProps = useMemo(
		() => ({
			width: 'small',
		}),
		[],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={deleteWebLinkDialogEntryPoint}
			entryPointProps={entryPointProps}
			interactionName="delete-weblink-modal"
			modalProps={modalProps}
			useInternalModal
		>
			{({ ref }) => (
				<div
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					ref={ref as RefObject<HTMLDivElement>}
				>
					{children}
				</div>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

const WebLinkLineCardGroup = (props: Props) => {
	const smartCardProviderProduct = useSmartCardProductType();
	const isAIFeatureEnabled = useSmartCardIsAIEnabled();

	const deleteLinkIdRef = useRef<string | null>(null);

	const openDeleteModal = useCallback((id: WebLinkId) => {
		deleteLinkIdRef.current = id;
	}, []);

	const openDeleteModalCallback = useCallback(
		(id: WebLinkId) => openDeleteModal(id),
		[openDeleteModal],
	);

	const onDeleteCancel = useCallback(() => {
		deleteLinkIdRef.current = null;
	}, []);

	const onDeleteConfirm = useCallback(() => {
		if (deleteLinkIdRef.current) {
			props.onDelete(deleteLinkIdRef.current);
		}
	}, [props]);

	const { canDelete } = props;

	const entryPointProps = useMemo(
		() => ({
			onDelete: onDeleteConfirm,
			onClose: onDeleteCancel,
		}),
		[onDeleteCancel, onDeleteConfirm],
	);

	return (
		<StyledWrapper>
			<MountEvent
				onMount={(analyticsEvent: UIAnalyticsEvent) =>
					fireOperationalAnalytics(analyticsEvent, 'webLinkLineCardGroup rendered', {
						linkCount: props.links.length,
					})
				}
			/>
			<Container>
				<Provider product={smartCardProviderProduct} isAdminHubAIEnabled={isAIFeatureEnabled}>
					{props.links.map((link) => (
						<SmartLinkCard
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							key={(link as SmartWebLink).smartLinkId || link.id}
							analyticsLocation={JiraSmartLinkLocations.JIRA_WEB_LINKS}
							linkId={link.id}
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							smartLinkId={(link as SmartWebLink).smartLinkId || link.id}
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							deleteFailed={(link as OptimisticallyDeletedWebLink).deleteFailed}
							canDelete={canDelete}
							onDeleteClick={openDeleteModalCallback}
							url={link.href}
							text={
								link.linkText !== undefined && link.href !== link.linkText
									? link.linkText
									: undefined
							}
							onClick={props.onClick}
							entryPointWrapper={(p) => (
								<EntryPointWrapper {...p} entryPointProps={entryPointProps} />
							)}
						/>
					))}
				</Provider>
			</Container>
		</StyledWrapper>
	);
};

export default WebLinkLineCardGroup;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"div[class*='item-line-card-view__Card']:focus": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderBottomColor: token('color.border', colors.N30),
	},
});
