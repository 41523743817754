/**
 * @generated SignedSource<<4601605fbd572f9706424f62a8525840>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type panel_issueDesignSection_DesignPanel_issueViewRelayFragment$data = {
  readonly designs: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id?: string;
        readonly " $fragmentSpreads": FragmentRefs<"ui_designCard_DesignCard" | "unlinkConfirmationModal_issueDesignSection_UnlinkDesignConfirmationModal">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"designForm_issueAddDesignForm_AddDesignForm_designs" | "heading_issueDesignSection_DesignsHeading_designs">;
  };
  readonly " $fragmentType": "panel_issueDesignSection_DesignPanel_issueViewRelayFragment";
};
export type panel_issueDesignSection_DesignPanel_issueViewRelayFragment$key = {
  readonly " $data"?: panel_issueDesignSection_DesignPanel_issueViewRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"panel_issueDesignSection_DesignPanel_issueViewRelayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "panel_issueDesignSection_DesignPanel_issueViewRelayFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "GraphStoreSimplifiedIssueAssociatedDesignConnection",
        "kind": "LinkedField",
        "name": "designs",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "heading_issueDesignSection_DesignsHeading_designs"
          },
          {
            "kind": "FragmentSpread",
            "name": "designForm_issueAddDesignForm_AddDesignForm_designs"
          },
          {
            "concreteType": "GraphStoreSimplifiedIssueAssociatedDesignEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "kind": "ScalarField",
                          "name": "id"
                        },
                        "action": "THROW",
                        "path": "designs.edges.node.id"
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "ui_designCard_DesignCard"
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "unlinkConfirmationModal_issueDesignSection_UnlinkDesignConfirmationModal"
                      }
                    ],
                    "type": "DevOpsDesign"
                  }
                ]
              }
            ]
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__id"
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "designs"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "37e0a69790a89427ad8c237b94d949f0";

export default node;
