import React, { useMemo, useState } from 'react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { keyframes } from 'styled-components';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { getBaseAutomationUrl } from '@atlassian/jira-automation-platform/src/common/utils.tsx';

import { ManualRulesContainer } from '@atlassian/jira-automation-platform/src/ui/manual-rules-container/index.tsx';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	useIssueId,
	useIssueKey,
	useProjectKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useIsSimplifiedProject,
	useProjectId,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCanAdministerProject } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import {
	type CloudId,
	type ProjectId,
	toBaseUrl,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { StatusTransition, StatusValue } from '../../../common/types.tsx';
import { getLoopedTransitionsForStatus } from '../../../common/utils.tsx';
import { StatusService } from '../../../services/status-service/index.tsx';
import type {
	OnStatusChangeSuccess,
	OnTransitionSubmit,
} from '../../../services/status-service/types.tsx';
import { TransitionsQuery } from '../../../services/transitions-query/index.tsx';
import { ActionsMenu } from './actions-menu/index.tsx';

export type Props = {
	initialStatusValue?: StatusValue;
	onSubmit: OnTransitionSubmit;
	onSuccess: OnStatusChangeSuccess;
};

export type ActionsMenuComponentProps = {
	environment: Environment | null;
	cloudId: CloudId;
	projectId: ProjectId | undefined;
	issueKey: string;
	issueId?: string;
	transitions: StatusTransition[];
	onTransitionStatus: (selection: StatusTransition, event: UIAnalyticsEvent) => Promise<void>;
	animationEnabled: boolean;
	baseAutomationUrl: string;
	canManageAutomations: boolean;
};

const ActionsMenuComponent = ({
	issueKey,
	issueId,
	environment,
	cloudId,
	transitions,
	onTransitionStatus,
	animationEnabled,
	baseAutomationUrl,
	canManageAutomations,
}: ActionsMenuComponentProps) => (
	<ActionsWrapper animationEnabled={animationEnabled}>
		<ManualRulesContainer
			env={environment}
			cloudId={cloudId}
			issueIds={issueId ? [Number(issueId)] : []}
		>
			{({ triggerFetch, initialised, rules, invokingRuleId, invokeRuleOrShowDialog }) => (
				<ActionsMenu
					issueKey={issueKey}
					issueId={Number(issueId)}
					rules={rules}
					triggerFetch={triggerFetch}
					executeRule={invokeRuleOrShowDialog}
					executingRule={invokingRuleId}
					initialised={initialised}
					transitions={transitions}
					onSelect={onTransitionStatus}
					canManageAutomations={canManageAutomations}
					baseAutomationUrl={baseAutomationUrl}
				/>
			)}
		</ManualRulesContainer>
	</ActionsWrapper>
);

const Actions = ({ initialStatusValue, onSubmit, onSuccess }: Props) => {
	const issueKey = useIssueKey();

	const issueId = useIssueId();
	const baseUrl = toBaseUrl('');
	const projectKey = useProjectKey();
	const projectId = useProjectId(projectKey);
	const isSimplified = useIsSimplifiedProject(projectKey);
	const projectType = useProjectType(projectKey);

	const baseAutomationUrl = useMemo(
		() => getBaseAutomationUrl({ projectKey, projectType, isSimplified }),
		[projectKey, projectType, isSimplified],
	);

	const isProjectAdmin = useCanAdministerProject(projectKey);
	const isGlobalAdmin = useIsAdmin();

	const canManageAutomations = isProjectAdmin || isGlobalAdmin;

	const { environment, cloudId } = useTenantContext();
	const [animationEnabled, setAnimationEnabled] = useState(false);
	// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
	const onSuccesInternal = (...args) => {
		setAnimationEnabled(true);
		// @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
		onSuccess(...args);
	};

	return (
		<StatusService
			appearance="button"
			projectType={projectType || null}
			issueKey={issueKey}
			issueId={issueId}
			baseUrl={baseUrl}
			onSubmit={onSubmit}
			onSuccess={onSuccesInternal}
			onFailure={noop}
			onEditStart={noop}
			onEditCancel={noop}
			initialValue={initialStatusValue}
		>
			{({ value: statusServiceValue, loading: saving, transitionStatus }) => (
				<TransitionsQuery
					issueKey={issueKey}
					baseUrl={baseUrl}
					status={statusServiceValue}
					isSaving={saving}
					preFetch
					projectType={projectType}
				>
					{({ data: transitions }) => {
						const loopedTransitions = getLoopedTransitionsForStatus(
							transitions,
							statusServiceValue.id,
						);

						const menuProps: ActionsMenuComponentProps = {
							environment,
							cloudId,
							projectId,
							issueKey,
							issueId,
							transitions: loopedTransitions,
							onTransitionStatus: transitionStatus,
							animationEnabled,
							baseAutomationUrl,
							canManageAutomations,
						};
						return <ActionsMenuComponent {...menuProps} />;
					}}
				</TransitionsQuery>
			)}
		</StatusService>
	);
};

export default Actions;

const fadeInAnimation = keyframes({
	'0%': {
		opacity: 0,
		maxHeight: 0,
	},
	'50%': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		maxHeight: `${gridSize * 5}px`,
	},
	'100%': {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles,  @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ActionsWrapper = styled.div<{ animationEnabled?: boolean }>((props) => ({
	display: 'inline-flex',
	maxWidth: '100%',
	animationDuration: '0.5s',
	animationIterationCount: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	animationName: props.animationEnabled ? fadeInAnimation : 'none',
	animationTimingFunction: 'linear',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
}));
