import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unlinkConfirmationModalTitle: {
		id: 'issue-design-section.panel.unlink-confirmation-modal.unlink-confirmation-modal-title',
		defaultMessage: 'Unlink design from issue',
		description:
			'Unlink design confirmation dialog title asking if user really wants to unlink the linked design to the issue.',
	},
	unlinkConfirmationModalDescription: {
		id: 'issue-design-section.panel.unlink-confirmation-modal.unlink-confirmation-modal-description',
		defaultMessage:
			'Are you sure you want to unlink {designName} from this issue? Unlinking means that you will no longer be able to see this design in Jira. You can still link designs to this issue again later.',
		description:
			'Unlink design confirmation dialog description asking if user really wants to unlink the linked design to the issue.',
	},
	unlinkButtonLabel: {
		id: 'issue-design-section.panel.unlink-confirmation-modal.unlink-button-label',
		defaultMessage: 'Unlink',
		description: 'The button label to unlink the design',
	},
	cancelButtonLabel: {
		id: 'issue-design-section.panel.unlink-confirmation-modal.cancel-button-label',
		defaultMessage: 'Cancel',
		description: 'The button label to close the unlink confirmation dialog',
	},
	unlinkFailedFlagMessage: {
		id: 'issue-design-section.panel.unlink-confirmation-modal.unlink-failed-flag-message',
		defaultMessage: 'Design could not be unlinked. Try again.',
		description: 'Contents of a flag shown when unlinking design to issue failed',
	},
	unlinkSuccessFlagMessage: {
		id: 'issue-design-section.panel.unlink-confirmation-modal.unlink-success-flag-message',
		defaultMessage: 'Design successfully unlinked',
		description: 'Contents of a flag shown when unlinking design to issue was successful',
	},
	unlinkFailedFlagErrorTitle: {
		id: 'issue-design-section.panel.unlink-confirmation-modal.unlink-failed-flag-error-title',
		defaultMessage: 'Unable to unlink design',
		description:
			'Title of a flag shown to the user indicating that an unexpected error occurred when unlinking a design from an issue',
	},
	unlinkFailedFlagErrorIssueUnavailableDescription: {
		id: 'issue-design-section.panel.unlink-confirmation-modal.unlink-failed-flag-error-issue-unavailable-description',
		defaultMessage:
			'Design could not be unlinked because the issue has been deleted or is unavailable. Please reload this page.',
		description:
			'Description for a flag shown when unlinking a design from an issue failed because the issue has been deleted or is not available',
	},
	reloadPageMessage: {
		id: 'issue-design-section.panel.unlink-confirmation-modal.reload-page-message',
		defaultMessage: 'Reload this page',
		description: 'Button label that causes a page reload',
	},
});
