import React, { type ReactNode, useCallback, type ChangeEvent, type KeyboardEvent } from 'react';
import Checkbox from '@atlaskit/checkbox';
import { components } from '@atlaskit/select';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { EPIC_LINK_MAX_RESULTS } from '@atlassian/jira-issue-view-common-views/src/epic-link/constants.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import Intersection from './intersection.tsx';
import messages from './messages.tsx';
import { CheckboxWrapper } from './styled.tsx';

type Option =
	| {
			label?: string;
			options?: [];
	  }
	| undefined;

type Props = {
	options: Option[];
	children: ReactNode;
	selectProps: {
		componentsProps?: {
			isChecked?: boolean;
			onChange?: (arg1: ChangeEvent<HTMLInputElement>) => void;
		};
	};
};

const Menu = (props: Props) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// @ts-expect-error - TS2367 - This condition will always return 'false' since the types '0 | undefined' and '20' have no overlap.
	const isOptionsLengthMax = props.options[1]?.options?.length === EPIC_LINK_MAX_RESULTS;
	const onChange = props.selectProps.componentsProps?.onChange;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const isChecked = props.selectProps.componentsProps?.isChecked as boolean;

	const onVisibilityChange = useCallback(
		(isVisible: boolean) => {
			if (isVisible && isOptionsLengthMax) {
				const analyticsEvent = createAnalyticsEvent({});
				fireTrackAnalytics(analyticsEvent, 'intersectionComponent viewed', 'epicLink');
			}
		},
		[createAnalyticsEvent, isOptionsLengthMax],
	);

	const onClick = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'args' implicitly has an 'any' type.
		(args) => {
			if (onChange) {
				onChange(args);
				const analyticsEvent = createAnalyticsEvent({});
				fireTrackAnalytics(analyticsEvent, 'doneEpics filtered', 'epicLink', {
					includeDone: !isChecked,
				});
			}
		},
		[createAnalyticsEvent, isChecked, onChange],
	);

	const onKeyDown = useCallback((event: KeyboardEvent) => {
		// stopping propagation when focus moves to checkbox because select already binded with space and enter for selection
		if (event.key === ' ') {
			event.stopPropagation();
		}
	}, []);

	const label = intl.formatMessage(messages.checkboxLabel);

	return (
		// @ts-expect-error - TS2740 - Type '{ children: (Element | ReactNode)[]; options: Option[]; selectProps: { componentsProps?: { isChecked?: boolean | undefined; onChange?: ((arg1: ChangeEvent<HTMLInputElement>) => void) | undefined; } | undefined; }; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 6 more.
		<components.Menu {...props}>
			<CheckboxWrapper onClick={onClick}>
				<Checkbox
					onKeyDown={onKeyDown}
					isChecked={isChecked}
					label={label}
					/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
					testId="issue.views.field.epic.checkbox"
				/>
			</CheckboxWrapper>
			{props.children}
			{isOptionsLengthMax && <Intersection onVisibilityChange={onVisibilityChange} />}
		</components.Menu>
	);
};

export default Menu;
