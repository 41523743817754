/**
 * @generated SignedSource<<a17d74e0bbc34118b9d93a258861a1dc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designProvider$data = {
  readonly id: string;
  readonly " $fragmentType": "bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designProvider";
};
export type bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designProvider$key = {
  readonly " $data"?: bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designProvider$data;
  readonly " $fragmentSpreads": FragmentRefs<"bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designProvider">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "bulkUnlinkConfirmationModal_issueDesignSection_BulkUnlinkDesignConfirmationModal_designProvider",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    }
  ],
  "type": "DevOpsDesignProvider"
};

(node as any).hash = "8d09707a5f0cd4de21609ec4f2399dd8";

export default node;
