import React from 'react';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import ActionIcon from './action-icon/index.tsx';
import Watchers from './view.tsx';

const WatchesViewOld = () => <Watchers ActionIcon={ActionIcon} />;

export default componentWithCondition(
	() => ff('relay-migration-issue-fields-watchers_iqhn4'),
	AnalyticsSubject('watchers')(ActionIcon),
	WatchesViewOld,
);
