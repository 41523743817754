import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIntl } from 'react-intl-next';

import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import { Fragment } from '@atlaskit/editor-prosemirror/model';
import { Text } from '@atlaskit/primitives';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { ProseMirrorContentStatistics } from '@atlassian/ai-model-io/utils/prosemirror-content-statistics';
import { PoweredByRovo } from '@atlassian/generative-ai-modal/components/PoweredByRovo';
import {
	type PreviewButton,
	PreviewScreen,
	type PreviewSecondaryActionButton,
	type RefineDropdownItem,
} from '@atlassian/generative-ai-modal/screens/Preview';

import { convertExperienceName } from '../../analytics/analytics-flow/analyticsFlow';
import type { ModelIO } from '../../analytics/analytics-flow/analyticsFlowTypes';
import { useAnalyticsFlow } from '../../analytics/analytics-flow/analyticsFlowUtils';
import type {
	CopyButtonClickAEP,
	EditPromptClickAEP,
	ResponseFeedbackButtonClickAEP,
	RetryPromptButtonClickAEP,
} from '../../analytics/types';
import { createUnifiedAnalyticPayload, useFireAIAnalyticsEvent } from '../../analytics/utils';
import {
	type EditorPluginAIConfigItemAgentAction,
	type EditorPluginAIConfigItemMarkdownAction,
	getTranslatedItemTitle,
} from '../../config-items/config-items';
import type { BackendModel } from '../../provider/prompt-requests/types';
import type {
	ConvoAIResponseRovoAction,
	FeedbackMetadata,
	PluginFeedbackMetadata,
	RovoPublish,
} from '../../types';
import { convertPMFragmentToPreview } from '../../ui/convert-markdown-to-preview';
import { isConfluenceTitleToolbarSuggestTitle } from '../../utils/confluence-suggest-title/is-title-toolbar-suggest-title';
import { ErrorUtils } from '../../utils/errors';
import type { AIExperienceMachineContext } from '../get-ai-experience-service';
import { getPMFragmentWithFallback } from '../get-pm-fragment-with-fallback';
import { messages as experienceMessages } from '../messages';
import { useAIExperienceCommonDataContext } from '../useAIExperienceCommonData';
import { useOutsideClickHandler } from '../useOutsideClickHandler';

import { copyResponseToClipboard } from './utils/copy-response';
import { FAILURE_REASON } from './utils/errors';
import { getHasAcceptableUseWarning } from './utils/get-has-acceptable-use-warning';
import { stripMatchingOuterQuotes } from './utils/strip-matching-outer-quotes';
import { useAdditionalFooterText } from './utils/use-additional-footer-text';
import { useAIUsageDisclaimer } from './utils/use-ai-usage-disclaimer';

type PreviewScreenWithLogicProps = {
	aiExperienceMachineContext: AIExperienceMachineContext;
	isInputActive: boolean;
	backendModel?: BackendModel;
	rovoAction?: ConvoAIResponseRovoAction['data'];
	rovoPublish?: RovoPublish;
};

export function PreviewScreenWithLogic({
	aiExperienceMachineContext,
	isInputActive,
	backendModel,
	rovoAction,
	rovoPublish,
}: PreviewScreenWithLogicProps) {
	const { formatMessage, locale } = useIntl();

	const {
		parentPresetPromptLabel,
		latestPrompt,
		markdown: actualMarkdown,
		multiPrompts,
		configItem,
		userInput: actualUserInput,
		userADFInput: actualUserADFInput,
		editCount,
		retryPromptCount,
		refinementCount,
		idMap,
		responseHistory: history,
		contextStatistics,
		promptTrigger,
		channelId,
		//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
		fragment,
	} = aiExperienceMachineContext;

	const aiExperienceCommonData = useAIExperienceCommonDataContext();
	const {
		appearance,
		editorPluginAIProvider,
		editorView,
		endExperience,
		positions,
		providerFactory,
		sendToAIExperienceMachine,
		lastTriggeredFrom,
		modalRef,
		//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
		triggeredFor,
	} = aiExperienceCommonData;

	const additionalFooterText = useAdditionalFooterText({ promptTrigger, configItem, latestPrompt });
	const disclaimer = useAIUsageDisclaimer({ editorPluginAIProvider });

	/**
	 * Short title for the submenu if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	const childShortTitle =
		parentPresetPromptLabel && configItem.nestingConfig
			? formatMessage(configItem.nestingConfig.shortTitle)
			: '';

	const userInput = actualUserInput || '';
	const userADFInput = actualUserADFInput;

	const schema = editorView.state.schema;
	const product = editorPluginAIProvider.product;
	/**
	 * Please check with Ethan/PM upon removing onExperienceEvent
	 * calls, to confirm it is no longer used in any ongoing experiments.
	 */
	useEffect(() => {
		editorPluginAIProvider.onExperienceEvent?.REVIEW_STATE_ENTERED();
	}, [editorPluginAIProvider]);
	const clearInputRef = useRef<() => boolean>();

	const lastHistoryEvent = history.entries[history.positionFromEnd];
	const responseTooSimilar = lastHistoryEvent.responseTooSimilar;
	const inputOutputDiffRatio = lastHistoryEvent.inputOutputDiffRatio;

	/**
	 * This should not be treated as a fact we are attempting
	 * to match as many responses with acceptable use warnings
	 * however, we may get false positives or false negatives
	 *
	 * TODO POST EAP
	 * Modify 'getHasAcceptableUseWarning' when the backend is
	 * able to return acceptable-use-warning flags to the frontend
	 */
	let markdown: string = actualMarkdown || '';
	const hasAcceptableUseWarning = getHasAcceptableUseWarning({
		markdown,
		locale,
	});
	const fireAIAnalyticsEvent = useFireAIAnalyticsEvent();
	const analyticsFlow = useAnalyticsFlow();
	const enqueuedPreviewStep = useRef<boolean>(false);
	const errorReported = React.useRef<boolean>(false);

	//TODO: multiPrompts experiment cleanup - editor_ai_-_multi_prompts
	const [multiPromptIndex, setMultiPromptIndex] = useState(0);

	const combinedPrompts = useMemo(() => {
		return multiPrompts && multiPrompts?.length
			? [{ name: configItem.title, content: markdown }, ...(multiPrompts || [])]
			: undefined;
	}, [markdown, multiPrompts, configItem.title]);

	const combinedPromptsWithMarkdown = useMemo(() => {
		const pmFragmentPreviewData = getPMFragmentWithFallback({
			markdown: combinedPrompts ? combinedPrompts[multiPromptIndex].content : markdown,
			schema,
			source: 'preview',
			fireAIAnalyticsEvent: (event) => {
				if (!errorReported.current) {
					errorReported.current = true;
					fireAIAnalyticsEvent(event);
				}
			},
			idMap: idMap!,
		});
		const pmFragment = pmFragmentPreviewData?.pmFragment || Fragment.empty;
		const preview = convertPMFragmentToPreview({
			schema,
			pmFragment: pmFragment,
			showTelepointer: false,
			providerFactory,
		});

		return combinedPrompts
			? combinedPrompts.map((item) => ({
					name: item.name, // Keep the same name
					content: preview, // Replace with the preview that has markdown
				}))
			: undefined;
	}, [
		combinedPrompts,
		fireAIAnalyticsEvent,
		idMap,
		markdown,
		multiPromptIndex,
		providerFactory,
		schema,
	]);

	const [multiPromptPresetTitle, setMultiPromptPresetTitle] = useState(
		combinedPrompts && multiPromptIndex
			? formatMessage(combinedPrompts[multiPromptIndex].name)
			: undefined,
	);
	const [isMultiPromptLoading, setIsMultiPromptLoading] = useState(!!multiPrompts);
	const [multiPromptCycleCount, setMultiPromptCycleCount] = useState(0);

	useEffect(() => {
		if (multiPrompts && multiPrompts.length > 1) {
			setIsMultiPromptLoading(false);
		}
	}, [multiPrompts, setIsMultiPromptLoading]);

	if (markdown && isConfluenceTitleToolbarSuggestTitle({ configItem, lastTriggeredFrom })) {
		markdown = stripMatchingOuterQuotes(markdown);
	}

	const pmFragmentPreviewData =
		editorExperiment('platform_editor_ai_ai_button_block_elements', 'test') && fragment
			? {
					pmFragment: fragment,
					htmlConvertedToNodes: 0,
					htmlConvertedToFallbackNodes: 0,
					isTextFallbackUsed: false,
				}
			: getPMFragmentWithFallback({
					markdown: combinedPrompts ? combinedPrompts[multiPromptIndex].content : markdown,
					schema,
					source: 'preview',
					fireAIAnalyticsEvent: (event) => {
						if (!errorReported.current) {
							errorReported.current = true;
							fireAIAnalyticsEvent(event);
						}
					},
					idMap: idMap!,
				});

	const pmFragment = pmFragmentPreviewData?.pmFragment || Fragment.empty;
	const preview = getPreviewElement(pmFragment);

	useOutsideClickHandler({
		modalRef,
		userInput,
		onCancel: () => sendToAIExperienceMachine({ type: 'enable discard' }),
		onDiscard: () => sendToAIExperienceMachine({ type: 'enable discard' }),
	});

	//TODO: multiPrompts experiment cleanup - editor_ai_-_multi_prompts
	const getMultiPromptAnalyticsAttributes = useCallback((): {
		multiPromptCycleCount?: number;
		multiPromptExperienceName?: string;
	} => {
		return combinedPrompts
			? {
					multiPromptCycleCount,
					multiPromptExperienceName: multiPromptPresetTitle ?? formatMessage(configItem.title),
				}
			: {};
	}, [
		combinedPrompts,
		configItem.title,
		formatMessage,
		multiPromptCycleCount,
		multiPromptPresetTitle,
	]);

	/**
	 * gets preview JSX element based on fragment
	 */
	function getPreviewElement(pmFragment: Fragment): JSX.Element {
		if (responseTooSimilar) {
			const responseTooSimilarMessage = formatMessage(experienceMessages.responseTooSimilarMessage);
			return <Text as="p">{responseTooSimilarMessage}</Text>;
		}

		return convertPMFragmentToPreview({
			schema,
			pmFragment,
			showTelepointer: false,
			providerFactory,
		});
	}

	useEffect(() => {
		analyticsFlow.fireQueuedStep('preview');

		let modelIO: ModelIO | undefined;
		if (pmFragmentPreviewData) {
			const stats = new ProseMirrorContentStatistics(pmFragment);
			const contentStatistics = stats.collectStatistics();

			modelIO = {
				selectionSize: lastHistoryEvent.modelInput.selection?.length,
				nodesConvertedToHTMLInput: idMap ? Object.keys(idMap).length : undefined,
				outputHTMLConvertedToNodes: pmFragmentPreviewData.htmlConvertedToNodes,
				outputHTMLConvertedToFallbackNodes: pmFragmentPreviewData.htmlConvertedToFallbackNodes,
				isTextFallbackUsed: pmFragmentPreviewData.isTextFallbackUsed,
				previewNodeCounts: contentStatistics.nodeCounts,
				previewMarkCounts: contentStatistics.markCounts,
				previewTotalCharacterCounts: contentStatistics.totalCharacterCount,
				userInputCharacterCount: localizedLatestPrompt.userInput?.length,
			};
		}

		analyticsFlow.addAttributes({
			editCount,
			responseTooSimilar,
			modelIO: {
				contextStatistics,
				...modelIO,
			},
			inputOutputDiffRatio,
		});

		return () => {
			// We only want to enqueue the preview step if it hasn't been enqueued
			// already. This is to handle the case where the user clicks on an
			// ai action button where we manually enqueue the analytics event,
			// and then seperately fire a complete step.

			if (enqueuedPreviewStep.current === false) {
				analyticsFlow.enqueueStep({
					stepName: 'preview',
					attributes: undefined,
				});
			}
		};
		// expecting to enqueue step only once on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fireDismissEvent = useCallback(() => {
		// Introduced in EDF-1443 to track Retry events as AI response dismissal
		fireAIAnalyticsEvent({
			payload: createUnifiedAnalyticPayload(
				'dismissed',
				channelId ?? analyticsFlow.getLastAiInteractionId(),
				convertExperienceName(configItem.key.toString()),
				false,
				getMultiPromptAnalyticsAttributes(),
			),
		});
	}, [
		analyticsFlow,
		channelId,
		configItem.key,
		fireAIAnalyticsEvent,
		getMultiPromptAnalyticsAttributes,
	]);

	const fireResponseFeedbackEvent = (
		sentiment: FeedbackMetadata['sentiment'],
		productSuccessState: ResponseFeedbackButtonClickAEP['attributes']['productSuccessStatus'],
	) => {
		fireAIAnalyticsEvent({
			payload: {
				action: 'click',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'responseFeedbackButton',
				attributes: {
					sentiment,
					hasAcceptableUseWarning,
					appearance,
					product,
					configItemTitle: configItem.agent?.name || configItem.key,
					lastTriggeredFrom,
					productSuccessStatus: productSuccessState,
					inputOutputDiffRatio,
					retryPromptCount,
					responseTooSimilar,
					aiInteractionID: analyticsFlow.getLastAiInteractionId(),
					...getMultiPromptAnalyticsAttributes(),
				},
				eventType: EVENT_TYPE.UI,
			} as ResponseFeedbackButtonClickAEP,
		});

		fireAIAnalyticsEvent({
			payload: createUnifiedAnalyticPayload(
				'submitted',
				channelId ?? analyticsFlow.getLastAiInteractionId(),
				convertExperienceName(configItem.key.toString()),
				false,
				{
					...getMultiPromptAnalyticsAttributes(),
					aiFeedbackResult: sentiment === 'good' ? 'up' : 'down',
				},
			),
		});
	};

	const handleFiringFeedbackEvent = async (feedbackMetadata: FeedbackMetadata) => {
		analyticsFlow.addAttributes({
			userFeedbackSentiment: feedbackMetadata.sentiment,
		});

		// handleFeedbackSubmission is intended to be implemented
		// by all products -- there will be a period where products won't have
		// it implemented (and will be relying on THUMBS_UP/THUMBS_DOWN)
		// in these cases -- we don't know the product response status
		if (!editorPluginAIProvider.handleFeedbackSubmission) {
			fireResponseFeedbackEvent(feedbackMetadata.sentiment, 'unknown');
			return;
		} else {
			try {
				const productResponse =
					await editorPluginAIProvider.handleFeedbackSubmission(feedbackMetadata);
				fireResponseFeedbackEvent(feedbackMetadata.sentiment, productResponse.status);
			} catch {
				fireResponseFeedbackEvent(feedbackMetadata.sentiment, 'failed');
			}
		}
	};

	// preset prompt or base generate / interrogation
	const localizedLatestPrompt = useMemo(() => {
		return latestPrompt?.presetTitle
			? {
					presetTitle: getTranslatedItemTitle(latestPrompt.presetTitle, formatMessage),
					userInput: latestPrompt?.userInput,
					userADFInput: latestPrompt?.userADFInput,
				}
			: {
					userInput: latestPrompt?.userInput || '',
					userADFInput: latestPrompt?.userADFInput,
				};
	}, [latestPrompt, formatMessage]);

	const onEditPromptClick = useCallback(() => {
		const { userInput, userADFInput, presetTitle } = localizedLatestPrompt;

		analyticsFlow.addAttributes({
			actionTaken: 'edit-prompt',
		});

		fireAIAnalyticsEvent({
			payload: {
				action: 'click',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'editPromptButton',
				attributes: {
					experienceType: convertExperienceName(configItem.key.toString()),
					editCount,
					...getMultiPromptAnalyticsAttributes(),
				},
				eventType: EVENT_TYPE.UI,
			} as EditPromptClickAEP,
		});

		fireDismissEvent();

		sendToAIExperienceMachine({
			type: 'edit prompt',
			userInput,
			userADFInput,
			presetTitle,
		});
	}, [
		configItem.key,
		localizedLatestPrompt,
		analyticsFlow,
		fireAIAnalyticsEvent,
		editCount,
		getMultiPromptAnalyticsAttributes,
		fireDismissEvent,
		sendToAIExperienceMachine,
	]);

	const discardWithoutWarning = useCallback(() => {
		const { presetTitle } = localizedLatestPrompt;
		sendToAIExperienceMachine({
			type: 'edit prompt',
			userInput: '',
			userADFInput: undefined,
			presetTitle,
		});
	}, [localizedLatestPrompt, sendToAIExperienceMachine]);

	const onBack = useCallback(() => {
		sendToAIExperienceMachine({
			type: 'enable discard',
			context: { onceDiscard: discardWithoutWarning },
		});
	}, [sendToAIExperienceMachine, discardWithoutWarning]);

	const aiExperienceMetadata = {
		hasAcceptableUseWarning,
		configItemTitle: configItem.agent?.name || convertExperienceName(configItem.key.toString()),
		lastTriggeredFrom,
		inputOutputDiffRatio,
		backendModel,
	};

	const getAIExperience: PluginFeedbackMetadata['getAIExperience'] = (
		hasUserConsent?: boolean,
	) => ({
		...aiExperienceMetadata,
		...(hasUserConsent && {
			userPrompt: localizedLatestPrompt.userInput,
			markdownResponse: markdown,
		}),
	});

	React.useEffect(() => {
		/**
		 * When userInput is empty, we need to clear interrogate editor
		 *  as setting empty userInput as defaultValue won't clear editor.
		 */
		if (!userInput) {
			clearInputRef.current?.();
		}
	}, [userInput]);

	const onInputChange = React.useCallback(
		(inputValue: string) => {
			sendToAIExperienceMachine({
				type: 'update user input',
				input: inputValue,
			});
		},
		[sendToAIExperienceMachine],
	);
	const onADFChange = React.useCallback(
		(inputValue: JSONDocNode) => {
			sendToAIExperienceMachine({
				type: 'update user input adf',
				adf: inputValue,
			});
		},
		[sendToAIExperienceMachine],
	);

	const disableInterrogation =
		configItem.disableInterrogation?.() || editorPluginAIProvider.disableInterrogation;

	const onCancelClick = useCallback(() => {
		if (
			(editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') && userInput) ||
			(!editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') && isInputActive)
		) {
			fireDismissEvent();
			sendToAIExperienceMachine({ type: 'cancel refinement' });
		} else {
			fireDismissEvent();
			discardWithoutWarning();
		}
	}, [
		isInputActive,
		userInput,
		discardWithoutWarning,
		fireDismissEvent,
		sendToAIExperienceMachine,
	]);

	const onRetryPromptClick = useCallback(() => {
		fireAIAnalyticsEvent({
			payload: {
				action: 'click',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'retryPromptButton',
				attributes: {
					experienceType: convertExperienceName(configItem.key.toString()),
					...getMultiPromptAnalyticsAttributes(),
				},
				eventType: EVENT_TYPE.UI,
			} as RetryPromptButtonClickAEP,
		});

		fireDismissEvent();

		sendToAIExperienceMachine({
			type: 'retry prompt',
			input: localizedLatestPrompt.userInput,
			adf: localizedLatestPrompt.userADFInput,
		});
	}, [
		configItem.key,
		fireAIAnalyticsEvent,
		fireDismissEvent,
		getMultiPromptAnalyticsAttributes,
		localizedLatestPrompt.userADFInput,
		localizedLatestPrompt.userInput,
		sendToAIExperienceMachine,
	]);

	const onActionButtonClick = useCallback(
		({
			runFn,
			analyticsTitle,
			preserveEditorSelectionOnComplete,
		}: {
			runFn: () => void;
			analyticsTitle: string;
			preserveEditorSelectionOnComplete?: boolean;
		}) => {
			try {
				analyticsFlow.addAttributes({
					actionTaken: analyticsTitle,
				});
				analyticsFlow.enqueueStep({
					stepName: 'preview',
					attributes: {
						...getMultiPromptAnalyticsAttributes(),
					},
				});
				// We set this to true to prevent the preview step from being
				// enqueued again on unmount
				// Note -- in future -- we expect the complete step to be deprecated
				// an replaced with a "content highlighted" step
				enqueuedPreviewStep.current = true;
				analyticsFlow.fireQueuedStep('complete');
				const beforeActionRun = performance.now();
				runFn();
				analyticsFlow.enqueueStep({
					stepName: 'complete',
					attributes: {
						actionTaken: analyticsTitle,
						duration: Math.round(performance.now() - beforeActionRun),
						editCount: editCount,
						retryPromptCount,
						promptType: promptTrigger,
						refinementCount,
						inputOutputDiffRatio,
						...getMultiPromptAnalyticsAttributes(),
					},
				});

				/**
				 * Please check with Ethan/PM upon removing onExperienceEvent
				 * calls, to confirm it is no longer used in any ongoing experiments.
				 */
				editorPluginAIProvider.onExperienceEvent?.EXPERIENCE_COMPLETE();
				endExperience({ preserveEditorSelectionOnComplete });
			} catch (error) {
				fireAIAnalyticsEvent({
					payload: {
						action: 'unhandledErrorCaught',
						actionSubject: 'editorPluginAI',
						actionSubjectId: 'experienceApplication',
						attributes: {
							errorType: 'actionRunError',
							actionTitle: analyticsTitle,
							errorMessage: ErrorUtils.extractErrorMessage(error),
						},
						eventType: EVENT_TYPE.OPERATIONAL,
					},
				});
				sendToAIExperienceMachine({
					type: 'error',
					errorInfo: {
						failureReason: FAILURE_REASON.UNKNOWN,
					},
				});
			}
		},
		[
			analyticsFlow,
			editCount,
			editorPluginAIProvider.onExperienceEvent,
			endExperience,
			fireAIAnalyticsEvent,
			getMultiPromptAnalyticsAttributes,
			inputOutputDiffRatio,
			retryPromptCount,
			promptTrigger,
			refinementCount,
			sendToAIExperienceMachine,
		],
	);

	const mapMarkdownAction = useCallback(
		(action: EditorPluginAIConfigItemMarkdownAction): PreviewButton => {
			// For Rovo actions, we only generate a fragment from the suggested content (without bot pleasantries)
			const contentFragment =
				action.isRovoAgentAction && rovoAction
					? getPMFragmentWithFallback({
							markdown: rovoAction.content || '',
							schema,
							source: 'rovo-action',
							// TODO: https://product-fabric.atlassian.net/browse/EDF-1044
							fireAIAnalyticsEvent: () => {},
							// Won't be expecting Markdown Plus
							idMap: {},
						}).pmFragment
					: pmFragment;

			return {
				label: formatMessage(action.title),
				appearance: action.appearance,
				onClick: () => {
					onActionButtonClick({
						runFn: () => {
							action.run({
								editorView,
								positions,
								markdown: combinedPrompts ? combinedPrompts[multiPromptIndex].content : markdown,
								pmFragment: contentFragment,
								//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
								triggeredFor,
							});

							if (configItem.agent && editorPluginAIProvider.onDocChangeByAgent) {
								editorPluginAIProvider.onDocChangeByAgent(configItem.agent);
							}
						},
						analyticsTitle:
							typeof action.title.defaultMessage === 'string' ? action.title.defaultMessage : '',
					});
				},
			};
		},
		[
			rovoAction,
			schema,
			pmFragment,
			formatMessage,
			onActionButtonClick,
			editorView,
			positions,
			combinedPrompts,
			multiPromptIndex,
			markdown,
			triggeredFor,
			configItem.agent,
			editorPluginAIProvider,
		],
	);

	const mapAgentAction = useCallback(
		(action: EditorPluginAIConfigItemAgentAction): PreviewSecondaryActionButton => {
			return {
				label: formatMessage(action.title),
				appearance: action.appearance,
				icon: action.icon,
				shortcut: action.shortcut,
				onClick: () => {
					onActionButtonClick({
						runFn: () =>
							action.run({
								channelId,
								publish: rovoPublish,
							}),
						analyticsTitle:
							typeof action.title.defaultMessage === 'string' ? action.title.defaultMessage : '',
						preserveEditorSelectionOnComplete: action.preserveEditorSelectionOnComplete,
					});
				},
			};
		},
		[channelId, formatMessage, onActionButtonClick, rovoPublish],
	);

	//TODO: multiPrompts experiment cleanup - editor_ai_-_multi_prompts
	const onMultiPromptClick = useCallback(
		(direction: number) => {
			const mutlipromptsLength = combinedPrompts!.length;

			let index = multiPromptIndex + direction;

			if (index < 0) {
				index = mutlipromptsLength - 1;
			} else if (index >= mutlipromptsLength) {
				index = 0;
			}

			setMultiPromptCycleCount((count) => count + 1);
			setMultiPromptIndex(index);
			setMultiPromptPresetTitle(formatMessage(combinedPrompts![index].name));
		},
		[combinedPrompts, multiPromptIndex, formatMessage],
	);

	const applications = useMemo(() => {
		return configItem.actions.map((action) => {
			if (action.type === 'agent') {
				return mapAgentAction(action);
			}
			return mapMarkdownAction(action);
		});
	}, [configItem.actions, mapMarkdownAction, mapAgentAction]);

	const refineDropdownItems = useMemo(() => {
		const allRefineSuggestions = editorPluginAIProvider.rangeConfig
			.getSuggestions({ inputValue: '' })
			.filter((suggestion) => suggestion.showInRefineDropdown);

		const result: RefineDropdownItem[] = [];
		const parentForNesting: { [parentKey: string]: RefineDropdownItem } = {};

		const handleClick = (configItem: (typeof allRefineSuggestions)[0]) => () => {
			sendToAIExperienceMachine({
				type: 'refine',
				configItem,
				latestPromptResponse: markdown!,
			});
		};

		for (const suggestion of allRefineSuggestions) {
			const { nestingConfig } = suggestion;
			if (!nestingConfig) {
				result.push({
					title: suggestion.title,
					icon: <>{suggestion.icon?.({ formatMessage, shownAt: 'suggestions' })}</>,
					onClick: handleClick(suggestion),
				});
			} else {
				const parentKey = nestingConfig.parentTitle.id ?? JSON.stringify(nestingConfig.parentTitle);

				if (!parentForNesting[parentKey]) {
					const icon = nestingConfig.icon || suggestion.icon;

					const parent: RefineDropdownItem = {
						icon: <> {icon?.({ formatMessage, shownAt: 'suggestions' })} </>,
						title: nestingConfig.parentTitle,
						children: [],
					};

					result.push(parent);
					parentForNesting[parentKey] = parent;
				}

				parentForNesting[parentKey].children?.push({
					title: suggestion.nestingConfig?.shortTitle ?? suggestion.title,
					onClick: handleClick(suggestion),
				});
			}
		}

		return result;
	}, [editorPluginAIProvider.rangeConfig, formatMessage, markdown, sendToAIExperienceMachine]);

	const historyProps = React.useMemo(() => {
		if (editorExperiment('platform_editor_ai_response_history', true)) {
			return {
				onTraverse: (direction: 'back' | 'forward') => {
					sendToAIExperienceMachine({
						type: 'traverse history',
						direction,
					});
				},
				showButtons: history.entries.length > 1,
				canTraverseForward: history.positionFromEnd > 0,
				canTraverseBack: history.positionFromEnd < history.entries.length - 1,
			};
		}
	}, [history.entries, history.positionFromEnd, sendToAIExperienceMachine]);

	return (
		<PreviewScreen
			key={multiPromptPresetTitle ?? configItem.key}
			parentPresetPromptLabel={parentPresetPromptLabel}
			childShortTitle={childShortTitle}
			actionButtonsLayoutMode="compact"
			latestPrompt={localizedLatestPrompt}
			preview={preview}
			disableActions={!!responseTooSimilar}
			inputValue={userInput}
			inputADFValue={userADFInput}
			isInputActive={isInputActive}
			onCancel={onCancelClick}
			onInputChange={onInputChange}
			onADFChange={onADFChange}
			onInputFocus={() => {
				sendToAIExperienceMachine({ type: 'start refinement' });
			}}
			onInputBlur={() => {
				if (!userInput) {
					sendToAIExperienceMachine({ type: 'cancel refinement' });
				}
			}}
			onSubmit={() => {
				/**
				 * Do we really need to pass userInput and userADFInput
				 *  on firing interrogate event.
				 * We should have latest userInput and userADFInput
				 *  set by onInputChange and onADFChange.
				 */
				sendToAIExperienceMachine({
					type: 'interrogate',
					userInput: userInput,
					userADFInput,
					latestPromptResponse: markdown!,
				});
			}}
			onCopy={() => {
				fireAIAnalyticsEvent({
					payload: {
						action: 'click',
						actionSubject: 'editorPluginAI',
						actionSubjectId: 'copyButton',
						attributes: {
							experienceType: convertExperienceName(configItem.key.toString()),
							editCount,
							...getMultiPromptAnalyticsAttributes(),
						},
						eventType: EVENT_TYPE.UI,
					} as CopyButtonClickAEP,
				});

				analyticsFlow.addAttributes({
					contentCopied: true,
				});
				copyResponseToClipboard({ editorView, pmFragment });
			}}
			onRetry={!configItem.agent ? onRetryPromptClick : undefined}
			onGoodResponseButtonClick={() => {
				const feedbackMetadata = {
					sentiment: 'good' as const,
					getAIExperience,
					editorAttributes: { appearance, product },
				};

				// Now deprecated - migrate to handleFeedbackSubmission
				editorPluginAIProvider.onExperienceEvent &&
					editorPluginAIProvider.onExperienceEvent.THUMBS_UP();

				handleFiringFeedbackEvent(feedbackMetadata);
			}}
			onBadResponseButtonClick={() => {
				const feedbackMetadata = {
					sentiment: 'bad' as const,
					getAIExperience,
					editorAttributes: { appearance, product },
				};

				// Now deprecated - migrate to handleFeedbackSubmission
				editorPluginAIProvider.onExperienceEvent &&
					editorPluginAIProvider.onExperienceEvent.THUMBS_DOWN();

				handleFiringFeedbackEvent(feedbackMetadata);
			}}
			onEditPromptClick={onEditPromptClick}
			onBack={onBack}
			applications={applications}
			secondaryActions={(configItem.secondaryActions ?? []).map((action) => mapAgentAction(action))}
			PromptEditor={editorPluginAIProvider.PromptEditor}
			clearInputRef={clearInputRef}
			disclaimer={disclaimer}
			additionalFooterText={additionalFooterText}
			providerFactory={providerFactory}
			schema={schema}
			disableInterrogation={disableInterrogation}
			footerRight={configItem.agent ? <PoweredByRovo /> : undefined}
			agent={configItem.agent}
			multiPrompts={combinedPromptsWithMarkdown}
			onMultiPromptClick={onMultiPromptClick}
			multiPromptPresetTitle={multiPromptPresetTitle}
			multiPromptIndex={multiPromptIndex}
			isMultiPromptLoading={isMultiPromptLoading}
			refineDropdownItems={refineDropdownItems}
			historyProps={historyProps}
		/>
	);
}
