/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { forwardRef } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import Tag, { type AppearanceType, SimpleTag } from '@atlaskit/tag';

import messages from './messages';
import { cssVars, refinementTagStyles, refinementTagStylesVisualRefresh } from './styles';

type Props = {
	onClick?: () => void;
	label: string;
	elemBefore?: React.ReactNode;
	appearance?: AppearanceType;
};

/**
 * RefinementTag is the component which renders the
 * title of preset prompts such as
 * - Brainstorm
 * - Summarize
 *
 * When rendered as part of a user input field, it should provide an
 * onClick to display the remove button to allow users to remove the tag.
 * When rendered as a presentational element to show which
 * preset prompt has been used, it should not provide an onClick
 * to keep the tag presentational.
 */
export const RefinementTag = forwardRef<HTMLDivElement, Props>(
	({ label, onClick, elemBefore, appearance }, ref) => {
		const styles = {
			[cssVars.alignItems]: appearance === 'rounded' ? 'center' : 'flex-start',
			[cssVars.elemBeforeHeight]: appearance === 'rounded' ? '100%' : 'auto',
		} as React.CSSProperties;

		const { formatMessage } = useIntl();

		const css = fg('platform_editor_ai_command_palette_post_ga_jira')
			? refinementTagStylesVisualRefresh
			: refinementTagStyles;

		return (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={styles}
				// eslint-disable-next-line  @atlaskit/design-system/consistent-css-prop-usage
				css={css}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="refinement-tag"
				data-testid="command-palette-refinement-tag"
				role="status"
				aria-atomic="true"
				aria-label={formatMessage(messages.promptSelected, { promptName: label })}
				aria-live="assertive"
				ref={ref}
			>
				{!onClick ? (
					<SimpleTag
						testId={'refinement-tag'}
						text={label}
						elemBefore={elemBefore}
						appearance={appearance}
					/>
				) : (
					<Tag
						appearance={appearance}
						elemBefore={elemBefore}
						testId={'refinement-tag'}
						removeButtonLabel={formatMessage(messages.removeLabel)}
						text={label}
						onBeforeRemoveAction={() => {
							onClick();
							return false;
						}}
					/>
				)}
			</div>
		);
	},
);
