import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpotlightCardContainer = styled.div({
	position: 'absolute',
	right: token('space.400', '32px'),
	top: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.dialog,
});
