import { pluginFactory } from '@atlaskit/editor-common/utils';
import type { EditorState, ReadonlyTransaction } from '@atlaskit/editor-prosemirror/state';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';

import { aiProactivePluginKey } from './ai-proactive-plugin-key';
// import { DOC_CHANGE_COUNT_LIMIT } from './constants';
import { regenerateAllDecorations, regenerateSelectionDecorations } from './decorations';
import { reducer } from './reducer';
import type { AIProactivePluginState } from './states';
import {
	findProactiveAIBlockToUpdate,
	getAllProactiveAIBlocks,
	getSelectedRecommendations,
	markRecommendationViewed,
} from './utils';

const handleDocChanged = (
	tr: ReadonlyTransaction,
	pluginState: AIProactivePluginState,
	newEditorState: EditorState,
): AIProactivePluginState => {
	const { updatedProactiveAIBlock } = findProactiveAIBlockToUpdate(tr, pluginState, newEditorState);

	const {
		decorationSet,
		// selectedID,
		// docChangeCountUntilLimit
	} = pluginState;

	// const uniqueInteractionID = tr.getMeta(aiProactivePluginKey)?.uniqueInteractionID ?? selectedID;

	return {
		...pluginState,
		// selectedID: uniqueInteractionID,
		decorationSet: regenerateAllDecorations({
			decorationSet,
			tr,
			pluginState: {
				...pluginState,
				proactiveAIBlocks: updatedProactiveAIBlock,
			},
		}),
		proactiveAIBlocks: updatedProactiveAIBlock,
		// ...(docChangeCountUntilLimit < DOC_CHANGE_COUNT_LIMIT && {
		// 	docChangeCountUntilLimit: docChangeCountUntilLimit + 1,
		// }),
	};
};

const handleSelectionChanged = (
	tr: ReadonlyTransaction,
	pluginState: AIProactivePluginState,
): AIProactivePluginState => {
	const {
		decorationSet,
		isProactiveContextPanelOpen,
		alwaysOpenProactiveContextPanelOnInlineSelection,
		alwaysDisplayProactiveInlineDecorations,
		selectedRecommendationId,
	} = pluginState;

	const selectedRecommendations =
		tr.selection.from === tr.selection.to
			? getSelectedRecommendations(pluginState, tr.selection.from)
			: undefined;

	let shouldOpenContextPanel = false;
	if (
		alwaysDisplayProactiveInlineDecorations &&
		alwaysOpenProactiveContextPanelOnInlineSelection &&
		!isProactiveContextPanelOpen &&
		!!selectedRecommendations
	) {
		shouldOpenContextPanel = selectedRecommendations.length > 0;
	}

	// XXX: It's possible for a block to contain many recommendations, so when a block is selected we will just set the
	// selected id to the first recommendation in the list.
	const currentSelectedRecommendationId =
		selectedRecommendations?.find((r) => r.id === selectedRecommendationId)?.id ??
		selectedRecommendations?.[0]?.id;

	const nextState = {
		...pluginState,
		proactiveAIBlocks: markRecommendationViewed(pluginState, currentSelectedRecommendationId),
		selectedRecommendationId: currentSelectedRecommendationId,
		isProactiveContextPanelOpen: shouldOpenContextPanel ? true : isProactiveContextPanelOpen,
	};

	return {
		...nextState,
		decorationSet: regenerateSelectionDecorations({
			decorationSet,
			tr,
			pluginState: nextState,
		}),
	};
};

export const createInitialState =
	({
		proactiveAIApiUrl,
		// documentSGChecker,
		defaultToggledState,
		product,
	}: {
		proactiveAIApiUrl: string;
		// documentSGChecker?: DocumentSGChecker;
		defaultToggledState?: boolean;
		product: string;
	}) =>
	(state: EditorState) => {
		const { proactiveAIBlocks } = getAllProactiveAIBlocks(state);
		const initialToggleState = !!defaultToggledState;
		return {
			product,
			proactiveAIBlocks,
			initialToggleState,
			isProactiveEnabled: true,
			isProactiveContextPanelOpen: false, // was: initialToggleState
			canProactiveContextPanelOpen: false,
			alwaysDisplayProactiveInlineDecorations: false,
			alwaysOpenProactiveContextPanelOnInlineSelection: false,
			validateBlocksOnMissingRecommendations: true,
			allowInlineHoverHighlightWhileRecommendationSelected: true,
			decorationSet: DecorationSet.empty,
			proactiveAIApiUrl,
			// dismissedWords: new Set<string>(),
			availableRecommendationIds: new Set<string>(),
			toggleCount: initialToggleState ? 1 : 0,
			insertionCount: 0,
			dismissedCount: 0,
			// documentSGChecker,
			docChangeCountUntilLimit: 0,
			isFirstInitiatedEventSent: false,
		};
	};

export const { createPluginState, createCommand, getPluginState } = pluginFactory(
	aiProactivePluginKey,
	reducer,
	{
		onDocChanged: handleDocChanged,
		onSelectionChanged: handleSelectionChanged,
	},
);
