import React, {
	type ComponentType,
	type ForwardRefExoticComponent,
	useState,
	Fragment,
	forwardRef,
} from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipProps } from '@atlaskit/tooltip';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export type MinimumItemData = {
	key: string | number;
};

export type Props<TData extends MinimumItemData> = {
	/**
	 * Tooltip shown when the user hovers over the show more button, which appears as a "+X" button
	 * when more than 1 item is present.
	 */
	showMoreTooltip: string;
	/**
	 * An array of the items to be rendered
	 */
	items: TData[];
	/**
	 * The component used to render the items. You may choose to create your own, or use one of the
	 * prebuilt components such as `LinkItem` or `TagItem`.
	 *
	 * It will receive all of the data passed to the `items` prop, except for the `key` property.
	 */
	ItemComponent: ComponentType<Omit<TData, 'key'>>;
	/**
	 * A component that wraps the content of the show more popup. Some of the prebuilt components
	 * have a corresponding wrapper component, like `LinkItemPopupContentWrapper` or
	 * `TagItemPopupContentWrapper` available.
	 *
	 * You can use Design System primitives like `Inline` and `Stack` to customise the layout of the
	 * items in the popup.
	 */
	PopupContentWrapper?:
		| ComponentType<{
				children: React.ReactNode;
				[key: string]: unknown;
		  }>
		| ComponentType<{
				[key: string]: unknown;
		  }>
		| ForwardRefExoticComponent<{
				[key: string]: unknown;
		  }>;
	/**
	 * The maximum number of items that can be displayed in Show more popup
	 * Default value is 99 items
	 * Example: if maxLimit is 99 and items contains 100 elements, 99 of them will be shown in the popup
	 */
	maxLimit?: number;
	/**
	 * Component that can be used to override the default content inside the popup
	 * When this prop is undefined, the default content will be rendered (list of items)
	 */
	OverrideContentComponent?: React.JSX.Element;
	/**
	 * The initial open state of the Show more popup
	 * Default value is false
	 */
	initialIsOpen?: boolean;
};

// can be removed when cleaning up extend-popup-cell
const MAX_DISPLAYED_AMOUNT = 99;

const ShowMorePopup = <TData extends MinimumItemData>({
	showMoreTooltip,
	items,
	ItemComponent,
	PopupContentWrapper = Fragment,
	maxLimit = 99,
	OverrideContentComponent = undefined,
	initialIsOpen = false,
}: Props<TData>) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isOpen, setIsOpen] = useState(initialIsOpen);

	if (items.length === 0) {
		return null;
	}

	const list = fg('extend-popup-cell')
		? items.slice(0, maxLimit)
		: items.slice(0, MAX_DISPLAYED_AMOUNT);

	const onClickTrigger = () => {
		setIsOpen(!isOpen);
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'listWithPopup',
		);
	};

	const content = () => (
		<>
			{fg('extend-popup-cell') ? (
				<Box xcss={ContainerDialogStyles}>
					{OverrideContentComponent === undefined ? (
						<PopupContentWrapper>
							{list.map(({ key, ...item }) => (
								<Fragment key={key}>
									<ItemComponent {...item} />
								</Fragment>
							))}
						</PopupContentWrapper>
					) : (
						OverrideContentComponent
					)}
				</Box>
			) : (
				<Box xcss={ContainerDialogStyles}>
					<PopupContentWrapper>
						{list.map(({ key, ...item }) => (
							<Fragment key={key}>
								<ItemComponent {...item} />
							</Fragment>
						))}
					</PopupContentWrapper>
				</Box>
			)}
			<ShortcutScope />
		</>
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom"
			content={content}
			testId="list-with-popup.ui.show-more-popup"
			trigger={(triggerProps) => (
				<Tooltip key="triggerLinksDialog" content={showMoreTooltip} tag={TooltipContainer}>
					<Button {...triggerProps} isSelected={isOpen} onClick={onClickTrigger} spacing="none">
						<ItemsCount>+{list.length}</ItemsCount>
					</Button>
				</Tooltip>
			)}
		/>
	);
};

const TooltipContainer: TooltipProps['tag'] = forwardRef((props, ref) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Box {...props} ref={ref} as="span" xcss={tooltipContainerStyles} />
));

const ContainerDialogStyles = xcss({
	width: '240px',
	maxHeight: '220px',
	overflow: 'auto',
	padding: 'space.100',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	border: `2px solid ${token('color.border.focused', colors.B200)}`,
});

const tooltipContainerStyles = xcss({
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemsCount = styled.span({
	fontWeight: 'normal',
	lineHeight: token('space.250', '20px'),
	paddingInline: token('space.050', '4px'),
});

export default ShowMorePopup;
