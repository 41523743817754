import { FieldValueLookupError } from '@atlassian/jira-issue-adjustments/src/common/errors/field-value-lookup-error.tsx';
import type {
	LookupUsers,
	LookupUser,
} from '@atlassian/jira-issue-adjustments/src/common/types/lookup.tsx';
import type { MultiUserPickerPublicGetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/multi-user-picker.tsx';
import { getValueLookupValuesDiff } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/utils/get-value-lookup-values-diff/index.tsx';
import { multiValueLookup } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/utils/multi-value-lookup/index.tsx';

type InternalValue = {
	accountId: string;
	displayName: string;
	avatarUrls?: {
		['24x24']?: string;
		[key: string]: string | undefined;
	};
};

export type MultiUserPickerPublicSetValue = string[];

export type MultiUserPickerInternalValue = InternalValue[];

export const transformMultiUserPickerSetValue = (
	values: MultiUserPickerPublicSetValue,
	lookupValues?: LookupUsers,
): MultiUserPickerInternalValue => {
	if (!values.length) {
		return [];
	}

	const filteredLookupValues = multiValueLookup<LookupUser>(values, lookupValues, 'accountId');

	if (values.length !== filteredLookupValues.length) {
		const notFoundValues = getValueLookupValuesDiff(values, filteredLookupValues, 'accountId');

		throw new FieldValueLookupError(notFoundValues.join());
	}

	return filteredLookupValues.map(({ accountId, name, picture }) => ({
		accountId,
		displayName: name,
		avatarUrls: {
			'24x24': picture,
		},
	}));
};

export const transformMultiUserPickerGetValue = (
	value: MultiUserPickerInternalValue,
): MultiUserPickerPublicGetValue => {
	if (!value) {
		return [];
	}

	return value.map(({ accountId }) => ({ accountId }));
};
