import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const getModule = () =>
	import(
		/* webpackChunkName: "async-forge-ui-issue-view-extension" */ '@atlassian/jira-forge-ui/src/common/ui/extension/components/issue-view'
	);

export const LazyIssueViewForgeExtension = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getModule().then((module) => module.IssueViewForgeExtension), {
		ssr: false,
	}),
);
