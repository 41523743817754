import React, { Component } from 'react';
import type { Store } from 'redux';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import AppStyle from '@atlassian/jira-common-components-app-style/src/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import AppBase from '@atlassian/jira-issue-view-app-base/src/index.tsx';
import type { keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay$key } from '@atlassian/jira-relay/src/__generated__/keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay.graphql';
import type { view_issueViewWatchers_ActionIcon$key } from '@atlassian/jira-relay/src/__generated__/view_issueViewWatchers_ActionIcon.graphql';
import { toBaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { WatchersProvider } from '../controllers/watchers-context/index.tsx';
import type { State, AppProps } from './model/types.tsx';
import createRootEpic from './ops/index.tsx';
import { initializeAppProps, refreshAppProps } from './state/actions.tsx';
import createStore from './state/index.tsx';
import View from './view/index.tsx';
import KeyboardShortcuts, { KeyboardShortcutsOld } from './view/keyboard-shortcuts/index.tsx';

export type Props = AppProps & {
	watches?:
		| view_issueViewWatchers_ActionIcon$key
		| keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay$key;
}; // TODO remove optional on watches on relay-migration-issue-fields-watchers_iqhn4 cleanup
// eslint-disable-next-line jira/react/no-class-components
export default class Watchers extends Component<Props> {
	static defaultProps = {
		permissions: {
			canViewWatchers: false,
			canManageWatchers: false,
		},
		onShowFlag: noop,
	};

	constructor(props: Props) {
		super(props);

		const { onShowFlag, locale: localeFromProps, accountId, ...otherProps } = this.props;

		this.store = createStore(createRootEpic({ onShowFlag }));

		this.store.dispatch(
			initializeAppProps({
				...otherProps,
				tenantContext: {
					baseUrl: toBaseUrl(''),
					locale: localeFromProps,
				},
				watchersList: {
					users: [],
					isLoading: false,
				},
			}),
		);
	}

	componentDidUpdate(prevProps: AppProps) {
		const { onShowFlag, locale, accountId, ...otherProps } = this.props;

		const issueUnchanged = this.props.issueKey === prevProps.issueKey;
		const watchersCountUnchanged = this.props.watchersCount === prevProps.watchersCount;
		const loggedInUserUnchanged = isEqual(
			this.props.loggedInUserDetails,
			prevProps.loggedInUserDetails,
		);

		if (watchersCountUnchanged && issueUnchanged && loggedInUserUnchanged) {
			return;
		}

		this.store.dispatch(
			refreshAppProps({
				...otherProps,
			}),
		);
	}

	store: Store<State>;

	render() {
		const { accountId } = this.props;
		if (!accountId) {
			return null;
		}

		return (
			<AppBase store={this.store}>
				<AppStyle>
					{ff('relay-migration-issue-fields-watchers_iqhn4') ? (
						<WatchersProvider store={{ onShowFlag: this.props.onShowFlag }}>
							{this.props.isKeyboardShortcutEnabled ? (
								<KeyboardShortcuts
									watches={
										// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
										this.props
											.watches as keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay$key
									}
								/>
							) : null}
							<View watches={this.props.watches} />
						</WatchersProvider>
					) : (
						<>
							{this.props.isKeyboardShortcutEnabled ? <KeyboardShortcutsOld /> : null}
							<View />
						</>
					)}
				</AppStyle>
			</AppBase>
		);
	}
}
