import { ff } from '@atlassian/jira-feature-flagging';
import type { Worklog } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import {
	NEWEST_FIRST,
	OLDEST_FIRST,
} from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import type { FetchMoreWorklogs } from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
import { convertAdfStringToObject } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';

export const sortToOrderByMap = {
	[NEWEST_FIRST]: '-started',
	[OLDEST_FIRST]: 'started',
} as const;

export const sortToOrderByCreatedMap = {
	[NEWEST_FIRST]: '-created',
	[OLDEST_FIRST]: 'created',
} as const;

type GiraWorklog = Worklog & {
	created: number | null;
	comment: string | null;
};
type FetchWorklogsData = {
	viewIssue: {
		worklogs: {
			nodes: GiraWorklog[];
			totalCount: number;
			startIndex: number | null;
		};
	};
};

export const transformData = (data: FetchWorklogsData): FetchMoreWorklogs => {
	const { totalCount, nodes, startIndex } = data.viewIssue.worklogs;

	const worklogs: Worklog[] = nodes.map(
		({ updateAuthor = null, updated, comment, created, ...rest }) => {
			const wasUpdated = updated !== created;
			return {
				...rest,
				...(ff('issue-view-worklog-timestamp-fix_8d0dt') ? { created } : {}),
				updated: wasUpdated ? updated : null,
				updateAuthor: wasUpdated && updateAuthor ? updateAuthor : null,
				comment: convertAdfStringToObject(comment),
			};
		},
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const worklogsMap = worklogs.reduce<Record<string, any>>(
		(acc, worklog) => ({
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			...acc,
			[worklog.id]: worklog,
		}),
		{},
	);

	const sortedWorklogIds = worklogs.map((worklog) => worklog.id);

	return {
		sortedWorklogIds,
		totalWorklogs: totalCount,
		worklogs: worklogsMap,
		startIndex: startIndex || 0,
	};
};
