// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	switchToModal: {
		id: 'issue.switch-to-modal',
		defaultMessage: 'Open issues in dialog',
		description: 'Label for the issue actions item to open issues in a dialog',
	},
	disabledTooltip: {
		id: 'issue.disabled-tooltip',
		defaultMessage: 'You can’t open issues in dialog when creating a new issue',
		description:
			'Tooltip message when the global issue create modal is opened and the menu item is disabled',
	},
});
