/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { Schema } from '@atlaskit/editor-prosemirror/model';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import VisuallyHidden from '@atlaskit/visually-hidden';

import type { EditorAgent } from '../../../utils/agents';
import { AI_MODAL_SCREEN_CLASS } from '../../../utils/constants';
import LoadingIcon from '../../assets/Loading';
import { CancelEscButton } from '../../components/CancelEscButton/CancelEscButton';
import { FloatingContainer } from '../../components/FloatingContainer/FloatingContainer';
import { defaultPaddingStyles } from '../../components/FloatingContainer/styles';
import { Footer } from '../../components/Footer/Footer';
import { FooterOld } from '../../components/Footer/FooterOld';
import { type Disclaimer } from '../../components/Footer/types';
import { LatestPromptHeader } from '../../components/LatestPromptHeader/LatestPromptHeader';
import { PoweredByRovo } from '../../components/PoweredByRovo/PoweredByRovo';
import { layoutBorderStyles } from '../../styles/renderer';

import messages from './messages';
import { layoutStyles, logoStyles, previewContainer, titleStyles } from './styles';

/**
 * Could be either:
 * - userInput only (free form generate / interrogate)
 * - presetTitle only (summarize)
 * - both presetTitle and userInput (brainstorm)
 */
type LatestPrompt =
	| {
			presetTitle?: never;
			userInput: string;
			userADFInput?: JSONDocNode;
	  }
	| {
			presetTitle: string;
			userInput?: string;
			userADFInput?: JSONDocNode;
	  };

type Props = {
	/**
	 * Label for the parent if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	parentPresetPromptLabel?: string;
	/**
	 * Short title for the submenu if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	childShortTitle?: string;
	/**
	 * Label for the selected partial prompt.
	 */
	presetPromptLabel?: string;
	title: string;
	latestPrompt?: LatestPrompt;
	/**
	 * Pass undefined when there is no previewable content
	 */
	preview: React.ReactElement | undefined;
	providerFactory: ProviderFactory;
	schema: Schema;
	onCancel: () => void;
	agent?: EditorAgent;
	disclaimer?: Disclaimer;
	additionalFooterText?: string;
};

export const LoadingScreen = ({
	parentPresetPromptLabel,
	childShortTitle,
	presetPromptLabel,
	title,
	latestPrompt,
	preview,
	providerFactory,
	schema,
	onCancel,
	agent,
	disclaimer,
	additionalFooterText,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<FloatingContainer
			data-testid="loading-screen"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={`loading-screen ${AI_MODAL_SCREEN_CLASS}`}
			rainbowBorderAnimation={true}
			disablePadding
		>
			<VisuallyHidden>{formatMessage(messages.loadingAriaTitle)}</VisuallyHidden>
			{/**
			 * we will be passing userADFInput here,
			 *  when LatestPromptHeader starts using Renderer.
			 * Will be done in https://product-fabric.atlassian.net/browse/EDF-367
			 */}
			<LatestPromptHeader
				parentPresetPromptLabel={parentPresetPromptLabel}
				childShortTitle={childShortTitle}
				prompt={latestPrompt?.userInput}
				promptADF={latestPrompt?.userADFInput}
				presetTitle={latestPrompt?.presetTitle}
				providerFactory={providerFactory}
				schema={schema}
				agent={agent}
				showBack={true}
			/>
			{
				// When there is no preview to display -- skip setting up a live region.
				// TODO AIFOLLOWUP
				// Should this be setup before content is streamed in?
			}
			{preview && (
				/*
				 * We use a live region so that content updates will be announced to screenreaders. We set:
				 * - aria-atomic="false": because if aria-atomic were set to true, the screen reader would keep
				 * reannouncing the content from the beginning rather than providing updates only for the newly added content.
				 * - aria-live="polite": to ensure that updates are announced by screen readers in a non-disruptive manner,
				 * allowing the user to finish their current task before the new content is communicated.
				 *
				 * We also add a visually hidden description for the live region ("Live updating content:") so that
				 * there is at least some minimal context presented to the user about the incoming updates.
				 */
				<div
					data-testid="adf-content-live-region"
					aria-atomic="false"
					aria-live="polite"
					/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */
					css={[
						previewContainer,
						defaultPaddingStyles,
						editorExperiment('platform_editor_ai_ai_button_block_elements', 'test') &&
							layoutBorderStyles,
					]}
					/* eslint-enable */
				>
					<VisuallyHidden testId="description">
						{formatMessage(messages.liveRegionDescription)}
					</VisuallyHidden>
					{preview}
				</div>
			)}
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={[defaultPaddingStyles, layoutStyles]}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
				<div data-testid="loading-icon" css={logoStyles}>
					<LoadingIcon alt={formatMessage(messages.iconAltText)} />
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
				<div css={titleStyles}>{title}</div>
				<CancelEscButton autoFocus onCancel={onCancel} />
			</div>
			{editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') ? (
				<Footer
					brand={agent ? 'rovo' : 'atlassian-intelligence'}
					disclaimer={disclaimer}
					showDisclaimer={true}
				/>
			) : (
				<FooterOld
					disclaimer={disclaimer}
					additionalFooterText={additionalFooterText}
					footerRightContent={agent ? <PoweredByRovo /> : null}
				/>
			)}
		</FloatingContainer>
	);
};
