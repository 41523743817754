import parameters from '@atlassian/jira-relay/src/__generated__/ui_DesignBackfillCardQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const designBackfillCardEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-design-backfill-card" */ './src/ui'),
	),
	getPreloadProps: ({ cloudId, providerId }: { cloudId: string; providerId: string }) => ({
		queries: {
			designBackfillCard: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: {
					cloudId,
					providerId,
				},
			},
		},
	}),
});
