import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { Collection } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import { getJQLForCollection } from '../../../common/utils/jql.tsx';
import {
	createCollection as createCollectionRequest,
	fetchCollection,
	fetchAllCollections,
	deleteCollection as deleteCollectionRequest,
	updateCollection as updateCollectionRequest,
	cloneCollection,
} from '../../../services/collections/index.tsx';
import type { State } from '../types.tsx';

export type CollectionToCreate = {
	name: string;
	emoji?: string;
	color?: string;
	projectKeys: string[];
	filters: unknown;
	ownerId: string;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
};

export const createCollection =
	({
		name,
		projectKeys,
		color,
		emoji,
		filters,
		ownerId,
		createAnalyticsEvent,
	}: CollectionToCreate): Action<State, undefined, Promise<Collection | undefined>> =>
	async ({ setState, getState }) => {
		try {
			return await wrapWithExperience(experience.collections.createCollection, async () => {
				const jql = getJQLForCollection(projectKeys, filters);

				const newCollection = await createCollectionRequest({
					name,
					color,
					emoji,
					jql,
				});

				const state = getState();

				setState({
					collections: {
						...state.collections,
						[newCollection.uuid]: {
							data: {
								...newCollection,
								ownerId,
							},
						},
					},
				});

				const actionSubjectAndAction = 'collection created';
				fireTrackAnalytics(createAnalyticsEvent({}), actionSubjectAndAction);

				sendPendoTrackEvent({ actionSubjectAndAction });

				return newCollection;
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(createErrorAnalytics('polaris.error.createCollectionFailed', error));
				throw error;
			}
		}
	};

export const initialize =
	(collections: Collection[]): Action<State> =>
	({ setState }) => {
		setState({
			collections: collections.reduce<State['collections']>((acc, collection) => {
				acc[collection.uuid] = { data: collection };
				return acc;
			}, {}),
			isLoading: false,
			error: undefined,
		});
	};

export const loadCollection =
	(collectionUUID: string, shouldIncludeViews?: boolean): Action<State, void, Promise<void>> =>
	async ({ setState, getState }) => {
		const state = getState();
		const existingCollection = state.collections[collectionUUID] || {
			data: undefined,
		};

		if (existingCollection.isLoading || existingCollection.data?.views !== undefined) {
			return;
		}

		const loadExperience = shouldIncludeViews
			? experience.collections.loadCollectionViews
			: experience.collections.loadCollection;

		try {
			await wrapWithExperience(loadExperience, async () => {
				setState({
					collections: {
						...state.collections,
						[collectionUUID]: {
							...existingCollection,
							isLoading: true,
							error: undefined,
						},
					},
				});

				const newCollection = await fetchCollection(collectionUUID, shouldIncludeViews);

				setState({
					collections: {
						...state.collections,
						[collectionUUID]: {
							data: {
								...existingCollection.data,
								...newCollection,
							},
							isLoading: false,
						},
					},
				});
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(createErrorAnalytics('polaris.error.collectionLoadingFailed', error));
				setState({
					collections: {
						...state.collections,
						[collectionUUID]: {
							...existingCollection,
							isLoading: false,
							error,
						},
					},
				});
			}
		}
	};

export const loadCollections =
	(): Action<State, void, Promise<Collection[] | undefined>> =>
	async ({ setState, dispatch }) => {
		try {
			return await wrapWithExperience(experience.collections.loadCollections, async () => {
				setState({
					collections: {},
					isLoading: true,
				});

				const collections = await fetchAllCollections();

				dispatch(initialize(collections));
				return collections;
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.allCollectionsLoadingFailed', error),
				);
				setState({
					isLoading: false,
					error,
				});
			}
		}
		return undefined;
	};

export const deleteCollection =
	(collectionUUID: string): Action<State> =>
	async ({ setState, getState }) => {
		const state = getState();

		try {
			await wrapWithExperience(experience.collections.deleteCollection, async () => {
				const { [collectionUUID]: _, ...collections } = state.collections;
				await deleteCollectionRequest(collectionUUID);

				setState({
					collections,
				});
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(createErrorAnalytics('polaris.error.collectionDeletionFailed', error));
			}
			throw error;
		}
	};

export const updateCollection =
	(collectionUUID: string, collection: Partial<CollectionToCreate>): Action<State> =>
	async ({ setState, getState }) => {
		const state = getState();
		const existingCollection = state.collections[collectionUUID];
		const data = existingCollection?.data;

		if (!data) {
			return;
		}

		try {
			await wrapWithExperience(experience.collections.updateCollection, async () => {
				const { name, color, emoji, projectKeys, filters } = collection;
				const updatedCollection = {
					name: name || data.name,
					color: 'color' in collection ? color : data.color,
					emoji: 'emoji' in collection ? emoji : data.emoji,
					jql: projectKeys ? getJQLForCollection(projectKeys, filters) : data.jql,
				};

				await updateCollectionRequest(collectionUUID, updatedCollection);

				setState({
					collections: {
						...state.collections,
						[collectionUUID]: {
							...existingCollection,
							data: {
								...data,
								...updatedCollection,
							},
						},
					},
				});
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(createErrorAnalytics('polaris.error.collectionUpdateFailed', error));
			}
			throw error;
		}
	};

export const copyCollection =
	(
		collectionUUID: string,
		newCollectionName: string,
		newCollectionOwnerId: string | null,
	): Action<State, undefined, Promise<string | undefined>> =>
	async ({ setState, getState }) => {
		try {
			const state = getState();
			const collectionToCopy = state.collections[collectionUUID]?.data;

			if (!collectionToCopy || !newCollectionOwnerId) {
				return;
			}

			return await wrapWithExperience(experience.collections.copyCollection, async () => {
				const { uuid } = await cloneCollection(collectionUUID, newCollectionName);

				setState({
					collections: {
						...state.collections,
						[uuid]: {
							isLoading: false,
							error: undefined,
							data: {
								...collectionToCopy,
								name: newCollectionName,
								ownerId: newCollectionOwnerId,
								uuid,
							},
						},
					},
				});
				return uuid;
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(createErrorAnalytics('polaris.error.copyCollectionFailed', error));
				throw error;
			}
		}
	};
