import componentsCache from '@atlassian/jira-cache/src/services/components/index.tsx';
import { toQuotedString, createIssuesUrl } from '@atlassian/jira-common-jql/src/helpers.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { TransformerContext } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';
import type {
	ServerSuggestions,
	TransformedServerSuggestions,
	CacheSuggestions,
	TransformedCacheSuggestions,
} from './types.tsx';

type TransformedValue = {
	href: string;
	content: string;
	value: string;
	fromCache?: boolean;
	ari?: string;
	metadata?: { typeId: string };
};

export type StateValue = {
	name: string;
	id: string;
	fromCache?: boolean;
	ari?: string;
	metadata?: { typeId: string };
};

export const transformFromStateValue = (
	value?: StateValue[],
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	{ projectKey }: TransformerContext,
): TransformedValue[] => {
	if (!value || value.length === 0) {
		return [];
	}
	return value.map((item) => {
		const jql = `project = ${toQuotedString(projectKey)} AND component = ${toQuotedString(
			item.name,
		)}`;
		const href = createIssuesUrl(jql);
		return {
			href,
			content: item.name,
			value: item.id,
			...(ff('compass-components-in-jira-components_xw42u', false)
				? { ari: item.ari, metadata: item.metadata }
				: {}),
		};
	});
};

// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
const transformItem = (item) => ({
	content: item.name,
	value: item.id,
	fromCache: item.fromCache || false,
	...(ff('compass-components-in-jira-components_xw42u', false)
		? { ari: item.ari, metadata: item.metadata }
		: {}),
});

const MAX_RESULTS = 5;

export const validateCacheItems = (
	rest: ServerSuggestions[],
	cache: CacheSuggestions[],
	projectKey: ProjectKey,
): TransformedCacheSuggestions[] =>
	cache
		.filter((item) => item.projectKey === projectKey)
		.map((item) => {
			const duplicatedItem = rest.find((restItem) => restItem.id === item.id);
			if (duplicatedItem) {
				const newItem = { ...duplicatedItem, projectKey };
				componentsCache.update(`${item.id}`, newItem);
				return { ...newItem, fromCache: true };
			}
			return { ...item, fromCache: true };
		});

export const transformCacheEntries = (
	cache: TransformedCacheSuggestions[],
): TransformedCacheSuggestions[] => cache.slice(-1 * MAX_RESULTS).reverse();

export const transformSuggestionsFromServerAndCache = (
	suggestionsFromServer: ServerSuggestions[] = [],
	cache: CacheSuggestions[] = [],
	intl: IntlShape,
	projectKey: ProjectKey,
): TransformedServerSuggestions => {
	const cacheLists = transformCacheEntries(
		validateCacheItems(suggestionsFromServer, cache, projectKey),
	);

	return [
		{
			heading: intl.formatMessage(messages.recent),
			items: cacheLists.map(transformItem),
		},
		{
			heading: intl.formatMessage(messages.all),
			items: suggestionsFromServer.map(transformItem),
		},
	];
};

export const transformToStateValue = (value: TransformedValue[]): StateValue[] =>
	value.map((item) => ({
		name: item.content,
		id: item.value,
		fromCache: item.fromCache || false,
		...(ff('compass-components-in-jira-components_xw42u', false)
			? { ari: item.ari, metadata: item.metadata }
			: {}),
	}));
