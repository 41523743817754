import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Avatar from '@atlaskit/avatar';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { COMMAND_PALETTE_ISSUE_ACTIONS } from '@atlassian/jira-command-palette-common/src/common/constants.tsx';
import {
	type Command,
	type RenderChildrenProps,
	CommandActionType,
	type ChildResult,
} from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import { components } from '@atlassian/jira-command-palette/src/common/ui/components/index.tsx';
import { useDebouncedLoadChildren } from '@atlassian/jira-command-palette/src/controllers/use-debounced-load-children/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { UserOption } from '@atlassian/jira-issue-user-picker/src/types.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { transformToStateValue } from '../../assignee-transformer.tsx';
import { messages } from '../messages.tsx';
import type { CommandPaletteAssigneeListProps, InitialUserOptions } from '../types.tsx';
import { useSaveAssigneeField } from '../use-save-assignee-field/index.tsx';

const logError = (msg: string) =>
	log.safeErrorWithoutCustomerData(
		'issue.issue-view-base.context.assignee.command-palette-assignee.assignee-list-body',
		msg,
	);

export const AssigneeListBody = ({
	prefetchResult,
	initialOptions = [],
	value,
	fetchSuggestions,
	results,
	setChildResult,
	setLoading,
	...bodyProps
}: CommandPaletteAssigneeListProps & RenderChildrenProps) => {
	const prefetchSessionId = prefetchResult?.prefetchSessionId || '';
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();
	const { saveAssigneeField } = useSaveAssigneeField(issueKey);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	/**
	 * Function to load the list of all assignees based on the search string
	 */
	const loadAssigneeList = useCallback(
		async (searchString: string) => {
			let suggestionsResult: readonly (UserOption | null)[];
			try {
				suggestionsResult = await fetchSuggestions(searchString, prefetchSessionId);
			} catch (error) {
				if (fg('thor-issue-view-enable-logging')) {
					logError(`Load Assignee list operation has failed: ${error}`);
				}
				setLoading(false);
				return;
			}

			const initialOptionsActive = initialOptions.map((option) => ({
				...option,
				active: true,
			}));

			// Filter out any suggestions that are in the initial or empty options.
			const suggestionIdsToFilterOut = initialOptionsActive.map((option) => option.id);

			const filterOutCurrentUser = value?.id;

			const suggestions = suggestionsResult.filter(
				(result) =>
					result &&
					!suggestionIdsToFilterOut.includes(result.id) &&
					filterOutCurrentUser !== result.id,
			);

			const fullList: (InitialUserOptions | null)[] = [...initialOptionsActive, ...suggestions];

			if (value) {
				const currentIndex = fullList.findIndex((result) => result?.id === value.id);
				if (currentIndex > -1) fullList.splice(currentIndex, 1);

				if (value?.id) {
					fullList.unshift({ ...value, active: true });
				}
			}

			const final = fullList
				.filter((result): result is UserOption => !!result?.active)
				.map((result): Command => {
					const isUserSelected = value?.id && result.id === value?.id;
					return {
						id: `issue.issue-view.issue-base.assignee.${result.id}`,
						name: result.name,
						keywords: `${result.byline || ''} ${result.displayName || ''}`,
						components: {
							Name: ({ isActive }) => (
								<AssigneeContainer active={isActive}>
									<AssigneeNameFlexBox>
										<AssigneeNameContainer>
											{result.name}
											{result.displayName && result.displayName !== result.name && (
												<span>({result.displayName})</span>
											)}
										</AssigneeNameContainer>
										{isUserSelected && <div>{formatMessage(messages.current)}</div>}
									</AssigneeNameFlexBox>
									<AssigneeByLine active={isActive}>{result.byline}</AssigneeByLine>
								</AssigneeContainer>
							),
							LeftIcon: () => (
								<AvatarContainer>
									<Avatar borderColor="transparent" src={result.avatarUrl} size="xsmall" />
								</AvatarContainer>
							),
						},
						...(!isUserSelected
							? {
									primaryAction: {
										type: CommandActionType.PERFORM,
										perform: () => {
											const user = transformToStateValue(result);
											saveAssigneeField(user, null, createAnalyticsEvent({}));
										},
									},
								}
							: {
									primaryAction: {
										type: CommandActionType.PERFORM,
										perform: noop,
									},
								}),
						analytics: {
							action: 'updateAssignee',
							actionCategory: COMMAND_PALETTE_ISSUE_ACTIONS,
						},
					};
				});

			const finalChildResult: ChildResult = {
				commands: final,
			};

			setChildResult(finalChildResult);
			setLoading(false);
		},
		[
			initialOptions,
			value,
			setChildResult,
			setLoading,
			fetchSuggestions,
			prefetchSessionId,
			formatMessage,
			saveAssigneeField,
			createAnalyticsEvent,
		],
	);

	useDebouncedLoadChildren(loadAssigneeList);

	return (
		<components.Body
			{...bodyProps}
			setChildResult={setChildResult}
			setLoading={setLoading}
			results={results}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AssigneeContainer = styled.div<{ active?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ active }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		active ? token('color.text', colors.N700) : token('color.text.subtle', colors.N400),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AssigneeNameFlexBox = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AssigneeNameContainer = styled.div({
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AssigneeByLine = styled.div<{ active?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ active }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		active ? token('color.text.subtle', colors.N100) : token('color.text.subtlest', colors.N200),
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	lineHeight: 0,
});
