import keys from 'lodash/keys';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import { safeLinkTypeName } from '@atlassian/jira-common-constants/src/issue-link-types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IssueLinks } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	closeAddIssueLink,
	createLinkedIssueSuccess,
	saveLinkedIssueSuccess,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import {
	issueKeySelector,
	issueIdSelector,
	baseUrlSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import CreateLinkedIssueButtonViewNew from './view-new.tsx';
import CreateLinkedIssueButtonViewOld from './view.tsx';

const CreateLinkedIssueButtonView = componentWithFG(
	'create_linked_issues_trigger_point_issue_view',
	CreateLinkedIssueButtonViewNew,
	CreateLinkedIssueButtonViewOld,
);

const linkedIssueCreatedAnalyticsAction = 'created';

const fireLinkedIssueCreatedTrackAnalytics = (
	{ issueLinks, linkedIssues }: IssueLinks,
	analyticsEvent: UIAnalyticsEvent,
) => {
	const issueLinkId = keys(issueLinks)[0];
	// issueLinkId would be undefined when the newly created linked issue is not actually linked to the current
	// issue.
	if (issueLinkId !== undefined) {
		const issueLink = issueLinks[issueLinkId];
		const linkedIssue = linkedIssues[issueLink.linkedIssueKey];
		const analyticsAttributes = {
			action: linkedIssueCreatedAnalyticsAction,
			actionSubjectId: linkedIssue.id,
			attributes: {
				linkTypeName: safeLinkTypeName(issueLink.typeName),
				linkTypeId: issueLink.typeId,
				issueLinkId,
			},
		};
		fireTrackAnalytics(analyticsEvent, analyticsAttributes);
	}
};

export default flowWithSafeComponent(
	injectIntl,
	ComponentWithAnalytics('createLinkedIssueButton', {
		onLinkedIssueCreated: linkedIssueCreatedAnalyticsAction,
	}),
	connect(
		(state: State) => ({
			baseUrl: baseUrlSelector(state),
			sourceIssueKey: issueKeySelector(state),
			sourceIssueId: toIssueId(String(issueIdSelector(state))),
			projectType: projectTypeSelector(state),
		}),
		(dispatch) => ({
			onLinkedIssueCreated: (
				newIssueLinks: IssueLinks,
				analyticOrMaybePostAnalytic: UIAnalyticsEvent,
				analyticsEvent?: UIAnalyticsEvent,
			) => {
				const handleLinkedIssueCreated = () => {
					dispatch(createLinkedIssueSuccess(newIssueLinks));
					if (ff('corex-operandi-issue-view-additional-logging_l6hj8')) {
						Object.entries(newIssueLinks.issueLinks).forEach(([optimisticId, issueLink]) => {
							dispatch(
								saveLinkedIssueSuccess(
									optimisticId,
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									analyticsEvent!,
									issueLink.linkedIssueKey,
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									analyticOrMaybePostAnalytic!,
								),
							);
						});

						dispatch(closeAddIssueLink());
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						fireLinkedIssueCreatedTrackAnalytics(newIssueLinks, analyticsEvent!);
					} else {
						Object.keys(newIssueLinks.issueLinks).forEach((optimisticId) => {
							dispatch(saveLinkedIssueSuccess(optimisticId, analyticOrMaybePostAnalytic));
						});

						dispatch(closeAddIssueLink());
						fireLinkedIssueCreatedTrackAnalytics(newIssueLinks, analyticOrMaybePostAnalytic);
					}
				};

				/**
				 * Any error thrown in this callback will block the create linked issue dialog from closing.
				 * We need to catch the error and log it to avoid blocking the dialog from closing.
				 */
				if (fg('legacy_create_linked_issue_callback_error_handling')) {
					try {
						handleLinkedIssueCreated();
					} catch (error) {
						fireErrorAnalytics({
							meta: {
								id: 'create-linked-issue-button',
								packageName: 'jira-issue-view-base',
								teamName: 'gryffindor',
							},
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							error: error as Error,
							sendToPrivacyUnsafeSplunk: true,
						});
					}
				} else {
					handleLinkedIssueCreated();
				}
			},
		}),
	),
)(
	// @ts-expect-error - Argument of type '(props: Props) => React.JSX.Element' is not assignable to parameter of type 'ComponentType<{ intl: IntlShapeV2 | IntlShape | undefined; }>'.
	CreateLinkedIssueButtonView,
);
