/**
 * @generated SignedSource<<52a9687fb608298c7bdea0877306f876>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueDesignSection_DesignPanelWrapper_issueViewRelayFragment$data = {
  readonly attachedDesignUrlV1: AGG$JSON | null | undefined;
  readonly attachedDesignUrlV2: AGG$JSON | null | undefined;
  readonly designs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "DevOpsDesign";
        readonly url: AGG$URL | null | undefined;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly issueId: string;
  readonly " $fragmentSpreads": FragmentRefs<"panel_issueDesignSection_DesignPanel_issueViewRelayFragment">;
  readonly " $fragmentType": "ui_issueDesignSection_DesignPanelWrapper_issueViewRelayFragment";
};
export type ui_issueDesignSection_DesignPanelWrapper_issueViewRelayFragment$key = {
  readonly " $data"?: ui_issueDesignSection_DesignPanelWrapper_issueViewRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueDesignSection_DesignPanelWrapper_issueViewRelayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueDesignSection_DesignPanelWrapper_issueViewRelayFragment",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "panel_issueDesignSection_DesignPanel_issueViewRelayFragment"
    },
    {
      "kind": "ScalarField",
      "name": "issueId"
    },
    {
      "alias": "attachedDesignUrlV1",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "attached-design-url"
        }
      ],
      "kind": "ScalarField",
      "name": "issuePropertyByKey",
      "storageKey": "issuePropertyByKey(key:\"attached-design-url\")"
    },
    {
      "alias": "attachedDesignUrlV2",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "attached-design-url-v2"
        }
      ],
      "kind": "ScalarField",
      "name": "issuePropertyByKey",
      "storageKey": "issuePropertyByKey(key:\"attached-design-url-v2\")"
    },
    {
      "concreteType": "GraphStoreSimplifiedIssueAssociatedDesignConnection",
      "kind": "LinkedField",
      "name": "designs",
      "plural": false,
      "selections": [
        {
          "concreteType": "GraphStoreSimplifiedIssueAssociatedDesignEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "url"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "__typename"
                    }
                  ],
                  "type": "DevOpsDesign"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "dd1bafb63b57ea0d917c400c78875f15";

export default node;
