import React, { forwardRef } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { B100, N20A, R500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { EditButtonHidden } from '@atlassian/jira-inline-edit-lite/src/ui/edit-button-hidden/index.tsx';
import { EditButton } from '@atlassian/jira-inline-edit-lite/src/ui/edit-button/index.tsx';
import type { Props } from './types.tsx';

/**
 * This component is designed to be rendered within the `readView` of a `FieldInlineEditLite` component. This can be
 * rendered in a 'read-only' layout by omitting the `context` prop which is provided by `useInlineEdit` in
 * `@atlassian/jira-inline-edit-lite`. This ensures consistent spacing for consumers when a field is conditionally
 * editable.
 */
const InlineEditReadViewContainer = forwardRef<HTMLDivElement, Props>(
	(
		{
			children,
			context,
			editButtonLabel,
			editButtonRef,
			hideEditButton = true,
			isEditButtonFocused = false,
			isLoading = false,
			isInvalid = false,
			onClick,
			onMouseDown,
			onEditButtonBlur,
			onEditButtonFocus,
			readViewFitContainerHeight = false,
		},
		ref,
	) => {
		const isHiddenButtonFocused = hideEditButton && isEditButtonFocused;
		const isFocusRingVisible = isHiddenButtonFocused || isLoading;

		return (
			<Container
				data-testid="issue-field-inline-edit-read-view-container.ui.container"
				readViewFitContainerHeight={readViewFitContainerHeight}
				hideEditButton={hideEditButton}
				isEditable={!!context}
				isFocusRingVisible={isFocusRingVisible}
				isInvalid={isInvalid}
				onClick={onClick}
				onMouseDown={onMouseDown}
				role="presentation"
				ref={ref}
			>
				{hideEditButton && context && (
					<EditButtonHidden
						ref={editButtonRef}
						context={context}
						label={editButtonLabel}
						onBlur={onEditButtonBlur}
						onFocus={onEditButtonFocus}
					/>
				)}
				{isLoading ? (
					<>
						<LoadingOverlay>{children}</LoadingOverlay>
						<SpinnerContainer>
							<Spinner />
						</SpinnerContainer>
					</>
				) : (
					children
				)}
				{!hideEditButton && context && (
					<EditButton
						ref={editButtonRef}
						context={context}
						label={editButtonLabel}
						onBlur={onEditButtonBlur}
						onFocus={onEditButtonFocus}
						// In future providing CSS variables *may* be something that makes its way into the design system, but
						// for now this is an acceptable override to prevent managing additional state with JS.
						// More context here https://atlassian.slack.com/archives/C060GTVV988/p1703214250865779?thread_ts=1703212675.166419&cid=C060GTVV988
						/* eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
						style={editButtonStyles}
					/>
				)}
			</Container>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	readViewFitContainerHeight: boolean;
	hideEditButton: boolean;
	isEditable: boolean;
	isFocusRingVisible: boolean;
	isInvalid: boolean;
}>(
	{
		borderWidth: '2px',
		borderStyle: 'solid',
		borderRadius: token('border.radius', '3px'),
		padding: `0 ${token('space.075', '6px')}`,
		display: 'flex',
		gap: token('space.100', '8px'),
		alignItems: 'center',
		boxSizing: 'border-box',
		maxWidth: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		minHeight: (props) => (props.readViewFitContainerHeight ? '100%' : '32px'),
		position: 'relative',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		!props.hideEditButton &&
		!props.isEditable && {
			paddingRight: `calc(${token('space.400', '32px')} + ${token('space.075', '6px')})`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.isEditable && {
			transition: 'backgroundColor 0.2s',
			'&:hover': {
				backgroundColor: token('color.background.neutral.subtle.hovered', N20A),
			},
			'--edit-button-opacity': 0,
			'&:hover,&:focus-within': {
				'--edit-button-opacity': 1,
			},
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.isFocusRingVisible && {
			borderColor: token('color.border.focused', B100),
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.isInvalid &&
		!props.isFocusRingVisible && {
			borderColor: token('color.border.danger', R500),
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		!props.isInvalid &&
		!props.isFocusRingVisible && {
			borderColor: 'transparent',
		},
);

const editButtonStyles = {
	flexShrink: 0,
	marginLeft: 'auto',
	opacity: 'var(--edit-button-opacity, 1)',
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingOverlay = styled.div({
	pointerEvents: 'none',
	opacity: token('opacity.loading', '0.2'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	position: 'absolute',
	// Space tokens do not make sense for this positioning.
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
});

export default InlineEditReadViewContainer;
