import { messages } from './messages.tsx';

export const conflictUimNotificationFlagProperties = {
	message: messages.uimAppsConflictFlagDescription,
	title: messages.uimAppsConflictFlagTitle,
	type: 'warning' as const,
};

export const uimMultipleAppsFlagProperties = {
	message: messages.uimMultipleAppsFlagDescription,
	title: messages.uimMultipleAppsFlagTitle,
	type: 'info' as const,
};
