import { useCallback } from 'react';
import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { LAST_VIEWED_ISSUE_PROPERTY_KEY } from '../constants.tsx';
import type { LastViewedIssuePropertyData } from '../types.tsx';

type Props = {
	issueAri: string;
};

export const useSetLastViewedIssuePropertyService = ({ issueAri }: Props) => {
	const request = useCallback(
		(value: LastViewedIssuePropertyData) => {
			const issueId = JiraIssueAri.parse(issueAri).resourceId;
			// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
			return performPutRequest<void>(
				`/rest/api/2/issue/${issueId}/properties/${LAST_VIEWED_ISSUE_PROPERTY_KEY}`,
				{ body: JSON.stringify(value) },
			);
		},
		[issueAri],
	);

	const { loading, error, fetch } = useService(request, { loading: true });

	return {
		loading,
		error,
		fetch,
	};
};
