import { createMachine } from 'xstate';

// ! TODO: When xstate v5+ is adopted remove the ts-expect-error
// This will swallow ts-errors, take care
// @ts-expect-error(TS2769) "No overload matches this call"
// This occurs because createMachine's options input does not include state.description
// However the state.description field is useful for the visual editor
// Tested 2023-07-06 on xstate@5.0.0-beta.15 and this problem will be resolved
export const editorPluginAIMachine = createMachine({
	/** @xstate-layout N4IgpgJg5mDOIC5QFEIEsAuB7ATgBQBsBXKNAOwEEBJAYgGUAVCgJQYH1q3kANPZZqsgByAYWQBtAAwBdRKAAOWWJjRYyckAA9EAWgCMkgKwAOAHQBmAEzHJAFmOWrtgGx7LAGhABPXc9OWATlsAdj1zY2cAy2dDMOMAX3jPVExcQhJyalNqAAIAWSwIAEMCHKKAYww0ADcwGk4RAHkhBmF2KiE6flaAESlZJBBFZSq1DW0EHXtg0z1bEODDcwDJZ0tDTx8EPWMzG0t14xMdw0MYxOT0bHxiUkoqbKp8wpKyypq6vMaeigAZNh6VDoIhYPWQfRkGmGKjGgwmOmCtkM-ii6xMKyRRk2uj0zhmlkkK0swRW5nCkiMFxAKWu6TuWVyBWKpQqVVqNC+P3+HQAan8qD9ev0oUoYeo4bpzCZTMFzLY9EtjMFic5drZsZM9FFZrtLEjlUjzJFnFSaWlbpkHrkRGoMGAyBgcgAJNBQAAWBFdbrtEHoyF+yBEDCozTYIidFCEAHFwcLBtDRuLQBN7KYzs41qtJNYAgEMxr9HoZWtbOZJFLVnLYsFEkkQGRCnANGabhl7iKRqok1ocQY-FYbPZHHrXB5vL5s6Y8UFgs5bI4DAk6y26ZbTEIsDlcmBNPIwDg0PbymA3mywB2xeNfLj-MFFkrjCs1htx5Nln49CSXEsAm5c7ZTSuc02wZJ4mVeVkPgvRMr0mVw-GJe9gkfVZ1g1ZxzBlAJFkMQIkSiZxJBrZcgNbekrSeG0HXtR0XXdT13R9aCu1gnR4NvWcwnlAlcT0DVoksUwHEkT9Vi1O9sPMWt4iAA */
	id: 'EditorPluginAI',

	description: `This machine models the high level functionality of the EditorPluginAI.

Currently this logic is split across prosemirror, xstate, and elsewhere.

The intention is to migrate to a single source of truth, backed by a state machine.`,

	states: {
		'No AI experience active': {
			description: `When an Editor is first started up with an editor-plugin-ai EditorPlugin - it will start in this state.

This state will be returned to when an ai experience is either discarded or completed.`,
		},

		'AI Modal active': {
			on: {
				AI_CONTENT_INSERTED: 'AI Content Highlighted',
				MODAL_DISCARDED: {
					target: 'No AI experience active',
					description: `This transition is intended to model when a user makes an explicit decision to clear the modal.`,
				},
				MODAL_INVALIDATED: {
					target: 'No AI experience active',

					description: `This transition is intended to model when an AI experience is ended due to the modal no longer being valid.

For example, if it's been linked to a selection, and another user collaborating on the document deletes the selected content.

In future we might expect this to go to an alternate state.`,
				},
			},

			description: `Currently there is a seperate xstate state machine which is run when the ai modal is active.
Over time we can expect to link these state machines.`,
		},

		'AI Content Highlighted': {
			on: {
				SELECTION_CHANGED: {
					target: 'No AI experience active',
					description: `This transition models when a user interacts with a document following the insertion of content via AI.`,
				},
			},

			description: `Following content being inserted, the inserted content is highlighted so the user is able to understand the impact of inserting the generated content into the document.

This highlight is persisted either until the user changes makes a selection change in the document or the content is removed from the document (ie. via collab editing sessions)`,
		},
	},

	on: {
		START_AI_EXPERIENCE: {
			target: 'AI Modal active',

			description: `We expect AI Experiences to be started in a variety of ways including;
- via the slash command/typeahead
- via the toolbar quick insert menu
- via the toolbar ai button
- via external actions (ie. atlas have a button outside the editor which starts an ai experience)

Only one AI experience can be active at a single time in an Editor.  If another AI experience is active when the user starts a new AI experience, the prior experience is replaced/reset with the new experience details.`,
		},
	},

	initial: 'No AI experience active',
});
