/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxFrag */
import { type ReactNode } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import type { DocNode } from '@atlaskit/adf-schema';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import { ReactRenderer } from '@atlaskit/renderer';
import { RendererActionsContext } from '@atlaskit/renderer/actions';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { token } from '@atlaskit/tokens';

const rendererContainerStyles = css({
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'.ak-renderer-document': {
		// We add 4px to avoid smart link bottom borders being visually clipped on the first line
		maxHeight: `calc(${token('space.250', '20px')} + ${token('space.050', '4px')})`,
		marginRight: token('space.100', '8px'),
		flexGrow: 1,
		flexShrink: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		p: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			hyphens: 'auto',
			wordWrap: 'break-word',
		},
		// This is a workaround for when the renderer has multiple paragraphs
		// which can be achieved by pasting in the input field. This will add
		// an ellipsis to the first paragraph.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'p:not(:only-child)::after': {
			content: '"..."',
		},
		// The content can have multiple lines separated by <br> tags if the user
		// does shift+enter in the input field. This wraps the content onto one
		// line to allow the parent element to add the ellipsis.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors, @atlaskit/ui-styling-standard/no-nested-selectors
		'p br': {
			content: '""',
			'&::after': {
				content: '" "',
			},
		},

		// Override smart links line original line wrapping behaviour to allow our
		// truncation CSS logic to trigger when part of multilined prompt content.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'span[data-inline-card] [data-testid="inline-card-icon-and-title"]': {
			whiteSpace: 'nowrap',
		},
	},
});

// 'platform_editor_ai_command_palette_post_ga'
const rendererDocumentStyles = css({
	maxHeight: `calc(24px + ${token('space.050', '4px')})`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'.ak-renderer-document': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		p: {
			// We need a tiny bit of padding to avoid inline nodes with borders being visually clipped
			// and wwe need to increase the line height to allow for when inline nodes are focused as
			// they add box-shadow styling of 1px
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
			paddingTop: '0.5px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
			lineHeight: '24px',
		},
	},
});

type Props = {
	promptADF: JSONDocNode;
	providerFactory: ProviderFactory;
};

const RendererWrapper = ({ children }: { children: ReactNode }) => {
	return (
		<div
			css={[
				rendererContainerStyles,
				editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') &&
					rendererDocumentStyles,
			]}
		>
			{children}
		</div>
	);
};

export const LatestPromptRenderer = ({ promptADF, providerFactory }: Props) => {
	const jsonDocument = promptADF as DocNode;

	return (
		<RendererActionsContext>
			<RendererWrapper>
				<ReactRenderer
					document={jsonDocument}
					media={{
						// We don't currently support images with captions
						allowLinking: false,
					}}
					// By default -- the renderer uses a "default schema" which supports all
					// the nodes and marks the markdown transformer can create.
					// see packages/editor/adf-schema/src/schema/default-schema.ts for details.
					useSpecBasedValidator
					dataProviders={providerFactory}
					maxHeight={20}
				/>
			</RendererWrapper>
		</RendererActionsContext>
	);
};
