import { useCallback, useMemo, useRef } from 'react';
import noop from 'lodash/noop';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { CANNED_COMMENTS_KEY } from '../../common/constants.tsx';
import messages from '../../common/messages.tsx';
import { isCannedCommentInContent } from './utils.tsx';

export type ContextValue = {
	hasCannedComment: boolean;
	isExactMatch: boolean;
};

type HookProps = {
	isSmartRepliesEnabled: boolean;
};

const isSmartRepliesExperimentEnabled = () => {
	const [expConfig] = UNSAFE_noExposureExp('smart_replies_m1');
	return expConfig.get('isEnabled', false);
};

export const useCannedCommentAnalytics = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cannedComments = useMemo(
		() =>
			CANNED_COMMENTS_KEY.map((key) =>
				`${key.emoji.text} ${formatMessage(messages[key.commentKey])}`.trim(),
			),
		[formatMessage],
	);
	const timeStartOnEditorRef = useRef<number>(0);

	const startEditing = useCallback(() => {
		timeStartOnEditorRef.current = Date.now();
	}, []);

	const fireAnalyticsForCannedComment = useCallback(
		(doc: ADF | undefined) => {
			const { isExactMatch, hasCannedComment } = isSmartRepliesExperimentEnabled()
				? isCannedCommentInContent(doc, cannedComments, createAnalyticsEvent({}))
				: {
						isExactMatch: undefined,
						hasCannedComment: undefined,
					};

			// Analytics event for this experiment only to help us understand users interactions with canned comments
			fireTrackAnalytics(createAnalyticsEvent({}), 'comment saved', {
				...(timeStartOnEditorRef.current && {
					timeSpentOnEditor: Date.now() - timeStartOnEditorRef.current,
				}),
				hasCannedComment,
				isExactMatch,
			});
		},
		[cannedComments, createAnalyticsEvent, timeStartOnEditorRef],
	);

	return { fireAnalyticsForCannedComment, startEditing };
};

export const useCannedCommentAnalyticsNew = ({ isSmartRepliesEnabled }: HookProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cannedComments = useMemo(
		() =>
			CANNED_COMMENTS_KEY.map((key) =>
				`${key.emoji.text} ${formatMessage(messages[key.commentKey])}`.trim(),
			),
		[formatMessage],
	);
	const timeStartOnEditorRef = useRef<number>(0);

	const startEditing = useCallback(() => {
		timeStartOnEditorRef.current = Date.now();
	}, []);

	const fireAnalyticsForCannedComment = useCallback(
		(doc: ADF | undefined) => {
			const { isExactMatch, hasCannedComment } = isSmartRepliesExperimentEnabled()
				? isCannedCommentInContent(doc, cannedComments, createAnalyticsEvent({}))
				: {
						isExactMatch: undefined,
						hasCannedComment: undefined,
					};

			// Analytics event for this experiment only to help us understand users interactions with canned comments
			fireTrackAnalytics(createAnalyticsEvent({}), 'comment saved', {
				...(timeStartOnEditorRef.current && {
					timeSpentOnEditor: Date.now() - timeStartOnEditorRef.current,
				}),
				hasCannedComment,
				isExactMatch,
			});
		},
		[cannedComments, createAnalyticsEvent, timeStartOnEditorRef],
	);

	if (isSmartRepliesEnabled) {
		return { fireAnalyticsForCannedComment, startEditing };
	}

	return { fireAnalyticsForCannedComment: noop, startEditing: noop };
};
