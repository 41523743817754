import head from 'lodash/head';
import type { WeightType } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type {
	FieldConfiguration,
	FieldKey,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetFieldByKey } from '../../selectors/field.tsx';
import { DEFAULT_OPTION_WEIGHT, type Props, type State } from '../../types.tsx';

export const updateFieldOptionWeightType =
	(fieldKey: FieldKey, newOptionWeightType: WeightType | undefined) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, ideaTypes, createAnalyticsEvent }: Props,
	): Promise<Error | undefined> => {
		const field = createGetFieldByKey(fieldKey)(getState());
		if (!field) {
			return undefined;
		}

		const issueType = head(ideaTypes);
		if (issueType === undefined) {
			return undefined;
		}

		const oldConfiguration: FieldConfiguration | undefined = field.configuration;
		const newConfiguration: FieldConfiguration = {
			...field.configuration,
			optionWeightType: newOptionWeightType,
		};

		const oldOptions = field.options;
		let newOptions = field.options;
		const isWeightingRemoved = newOptionWeightType === undefined;
		if (isWeightingRemoved) {
			newConfiguration.optionWeights = (field.configuration?.optionWeights || []).map((e) => ({
				...e,
				weight: DEFAULT_OPTION_WEIGHT,
			}));
			newOptions = newOptions?.map((optionItem) => ({
				...optionItem,
				weight: DEFAULT_OPTION_WEIGHT,
			}));
		}

		try {
			const fields = { ...getState().fields };
			fields[fieldKey] = {
				...fields[fieldKey],
				configuration: newConfiguration,
				options: newOptions,
			};
			setState({ fields });

			await fieldRemote.updateFieldConfiguration({
				oldConfiguration: oldConfiguration ?? {},
				configuration: newConfiguration,
				fieldKey: field.key,
				issueTypeId: issueType.jiraIssueTypeId,
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'issueFields updated', {
				issueFieldKey: fieldKey,
				issueFieldType: field.type,
				updatedItems: Object.keys(newConfiguration).map((name) => ({ name })),
			});

			return undefined;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			const fields = { ...getState().fields };
			fields[fieldKey] = {
				...fields[fieldKey],
				configuration: oldConfiguration,
				options: oldOptions,
			};
			setState({ fields });

			onFieldUpdateFailed(err);

			return err;
		}
	};
