import React, { type ReactNode, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import Icon, { type IconProps } from '@atlaskit/icon';
import ChangesIcon from '@atlaskit/icon/core/changes';
import PeopleGroupIcon from '@atlaskit/icon/core/people-group';
import OverviewIcon from '@atlaskit/icon/glyph/overview';
import { JiraIcon } from '@atlaskit/logo';
import { token } from '@atlaskit/tokens';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { autosaveOffGlyph, autosaveOnGlyph } from './autosave/index.tsx';
import { boardGlyph } from './board/index.tsx';
import { bulletListGlyph } from './bullet/index.tsx';
import { cardLayoutGlyph } from './card-layout/index.tsx';
import { checkboxGlyph } from './checkbox/index.tsx';
import { chevronRightGlyph } from './chevron-right/index.tsx';
import { chevronUpGlyph } from './chevron-up/index.tsx';
import { collectionGlyph } from './collection/index.tsx';
import { collectionsGlyph } from './collections/index.tsx';
import { commentGlyph } from './comment/index.tsx';
import { datapointGlyph } from './datapoint/index.tsx';
import { dateIntervalGlyph } from './date-interval/index.tsx';
import { dateGlyph } from './date/index.tsx';
import { deliveryTicketsGlyph } from './delivery-tickets/index.tsx';
import { dropdownGlyph } from './dropdown/index.tsx';
import { atlasGoalGlyph, atlasProjectGlyph } from './external-reference/index.tsx';
import { formulaGlyph } from './formula/index.tsx';
import { hiddenGlyph } from './hidden/index.tsx';
import { insightsGlyph } from './insights/index.tsx';
import { issueTypeGlyph } from './issuetype/index.tsx';
import { keyGlyph } from './key/index.tsx';
import { labelGlyph } from './label/index.tsx';
import { likeIconFilledGlyph } from './like-filled/index.tsx';
import { linkGlyph } from './link/index.tsx';
import { matrixGlyph } from './matrix/index.tsx';
import { mergeIdeasGlyph } from './merge-ideas/index.tsx';
import { numberGlyph } from './number/index.tsx';
import { peopleGlyph } from './people/index.tsx';
import { pollGlyph } from './poll/index.tsx';
import { projectGlyph } from './project/index.tsx';
import { ratingGlyph } from './rating/index.tsx';
import { reactionGlyph } from './reactions/index.tsx';
import { scalesGlyph } from './scales/index.tsx';
import { setUpXAxisGlyph, setUpYAxisGlyph } from './setup-axis/index.tsx';
import { shortTextGlyph } from './short-text/index.tsx';
import { sliderGlyph } from './slider/index.tsx';
import { sortAscMatrixGlyph, sortDescMatrixGlyph } from './sort-matrix/index.tsx';
import { statusGlyph } from './status/index.tsx';
import { switchGlyph } from './switch/index.tsx';
import { timelineGlyph } from './timeline/index.tsx';
import { timeStampGlyph } from './timestamp/index.tsx';
import type { PolarisIconProps } from './types.tsx';
import { upvoteGlyph } from './upvote/index.tsx';
import { visibleGlyph } from './visible/index.tsx';

const FIELD_TYPE_GLYPH_MAPPING = {
	[FIELD_TYPES.ASSIGNEE]: [peopleGlyph, 'assignee'],
	[FIELD_TYPES.CREATOR]: [peopleGlyph, 'creator'],
	[FIELD_TYPES.REPORTER]: [peopleGlyph, 'reporter'],
	[FIELD_TYPES.SUMMARY]: [shortTextGlyph, 'summary'],
	[FIELD_TYPES.ISSUE_ID]: [hiddenGlyph, 'issue id'],
	[FIELD_TYPES.STATUS]: [statusGlyph, 'status'],
	[FIELD_TYPES.ISSUE_KEY]: [keyGlyph, 'issue key'],
	[FIELD_TYPES.ISSUE_TYPE]: [issueTypeGlyph, 'issue type'],
	[FIELD_TYPES.SHORT_TEXT]: [shortTextGlyph, 'short text'],
	[FIELD_TYPES.HYPERLINK]: [linkGlyph, 'hyperlink'],
	[FIELD_TYPES.DATE]: [dateGlyph, 'date'],
	[FIELD_TYPES.INTERVAL]: [dateIntervalGlyph, 'date interval'],
	[FIELD_TYPES.NUMBER]: [numberGlyph, 'number'],
	[FIELD_TYPES.UPDATED]: [timeStampGlyph, 'updated'],
	[FIELD_TYPES.CREATED]: [timeStampGlyph, 'created'],
	[FIELD_TYPES.LABELS]: [labelGlyph, 'labels'],
	[FIELD_TYPES.CUSTOM_LABELS]: [labelGlyph, 'labels'],
	[FIELD_TYPES.SINGLE_SELECT]: [dropdownGlyph, 'single select dropdown'],
	[FIELD_TYPES.MULTI_SELECT]: [dropdownGlyph, 'multi select dropdown'],
	[FIELD_TYPES.JSW_MULTI_SELECT]: [dropdownGlyph, 'multi select dropdown'],
	[FIELD_TYPES.PEOPLE]: [peopleGlyph, 'users'],
	[FIELD_TYPES.JSW_PEOPLE]: [peopleGlyph, 'users'],
	[FIELD_TYPES.REACTIONS]: [reactionGlyph, 'reactions'],
	[FIELD_TYPES.CHECKBOX]: [checkboxGlyph, 'checkbox'],
	[FIELD_TYPES.SLIDER]: [sliderGlyph, 'slider'],
	[FIELD_TYPES.RATING]: [ratingGlyph, 'rating'],
	[FIELD_TYPES.FORMULA]: [formulaGlyph, 'formula'],
	[FIELD_TYPES.VOTES]: [likeIconFilledGlyph, 'play'],
	[FIELD_TYPES.ISSUE_COMMENTS]: [commentGlyph, 'comments'],
	[FIELD_TYPES.LINKED_ISSUES]: [linkGlyph, 'linked issues'],
	[FIELD_TYPES.INSIGHTS]: [insightsGlyph, 'insights'],
	[FIELD_TYPES.ATLAS_GOAL]: [atlasGoalGlyph, 'Atlas goals'],
	[FIELD_TYPES.ATLAS_PROJECT]: [atlasProjectGlyph, 'Atlas project'],
	[FIELD_TYPES.ATLAS_PROJECT_STATUS]: [atlasProjectGlyph, 'Atlas project'],
	[FIELD_TYPES.PROJECT]: [projectGlyph, 'project'],
} as const;

const isFieldTypeGlyphMappingKey = (
	fieldType: FieldType,
): fieldType is keyof typeof FIELD_TYPE_GLYPH_MAPPING => fieldType in FIELD_TYPE_GLYPH_MAPPING;

// we need 'isWeightedField' because some field types map to multiple icons, eg. MULTI_SELECT_FIELD_TYPE
export const iconForPolarisFieldType = (
	fieldType?: FieldType,
	iconProps?: IconProps | null,
	isWeightedField = false,
): ReactNode | null => {
	const iconPropsInner = iconProps ?? {};
	if (fieldType === undefined) {
		return null;
	}

	if (isWeightedField === true) {
		return <Icon glyph={scalesGlyph} size="small" label="weight" {...iconPropsInner} />;
	}

	if (fieldType === FIELD_TYPES.DELIVERY_STATUS) {
		return (
			<IconWrapper>
				<JiraIcon size="small" {...iconPropsInner} label="delivery status" />
			</IconWrapper>
		);
	}

	if (fieldType === FIELD_TYPES.DELIVERY_PROGRESS) {
		return (
			<IconWrapper>
				<JiraIcon size="small" {...iconPropsInner} label="delivery progress" />
			</IconWrapper>
		);
	}

	if (isFieldTypeGlyphMappingKey(fieldType)) {
		const [glyph, label] = FIELD_TYPE_GLYPH_MAPPING[fieldType];
		return <Icon glyph={glyph} size="small" label={label} {...iconPropsInner} />;
	}

	if (fieldType === FIELD_TYPES.DESCRIPTION) {
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		return <OverviewIcon size="small" label="overview" {...iconPropsInner} />;
	}

	if (fieldType === FIELD_TYPES.CONNECTION) {
		// eslint-disable-next-line jira/i18n/text-wrapped-in-format-message
		return <ChangesIcon label="connection" color="var(--ds-icon)" />;
	}

	if (fieldType === FIELD_TYPES.TEAM) {
		return (
			// eslint-disable-next-line jira/i18n/text-wrapped-in-format-message
			<PeopleGroupIcon label="team" color="var(--ds-icon)" />
		);
	}

	return null;
};

export const PolarisIconType = {
	ChevronRight: 'PolarisIconChevronRight',
	ChevronUp: 'PolarisIconChevronUp',
	Collection: 'PolarisCollection',
	Collections: 'PolarisCollections',
	Datapoint: 'PolarisIconDatapoint',
	Board: 'PolarisIconBoard',
	Matrix: 'PolarisIconMatrix',
	Timeline: 'PolarisIconTimeline',
	BulletList: 'PolarisIconBulletList',
	Visible: 'PolarisIconVisible',
	Hidden: 'PolarisIconHidden',
	Scales: 'PolarisIconScales',
	Downvote: 'PolarisIconDownvote',
	Upvote: 'PolarisIconUpvote',
	Poll: 'PolarisIconPoll',
	Switch: 'PolarisIconSwitch',
	AutosaveOff: 'PolarisIconAutosaveOff',
	AutosaveOn: 'PolarisIconAutosaveOn',
	SortMatrixAsc: 'PolarisIconSortMatrixAsc',
	SortMatrixDesc: 'PolarisIconSortMatrixDesc',
	CardLayout: 'PolarisIconCardLayout',
	MergeIdeas: 'PolarisIconMergeIdeas',
	DeliveryTickets: 'PolarisIconDeliveryTickets',
	SetUpXAxis: 'PolarisIconSetUpXAxis',
	SetUpYAxis: 'PolarisIconSetUpYAxis',
	Project: 'PolarisIconProject',
};

export const PolarisIcon = (props: PolarisIconProps) => {
	switch (props.name) {
		case PolarisIconType.ChevronRight:
			return <Icon glyph={chevronRightGlyph} {...props} />;
		case PolarisIconType.ChevronUp:
			return <Icon glyph={chevronUpGlyph} {...props} />;
		case PolarisIconType.Datapoint:
			return <Icon glyph={datapointGlyph} {...props} />;
		case PolarisIconType.Board:
			return <Icon glyph={boardGlyph} {...props} />;
		case PolarisIconType.Matrix:
			return <Icon glyph={matrixGlyph} {...props} />;
		case PolarisIconType.Timeline:
			return <Icon glyph={timelineGlyph} {...props} />;
		case PolarisIconType.BulletList:
			return <Icon glyph={bulletListGlyph} {...props} />;
		case PolarisIconType.Visible:
			return <Icon glyph={visibleGlyph} {...props} />;
		case PolarisIconType.Hidden:
			return <Icon glyph={hiddenGlyph} {...props} />;
		case PolarisIconType.Scales:
			return <Icon glyph={scalesGlyph} {...props} />;
		case PolarisIconType.Downvote:
			return <Icon glyph={upvoteGlyph} {...props} />;
		case PolarisIconType.Upvote:
			return <Icon glyph={upvoteGlyph} {...props} />;
		case PolarisIconType.Poll:
			return <Icon glyph={pollGlyph} {...props} />;
		case PolarisIconType.Switch:
			return <Icon glyph={switchGlyph} {...props} />;
		case PolarisIconType.AutosaveOff:
			return <Icon glyph={autosaveOffGlyph} {...props} />;
		case PolarisIconType.AutosaveOn:
			return <Icon glyph={autosaveOnGlyph} {...props} />;
		case PolarisIconType.SortMatrixAsc:
			return <Icon glyph={sortAscMatrixGlyph} {...props} />;
		case PolarisIconType.SortMatrixDesc:
			return <Icon glyph={sortDescMatrixGlyph} {...props} />;
		case PolarisIconType.CardLayout:
			return <Icon glyph={cardLayoutGlyph} {...props} />;
		case PolarisIconType.MergeIdeas:
			return <Icon glyph={mergeIdeasGlyph} {...props} />;
		case PolarisIconType.DeliveryTickets:
			return <Icon glyph={deliveryTicketsGlyph} {...props} />;
		case PolarisIconType.SetUpXAxis:
			return <Icon glyph={setUpXAxisGlyph} {...props} />;
		case PolarisIconType.SetUpYAxis:
			return <Icon glyph={setUpYAxisGlyph} {...props} />;
		case PolarisIconType.Project:
			return <Icon glyph={projectGlyph} {...props} />;
		case PolarisIconType.Collection:
			return <Icon glyph={collectionGlyph} {...props} />;
		case PolarisIconType.Collections:
			return <Icon glyph={collectionsGlyph} {...props} />;
		default:
			return null;
	}
};

export const ViewItemIcon = ({
	viewKind,
	size,
}: {
	viewKind?: ViewKind;
	size?: IconProps['size'];
}) => {
	if (viewKind === VIEW_KIND_TABLE) {
		return <PolarisIcon name={PolarisIconType.BulletList} size={size ?? 'medium'} label="" />;
	}

	if (viewKind === VIEW_KIND_BOARD) {
		return <PolarisIcon name={PolarisIconType.Board} size={size ?? 'medium'} label="" />;
	}

	if (viewKind === VIEW_KIND_MATRIX) {
		return <PolarisIcon name={PolarisIconType.Matrix} size={size ?? 'medium'} label="" />;
	}

	if (viewKind === VIEW_KIND_TIMELINE) {
		return <PolarisIcon name={PolarisIconType.Timeline} size={size ?? 'medium'} label="" />;
	}

	return null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& svg': {
		width: token('space.200', '16px'),
		height: token('space.200', '16px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		width: token('space.200', '16px'),
		height: token('space.200', '16px'),
		display: 'flex',
		alignItems: 'center',
	},
});

export const getIconWithProps =
	(providedProps: Partial<IconProps>): ComponentType<IconProps> =>
	(props: IconProps) => <Icon {...props} {...providedProps} />;
