import React, { useCallback, useContext, useEffect, useState, Fragment } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { SpotlightTarget } from '@atlaskit/onboarding';
import UFOInteractionContext from '@atlaskit/react-ufo/interaction-context';
import {
	CORE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { toForgeKey } from '@atlassian/jira-forge-ui-utils/src/utils/connect/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { EcosystemActivity } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import type { ActivitySortOrderType } from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import {
	ALL_ACTIVITY,
	APPROVALS,
	COMMENTS,
	CONNECT,
	FORGE,
	HISTORY,
	WORKLOG,
} from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import { JSW_ACTIVITY_FEED_BUTTONS } from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants.tsx';
import type {
	ActivityItem,
	AllActivityItem,
	CommentActivityItem,
	HistoryActivityItem,
	WorklogActivityItem,
} from '@atlassian/jira-issue-view-common-types/src/activity-item-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import {
	HeadingWithDraft,
	SectionHeading,
	SectionHeadingIcons,
	SectionHeadingTitle,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import { activityPanelAnalyticsData } from '@atlassian/jira-issue-view-common/src/ecosystem/ecosystem-analytics.tsx';
import ActivityFeedSkeleton from '@atlassian/jira-issue-view-common/src/skeleton/activity-feed-view.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	JsmSmartRequestSummaryEntryPointContextProvider,
	IssueSmartRequestSummaryEntryPointContextProvider,
} from '@atlassian/jira-issue-view-smart-request-summary-entrypoint/src/index.tsx';
import {
	setSelectedActivityItem,
	setInitialSelectedActivityItem,
	setSelectedActivitySortOrder,
	updateActivitySortOrderRequest,
} from '@atlassian/jira-issue-view-store/src/actions/activity-feed-actions.tsx';
import {
	isServiceDeskSelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { isPreviewSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { isNativeJiraTimeTrackingEnabledSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector.tsx';
import { ecosystemActivityPanelsSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector.tsx';
import {
	getSelectedActivityItem,
	getSelectedActivitySortOrder,
} from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector.tsx';
import {
	canAddCommentsSelector,
	visibleCommentIdsSelector,
	totalCommentsSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import { ActivityFeedFilter } from '@atlassian/jira-jsm-issue-activity-filter/src/ui/index.tsx';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { WORKLOG as WORKLOG_PERMALINK_TYPE } from '@atlassian/jira-platform-issue-permalinks/src/constants.tsx';
import { getPermalinkTypeAndId } from '@atlassian/jira-platform-issue-permalinks/src/index.tsx';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in/src/index.tsx';
import {
	ContextualAnalyticsData,
	fireOperationalAnalytics,
	fireUIAnalytics,
	MountEvent,
	SCREEN,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import {
	useIsSimplifiedProject,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useIsPremiumUserSeat } from '@atlassian/jira-router-resources-business-navigation/src/index.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { SelectActivitySortOrder } from './common/ui/types.tsx';
import { messages } from './messages.tsx';
import { ActivityItemHeading, ActivityItems, ActivityHeadingEndWrapper } from './styled.tsx';
import { ActivitySortingToggle } from './ui/activity-sorting-toggle/index.tsx';
import { ActivityFeedButtons } from './ui/buttons/index.tsx';
import { ActivityFeedDropdown } from './ui/dropdown/index.tsx';
import { JsmSmartRequestSummaryTrigger } from './ui/jsm-smart-request-summary-trigger/index.tsx';
import ActivityFeedItem from './ui/selected-item/index.tsx';
import { IssueSmartRequestSummaryTrigger } from './ui/smart-request-summary-trigger/index.tsx';

const SINGLE_COLUMN_MAX_WIDTH = 410;

type OwnProps = {
	shouldDisplayDropdown: boolean;
	containerWidth: number;
	setActivityItemsRef: (arg1: HTMLElement | null) => void;
	setActivitySortRef: (arg1: HTMLElement | null) => void;
	setSmartRequestSummaryTriggerRef: (arg1: HTMLElement | null) => void;
	onForceUpdateCompactState: () => void;
};

type StateProps = {
	isPreview: boolean;
	isServiceDesk?: boolean;
	shouldShowWorklog: boolean;
	canAddComments?: boolean;
	commentIds: string[];
	totalComments: number;
	ecosystemModules?: EcosystemActivity[];
	forgeModules?: EcosystemActivity[];
	selectedItem?: ActivityItem | null;
	selectedSortOrder: ActivitySortOrderType;
	projectKey: ProjectKey;
};

type DispatchProps = {
	onSwitchSelectedActivityItem: (arg1: ActivityItem) => void;
	onInitialSelectedActivityItem: (arg1: ActivityItem) => void;
	onSwitchSelectedActivitySortOrder: (arg1: ActivitySortOrderType) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const ERROR_LOCATION = 'issue.issue-actions.activity-feed';

const SORTABLE_ACTIVITY_TYPES = [ALL_ACTIVITY, COMMENTS, HISTORY, WORKLOG, APPROVALS];

const ufoNameSuffixForSwitchingToActivityItem = (item: ActivityItem): string => {
	// Intentionally avoiding default case to get exhaustiveness checking. Each of the entries here has a hand-made
	// experience created for it in the performance portal. Therefore, you may want to modify the performance portal
	// if you modify this function.
	//
	// eslint-disable-next-line default-case
	switch (item.type) {
		case ALL_ACTIVITY:
			return 'all';
		case APPROVALS:
			return 'approvals';
		case COMMENTS:
			return 'comments';
		case HISTORY:
			return 'history';
		case WORKLOG:
			return 'worklog';
		case CONNECT:
			return 'connect-app';
		case FORGE:
			return 'forge-app';
	}
};

const ufoNameForSwitchingToActivityItem = (item: ActivityItem): string =>
	`issue-view-activity-button-${ufoNameSuffixForSwitchingToActivityItem(item)}`;

export const FeedDisplay = ({
	shouldDisplayDropdown = false,
	canAddComments = false,
	selectedItem = null,
	ecosystemModules = [],
	commentIds,
	totalComments,
	isPreview,
	projectKey,
	onInitialSelectedActivityItem,
	onSwitchSelectedActivityItem,
	onSwitchSelectedActivitySortOrder,
	selectedSortOrder,
	setActivityItemsRef,
	setActivitySortRef,
	setSmartRequestSummaryTriggerRef,
	onForceUpdateCompactState,
	shouldShowWorklog,
	containerWidth,
}: Props) => {
	const { formatMessage } = useIntl();
	const projectType = useProjectType(projectKey);

	const isPremiumUserSeat = useIsPremiumUserSeat();

	const isSimplifiedProject = useIsSimplifiedProject(projectKey);

	const hasApprovals =
		projectType === SERVICE_DESK_PROJECT ||
		(projectType === CORE_PROJECT && isPremiumUserSeat && isSimplifiedProject);

	const getAllActivity = useCallback(
		() => [
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			{
				key: ALL_ACTIVITY,
				type: ALL_ACTIVITY,
				name: formatMessage(messages.allActivity),
			} as AllActivityItem,
		],
		[formatMessage],
	);

	const getApprovalsActivity = useCallback(
		() =>
			hasApprovals
				? [
						{
							key: APPROVALS,
							type: APPROVALS,
							name: formatMessage(messages.approvals),
						},
					]
				: [],
		[formatMessage, hasApprovals],
	);

	const getCommentActivity = useCallback(
		() =>
			commentIds.length || canAddComments
				? [
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							key: COMMENTS,
							type: COMMENTS,
							name: formatMessage(messages.comments),
						} as CommentActivityItem,
					]
				: [],
		[canAddComments, commentIds.length, formatMessage],
	);

	const getHistoryActivity = useCallback(
		() => [
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			{
				key: HISTORY,
				type: HISTORY,
				name: formatMessage(messages.history),
			} as HistoryActivityItem,
		],
		[formatMessage],
	);

	const getWorklogActivity = useCallback(
		() =>
			shouldShowWorklog
				? [
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							key: WORKLOG,
							type: WORKLOG,
							name: formatMessage(messages.worklog),
						} as WorklogActivityItem,
					]
				: [],
		[formatMessage, shouldShowWorklog],
	);

	const getEcosystemActivity = useCallback(
		(): ActivityItem[] =>
			ecosystemModules
				.map((item) => {
					switch (item.type) {
						case FORGE:
							return {
								key: toForgeKey(item.appKey, item.moduleKey),
								...item,
							};
						case CONNECT:
							return {
								key: `${item.moduleKey}${item.appKey}`,
								...item,
							};
						default:
							log.safeErrorWithoutCustomerData(
								ERROR_LOCATION,
								'Tried to show ecosystem activity with other type than connect or forge',
							);
							return null;
					}
				})
				.filter(Boolean),
		[ecosystemModules],
	);

	const getSelectedItem = useCallback(
		(activityItems: ActivityItem[] = []): ActivityItem => {
			if (selectedItem) {
				return selectedItem;
			}
			const permalink = getPermalinkTypeAndId();
			if (permalink) {
				const { permalinkType } = permalink;
				const activityItem = activityItems.find((item) => item.type === permalinkType);
				if (activityItem) {
					onInitialSelectedActivityItem(activityItem);
					return activityItem;
				}
			}

			const initialSelectedItem = activityItems[1];

			return initialSelectedItem;
		},
		[onInitialSelectedActivityItem, selectedItem],
	);

	const getActivityItems = useCallback(
		(): ActivityItem[] => [
			...getAllActivity(),
			...getCommentActivity(),
			...getHistoryActivity(),
			...getWorklogActivity(),
			...getEcosystemActivity(),
			...getApprovalsActivity(),
		],
		[
			getAllActivity,
			getApprovalsActivity,
			getCommentActivity,
			getEcosystemActivity,
			getHistoryActivity,
			getWorklogActivity,
		],
	);

	const getAnalyticsData = useCallback((item: ActivityItem): Attributes => {
		switch (item.type) {
			case FORGE:
				return {
					extensionId: item.extension.id,
				};
			case CONNECT:
				return activityPanelAnalyticsData(item.appKey, item.moduleKey);
			default:
				return { activityItemType: item.type };
		}
	}, []);

	const ufoInteractionContext = useContext(UFOInteractionContext);

	const ufoTraceSelectionOfActivityItem = useCallback(
		(item: ActivityItem, timeStamp: number): void => {
			const ufoName = ufoNameForSwitchingToActivityItem(item);
			ufoInteractionContext && ufoInteractionContext.tracePress(ufoName, timeStamp);
		},
		[ufoInteractionContext],
	);

	const selectActivityItem = useCallback(
		(item: ActivityItem, analyticsEvent: UIAnalyticsEvent, subject: string, timeStamp: number) => {
			const analyticsData: [string, Attributes] = [subject, getAnalyticsData(item)];
			fireUIAnalytics(analyticsEvent, ...analyticsData);

			ufoTraceSelectionOfActivityItem(item, timeStamp);

			// This needs to be persisted in redux otherwise when upper components remount, the selected item will be lost
			// This can happen when IssueLayout switches into compact mode.
			onSwitchSelectedActivityItem(item);
		},
		[getAnalyticsData, onSwitchSelectedActivityItem, ufoTraceSelectionOfActivityItem],
	);

	const selectSortOrder: SelectActivitySortOrder = useCallback(
		(order, analyticsEvent, subject: string) => {
			fireUIAnalytics(analyticsEvent, subject, {
				selectedSortOrder: order,
				selectedActivityFeed: selectedItem?.key,
			});
			onSwitchSelectedActivitySortOrder(order);
		},
		[onSwitchSelectedActivitySortOrder, selectedItem?.key],
	);

	const [getActivityItemsInState] = useState(() => getActivityItems);
	const [getSelectedItemInState] = useState(() => getSelectedItem);
	const [onInitialSelectedActivityItemInState] = useState(() => onInitialSelectedActivityItem);

	// componentDidMount
	useEffect(() => {
		const activityItems = getActivityItemsInState();
		const chosenItem = getSelectedItemInState(activityItems);
		onInitialSelectedActivityItemInState(chosenItem);
	}, [getActivityItemsInState, getSelectedItemInState, onInitialSelectedActivityItemInState]);

	const renderChangeboardingMount = useCallback(() => {
		const { permalinkType } = getPermalinkTypeAndId() || {};
		const hasCommentOrWorklogPermalink = permalinkType === COMMENTS || permalinkType === WORKLOG;

		if (!hasCommentOrWorklogPermalink) {
			return null;
		}
		return (
			<MountEvent
				onMount={(analyticsEvent: UIAnalyticsEvent) => {
					fireOperationalAnalytics(
						analyticsEvent,
						permalinkType === COMMENTS
							? 'permalinkWithChangeboardingModalComments mounted'
							: 'permalinkWithChangeboardingModalWorklog mounted',
					);
				}}
			/>
		);
	}, []);

	// When mounting and unmounting the button, we want to force update the activity items' "compact" state
	// This will collapse/uncollapse the activity items dropdown as necessary

	const smartRequestSummaryTriggerRef = useMergeRefs(
		setSmartRequestSummaryTriggerRef,
		onForceUpdateCompactState,
	);

	const isPremium = useIsPremium();

	const renderSmartRequestSummarizeAction = useCallback(
		(chosenItem: ActivityItem) => {
			const shouldExcludeSmartSummary = projectType === PRODUCT_DISCOVERY_PROJECT || !isPremium;

			if (chosenItem.type !== COMMENTS || shouldExcludeSmartSummary) {
				return null;
			}

			return (
				<ErrorBoundary id="issue.issue-view.activity.smart-summary" packageName="issueActivityFeed">
					{projectType === SERVICE_DESK_PROJECT && (
						<JsmSmartRequestSummaryTrigger
							ref={smartRequestSummaryTriggerRef}
							isCompact={!!containerWidth && containerWidth <= SINGLE_COLUMN_MAX_WIDTH}
							totalComments={
								fg('jsm_smart_summary_total_comments') ? totalComments : commentIds.length
							}
						/>
					)}
					{projectType !== SERVICE_DESK_PROJECT && (
						<IssueSmartRequestSummaryTrigger
							ref={smartRequestSummaryTriggerRef}
							isCompact={!!containerWidth && containerWidth <= SINGLE_COLUMN_MAX_WIDTH}
							totalComments={commentIds.length}
						/>
					)}
				</ErrorBoundary>
			);
		},
		[
			projectType,
			isPremium,
			smartRequestSummaryTriggerRef,
			containerWidth,
			commentIds.length,
			totalComments,
		],
	);

	const renderActivitySortDropdownNew = useCallback(
		(chosenItem: ActivityItem) => {
			// We want to disable the ability to allow users to sort ecosystem activity feeds
			// @ts-expect-error - TS2345 - Argument of type '"AllActivity" | "Approvals" | "Comments" | "CONNECT_ENTITY_TYPE" | "FORGE_ENTITY_TYPE" | "History" | "Worklog"' is not assignable to parameter of type '"AllActivity" | "Approvals" | "Comments" | "History" | "Worklog"'.
			const isDisabled = !SORTABLE_ACTIVITY_TYPES.includes(chosenItem.type);

			return (
				<div ref={setActivitySortRef}>
					<ActivitySortingToggle
						selectedSortOrder={selectedSortOrder}
						onSelectSortOrder={selectSortOrder}
						isDisabled={isDisabled}
					/>
				</div>
			);
		},
		[selectSortOrder, selectedSortOrder, setActivitySortRef],
	);

	const renderActvityHeadingRow = (activityItems: ActivityItem[], chosenItem: ActivityItem) => {
		if (activityItems.length <= 1) {
			return <ActivityItemHeading>{renderActivitySortDropdownNew(chosenItem)}</ActivityItemHeading>;
		}

		const menuProps = { role: 'menu', 'aria-label': formatMessage(messages.filterByLabel) };

		return (
			<ActivityItemHeading>
				<ActivityItems ref={setActivityItemsRef}>
					<HeadingWithDraft>{formatMessage(messages.filterBy)}</HeadingWithDraft>
					<SpotlightTarget name={JSW_ACTIVITY_FEED_BUTTONS}>
						<SectionHeadingIcons leftAlign {...(!shouldDisplayDropdown && menuProps)}>
							{shouldDisplayDropdown ? (
								<ActivityFeedDropdown
									items={activityItems}
									selectedItem={chosenItem}
									onSelectActivityItem={selectActivityItem}
									label={formatMessage(messages.filterBy)}
								/>
							) : (
								<ActivityFeedButtons
									items={activityItems}
									selectedItem={chosenItem}
									onSelectActivityItem={selectActivityItem}
								/>
							)}
						</SectionHeadingIcons>
					</SpotlightTarget>
				</ActivityItems>
				<ActivityHeadingEndWrapper>
					{renderSmartRequestSummarizeAction(chosenItem)}
					{renderActivitySortDropdownNew(chosenItem)}
				</ActivityHeadingEndWrapper>
			</ActivityItemHeading>
		);
	};

	const SmartRequestSummaryEntryPointContextProvider =
		projectType !== SERVICE_DESK_PROJECT
			? IssueSmartRequestSummaryEntryPointContextProvider
			: JsmSmartRequestSummaryEntryPointContextProvider;

	// on jira-ai-issue-view-improve-issues-button cleanup remove this code from DOM
	const SmartRequestSummaryContextProviderEnabled = fg('jira-ai-issue-view-improve-issues-button')
		? Fragment
		: SmartRequestSummaryEntryPointContextProvider;

	const { permalinkType } = getPermalinkTypeAndId() || {};

	if ((permalinkType === WORKLOG_PERMALINK_TYPE || !commentIds.length) && isPreview) {
		return <ActivityFeedSkeleton />;
	}

	const activityItems = getActivityItems();
	const chosenItem = getSelectedItem(activityItems);

	if (!activityItems.length || !chosenItem) {
		return null;
	}

	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName="activityFeed">
			<SmartRequestSummaryContextProviderEnabled>
				{renderChangeboardingMount()}
				<SectionHeading leftAlign>
					<SectionHeadingTitle data-testid="issue-activity-feed.heading">
						{activityItems.length > 1 ? formatMessage(messages.activity) : chosenItem.name}
					</SectionHeadingTitle>
				</SectionHeading>
				{renderActvityHeadingRow(activityItems, chosenItem)}
				<ActivityFeedFilter selectedActivityFeed={chosenItem.key} />
				<ActivityFeedItem selectedItem={chosenItem} selectedSortOrder={selectedSortOrder} />
			</SmartRequestSummaryContextProviderEnabled>
		</ContextualAnalyticsData>
	);
};

FeedDisplay.displayName = 'FeedDisplay';

export default flowWithSafeComponent(
	connect(
		(state: State): StateProps => ({
			isPreview: isPreviewSelector(state),
			isServiceDesk: isServiceDeskSelector(state),
			shouldShowWorklog: isNativeJiraTimeTrackingEnabledSelector(state),
			canAddComments: canAddCommentsSelector(state),
			commentIds: visibleCommentIdsSelector(state),
			totalComments: fg('jsm_smart_summary_total_comments') ? totalCommentsSelector(state) : 0,
			ecosystemModules: ecosystemActivityPanelsSelector(state),
			selectedItem: getSelectedActivityItem(state),
			selectedSortOrder: getSelectedActivitySortOrder(state),
			projectKey: projectKeySelector(state),
		}),
		(dispatch): DispatchProps => ({
			onSwitchSelectedActivityItem: (activityItem: ActivityItem) => {
				dispatch(setSelectedActivityItem(activityItem));
			},
			onInitialSelectedActivityItem: (activityItem: ActivityItem) => {
				dispatch(setInitialSelectedActivityItem(activityItem));
			},
			onSwitchSelectedActivitySortOrder: (sortOrder: ActivitySortOrderType) => {
				dispatch(setSelectedActivitySortOrder(sortOrder));
				dispatch(updateActivitySortOrderRequest(sortOrder));
			},
		}),
	),
)(withContainerWidth(FeedDisplay));
