import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type {
	JIRA_COMMENT_VISIBILITY_TYPE_GROUP,
	JIRA_COMMENT_VISIBILITY_TYPE_ROLE,
} from './comment-type.tsx';

export type RawUser = {
	id: string;
	displayName: string;
	avatarUrl: string;
	timeZone?: string;
};

export const COMMENT_VISIBILITY_TYPE_GROUP = 'group' as const;
export const COMMENT_VISIBILITY_TYPE_ROLE = 'role' as const;
export const COMMENT_VISIBILITY_TYPE_PUBLIC = 'public' as const;

export type CommentVisibilityType =
	| typeof COMMENT_VISIBILITY_TYPE_GROUP
	| typeof COMMENT_VISIBILITY_TYPE_ROLE
	| typeof COMMENT_VISIBILITY_TYPE_PUBLIC
	| typeof JIRA_COMMENT_VISIBILITY_TYPE_GROUP
	| typeof JIRA_COMMENT_VISIBILITY_TYPE_ROLE;

export type CommentVisibility = {
	type: CommentVisibilityType;
	value: string;
	groupId?: string;
};

export type RawComment = {
	id: string;
	author: RawUser | null;
	updateAuthor: RawUser | null;
	visibility: CommentVisibility | null;
	createdDate: string;
	updatedDate: string;
	edited: boolean;
	bodyAdf: ADF;
	isInternal: boolean;
	bodyHtml: string;
	jsdAuthorCanSeeRequest?: boolean;
	eventOccurredAt?: string | null;
	jsdIncidentActivityViewHidden?: boolean | null;
	parentId?: string;
	replies?: string[];
	isDeleted?: boolean;
};

export type RawComments = {
	comments: RawComment[];
	totalComments: number;
	rootCommentsCount?: number;
};
