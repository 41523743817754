import { useCallback, useMemo } from 'react';
import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { LAST_VIEWED_ISSUE_PROPERTY_KEY } from '../constants.tsx';
import type { LastViewedIssuePropertyData } from '../types.tsx';

type Props = {
	issueAri: string;
};

type GetIssuePropertyResponse<T> = {
	key: string;
	value: T;
};

const logError = (msg: string) =>
	log.safeErrorWithoutCustomerData(
		'issue.designs.design-section-fetch-last-viewed-issue-property',
		msg,
	);

class LastViewedIssuePropertyValidationError extends Error {}

function validateIssuePropertyValue(
	issuePropertyValue: unknown,
): asserts issuePropertyValue is LastViewedIssuePropertyData {
	if (
		issuePropertyValue === null ||
		issuePropertyValue === undefined ||
		typeof issuePropertyValue !== 'object'
	) {
		throw new LastViewedIssuePropertyValidationError('Issue property value is unexpected type');
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	for (const [key, value] of Object.entries(issuePropertyValue as object)) {
		if (typeof value !== 'object' || Object.values(value).some((val) => typeof val !== 'string')) {
			if (fg('thor-issue-view-enable-logging')) {
				logError(`Issue property object contains value of unexpected type at ${key}`);
			}
			throw new LastViewedIssuePropertyValidationError(
				`Issue property object contains value of unexpected type at ${key}`,
			);
		}
	}
}

export const useFetchLastViewedIssuePropertyService = ({ issueAri }: Props) => {
	const request = useCallback(async () => {
		const issueId = JiraIssueAri.parse(issueAri).resourceId;

		const url = `/rest/api/2/issue/${issueId}/properties/${LAST_VIEWED_ISSUE_PROPERTY_KEY}`;
		try {
			const data =
				await performGetRequest<GetIssuePropertyResponse<LastViewedIssuePropertyData>>(url);
			validateIssuePropertyValue(data.value);
			return data.value;
		} catch (e: unknown) {
			if (
				e instanceof LastViewedIssuePropertyValidationError ||
				(e instanceof FetchError && e.statusCode === 404)
			) {
				return undefined;
			}
			if (fg('thor-issue-view-enable-logging')) {
				logError('last viewed issue property validation error occured');
			}
			throw e;
		}
	}, [issueAri]);

	const { loading, error, data, fetch } = useService(request, { loading: true });

	const memoisedData = useMemo(() => data, [data]);

	return {
		loading,
		error,
		data: memoisedData,
		fetch,
	};
};
