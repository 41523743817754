import {
  JiraPriorityAriResourceOwner,
  JiraPriorityAriResourceType
} from "./chunk-C4M7DMM2.mjs";
import {
  RegisteredAri
} from "./chunk-CLQNXEWF.mjs";
import {
  AriParser
} from "./chunk-HNZGDVUS.mjs";

// src/jira/priority/manifest.ts
var jiraPriorityAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9-]+$"),
  resourceOwner: JiraPriorityAriResourceOwner,
  resourceType: JiraPriorityAriResourceType,
  resourceIdSlug: "activation/{activationId}/{priorityId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    priorityId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/priority/index.ts
var JiraPriorityAri = class _JiraPriorityAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._priorityId = opts.resourceIdSegmentValues.priorityId;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get priorityId() {
    return this._priorityId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraPriorityAriStaticOpts.qualifier,
      platformQualifier: jiraPriorityAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraPriorityAriStaticOpts.resourceOwner,
      resourceType: jiraPriorityAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.priorityId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        priorityId: opts.priorityId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraPriorityAriStaticOpts);
    return new _JiraPriorityAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraPriorityAriStaticOpts);
    return new _JiraPriorityAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      priorityId: this.priorityId
    };
  }
};

export {
  JiraPriorityAri
};
