import React, { useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import AkSpinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	TASK_FAIL,
	TASK_SUCCESS,
} from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import {
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';
import NoWatchers from './no-watchers/index.tsx';
import UsersList from './users-list/index.tsx';

type Props = {
	isLoading: boolean;
	canManageWatchers: boolean;
	users: User[];
	onDeleteUser: (arg1: User, arg2: () => void, arg3: () => void, arg4: UIAnalyticsEvent) => void;
	onFetchUsers?: () => void;
};

export const WatchersList = (props: Props) => {
	const { isLoading, canManageWatchers, users, onDeleteUser, onFetchUsers } = props;
	const { formatMessage } = useIntl();

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	const onSuccess = useCallback(() => {
		sendExperienceAnalytics({
			wasExperienceSuccesful: true,
			action: TASK_SUCCESS,
			analyticsSource: 'button',
			experience: 'removeUserAsWatcher',
			application: application ?? null,
			edition: edition ?? null,
		});
	}, [application, edition]);

	const onError = useCallback(() => {
		sendExperienceAnalytics({
			wasExperienceSuccesful: false,
			action: TASK_FAIL,
			analyticsSource: 'button',
			experience: 'removeUserAsWatcher',
			application: application ?? null,
			edition: edition ?? null,
		});
	}, [application, edition]);

	if (!ff('relay-migration-issue-fields-watchers_iqhn4')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			onFetchUsers?.();
		}, [onFetchUsers]);
	}

	if (users.length > 0) {
		return (
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			<Container data-testid="issue.watchers.dropdown.watchers-list.populated">
				<UsersList
					users={users}
					headerText={formatMessage(messages.watchingThisIssue)}
					onDeleteUser={onDeleteUser}
					canManageWatchers={canManageWatchers}
					onDeleteSuccess={onSuccess}
					onDeleteError={onError}
				/>
			</Container>
		);
	}

	if (isLoading) {
		return (
			<Container data-testid="issue-view-watchers.watchers.dropdown.watchers-list.loading">
				<SpinnerContainer>
					<AkSpinner size="medium" />
				</SpinnerContainer>
			</Container>
		);
	}

	return (
		// eslint-disable-next-line jira/integration/test-id-by-folder-structure
		<Container data-testid="issue.watchers.dropdown.watchers-list.empty">
			<NoWatchers text={formatMessage(messages.noWatchersYet)} />
		</Container>
	);
};

export default WatchersList;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ContainerOld = styled.div({
	paddingTop: token('space.100', '8px'),
	maxHeight: '190px',
	overflowY: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border', colors.N30)};`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ContainerVisualRefresh = styled.div({
	maxHeight: '190px',
	overflowY: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border', colors.N30)};`,
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = componentWithCondition(
	() => isVisualRefreshEnabled(),
	ContainerVisualRefresh,
	ContainerOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpinnerContainer = styled.div({
	height: '190px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});
