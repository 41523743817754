import {
	ASSET_ONBOARDING_TYPE,
	CUSTOMER_CONTEXT_TYPE,
	DEV_SUMMARY_TYPE,
	SLA_PANEL_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

export const JSD_TEMPLATE = 'JSD_TEMPLATE' as const;
export const JCS_TEMPLATE = 'JCS_TEMPLATE' as const;
export const JWM_TEMPLATE = 'JWM_TEMPLATE' as const;
export const DEFAULT_TEMPLATE = 'DEFAULT_TEMPLATE' as const;

export const SLA_PANEL_ID = 'sla-panel';
export const CUSTOMER_CONTEXT_ID = 'customer-context';

/**
 *  These fields (or panels) are required to be always visible in the primary section for the relevant layout template
 */
export const ALWAYS_PRIMARY_AND_VISIBLE = {
	[JSD_TEMPLATE]: [SLA_PANEL_TYPE, CUSTOMER_CONTEXT_TYPE, ASSET_ONBOARDING_TYPE],
	[JCS_TEMPLATE]: [SLA_PANEL_TYPE, CUSTOMER_CONTEXT_TYPE, ASSET_ONBOARDING_TYPE],
	/*
	 * The Dev Panel is a JSW only field, but because of the way the JSW plugin creates the field it is
	 * also available in Core as well, and therefore needs to be considered in the default template.
	 */
	[JWM_TEMPLATE]: [DEV_SUMMARY_TYPE],
	[DEFAULT_TEMPLATE]: [DEV_SUMMARY_TYPE],
};
