import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/index.tsx';
import { OriginalEstimateInlineEditView } from '@atlassian/jira-issue-field-original-estimate-inline-edit-full/src/ui/original-estimate/index.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField.graphql';

export interface IssueViewOriginalEstimateFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$key;
}

export const IssueViewOriginalEstimateField = ({
	area,
	fragmentKey,
}: IssueViewOriginalEstimateFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	// NOTE: original estimate type is used to get timeTrackingField data, which is used in underlying original estimate decomposed field.
	const data =
		useFragment<ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$key>(
			graphql`
				fragment ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField on JiraOriginalTimeEstimateField {
					...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
					issue {
						timeTrackingField {
							__typename
							fieldId
							type
							...originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditView
						}
					}
					fieldId
					type
					__typename
				}
			`,
			fragmentKey,
		);

	const originalEstimateFieldTypename = data?.__typename;
	const originalEstimateFieldId = data?.fieldId ?? '';
	const originalEstimateFieldType = data?.type ?? '';

	const onSubmit = useCallback(
		(value: number | null) => {
			issueId &&
				fieldChangeRequested(
					issueId,
					originalEstimateFieldId,
					{ timeInSeconds: value },
					undefined,
					{
						type: originalEstimateFieldType,
						__typename: originalEstimateFieldTypename,
					},
				);
		},
		[
			originalEstimateFieldTypename,
			originalEstimateFieldId,
			originalEstimateFieldType,
			fieldChangeRequested,
			issueId,
		],
	);

	const onSubmitSucceeded = useCallback(
		(value: number | null | undefined) => {
			issueId &&
				fieldChanged(
					issueId,
					originalEstimateFieldId,
					{ timeInSeconds: value },
					{
						type: originalEstimateFieldType,
						__typename: originalEstimateFieldTypename,
					},
				);
		},
		[
			originalEstimateFieldTypename,
			originalEstimateFieldId,
			originalEstimateFieldType,
			fieldChanged,
			issueId,
		],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, originalEstimateFieldId),
		[originalEstimateFieldId, fieldChangeFailed, issueId],
	);
	return (
		<Box testId={`issue.views.field.original-estimate-inline-edit.${data.fieldId}`}>
			<IssueViewFieldHeading
				area={area}
				fragmentKey={data}
				testId={`issue.issue-view-layout.issue-view-original-estimate-field.${data.fieldId}`}
			>
				<Box xcss={fieldWrapperStyles} testId="issue-view-layout-original-estimate-field.ui.view">
					<OriginalEstimateInlineEditView
						fragmentRef={data?.issue?.timeTrackingField ?? null}
						onSubmit={onSubmit}
						onSubmitSucceeded={onSubmitSucceeded}
						onSubmitFailed={onSubmitFailed}
					/>
				</Box>
			</IssueViewFieldHeading>
		</Box>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
	marginTop: 'space.negative.100',
});
