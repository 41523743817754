/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Box, xcss, Inline, Stack, Text } from '@atlaskit/primitives';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

const PinFieldInfoBanner = ({ onBannerDismiss }: { onBannerDismiss: () => void }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'banner shown', 'pinFieldInformation');
	}, [createAnalyticsEvent]);

	const { formatMessage } = useIntl();

	const handleBannerClose = () => {
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'button clicked',
			'pinnedFieldInfoBannerButtonCrossButton',
		);
		onBannerDismiss();
	};

	return (
		<Box xcss={pinFieldConfigurabilityButton} tabIndex={0}>
			<Stack space="space.050" xcss={bannerWrapperStyles}>
				<Inline xcss={mainStyles}>
					<Text weight="medium">{formatMessage(messages.heading)}</Text>
					<Box xcss={borderlessIconButton}>
						<IconButton
							appearance="subtle"
							icon={(iconProps) => (
								<CrossIcon
									{...iconProps}
									primaryColor={token('color.icon', N0)}
									size="small"
									aria-label={formatMessage(messages.crossButtonMessage)}
								/>
							)}
							onClick={handleBannerClose}
							label=""
							spacing="compact"
						/>
					</Box>
				</Inline>
				<Inline space="space.050" xcss={pinFieldConfigurabilityButton}>
					<Text>{formatMessage(messages.info)}</Text>
				</Inline>
			</Stack>
		</Box>
	);
};
export default PinFieldInfoBanner;

const bannerWrapperStyles = xcss({
	padding: 'space.150',
	border: `1px solid ${token('color.border')}`,
	marginTop: 'space.150',
});

const borderlessIconButton = xcss({
	marginTop: 'space.negative.050',
});

const pinFieldConfigurabilityButton = xcss({
	marginTop: 'space.negative.050',
	paddingBottom: 'space.050',
});

const mainStyles = xcss({
	flex: '1 1 0',
	width: '100%',
	justifyContent: 'space-between',
});
