import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';

import { addAnalytics } from '../../analytics/utils';

import { aiPanelsPluginKey } from './ai-panels-plugin-key';
import { type AIPanelsPluginState, type LocalIdsPanelTypes } from './types';
import { getNewlyEditedAIPanels } from './utils';

export const createAIPanelsPlugin = () => {
	return new SafePlugin<AIPanelsPluginState>({
		key: aiPanelsPluginKey,
		state: {
			init: (_, _state) => ({ editedPanelIds: {} }),
			apply: (tr, pluginState) => {
				const meta = tr.getMeta(aiPanelsPluginKey) as {
					localIdsPanelTypes: LocalIdsPanelTypes;
				};

				if (!meta || !meta.localIdsPanelTypes) {
					return pluginState;
				}

				const newEditedState = {
					...pluginState.editedPanelIds,
					...meta.localIdsPanelTypes,
				};

				return {
					...pluginState,
					editedPanelIds: newEditedState,
				};
			},
		},
		appendTransaction: (transactions, _oldState, newState) => {
			let tr = newState.tr;
			let edited = false;
			const aiPluginState = aiPanelsPluginKey.getState(newState);
			const localIdsPanelTypes = getNewlyEditedAIPanels({
				transactions,
				knownEditedPanelIds: aiPluginState.editedPanelIds,
			});
			if (Object.keys(localIdsPanelTypes).length) {
				tr = tr.setMeta(aiPanelsPluginKey, {
					localIdsPanelTypes,
				});
				edited = true;
				for (const [_, panelTypes] of Object.entries(localIdsPanelTypes)) {
					tr = addAnalytics({
						editorState: newState,
						tr,
						payload: {
							action: 'edited',
							actionSubject: 'editorPluginAI',
							actionSubjectId: 'AIPanels',
							attributes: {
								extensionKey: panelTypes.extensionKey,
								extensionType: panelTypes.extensionType,
							},
							eventType: EVENT_TYPE.UI,
						},
					});
				}
			}
			return edited ? tr : null;
		},
	});
};
