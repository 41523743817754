import React, { type KeyboardEvent, type MouseEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import { componentWithCondition, componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	Link as RouterLink,
	type LinkProps as RouterLinkProps,
} from '@atlassian/react-resource-router';

type LinkProps = RouterLinkProps & {
	wordBreak?: boolean;
	testId?: string;
	shouldDisplayStrikethrough?: boolean;
};

const Link = ({
	onClick,
	wordBreak = true,
	shouldDisplayStrikethrough = false,
	...rest
}: LinkProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onClickWithAnalytics = useCallback(
		(e: MouseEvent | KeyboardEvent) => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'link',
				}),
				'listWithPopup',
			);
			onClick && onClick(e);
		},
		[createAnalyticsEvent, onClick],
	);
	return (
		<StyledLink
			wordBreak={wordBreak}
			shouldDisplayStrikethrough={shouldDisplayStrikethrough}
			{...rest}
			onClick={onClickWithAnalytics}
		/>
	);
};

// Fix for Compiled bug where invalid props are passed on to the underlying DOM element when using inheritance.
const PropFilteredLink = componentWithFF(
	'jsc_fix_compiled_prop_forwarding_bug',
	({ wordBreak, shouldDisplayStrikethrough, ...rest }: LinkProps) => <RouterLink {...rest} />,
	RouterLink,
);

// can be removed when StyledLinkOld is removed
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkWrapperOld = styled(PropFilteredLink)({
	wordBreak: 'break-word',
});

// can be removed when cleaning up extend-popup-cell
const StyledLinkOld = ({ wordBreak = true, ...props }: LinkProps) =>
	wordBreak ? <LinkWrapperOld {...props} /> : <RouterLink {...props} />;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLinkNew = styled(PropFilteredLink)<{
	wordBreak?: boolean;
	shouldDisplayStrikethrough?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	wordBreak: (props) => (props.wordBreak ? 'break-word' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textDecoration: (props) =>
		props.shouldDisplayStrikethrough ? 'line-through !important' : undefined,
});

const StyledLink = componentWithCondition(
	() => fg('extend-popup-cell') || fg('nin-issue-links-entry-point'),
	StyledLinkNew,
	StyledLinkOld,
);

export default Link;
