import React, { memo } from 'react';

import { styled } from '@compiled/react';
import Avatar, {
	AVATAR_SIZES,
	Skeleton,
	type AppearanceType,
	type AvatarPropTypes,
	type SizeType,
} from '@atlaskit/avatar';
import { MediaImage } from '@atlaskit/media-image';
import type { MediaImageWithMediaClientConfigProps } from '@atlaskit/media-image/src/types';
import { Inline } from '@atlaskit/primitives';
import { type SimpleTagProps, SimpleTag } from '@atlaskit/tag';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import Link from '../../common/ui/link/index.tsx';

export type TagItemMediaImageProps = Omit<MediaImageWithMediaClientConfigProps, 'children'>;

type TagItemIconProps = {
	url?: string;
	mediaImage?: TagItemMediaImageProps;
};

type Props = {
	/** The tag name to be displayed to users for the item */
	name: string;
	/** URL to direct users to when they click on the item */
	href?: string;
	/** Optional icon to display next to the item */
	icon?: TagItemIconProps;
	/** Optional boolean to apply strikethrough style to the item */
	shouldDisplayStrikethrough?: boolean;
};

const AVATAR_SIZE: SizeType = 'xsmall';
const AVATAR_APPEARANCE: AppearanceType = 'square';

const StrikethroughTagLink: SimpleTagProps['linkComponent'] = (props) => (
	<Link shouldDisplayStrikethrough {...props} />
);

const TagItemAvatar = ({
	src,
	testId = 'list-with-popup.ui.tag-item.avatar',
}: Pick<AvatarPropTypes, 'src' | 'testId'>) => (
	<Avatar
		appearance={AVATAR_APPEARANCE}
		size={AVATAR_SIZE}
		src={src}
		borderColor="transparent"
		testId={testId}
	/>
);

const TagItemMediaImage = memo(
	({ mediaImage, fallbackSrc }: { mediaImage: TagItemMediaImageProps; fallbackSrc?: string }) => (
		<MediaImage
			identifier={mediaImage.identifier}
			mediaClientConfig={mediaImage.mediaClientConfig}
			apiConfig={{
				...mediaImage.apiConfig,
				width: AVATAR_SIZES[AVATAR_SIZE],
				height: AVATAR_SIZES[AVATAR_SIZE],
			}}
		>
			{({ loading, error, data }) => {
				if (loading) {
					return <Skeleton appearance={AVATAR_APPEARANCE} size={AVATAR_SIZE} />;
				}

				if (error || !data?.src) {
					return (
						<TagItemAvatar
							src={fallbackSrc}
							testId="list-with-popup.ui.tag-item.media-image-error"
						/>
					);
				}

				return (
					<TagItemAvatar src={data?.src} testId="list-with-popup.ui.tag-item.media-image-avatar" />
				);
			}}
		</MediaImage>
	),
);

const TagItemIcon = ({ icon }: { icon: TagItemIconProps }) => (
	<>
		{icon.mediaImage ? (
			<TagItemMediaImage mediaImage={icon.mediaImage} fallbackSrc={icon.url} />
		) : (
			<TagItemAvatar src={icon.url} />
		)}
	</>
);

/**
 * An item formatted with an Atlaskit Tag wrapper.
 * It's recommended to use the TagItemPopupContentWrapper with this item component.
 */
export const TagItem = ({ name, href, icon, shouldDisplayStrikethrough }: Props) => (
	<TagMarginFix>
		<SimpleTag
			elemBefore={
				icon ? (
					<>
						{fg('cmdb_field_avatar_issue_table_from_media') ? (
							<TagItemIcon icon={icon} />
						) : (
							<Avatar
								appearance="square"
								size="xsmall"
								src={icon.url}
								borderColor="transparent"
								testId="list-with-popup.ui.tag-item.avatar"
							/>
						)}
					</>
				) : undefined
			}
			text={name}
			href={href}
			linkComponent={shouldDisplayStrikethrough ? StrikethroughTagLink : Link}
		/>
	</TagMarginFix>
);

export const TagItemPopupContentWrapper = ({ children }: { children: React.ReactNode }) => (
	<Inline space="space.100" shouldWrap>
		{children}
	</Inline>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TagMarginFix = styled.span({
	display: 'inline-block',
	margin: token('space.negative.050', '-4px'),
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> span': {
		maxWidth: '100%',
	},
});
