// THIS FILE IS GENERATED. DO NOT MODIFY IT MANUALLY.
export { blockTypePlugin } from '@atlaskit/editor-plugin-block-type';
export type {
	BlockTypePlugin,
	BlockTypePluginOptions,
	BlockType,
	BlockTypeState,
	InputMethod,
	DropdownItem,
	TextBlockTypes,
} from '@atlaskit/editor-plugin-block-type';
