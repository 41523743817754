import React, { useEffect } from 'react';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer/src/index.tsx';
import { useEditorState, CommentActivityStoreContainer } from './state/context.tsx';
import { useCommentActivityActions } from './state/main.tsx';
import type { PackageProps } from './types.tsx';
import CommentView, { type Props as CommentProps } from './ui/comment/index.tsx';

export type Props = PackageProps & CommentProps;

export const Comment = (props: Props) => {
	const {
		baseUrl,
		issueAri,
		commentAri,
		isReactionsFeatureActive,
		mediaContext,
		mentionProvider,
		contextIdentifier,
		getEncodedMention,
		projectType,
		activityProvider,
		sharedAtTimestamp,
		sharedValue,
		onEditorExpandedFailure,
		onEditorSaveFailure,
		onEditorClearSharedValues,
		...commentProps
	} = props;
	const [, { setContextState, setEditorState, setReactionsState }] = useCommentActivityActions();

	useEffect(() => {
		setContextState({
			issueAri,
			commentAri,
			projectType,
		});
	}, [commentAri, issueAri, projectType, setContextState]);

	useEffect(() => {
		setEditorState({
			contextIdentifier,
			mentionProvider,
			getEncodedMention,
			activityProvider,
			mediaContext,
			sharedAtTimestamp,
			sharedValue,
			onEditorExpandedFailure,
			onEditorSaveFailure,
			onEditorClearSharedValues,
		});
	}, [
		activityProvider,
		contextIdentifier,
		getEncodedMention,
		mediaContext,
		mentionProvider,
		onEditorClearSharedValues,
		onEditorExpandedFailure,
		onEditorSaveFailure,
		setEditorState,
		sharedAtTimestamp,
		sharedValue,
	]);

	useEffect(() => {
		setReactionsState({
			isActive: isReactionsFeatureActive,
		});
	}, [isReactionsFeatureActive, setReactionsState]);

	// in preview mode there is only ONE render phase, so `useEffect` above is never
	// called. This means we have to control and init sweet-state imperatively
	// so all chilren components can read the information present in the state.
	if (__SERVER__) {
		setContextState({
			issueAri,
			commentAri,
			projectType,
		});
		setEditorState({
			contextIdentifier,
			mentionProvider,
			getEncodedMention,
			activityProvider,
			mediaContext,
			sharedAtTimestamp,
			sharedValue,
			onEditorExpandedFailure,
			onEditorSaveFailure,
			onEditorClearSharedValues,
		});
		setReactionsState({
			isActive: isReactionsFeatureActive,
		});
	}

	const [editorState] = useEditorState();

	// If these values are not available in the editor state then we do not
	// want to render the comment, because at the moment a second rendering
	// phase occurs once the values are set. If we don't avoid this, scrolling
	// to a focused comment does not work as expected because the height of
	// the comment content changes after the first render.
	if (!editorState.contextIdentifier || !editorState.mediaContext || !editorState.mentionProvider) {
		return null;
	}

	return (
		<ErrorBoundary id={`issue-comment-${props.commentId}`}>
			<CommentView {...commentProps} projectType={projectType} />
		</ErrorBoundary>
	);
};

export const IssueComment = (props: Props) => (
	<CommentActivityStoreContainer scope={`comment-${props.commentId}`}>
		<Comment {...props} />
	</CommentActivityStoreContainer>
);

export default IssueComment;
