import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { LoadIfNeeded } from '../../common/ui/load-if-needed/index.tsx';
import type SuggestedResourcesPanel from './index.tsx';

const SuggestedResourcesPanelAsync = lazyForPaint<typeof SuggestedResourcesPanel>(
	() => import(/* webpackChunkName: "async-incident-suggested-resources-panel" */ './index'),
);

export const AsyncSuggestedResourcesPanel = () => (
	<ErrorBoundary
		id="suggestedResourcesPanel"
		packageName="jiraAiopsSuggestedResources"
		teamName="itsm-aiops-grogu"
	>
		<LoadIfNeeded>
			{(containerProps) => (
				<UFOSegment name="issue-suggested-resources-panel">
					<Placeholder name="suggested-resources-panel" fallback={null}>
						<SuggestedResourcesPanelAsync containerProps={containerProps} />
					</Placeholder>
				</UFOSegment>
			)}
		</LoadIfNeeded>
	</ErrorBoundary>
);
