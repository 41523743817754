import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { fg } from '@atlassian/jira-feature-gating';
import type { StoreActionApi, GetState, SetState } from '@atlassian/react-sweet-state';
import type {
	AppId,
	AppRegisteredFields,
	ChangeId,
	IssueAdjustmentsState,
	RegisteredFields,
} from '../../types.tsx';

const registerFieldsFFOn = ({
	getState,
	setState,
	changeId,
	appId,
	fieldsToBeRegistered,
}: {
	getState: GetState<IssueAdjustmentsState>;
	setState: SetState<IssueAdjustmentsState>;
	changeId: ChangeId;
	appId: AppId;
	fieldsToBeRegistered: RegisteredFields;
}) => {
	const { registeredFields } = getState();
	const appFields = registeredFields[appId] ?? {};
	const appChangeIdFields = appFields[changeId] ?? [];

	const nextRegisteredFields = {
		...registeredFields,
		[appId]: {
			...appFields,
			[changeId]: uniq([...appChangeIdFields, ...fieldsToBeRegistered]),
		},
	};

	setState({ registeredFields: nextRegisteredFields });
};

export const registerFields =
	({
		changeId,
		fieldsToBeRegistered,
		appId,
	}: {
		changeId: ChangeId;
		fieldsToBeRegistered: RegisteredFields;
		appId: AppId;
	}) =>
	({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
		if (!Array.isArray(fieldsToBeRegistered)) {
			return;
		}

		if (fg('ui_modifications_multiple_apps')) {
			registerFieldsFFOn({
				getState,
				setState,
				changeId,
				appId,
				fieldsToBeRegistered,
			});
		} else {
			const alreadyRegisteredFields: AppRegisteredFields = getState().registeredFieldsDeprecated;

			setState({
				registeredFieldsDeprecated: {
					...alreadyRegisteredFields,
					[changeId]: fieldsToBeRegistered,
				},
			});
		}
	};

export const unregisterFields =
	({ changeId, appId }: { changeId: ChangeId; appId: AppId }) =>
	({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
		if (fg('ui_modifications_multiple_apps')) {
			const { registeredFields } = getState();
			const registeredAppFields: AppRegisteredFields = registeredFields[appId] ?? {};
			const nextRegisteredAppFields = omit(registeredAppFields, changeId);

			setState({
				registeredFields: {
					...registeredFields,
					[appId]: nextRegisteredAppFields,
				},
			});
		} else {
			setState({
				registeredFieldsDeprecated: omit(getState().registeredFieldsDeprecated, changeId),
			});
		}
	};
