import React from 'react';
import { CORE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { updateApprovalLinkedUserPickerField } from '@atlassian/jira-issue-view-store/src/actions/approval-action/index.tsx';
import { refreshIssueRequest } from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { DispatchGlanceProps, OwnPanelProps } from './types.tsx';
import Glance from './view/approval-glance.tsx';
import { JSMApprovalPanel } from './view/jsm-approval-panel.tsx';
import JwmPanel from './view/jwm-approval-panel.tsx';

export const ConnectedApprovalGlance = connect(null, {
	renderApproverList: refreshIssueRequest,
	updateApprovalLinkedUserPickerField,
})(Glance);

export const ConnectedJsmApprovalPanel = connect(null, {
	renderApproverList: refreshIssueRequest,
	updateApprovalLinkedUserPickerField,
	setRefreshIssue: refreshIssueRequest,
})(JSMApprovalPanel);

export const ConnectedJwmApprovalPanel = connect(null, {
	setRefreshIssue: refreshIssueRequest,
})(JwmPanel);

export const ApprovalGlance = (props: DispatchGlanceProps) => (
	<JSErrorBoundary
		id="JSMApprovalGlancePanel"
		packageName="jiraIssueView"
		teamName="jsm-getting-started"
		fallback="unmount"
		sendToPrivacyUnsafeSplunk
	>
		{/* @ts-expect-error - Type '{ renderApproverList: () => void; updateApprovalLinkedUserPickerField: (linkedUserPickerFieldId: string | undefined, newApproverList: ApproverFieldValue | readonly ApproverFieldValue[] | readonly GroupApproverPrincipal[] | null) => void; }' has no properties in common with type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<Omit<{ payload?: { approvalFieldId?: string | undefined; } | undefined; } & DispatchGlanceProps, "renderApproverList" | "updateApprovalLinkedUserPickerField">, any, any>> & Readonly<...>'. | Type '{ renderApproverList: () => void; updateApprovalLinkedUserPickerField: (linkedUserPickerFieldId: string | undefined, newApproverList: readonly GroupApproverPrincipal[] | ApproverFieldValue | readonly ApproverFieldValue[] | null) => void; }' has no properties in common with type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<Omit<{ payload?: { approvalFieldId?: string | undefined; } | undefined; } & DispatchGlanceProps, "renderApproverList" | "updateApprovalLinkedUserPickerField">, any, any>> & Readonly<...>'. */}
		<ConnectedApprovalGlance {...props} />
	</JSErrorBoundary>
);

export const ApprovalPanel = (props: OwnPanelProps) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	return projectType === CORE_PROJECT ? (
		<JSErrorBoundary
			id="JWMApprovalPanel"
			packageName="jiraIssueView"
			teamName="wanjel"
			fallback="unmount"
			sendToPrivacyUnsafeSplunk
		>
			<ConnectedJwmApprovalPanel {...props} />
		</JSErrorBoundary>
	) : (
		<JSErrorBoundary
			id="JSMApprovalPanel"
			packageName="jiraIssueView"
			teamName="jsm-getting-started"
			fallback="unmount"
			sendToPrivacyUnsafeSplunk
		>
			<ConnectedJsmApprovalPanel {...props} />
		</JSErrorBoundary>
	);
};
