import React, { Component, useRef } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { ButtonItem } from '@atlaskit/menu';
import {
	withConnectHost,
	isConnectDialogWebItem,
} from '@atlassian/jira-connect-utils/src/common/utils/ecosystem-connect-host.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import type { EcosystemOperation } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import type { ShortcutComponentProps } from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';

const CONNECT_APP_ACTION_TYPE = 'connectApp';

type Props = Omit<ShortcutComponentProps, 'onClick'> & {
	id: string;
	href: string;
	linkText: string;
	styleClass: string | null;
	tooltip: string | null;
	onClick?: (
		itemKey: string,
		event?: Event,
		actionAttributes?: Attributes,
	) => Promise<undefined> | undefined;
};

// eslint-disable-next-line jira/react/no-class-components
class EcosystemWebItem extends Component<Props> {
	static defaultProps = {
		styleClass: null,
		tooltip: null,
	};

	/**
	 * The reason we need a ref is to provide a relative anchor for Connect inline dialogs.
	 */
	ecosystemModalLinkRef: HTMLElement | null | undefined;

	setEcosystemModalLinkRef = (element: HTMLElement | null) => {
		this.ecosystemModalLinkRef = element;
	};

	clickEcosystemModalLinkRef = () => {
		this.props.onClick?.(this.props.itemKey, undefined, {
			actionType: CONNECT_APP_ACTION_TYPE,
		});
		// we need to capture element as it will be null when the callback is called
		// due to dropdown close on click/action

		const targetElement = this.ecosystemModalLinkRef;
		if (!targetElement) {
			return;
		}

		// if this isn't a connect web item, we still need to navigate to the href
		if (!isConnectDialogWebItem(this.props.styleClass || '')) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.href = this.props.href;
			return;
		}
		// delegate action to connectjs
		withConnectHost((connectHost) => {
			const performAction = () => {
				connectHost.triggerWebItem(
					targetElement,
					this.props.styleClass || '',
					this.props.href,
					'click',
				);
			};
			// put a delay in between to let MeatballMenu close and return focus
			// so the potential Dialog will be able to focus self properly
			// the delay is small enough to not be noticeable by the user
			setTimeout(performAction, 16);
		});

		// in case of error thrown it will be caught by Sentry
	};

	render() {
		const { id, linkText, tooltip } = this.props;
		return (
			<DropdownItem
				key={id}
				onClick={this.clickEcosystemModalLinkRef}
				title={tooltip || undefined}
				ref={this.setEcosystemModalLinkRef}
			>
				{linkText}
			</DropdownItem>
		);
	}
}

export const EcosystemWebButtonItem = ({
	id,
	linkText,
	href,
	styleClass,
	setDropdownItemRef,
	onClick,
	itemKey,
}: Props) => {
	const ecosystemModalLinkRef = useRef<HTMLAnchorElement | null>(null);

	const clickEcosystemModalLinkRef = () => {
		onClick?.(itemKey, undefined, { actionType: CONNECT_APP_ACTION_TYPE });
		ecosystemModalLinkRef.current?.click();
	};

	const renderEcosystemModalLink = (linkHref: string, linkStyleClass: string | null) => (
		// eslint-disable-next-line jsx-a11y/anchor-has-content
		<a
			href={linkHref}
			ref={ecosystemModalLinkRef}
			{...(linkStyleClass && {
				className: linkStyleClass,
			})}
		/>
	);

	return (
		<ButtonItem key={id} onClick={clickEcosystemModalLinkRef} ref={setDropdownItemRef}>
			{linkText}
			{renderEcosystemModalLink(href, styleClass)}
		</ButtonItem>
	);
};

export const getConnectAction = (ecosystemAction: EcosystemOperation) => ({
	component: (shortcutProps: ShortcutComponentProps) => (
		<ErrorBoundary packageName="issue" id="connect-items">
			<EcosystemWebItem
				href={ecosystemAction.url}
				linkText={ecosystemAction.name}
				styleClass={ecosystemAction.styleClass}
				id={ecosystemAction.name}
				tooltip={ecosystemAction.tooltip}
				{...shortcutProps}
			/>
		</ErrorBoundary>
	),
	dialogComponent: (shortcutProps: ShortcutComponentProps) => (
		<ErrorBoundary packageName="issue" id="connect-items">
			<EcosystemWebButtonItem
				href={ecosystemAction.url}
				linkText={ecosystemAction.name}
				styleClass={ecosystemAction.styleClass || null}
				tooltip={ecosystemAction.tooltip || null}
				id={ecosystemAction.name}
				{...shortcutProps}
			/>
		</ErrorBoundary>
	),
	eventName: ecosystemAction.name,
	key: ecosystemAction.name,
	isAllowed: true,
	label: ecosystemAction.name,
});
