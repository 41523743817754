import React, { type FC } from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { AiIcon } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../messages.tsx';

type Props = {
	onShowAISuggestionsClicked: () => void;
};

export const SuggestRelatedResourcesButton: FC<Props> = ({ onShowAISuggestionsClicked }) => {
	const { formatMessage } = useIntl();
	return (
		<Box xcss={buttonStyles}>
			<Button
				spacing="compact"
				onClick={onShowAISuggestionsClicked}
				iconBefore={(iconProps) => (
					<AiIcon
						{...iconProps}
						label={formatMessage(messages.suggestConfluencePages)}
						size="small"
					/>
				)}
			>
				{formatMessage(messages.suggestConfluencePages)}
			</Button>
		</Box>
	);
};

const buttonStyles = xcss({
	marginInlineStart: 'space.075',
});
