import React, { useCallback } from 'react';
import type { Action, Dispatch } from 'redux';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import inlineEditMessages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { DONE } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import type { IntlShapeV2 as Intl } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { ADD_MODAL } from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { openModal } from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions.tsx';
import {
	isMobileSelector,
	issueKeySelector,
	issueIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { fieldNameSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { isClassicProjectSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	timeTrackingWithSubtasksOrChildrenRollupSelector,
	timeTrackingConfigurationSelector,
	canLogTimeSelector,
	isNativeJiraTimeTrackingEnabledSelector,
	shouldDisplayRollUpDataControlSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector.tsx';
import { statusCategorySelector } from '@atlassian/jira-issue-view-store/src/selectors/status-selector.tsx';
import { useWorklogRollingUpData } from '@atlassian/jira-worklog-time-tracking/src/services/context/index.tsx';
import messages from './messages.tsx';
import type { StateProps, DispatchProps } from './view/index.tsx';
import TimeTrackingView from './view/view.tsx';

type OwnProps = {
	fieldId: string;
	area: Area;
	intl: Intl;
};

type ReduxStateProps = Omit<
	StateProps & {
		timeTrackingCalculationSelector: (arg1: boolean) => TimeTrackingState;
	},
	'value' | 'isRollingUpData'
>;
type ReduxDispatchProps = Omit<DispatchProps, 'onToggleRollingUpData'>;

export type ConnectedProps = ReduxStateProps & ReduxDispatchProps & OwnProps;

const WrappedViewWithAnalytics = withFireUiAnalytics({
	onToggleRollingUpData: 'toggleRollingUpData',
})(AnalyticsSubject('worklog')(TimeTrackingView));

export const WrappedTimeTrackingView = (props: ConnectedProps) => {
	// go/jfe-eslint
	const { timeTrackingCalculationSelector, area, intl, ...viewProps } = props;
	const [isRollingUpData, { markRollingUpData, unmarkRollingUpData }] = useWorklogRollingUpData();

	const onToggleRollingUpData = useCallback(
		(includeChildIssues: boolean) => {
			includeChildIssues ? markRollingUpData() : unmarkRollingUpData();
		},
		[markRollingUpData, unmarkRollingUpData],
	);

	return (
		<WrappedViewWithAnalytics
			{...viewProps}
			value={timeTrackingCalculationSelector(isRollingUpData)}
			isRollingUpData={isRollingUpData}
			onToggleRollingUpData={onToggleRollingUpData}
		/>
	);
};

export const extractedInvalidTimeFormatMessage = (
	<FormattedMessage
		id="issue.time-estimate.input-time-string-is-invalid"
		defaultMessage="Your estimate must be in the format {format}."
		values={{
			format: <b>2w 4d 6h 45m</b>,
		}}
	/>
);

export const mapStateToProps = (state: State, ownProps: OwnProps): ReduxStateProps => {
	const {
		intl: { formatMessage },
		area,
		fieldId,
	} = ownProps;

	const label = fieldNameSelector(ownProps.fieldId)(state);
	return {
		formatMessage,
		fieldId,
		timeTrackingCalculationSelector: timeTrackingWithSubtasksOrChildrenRollupSelector(state),
		config: timeTrackingConfigurationSelector(state),
		issueKey: issueKeySelector(state),
		issueId: issueIdSelector(state),
		isDone: statusCategorySelector(state) === DONE,
		isEditable: canLogTimeSelector(state),
		isMobile: isMobileSelector(state),
		isVisible: isNativeJiraTimeTrackingEnabledSelector(state),
		label,
		editButtonLabel: formatMessage(inlineEditMessages.editButtonLabel, {
			fieldName: label,
		}),
		confirmButtonLabel: formatMessage(inlineEditMessages.confirmButtonLabel, {
			fieldName: label,
		}),
		cancelButtonLabel: formatMessage(inlineEditMessages.cancelButtonLabel, {
			fieldName: label,
		}),
		timeRemainingFieldLabel: formatMessage(messages.timeRemainingFieldLabel),
		rollupLabel: isClassicProjectSelector(state)
			? formatMessage(messages.includeSubtasksLabel)
			: formatMessage(messages.includeChildrenLabel),
		showPinButton: getShowPinButton(area),
		shouldDisplayRollUpDataControl: shouldDisplayRollUpDataControlSelector(state),
		placeholderMessage: '2w 4d 6h 45m',
		invalidTimeFormatMessage: extractedInvalidTimeFormatMessage,
	};
};

export const mapDispatchToProps = (dispatch: Dispatch<Action>): ReduxDispatchProps => ({
	onEditRequest: () => {
		dispatch(openModal(ADD_MODAL, null));
	},
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(WrappedTimeTrackingView);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (ownProps: { fieldId: string; area: Area }) => {
	const intl = useIntl();
	return (
		<ErrorBoundary id="issue.issue-view.context.time-tracking-field">
			<ReportErrors
				id="context.time-tracking-field"
				packageName="jiraIssueView"
				sendToPrivacyUnsafeSplunk
			>
				<ConnectedComponent {...ownProps} intl={intl} />
			</ReportErrors>
		</ErrorBoundary>
	);
};
