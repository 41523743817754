// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	cloneIssue: {
		id: 'issue-view.issue-base.issue-actions.command-palette.clone-issue',
		defaultMessage: 'Clone issue',
		description: 'clone issue command registered in the command palette',
	},
	addFlag: {
		id: 'issue-view.issue-base.issue-actions.command-palette.add-flag',
		defaultMessage: 'Add flag',
		description: 'Flag issue command registered in the command palette',
	},
	removeFlag: {
		id: 'issue-view.issue-base.issue-actions.command-palette.remove-flag',
		defaultMessage: 'Remove flag',
		description: 'Remove flag from issue command registered in the command palette',
	},
	flagAddedSuccessfully: {
		id: 'issue-view.issue-base.issue-actions.command-palette.flag-added-successfully',
		defaultMessage: 'Successfully added flag',
		description: 'Toast flag title when a flag is added successfully',
	},
	flagAddedDescription: {
		id: 'issue-view.issue-base.issue-actions.command-palette.flag-added-description',
		defaultMessage: 'You’ve added a flag to {issueKey}.',
		description: 'Toast flag description when a flag is added successfully',
	},
	flagRemovedSuccessfully: {
		id: 'issue-view.issue-base.issue-actions.command-palette.flag-removed-successfully',
		defaultMessage: 'Successfully removed flag',
		description: 'Toast flag title when a flag is removed successfully',
	},
	flagRemovedDescription: {
		id: 'issue-view.issue-base.issue-actions.command-palette.flag-removed-description',
		defaultMessage: 'You’ve removed the flag from {issueKey}.',
		description: 'Toast flag description when a flag is removed successfully',
	},
});
