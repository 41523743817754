import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl-next';
import uuid from 'uuid';

import {
	AgentAvatar,
	type ConversationStarter,
	type StaticAgentConversationStarter,
} from '@atlaskit/rovo-agent-components';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { useContextualConversationStartersController } from '@atlassian/conversation-assistant';
import { type ApiError } from '@atlassian/conversation-assistant-service';
import type { Suggestion } from '@atlassian/generative-ai-modal/screens/UserInputCommandPalette';

import { createUnifiedAnalyticPayload, useFireAIAnalyticsEvent } from '../../../analytics/utils';
import { type EditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { mapToConvoAIProductName } from '../../../provider/prompt-requests/utils/get-convoai-supported-product-name';
import { type HandleHighlightCbType } from '../UserInputCommandPaletteWithLogic';

import { getDummySuggestions } from './dummy-suggestions';

const experienceName = 'Rovo agent conversation starters';
const suggestionCount = 3;

export function useAgentStarters({
	handleHighlight,
	configItem,
	onConversationStarterSelected,
	product,
}: {
	handleHighlight: HandleHighlightCbType;
	configItem: EditorPluginAIConfigItemMarkdown;
	onConversationStarterSelected: (conversationStarter: string) => void;
	product: string;
}) {
	const { agent } = configItem;

	const { formatMessage } = useIntl();

	const [hasError, setHasError] = useState(false);
	const fireAIAnalyticsEvent = useFireAIAnalyticsEvent();
	const [lastAiInteractionID, setLastAiInteractionID] = useState('');

	const userDefinedAgentStarters = useMemo(() => {
		if (!agent) {
			return [];
		}

		return agent.conversationStarters.filter((conversationStarter) => {
			return conversationStarter.type === 'user-defined';
		});
	}, [agent]);

	const onInit = useCallback(() => {
		setHasError(false);

		const aiId = uuid();
		setLastAiInteractionID(aiId);

		fireAIAnalyticsEvent({
			payload: createUnifiedAnalyticPayload('initiated', aiId, experienceName),
		});
	}, [fireAIAnalyticsEvent]);

	const onError = useCallback(
		(error: ApiError) => {
			setHasError(true);
			fireAIAnalyticsEvent({
				payload: createUnifiedAnalyticPayload('error', lastAiInteractionID, experienceName, false, {
					aiErrorMessage: error.message,
					aiErrorCode: 500,
				}),
			});
		},
		[fireAIAnalyticsEvent, lastAiInteractionID],
	);

	const {
		getContextualStarters,
		starters: contextualStarters,
		isMutating,
	} = useContextualConversationStartersController({
		onInit,
		onError,
		assistanceServiceConfig: {
			product: mapToConvoAIProductName(product),
			experienceId: 'ai-mate',
		},
	});

	const isPending = useMemo(() => {
		return (
			isMutating ||
			(contextualStarters.some((cs) => !cs) && userDefinedAgentStarters.length < suggestionCount)
		);
	}, [isMutating, contextualStarters, userDefinedAgentStarters]);

	useEffect(() => {
		if (!editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')) {
			return;
		}

		if (agent && userDefinedAgentStarters.length < suggestionCount) {
			getContextualStarters({
				locationUrl: window.location.href,
				fallbackStarters: agent.conversationStarters.map((conversationStarter) => {
					if (typeof conversationStarter.message !== 'string') {
						conversationStarter.message = formatMessage(conversationStarter.message);
					}

					return conversationStarter as ConversationStarter;
				}),
				agentNamedId: agent.namedId,
			});
		}
	}, [agent, getContextualStarters, formatMessage, userDefinedAgentStarters]);

	const starters = useMemo<Suggestion[]>(() => {
		if (!agent) {
			return [];
		}

		let conversationStarters: (ConversationStarter | StaticAgentConversationStarter)[] =
			editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')
				? userDefinedAgentStarters
				: agent.conversationStarters;

		if (editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')) {
			if (isPending) {
				return getDummySuggestions(agent, suggestionCount);
			}

			if (conversationStarters.length < suggestionCount) {
				// The linter was being very agressive about this typing for some reason
				// so I had to comfort it and make sure it was aware this variable definitely did
				// not contain nulls.
				// See: How to win friends and influence people by Dale Carnegie
				const nonNullContextualStarters = contextualStarters.filter(
					(conversationStarter) => conversationStarter !== null,
				) as ConversationStarter[];
				const availableContextualStarters: ConversationStarter[] = nonNullContextualStarters.slice(
					0,
					suggestionCount - conversationStarters.length,
				);

				conversationStarters = [...conversationStarters, ...availableContextualStarters];
			}
		}

		return conversationStarters.map(({ message: conversationStarter }, index) => {
			const translatedMessage =
				typeof conversationStarter === 'string'
					? conversationStarter
					: formatMessage(conversationStarter);

			return {
				value: `agent:${agent.id}:${index}:${translatedMessage}`,
				label: translatedMessage,
				icon: (
					<AgentAvatar
						agentId={agent.id}
						agentNamedId={agent.externalConfigReference ?? agent.namedId}
						agentIdentityAccountId={agent.identityAccountId}
						isForgeAgent={agent.creatorType === 'FORGE'}
						forgeAgentIconUrl={agent.icon}
					/>
				),
				groupHeading: '',
				onSelect: () => {
					onConversationStarterSelected(translatedMessage);
				},
				onSuggestionFocused: () => {
					handleHighlight({
						configItem,
					});
				},
			};
		});
	}, [
		agent,
		isPending,
		userDefinedAgentStarters,
		contextualStarters,
		formatMessage,
		onConversationStarterSelected,
		handleHighlight,
		configItem,
	]);

	useEffect(() => {
		if (!isPending && !hasError && starters.length) {
			fireAIAnalyticsEvent({
				payload: createUnifiedAnalyticPayload('viewed', lastAiInteractionID, experienceName),
			});
		}
	}, [isPending, hasError, starters, fireAIAnalyticsEvent, lastAiInteractionID]);

	return {
		starters,
	};
}
