import React, { type ReactNode, useState, useEffect, useRef } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useAnalyticsSource } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueError } from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import { getIssueErrorAttributes } from '@atlassian/jira-issue-view-errors/src/common/utils/index.tsx';
import {
	useApplication,
	useEdition,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { IssueErrorViewWithSuccessTracking } from '../issue-error-view-with-success-tracking/index.tsx';

type Props = {
	errorBoundaryId: string;
	children: ReactNode;
	renderFeedback?: () => ReactNode;
	// eslint-disable-next-line jira/react/handler-naming
	fallback?: (errorComponent: ReactNode, errorType: IssueError) => ReactNode;
	issueKey?: IssueKey;
	loginRedirectUrl: string | undefined;
};
const IssueViewErrorBoundaryOld = ({
	errorBoundaryId,
	children,
	issueKey,
	loginRedirectUrl,
	fallback,
}: Props) => {
	const accountIdLoggedInUser = useAccountId();
	const [caughtError, setCaughtError] = useState<Error | undefined>();
	const [errorKey, setErrorKey] = useState<number>(0);
	// We are purposely not using the usePrevious/usePreviousWithInitial hook as it has its own useEffect internally meaning
	// because of asynchronicity when we are checking it to update the error key it is not up to date so we need to manage this state ourselves
	const previousIssueKeyRef = useRef<IssueKey | undefined>(issueKey);

	useEffect(() => {
		if (issueKey !== previousIssueKeyRef.current && caughtError) {
			// When we error we are incrementing the key so that we completely remount the issue view + AppProvider as the error will otherwise be cached
			setErrorKey((key) => key + 1);
			setCaughtError(undefined);
		}
		previousIssueKeyRef.current = issueKey;
	}, [issueKey, caughtError, previousIssueKeyRef]);

	return (
		<JSErrorBoundary
			key={errorKey}
			id={errorBoundaryId}
			packageName="issue"
			onError={(_, error) => {
				setCaughtError(error);
			}}
			fallback={(errorProps) => {
				const { errorType, errorAttributes } = getIssueErrorAttributes(
					errorProps.error,
					accountIdLoggedInUser,
				);

				const isIssueKeyFresh = issueKey === previousIssueKeyRef.current;

				// We should avoid tracking the error if the issue key has changed as this means we are remounting the issue view
				const errorComponent = isIssueKeyFresh ? (
					<IssueErrorViewWithSuccessTracking
						errorType={errorType}
						loginRedirectUrl={loginRedirectUrl}
						errorAttributes={errorAttributes}
					/>
				) : (
					<>{null}</>
				);

				if (fallback) {
					return <>{fallback(errorComponent, errorType)}</>;
				}
				return errorComponent;
			}}
		>
			{children}
		</JSErrorBoundary>
	);
};

const IssueViewErrorBoundaryNew = ({
	errorBoundaryId,
	children,
	issueKey,
	loginRedirectUrl,
	fallback,
}: Props) => {
	const accountIdLoggedInUser = useAccountId();
	const [caughtError, setCaughtError] = useState<Error | undefined>();
	const [errorKey, setErrorKey] = useState<number>(0);
	// We are purposely not using the usePrevious/usePreviousWithInitial hook as it has its own useEffect internally meaning
	// because of asynchronicity when we are checking it to update the error key it is not up to date so we need to manage this state ourselves
	const previousIssueKeyRef = useRef<IssueKey | undefined>(issueKey);
	const projectKey = useProjectKey(issueKey || '');
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);
	const analyticsSource = useAnalyticsSource();

	useEffect(() => {
		if (issueKey !== previousIssueKeyRef.current && caughtError) {
			// When we error we are incrementing the key so that we completely remount the issue view + AppProvider as the error will otherwise be cached
			setErrorKey((key) => key + 1);
			setCaughtError(undefined);
		}
		previousIssueKeyRef.current = issueKey;
	}, [issueKey, caughtError, previousIssueKeyRef]);

	return (
		<JSErrorBoundary
			key={errorKey}
			id={errorBoundaryId}
			packageName="issue"
			onError={(_, error) => {
				setCaughtError(error);
			}}
			fallback={(errorProps) => {
				const { errorType, errorAttributes } = getIssueErrorAttributes(
					errorProps.error,
					accountIdLoggedInUser,
				);

				if (
					errorAttributes.statusCode &&
					errorAttributes.statusCode >= 400 &&
					errorAttributes.statusCode < 500
				) {
					fireErrorAnalytics({
						error: errorProps.error,
						meta: {
							id: 'issue.issue-view.viewIssue',
							teamName: 'Bento',
						},
						attributes: {
							experience: 'viewIssue',
							analyticsSource: analyticsSource || 'unknown',
							application: application || null,
							edition: edition || null,
							wasExperienceSuccesful: false,
							statusCode: errorAttributes.statusCode,
							traceId: errorAttributes.traceId || undefined,
						},
					});
				}

				const isIssueKeyFresh = issueKey === previousIssueKeyRef.current;

				// We should avoid tracking the error if the issue key has changed as this means we are remounting the issue view
				const errorComponent = isIssueKeyFresh ? (
					<IssueErrorViewWithSuccessTracking
						errorType={errorType}
						loginRedirectUrl={loginRedirectUrl}
						errorAttributes={errorAttributes}
					/>
				) : (
					<>{null}</>
				);

				if (fallback) {
					return <>{fallback(errorComponent, errorType)}</>;
				}
				return errorComponent;
			}}
		>
			{children}
		</JSErrorBoundary>
	);
};

export const IssueViewErrorBoundary = componentWithFG<Props, Props>(
	'add_analytics_for_4xx_for_view_issue',
	IssueViewErrorBoundaryNew,
	IssueViewErrorBoundaryOld,
);
