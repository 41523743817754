import { createSelector } from 'reselect';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { isMatchingConnectionFieldFilter } from '../utils/connection-field-filters.tsx';
import { getIssueIdsConsideringArchived } from './filters.tsx';
import {
	createGetIssueType,
	getConnectionProperties,
	getSelectedIssueLocalIssueId,
} from './properties/index.tsx';

export const createGetAllIssueIdsMatchingConnectionFieldFilters = (
	localIssueId: LocalIssueId,
	fieldKey: FieldKey,
) =>
	createSelector(
		getIssueIdsConsideringArchived,
		(state, props) => ({ state, props }),
		(issueIds, { state, props }) =>
			issueIds.filter(
				(issueId) =>
					issueId !== localIssueId &&
					isMatchingConnectionFieldFilter({
						fieldKey,
						localIssueId: issueId,
						state,
						props,
						skipEmptyFilter: true,
						issueType: createGetIssueType(issueId)(state, props),
					}),
			),
	);

export const createGetConnectionFieldIssueIds = (localIssueId: LocalIssueId, fieldKey: FieldKey) =>
	createSelector(
		getConnectionProperties,
		(connectionProperties) => connectionProperties[fieldKey]?.[localIssueId] || [],
	);

export const createGetSelectedIssuesConnectionFieldIssueIds = (fieldKey: FieldKey) =>
	createSelector(
		getSelectedIssueLocalIssueId,
		getConnectionProperties,
		(localIssueId, connectionProperties) => {
			if (!localIssueId) {
				return [];
			}

			return connectionProperties[fieldKey]?.[localIssueId] || [];
		},
	);

export const createGetSelectedIssuesConnectionFieldIssuesCount = (fieldKey: FieldKey) =>
	createSelector(createGetSelectedIssuesConnectionFieldIssueIds(fieldKey), (ids) => ids.length);
