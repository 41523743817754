import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { VIEW_RANK, FIELD_SORT } from '@atlassian/jira-polaris-domain-view/src/sort/constants.tsx';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache/src/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { wrapWithDeepEqualityCheck } from '../../../common/utils/reselect/index.tsx';
import { type Props, type State, IssueCreateStatusCreated } from '../types.tsx';
import { fieldMapping } from '../utils/field-mapping/index.tsx';
import type { FieldMapping } from '../utils/field-mapping/types.tsx';
import { getAllFieldsByKey, getFields } from './fields.tsx';
import { getFilteredIssueIds, getIssueIdsConsideringArchived } from './filters.tsx';
import {
	getLocalIssueIdToJiraId,
	getLocalIssueIdToJiraKey,
	getRankedIssueIds,
} from './issue-ids.tsx';
import {
	createSpecificallyMemoizedDataSelector,
	getCreatedProperties,
} from './properties/index.tsx';
import { addCreatedIssueIdsToSortedIds } from './sort-utils.tsx';

const EMPTY_SORT: Array<SortField> = [];

export type IdeaComparator = (arg1: LocalIssueId, arg2: LocalIssueId) => number;

const rankComparator =
	(idsWithOrder: Record<LocalIssueId, number>) =>
	(a: LocalIssueId, b: LocalIssueId): number =>
		idsWithOrder[a] - idsWithOrder[b];

export const createIndexMap = (keys: IssueId[]): Record<IssueId, number> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const indexMap: Record<string, any> = {};
	keys.forEach((key: IssueId, i) => {
		if (key === undefined) {
			return;
		}
		// String needed for flow
		indexMap[String(key)] = i;
	});
	return indexMap;
};

export const nullSafeExternalRankComparator = (
	idsInOrder: IssueId[],
	mapping: Record<LocalIssueId, IssueId>,
) => {
	const jiraIdsToIndex = createIndexMap(idsInOrder);
	return (a: LocalIssueId, b: LocalIssueId): number => {
		const issueIdA = mapping[a];
		const issueIdB = mapping[b];
		if (issueIdA === issueIdB) {
			return 0;
		}
		if (issueIdA === undefined) {
			return 1;
		}
		if (issueIdB === undefined) {
			return -1;
		}
		const indexA = jiraIdsToIndex[issueIdA];
		const indexB = jiraIdsToIndex[issueIdB];
		if (indexA === indexB) {
			return 0;
		}
		if (indexA === undefined) {
			return 1;
		}
		if (indexB === undefined) {
			return -1;
		}
		return indexA - indexB;
	};
};

const createIdeaComparatorForFieldComparator =
	(
		state: State,
		props: undefined | Props,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		accessor: (arg1: State, arg2: Props | undefined, arg3: LocalIssueId) => any | undefined,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		comparator: (a: any | undefined, b: any | undefined, direction: 'ASC' | 'DESC') => number,
		comparatorWithMapping:
			| ((
					arg1: State,
					arg2: Props | undefined,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					a: any | undefined,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					b: any | undefined,
					direction: 'ASC' | 'DESC',
			  ) => number)
			| undefined,
		ascending: boolean | string,
	) =>
	(a: LocalIssueId, b: LocalIssueId): number =>
		comparatorWithMapping
			? comparatorWithMapping(
					state,
					props,
					accessor(state, props, a),
					accessor(state, props, b),
					ascending ? 'ASC' : 'DESC',
				)
			: comparator(
					accessor(state, props, a),
					accessor(state, props, b),
					ascending ? 'ASC' : 'DESC',
				);

/**
 * creates a multi-comparator. every item in the list of passed comparators
 * is executed until we find one that does not consider the args equal
 *
 * visible for testing
 */
export const createCombinedComparator =
	(comparators: IdeaComparator[]): IdeaComparator =>
	(a: LocalIssueId, b: LocalIssueId): number => {
		for (let i = 0; i < comparators.length; i += 1) {
			const val = comparators[i](a, b);
			if (val !== 0) {
				return val;
			}
		}
		return 0;
	};

/** Visible for testing */
export const getCurrentSort = createSelector(
	getFields,
	(_state: State, props?: Props) => props?.sortBy,
	(fields, sortBy?: SortField[]): SortField[] => {
		if (!sortBy) {
			return EMPTY_SORT;
		}

		return sortBy.filter((sortField) => fields.find((field) => field.key === sortField.fieldKey));
	},
);

/** Visible for testing */
export const getCurrentSortMode = (_state: State, props?: Props) => {
	if (props?.sortMode !== FIELD_SORT || props?.sortBy?.length) {
		return props?.sortMode;
	}
};

const getCurrentExternalRank = (state: State, props?: Props) =>
	state.externalIssueRanking || props?.externalIssueRanking;

const getSortRelevantFieldMappings = createSelector(
	getCurrentSort,
	getAllFieldsByKey,
	getFields,
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
	({ containerProps }) => containerProps?.issuesRemote!,
	(sortBy, fieldsByKey, fields, issuesRemote): FieldMapping<unknown>[] => {
		const mappings: Array<FieldMapping<unknown>> = [];
		sortBy.forEach((sort) => {
			mappings.push(fieldMapping(issuesRemote, fields, fieldsByKey[sort.fieldKey]));
		});
		return mappings;
	},
);

const getSortRelevantProperties = createSpecificallyMemoizedDataSelector(
	getSortRelevantFieldMappings,
);

const getRankedIssueIdsIndexMap = createSelector(getRankedIssueIds, (issueIds) =>
	issueIds.reduce(
		(result, issueId, index) =>
			Object.assign(result, {
				[issueId]: index,
			}),
		{},
	),
);

/**
 * gets the comparator function depending on the current sort config, considering
 * fields and view config
 */
export const getCombinedComparator = createSelector(
	getCurrentSort,
	getCurrentSortMode,
	getCurrentExternalRank,
	getRankedIssueIdsIndexMap,
	getSortRelevantProperties,
	getAllFieldsByKey,
	getFields,
	getLocalIssueIdToJiraId,
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
	({ containerProps }) => containerProps?.issuesRemote!,
	(
		sortBy,
		sortMode,
		externalRank,
		rankedIssueIdsIndexMap,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[state, props]: [any, any],
		fieldsByKey,
		fields,
		localIssueIdToJiraId,
		issuesRemote,
	) => {
		if (sortMode === VIEW_RANK) {
			const { valueAccessor, comparator, comparatorWithMapping } = fieldMapping(
				issuesRemote,
				fields,
				fieldsByKey.created,
			);
			const comparatorForCreatedField = createIdeaComparatorForFieldComparator(
				state,
				props,
				valueAccessor,
				comparator,
				comparatorWithMapping,
				'ASC',
			);
			return createCombinedComparator([
				nullSafeExternalRankComparator(externalRank || [], localIssueIdToJiraId),
				comparatorForCreatedField,
			]);
		}

		const configuredComparators = sortBy.reduce<Array<IdeaComparator>>((comparators, sort) => {
			const mapping = fieldMapping(issuesRemote, fields, fieldsByKey[sort.fieldKey]);

			const comparator = createIdeaComparatorForFieldComparator(
				state,
				props,
				mapping.valueAccessor,
				mapping.comparator,
				mapping.comparatorWithMapping,
				sort.asc,
			);
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			return [...comparators, comparator];
		}, []);

		return createCombinedComparator([
			...configuredComparators,
			rankComparator(rankedIssueIdsIndexMap),
		]);
	},
);

/**
 * visible for testing
 */
export const createSortedIssueIdSelector = (
	baseIssueIdListSelector: (arg1: State, arg2: Props | undefined) => LocalIssueId[],
) =>
	createSelector(
		createSelector(
			baseIssueIdListSelector,
			getCombinedComparator,
			(baseIssueIdList, comparator) => {
				baseIssueIdList.sort(comparator);
				return baseIssueIdList.slice(0);
			},
		),
		getCreatedProperties,
		(sorted, createdProperties): LocalIssueId[] => {
			if (isEmpty(createdProperties)) {
				return sorted;
			}

			const createdIssueIds = Object.keys(createdProperties);
			// get relevant recently created ids (either in creation state or it is also included in the base list (and not filtered out)
			const relevantCreatedIssuesIds = createdIssueIds.filter(
				(createdId) =>
					createdProperties[createdId].status !== IssueCreateStatusCreated ||
					sorted.includes(createdId),
			);
			// remove created issueIds from baseIssueIdList
			const filtered = sorted.filter((id) => relevantCreatedIssuesIds.indexOf(id) === -1);

			const relevantCreatedProperties = pick(createdProperties, relevantCreatedIssuesIds);
			// add createdIssueIds according to their rank position
			if (!isEmpty(createdIssueIds)) {
				return addCreatedIssueIdsToSortedIds(relevantCreatedProperties, filtered);
			}

			return filtered;
		},
	);

export const getSortedIssueIds = wrapWithDeepEqualityCheck<
	State,
	Props | undefined,
	LocalIssueId[]
>(createSortedIssueIdSelector(getFilteredIssueIds));

export const getSortedIssueIndex = (localIssueId: LocalIssueId) =>
	createSelector(getSortedIssueIds, (sortedLocalIssueIds) =>
		sortedLocalIssueIds.indexOf(localIssueId),
	);

export const getSortedUnfilteredIssueIds = wrapWithDeepEqualityCheck<
	State,
	Props | undefined,
	LocalIssueId[]
>(createSortedIssueIdSelector(getIssueIdsConsideringArchived));

export const getSortedUnfilteredIssueKeys = createSelector(
	getSortedUnfilteredIssueIds,
	getLocalIssueIdToJiraKey,
	// eslint-disable-next-line @atlassian/eng-health/code-evolution/ts-migration-4.9-5.4
	// @ts-expect-error([Part of upgrade 4.9-5.4]) - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Readonly<{}>'.
	(localIssueIds, idsToKeys) => localIssueIds.map((id) => idsToKeys[id]).filter(Boolean),
);

export const getIsSorted = createSelector(getCurrentSort, (sortBy) => sortBy.length > 0);

/**
 * Returns the number of issues that are hidden by the current filter
 * before or after the given localIssueId
 */
export const getHiddenByFilter = cacheSelectorCreator(
	(localIssueId: LocalIssueId, position: 'before' | 'after') =>
		createSelector(
			getSortedIssueIds,
			getSortedUnfilteredIssueIds,
			(sortedIssueIds, sortedUnfilteredIssueIds) => {
				const filteredIssuesIssueIdx = sortedIssueIds.indexOf(localIssueId);
				const unfilteredIssuesIssueIdx = sortedUnfilteredIssueIds.indexOf(localIssueId);

				if (filteredIssuesIssueIdx === -1 || unfilteredIssuesIssueIdx === -1) {
					return 0;
				}

				if (position === 'before') {
					const prevIssueId = sortedIssueIds[filteredIssuesIssueIdx - 1];
					const unfilteredIssuesPrevIssueIdx = sortedUnfilteredIssueIds.indexOf(prevIssueId);
					if (unfilteredIssuesPrevIssueIdx === -1) {
						return 0;
					}
					return unfilteredIssuesIssueIdx - unfilteredIssuesPrevIssueIdx - 1;
				}

				if (position === 'after') {
					const nextIssueId = sortedIssueIds[filteredIssuesIssueIdx + 1];
					const unfilteredIssuesNextIssueIdx = sortedUnfilteredIssueIds.indexOf(nextIssueId);
					if (unfilteredIssuesNextIssueIdx === -1) {
						return 0;
					}
					return unfilteredIssuesNextIssueIdx - unfilteredIssuesIssueIdx - 1;
				}

				return 0;
			},
		),
);
