import React from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import Dropdown from './dropdown/index.tsx';
import type { VotersProps } from './types.tsx';
import VoteToggle from './vote-toggle/index.tsx';

export const Voters = ({ disableVotingProps, onFieldUpdated, ...rest }: VotersProps) => {
	const projectKey = useProjectKey();
	const [permissions] = useProjectPermissions(projectKey);
	const hasViewVotersPermissions = permissions.canViewVoters;

	if (hasViewVotersPermissions) {
		return (
			<Dropdown
				disableVotingProps={disableVotingProps}
				onFieldUpdated={onFieldUpdated}
				{...(ff('relay-migration-issue-fields-votes_nsbqd') ? { ...rest } : {})}
			/>
		);
	}
	return (
		<VoteToggle
			disableVotingProps={disableVotingProps}
			onFieldUpdated={onFieldUpdated}
			{...(ff('relay-migration-issue-fields-votes_nsbqd') ? { ...rest } : {})}
		/>
	);
};

export default Voters;
