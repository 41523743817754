// THIS FILE IS GENERATED. DO NOT MODIFY IT MANUALLY.
export { typeAheadPlugin } from '@atlaskit/editor-plugin-type-ahead';
export type {
	TypeAheadPlugin,
	TypeAheadHandler,
	TypeAheadInputMethod,
	TypeAheadPluginOptions,
	TypeAheadPluginState,
	TypeAheadPluginSharedState,
} from '@atlaskit/editor-plugin-type-ahead';
