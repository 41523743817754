import React, { Component } from 'react';
import noop from 'lodash/noop';
import Spinner from '@atlaskit/spinner';
import { CannedCommentsPlaceHolderSkeleton } from '@atlassian/jira-canned-comments/src/ui/canned-comments-placeholder/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { Button, Flex, SpinnerSpan, ServiceDeskSpinner } from './styled.tsx';

export type OwnProps = {
	placeholder: string;
	onExpanded: () => void;
	isSmartRepliesEnabled?: boolean;
};

export type StateProps = {
	isServiceDesk: boolean;
};

export type Props = OwnProps & StateProps;

type State = {
	changed: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export class FallbackAddCommentEditor extends Component<Props, State> {
	// go/jfe-eslint
	// eslint-disable-next-line react/sort-comp
	static defaultProps = {
		placeholder: '',
		onExpanded: noop,
		isSmartRepliesEnabled: false,
	};

	static getServiceDeskFallbackEditor() {
		return (
			<ServiceDeskSpinner>
				<Spinner size="medium" />
			</ServiceDeskSpinner>
		);
	}

	state = {
		changed: false,
	};

	onClick = () => {
		this.props.onExpanded();
		this.setState({ changed: true });
	};

	getGeneralFallbackEditor() {
		const [expConfig] = UNSAFE_noExposureExp('smart_replies_m1');

		return this.props.isSmartRepliesEnabled && expConfig.get('isEnabled', false) ? (
			<CannedCommentsPlaceHolderSkeleton
				onClick={this.onClick}
				shouldShowLoadingStateSpinner={this.state.changed}
			/>
		) : (
			<Button onClick={this.onClick}>
				<Flex>
					{this.props.placeholder}
					{this.state.changed && (
						<SpinnerSpan>
							<Spinner size="small" />
						</SpinnerSpan>
					)}
				</Flex>
			</Button>
		);
	}

	render() {
		const shouldUseServiceDeskCommentEditor = this.props.isServiceDesk;
		return shouldUseServiceDeskCommentEditor
			? FallbackAddCommentEditor.getServiceDeskFallbackEditor()
			: this.getGeneralFallbackEditor();
	}
}
