import { SET_VALUE_ARRAY_SIZE_LIMIT } from '@atlassian/jira-issue-adjustments/src/common/constants.tsx';
import { baseScopesRequiredPerField } from '@atlassian/jira-issue-adjustments/src/common/utils/filter-based-on-scopes/fields-scopes.tsx';
import { isAriResourceIdValid } from '../utils/is-ari-resource-id-valid/index.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string/index.tsx';

export type VersionsPublicSetValue = string[];

export type VersionValue = {
	id: string;
	name: string;
};

export const validateVersionValue = (value: unknown): value is VersionsPublicSetValue =>
	Array.isArray(value) &&
	value.every(isNotEmptyString) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT;

export const validateVersionWithAriSetValue = (value: unknown): value is VersionsPublicSetValue =>
	Array.isArray(value) &&
	value.every((val) => isNotEmptyString(val) && isAriResourceIdValid(val)) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT;

export const versionsRequiredScopes = {
	granular: [...baseScopesRequiredPerField.granular, 'read:project-version:jira'],
	coarse: [...baseScopesRequiredPerField.coarse],
};
