import React, { useCallback, type ComponentType } from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { EcosystemContextPanels } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import type { ContextPanel } from '@atlassian/jira-issue-shared-types/src/common/types/context-panel-type.tsx';
import { CONTEXT_GROUP_INITIAL_OPENED } from '@atlassian/jira-issue-view-common-constants/src/layout.tsx';
import { useEcosystemContextPanels } from '@atlassian/jira-issue-view-ecosystem-service/src/services/main.tsx';
import { isGroupPanelOpen } from '@atlassian/jira-issue-view-layout-group/src/services/local-storage/index.tsx';
import {
	generateContextPanelId,
	useForgeContextPanelsAsLayoutItems,
} from '@atlassian/jira-issue-view-layout-templates-services/src/services/context/visible-hidden/primary-secondary-items/utils.tsx';
import { useLayoutFieldsMeta } from '@atlassian/jira-issue-view-layout-templates-services/src/services/fields-meta/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnalyticsAttribution = Record<string, any>;
export type AnalyticsAttributionFactory = () => AnalyticsAttribution | undefined;

const toPanelAnalyticsData = (
	projectKey: string,
	appKey: string,
	appName: string,
	contextPanelId: string,
): { appKey: string; appName: string; isPanelOpen: boolean } => ({
	appKey,
	appName,
	isPanelOpen: isGroupPanelOpen(
		projectKey,
		'context-group',
		contextPanelId,
		CONTEXT_GROUP_INITIAL_OPENED,
	),
});

const flattenEcosystemPanels = (ecosystemContextPanels: EcosystemContextPanels) => {
	const contextPanels: ContextPanel[] = [];

	Object.values(ecosystemContextPanels).forEach((contextPanelObject) => {
		contextPanels.push(Object.values(contextPanelObject)[0]);
	});
	return contextPanels;
};

/**
 * @returns a function generating current analytics attributes
 */
export const useIssueAnalyticsAttributes = (): AnalyticsAttributionFactory => {
	const projectKey = useProjectKey(useIssueKey());

	const forgeContextPanels = useForgeContextPanelsAsLayoutItems();

	const [ecosystemContextPanels] = useEcosystemContextPanels();

	const layoutFieldsMeta = useLayoutFieldsMeta();

	// we return callback as updates to localStorage used to keep panel state are not reactive
	// it's easier to recompute it every time rather refactor everything to use jira-browser-storage-providers
	return useCallback(
		() => ({
			...(forgeContextPanels
				? {
						forgeContextPanel: forgeContextPanels.map((item) =>
							toPanelAnalyticsData(projectKey, item.id, item.payload.name, item.id),
						),
					}
				: {}),

			...(ecosystemContextPanels
				? {
						connectContextPanel: flattenEcosystemPanels(ecosystemContextPanels).map((item) =>
							toPanelAnalyticsData(
								projectKey,
								item.appKey,
								item.name,
								generateContextPanelId(item),
							),
						),
					}
				: {}),
			...layoutFieldsMeta,
		}),
		[ecosystemContextPanels, forgeContextPanels, projectKey, layoutFieldsMeta],
	);
};

/**
 * HOC to inject `analyticsAttributesFactory`
 */
export const withAnalyticsAttributes =
	() =>
	<Props,>(
		Component: ComponentType<Props & { analyticsAttributesFactory?: AnalyticsAttributionFactory }>,
	): ComponentType<Props> =>
		function WithAnalyticsAttributes(props) {
			const factory = useIssueAnalyticsAttributes();
			return <Component {...props} analyticsAttributesFactory={factory} />;
		};
