import React, { useEffect, useMemo } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { designBackfillCardEntryPoint } from '../entrypoint.tsx';
import type { BackfillDesign } from './ui/types.tsx';

type DesignBackfillCardProps = {
	providerId: string;
	providerName: string | null | undefined;
	designs: BackfillDesign[];
	ariGraphRecordId?: string;
	designRecordId?: string;
	onBackfillComplete: () => void;
};

export const DesignBackfillCard = ({
	providerId,
	providerName,
	designs,
	ariGraphRecordId,
	designRecordId,
	onBackfillComplete,
}: DesignBackfillCardProps) => {
	const cloudId = useCloudId();

	const entryPointParams = useMemo(
		() => ({
			cloudId,
			providerId,
		}),
		[cloudId, providerId],
	);

	const runtimeProps = useMemo(
		() => ({
			providerId,
			providerName,
			designs,
			ariGraphRecordId,
			designRecordId,
			onBackfillComplete,
		}),
		[providerId, providerName, designs, ariGraphRecordId, designRecordId, onBackfillComplete],
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		designBackfillCardEntryPoint,
		entryPointParams,
	);
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => entryPointActions.load(), [entryPointActions]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="designBackfillCardEntryPoint"
			packageName="jiraDesignBackfillCard"
			errorFallback="unmount"
			teamName="helios-headcoach"
			runtimeProps={runtimeProps}
		/>
	);
};
