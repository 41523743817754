import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { ErrorBoundary } from '../../common/ui/error-boundary/index.tsx';
import { useDevOpsSummaryExperienceFail } from '../../services/experience-tracking/index.tsx';
import type { Props } from './types.tsx';

const LazyDeliverySummary = lazyAfterPaint(() =>
	import(
		/* webpackChunkName: "async-dev-ops-summary-delivery-summary" */
		'./index'
	).then((module) => module.DeliverySummary),
);

export const AsyncDeliverySummary = (props: Props) => {
	const onFail = useDevOpsSummaryExperienceFail();
	return (
		<ErrorBoundary fallback="unmount" onError={onFail}>
			<Placeholder name="delivery-summary" fallback={null}>
				<LazyDeliverySummary {...props} />
			</Placeholder>
		</ErrorBoundary>
	);
};
