export const FIELD_ITEM = 'FIELD' as const;
export const DEV_SUMMARY_ITEM = 'DEV_SUMMARY' as const;
export const RELEASES_PANEL_ITEM = 'RELEASES_PANEL' as const;
export const PANEL_ITEM = 'PANEL' as const;
export const TAB_ITEM = 'TAB' as const;

export type LayoutItemType =
	| typeof FIELD_ITEM
	| typeof DEV_SUMMARY_ITEM
	| typeof RELEASES_PANEL_ITEM
	| typeof PANEL_ITEM
	| typeof TAB_ITEM;

export type LayoutItem = {
	id: string;
	type: LayoutItemType;
};

//
// --- SERVER RESPONSE TYPES ---
//

export const CONTEXT_LOCATION = 'CONTEXT' as const;
export const CONTENT_LOCATION = 'CONTENT' as const;
export const FOUNDATION_LOCATION = 'FOUNDATION' as const;

export const CONTENT_REQUEST_CATEGORY = 'CONTENT_REQUEST' as const;
export const CONTENT_VISIBLE_CATEGORY = 'CONTENT_VISIBLE' as const;
export const CONTENT_HIDDEN_CATEGORY = 'CONTENT_HIDDEN' as const;
export const CONTEXT_PRIMARY_CATEGORY = 'CONTEXT_PRIMARY' as const;
export const CONTEXT_SECONDARY_CATEGORY = 'CONTEXT_SECONDARY' as const;
export const CONTEXT_HIDDEN_CATEGORY = 'CONTEXT_HIDDEN' as const;
export const FOUNDATION_CONTEXT_CATEGORY = 'FOUNDATION_CONTEXT' as const;
export const FOUNDATION_CONTENT_CATEGORY = 'FOUNDATION_CONTENT' as const;

type ServerLayoutLocationKey =
	| typeof CONTEXT_LOCATION
	| typeof CONTENT_LOCATION
	| typeof FOUNDATION_LOCATION;

export type ServerLayoutCategoryKey =
	| typeof CONTENT_REQUEST_CATEGORY
	| typeof CONTENT_VISIBLE_CATEGORY
	| typeof CONTENT_HIDDEN_CATEGORY
	| typeof CONTEXT_PRIMARY_CATEGORY
	| typeof CONTEXT_SECONDARY_CATEGORY
	| typeof FOUNDATION_CONTEXT_CATEGORY
	| typeof FOUNDATION_CONTENT_CATEGORY
	| typeof CONTEXT_HIDDEN_CATEGORY;

export type ServerLayoutItem = {
	itemId: string;
	type: LayoutItemType;
};

export type ServerLayoutItemsCategory = {
	location: ServerLayoutLocationKey;
	category: ServerLayoutCategoryKey;
	items: ServerLayoutItem[];
};

export type ServerLayoutConfiguration = {
	contextPrimary: ServerLayoutItem[];
	contextSecondary: ServerLayoutItem[];
	content: ServerLayoutItem[];
	// A missing contentRequest layout section means that the container for these items will not even mount
	contentRequest: ServerLayoutItem[] | undefined;
};

export type TabItemType = typeof FIELD_ITEM;

export type TabItem = {
	id: string;
	type: TabItemType;
};

export type Tab = {
	id: string;
	name: string;
	items: TabItem[];
};

export type Tabs = {
	[id: string]: Tab;
};
