import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	ideasTitle: {
		id: 'issue-view-jpd-ideas.ideas-title',
		defaultMessage: 'Ideas',
		description: 'Title for ideas panel section in JSW issue view',
	},
	ideasPickerPlaceholder: {
		id: 'issue-view-jpd-ideas.ideas-picker-placeholder',
		defaultMessage: 'Search for ideas',
		description: 'Placeholder text for ideas in issue picker select component.',
	},
	linkButton: {
		id: 'issue-view-jpd-ideas.link-button',
		defaultMessage: 'Link',
		description: 'Label for link button.',
	},
	cancelButton: {
		id: 'issue-view-jpd-ideas.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Label for cancel button.',
	},
	linkIdeaErrorFlagTitle: {
		id: 'issue-view-jpd-ideas.link-idea-error-flag-title',
		defaultMessage: "We couldn't link your ideas",
		description: 'Title for the flag shown if linking idea fails',
	},
	linkIdeaErrorFlagDescription: {
		id: 'issue-view-jpd-ideas.link-idea-error-flag-description',
		defaultMessage: "We couldn't link your ideas. Refresh and try again.",
		description: 'Description for the flag shown if linking idea fails',
	},
	unlinkIdeaErrorFlagTitle: {
		id: 'issue-view-jpd-ideas.unlink-idea-error-flag-title',
		defaultMessage: "We couldn't unlink your idea",
		description: 'Title for the flag shown if unlinking idea fails',
	},
	unlinkIdeaErrorFlagDescription: {
		id: 'issue-view-jpd-ideas.unlink-idea-error-flag-description',
		defaultMessage: "We couldn't unlink your idea. Refresh and try again.",
		description: 'Description for the flag shown if unlinking idea fails',
	},
});
