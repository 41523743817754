// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ReactNode, type ComponentProps } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Renderer from '@atlassian/jira-issue-view-media-renderer/src/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';

type IssueEditorRenderedProps = ComponentProps<typeof Renderer>;

export const IssueEditorReadonly = ({
	defaultValue,
	mediaContext,
}: {
	defaultValue: IssueEditorRenderedProps['adf'] | string;
	mediaContext: IssueEditorRenderedProps['mediaContext'];
	onViewRefresh?: () => void;
}) => <Renderer mediaContext={mediaContext} adf={defaultValue} />;

export type IssueEditorBoundaryProps = {
	children: ReactNode;
	fallback: ReactNode;
	packageName: string;
};

export const IssueEditorBoundary = ({
	children,
	fallback,
	packageName,
}: IssueEditorBoundaryProps) => (
	<ErrorBoundary id="issue-editor" packageName={packageName} render={() => <>{fallback}</>}>
		<Placeholder name="issue-editor-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</ErrorBoundary>
);
