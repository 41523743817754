import type { PreloadFetchPolicy } from 'react-relay';
import mainGoalsPopupContentQueryParameters from '@atlassian/jira-relay/src/__generated__/mainGoalsPopupContentQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const goalPickerPopoverEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-jwm-goals-picker" */ './index'),
	),
	getPreloadProps: ({ cloudId, issueAri }: { cloudId: string; issueAri: string }) => ({
		queries: {
			mainGoalsPopupContentQuery: {
				parameters: mainGoalsPopupContentQueryParameters,
				variables: {
					cloudId,
					issueAri,
				},
				options: {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					fetchPolicy: 'store-or-network' as PreloadFetchPolicy,
				},
			},
		},
	}),
});
