import { styled } from '@compiled/react';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	InlineEditContainer as BaseInlineEditContainer,
	READ_VIEW_CONTAINER_SELECTOR,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineEditContainer = styled(BaseInlineEditContainer)<{
	isEditable: boolean;
	hasValue: boolean;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		paddingRight: `${gridSize}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			padding: ({ hasValue }) => (hasValue ? '4px 0' : '7px 0'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) =>
		!isEditable
			? {
					marginLeft: 0,
					'& > div': {
						margin: '14px 0 6px',
					},
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					[READ_VIEW_CONTAINER_SELECTOR]: {
						left: 0,
						'& > div > div > div > div': {
							padding: 0,
						},
					},
				}
			: undefined,
);
