import React, { useCallback, useEffect } from 'react';

import { toJSON } from '@atlaskit/editor-common/utils';
// eslint-disable-next-line @atlaskit/editor/warn-no-restricted-imports
import type { EditorActions } from '@atlaskit/editor-core';
import { ComposableEditor } from '@atlaskit/editor-core/composable-editor';
import { EditorContext } from '@atlaskit/editor-core/editor-context';
import { usePreset } from '@atlaskit/editor-core/use-preset';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import { AllSelection } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { TextSerializer } from '@atlaskit/renderer/text-serializer';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import type { AIPromptPresetOptions } from '../presets/prompt';
import { createAIPromptPreset } from '../presets/prompt';

type CreatePromptEditorOptions = Omit<AIPromptPresetOptions, 'placeholder' | 'onSave'>;

// PromptEditorProps is mirrored at `packages/editor/generative-ai-modal/src/ui/components/PromptEditorWrapper/PromptEditorWrapper.tsx`
// since editor-ai-injected-editors cannot be a dependency of generative-ai-modal or editor-plugin-ai.
type PromptEditorProps = {
	autoFocus?: boolean;
	defaultValue?: JSONDocNode;
	placeholder?: string;
	onInputChange?: (input: string) => void;
	onADFChange?: (input: JSONDocNode) => void;
	setFocusHandlers?: (focusFn?: () => boolean, blurFn?: () => boolean) => void;
	/**
	 * Using separate setter function for clear as focus and blur
	 *  is set from editorApi while clear from editorActions.
	 * They both can be available at different time.
	 * So it's safest to have separate setter function.
	 */
	setClearHandler?: (clearFn?: () => boolean) => void;
	/**
	 * Having links in Editor is giving false impression to customers that
	 *  we have started supporting links, but we haven't yet as backend will
	 *  still point to xp-gen-ai and leading to hallucinations.
	 * So we have tied enabling links to ConvoAI FF through this prop.
	 */
	enableLinks?: boolean;
};

type EditorWrapperProps = {
	editorProps: PromptEditorProps;
	options: CreatePromptEditorOptions;
};

const EditorWrapper = (props: EditorWrapperProps) => {
	const appearance = 'chromeless';
	const { editorProps, options } = props;
	const {
		onInputChange,
		onADFChange,
		autoFocus,
		defaultValue,
		enableLinks,
		setFocusHandlers,
		setClearHandler,
	} = editorProps;

	const onChange = useCallback(
		(editorView: EditorView) => {
			if (onADFChange) {
				onADFChange(toJSON(editorView.state.doc));
			}
			if (onInputChange) {
				const textSerializer = TextSerializer.fromSchema(editorView.state.schema);
				const text = textSerializer.serializeFragment(editorView.state.doc.content);
				onInputChange(text);
			}
		},
		[onInputChange, onADFChange],
	);

	const onSave = useCallback(
		(editorView: EditorView) => {
			onChange(editorView);
		},
		[onChange],
	);

	// We could have created preset outside PromptEditor component,
	//  but currently placeholder prop ComposableEditor is not working.
	// We have to pass placeholder to placeholder plugin.
	// That's why using usePreset, who memoize preset.
	const { preset, editorApi } = usePreset(
		() =>
			createAIPromptPreset({
				...options,
				placeholder: {
					placeholder: editorProps.placeholder,
				},
				onSave,
				enableLinks,
			}),
		[options, editorProps.placeholder],
	);

	const onEditorReady = useCallback(
		(editorActions: EditorActions) => {
			if (setClearHandler) {
				setClearHandler(() => editorActions.clear());
			}
			if (editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')) {
				const editorView = editorActions._privateGetEditorView();
				if (editorView) {
					if (!editorActions.isDocumentEmpty()) {
						editorView.dispatch(
							editorView.state.tr.setSelection(new AllSelection(editorView.state.doc)),
						);
					}
				}
			}
		},
		[setClearHandler],
	);

	useEffect(() => {
		if (setFocusHandlers) {
			/**
			 * We need to expose clear function through ref because
			 *  changing defaultValue does not clear editor.
			 */
			setFocusHandlers(editorApi?.core?.actions.blur, editorApi?.core?.actions.focus);
		}
	}, [editorApi, setFocusHandlers]);

	return (
		<ComposableEditor
			appearance={appearance}
			minHeight={20}
			preset={preset}
			shouldFocus={
				editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') || autoFocus
			}
			defaultValue={defaultValue}
			onChange={onChange}
			// We will remove use of onEditorReady when editorApi provides a matching "clear()" action.
			onEditorReady={onEditorReady}
		/>
	);
};

export function createPromptEditor(options: CreatePromptEditorOptions) {
	return (props: PromptEditorProps): JSX.Element => {
		const editorProps = {
			editorProps: props,
			options,
		};

		return (
			<EditorContext>
				<EditorWrapper {...editorProps} />
			</EditorContext>
		);
	};
}
