import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

export const readViewContainerSelectorName =
	'jira-issue-view-single-line-text-inline-edit-view-read-view-container';
const READ_VIEW_CONTAINER_COMPONENT_SELECTOR = `[data-component-selector="${readViewContainerSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadViewContainer = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	font: token('font.body'),
	wordBreak: 'break-word',
	position: 'relative',
	width: '100%',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& .smart-link-title-wrapper': {
		lineHeight: token('space.300', '24px'),
	},
});

const nonEditableStyles = {
	margin: 0,
	'& > div': {
		marginTop: 0,
		marginBottom: 0,
		marginLeft: 0,
		marginRight: token('space.100', '8px'),

		paddingTop: token('space.025', '2px'),
		paddingBottom: token('space.025', '2px'),
		paddingLeft: 0,
		paddingRight: token('space.025', '2px'),
	},
	[READ_VIEW_CONTAINER_COMPONENT_SELECTOR]: {
		minHeight: token('space.300', '28px'),
		alignItems: 'center',
	},
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineEditContainer = styled.div<{ isEditable: boolean }>(
	{
		marginLeft: token('space.negative.100', '-8px'),
		marginTop: token('space.negative.100', '-8px'),
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			minHeight: token('space.400', '32px'),
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: token('space.075', '6px'),
			paddingRight: 0,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => !isEditable && nonEditableStyles,
);
