import { defineMessages } from 'react-intl-next';

export default defineMessages({
	bulletListSummarize: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.bulletListSummarize',
		defaultMessage: 'Try "Summarize the selected text into bullet points"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	translateSpanish: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.translateSpanish',
		defaultMessage: 'Try "Translate to Spanish"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	improveClarity: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.improveClarity',
		defaultMessage: 'Try "Improve the clarity of this section"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	formattedTable: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.formattedTable',
		defaultMessage: 'Try "Turn this list into a formatted table"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	simplifyJargon: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.simplifyJargon',
		defaultMessage: 'Try "Simplify this technical jargon for a general audience"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	actionPlan: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.actionPlan',
		defaultMessage: 'Try "Create an action plan based on these notes"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	dynamicEngaging: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.dynamicEngaging',
		defaultMessage: 'Try "Make this content more engaging and dynamic"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	highlightRisks: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.highlightRisks',
		defaultMessage: 'Try "Highlight the key risks in this plan"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	generateFaqs: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.generateFaqs',
		defaultMessage: 'Try "Generate FAQs from the above information"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	actionCheckboxes: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.actionCheckboxes',
		defaultMessage: 'Try "Extract the key actionables as action checkboxes"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	keyPointsTodo: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.keyPointsTodo',
		defaultMessage: 'Try "Summarize the key points and create a to-do list"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	decisionLog: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.decisionLog',
		defaultMessage: 'Try "Convert this discussion into a decision log"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	termsGlossary: {
		id: 'fabric.editor.ai.screens.UserInputCommandPalette.promptPlaceholder.promptExamples.termsGlossary',
		defaultMessage: 'Try "Create a glossary of terms from this document"',
		description: 'An example of a quality prompt in Editor AI modal',
	},
	timeline: {
		id: 'fabric.editor.ai.screens.UserInputCommamdPalette.promptPlaceholder.promptExamples.timeline',
		defaultMessage: 'Try "Convert this information into a timeline with dates"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	beginnerAudience: {
		id: 'fabric.editor.ai.screens.UserInputCommamdPalette.promptPlaceholder.promptExamples.beginnerAudience',
		defaultMessage: 'Try "Make this content suitable for a beginner audience"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	openQuestions: {
		id: 'fabric.editor.ai.screens.UserInputCommamdPalette.promptPlaceholder.promptExamples.openQuestions',
		defaultMessage: 'Try "Identify and list any open questions"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	missingInformation: {
		id: 'fabric.editor.ai.screens.UserInputCommamdPalette.promptPlaceholder.promptExamples.missingInformation',
		defaultMessage: 'Try "Identify and list any missing information needed"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	readerQuestions: {
		id: 'fabric.editor.ai.screens.UserInputCommamdPalette.promptPlaceholder.promptExamples.readerQuestions',
		defaultMessage: 'Try "Create a list of questions a reader might have about this content"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	stepsWorkflow: {
		id: 'fabric.editor.ai.screens.UserInputCommamdPalette.promptPlaceholder.promptExamples.stepsWorkflow',
		defaultMessage: 'Try "Convert this into a step-by-step workflow"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
	actionableFeedback: {
		id: 'fabric.editor.ai.screens.UserInputCommamdPalette.promptPlaceholder.promptExamples.actionableFeedback',
		defaultMessage:
			'Try "Provide concise, specific and actionable feedback on this draft from an editor\'s perspective"',
		description: 'A tip for writing prompts in Editor AI modal',
	},
});
