/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { N20, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// TODO: Remove during platform_editor_ai_command_palette_post_ga cleanup
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const footerStyles = css({
	display: 'flex',
	flexWrap: 'wrap',
	padding: `${token('space.050', '4px')} ${token('space.150', '12px')}`,
	gap: token('space.050', '4px'),
	borderTopLeftRadius: 'unset',
	borderTopRightRadius: 'unset',
	backgroundColor: token('color.background.accent.gray.subtlest', N20),
	alignItems: 'center',
	justifyContent: 'space-between',
});

// TODO: Remove during platform_editor_ai_command_palette_post_ga cleanup
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const footerInformationStyles = css({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	minWidth: 0,
	minHeight: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > p, & > div:first-child': {
		font: token('font.body.small'),
		color: token('color.text.subtle', N500),
		marginTop: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		marginTop: token('space.050', '4px'),
		marginRight: token('space.050', '4px'),
		marginBottom: token('space.050', '4px'),
	},
});
