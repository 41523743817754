/**
 * @generated SignedSource<<69f9d4513b47717b7de06dde6aeaa0f2>>
 * @relayHash d05dfebd57ab79cd72544e16a8f8f1df
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b9201a7779d47e5d2e26d25dedd2c29b66dbe77b9b47298cb39f7449efb451c2

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { indexOld_DesignPanelQuery } from './indexOld_DesignPanelQuery.graphql';

const node: PreloadableConcreteRequest<indexOld_DesignPanelQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "b9201a7779d47e5d2e26d25dedd2c29b66dbe77b9b47298cb39f7449efb451c2",
    "metadata": {},
    "name": "indexOld_DesignPanelQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
