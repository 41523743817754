import { fg } from '@atlaskit/platform-feature-flags';
import { checkAssistanceServiceFreeGenerateFg } from '@atlassian/editor-ai-common/utils/check-assistance-service-fg';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../../config-items/slice-or-node-to-markdown';
import { streamConvoAI } from '../../../provider/prompt-requests/convo-ai';
import type { ContextList } from '../../../provider/prompt-requests/types';
import { streamXPGenAI } from '../../../provider/prompt-requests/xp-gen-ai';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

const SELECTION_TYPE = 'range';

export const generateFromSelectionConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.FREE_GENERATE,
	title: messages.title,
	description: messages.description,
	selectionType: SELECTION_TYPE,
	getInitialContext: ({ editorView, positions, intl, updateIdMap, mentionMap }) => {
		const selection = sliceOrNodeToMarkdown({
			slice: editorView.state.doc.slice(positions[0], positions[1]),
			editorView,
			convertTo: 'markdown-plus',
			updateIdMap,
			selectionType: SELECTION_TYPE,
			mentionMap,
		});

		const document = sliceOrNodeToMarkdown({
			node: editorView.state.doc,
			editorView,
			convertTo: 'markdown-plus',
			updateIdMap,
			selectionType: SELECTION_TYPE,
			mentionMap,
		});

		return {
			selection: selection.markdown,
			document: document.markdown,
			userLocale: intl.locale,
			intentSchemaId: 'custom_with_interrogation_intent_schema.json',
			contentStatistics: selection.contentStatistics,
		};
	},
	promptHint: messages.promptHint,
	// No prompt label is required -- as this is intended to be set via the
	// the baseGenerate
	triggerPromptRequest({
		promptInput,
		initialContext,
		latestPromptResponse,
		editorSchema,
		analyticsContext,
	}) {
		// This config item is overloaded to support both the interrogation prompt and the free generate prompt
		// we distinguish which one we're in by checking if we have a latestPromptResponse
		let contextList: ContextList;

		if (latestPromptResponse) {
			// If we have a latestPromptResponse, we don't want to add the document to the contextList
			// as it's an interrogation prompt
			contextList = [
				{
					type: 'ADF_MARKDOWN_V1',
					entity: latestPromptResponse,
					// when stringifying -- this will be stripped when the value is undefined
					relationship: 'PREVIOUS_RESPONSE',
				},
			];
		} else {
			contextList = [
				{
					type: 'ADF_MARKDOWN_V1',
					entity: initialContext.selection,
					relationship: 'SELECTION',
				},
			];
		}

		// Use Convo AI api for free generate if flag is on and it's not interrogate
		// Have kept this separate to interrogate in case they need to be toggled separately
		// for whatever reason (eg. generate works well but interrogate does not)
		if (!latestPromptResponse && checkAssistanceServiceFreeGenerateFg()) {
			return streamConvoAI({
				aiSessionId: analyticsContext?.aiSessionId,
				customPrompt: promptInput!,
				userLocale: initialContext.userLocale,
				intentSchemaId: 'custom_free_generate_intent_schema.json',
				editorSchema,
				currentSelection: initialContext.selection,
				fullDocument: initialContext.document,
			});
		}

		// Use Convo AI api for interrogate if flag is on
		if (latestPromptResponse && fg('platform_editor_ai_interrogate_with_convo_ai')) {
			return streamConvoAI({
				aiSessionId: analyticsContext?.aiSessionId,
				customPrompt: promptInput!,
				userLocale: initialContext.userLocale,
				intentSchemaId: 'custom_with_interrogation_intent_schema.json',
				editorSchema,
				draftSelectedContentOverride: latestPromptResponse,
				currentSelection: initialContext.selection,
				fullDocument: initialContext.document,
			});
		}

		return streamXPGenAI({
			customPrompt: promptInput!,
			userLocale: initialContext.userLocale,
			editorSchema,
			contextList: contextList,
			intentSchemaId: latestPromptResponse
				? 'custom_with_interrogation_intent_schema.json'
				: 'custom_free_generate_intent_schema.json',
		});
	},
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	actions: [
		createInsertBelow({ appearance: 'secondary' }),
		createReplace({ appearance: 'primary' }),
	],
	getBackendModel: (isInterrogate) => {
		if (isInterrogate && fg('platform_editor_ai_interrogate_with_convo_ai')) {
			return 'assistance-service';
		}
		if (!isInterrogate && checkAssistanceServiceFreeGenerateFg()) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});
