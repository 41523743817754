import React from 'react';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { Text } from '@atlaskit/primitives';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	FireScreenAnalytics,
	ContextualAnalyticsData,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { SPOTLIGHT_SCREEN_NAME, SPOTLIGHT_ID, SPOTLIGHT_TARGET_ID } from './constants.tsx';
import messages from './messages.tsx';

export const AddFormSpotlight = ({ onAcknowledge }: { onAcknowledge: () => void }) => {
	const { formatMessage } = useIntl();

	return (
		<SpotlightTransition>
			<ContextualAnalyticsData sourceName={SPOTLIGHT_SCREEN_NAME} sourceType={MODAL}>
				<JiraSpotlight
					target={SPOTLIGHT_TARGET_ID}
					actions={[
						{
							text: formatMessage(messages.ok),
							onClick: onAcknowledge,
						},
					]}
					dialogWidth={336}
					key={SPOTLIGHT_ID}
					targetBgColor={token('elevation.surface.raised', N0)}
					messageId="issue-view-foundation.quick-add.add-form-button.add-form-spotlight.jira-spotlight"
					messageType={fg('bug-fix-add-form-button-onboarding') ? 'transactional' : 'engagement'}
				>
					<Text as="p">{formatMessage(messages.message)}</Text>
				</JiraSpotlight>
				<FireScreenAnalytics />
			</ContextualAnalyticsData>
		</SpotlightTransition>
	);
};
