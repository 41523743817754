// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const suggestionsContainerStyles = css({
	width: '100%',
	height: '100%',
	margin: '0px',
	padding: '0px',
	listStyle: 'none',
	display: 'inline-block',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'li:first-of-type': {
		borderTopLeftRadius: 'inherit',
		borderTopRightRadius: 'inherit',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'li:last-of-type': {
		borderBottomLeftRadius: 'inherit',
		borderBottomRightRadius: 'inherit',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const suggestionButtonStyles = css({
	width: '100%',
	minHeight: '40px',
	padding: `${token('space.075', '6px')} ${token('space.150', '12px')} ${token(
		'space.075',
		'6px',
	)} ${token('space.150', '12px')}`,
	marginTop: '0px',
	border: 0,
	borderTopLeftRadius: 0,
	borderTopRightRadius: 0,
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'start',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	gap: '13px',
	textAlign: 'left',
	fontWeight: 400,
	lineHeight: '18px',
	color: token('color.text', '#172b4d'),
	fontFamily: 'inherit',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: 'inherit',
	background: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not([disabled])': {
		cursor: 'pointer',
		'&:active': {
			backgroundColor: token('color.background.neutral.subtle.pressed', '#091E4224'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"&[data-highlighted='true']": {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:not(:active)': {
				backgroundColor: token('color.background.neutral.subtle.hovered', '#091E420F'),
			},
		},
		'&:focus': {
			outline: `2px solid ${token('color.border.focused', '#4C9AFF')}`,
			outlineOffset: token('space.negative.025', '-2px'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&[disabled]': {
		background: token('color.background.disabled', '#091e420a'),
		color: token('color.text.disabled', '#A5ADBA'),
		cursor: 'not-allowed',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
export const rovoSuggestionButtonStyles = css(suggestionButtonStyles, {
	minHeight: '46px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const enterIconStyles = css({
	// with '&', the following selector applies only if this icon style is under a highlighted button
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	"button[data-highlighted='true'] &": {
		display: 'inline',
	},
	display: 'none',
	padding: '5px 5px 3px 5px',
	borderRadius: '3px',
	color: token('color.text.subtle', '#44546F'),
	backgroundColor: token('color.background.neutral.subtle.hovered', '#091E420F'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const suggestionListItemStyles = css({
	marginTop: '0px',
	listStyle: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const suggestionLabelStyles = css({
	hyphens: 'auto',
	wordWrap: 'break-word',
	flex: 1,
	minWidth: '90px',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const suggestionDescriptionStyles = css({
	hyphens: 'auto',
	wordWrap: 'break-word',
	flex: 1,
	minWidth: '90px',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtlest', '#626F86'),
	paddingTop: token('space.050', '4px'),
	display: 'inline-block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const tellAILabelStyles = css({
	fontWeight: 500,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const suggestionsHeadingStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		margin: '0px',
		padding: `${token('space.075', '6px')} ${token(
			'space.150',
			'12px',
		)} ${token('space.075', '6px')} ${token('space.150', '12px')}`,
		font: token('font.body.small'),
		fontweight: token('font.weight.bold'),
		textTransform: 'uppercase',
		color: token('color.text.subtle', '#42526E'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const suggestionsGroupHeadingStyles = css({
	// use flex to remove the extra space around children
	display: 'flex',
	margin: '0px',
	padding: `${token('space.150', '12px')} ${token(
		'space.150',
		'12px',
	)} ${token('space.050', '4px')} ${token('space.150', '12px')}`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .list-heading-text': {
		margin: 0,
		font: token('font.body.small'),
		fontweight: token('font.weight.bold'),
		textTransform: 'uppercase',
		color: token('color.text.subtle', '#42526E'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const stickySuggestionsGroupHeadingStyles = css({
	position: 'sticky',
	width: 'inherit',
	top: 0,
	background: token('elevation.surface', '#FFFFFF'),
	zIndex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const suggestionIconStyles = css({
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '24px',
	width: '24px',

	// Increase the size of default icon a little so it looks better
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > .default-icon': {
		height: '24px',
		width: '20px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/use-tokens-typography, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const rovoSuggestionIconStyles = css(suggestionIconStyles, {
	height: '32px',
	width: '32px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css -- Ignored via go/DSP-18766
export const suggestionsGroupStyles = css({
	margin: 0,
	padding: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
export const chevronIconStyles = css({
	padding: '0 4px',
});
