import React from 'react';

import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

type Props = {
	projectKey: string;
};

const AutomationIssueAuditLogPanel: React.FC<Props> = ({ projectKey }) => {
	// TODO: permissions.canAdministerProject will be passed down to a child component in a future PR
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [permissions] = useProjectPermissions(projectKey);
	const accountId: string | null = useAccountId();
	const isSignedIn = accountId !== null;

	const shouldShow = isSignedIn;
	if (!shouldShow) {
		return null;
	}

	return (
		<UFOSegment name="issue-automation-audit-log-panel">
			<div data-testid="issue-view-layout-templates-views.ui.context.visible-hidden.ui.automation-issue-audit-log-panel.automation-issue-audit-log-panel" />
		</UFOSegment>
	);
};

export default AutomationIssueAuditLogPanel;
