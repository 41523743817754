import React, { useCallback, useEffect, useRef, type FC } from 'react';
import uuid from 'uuid';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { Box, Text, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import type { AIEventBaseAttributes } from '@atlassian/ai-analytics/src/events/AIEvents';
import { StreamingStatusBar } from '@atlassian/jira-ai-work-breakdown/src/ui/issue-breakdown-draft-list/streaming-status-bar/index.tsx';
import AIContainer from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-container/index.tsx';
import { AiFooter } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-footer/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	linkConfluencePageAISuggestionsClosed,
	createConfluencePageLinkSuccess,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import {
	issueIdSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	linkedConfluencePagesPagesSelector,
	mentionedConfluencePagesPagesSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/confluence-content-selector.tsx';
import {
	ContextualAnalyticsData,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	JiraIssueRelatedResourcesContainer,
	useRelatedResources,
} from '../controllers/related-resources-context.tsx';
import messages from './messages.tsx';
import { RelatedResourcesDraftList } from './related-resources-draft-list/index.tsx';

export const SuggestRelatedResourcesModal: FC = () => {
	const { formatMessage } = useIntl();

	const [{ isLoadingSuggestions, resourceSuggestions, errorMessage }, { discardAllSuggestions }] =
		useRelatedResources();

	// Unified Event Instrumentation For AI Features
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'aiInteraction initiated');
	}, [createAnalyticsEvent]);

	const areSuggestionsLoaded = resourceSuggestions !== undefined && errorMessage === null;
	const isError = errorMessage !== null;
	useEffect(() => {
		if (areSuggestionsLoaded) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'aiResult viewed');
		} else if (isError) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'aiResult error');
		}
	}, [areSuggestionsLoaded, isError, createAnalyticsEvent]);

	const onDismissSuggestions = useCallback(() => {
		if (isLoadingSuggestions) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'aiInteraction dismissed');
		}
		discardAllSuggestions();
	}, [createAnalyticsEvent, discardAllSuggestions, isLoadingSuggestions]);

	return (
		<SmartCardProvider>
			<AIContainer
				hasNewBorderExperience
				shouldFitContainer
				isShadow
				isLoading={isLoadingSuggestions}
				spacing="none"
				testId="ai-related-resources.ui.ai-container"
			>
				<Box
					testId="ai-related-resources.ui.header"
					xcss={[headerContainerStyles, hoverableHeaderStyles, hoverableStyles]}
				>
					<Box testId="ai-related-resources.ui.button" xcss={headerButtonStyles}>
						<Box as="span" xcss={headerPromptStyles}>
							<Text>{formatMessage(messages.suggestConfluencePages)}</Text>
						</Box>
					</Box>
					<IconButton
						testId="ai-related-resources.ui.cross-button"
						appearance="subtle"
						icon={CrossIcon}
						label={formatMessage(messages.discardSuggestions)}
						onClick={onDismissSuggestions}
						isTooltipDisabled={false}
						tooltip={{ position: 'left' }}
					/>
				</Box>
				{isLoadingSuggestions && (
					<StreamingStatusBar message={formatMessage(messages.generatingPageSuggestions)} />
				)}
				{((!isLoadingSuggestions && resourceSuggestions && resourceSuggestions.length === 0) ||
					errorMessage != null) && (
					<SectionMessage appearance="warning">
						{formatMessage(messages.pageSuggestionsCouldNotBeGenerated)}
					</SectionMessage>
				)}
				<RelatedResourcesDraftList />
				<AiFooter isBeta={false} />
			</AIContainer>
		</SmartCardProvider>
	);
};

const ReduxConnectedRelatedResourcesSweetStateContainer = connect(
	(state) => ({
		issueId: issueIdSelector(state),
		issueKey: issueKeySelector(state),
		linkedConfluencePages: linkedConfluencePagesPagesSelector(state),
		mentionedConfluencePagesPages: mentionedConfluencePagesPagesSelector(state),
	}),
	{
		onCloseAISuggestions: linkConfluencePageAISuggestionsClosed,
		onCreateConfluencePageLinkSuccess: createConfluencePageLinkSuccess,
	},
)(JiraIssueRelatedResourcesContainer);

type RelatedResourcesAttributes = AIEventBaseAttributes & { singleInstrumentationId: string };

const AI_EVENT_ATTRIBUTES: AIEventBaseAttributes = {
	aiFeatureName: 'jiraIssueRelatedResources',
	isAIFeature: 1,
	proactiveGeneratedAI: 0,
	userGeneratedAI: 1,
};

export const SuggestRelatedResourcesModalContainer = () => {
	const singleInstrumentationId = useRef(uuid());
	const attributes: RelatedResourcesAttributes = {
		...AI_EVENT_ATTRIBUTES,
		singleInstrumentationId: singleInstrumentationId.current,
	};

	return (
		<ReduxConnectedRelatedResourcesSweetStateContainer>
			<ContextualAnalyticsData attributes={attributes}>
				<SuggestRelatedResourcesModal />
			</ContextualAnalyticsData>
		</ReduxConnectedRelatedResourcesSweetStateContainer>
	);
};

const headerButtonStyles = xcss({
	background: 'none',
	width: '100%',
	display: 'flex',
	justifyContent: 'baseline',
	alignItems: 'center',
	padding: 'space.200',
});

const headerPromptStyles = xcss({
	borderRadius: token('border.radius.100', '3px'),
	padding: 'space.025',
	paddingTop: '0',
	paddingBottom: '0',
	height: 'space.0',
	width: 'space.100',
	color: 'color.text.subtle',
	background: token('color.background.neutral', '#091E420F'),
	fontWeight: token('font.weight.medium', '400'),
	marginRight: 'space.100',
});

const headerContainerStyles = xcss({
	paddingRight: 'space.150',
	display: 'flex',
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler', '#EBECF0')}`,
	alignItems: 'center',
});

const hoverableStyles = xcss({
	cursor: 'pointer',
});

const hoverableHeaderStyles = xcss({
	':hover': {
		background: token('color.background.neutral.subtle.hovered', '#EBECF0'),
		textDecoration: 'none',
	},
});
