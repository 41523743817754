import React, { useCallback, useEffect, type FC } from 'react';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { Box, Text, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { StreamingStatusBar } from '@atlassian/jira-ai-work-breakdown/src/ui/issue-breakdown-draft-list/streaming-status-bar/index.tsx';
import AIContainer from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-container/index.tsx';
import { AiFooter } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-footer/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { steps, useRelatedIssuesActions, type Step } from '../controllers/context.tsx';
import { useFetchRelatedIssue } from '../services/use-fetch-related-issues/index.tsx';
import messages from './messages.tsx';
import { RelatedIssuesDraftList } from './related-issues-draft-list/index.tsx';

type SuggestRelatedIssuesModalProps = {
	currentStep: Step;
	onDiscardAllSuggestions: () => void;
};

export const SuggestRelatedIssuesModal: FC<SuggestRelatedIssuesModalProps> = ({
	currentStep,
	onDiscardAllSuggestions,
}) => {
	const { formatMessage } = useIntl();
	const { data, error, loading, fetchSuggestion } = useFetchRelatedIssue();
	const { setResourceSuggestion, setErrorMessage } = useRelatedIssuesActions();

	// Our own version of the Unified Event Instrumentation For AI Features
	// Since our AI feature has multiple entrypoints - it is up to the component to implement analytics
	// TODO: AIX-689 add props to the component to allow for custom analytics attributes to be passed in
	// Such as invokedFrom (e.g. improve issue dropdown, similar issues button etc.)
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		if (currentStep === steps.draftDisplay) {
			fetchSuggestion();
			fireTrackAnalytics(createAnalyticsEvent({}), 'aiInteraction initiated');
		}
	}, [createAnalyticsEvent, fetchSuggestion, currentStep]);

	useEffect(() => {
		if (data) {
			setResourceSuggestion(data);
			fireTrackAnalytics(createAnalyticsEvent({}), 'aiResult viewed');
		} else if (error) {
			setErrorMessage(error);
			fireTrackAnalytics(createAnalyticsEvent({}), 'aiResult error');
		}
	}, [setResourceSuggestion, setErrorMessage, createAnalyticsEvent, data, error]);

	const onDismissSuggestions = useCallback(() => {
		onDiscardAllSuggestions();
		fireTrackAnalytics(createAnalyticsEvent({}), 'aiInteraction dismissed');
	}, [createAnalyticsEvent, onDiscardAllSuggestions]);

	switch (currentStep) {
		case steps.draftDisplay:
			return (
				<SmartCardProvider>
					<AIContainer
						hasNewBorderExperience
						shouldFitContainer
						isShadow
						isLoading={loading}
						spacing="none"
						testId="ai-related-issues.ui.ai-container"
					>
						<Box
							testId="ai-related-issues.ui.header"
							xcss={[headerContainerStyles, hoverableHeaderStyles, hoverableStyles]}
						>
							<Box testId="ai-related-issues.ui.button" xcss={headerButtonStyles}>
								<Box as="span" xcss={headerPromptStyles}>
									<Text>{formatMessage(messages.findSimilarIssues)}</Text>
								</Box>
							</Box>
							<IconButton
								testId="ai-related-issues.ui.cross-button"
								appearance="subtle"
								icon={CrossIcon}
								label={formatMessage(messages.discardSuggestions)}
								onClick={onDismissSuggestions}
								isTooltipDisabled={false}
								tooltip={{ position: 'left' }}
							/>
						</Box>
						{loading && (
							<StreamingStatusBar message={formatMessage(messages.generatingSuggestions)} />
						)}
						{error !== undefined && (
							<SectionMessage appearance="warning">
								{formatMessage(messages.pageSuggestionsCouldNotBeGenerated)}
							</SectionMessage>
						)}
						<RelatedIssuesDraftList />
						<AiFooter isBeta={false} />
					</AIContainer>
				</SmartCardProvider>
			);
		default:
			return null;
	}
};

const headerButtonStyles = xcss({
	background: 'none',
	width: '100%',
	display: 'flex',
	justifyContent: 'baseline',
	alignItems: 'center',
	padding: 'space.200',
});

const headerPromptStyles = xcss({
	borderRadius: token('border.radius.100', '3px'),
	padding: 'space.025',
	paddingTop: '0',
	paddingBottom: '0',
	height: 'space.0',
	width: 'space.100',
	color: 'color.text.subtle',
	background: token('color.background.neutral', '#091E420F'),
	fontWeight: token('font.weight.medium', '400'),
	marginRight: 'space.100',
});

const headerContainerStyles = xcss({
	paddingRight: 'space.150',
	display: 'flex',
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler', '#EBECF0')}`,
	alignItems: 'center',
});

const hoverableStyles = xcss({
	cursor: 'pointer',
});

const hoverableHeaderStyles = xcss({
	':hover': {
		background: token('color.background.neutral.subtle.hovered', '#EBECF0'),
		textDecoration: 'none',
	},
});
