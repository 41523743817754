import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { ui_designCard_DesignCard$key } from '@atlassian/jira-relay/src/__generated__/ui_designCard_DesignCard.graphql';
import { lazy } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyDesignCard = lazy(() =>
	import(/* webpackChunkName: "async-design-card" */ './index').then((module) => module.DesignCard),
);

type Props = {
	modalPage: number;
	lastViewed?: string;
	designCard: ui_designCard_DesignCard$key;
	onSetDesignToUnlink?: () => void;
};

export const DesignCard = ({ modalPage, lastViewed, designCard, onSetDesignToUnlink }: Props) => (
	<JSErrorBoundary
		teamName="helios-headcoach"
		packageName="jiraDesignCard"
		id="jiraDesignCardAsync"
		fallback="unmount"
		sendToPrivacyUnsafeSplunk
	>
		<Placeholder name="design-card" fallback={null}>
			<LazyDesignCard
				modalPage={modalPage}
				lastViewed={lastViewed}
				designCard={designCard}
				onSetDesignToUnlink={onSetDesignToUnlink}
			/>
		</Placeholder>
	</JSErrorBoundary>
);
