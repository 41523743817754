import React, { useState, useCallback, memo, useRef } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldFlags } from '@atlassian/jira-issue-field-base/src/services/field-flags-service/index.tsx';
import {
	type InlineEditContainerProps,
	InlineEditContainerExperiment,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';

import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { useIsMounted } from '@atlassian/jira-platform-react-hooks-use-is-mounted/src/common/utils/index.tsx';
import { fireUIAnalytics, AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ProjectOption, Option } from '../common/types.tsx';
import { mapDataToOptions, mapDataToOption, mapOptionToData } from '../common/utils.tsx';
import { isStopLoadingFieldConfigTwiceEnabled } from '../feature-flags.tsx';
import { useProjectField } from '../services/index.tsx';
import { ProjectCustomFieldEdit } from './edit/async-project-cf/index.tsx';
import { ProjectEditView } from './edit/options-select-edit/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import { ProjectReadView } from './view/index.tsx';

export const actionSubject = 'projectInlineEdit';

export const FieldInlineEditStateLessWithAnalytics = AnalyticsEventToProps('ProjectPicker', {
	onChange: 'changed',
})(FieldInlineEditStateLess);

export default memo<Props>((props: Props) => {
	const {
		fieldKey,
		issueKey,
		onCancel,
		onEdit,
		onEscape,
		onConfirm,
		onEnter,
		readView,
		editView,
		noValueText,
		...rest
	} = props;

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const { formatMessage } = useIntl();

	const { onFailure: showErrorFlag } = useFieldFlags();
	// Track mounted state of edit view for async operations
	const isMounted = useIsMounted();

	const onFailure = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
		(error) => {
			showErrorFlag({
				error,
				title: messages.errorTitle,
				description: messages.errorMessage,
			});

			if (isMounted.current) {
				setIsEditing(true);
			}
		},
		[isMounted, showErrorFlag],
	);

	const [{ value, error, fieldConfig }, { resetError, saveValue }] = useProjectField({
		fieldKey,
		issueKey: toIssueKey(issueKey),
		onFailure,
	});

	const updatedValue = useRef<ProjectOption | null>(value);
	const allowedValues = fieldConfig?.allowedValues || [];
	const autoCompleteUrl = fieldConfig?.autoCompleteUrl ?? '';
	const isFieldEditable = fieldConfig?.isEditable ?? false;

	const save = useCallback(
		(newValue: ProjectOption | null, analyticsEvent: UIAnalyticsEvent) => {
			if (newValue !== value) {
				saveValue(newValue, null, analyticsEvent);
			}
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
		},
		[saveValue, value],
	);

	const saveWithoutValue = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (updatedValue.current !== value) {
				saveValue(updatedValue.current, null, analyticsEvent);
			}

			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
		},
		[saveValue, updatedValue, value],
	);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			resetError();
			updatedValue.current = value;
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
		},
		[resetError, value],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[cancel, onCancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(false);
			saveWithoutValue(analyticsEvent);
		},
		[saveWithoutValue],
	);

	const onEditRequested = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape && onEscape(analyticsEvent);
		},
		[onEscape, cancel],
	);

	const onChange = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type.
		(option: Option | null, _, analyticsEvent: UIAnalyticsEvent) => {
			const projectOption = option ? mapOptionToData(option) : option;

			updatedValue.current = projectOption;
			save(projectOption, analyticsEvent);
		},
		[save],
	);

	const onChangeWithoutAnalytics = useCallback(
		(option: Option | null) => {
			const projectOption = option ? mapOptionToData(option) : option;

			resetError();
			updatedValue.current = projectOption;
		},
		[resetError],
	);
	const renderEditView = () => {
		if (editView !== undefined) return editView;

		const dataValue = updatedValue.current;
		const optionValue = dataValue === null ? null : mapDataToOption(dataValue);

		const shouldShowCustomProjectField = isStopLoadingFieldConfigTwiceEnabled()
			? autoCompleteUrl !== ''
			: true;

		return shouldShowCustomProjectField ? (
			<ProjectCustomFieldEdit
				autoFocus
				blurInputOnSelect
				fetchSuggestionsOnFocus
				hideSelectedOptions
				openMenuOnFocus
				spacing="compact"
				autoCompleteUrl={autoCompleteUrl}
				fieldId={fieldKey}
				isInvalid={!!error}
				onChange={onChangeWithoutAnalytics}
				value={optionValue}
			/>
		) : (
			<ProjectEditView
				autoFocus
				isInvalid={!!error}
				placeholder={formatMessage(messages.placeholder)}
				onChange={onChange}
				options={mapDataToOptions(allowedValues)}
				value={optionValue}
			/>
		);
	};

	const renderReadView = () =>
		readView !== undefined ? readView : <ProjectReadView value={value} noValueText={noValueText} />;

	return (
		// @ts-expect-error - TS2322 - Type '{ children: any[]; isEditing: boolean; hasValue: boolean; isEditable: boolean; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { ...; }, any>, any, any>> & Readonly<...> & Readonly<...>'.
		<ProjectInlineEditContainer
			isEditing={isEditing}
			hasValue={!!value}
			isEditable={isFieldEditable}
		>
			<FieldInlineEditStateLessWithAnalytics
				testId="issue-field-project.ui.inline-edit-field"
				actionSubject={actionSubject}
				isEditable={isFieldEditable}
				isEditing={isEditing}
				readView={renderReadView()}
				editView={isFieldEditable ? renderEditView() : null}
				onConfirm={onConfirmRequest}
				onCancel={onCancelRequest}
				onEdit={onEditRequested}
				onEscape={onEscapeRequest}
				areActionButtonsHidden
				{...rest}
			/>
		</ProjectInlineEditContainer>
	);
});

const PROJECT_READ_VIEW_CONTAINER_SELECTOR =
	'[data-component-selector="project-read-view-container"]';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectInlineEditContainer = styled<InlineEditContainerProps>(InlineEditContainerExperiment)(
	{},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable, hasValue }: InlineEditContainerProps) =>
		!isEditable &&
		hasValue && {
			marginLeft: 0,

			[`${PROJECT_READ_VIEW_CONTAINER_SELECTOR}`]: {
				lineHeight: 1,
				padding: `${token('space.050', '4px')} 0`,
			},

			// NonEditableMargin overrides
			'& > div': {
				margin: `10px 0 ${token('space.025', '2px')} ${token('space.negative.025', '-2px')}`,
			},
		},
);
