import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const pageSegmentLoad = createJpdExperience(
	new UFOExperience('jpd.fields-sidebar.load', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	}),
);

const fieldToggle = createJpdExperience(
	new UFOExperience('jpd.fields-sidebar.field-toggle', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const fieldHide = createJpdExperience(
	new UFOExperience('jpd.fields-sidebar.field-hide', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const fieldDelete = createJpdExperience(
	new UFOExperience('jpd.fields-sidebar.field-delete', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const fieldRegularCreate = createJpdExperience(
	new UFOExperience('jpd.fields-sidebar.field-regular-create', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const fieldFormulaCreate = createJpdExperience(
	new UFOExperience('jpd.fields-sidebar.field-formula-create', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const fieldPlayCreate = createJpdExperience(
	new UFOExperience('jpd.fields-sidebar.field-play-create', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	fieldToggle,
	fieldHide,
	fieldDelete,
	fieldRegularCreate,
	fieldFormulaCreate,
	fieldPlayCreate,
	pageSegmentLoad,
};
