import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { fg } from '@atlassian/jira-feature-gating';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import {
	performDeleteRequest,
	performPostRequest,
	performPutRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Collection } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';
import { extractProjectKeysFromJql } from '../../common/utils/jql.tsx';
import { transformView } from '../views/utils.tsx';
import type {
	CollectionFullResponse,
	CollectionResponse,
	CollectionUpdateRequestBody,
	CollectionCreateRequestBody,
} from './types.tsx';

const generateProjectKeys = (jql: string) => {
	if (fg('polaris-collections-show-projects-list')) {
		return extractProjectKeysFromJql(jql);
	}
	return [];
};

export const createCollection = (
	collectionToCreate: CollectionCreateRequestBody,
): Promise<Collection> =>
	performPostRequest<CollectionResponse>(`${getJpdViewsServicePathPrefix()}/v1/collections`, {
		body: JSON.stringify(collectionToCreate),
	}).then((response) => ({
		uuid: response.uuid,
		name: response.name,
		emoji: response.emoji,
		color: response.color,
		jql: response.jql,
		ownerId: response.owner,
		projectKeys: generateProjectKeys(response.jql),
	}));

export const fetchCollection = (
	collectionUUID: string,
	shouldIncludeViews = false,
): Promise<Collection> =>
	fetchJson<CollectionFullResponse>(
		`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}${shouldIncludeViews ? '?expand=views' : ''}`,
	).then((response) => ({
		uuid: response.uuid,
		name: response.name,
		emoji: response.emoji,
		color: response.color,
		jql: response.jql,
		ownerId: response.owner,
		views: response.views?.map(transformView),
		projectKeys: generateProjectKeys(response.jql),
	}));

export const fetchAllCollections = (): Promise<Collection[]> =>
	fetchJson<{ collections: CollectionResponse[] }>(
		`${getJpdViewsServicePathPrefix()}/v1/collections`,
	).then(({ collections }) =>
		collections.map((collection) => ({
			uuid: collection.uuid,
			name: collection.name,
			emoji: collection.emoji,
			color: collection.color,
			jql: collection.jql,
			ownerId: collection.owner,
			projectKeys: generateProjectKeys(collection.jql),
		})),
	);

export const deleteCollection = async (collectionUUID: string): Promise<void> => {
	await performDeleteRequest(`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}`);
};

export const updateCollection = async (
	collectionUUID: string,
	collectionToUpdate: CollectionUpdateRequestBody,
): Promise<void> => {
	await performPutRequest(`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}`, {
		body: JSON.stringify(collectionToUpdate),
	});
};

export const fetchCollectionsViewsCounts = (
	collectionsUUIDs: string[],
): Promise<Record<string, number | undefined>> =>
	performPostRequest(`${getJpdViewsServicePathPrefix()}/v1/collections/views-count`, {
		body: JSON.stringify({ uuids: collectionsUUIDs }),
	});

export const fetchCollectionDescription = (collectionUUID: string): Promise<ADF | null> =>
	fetchJson(`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/description`);

export const cloneCollection = (collectionUUID: string, newCollectionName: string) =>
	performPostRequest<{ uuid: string }>(
		`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/clone`,
		{
			body: JSON.stringify({ name: newCollectionName }),
		},
	);
