import React from 'react';

import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { EditorAppearance } from '@atlaskit/editor-common/types';
import { type NodeType } from '@atlaskit/editor-prosemirror/model';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import type {
	InvokedFrom,
	InvokedFromTriggerMethod,
} from '../analytics/analytics-flow/analyticsFlowTypes';
import type { EditorPluginAIConfigItemMarkdown } from '../config-items/config-items';
import type { EditorPluginAIProvider, EndExperience } from '../types';

import { type AIExperienceMachineEvent } from './get-ai-experience-service';

export type AIExperienceCommonDataContextData = {
	configItem: EditorPluginAIConfigItemMarkdown;
	editorPluginAIProvider: EditorPluginAIProvider;
	providerFactory: ProviderFactory;
	// all props under here may change based on how we wire things together
	editorView: EditorView;
	/**
	 * For empty selections, the start and end will be the same
	 */
	positions: [start: number, end: number];
	appearance: EditorAppearance;
	/**
	 * Called to end the experience
	 *
	 * Note: the ExperienceApplication will do additional cleanup work after calling this in
	 * its react unmount cleanup.
	 */
	endExperience: EndExperience;
	lastTriggeredFrom?: InvokedFrom;
	triggerMethod?: InvokedFromTriggerMethod;
	sendToAIExperienceMachine: (event: AIExperienceMachineEvent) => void;
	modalRef: React.RefObject<HTMLDivElement>;
	//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
	triggeredFor?: NodeType;
	/**
	 * Engagement Platform Plugin API.
	 *
	 * You don't need to use it directly from here, use `useEngagementPlatform` hook instead.
	 */
	engagementPlatformApi?: {
		/** Starts the Engagement Platform external message with given messageId */
		startMessage: (messageId: string, variationId?: string) => Promise<boolean>;
		/** Stops the Engagement Platform external message with given messageId */
		stopMessage: (messageId: string) => Promise<boolean>;
	};
};

/**
 * We are doing null with non-null assertion operator to trick Typescript to allow null as default value.
 * We don't want to set dummy default values, that's why setting null.
 * This context is used every where using useAIExperienceCommonDataContext hook.
 * We check first if it's value is null, throws error if it's null to prevent random runtime error.
 */
export const AIExperienceCommonDataContext = React.createContext<AIExperienceCommonDataContextData>(
	null!,
);

export const useAIExperienceCommonDataContext = () => {
	const contextData = React.useContext(AIExperienceCommonDataContext);
	if (!contextData) {
		throw new Error('Attempted to access AIExperienceCommonDataContext before its value is set');
	}
	return contextData;
};
