import React, { type ComponentType, useCallback } from 'react';
import memoizeOne from 'memoize-one';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import ChildIssuesPanel from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/index.tsx';
import {
	type ChangeEvent,
	ChangeEventTypes,
} from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/model/on-change-callback.tsx';
import { CLASSIC_PROJECT_EPIC_CHILDREN } from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/model/types.tsx';
import type { Props as ChildIssuesPanelProps } from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/state/types.tsx';
import { EPIC_ISSUES } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { updateAssociatedIssueRequest } from '@atlassian/jira-issue-view-store/src/actions/associated-issue-actions.tsx';
import {
	childIssueAddSuccess,
	childIssuePanelAddClick,
} from '@atlassian/jira-issue-view-store/src/actions/child-panel-actions.tsx';
import { navigateToNewIssue } from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions.tsx';
import { issueIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	childIssuesLimitUrlSelector,
	issueTypeIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import {
	epicLinkFieldKeySelector,
	isCompletedLoadingSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';

import { hierarchyLevelSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/hierarchy-level-selector.tsx';
import { totalChildIssueCountSelector } from '@atlassian/jira-issue-view-store/src/selectors/child-issues-limit-selector.tsx';
import {
	issueInEpicQuickAddClickCountSelector,
	hasMultipleChildPanelsSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/child-panel-ui-selector.tsx';
import { persistedChildrenIssuesSelector } from '@atlassian/jira-issue-view-store/src/selectors/children-issues-selector.tsx';
import { canCreateIssuesInEpicSelector } from '@atlassian/jira-issue-view-store/src/selectors/classic-project-epic-selector.tsx';
import {
	fetchIssueTypesStatusSelector,
	issueTypesForClassicProjectEpicSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/issue-types-selector.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

export const ConnectedComponent = (props: ChildIssuesPanelProps) => {
	const reduxOnChange = props.onChange;

	const issueKey = useIssueKey();
	const [currentValue, { setFieldValue }] = useFieldValue({
		issueKey,
		fieldKey: EPIC_ISSUES,
	});

	const onChange = useCallback(
		(event: ChangeEvent) => {
			switch (event.type) {
				case ChangeEventTypes.CHILD_ISSUE_ADDED: {
					setFieldValue(
						issueKey,
						EPIC_ISSUES,
						currentValue.concat({
							id: event.meta.childIssue.id,
							key: event.meta.childIssue.issueKey,
						}),
					);
					break;
				}
				default:
					break;
			}
			reduxOnChange(event);
		},
		[currentValue, issueKey, reduxOnChange, setFieldValue],
	);

	return (
		<ChildIssuesPanel
			{...props}
			onChange={onChange}
			filterSubtasks={props.hasMultipleChildPanels ? false : null}
		/>
	);
};

const onNavigateToNewIssueMemoized = memoizeOne((onNavigateToNewIssueDispatchProp) =>
	// @ts-expect-error - TS7031 - Binding element 'fromIssueKey' implicitly has an 'any' type. | TS7031 - Binding element 'toIssueKey' implicitly has an 'any' type.

	({ fromIssueKey, toIssueKey }) => {
		onNavigateToNewIssueDispatchProp &&
			onNavigateToNewIssueDispatchProp({ fromIssueKey, toIssueKey });
	},
);

const ReduxConnectedComponent = connect(
	(state: State) => ({
		childIssuesPanelType: CLASSIC_PROJECT_EPIC_CHILDREN,
		parentId: issueIdSelector(state),
		parentIssueTypeId: issueTypeIdSelector(state),
		epicLinkFieldKey: epicLinkFieldKeySelector(state),
		issueTypes: issueTypesForClassicProjectEpicSelector(state),
		issues: persistedChildrenIssuesSelector(state),
		messages,
		quickAddClickCount: issueInEpicQuickAddClickCountSelector(state),
		supportsIssueCreation: canCreateIssuesInEpicSelector(state),
		isIssueViewComplete: isCompletedLoadingSelector(state),
		fetchIssueTypesStatus: fetchIssueTypesStatusSelector(state),
		issueHierarchyLevel: hierarchyLevelSelector(state),
		childIssuesLimitUrl: childIssuesLimitUrlSelector(state)(EPIC_ISSUES),
		hasExceededIssuesLimitAfterLoad: undefined, // TODO: Clean up or change
		totalChildIssueCount: totalChildIssueCountSelector(state),
		hasMultipleChildPanels: hasMultipleChildPanelsSelector(state),
	}),
	(dispatch) => ({
		onChange: (event: ChangeEvent) => {
			switch (event.type) {
				case ChangeEventTypes.CHILD_ISSUE_ADDED:
					dispatch(childIssueAddSuccess(event.meta.childIssue, event.meta.fieldsData));
					break;
				default:
					break;
			}
		},
		onAddChildClick: () => dispatch(childIssuePanelAddClick()),
		// @ts-expect-error - TS7031 - Binding element 'fromIssueKey' implicitly has an 'any' type. | TS7031 - Binding element 'toIssueKey' implicitly has an 'any' type.
		onNavigateToNewIssueDispatchProp: ({ fromIssueKey, toIssueKey }) => {
			if (!__SPA__) {
				dispatch(navigateToNewIssue({ fromIssueKey, toIssueKey }));
			}
		},
		onEditAssociatedIssue: (issueKey?: IssueKey) => {
			dispatch(updateAssociatedIssueRequest(issueKey));
		},
	}),
	(stateProps, dispatchProps) => {
		const { onNavigateToNewIssueDispatchProp, ...restDispatchProps } = dispatchProps;

		return {
			...stateProps,
			...restDispatchProps,
			onNavigateToNewIssue: onNavigateToNewIssueMemoized(onNavigateToNewIssueDispatchProp),
		};
	},
	// @ts-expect-error - TS2345 - Argument of type '(props: ChildIssuesPanelProps) => JSX.Element' is not assignable to parameter of type 'Component<{ onNavigateToNewIssue: ({ fromIssueKey, toIssueKey }: { fromIssueKey: any; toIssueKey: any; }) => void; onChange: (event: ChangeEvent) => void; onAddChildClick: () => { type: "CHILD_ISSUE_PANEL_ADD_CLICKED"; }; ... 12 more ...; totalChildIssueCount: any; }>'.
)(ConnectedComponent);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ReduxConnectedComponent as unknown as ComponentType<{}>;
