import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import messages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import RadioFieldView, { type RadioValueItemShape } from './radio-field-view.tsx';
import {
	InlineEditContainer,
	READ_VIEW_CONTAINER_COMPONENT_SELECTOR,
	ReadViewContainer,
	readViewContainerSelectorName,
} from './radio-inline-edit-view.styled.tsx';

type Props = {
	isEditable?: boolean;
	isEditing?: boolean;
	isMobile?: boolean;
	issueKey?: IssueKey; // TODO - make issueKey mandatory in BENTO-11149,
	isRequired?: boolean;
	options?: RadioValueItemShape[];
	value?: RadioValueItemShape;
	portalElement?: HTMLElement;
	label?: string;
	placeholder?: string;
	noValueText?: string;
	invalidMessage?: string;
	fieldId: string;
	showPinButton?: boolean;
	onChange: (arg1: RadioValueItemShape) => void;
	onEditRequest: () => void;
	onConfirm: () => void;
	onCancel: () => void;
};

export const RadioInlineEditView = ({
	isEditable = false,
	isEditing = false,
	isMobile = false,
	isRequired = false,
	options = [],
	label = '',
	placeholder,
	noValueText,
	invalidMessage,
	value,
	portalElement,
	onChange,
	issueKey,
	fieldId,
	showPinButton,
	onEditRequest,
	onConfirm,
	onCancel,
}: Props) => {
	const { formatMessage } = useIntl();

	const emptyOption = useMemo(
		() => ({
			label: formatMessage(genericMessages.noValue),
		}),
		[formatMessage],
	);

	const getOptions = useCallback(
		() => (!isRequired && value ? [emptyOption, ...options] : options),
		[emptyOption, options, isRequired, value],
	);

	const renderReadViewOld = useCallback(() => {
		if (value) {
			return value.label;
		}
		return <Text color="color.text.subtlest">{noValueText}</Text>;
	}, [noValueText, value]);

	const renderReadView = useCallback(
		() => (
			<ReadViewContainer data-component-selector={readViewContainerSelectorName}>
				{renderReadViewOld()}
			</ReadViewContainer>
		),
		[renderReadViewOld],
	);

	const renderEditView = useCallback(() => {
		if (!isEditable) {
			return null;
		}
		return (
			<RadioFieldView
				portalElement={portalElement}
				value={value}
				options={getOptions()}
				onChange={onChange}
				placeholder={placeholder}
				invalidMessage={invalidMessage}
			/>
		);
	}, [getOptions, invalidMessage, isEditable, onChange, placeholder, portalElement, value]);

	return (
		<FieldWrapper data-testid={`issue.views.field.radio-inline-edit.${fieldId}`}>
			<FieldHeading fieldId={fieldId}>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				{issueKey !== undefined && fieldId !== undefined && (
					<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={label} />
				)}
				{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
			</FieldHeading>
			<SideBySideField isEditing={isEditing}>
				<EnterEscapeHandler onEscape={onCancel}>
					<RadioInlineEditContainer isEditable={isEditable}>
						<FieldInlineEditStateLess
							testId={`issue.views.field.issue-internal-fields.radio-select.${fieldId}`}
							hideActionButtons
							readView={renderReadView}
							editView={renderEditView}
							isEditing={isEditing}
							isEditable={isEditable}
							onEdit={onEditRequest}
							onConfirm={onConfirm}
							onCancel={onCancel}
							readViewFitContainerWidth={!isMobile}
							editButtonLabel={formatMessage(messages.editButtonLabel, {
								fieldName: label,
							})}
							confirmButtonLabel={formatMessage(messages.confirmButtonLabel, {
								fieldName: label,
							})}
							cancelButtonLabel={formatMessage(messages.cancelButtonLabel, {
								fieldName: label,
							})}
						/>
					</RadioInlineEditContainer>
				</EnterEscapeHandler>
			</SideBySideField>
		</FieldWrapper>
	);
};

export default RadioInlineEditView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RadioInlineEditContainer = styled(InlineEditContainer)<{
	isEditable?: boolean;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) =>
		!isEditable && {
			marginLeft: 0,
			/* NonEditableMargin overrides */
			'& > div': {
				marginLeft: 0,
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			[READ_VIEW_CONTAINER_COMPONENT_SELECTOR]: {
				left: 0,
			},
		},
);
