import head from 'lodash/head';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { EntityLimitError } from '@atlassian/jira-polaris-lib-errors/src/common/utils/entity-limit-error/index.tsx';
import { ENTITY_LIMIT_ERROR_TYPE } from '@atlassian/jira-polaris-lib-errors/src/common/utils/entity-limit-error/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { type Props, type State, DEFAULT_OPTION_WEIGHT } from '../../types.tsx';
import { loadFields } from '../load-fields.tsx';

export const addOption =
	(fieldKey: FieldKey, newOption: string) =>
	(
		{ getState, setState, dispatch }: StoreActionApi<State>,
		{ onFieldUpdateFailed, projectId, createAnalyticsEvent, fieldRemote, ideaTypes }: Props,
	): Promise<number | undefined> => {
		const issueTypeId = head(ideaTypes)?.jiraIssueTypeId;

		if (projectId === undefined || issueTypeId === undefined) {
			return Promise.resolve(undefined);
		}

		return fieldRemote
			.addFieldOption({
				fieldKey,
				newOption,
				issueTypeId,
			})
			.then((jiraOptionId: number) => {
				dispatch(loadFields());

				const state = getState();

				const newOptions = [
					...(state?.fields?.[fieldKey]?.options || []),
					{
						id: String(jiraOptionId),
						jiraOptionId: String(jiraOptionId),
						value: newOption,
						weight: DEFAULT_OPTION_WEIGHT,
					},
				];

				const fields = {
					...(state?.fields ?? {}),
					[fieldKey]: {
						...state?.fields?.[fieldKey],
						options: newOptions,
					},
				};

				setState({ fields });
				fireCompoundAnalyticsEvent.FieldConfig.optionCreatedSuccess(createAnalyticsEvent({}), {
					issueFieldKey: fieldKey,
					issueFieldType: fields[fieldKey].type,
					fieldValueId: jiraOptionId,
				});

				return jiraOptionId;
			})
			.catch((err: Error) => {
				fireCompoundAnalyticsEvent.FieldConfig.optionCreatedFailure(createAnalyticsEvent({}));

				let error = err;
				if (err instanceof FetchError) {
					const { message, statusCode } = err;
					if (statusCode === 409) {
						error = new EntityLimitError(
							message,
							[{ error: message }],
							statusCode,
							ENTITY_LIMIT_ERROR_TYPE.OPTIONS_PER_FIELD,
						);
					}
				}

				onFieldUpdateFailed(error);
				return undefined;
			});
	};
