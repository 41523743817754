// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	typesPlaceholder: {
		id: 'issue.views.common.child-issues-panel.inline-create.child-types-select.types-placeholder',
		defaultMessage: 'Issue type',
		description:
			'Placeholder message to show in the issue type dropdown menu when creating a new child issue inline',
	},
	issueTypeSelectorLabel: {
		id: 'issue.views.common.child-issues-panel.inline-create.child-types-select.issue-type-selector-label',
		defaultMessage: 'Select issue type',
		description:
			'Aria label for issue type select component on the inline create child issue in the child issue panel on the issue view',
	},
});
