import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { MOBILE_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import {
	useIssueKey,
	useAnalyticsSource,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import {
	CUSTOM_ITEM_TYPE,
	type LayoutContainerTemplateItem,
} from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import { useLayoutItem } from '@atlassian/jira-issue-view-layout-templates-item-list-services/src/index.tsx';
import { LayoutItem } from '@atlassian/jira-issue-view-layout-templates-layout-item/src/index.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import type {
	src_issueViewLayoutTemplatesItemList_ItemList$key as ItemListFragment,
	src_issueViewLayoutTemplatesItemList_ItemList$data as ItemListFragmentData,
} from '@atlassian/jira-relay/src/__generated__/src_issueViewLayoutTemplatesItemList_ItemList.graphql';

import GlobalSpotlightTargetDeprecated from '@atlassian/jira-servicedesk-common/src/ui/global-spotlight-target/index.tsx';
import { SortableItemList } from './ui/sortable-item-list/index.tsx';
import type { MappedItem, MappedItemsRenderId } from './ui/sortable-item-list/types.tsx';

type Props = {
	layoutItemsDataFragment: ItemListFragment | null | undefined;
	items: LayoutContainerTemplateItem[];
	area: string;
	/** If itemList is sortable, it is required to set a unique sortableId */
	sortableId?: string;
	/** Sorting action to be saved */
	onSortItems?: (idSource: string, idDestination: string, hasMovedUp: boolean) => void;
};
type ArrayElement<TArray> = TArray extends readonly (infer TElement)[] ? TElement : never;

export const ItemList = ({
	items,
	area,
	layoutItemsDataFragment,
	sortableId,
	onSortItems,
}: Props) => {
	const issueKey = useIssueKey();

	let relayFieldsByLegacyId: {
		[nonGlobalFieldId: string]: NonNullable<
			ArrayElement<NonNullable<ItemListFragmentData['fieldFragments']>['edges']>
		>['node'];
	} = {};
	// must-colocate-fragment-spreads doesn't like the memo-HoC-wrapped component (or likely any differently-named HoC wrapped components)
	const data = useFragment<ItemListFragment>(
		graphql`
			fragment src_issueViewLayoutTemplatesItemList_ItemList on JiraIssue
			@argumentDefinitions(
				jsmSentimentCustomFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayPriorityFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayAssigneeFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayNumberFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelaySprintFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelaySingleLineTextFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayDateFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayProjectFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayDateTimeFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayUrlFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayPeopleAndApproversFieldFlag: { type: "Boolean!", defaultValue: false }
			) {
				fieldFragments: fields {
					edges {
						node {
							... on JiraIssueField {
								fieldId
								...src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal
									@arguments(
										jsmSentimentCustomFieldFlag: $jsmSentimentCustomFieldFlag
										issueViewRelayPriorityFieldFlag: $issueViewRelayPriorityFieldFlag
										issueViewRelayAssigneeFieldFlag: $issueViewRelayAssigneeFieldFlag
										issueViewRelayNumberFieldFlag: $issueViewRelayNumberFieldFlag
										issueViewRelaySprintFieldFlag: $issueViewRelaySprintFieldFlag
										issueViewRelaySingleLineTextFieldFlag: $issueViewRelaySingleLineTextFieldFlag
										issueViewRelayDateFieldFlag: $issueViewRelayDateFieldFlag
										issueViewRelayProjectFieldFlag: $issueViewRelayProjectFieldFlag
										issueViewRelayDateTimeFieldFlag: $issueViewRelayDateTimeFieldFlag
										issueViewRelayUrlFieldFlag: $issueViewRelayUrlFieldFlag
										issueViewRelayPeopleAndApproversFieldFlag: $issueViewRelayPeopleAndApproversFieldFlag
									)
							}
						}
					}
				}
			}
		`,
		layoutItemsDataFragment ?? null,
	);
	relayFieldsByLegacyId = (data?.fieldFragments?.edges || []).reduce((acc, fieldEdge) => {
		const field = fieldEdge?.node;
		const fieldId = field?.fieldId;
		if (!!fieldId && !!field) {
			acc[fieldId] = field;
		}
		return acc;
	}, relayFieldsByLegacyId);

	const mappedItems = useLayoutItem(items);

	const [{ value: issueFieldsConfig }] = useIssueFieldConfig(issueKey);

	const lastElement = mappedItems[mappedItems.length - 1];

	const itemArray: MappedItem[] = mappedItems.map((sectionItem: LayoutContainerTemplateItem) => {
		const isLast = sectionItem === lastElement;

		if (sectionItem.type === CUSTOM_ITEM_TYPE) {
			const id = getLayoutItemId(sectionItem);
			return {
				id,
				element: <div key={id}>{sectionItem.item}</div>,
				fieldName:
					issueFieldsConfig === null || issueFieldsConfig === undefined
						? id
						: issueFieldsConfig[id]?.title,
			};
		}

		if (issueFieldsConfig === null || issueFieldsConfig === undefined) return null;

		// @ts-expect-error - TS2339 - Property 'id' does not exist on type 'FieldSectionItem | TabLayout | PanelSectionItem | EcosystemSectionItem | ForgeSectionItem | ... 4 more ... | TabItem'. | TS2339 - Property 'payload' does not exist on type 'FieldSectionItem | TabLayout | PanelSectionItem | EcosystemSectionItem | ForgeSectionItem | ... 4 more ... | TabItem'.
		const { id, type, payload, globalRef = '' } = sectionItem;

		const LayoutItemComponent = (
			<LayoutItem
				key={id}
				id={id}
				type={type}
				area={area}
				payload={payload}
				// @ts-expect-error - Type '{ readonly fieldId: string; readonly " $fragmentSpreads": FragmentRefs<"src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal">; } | null | undefined' is not assignable to type 'src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal$key | null'.
				layoutItemFragment={relayFieldsByLegacyId[id]}
				isLast={isLast}
			/>
		);

		if (globalRef) {
			/* NOTE: These GlobalSpotlightTargets (deprecated) can be used throughout Jira not just within
			 * the current package. They're often used for onboarding or changeboarding
			 * experiences and allow an Atlaskit spotlight to be attached to page elements.
			 * If you need to change this, make sure you check where the target ID is being used
			 * and contact the relevant team as necessary. This Sourcegraph link should help :)
			 * http://go/src/ISSUE_FIELD_SPOTLIGHT_PREFIX|global-spotlight-target-
			 */

			return {
				id,
				element: (
					<GlobalSpotlightTargetDeprecated id={globalRef} key={id}>
						{LayoutItemComponent}
					</GlobalSpotlightTargetDeprecated>
				),
				fieldName: issueFieldsConfig[id]?.title ?? id,
			};
		}

		return {
			id,
			element: LayoutItemComponent,
			fieldName: issueFieldsConfig[id]?.title ?? id,
		};
	});

	const itemListNotNull = itemArray.filter((item) => item !== null);
	const source = useAnalyticsSource();

	const isSortable =
		sortableId !== undefined && itemListNotNull.length > 1 && source !== MOBILE_ISSUE;

	if (!isSortable) {
		const itemList = itemListNotNull.map((item) => item && item.element);

		return <>{itemList}</>;
	}

	return (
		<SortableItemList
			droppableId={sortableId}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			items={itemListNotNull as MappedItemsRenderId[]}
			onSortItems={onSortItems}
		/>
	);
};
