// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createErrorHeading: {
		id: 'issue-view.views.common.web-link-line-card-group.line-card.error-message.create-error-heading',
		defaultMessage: "We couldn't create the web link",
		description:
			'Inline message heading shown when web link creation failed and user clicks on the error icon.',
	},
	deleteErrorHeading: {
		id: 'issue-view.views.common.web-link-line-card-group.line-card.error-message.delete-error-heading',
		defaultMessage: "We couldn't delete the web link",
		description:
			'Inline message heading shown when web link deletion failed and user clicks on the error icon.',
	},
	errorBody: {
		id: 'issue-view.views.common.web-link-line-card-group.line-card.error-message.create-error-body',
		defaultMessage: 'Wait a few moments, then give it another try.',
		description:
			'Inline message body shown when web link creation failed and user clicks on the error icon.',
	},
	retryAction: {
		id: 'issue-view.views.common.web-link-line-card-group.line-card.error-message.retry-action',
		defaultMessage: 'Try again',
		description: 'Action label to retry when a creation or deletion error occurs.',
	},
	cancelAction: {
		id: 'issue-view.views.common.web-link-line-card-group.line-card.error-message.cancel-action',
		defaultMessage: 'Cancel',
		description: 'Action label to cancel when a creation or deletion error occurs.',
	},
	linksPerIssueLimitExceededTitle: {
		id: 'issue-view.views.common.web-link-line-card-group.line-card.links-per-issue-limit-exceeded-title',
		defaultMessage: 'We couldn’t add the link',
		description: 'Title for warning flag when links per issue limit is exceeded',
	},
	linksPerIssueLimitExceededDescription: {
		id: 'issue-view.views.common.web-link-line-card-group.line-card.links-per-issue-limit-exceeded-description',
		defaultMessage: 'We can’t add any more links to this issue as the limit has been reached.',
		description: 'Description for warning flag when links per issue limit is exceeded',
	},
	linksPerIssueLimitExceededNotifyAdminLink: {
		id: 'issue-view.views.common.web-link-line-card-group.line-card.links-per-issue-limit-exceeded.contact-administrator',
		defaultMessage: 'Notify your admin',
		description: 'A link to notify your admin which opens the comment in new tab',
	},
});
