/**
 * @generated SignedSource<<950145456c93a311dca922c2be12c405>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type bulkUnlinkDropdownItem_issueDesignSection$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "bulkUnlinkDropdownItem_issueDesignSection";
};
export type bulkUnlinkDropdownItem_issueDesignSection$key = {
  readonly " $data"?: bulkUnlinkDropdownItem_issueDesignSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"bulkUnlinkDropdownItem_issueDesignSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "bulkUnlinkDropdownItem_issueDesignSection",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "name"
      },
      "action": "THROW",
      "path": "name"
    }
  ],
  "type": "DevOpsDesignProvider"
};

(node as any).hash = "96586eeeccfe981f350a131bffc32e67";

export default node;
