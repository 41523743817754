import React, { useCallback, useMemo } from 'react';
import { graphql, useMutation } from 'react-relay';
import EditorSuccessIcon from '@atlaskit/icon/glyph/editor/success';
import { token } from '@atlaskit/tokens';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldIsEditingActions } from '@atlassian/jira-issue-field-editing-store/src/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import type { assignToMeMutationKeyboardShortcutMutation } from '@atlassian/jira-relay/src/__generated__/assignToMeMutationKeyboardShortcutMutation.graphql';
import { messages } from './messages.tsx';
import type { AssignToMeMutationData } from './types.tsx';

const successIcon = <EditorSuccessIcon primaryColor={token('color.icon.success')} label="" />;

export const useAssignToMeMutation = () => {
	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();

	const { showFlag, dismissFlag } = useFlagsService();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const { setFieldEditingState } = useFieldIsEditingActions();

	const [commit] = useMutation<assignToMeMutationKeyboardShortcutMutation>(graphql`
		mutation assignToMeMutationKeyboardShortcutMutation(
			$input: JiraUpdateSingleSelectUserPickerFieldInput!
		) @raw_response_type {
			jira {
				updateSingleSelectUserPickerField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						user {
							accountId
							name
							picture
							accountStatus
							id
						}
					}
				}
			}
		}
	`);
	// #endregion

	const onAssignToMeOrUnassign = useCallback(
		({ field, newUser, isEditable }: AssignToMeMutationData) => {
			if (!isEditable || !field?.fieldId) {
				return;
			}
			return new Promise<void>((resolve, reject) => {
				const onSubmit = (value: AggUser | null) => {
					issueId &&
						fieldChangeRequested(issueId, field?.fieldId, value, undefined, {
							type: field?.type || '',
							__typename: 'JiraSingleSelectUserPickerField',
						});
				};

				const onSubmitSucceeded = (value: AggUser | null) => {
					issueId &&
						fieldChanged(issueId, field?.fieldId, value, {
							type: field?.type || '',
							__typename: 'JiraSingleSelectUserPickerField',
						});

					showFlag({
						title: formatMessage(newUser ? messages.titleAssignToMe : messages.titleUnassign, {
							issueKey,
						}),
						description: formatMessage(
							newUser ? messages.descriptionAssignToMe : messages.descriptionUnassign,
							{ issueKey },
						),
						icon: successIcon,
						type: 'success',
						isAutoDismiss: true,
						testId:
							'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.assign-to-me-mutation.success-flag',
					});
					resolve();
				};

				const onSubmitFailed = () => {
					issueId && fieldChangeFailed(issueId, field?.fieldId);

					const flagId = showFlag({
						type: 'error',
						title: formatMessage(messages.submitFailedTitle, { fieldName: field?.name }),
						description: formatMessage(messages.submitFailedDescription),
						actions: [
							{
								content: messages.submitFailedTryAgain,
								onClick: () => {
									setFieldEditingState(field?.fieldId, true);
									flagId && dismissFlag(flagId);
								},
							},
						],
						testId:
							'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.assign-to-me-mutation.error-flag',
					});

					reject(new Error('Failed to update field'));
				};

				onSubmit?.(newUser);
				commit({
					variables: {
						input: {
							id: field?.id,
							operation: {
								operation: 'SET',
								id: newUser?.id ?? null,
							},
						},
					},
					onCompleted: (mutationData) => {
						if (mutationData.jira?.updateSingleSelectUserPickerField?.success) {
							onSubmitSucceeded?.(newUser);
						} else {
							onSubmitFailed?.();
						}
					},
					onError() {
						onSubmitFailed?.();
					},
					optimisticResponse: {
						jira: {
							updateSingleSelectUserPickerField: {
								success: true,
								errors: null,
								field: {
									id: field?.id,
									user: newUser
										? {
												id: newUser.id,
												accountId: newUser.accountId,
												accountStatus: newUser.accountStatus,
												name: newUser.name,
												picture: newUser.picture,
												__typename: 'AtlassianAccountUser',
											}
										: null,
								},
							},
						},
					},
				});
			});
		},
		[
			commit,
			issueId,
			fieldChangeRequested,
			fieldChanged,
			showFlag,
			formatMessage,
			issueKey,
			fieldChangeFailed,
			setFieldEditingState,
			dismissFlag,
		],
	);

	return useMemo(() => ({ onAssignToMeOrUnassign }), [onAssignToMeOrUnassign]);
};
