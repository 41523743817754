/**
 * @generated SignedSource<<0b8d90a412bd5944a775b50293f007c1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type moreActionsDropdownMenu_issueDesignSection$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"bulkUnlinkDropdownItem_issueDesignSection">;
  readonly " $fragmentType": "moreActionsDropdownMenu_issueDesignSection";
}>;
export type moreActionsDropdownMenu_issueDesignSection$key = ReadonlyArray<{
  readonly " $data"?: moreActionsDropdownMenu_issueDesignSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"moreActionsDropdownMenu_issueDesignSection">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "moreActionsDropdownMenu_issueDesignSection",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "bulkUnlinkDropdownItem_issueDesignSection"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    }
  ],
  "type": "DevOpsDesignProvider"
};

(node as any).hash = "9b6071f0d88297d06c2ebd0dbd22b0a1";

export default node;
