/* eslint-disable jira/ff/no-preconditioning */
import React, { memo, useMemo, type FunctionComponent } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { AdControlProvider } from '@atlassian/ad-control-toolkit';
import { SuggestRelatedResourcesModalContainer } from '@atlassian/jira-ai-related-resources/src/ui/index.tsx';
import { SuggestRelatedResourcesButton } from '@atlassian/jira-ai-related-resources/src/ui/suggest-related-resources-button/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import {
	useIsAiOptInEnabled,
	useIsJiraIssue,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	ConfluencePage,
	ConfluenceWhiteboard,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { IssueType } from '@atlassian/jira-issue-shared-types/src/common/types/issue-hierarchy-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import { ConfluencePageLineCardGroup } from '@atlassian/jira-issue-view-common-views/src/confluence-content-line-card/ui/confluence-page-line-card-group-view/confluence-page-line-card-group-view.tsx';
import { ConfluenceWhiteboardLineCardGroup } from '@atlassian/jira-issue-view-common-views/src/confluence-content-line-card/ui/confluence-whiteboard-line-card-group-view/confluence-whiteboard-line-card-group-view.tsx';
import LineCardSkeleton from '@atlassian/jira-issue-view-common-views/src/skeleton/item-line-card-group.tsx';
import { Container } from '@atlassian/jira-issue-view-common-views/src/smart-link-content/styled.tsx';
import {
	SectionHeading,
	SectionHeadingTitle,
	SectionHeadingIcons,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { Href, RemoteLinkGlobalId } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ELIGIBLE_ISSUE_TYPES } from '../confluence-placeholder-template-experiment/common/constants.tsx';
import { ConfluenceDummyLinkProvider } from '../confluence-placeholder-template-experiment/controllers/confluence-dummy-link-context/index.tsx';
import AddButton from './add-button/index.tsx';
import ConfluencePageCreateLinkView from './create-link/index.tsx';
import DummyConfluenceLinkCoUseLazy from './dummy-confluence-link-co-use-lazy/index.tsx';
import DummyConfluenceLinkCrossJoin, {
	ConfluenceContentPlaceholderCrossJoin,
} from './dummy-confluence-link-grw-1117-lazy/index.tsx';
import DummyConfluenceLinkViewLazy from './dummy-confluence-link-view-lazy/index.tsx';

const LINKED_PAGES_GROUP_ID = 'linked-confluence-pages';
const LINKED_WHITEBOARDS_GROUP_ID = 'linked-confluence-whiteboards';
const MENTIONED_PAGES_GROUP_ID = 'mentioned-confluence-pages';

export type ConfluenceContentProps = {
	isCreateLinkedPageOpened: boolean;
	isConfluencePageLinksEnabled?: boolean;
	canLinkConfluencePage: boolean;
	linkedPages: (ConfluencePage | FailedRemoteLink)[] | null | undefined;
	linkedPagesCachedCount?: number;
	linkedWhiteboards: ConfluenceWhiteboard[] | null | undefined;
	linkedWhiteboardsCachedCount?: number;
	systemConfluenceAppLinkUrl: string | undefined;
	licensedProducts?: { [key: string]: boolean };
	mentionedPages?: (ConfluencePage | FailedRemoteLink)[];
	mentionedPagesCachedCount?: number;
	onLinkedPageClicked?: () => void;
	onLinkedWhiteboardClicked?: () => void;
	onMentionedPageClicked?: () => void;
	onAuthenticateApplink: (href: Href) => void;
	onDeleteConfluencePageLink: (id: RemoteLinkGlobalId) => void;
	onAddButtonClicked: () => void;
	onShowAISuggestionsClicked?: () => void;
	projectType?: string;
	issueType?: IssueType;
	projectName?: string;
	hasShownIntentToXflowToConfluence?: boolean;
	hasActivatedConfluenceWithinFourteenDays?: boolean;
	isAISuggestionsOpen?: boolean;
};

const ConfluenceContent: FunctionComponent<ConfluenceContentProps> = ({
	isCreateLinkedPageOpened,
	isConfluencePageLinksEnabled = false,
	canLinkConfluencePage,
	linkedPages,
	linkedPagesCachedCount = 0,
	linkedWhiteboards,
	linkedWhiteboardsCachedCount = 0,
	systemConfluenceAppLinkUrl,
	mentionedPages,
	mentionedPagesCachedCount = 0,
	onLinkedPageClicked,
	onLinkedWhiteboardClicked,
	onMentionedPageClicked,
	onAuthenticateApplink,
	onDeleteConfluencePageLink,
	onAddButtonClicked,
	onShowAISuggestionsClicked,
	projectType,
	issueType,
	projectName,
	hasShownIntentToXflowToConfluence,
	hasActivatedConfluenceWithinFourteenDays = false,
	isAISuggestionsOpen = false,
}: ConfluenceContentProps) => {
	const isIssueTypeEligibleForPlaceholderTemplate = useMemo(
		() => (issueType ? ELIGIBLE_ISSUE_TYPES.includes(issueType.name.toLowerCase()) : false),
		[issueType],
	);

	const hasLinkedPages = useMemo(() => linkedPages && linkedPages.length > 0, [linkedPages]);

	const hasMentionedPages = useMemo(
		() => mentionedPages && mentionedPages.length > 0,
		[mentionedPages],
	);

	const hasLinkedWhiteboards = useMemo(
		() => linkedWhiteboards && linkedWhiteboards.length > 0,
		[linkedWhiteboards],
	);
	const hasContent = useMemo(
		() => hasLinkedPages || hasMentionedPages || hasLinkedWhiteboards,
		[hasLinkedPages, hasLinkedWhiteboards, hasMentionedPages],
	);

	const shouldShowConfluenceContent = useMemo(
		() => isCreateLinkedPageOpened || hasContent,
		[hasContent, isCreateLinkedPageOpened],
	);

	const isAiOptInEnabled = fg('jira_ai_powered_issue_related_confluence_resources')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsAiOptInEnabled()
		: false;

	const isJiraIssue: boolean | null = fg('jira_ai_powered_issue_related_confluence_resources')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsJiraIssue()
		: null;

	const canShowAISuggestions = fg('jira_ai_powered_issue_related_confluence_resources')
		? isAiOptInEnabled && canLinkConfluencePage && isJiraIssue
		: false;

	const hasAnyLinkedPageLoaded = useMemo(
		() => linkedPages && linkedPages.length > 0,
		[linkedPages],
	);

	const hasAnyMentionedPageLoaded = useMemo(
		() => mentionedPages && mentionedPages.length > 0,
		[mentionedPages],
	);

	if (isConfluencePageLinksEnabled && shouldShowConfluenceContent) {
		const shouldShowHeading = shouldShowConfluenceContent;
		const shouldShowCoUseExperiment = !hasContent;

		return (
			<UFOSegment name="issue-confluence-pages">
				{shouldShowHeading && (
					<StyledSectionHeading>
						<SectionHeadingTitle>
							<FormattedMessage
								id="issue.details.confluence-content.heading"
								defaultMessage="Confluence content"
								description="Heading for linked Confluence content section."
							/>
						</SectionHeadingTitle>
						<SectionHeadingIcons>
							{canLinkConfluencePage && hasLinkedPages && (
								<AddButton onClick={onAddButtonClicked} />
							)}
							{fg('jira_ai_powered_issue_related_confluence_resources') ? (
								canShowAISuggestions &&
								onShowAISuggestionsClicked && (
									<SuggestRelatedResourcesButton
										onShowAISuggestionsClicked={onShowAISuggestionsClicked}
									/>
								)
							) : (
								<></>
							)}
						</SectionHeadingIcons>
					</StyledSectionHeading>
				)}
				{(hasLinkedPages || hasLinkedWhiteboards) && (
					<GroupContainer>
						{(linkedPages || linkedWhiteboards) && (
							<Container>
								{hasAnyLinkedPageLoaded && linkedPages && (
									<ConfluencePageLineCardGroup
										groupId={LINKED_PAGES_GROUP_ID}
										pages={linkedPages}
										onClick={onLinkedPageClicked}
										onAuthenticateApplink={onAuthenticateApplink}
										onDeleteConfluencePageLink={onDeleteConfluencePageLink}
										systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
									/>
								)}
								{hasLinkedWhiteboards && (
									<ConfluenceWhiteboardLineCardGroup
										groupId={LINKED_WHITEBOARDS_GROUP_ID}
										whiteboards={linkedWhiteboards ?? []}
										onClick={onLinkedWhiteboardClicked}
										systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
									/>
								)}
							</Container>
						)}
						{!linkedPages && <LineCardSkeleton itemCount={linkedPagesCachedCount} />}
						{!linkedWhiteboards && <LineCardSkeleton itemCount={linkedWhiteboardsCachedCount} />}
					</GroupContainer>
				)}
				{hasMentionedPages && (
					<GroupContainer>
						<Heading as="h3" size="xxsmall">
							<FormattedMessage
								id="issue.details.confluence-pages.mentioned-on"
								defaultMessage="mentioned on"
							/>
						</Heading>
						{!mentionedPages && <LineCardSkeleton itemCount={mentionedPagesCachedCount} />}
						{mentionedPages && (
							<Box xcss={containerWrapperStyles}>
								<Container>
									{hasAnyMentionedPageLoaded && mentionedPages && (
										<ConfluencePageLineCardGroup
											groupId={MENTIONED_PAGES_GROUP_ID}
											pages={mentionedPages}
											onClick={onMentionedPageClicked}
											onAuthenticateApplink={onAuthenticateApplink}
											onDeleteConfluencePageLink={onDeleteConfluencePageLink}
											systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
										/>
									)}
								</Container>
							</Box>
						)}
					</GroupContainer>
				)}
				{fg('confluence-placeholder-template-co-use-gate') && shouldShowCoUseExperiment ? (
					<AdControlProvider>
						<DummyConfluenceLinkCoUseLazy
							issueType={issueType}
							projectName={projectName}
							onAddButtonClicked={onAddButtonClicked}
							shouldShowHeading={!shouldShowHeading}
							hasActivatedConfluenceWithinFourteenDays={hasActivatedConfluenceWithinFourteenDays}
						/>
					</AdControlProvider>
				) : null}

				{fg('jira_ai_powered_issue_related_confluence_resources') ? (
					canShowAISuggestions && isAISuggestionsOpen && <SuggestRelatedResourcesModalContainer />
				) : (
					<></>
				)}

				{fg('jira_ai_powered_issue_related_confluence_resources')
					? isCreateLinkedPageOpened &&
						!(canShowAISuggestions && isAISuggestionsOpen) && <ConfluencePageCreateLinkView />
					: isCreateLinkedPageOpened && <ConfluencePageCreateLinkView />}
			</UFOSegment>
		);
	}

	if (projectType === SOFTWARE_PROJECT && isIssueTypeEligibleForPlaceholderTemplate) {
		// the following 2 components can never be rendered at the same time
		return (
			<UFOSegment name="issue-confluence-pages-placeholder">
				{/* Confluence Page Dummy Link Experiment
				(this component can only be rendered for tenants that DO NOT have confluence) */}
				{fg('issue_view_placeholder_template_e2e_gate') ? (
					<ContextualAnalyticsData
						sourceName="confluencePlaceholderTemplate"
						sourceType={SCREEN}
						attributes={{ experiment: 'placeholderTemplateContextual' }}
					>
						<Placeholder name="confluence-dummy-link-experiment" fallback={null}>
							<ConfluenceDummyLinkProvider>
								<AdControlProvider>
									<DummyConfluenceLinkViewLazy
										issueType={issueType}
										tenantHasConfluenceAppLinks={canLinkConfluencePage}
										projectName={projectName}
										hasShownIntentToXflowToConfluence={hasShownIntentToXflowToConfluence}
									/>
								</AdControlProvider>
							</ConfluenceDummyLinkProvider>
						</Placeholder>
					</ContextualAnalyticsData>
				) : null}
				{/* Confluence Placeholder Co-Use Experiment
				(this component can only be rendered for tenants that HAVE confluence) */}
				{fg('confluence-placeholder-template-co-use-gate') ? (
					<AdControlProvider>
						<DummyConfluenceLinkCoUseLazy
							issueType={issueType}
							projectName={projectName}
							onAddButtonClicked={onAddButtonClicked}
							shouldShowHeading
							hasActivatedConfluenceWithinFourteenDays={hasActivatedConfluenceWithinFourteenDays}
						/>
					</AdControlProvider>
				) : null}
				{fg('grw1117_jsw_pt_crossjoin_gate_aa') ? (
					<DummyConfluenceLinkCrossJoin
						issueType={issueType}
						projectName={projectName}
						onAddButtonClicked={onAddButtonClicked}
						shouldShowHeading={false}
						hasActivatedConfluenceWithinFourteenDays={hasActivatedConfluenceWithinFourteenDays}
					/>
				) : null}
				{fg('grw1117_jsw_pt_crossjoin_gate') ? (
					<ConfluenceDummyLinkProvider>
						<AdControlProvider>
							<ConfluenceContentPlaceholderCrossJoin
								issueType={issueType}
								projectName={projectName}
								onAddButtonClicked={onAddButtonClicked}
								shouldShowHeading
								hasActivatedConfluenceWithinFourteenDays={hasActivatedConfluenceWithinFourteenDays}
							/>
						</AdControlProvider>
					</ConfluenceDummyLinkProvider>
				) : null}
			</UFOSegment>
		);
	}

	return null;
};

ConfluenceContent.defaultProps = {
	isConfluencePageLinksEnabled: false,
	linkedPagesCachedCount: 0,
	linkedWhiteboardsCachedCount: 0,
	mentionedPagesCachedCount: 0,
};

const ConfluenceContentFG = componentWithFG(
	'issue-content-confluence-pages-optimisation',
	memo(ConfluenceContent),
	ConfluenceContent,
);

export default ConfluenceContentFG;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const StyledSectionHeading = styled(SectionHeading)<{}>({
	paddingBottom: token('space.100', '8px'),
});

const containerWrapperStyles = xcss({
	marginTop: 'space.100',
});

// 1px padding to the right to be aligned with the add comment box because of the shadow around it
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const GroupContainer = styled.div({
	margin: `${token('space.100', '8px')} 0`,
	paddingRight: token('space.025', '2px'),
});
