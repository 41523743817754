import React, { useCallback } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useForgeUiIssueData } from '@atlassian/jira-forge-controllers-extension-context/src/index.tsx';
import { createAttributes } from '@atlassian/jira-forge-ui-analytics/src/common/utils/create-attributes/index.tsx';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension/index.tsx';
import { ViewAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/issue-view-background-script/index.tsx';
import type { IssueViewBackgroundScript } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { issueViewBackgroundScriptsSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector.tsx';
import { ForgeBackgroundScript } from './background-script/index.tsx';

export const BackgroundScriptsUnsafe = ({
	issueViewBackgroundScripts,
}: {
	issueViewBackgroundScripts: IssueViewBackgroundScript[];
}) => {
	const extensionData = useForgeUiIssueData();
	const hasScripts = issueViewBackgroundScripts.length > 0;

	if (__SERVER__ || !extensionData || !hasScripts) {
		return null;
	}

	return (
		<>
			{issueViewBackgroundScripts?.map((extension) => (
				<ViewAnalyticsWrapper
					attributes={getAnalyticsAttributesFromExtension(extension)}
					key={`${extension.id}_${extension.properties.resourceUploadId}`}
				>
					<ForgeBackgroundScript extensionData={extensionData} item={extension} />
				</ViewAnalyticsWrapper>
			))}
		</>
	);
};

export const ReduxConnectedView = connect(
	(state: State) => ({
		issueViewBackgroundScripts: issueViewBackgroundScriptsSelector(state),
	}),
	{},
)(BackgroundScriptsUnsafe);

export const BackgroundScripts = () => {
	const getAttributes = useCallback((error: Error) => createAttributes({ error }), []);

	return (
		<JSErrorBoundary
			id="forge-background-scripts"
			packageName="issue"
			teamName="bento"
			fallback="unmount"
			attributes={getAttributes}
		>
			<ReduxConnectedView />
		</JSErrorBoundary>
	);
};

export default BackgroundScripts;
