/**
 * Feature flags convention: go/frontend-feature-flags
 */
import {
	CONTROL,
	NOT_ENROLLED,
	VARIATION,
} from '@atlassian/jira-common-util-get-experiment-group/src/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ff, getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';
// <ssaraswat>
export const isQuickActionExperimentFF = () => ff('issue_view_quick_actions_revamp_l8mbx');
export const isPinFieldExperimentAggFFEnabled = () => ff('pin_field_exp_launch_darkly_flag_ef4mc');
// </ssaraswat>

// <ugouhar>
export const isDataClassificationEnabled = () =>
	ff('jira-issue-history-data-classification_mhyjw', false);
// </lli7>
// Rach slot
// start

// 2
// 3
// Rach slot

// @pierrelingat slot start
export const isJiraIntelligentIssueBreakdownEnabled = () =>
	ff('jira-intelligent-issue-breakdown_18syl', false);

export const isIssueViewRemoveConnectOperationsFromCriticalFetchEnabled = () =>
	ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w');

// <growth team experiment slot>
export const isInviteFromAssignEnabled = (): boolean =>
	getMultivariateFeatureFlag('invite.from.assign', NOT_ENROLLED, [
		CONTROL,
		NOT_ENROLLED,
		VARIATION,
	]) === VARIATION;

export const getInviteFromAssignModalCohort = () =>
	getMultivariateFeatureFlag('invite.from.assign.modal', 'not-enrolled', [
		'control',
		'not-enrolled',
		'variation',
	]);

export const isInviteFromAssignModalFeEnabled = () =>
	ff('invite.from.assign.modal.fe.event', false);
// </growth team experiment slot>

// <gryffindor>
export const isModernGlobalIssueCreateTriggerEnabled = () =>
	ff('project.config.pce.modern.global-issue-create.trigger', false);

// on cleanup, please remove from src/packages/issue/test-util/src/main.js also
export const isModernGICEnabledForSubtask = () => isModernGlobalIssueCreateTriggerEnabled();
// </gryffindor>

// <crobertson>

// on cleanup, please remove from src/packages/issue/test-util/src/main.js also
export const isSwitcherooRealtimeExposuresTestFlagEnabled = () =>
	ff('switcheroo-realtime-exposures-test-flag_648u4', false);

// </crobertson>

// <tchen3>
export const isRelayMigrationIssueFieldsSoftRefreshLdEnabled = () =>
	ff('relay-migration-issue-fields-soft-refresh-ld');

export const isRelayMigrationIssueFieldsPriorityEnabled = () =>
	ff('relay-migration-issue-fields-priority_ri9vi');

export const isRelayMigrationIssueFieldsReporterEnabled = () =>
	ff('relay-migration-issue-fields-reporter_jfzs2');

// </tchen3>

// <smathew>
export const isRelayMigrationIssueFieldsParentEnabled = () =>
	ff('relay-migration-issue-fields-parent_5p1ac');
// </smathew>

// <fnio>
export const isFixIssueStatusTransitionLoadingEnabled = () =>
	ff('issue.details.fix-issue-status-transition-loading_6s8n8', false);
// </fnio>

// <rnguyen>
export const isPriorityFieldTooltipEnabled = (): boolean =>
	ff('sea-2830-improve-accessibility-of-priority-field-in-backlog-card_k53tq', false);
// </rnguyen>

// <jsmTimeline>
export const isJsmTimelineEnabled = (): boolean => ff('jsm-timelines-phase-2_70gja', false);

// <jsmTimeline>

// <asingh16>
export const isRespondersFieldAvailableInAllProjectsEnabled = () =>
	ff('responders-field-in-all-projects-issues-ui', false);
// </asingh16>

// <asharma9>

export const isHideEmptyFieldsEnabled = () =>
	ff('ken-545-display-hide-when-empty-button-to-user-_fjpw9');

// </asharma9>

// </ssharma9>
export const isArchivedIssuesPageLinkEnabled = () => ff('enable-archived-issues-page-link', false);
// </ssharma9>

// <mbiniek>
export const isBM3EmitOnRafTopExperiencesEnabled = () =>
	ff('bm3.emit-on-raf.top-experiences', false);
// </mbiniek>

// <cturner>
export const isStopLoadingFieldConfigTwiceEnabled = () =>
	ff('jracloud-81255-stop-loading-field-config-twice_jfdek', false);
// </cturner>

// <mhanna>
export const isCompassComponentsEnabled = () =>
	ff('compass-components-in-jira-components_xw42u', false);
// </mhanna>

// <itsm-agni>
export const isConferenceCallFieldHidden = () => ff('hide-icc-for-all-users_avyh5', false);
// <itsm-agni>

// <pjerome>
export const isCreateConfluencePagesEnabled = () =>
	ff('platform.embedded-confluence.jira-issue.create-confluence-pages', false);
// <pjerome>

// <kmaharjan4>
export const isDevOpsAppRecommenderEnabled = () =>
	ff('devops_app_recommendation_on_link_paste', false) &&
	expValEquals('devops_app_recommendation_on_link_paste', 'cohort', 'variation');
// </kmaharjan4>

// <dxu>
export const isAggMigrationCommentsEnabled = () =>
	ff('jiv-14067-issue.details.gira-agg-migration-comments_pzao9', false);
export const isAggMigrationCommentsConsistencyEnabled = () =>
	ff('issue-jiv-14530-comments-consistency-check_x716d', false);
// </dxu>

// <jchen2>
export const shouldFireAnalyticsEventForTimeTrackingUpdateFailed = () =>
	ff('jiv-18751-investigate-time-tracking-update-errors_ng38s');
// </jchen2>

// <asheel>
export const isAggMigrationAttachmentsEnabled = () =>
	ff('issue.details.gira-agg-migration-attachments_ecebe', false);
// </asheel>

// <skashyap>
export const isEmbeddedConfluenceIssueViewCrossJoinEnabled = () =>
	ff('platform_embed-conf_jira-issue_create_cross-join', false);
// </skashyap>

// <aullal>
export const isTokensAndStyledComponentsMigrationEnabled = () =>
	ff('im-tokens-and-styled-components-migration_qpsld', false);
// <aullal>

// <rkumar8>
export const isIssueArchivalFeatureEnabled = () => ff('issue-unarchival_wdpb9', false);
// </rkumar8>

// <dxu>
export const aggUseIsEditableInViewFlag = () =>
	ff('jiv-17158-agg-use-iseditableinview_8nogh', false);
// </dxu>

// <clawu>
export const isEmbeddedConfluenceCreateContentCrossFlowEnabled = () =>
	ff('platform.embedded-confluence.jira-issue.create-confluence-content-crossflow', false);
// </clawu>

// vyakimenko
export const isCascadingSelectSupportForUIMEnabled = () =>
	ff('jsw.xflow.issue-view-nudge-experiment', false);
// vyakimenko

// <sms2>
export const is3pIssueWatchNudgeEnabled = () =>
	ff('third_party_app_nudge_on_issues_watch', false) &&
	expValEquals('third_party_app_nudge_on_issues_watch', 'cohort', 'variation');
// </sms2>

// <alaktionova>
export const isJPDPanelEnabled = () => ff('polaris.jpd-panel-in-issue-view', false);
// </alaktionova>

// <ksingh4>
// </ksingh4>

// <dvedi>
export const fixforJRACLOUD84181 = () => ff('fix-for-jracloud-84181_dm5fg', false);
// </dvedi>

// <jgrose>
export const isRelayMigrationIssueFieldsSprintEnabled = () =>
	ff('relay-migration-issue-fields-sprint_jozpu');
export const isRelayMigrationIssueFieldsMetadataEnabled = () =>
	ff('relay-migration-issue-fields-metadata_d42b0');
// </jgrose>

// <relay-code-gen-Assignee>
export const isRelayMigrationIssueFieldAssigneeEnabled = () =>
	ff('relay-migration-issue-fields-assignee-ld', false);
// </relay-code-gen-Assignee>

// <relay-code-gen-Number>
export const isRelayMigrationIssueFieldNumberEnabled = () =>
	ff('relay-migration-issue-fields-number_q0em4', false);
// <relay-code-gen-Number>

// <ksingh4>
export const isCompiledReactMigrationPhase1Enabled = () =>
	ff('compiled-react-migration-issue-view_zilee', false);
// </ksingh4>

// <ksingh4>
export const isCompiledReactMigrationPhase2Enabled = () =>
	ff('compiled-react-migration-issue-view_u9myz', false);
// </ksingh4>

// <jenga>
export const isInvestigateButtonEnabledForOnlyPremium = () =>
	ff('enable_jsm_repackaging_changes_vtwk9', false);
// </jenga>

// <relay-code-gen-SingleLineText>
export const isRelayMigrationIssueFieldSingleLineTextEnabled = () =>
	ff('relay-migration-issue-fields-single-line-text_in6w6', false);
// <relay-code-gen-SingleLineText>

// <relay-code-gen-Date>
export const isRelayMigrationIssueFieldDateEnabled = () =>
	ff('relay-migration-issue-fields-date_ptj72', false);
// <relay-code-gen-Date>

// <relay-code-gen-Project>
export const isRelayMigrationIssueFieldProjectEnabled = () =>
	ff('relay-migration-issue-fields-project-select_w0xem', false);
// <relay-code-gen-Project>

// <aoshannessy>
export const isTeamAttributeAdded = () => ff('add-team-id-analytics-data');
// </aoshannessy>

// <relay-code-gen-DateTime>
export const isRelayMigrationIssueFieldDateTimeEnabled = () =>
	ff('relay-migration-issue-fields-date-time_eaqd2', false);
// <relay-code-gen-DateTime>

// <alaktionova>
export const isJPDDeliveryLinkTypeFilteringEnabled = () =>
	ff('polaris.jpd-panel-filter-by-delivery-link-type', false);
// </alaktionova>

// <relay-code-gen-Url>
export const isRelayMigrationIssueFieldUrlEnabled = () =>
	ff('relay-migration-issue-fields-url_ob8uu', false);
// <relay-code-gen-Url>

// <relay-code-gen-SecurityLevel>
export const isRelayMigrationIssueFieldSecurityLevelEnabled = () =>
	ff('relay-migration-issue-fields-security-level_7y5uv', false);
// <relay-code-gen-SecurityLevel>

export const isRelayMigrationIssueFieldSummaryEnabled = () =>
	ff('relay-migration-issue-fields-summary_eog1v', false);
// <relay-code-gen-Summary>

// <jchau>
export const isRelayMigrationIssueFieldSingleLineTextMessageEnabled = () =>
	ff('relay-migration-issue-fields-single-line-text-message', false);

export const isRelayMigrationIssueFieldsUserEnabled = () => ff('relay-migration-issue-fields-user');

export const isRelayMigrationIssueFieldApproversEnabled = () =>
	ff('relay-migration-issue-fields-approvers', false);

// </jchau>

// <relay-code-gen-TimeTracking>
export const isRelayMigrationIssueFieldTimeTrackingEnabled = () =>
	ff('relay-migration-issue-fields-time-tracking_eefh0', false);
// <relay-code-gen-TimeTracking>

// <relay-code-gen-CheckboxSelect>
export const isRelayMigrationIssueFieldCheckboxSelectEnabled = () =>
	ff('relay-migration-issue-fields-checkbox-select_ytl5n', false);
// <relay-code-gen-CheckboxSelect>

// <relay-code-gen-People>
export const isRelayMigrationIssueFieldPeopleEnabled = () =>
	ff('relay-migration-issue-fields-people', false);
// <relay-code-gen-People>

// <relay-code-gen-Color>
export const isRelayMigrationIssueFieldColorEnabled = () =>
	ff('relay-migration-issue-fields-color_wrbay', false);
// <relay-code-gen-Color>

// <relay-code-gen-Components>
export const isRelayMigrationIssueFieldComponentsEnabled = () =>
	ff('relay-migration-issue-fields-components-select_prpz1', false);
// <relay-code-gen-Components>

// <relay-code-gen-OriginalEstimate>
export const isRelayMigrationIssueFieldOriginalEstimateEnabled = () =>
	ff('relay-migration-issue-fields-original-estimate_eb4wv', false);
// <relay-code-gen-OriginalEstimate>

// <relay-code-gen-Team>
export const isRelayMigrationIssueFieldTeamEnabled = () =>
	ff('relay-migration-issue-fields-team-field-next_nctbt', false);
// <relay-code-gen-Team>

// <relay-code-gen-UserPicker>
export const isRelayMigrationIssueFieldUserPickerEnabled = () =>
	ff('relay-migration-issue-fields-multi-user', false);
// <relay-code-gen-UserPicker>

// <relay-code-gen-UserPicker>
export const isRelayMigrationIssueFieldParticipantsEnabled = () =>
	ff('relay-migration-issue-fields-participants', false);
// <relay-code-gen-UserPicker>

// <relay-code-gen-UserPicker>
export const isRelayMigrationIssueFieldRequestParticipantsEnabled = () =>
	ff('relay-migration-issue-fields-request-participants_itocm', false);
// <relay-code-gen-UserPicker>

// <psavchenko>
export const isRelayMigrationIssueTypeSwitcherEnabled = () =>
	ff('relay-migration-issue-fields-issue-type-switcher_l0lrt', false);
// </psavchenko>

// <relay-code-gen-Votes>
export const isRelayMigrationIssueFieldVotesEnabled = () =>
	ff('relay-migration-issue-fields-votes_nsbqd', false);
// <relay-code-gen-Votes>

// <relay-code-gen-Votes>
export const isRelayMigrationIssueFieldIssueRestrictionsEnabled = () =>
	ff('relay-migration-issue-fields-issue-restrictions_yudid', false);
// <relay-code-gen-Votes>

// <relay-code-gen-Watchers>
export const isRelayMigrationIssueFieldWatchersEnabled = () =>
	ff('relay-migration-issue-fields-watchers_iqhn4', false);
// <relay-code-gen-Watchers>

// <relay-code-gen-Resolution>
export const isRelayMigrationIssueFieldResolutionEnabled = () =>
	ff('relay-migration-issue-fields-resolution_86iya', false);
// <relay-code-gen-Resolution>

// <relay-code-gen-GroupPicker>
export const isRelayMigrationIssueFieldGroupPickerEnabled = () =>
	ff('relay-migration-issue-fields-group-picker', false);
// <relay-code-gen-GroupPicker>

// <relay-code-gen-MultiGroupPicker>
export const isRelayMigrationIssueFieldMultiGroupPickerEnabled = () =>
	ff('relay-migration-issue-fields-multi-group-picker', false);
// <relay-code-gen-MultiGroupPicker>

// <relay-code-gen-Status>
export const isRelayMigrationIssueFieldStatusEnabled = () =>
	ff('relay-migration-issue-fields-status_qg82c', false);
// <relay-code-gen-Status>

// <relay-code-gen-RadioSelect>
export const isRelayMigrationIssueFieldRadioSelectEnabled = () =>
	ff('relay-migration-issue-fields-radio_6gqb3', false);
// <relay-code-gen-RadioSelect>

// <relay-code-gen-RichText>
export const isRelayMigrationIssueFieldRichTextEnabled = () =>
	ff('relay-migration-issue-fields-issue-multi-line-text_w69oo', false);
// <relay-code-gen-RichText>
