import React, { Component, type ReactElement } from 'react';

type Props = {
	isValid: boolean;
	children: (shouldAppearInvalid: boolean) => ReactElement;
};

type State = {
	shouldAppearInvalid: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class WithValidationOnBlur extends Component<Props, State> {
	state = { shouldAppearInvalid: !this.props.isValid };

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const hasBecomeValid = !this.props.isValid && nextProps.isValid;
		if (hasBecomeValid) {
			this.setState({ shouldAppearInvalid: false });
		}
	}

	onBlur = () => {
		this.setState({ shouldAppearInvalid: !this.props.isValid });
	};

	render() {
		return <div onBlur={this.onBlur}>{this.props.children(this.state.shouldAppearInvalid)}</div>;
	}
}
