import memoizeOne from 'memoize-one';
import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { UserGroups, UserGroup } from '../model/types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toItem = (group: UserGroup): any => ({
	content: group.name,
	value: group.name,
	...(ff('relay-migration-issue-fields-group-picker') ||
	ff('relay-migration-issue-fields-multi-group-picker')
		? { id: group.id, groupKey: group.groupId }
		: {}),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformSuggestions = (suggestionsFromServer: UserGroups | null): any[] => {
	if (!suggestionsFromServer || !suggestionsFromServer.groups) {
		return [{ items: [] }];
	}

	return [{ items: suggestionsFromServer.groups.map(toItem) }];
};

export const fetchSuggestionsFactory = memoizeOne(
	() => (query: string) =>
		fetchJson(`/rest/api/2/groups/picker?query=${encodeURIComponent(query)}`).then((response) =>
			transformSuggestions(response),
		),
);
