import type { ErrorCode } from '@atlassian/jira-issue-adjustments/src/controllers/issue-adjustments/actions/errors/types.tsx';
import {
	genericUimErrorFlagProperties,
	loadingUimErrorFlagProperties,
	perFieldHookErrorFlagProperties,
	missingScopesUimErrorFlagProperties,
	applyingUimErrorFlagProperties,
} from './constants.tsx';
import type { ErrorFlagProperties } from './types.tsx';

export const getErrorFlagProperties = (errorCode: ErrorCode): ErrorFlagProperties | null => {
	const errorCodeToErrorFlagPropertiesMap: Record<ErrorCode, ErrorFlagProperties> = {
		ON_BRIDGE_ERROR_ERROR_CODE: genericUimErrorFlagProperties,
		USE_REGISTER_FIELDS_ERROR_CODE: genericUimErrorFlagProperties,
		USE_SUBMIT_ERROR_CODE: applyingUimErrorFlagProperties,
		PER_FIELD_HOOK_ERROR_CODE: perFieldHookErrorFlagProperties,
		SCOPES_MISSING_ERROR_CODE: missingScopesUimErrorFlagProperties,
		ERROR_BOUNDARY_HANDLER_ERROR_CODE: genericUimErrorFlagProperties,
		ON_INIT_FIELDS_INITIALIZATION_TIMEOUT: loadingUimErrorFlagProperties,
	};

	const errorFlagProperties = errorCodeToErrorFlagPropertiesMap[errorCode];

	if (errorFlagProperties === undefined) {
		return null;
	}

	return errorFlagProperties;
};
