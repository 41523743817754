import React, { useMemo, useCallback } from 'react';
import isNil from 'lodash/isNil';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { DateTimeField } from '@atlassian/jira-issue-field-date-time/src/ui/main.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import { getBrowserTimeZone } from '@atlassian/jira-platform-utils-datetime/src/index.tsx';
import { toTimeZone } from '@atlassian/jira-shared-types/src/general.tsx';
import { useUserSubscriber } from '@atlassian/jira-user-services/src/main.tsx';
import { DateTimeFieldWrapper } from '../../../../../date-time/main.tsx';
import { useAnalyticsAttributesContext } from '../../analytics/atrributes-context/index.tsx';
import type { CustomFieldProps } from '../../types.tsx';

export type Props = CustomFieldProps<string>;

export const View = ({
	label,
	showPinButton,
	fieldId,
	customReadView,
	isEditing,
	value,
	onEditRequest,
	onCancel,
	onSave,
	shouldFireScreenEvent,
	titleView = label,
	extension,
	fieldContentWrapper,
	renderAppEditView,
}: Props) => {
	const issueKey = useIssueKey();
	const [{ data: loggedInUser }] = useUserSubscriber();
	const { formatMessage } = useIntl();
	const timeZone = useMemo(
		() => (loggedInUser ? loggedInUser.timeZone : toTimeZone(getBrowserTimeZone())),
		[loggedInUser],
	);

	// If app does not specifies edit view than we go with native edit view which will rely on redux state
	const isControlledEditing = extension.properties.edit ? isEditing : undefined;

	const onUpdateCallback = useCallback(
		<T,>(newValue: T) => {
			if (typeof newValue === 'string' || (typeof newValue === 'object' && newValue === null)) {
				onSave && onSave(newValue);
			}
		},
		[onSave],
	);

	const analyticsAttributes = useAnalyticsAttributesContext();
	const attributes = useMemo(
		() => ({ ...analyticsAttributes, renderMode: 'default' }),
		[analyticsAttributes],
	);

	const renderContent = useCallback(
		() => (
			<>
				{shouldFireScreenEvent && <ForgeScreenEvent attributes={attributes} />}
				<DateTimeField
					isFitContainerWidthReadView
					readView={customReadView ? customReadView(value) : undefined}
					onEdit={onEditRequest}
					noValueText={formatMessage(genericMessages.noValue)}
					issueKey={issueKey}
					fieldKey={fieldId}
					timeZone={timeZone}
					onUpdate={onUpdateCallback}
					onCancel={onCancel}
					isEditing={isControlledEditing}
					{...(fg('forge_cf_inline_edit_on_issue_view') ? { renderAppEditView } : {})}
				/>
			</>
		),
		[
			renderAppEditView,
			shouldFireScreenEvent,
			customReadView,
			value,
			onEditRequest,
			issueKey,
			fieldId,
			timeZone,
			onUpdateCallback,
			onCancel,
			attributes,
			formatMessage,
			isControlledEditing,
		],
	);

	const renderContentWithContentWrapper = () => {
		const FieldContentWrapper = fieldContentWrapper;

		if (!isNil(FieldContentWrapper)) {
			return (
				<FieldContentWrapper topMargin={0.75 * gridSize}>{renderContent()}</FieldContentWrapper>
			);
		}
		return renderContent();
	};

	return (
		<DateTimeFieldWrapper>
			<FieldHeading>
				<FieldHeadingTitle>{titleView}</FieldHeadingTitle>
				{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
			</FieldHeading>
			<SideBySideField
				data-testid="issue-view-base.context.ecosystem.forge.custom-field.fields.date-time.side-by-side-field"
				isEditing={isControlledEditing}
			>
				{renderContentWithContentWrapper()}
			</SideBySideField>
		</DateTimeFieldWrapper>
	);
};

export default View;
