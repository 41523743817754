import React, { memo } from 'react';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { SkeletonLineWrapper, SkeletonWrapper } from './styled.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default memo<Record<any, any>>(() => (
	<SkeletonWrapper data-testid="risk-insights-panel.common.ui.skeleton.skeleton-wrapper">
		<SkeletonLineWrapper>
			<Skeleton height="20px" width="30%" />
		</SkeletonLineWrapper>
		<SkeletonLineWrapper>
			<Skeleton height="20px" width="100%" />
		</SkeletonLineWrapper>
		<SkeletonLineWrapper>
			<Skeleton height="20px" width="100%" />
		</SkeletonLineWrapper>
	</SkeletonWrapper>
));
