import { fg } from '@atlassian/jira-feature-gating';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { ScreenTabsChangesPublic } from '../../../../common/types/screen-tabs.tsx';
import {
	screenTabsPublicToInternalValidator,
	screenTabsPublicToInternalTransformer,
	getScreenTabsPublicToInternalValidationErrors,
} from '../../../../common/utils/screen-tabs/index.tsx';
import type { AppId, ChangeId, IssueAdjustmentsState } from '../../types.tsx';
import { UiModificationsScreenTabsValidationError } from '../../utils/errors/ui-modifications-screentabs-validation-error.tsx';
import { toScreenTabsValidationError } from '../../utils/errors/utils.tsx';
import { collectScreenTabsAppliedChangesHistory } from '../applied-changes-history/collect-history/index.tsx';

type ProcessIframeScreenTabsChangesPayload = {
	screenTabsChanges: ScreenTabsChangesPublic;
	appId: AppId;
	changeId: ChangeId;
};

/**
 * Update screen tabs store data from changes coming from UI Modifications
 */
export const updateScreenTabs =
	({
		screenTabsChanges: screenTabsFromIframe,
		changeId,
		appId,
	}: ProcessIframeScreenTabsChangesPayload) =>
	({ getState, setState, dispatch }: StoreActionApi<IssueAdjustmentsState>) => {
		const { screenTabs: internalScreenTabs } = getState();
		// If screenTabsFromIframe is empty it means that the app does not provide srceenTabs changes.
		if (Array.isArray(screenTabsFromIframe) && !screenTabsFromIframe.length) {
			return;
		}

		if (
			internalScreenTabs &&
			screenTabsPublicToInternalValidator(internalScreenTabs, screenTabsFromIframe)
		) {
			const newScreenTabs = screenTabsPublicToInternalTransformer(
				internalScreenTabs,
				screenTabsFromIframe,
			);

			setState({ screenTabs: newScreenTabs });

			if (fg('ui_modifications_multiple_apps')) {
				dispatch(
					collectScreenTabsAppliedChangesHistory({
						appId,
						changeId,
						screenTabs: newScreenTabs,
					}),
				);
			}
		} else {
			throw new UiModificationsScreenTabsValidationError('Transformation failed', {
				errors: getScreenTabsPublicToInternalValidationErrors(
					internalScreenTabs,
					screenTabsFromIframe,
				).map(toScreenTabsValidationError),
			});
		}
	};
