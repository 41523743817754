/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import Tooltip from '@atlaskit/tooltip';

import messages from './messages';

type Props = {
	onClick: (event: React.MouseEvent) => void;
	tabIndex?: number;
	appearance?: IconButtonProps['appearance'];
	spacing?: IconButtonProps['spacing'];
};
/* *
 * TODO: AIFOLLOWUP
 * When playwright has cross browser support, make sure to test it:
 * ./__tests__/playwright/copy-paste.ai-test.ts
 *
 * TODO: AIFOLLOWUP
 * This needs extensive manual cross-browser testing as part of EAP.
 * */
export const CopyIconButton = ({ onClick, tabIndex, appearance, spacing }: Props) => {
	const { formatMessage } = useIntl();
	const [tooltip, setTooltip] = React.useState(formatMessage(messages.copy));
	const updateTooltip = React.useRef<() => void>();

	const handleOnClick = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement | HTMLElement, MouseEvent>) => {
			onClick(event);
			setTooltip(formatMessage(messages.copiedToClipboard));
		},
		[onClick, formatMessage],
	);

	const resetTooltip = React.useCallback(
		() => setTooltip(formatMessage(messages.copy)),
		[formatMessage],
	);

	React.useLayoutEffect(() => {
		updateTooltip.current?.();
	}, [tooltip]);

	return (
		<Tooltip
			content={({ update }) => {
				updateTooltip.current = update;
				return tooltip;
			}}
			position="top"
			onHide={resetTooltip}
		>
			<IconButton
				testId="copy-response-button"
				type="button"
				onClick={handleOnClick}
				icon={CopyIcon}
				label={tooltip}
				appearance={appearance}
				spacing={spacing}
				tabIndex={tabIndex}
			/>
		</Tooltip>
	);
};
