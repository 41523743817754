// api contract: https://hello.atlassian.net/wiki/spaces/AM3/pages/4383854942/Suggested+Comment+Reply+Agent+API+Design
export const PRODUCT_TYPES = {
	JWM: 'JWM',
	JSW: 'JSW',
} as const;

export type SuggestedCommentRelyAgentProduct = (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];

export const SuggestedCommentRelyAgentCommentType = {
	QUICK_REPLY: 'QUICK_REPLY',
	QUESTION: 'QUESTION',
	SUGGESTION: 'SUGGESTION',
	JIRA_STATUS_UPDATE: 'JIRA_STATUS_UPDATE',
	JIRA_COMMENT_REPLY: 'JIRA_COMMENT_REPLY',
} as const;

export type SuggestedCommentRelyAgentCommentType =
	(typeof SuggestedCommentRelyAgentCommentType)[keyof typeof SuggestedCommentRelyAgentCommentType];

export const SUGGESTED_COMMENT_AGENT_NAME_ID = 'suggested_comment_reply_agent';
export const SUGGESTED_COMMENT_AGENT_EXPERIENCE_ID = 'suggested-comment-reply';

export type SuggestedCommentRelyCommentTypes = SuggestedCommentRelyAgentCommentType[];

export type SuggestedCommentRelyAgentRequest = {
	object_id: string; // issue-key
	locale: string;
	product: SuggestedCommentRelyAgentProduct;
	comment_types?: SuggestedCommentRelyCommentTypes;
};

export type SuggestedCommentRelyAgentResponse = {
	commentSuggestions?: { commentReplyType: string; text: string }[];
};
