export const UIM_CONSENT_UNKNOWN = 'unknown';
export const UIM_CONSENT_REQUIRED = 'required';
export const UIM_CONSENT_CONSENTED = 'consented';

export type UIMConsentType =
	| typeof UIM_CONSENT_UNKNOWN
	| typeof UIM_CONSENT_REQUIRED
	| typeof UIM_CONSENT_CONSENTED;

export type AppId = string;

export type UIMConsentMap = Record<AppId, UIMConsentType>;

export type AdjustmentsContextState = {
	// Whether the conditions are met in the current project/entry point
	// to mount our main async component and load data from our API.
	// Needs a tri-state because there's a delay before the current project is available
	shouldUiModificationsLoad: 'unknown' | 'no' | 'yes';
	// Whether UI modifications data from the internal REST API has finished loading.
	// Only valid when shouldUiModificationsLoad === 'yes'
	hasFinishedLoading: boolean;
	// Whether there are any iframes mounted after filtering based on the above data.
	// Only valid when hasFinishedLoading === true
	adjustmentsAreEnabled: boolean;
	uiModificationsConsentStatusDeprecated: UIMConsentType;
	uiModificationsConsentStatus: UIMConsentMap;
};
