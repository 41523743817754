/* eslint-disable @atlaskit/design-system/no-html-button */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/* @jsxFrag React.Fragment */
import React, { useEffect, useMemo, useRef } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { type MessageDescriptor, useIntl } from 'react-intl-next';

import ChevronRight from '@atlaskit/icon/glyph/chevron-right';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import Lozenge from '@atlaskit/lozenge';
import { Flex, Text, xcss } from '@atlaskit/primitives';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { token } from '@atlaskit/tokens';

import Enter from '../../assets/Enter';
import { LogoSvg } from '../../assets/Logo';

import { messages } from './messages';
import {
	chevronIconStyles,
	rovoSuggestionButtonStyles,
	rovoSuggestionIconStyles,
	suggestionButtonStyles,
	suggestionIconStyles,
	suggestionListItemStyles,
	tellAILabelStyles,
} from './styles';
import type { Suggestion } from './types';

const contentStyles = xcss({
	hyphens: 'auto',
	wordWrap: 'break-word',
	flex: 1,
	minWidth: '90px',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

const descriptionStyles = css({
	hyphens: 'auto',
	wordWrap: 'break-word',
	flex: 1,
	minWidth: '90px',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtlest', '#626F86'),
	display: 'inline-block',
	lineHeight: '14px',
});

const statusStyles = xcss({
	paddingLeft: 'space.050',
});

const enterStyles = css({
	// with '&', the following selector applies only if this icon style is under a highlighted button
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	"button[data-highlighted='true'] &": {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	display: 'none',
	height: '20px',
	width: '20px',
	borderRadius: '3px',
	color: token('color.text.subtle', '#44546F'),
	backgroundColor: token('color.background.neutral.subtle.hovered', '#091E420F'),
});

// Height of ListHeading component, used to calculate the scroll position.
// Kind of a hack, but it's the easiest way to get the height of the sticky group header.
const STICKY_GROUP_HEADER_HEIGHT = 32;

const ensureElementIsVisibleInScrollContainer = (
	listItem: HTMLElement,
	stickyHeaderHeight: number,
) => {
	// ensure the structure of suggestions li element > ul (position relative) > scrollable div (overflow auto)
	const scrollContainer = listItem.offsetParent?.parentElement;
	if (!scrollContainer || scrollContainer.tagName !== 'DIV') {
		return;
	}

	const scrollContainerRect = scrollContainer.getBoundingClientRect();
	const itemRect = listItem.getBoundingClientRect();

	// if highlighted item is above/below the current scroll viewport
	const itemNotVisible =
		listItem.offsetTop < scrollContainer.scrollTop ||
		listItem.offsetTop + itemRect.height > scrollContainer.scrollTop + scrollContainerRect.height;

	if (itemNotVisible) {
		// Can't use item.scrollIntoView() because it moves the whole page too
		scrollContainer.scrollTo({
			top: listItem.offsetTop - stickyHeaderHeight,
			behavior: 'smooth',
		});
	}
};
const statusLozengeMessages: Record<
	Exclude<Suggestion['statusLozengeType'], undefined>,
	MessageDescriptor
> = {
	beta: messages.suggestionStatusBeta,
	new: messages.suggestionStatusNew,
};

type ListItemProps = {
	suggestion: Suggestion;
	customLabel?: string;
	highlighted: boolean;
	setSuggestion?: (suggestion: Suggestion, eventName: 'focus' | 'mousemove') => void;
	hasGroupHeading?: boolean;
};
export const ListItem = ({
	suggestion,
	customLabel,
	highlighted,
	setSuggestion,
	hasGroupHeading,
}: ListItemProps) => {
	const { formatMessage } = useIntl();
	const listItemRef = useRef<HTMLLIElement>(null);
	const hasChildren = useMemo(() => {
		return suggestion.childSuggestions && suggestion.childSuggestions.length;
	}, [suggestion.childSuggestions]);

	const updateSuggestion = React.useCallback(
		(eventName: 'focus' | 'mousemove') => {
			if (setSuggestion) {
				setSuggestion(suggestion, eventName);
			}
		},
		[setSuggestion, suggestion],
	);

	const onFocus = React.useCallback(() => {
		updateSuggestion('focus');
		suggestion.onSuggestionFocused();
	}, [updateSuggestion, suggestion]);

	const onMouseMove = React.useCallback(() => {
		updateSuggestion('mousemove');
	}, [updateSuggestion]);

	useEffect(() => {
		// If the item is highlighted and not hovered, scroll it into view
		if (highlighted && listItemRef.current && !listItemRef.current.matches(':hover')) {
			ensureElementIsVisibleInScrollContainer(
				listItemRef.current,
				editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') && hasGroupHeading
					? STICKY_GROUP_HEADER_HEIGHT
					: 0,
			);
		}
	}, [listItemRef, highlighted, hasGroupHeading]);

	const chevronRight = useMemo(() => {
		if (hasChildren && !highlighted) {
			if (editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')) {
				return (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					<span css={chevronIconStyles}>
						<ChevronRightIcon label="" color={token('color.icon.subtle')} />
					</span>
				);
			} else {
				return <ChevronRight label="" size="medium" />;
			}
		}

		return null;
	}, [hasChildren, highlighted]);

	// When the FF is removed we can get rid of this memo and use the value directly.
	const rightText = useMemo(() => {
		if (suggestion.rightText) {
			return suggestion.rightText;
		}

		return null;
	}, [suggestion]);

	const getSuggestionButtonStyles = useMemo(() => {
		if (
			suggestion.value.includes('agent:') &&
			editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')
		) {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			return rovoSuggestionButtonStyles;
		}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		return suggestionButtonStyles;
	}, [suggestion.value]);

	const getRovoSuggestionIconStyles = useMemo(() => {
		if (
			suggestion.value.includes('agent:') &&
			editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')
		) {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			return rovoSuggestionIconStyles;
		}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		return suggestionIconStyles;
	}, [suggestion.value]);

	let statusLozengeText =
		suggestion.statusLozengeType &&
		(statusLozengeMessages[suggestion.statusLozengeType]
			? formatMessage(statusLozengeMessages[suggestion.statusLozengeType])
			: null);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<li css={suggestionListItemStyles} ref={listItemRef}>
			<button
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				css={getSuggestionButtonStyles}
				type="button"
				onClick={suggestion.onSelect}
				onMouseMove={onMouseMove}
				data-testid={suggestion.testId || (customLabel ? `tell-ai` : suggestion.label)}
				data-highlighted={highlighted}
				onFocus={onFocus}
			>
				{!suggestion.hideIcon && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					<span css={getRovoSuggestionIconStyles}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						{suggestion.icon || <LogoSvg className="default-icon" alt={''} />}
					</span>
				)}
				<Flex direction="column" xcss={contentStyles}>
					<Flex alignItems="center">
						{!customLabel ? (
							<Text>{suggestion.label}</Text>
						) : (
							<>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
								<span css={tellAILabelStyles}>{customLabel}</span> "{suggestion.label}"
							</>
						)}
						{statusLozengeText && (
							<Flex alignItems="center" xcss={statusStyles}>
								<Lozenge appearance="new">{statusLozengeText}</Lozenge>
							</Flex>
						)}
					</Flex>
					{suggestion.description && <span css={descriptionStyles}>{suggestion.description}</span>}
				</Flex>

				{rightText && <Text color="color.text.subtlest">{rightText}</Text>}

				{suggestion.value !== 'no-suggestions' && (
					<span css={enterStyles}>
						<Enter />
					</span>
				)}

				{chevronRight}
			</button>
		</li>
	);
};
