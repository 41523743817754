import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../../config-items/slice-or-node-to-markdown';
import { streamConvoAI } from '../../../provider/prompt-requests/convo-ai';
import { streamXPGenAI } from '../../../provider/prompt-requests/xp-gen-ai';
import { Icon } from '../../assets/icons/suggestTitle';
import {
	createInsertAtCurrentPosition,
	createInsertAtTop,
	createReplaceExistingConfluenceTitle,
} from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';
import { getSlice } from '../utils';

import { messages } from './messages';

const SELECTION_TYPE = 'range';

export const suggestConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
	title: messages.title,
	description: messages.description,
	selectionType: SELECTION_TYPE,
	getInitialContext: ({ editorView, positions, intl, updateIdMap, mentionMap }) => {
		const selection = sliceOrNodeToMarkdown({
			slice: getSlice(editorView, positions),
			editorView,
			convertTo: 'markdown',
			updateIdMap,
			selectionType: SELECTION_TYPE,
			mentionMap,
		});

		const document = sliceOrNodeToMarkdown({
			node: editorView.state.doc,
			editorView,
			convertTo: 'markdown',
			updateIdMap,
			selectionType: SELECTION_TYPE,
			mentionMap,
		});

		return {
			selection: selection.markdown,
			document: document.markdown,
			userLocale: intl.locale,
			intentSchemaId: 'suggest_title_intent_schema.json',
			contentStatistics: selection.contentStatistics,
		};
	},
	triggerPromptRequest({ initialContext, editorSchema, analyticsContext }) {
		if (fg('platform_editor_ai_assistance_service')) {
			return streamConvoAI({
				aiSessionId: analyticsContext?.aiSessionId,
				userLocale: initialContext.userLocale,
				intentSchemaId: 'suggest_title_intent_schema.json',
				editorSchema,
				currentSelection: initialContext.selection,
				fullDocument: initialContext.document,
			});
		}

		return streamXPGenAI({
			userLocale: initialContext.userLocale,
			intentSchemaId: 'suggest_title_intent_schema.json',
			editorSchema,
			contextList: [
				{
					type: 'text',
					entity: initialContext.selection,
					relationship: 'SELECTION',
				},
			],
		});
	},
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	actions: [
		createInsertAtCurrentPosition({ appearance: 'secondary' }),
		createInsertAtTop({ appearance: 'primary' }),
	],
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});

/**
 * EXPERIMENTAL: Util for experimental flow where 'Suggest title' in
 * Confluence title toolbar will trigger the 'Suggest a title' flow in Editor AI.
 */
export const suggestConfigItemForConfluenceTitleToolbar = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
	title: messages.title,
	description: messages.description,
	selectionType: 'range',
	getInitialContext: ({ editorView, positions, intl, updateIdMap, mentionMap }) => {
		const { markdown, contentStatistics } = sliceOrNodeToMarkdown({
			slice: getSlice(editorView, positions),
			editorView,
			convertTo: 'markdown',
			updateIdMap,
			selectionType: 'range',
			mentionMap,
		});
		return {
			selection: markdown,
			userLocale: intl.locale,
			intentSchemaId: 'suggest_title_intent_schema.json',
			contentStatistics,
		};
	},
	triggerPromptRequest({ initialContext, editorSchema, analyticsContext }) {
		if (fg('platform_editor_ai_assistance_service')) {
			return streamConvoAI({
				aiSessionId: analyticsContext?.aiSessionId,
				userLocale: initialContext.userLocale,
				intentSchemaId: 'suggest_title_intent_schema.json',
				editorSchema,
				currentSelection: initialContext.selection,
				fullDocument: initialContext.document,
			});
		}

		return streamXPGenAI({
			userLocale: initialContext.userLocale,
			intentSchemaId: 'suggest_title_intent_schema.json',
			editorSchema,
			contextList: [
				{
					type: 'text',
					entity: initialContext.selection,
					relationship: 'SELECTION',
				},
			],
		});
	},
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	actions: [createReplaceExistingConfluenceTitle({ appearance: 'primary' })],
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});
