import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useViewIdsByViewUUID } from '@atlassian/jira-polaris-component-view-id-mapping/src/index.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { createStore, createStateHook, createActionsHook } from '@atlassian/react-sweet-state';
import type { UserData, ViewLastSeen } from '../../domain/types.tsx';
import { actions } from './actions/index.tsx';
import { getSortedLastVisitors } from './selectors/index.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	lastSeen: {},
	users: {},
};

const Store = createStore({
	initialState,
	actions,
	name: 'PolarisViewMetadataStore',
});

const useLastSeenRecords = createStateHook(Store, { selector: (state) => state.lastSeen });
const useLastSeenRecord = (viewUUID: ViewUUID) => {
	const lastSeenRecords = useLastSeenRecords();
	return lastSeenRecords[viewUUID];
};
export const useUserData = createStateHook(Store, {
	selector: (state, { viewUUID }: { viewUUID: string }): Record<AccountId, UserData> =>
		state.users[viewUUID] || {},
});
export const useSortedVisitors = createStateHook(Store, {
	selector: (state, { viewUUID }: { viewUUID: string }) => getSortedLastVisitors(state, viewUUID),
});

export const useActions = createActionsHook(Store);

// since we don't actively update the current user in the state, this will only
// every look at "previous" view loads, hence there is no race condition
export const useHasSeenView = createStateHook(Store, {
	selector: (
		state,
		{ viewUUID, accountId }: { viewUUID: string | undefined; accountId: AccountId | undefined },
	) => {
		if (
			viewUUID === undefined ||
			accountId === undefined ||
			state.lastSeen[viewUUID] === undefined ||
			state.lastSeen[viewUUID].loading ||
			state.lastSeen[viewUUID].error
		) {
			return undefined;
		}

		return state.lastSeen[viewUUID].data[accountId] !== undefined;
	},
});

export const useViewLastSeen = functionWithCondition(
	() => fg('jpd_visitor_handling_refactor'),
	(viewUUID: ViewUUID): ViewLastSeen => {
		const [data, setData] = useState<ViewLastSeen>({ loading: true, data: {} });
		const record = useLastSeenRecord(viewUUID);
		const { loadViewsLastSeen } = useActions();
		const apolloClient = useApolloClient();
		const viewIds = useViewIdsByViewUUID({ viewUUID });
		const currentUser = useAccountId();

		useEffect(() => {
			if (record !== undefined) {
				setData(record);
			} else {
				loadViewsLastSeen(viewIds, { apolloClient, currentUser });
			}
		}, [loadViewsLastSeen, viewIds, record, apolloClient, currentUser]);

		return data;
	},
	() => ({
		loading: false,
		error: new Error('Feature gate jpd_visitor_handling_refactor not enabled'),
		data: {},
	}),
);
