import React from 'react';

import type { IntlShape, MessageDescriptor } from 'react-intl-next';

import type { Schema } from '@atlaskit/editor-prosemirror/model';
import { fg } from '@atlaskit/platform-feature-flags';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import type { EditorPluginAIConfigItemMarkdownAction } from '../../../config-items/config-items';
import {
	type ConfigItemInitialContext,
	createEditorPluginAIConfigItemMarkdown,
} from '../../../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../../config-items/slice-or-node-to-markdown';
import { streamConvoAI } from '../../../provider/prompt-requests/convo-ai';
import type {
	BackendModel,
	GrammarSpellingIntentSchema,
	ImproveQualityIntentSchema,
	PromptRequestOptions,
} from '../../../provider/prompt-requests/types';
import { streamXPGenAI } from '../../../provider/prompt-requests/xp-gen-ai';
import { Icon as FixSpellingIcon } from '../../assets/icons/fixSpelling';
import { Icon as ImproveWritingIcon } from '../../assets/icons/improveWriting';
import type { IconShownAt } from '../../assets/icons/types';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';
import { messages as changeToneMessages } from '../change-tone/messages';
import { messages as shortenMessages } from '../shorten/messages';
import { getSlice } from '../utils';

import { messages as enhancedMessages } from './messages';

const SELECTION_TYPE = 'range';

const createStreamTask = (
	taskName: MessageDescriptor,
	intentSchemaId: any,
	editorSchema: Schema,
	initialContext: ConfigItemInitialContext<'range'>,
) => {
	if (fg('platform_editor_ai_assistance_service')) {
		return async function* (options: PromptRequestOptions) {
			const streaming = streamConvoAI({
				userLocale: initialContext.userLocale,
				intentSchemaId,
				editorSchema,
				fullDocument: initialContext.document,
				currentSelection: initialContext.selection,
			})(options);
			for await (const streamItem of streaming) {
				yield {
					...streamItem,
					name: taskName,
				};
			}
		};
	} else {
		return async function* (options: PromptRequestOptions) {
			const streaming = streamXPGenAI({
				userLocale: initialContext.userLocale,
				intentSchemaId,
				editorSchema,
				contextList: [
					{
						type: 'ADF_MARKDOWN_V1',
						entity: initialContext.selection,
						relationship: 'SELECTION',
					},
				],
			})(options);
			for await (const streamItem of streaming) {
				yield {
					...streamItem,
					name: taskName,
				};
			}
		};
	}
};

const tasks = [
	{ taskName: shortenMessages.title, intentSchemaId: 'shorten_intent_schema.json' },
	{
		taskName: enhancedMessages.fixSpellingGrammarTitle,
		intentSchemaId: 'grammar_spelling_intent_schema.json',
	},
	{
		taskName: changeToneMessages.professionalToneTitle,
		intentSchemaId: 'change_tone_professional_intent_schema.json',
	},
];

function createEnhanceOption({
	key,
	title,
	description,
	actions,
	intentSchemaId,
	icon,
	getBackendModel,
}: {
	key: CONFIG_ITEM_KEYS;
	title: MessageDescriptor;
	description: MessageDescriptor;
	actions: EditorPluginAIConfigItemMarkdownAction[];
	intentSchemaId: GrammarSpellingIntentSchema | ImproveQualityIntentSchema;
	icon?: ({
		formatMessage,
		shownAt,
	}: {
		formatMessage: IntlShape['formatMessage'];
		shownAt: IconShownAt;
	}) => JSX.Element;
	getBackendModel: (isInterrogate?: boolean) => BackendModel;
}) {
	return createEditorPluginAIConfigItemMarkdown({
		key,
		title,
		description,
		selectionType: SELECTION_TYPE,
		getInitialContext: ({ editorView, positions, intl, updateIdMap, mentionMap }) => {
			const selection = sliceOrNodeToMarkdown({
				slice: getSlice(editorView, positions),
				editorView,
				convertTo: 'markdown-plus',
				updateIdMap,
				selectionType: SELECTION_TYPE,
				mentionMap,
			});

			//TODO: multiPrompts experiment cleanup - editor_ai_-_multi_prompts
			if (
				key === CONFIG_ITEM_KEYS.IMPROVE_WRITING &&
				editorExperiment('editor_ai_-_multi_prompts', 'test', { exposure: true })
			) {
				return {
					selection: selection.markdown,
					userLocale: intl.locale,
					intentSchemaId: 'improve_quality_intent_schema.json',
					additionalIntentSchemaIds: [
						'shorten_intent_schema.json',
						'grammar_spelling_intent_schema.json',
						'change_tone_professional_intent_schema.json',
					],
					contentStatistics: selection.contentStatistics,
				};
			}

			const document = sliceOrNodeToMarkdown({
				node: editorView.state.doc,
				editorView,
				convertTo: 'markdown-plus',
				updateIdMap,
				selectionType: SELECTION_TYPE,
				mentionMap,
			});

			return {
				selection: selection.markdown,
				document: document.markdown,
				userLocale: intl.locale,
				intentSchemaId,
				contentStatistics: selection.contentStatistics,
			};
		},
		triggerPromptRequest({ initialContext, editorSchema, analyticsContext }) {
			if (
				intentSchemaId === 'improve_quality_intent_schema.json' &&
				fg('platform_editor_ai_assistance_service')
			) {
				return streamConvoAI({
					aiSessionId: analyticsContext?.aiSessionId,
					userLocale: initialContext.userLocale,
					intentSchemaId: 'improve_quality_intent_schema.json',
					editorSchema,
					fullDocument: initialContext.document,
					currentSelection: initialContext.selection,
				});
			}

			if (
				intentSchemaId === 'grammar_spelling_intent_schema.json' &&
				fg('platform_editor_ai_assistance_service')
			) {
				return streamConvoAI({
					aiSessionId: analyticsContext?.aiSessionId,
					userLocale: initialContext.userLocale,
					intentSchemaId: 'grammar_spelling_intent_schema.json',
					editorSchema,
					fullDocument: initialContext.document,
					currentSelection: initialContext.selection,
				});
			}

			return streamXPGenAI({
				userLocale: initialContext.userLocale,
				intentSchemaId,
				editorSchema,
				contextList: [
					{
						type: 'ADF_MARKDOWN_V1',
						entity: initialContext.selection,
						relationship: 'SELECTION',
					},
				],
			});
		},
		//TODO: multiPrompts experiment cleanup - editor_ai_-_multi_prompts
		additionalTriggerPromptRequest({ initialContext, editorSchema, analyticsContext }) {
			if (
				key === CONFIG_ITEM_KEYS.IMPROVE_WRITING &&
				editorExperiment('editor_ai_-_multi_prompts', 'test', { exposure: true })
			) {
				return async function* combinedStream(options: PromptRequestOptions) {
					for (const { taskName, intentSchemaId } of tasks) {
						const streamTask = createStreamTask(
							taskName,
							intentSchemaId,
							editorSchema,
							initialContext,
						);
						for await (const streamItem of streamTask(options)) {
							yield streamItem;
						}
					}
				};
			}
		},
		isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
		icon,
		actions,
		getBackendModel,
	});
}

export const improveWriting = createEnhanceOption({
	key: CONFIG_ITEM_KEYS.IMPROVE_WRITING,
	title: enhancedMessages.improveWritingTitle,
	description: enhancedMessages.improveWritingDescription,
	actions: [
		createInsertBelow({ appearance: 'secondary' }),
		createReplace({ appearance: 'primary' }),
	],
	intentSchemaId: 'improve_quality_intent_schema.json',
	icon: ({ shownAt }) => <ImproveWritingIcon shownAt={shownAt} />,
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}
		return 'xp-gen-ai';
	},
});

export const fixSpellingAndGrammar = createEnhanceOption({
	key: CONFIG_ITEM_KEYS.FIX_SPELLING_AND_GRAMMAR,
	title: enhancedMessages.fixSpellingGrammarTitle,
	description: enhancedMessages.fixSpellingGrammarDescription,
	actions: [createReplace({ appearance: 'primary' })],
	intentSchemaId: 'grammar_spelling_intent_schema.json',
	icon: ({ shownAt }) => <FixSpellingIcon shownAt={shownAt} />,
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}
		return 'xp-gen-ai';
	},
});
