import type { Store } from 'redux';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import { INTERNAL_SERVER_ERROR } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	transformAggResponseToLegacyGraphql,
	transformAggResponseToLegacyGira,
} from '@atlassian/jira-issue-agg-field-transformers/src/utils.tsx';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/types.tsx';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service/src/types.tsx';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import type { ContainersByType } from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import { UNKNOWN_ERROR } from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { ProjectContext } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import type { MyPreferences } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { AGG_ISSUE_ERROR_ANALYTICS } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import fetchUploadContextTransformer from '@atlassian/jira-issue-view-common-media/src/upload-context/upload-context-transformer.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { getAssociatedIssuesContext } from '@atlassian/jira-issue-view-common-utils/src/utils/get-associated-issues-context.tsx';
import type PrefetchedResourceManager from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import {
	CHILDREN_ISSUES,
	EPIC_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
	SUBTASKS,
	DATA_CLASSIFICATION_FIELD,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import type { State as EcosystemState } from '@atlassian/jira-issue-view-ecosystem-service/src/services/types.tsx';
import { makeErrorObject } from '@atlassian/jira-issue-view-services/src/issue/error-utils.tsx';
import { combineResponseData } from '@atlassian/jira-issue-view-services/src/issue/gira/agg/index.tsx';
import { transformData as transformGiraData } from '@atlassian/jira-issue-view-services/src/issue/gira/index.tsx';
import {
	transformReadAttachmentsToken,
	transformUserContext,
} from '@atlassian/jira-issue-view-services/src/issue/gira/media-context/index.tsx';
import {
	fetchIssueFailure,
	fetchIssueSuccess,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import {
	fetchUploadContextFailure,
	fetchUploadContextRequest,
	fetchUploadContextSuccess,
} from '@atlassian/jira-issue-view-store/src/common/media/upload-context/upload-context-actions.tsx';
import { fetchUserAuthSuccess } from '@atlassian/jira-issue-view-store/src/common/media/user-auth/user-auth-actions.tsx';
import {
	fetchViewContextRequest,
	fetchViewContextSuccess,
} from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions.tsx';
import type { ProjectPermissionActions } from '@atlassian/jira-project-permissions-service/src/types.tsx';
import type { JiraSettingsActions } from '@atlassian/jira-settings-service/src/types.tsx';
import {
	type BaseUrl,
	type IssueKey,
	type ProjectKey,
	toIssueId,
	toProjectKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { transformIssueType } from './utils.tsx';

type StoreWithContext = (arg1: FieldsState, project: ProjectContext | null) => void;
type SavePreferences = (prefs?: MyPreferences) => void;
type SaveProjectContext = (projectKey: ProjectKey, projectContext: ProjectContext) => void;
type StoreIssueContainersLayoutData = (issueLayout: ContainersByType) => void;
type StoreEcosystemData = (ecosystem: EcosystemState, issueKey: string) => void;

export type PrefetchDataConsumptionArguments = {
	resourceManager: PrefetchedResourceManager;
	savePreferences: SavePreferences;
	saveProjectContext: SaveProjectContext;
	storeFieldsWithContext: StoreWithContext;
	permissionActions: ProjectPermissionActions;
	attachmentActions: AttachmentServiceActions;
	storeIssueContainersLayoutData: StoreIssueContainersLayoutData;
	storeEcosystemData: StoreEcosystemData;
	jiraSettingsActions: JiraSettingsActions;
	store: Store<State>;
	baseUrl: BaseUrl;
	issueContextActions?: IssueContextServiceActions;
	issueKey: IssueKey;
	associatedIssuesContextActions?: AssociatedIssuesContextActions;
};

const isValidViewScreenId = (viewScreenId?: number) => !!viewScreenId;

const checkPrefetchedData = (resourceManager: PrefetchedResourceManager): boolean => {
	const giraResponse = resourceManager.getGiraData();
	const aggResponse = resourceManager.getAggData();
	const aggResponseErrors = resourceManager.getAggErrors();

	if (giraResponse && !giraResponse.viewIssue) {
		return false;
	}

	if (aggResponse) {
		const issueByKeyData = aggResponse.jira?.issueByKey;
		if (!issueByKeyData) {
			return false;
		}
		if (issueByKeyData.errorRetrievingData === true) {
			return false;
		}
	}

	if (aggResponseErrors?.length) {
		log.safeErrorWithoutCustomerData(
			'issue.prefetch.agg',
			'Something went wrong when pre-fetching data from AGG',
			makeErrorObject(aggResponseErrors, AGG_ISSUE_ERROR_ANALYTICS),
		);
	}

	return true;
};

export const consumePrefetchedDataIfAble = ({
	resourceManager,
	savePreferences,
	saveProjectContext,
	storeFieldsWithContext,
	storeIssueContainersLayoutData,
	storeEcosystemData,
	attachmentActions,
	permissionActions,
	jiraSettingsActions,
	store,
	issueContextActions,
	issueKey,
	associatedIssuesContextActions,
}: PrefetchDataConsumptionArguments): boolean => {
	try {
		const giraResponse = resourceManager.getGiraData();
		const aggResponse = resourceManager.getAggData();

		if (giraResponse && !giraResponse.permissions) {
			return false;
		}

		if (!checkPrefetchedData(resourceManager)) {
			return false;
		}

		if (giraResponse && giraResponse.viewIssue && aggResponse) {
			const { readToken, uploadToken, userToken } = giraResponse.mediaContext || {};

			// note existence check in enclosing if
			if (readToken) {
				const viewContext = transformReadAttachmentsToken(readToken);
				store.dispatch(fetchViewContextSuccess(viewContext));
			} else {
				store.dispatch(fetchViewContextRequest());
			}

			if (uploadToken) {
				if ('error' in uploadToken) {
					store.dispatch(fetchUploadContextFailure(new Error(uploadToken.error)));
				} else if (
					uploadToken.clientId != null &&
					uploadToken.endpointUrl != null &&
					uploadToken.targetCollection != null &&
					uploadToken.token != null &&
					uploadToken.tokenDurationInMins != null
				) {
					const uploadContext = fetchUploadContextTransformer({
						clientId: uploadToken.clientId,
						endpointUrl: uploadToken.endpointUrl,
						targetCollection: uploadToken.targetCollection,
						token: uploadToken.token,
						tokenDurationInMins: uploadToken.tokenDurationInMins,
					});
					store.dispatch(fetchUploadContextSuccess(uploadContext));
				} else {
					store.dispatch(fetchUploadContextFailure({ statusCode: INTERNAL_SERVER_ERROR }));
				}
			} else {
				store.dispatch(fetchUploadContextRequest());
			}

			if (userToken) {
				const userAuth = transformUserContext(userToken);
				store.dispatch(fetchUserAuthSuccess(userAuth));
			}

			const projectKeyFromIssueKey = extractProjectKey(issueKey);

			const giraData = transformGiraData(giraResponse); // This line will go away when all gira data has been migrated
			const aggLegacyGraphqlData = transformAggResponseToLegacyGraphql(aggResponse);
			const aggLegacyGiraData = transformAggResponseToLegacyGira(
				aggResponse,
				projectKeyFromIssueKey,
			);

			if (!aggLegacyGraphqlData) {
				return false;
			}

			const combinedData = combineResponseData(aggLegacyGraphqlData, aggLegacyGiraData, giraData);

			const { project } = combinedData;

			if (project) {
				const issueTypes = combinedData.issueTypes.map(transformIssueType);
				saveProjectContext(toProjectKey(project.projectKey), { ...project, issueTypes });
			}

			if (issueContextActions) {
				issueContextActions.mergeIssueContext({
					...(fg('jira-ai-issue-view-improve-issues-button')
						? {
								isAiEnabledForIssue: combinedData.isAiEnabledForIssue,
							}
						: {}),
					childIssuesLimit: combinedData.childIssuesLimit,
					childIssuesIssueLimitUrls: {
						[SUBTASKS]: combinedData.issue.fields[SUBTASKS]?.issueLimitUrl,
						[CHILDREN_ISSUES]: combinedData.issue.fields[CHILDREN_ISSUES]?.issueLimitUrl,
						[EPIC_ISSUES]: combinedData.issue.fields[EPIC_ISSUES]?.issueLimitUrl,
						[PORTFOLIO_CHILD_ISSUES]:
							combinedData.issue.fields[PORTFOLIO_CHILD_ISSUES]?.issueLimitUrl,
					},
				});
			}

			storeFieldsWithContext(combinedData.issue.fields, project);

			storeIssueContainersLayoutData(combinedData.containersByType);

			savePreferences(combinedData.myPreferences);

			// @ts-expect-error - TS2345 - Argument of type 'Partial<EcosystemExtensions>' is not assignable to parameter of type 'State'.
			storeEcosystemData(combinedData.ecosystem, issueKey);

			store.dispatch(fetchIssueSuccess(combinedData));

			combinedData.attachments &&
				attachmentActions.setVisibleAttachments(issueKey, combinedData.attachments.nodes);
			combinedData.attachments &&
				attachmentActions.setTotalCount(issueKey, combinedData.attachments.totalCount);
			combinedData.attachments &&
				typeof combinedData.attachments.deletableCount === 'number' &&
				attachmentActions.setDeletableCount(issueKey, combinedData.attachments.deletableCount);

			combinedData.permissions &&
				project &&
				permissionActions.setProjectPermissions(
					toProjectKey(project.projectKey),
					combinedData.permissions,
				);

			combinedData.jiraSettings && jiraSettingsActions.setJiraSettings(combinedData.jiraSettings);

			isValidViewScreenId(combinedData.issue.viewScreenId) &&
				issueContextActions &&
				issueContextActions.mergeIssueContext({
					viewScreenId: combinedData.issue.viewScreenId,
				});

			if (associatedIssuesContextActions) {
				associatedIssuesContextActions.mergeLocalAssociatedIssuesContext(
					getAssociatedIssuesContext([
						...combinedData.childrenIssues,
						...combinedData.subtasks,
						...(combinedData.issueLinks?.linkedIssueMetas || []),
					]),
				);
			}

			if (issueContextActions) {
				const dataClassifcationFieldValue =
					combinedData.issue.fields[DATA_CLASSIFICATION_FIELD]?.value || {};

				const isClassifyEditEnabled = !!dataClassifcationFieldValue?.fieldConfig?.isEditable;
				issueContextActions.mergeIssueContext({
					isArchived: combinedData.isArchived,
					isClassifyEditEnabled,
				});
			}

			// This is safe to be switched on even without checking `useIsRelayEnabled` because:
			// populating the issue-id in context from route-resource data at this point is expected regardless of relay's state
			if (issueContextActions && combinedData.issue.id) {
				issueContextActions.mergeIssueContext({
					issueId: toIssueId(String(combinedData.issue.id)),
				});
			}
			return true;
		}
		return false;
	} catch (error) {
		if (error instanceof Error || error instanceof TypeError) {
			const logMessage = 'Failed to fetch from issue prefetched data.';
			trackOrLogClientError('issue.fetch.consume-prefetched-data', logMessage, error);

			store.dispatch(
				fetchIssueFailure({
					error: UNKNOWN_ERROR,
					statusCode: null,
					errorMessage: `${logMessage} ${error.message}`,
				}),
			);
		}
		return false;
	}
};
