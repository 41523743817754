import type { DocNode } from '@atlaskit/adf-schema';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ViewType } from '@atlassian/jira-issue-adjustments/src/common/types/context.tsx';
import type { Field } from '@atlassian/jira-issue-adjustments/src/common/types/field.tsx';
import { baseScopesRequiredPerField } from '@atlassian/jira-issue-adjustments/src/common/utils/filter-based-on-scopes/fields-scopes.tsx';
import { isValidADF } from '@atlassian/jira-issue-adjustments/src/common/utils/is-valid-adf/index.tsx';
import { convertTextToAdf } from '@atlassian/jira-issue-field-textarea/src/ui/edit/utils.tsx';
import { commonIssueViewGranularScopes } from './fields-scopes.tsx';

export const validateRichText = (
	value: unknown,
	_metadata: unknown,
	_createAnalyticsEvent: CreateUIAnalyticsEvent,
	extra: { currentField: Field; viewType: ViewType },
) =>
	isValidADF({
		newValue: value,
		currentValue: extra.currentField?.value,
		viewType: extra.viewType,
	});

export const transformRichTextToPublicValue = (value?: DocNode): DocNode => {
	if (value === undefined) {
		// The `convertTextToAdf` call will give us an "empty ADF object":
		// {
		//   "type": "doc",
		//   "version": 1,
		//   "content": [
		//     {
		//       "type": "paragraph",
		//       "content": []
		//     }
		//   ]
		// }
		return convertTextToAdf('');
	}

	return value;
};

export const descriptionIssueViewScopes = {
	...baseScopesRequiredPerField,
	granular: [...baseScopesRequiredPerField.granular, ...commonIssueViewGranularScopes],
};
