/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { relativeFontSizeToBase16 } from '@atlaskit/editor-shared-styles';
import { N40, N50 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	placeholder?: string;
	onClick?: () => void;
	onFocus?: () => void;
};

export const CommentTextAreaPlaceholder = ({ placeholder, onFocus, onClick }: Props) => {
	const { formatMessage } = useIntl();

	if (fg('jira-a11y-collapsible-editor-update')) {
		return (
			<button
				data-testid="issue-view-activity-comment.comment-add-editor.textarea"
				css={textAreaStyles}
				onClick={onClick}
			>
				{placeholder || formatMessage(messages.defaultPlaceholderMessages)}
			</button>
		);
	}

	return (
		<textarea
			data-testid="issue-view-activity-comment.comment-add-editor.textarea"
			css={textAreaStyles}
			placeholder={placeholder || formatMessage(messages.defaultPlaceholderMessages)}
			onFocus={onFocus}
			onClick={onClick}
		/>
	);
};

// NOTE: Styling gets copied from platform/packages/editor/editor-core/src/ui/ChromeCollapsed/styles.ts and adjusted to the design
// Except the focus visible state which is new
const textAreaFontSize = relativeFontSizeToBase16(14);
const textAreaStyles = css({
	resize: 'none',
	appearance: 'none',
	// NOTE: before changing this.
	// see src/packages/issue/issue-view-activity-comment/src/comment-add-editor/comments-placeholder.tsx:22
	backgroundColor: `var(--jira-issue-view-add-comment-placeholder-background-color, ${token('color.background.input')})`,
	border: `1px solid ${token('color.border.input', N40)}`,
	borderRadius: token('border.radius', '3px'),
	boxSizing: 'border-box',
	height: '40px',
	paddingLeft: token('space.250', '20px'),
	paddingTop: token('space.150', '12px'),
	paddingBottom: token('space.150', '12px'),
	fontFamily: token('font.family.body'),
	fontSize: token('font.body', textAreaFontSize),
	fontWeight: token('font.weight.regular', '400'),
	lineHeight: 1,
	width: '100%',
	letterSpacing: '-0.005em',
	color: token('color.text.subtlest'),
	display: 'inline-flex',
	alignItems: 'flex-start',
	'&:hover': {
		// NOTE: before changing this.
		// see src/packages/issue/issue-view-activity-comment/src/comment-add-editor/comments-placeholder.tsx:22
		backgroundColor: `var(--jira-issue-view-add-comment-placeholder-background-color, ${token('color.background.input.hovered')})`,
		borderColor: token('color.border.input', N50),
		cursor: 'text',
	},
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		outline: `2px solid ${token('color.border.focused')}`,
		outlineOffset: token('space.025', '2px'),
	},
});
