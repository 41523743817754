import React from 'react';
import { styled } from '@compiled/react';
import { ExitingPersistence, SlideIn } from '@atlaskit/motion';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import UploadImageIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/software/components/upload/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import uploadImage from './assets/upload.svg';
import { messages } from './messages.tsx';

const animationDuration = 800;

const DropzoneOverlay = () => {
	const { formatMessage } = useIntl();
	return (
		<ExitingPersistence appear>
			<SlideIn enterFrom="bottom" fade="inout" duration={animationDuration}>
				{(animateProps) => (
					<IssueBodyContainerDropzonePopup {...animateProps}>
						{!fg('move_issue_view_assets_into_central_location') && (
							<Image src={uploadImage} alt={formatMessage(messages.uploadImage)} />
						)}
						{fg('move_issue_view_assets_into_central_location') && (
							<Box xcss={imageWrapperStyles}>
								<UploadImageIllustration alt={formatMessage(messages.uploadImage)} fill />
							</Box>
						)}
						<TextContainer>{formatMessage(messages.upload)}</TextContainer>
					</IssueBodyContainerDropzonePopup>
				)}
			</SlideIn>
		</ExitingPersistence>
	);
};

export default DropzoneOverlay;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueBodyContainerDropzonePopup = styled.div({
	display: 'flex',
	alignItems: 'center',
	position: 'absolute',
	width: '290px',
	height: '64px',
	bottom: token('space.800', '64px'),
	zIndex: 101,
	background: token('elevation.surface.raised', '#ffffff'),
	boxShadow: token(
		'elevation.shadow.raised',
		'0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
	),
	borderRadius: '3px',
	right: 0,
	left: 0,
	marginLeft: 'auto',
	marginRight: 'auto',
	// https://bitbucket.org/atlassian/atlassian-frontend/src/17086749154fbc64e237d814f8ecab9e13daa875/packages/design-system/motion/src/utils/accessibility.tsx#lines-57
	'@media (prefers-reduced-motion: reduce)': {
		animation: 'none',
		transition: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextContainer = styled.div({
	width: '184px',
	font: token('font.heading.small'),
	// MUST OCCUR AFTER `font`
	fontWeight: token('font.weight.medium'),
	color: token('color.text', '#172b4d'),
});
const imageWrapperStyles = xcss({
	height: '51px',
	paddingLeft: 'space.250',
	position: 'relative',
	paddingRight: 'space.250',
	width: '111px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	height: '51px',
	padding: `0 ${token('space.250', '20px')}`,
});
