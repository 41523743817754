import React, { useCallback, useMemo } from 'react';
import noop from 'lodash/noop';
import messages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldEditIcon } from '@atlassian/jira-issue-field-edit-icon/src/FieldEditIcon.tsx';
import { isFieldEditable } from '@atlassian/jira-issue-field-edit-icon/src/utils.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { ReadViewContainer } from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import TagView from '@atlassian/jira-issue-view-internal-tagview/src/tag-view.tsx';
import { useEditFieldConfigContext } from '@atlassian/jira-issue-view-services/src/edit-field-config-context/context.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	useCanAdministerJira,
	useCanAdministerProject,
} from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import CheckboxFieldView, { type CheckboxValueItemShape } from './checkbox-field-view.tsx';
import { InlineEditContainer } from './checkbox-inline-edit-view.styled.tsx';

type Props = {
	isEditable?: boolean;
	isEditing?: boolean;
	isMobile?: boolean;
	issueKey?: IssueKey; // TODO - make issueKey mandatory in BENTO-11149,
	options?: CheckboxValueItemShape[];
	value?: CheckboxValueItemShape[];
	label?: string;
	tagAppearance?: string;
	placeholder?: string;
	noValueText?: string;
	invalidMessage?: string;
	fieldId: string;
	showPinButton?: boolean;
	onChange: (arg1: CheckboxValueItemShape[]) => void;
	onEditRequest: () => void;
	onConfirm: () => void;
	onCancel: () => void;
};

export const CheckboxInlineEditView = ({
	isEditable = false,
	isEditing = false,
	isMobile = false,
	options = [],
	value = [],
	label = '',
	placeholder,
	noValueText,
	tagAppearance = 'default',
	invalidMessage,
	issueKey,
	fieldId,
	showPinButton,
	onChange,
	onEditRequest,
	onConfirm,
	onCancel,
}: Props) => {
	const { formatMessage } = useIntl();
	const { onEditFieldConfigClick } = useEditFieldConfigContext();

	let isJiraAdmin = false;
	let isProjectAdmin = false;

	const renderReadView = useCallback(
		() => (
			<TagView
				value={value.map(({ label: lLabel, value: lValue }) => ({
					content: lLabel,
					value: lValue,
				}))}
				noValueText={noValueText}
				tagAppearance={tagAppearance}
				shouldHideLinks={isMobile}
			/>
		),
		[isMobile, noValueText, tagAppearance, value],
	);

	const renderEditView = useCallback(
		() => (
			<CheckboxFieldView
				value={value}
				options={options}
				onChange={onChange}
				placeholder={placeholder}
				invalidMessage={invalidMessage}
			/>
		),
		[value, onChange, options, placeholder, invalidMessage],
	);

	let handleClickFieldEdit = useCallback(noop, []);

	if (fg('issue_view_field_config_edit')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		handleClickFieldEdit = useCallback(
			() => onEditFieldConfigClick(fieldId),
			[fieldId, onEditFieldConfigClick],
		);
		if (issueKey) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const projectKey = useProjectKey(issueKey);
			// eslint-disable-next-line react-hooks/rules-of-hooks
			isJiraAdmin = useCanAdministerJira(projectKey);
			// eslint-disable-next-line react-hooks/rules-of-hooks
			isProjectAdmin = useCanAdministerProject(projectKey);
		}
	}

	const showEditIcon = useMemo(() => {
		if (!fg('issue_view_field_config_edit')) {
			return false;
		}

		return isFieldEditable(fieldId) && (isJiraAdmin || isProjectAdmin);
	}, [fieldId, isJiraAdmin, isProjectAdmin]);

	return (
		<FieldWrapper data-testid={`issue.views.field.checkbox-inline-edit.${fieldId}`}>
			<FieldHeading fieldId={fieldId}>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				{issueKey !== undefined && fieldId !== undefined && (
					<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={label} />
				)}
				{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
				{showEditIcon && <FieldEditIcon onClick={handleClickFieldEdit} />}
			</FieldHeading>
			<SideBySideField isEditing={isEditing}>
				<InlineEditContainer isEditable={isEditable} hasValue={value && value.length !== 0}>
					<FieldInlineEditStateLess
						isLabelHidden
						label={label}
						readView={<ReadViewContainer>{renderReadView()}</ReadViewContainer>}
						testId={`issue.views.field.checkbox-inline-edit.${fieldId}`}
						editView={isEditable ? renderEditView() : null}
						// @ts-expect-error - TS2322 - Property 'disableEditViewFieldBase' does not exist on type 'IntrinsicAttributes & FieldInlineEditStateLessProps<unknown>'.
						disableEditViewFieldBase
						areActionButtonsHidden
						isEditing={isEditing}
						onConfirm={onConfirm}
						onCancel={onCancel}
						onEscape={onCancel}
						onEditRequested={onEditRequest}
						isFitContainerWidthReadView={!isMobile}
						editButtonLabel={formatMessage(messages.editButtonLabel, {
							fieldName: label,
						})}
						confirmButtonLabel={formatMessage(messages.confirmButtonLabel, {
							fieldName: label,
						})}
						cancelButtonLabel={formatMessage(messages.cancelButtonLabel, {
							fieldName: label,
						})}
						isEditable={isEditable}
						aria-label={label}
					/>
				</InlineEditContainer>
			</SideBySideField>
		</FieldWrapper>
	);
};

export default CheckboxInlineEditView;
