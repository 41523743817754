import React from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { shareAsComment } from '@atlassian/jira-issue-view-store/src/actions/share-as-comment-actions.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
	localeSelector,
	issueIdSelector,
	projectKeySelector,
	siteAdminStatusSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { canUseServiceDeskAgentFeaturesSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { kbGlanceLoadExperience } from '@atlassian/jira-servicedesk-knowledge-glance-v2/src/experiences.tsx';
import type {
	IssueKey,
	BaseUrl,
	IssueId,
	ProjectKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';

type Props = {
	isVisible: boolean;
	isAdmin: boolean;
	issueKey: IssueKey;
	issueId: IssueId;
	baseUrl: BaseUrl;
	locale: Locale;
	projectKey: ProjectKey;
	itemId: string;
	showPinButton?: boolean;
	onShareAsComment: (arg1: ADF) => void;
	isLast?: boolean;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const KbGlanceV2 = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "kb-glance-v2" */ '@atlassian/jira-servicedesk-knowledge-glance-v2/src/ui/kb-glance'
		),
	{ ssr: false },
);

export const KbNestedPanel = ({
	isVisible = true,
	baseUrl,
	issueKey,
	issueId,
	projectKey,
	isAdmin,
	itemId,
	showPinButton,
	isLast,
	onShareAsComment,
}: Props) => {
	if (isVisible) {
		kbGlanceLoadExperience.start();
	}

	return isVisible ? (
		<Placeholder name="kb-glance-v2" fallback={null}>
			<KbGlanceV2
				baseUrl={baseUrl}
				issueKey={issueKey}
				issueId={issueId}
				projectKey={projectKey}
				onShareAsComment={onShareAsComment}
				isAdmin={isAdmin}
				itemId={itemId}
				showPinButton={showPinButton}
				isLast={isLast}
			/>
		</Placeholder>
	) : null;
};

export default flowWithSafeComponent(
	connect(
		(state: State, ownPropsOnMount: Pick<Props, 'itemId' | 'isLast'> & { area: Area }) => ({
			itemId: ownPropsOnMount.itemId,
			isLast: ownPropsOnMount.isLast,
			isVisible: canUseServiceDeskAgentFeaturesSelector(state),
			baseUrl: baseUrlSelector(state),
			issueKey: issueKeySelector(state),
			locale: localeSelector(state),
			// FIXME: JIV-17455 this field can be null
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			issueId: issueIdSelector(state)!,
			projectKey: projectKeySelector(state),
			isAdmin: siteAdminStatusSelector(state) === 'admin',
			showPinButton: getShowPinButton(ownPropsOnMount.area),
		}),
		{
			onShareAsComment: shareAsComment,
		},
	),
)(KbNestedPanel);
