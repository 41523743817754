import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type {
	IntlShapeV2 as IntlShape,
	MessageDescriptorV2 as MessageDescriptor,
} from '@atlassian/jira-intl/src/v2/types.tsx';
import type {
	ProjectKey,
	ProjectId,
	IssueKey,
	IssueTypeId,
} from '@atlassian/jira-shared-types/src/general.tsx';

export type RestrictionType = string;
export const ISSUE: RestrictionType = 'ISSUE'; // RestrictionType is passed from IssueView and IssueType screen to differentiate the update API for issueRestriction
export const ISSUE_TYPE: RestrictionType = 'ISSUE_TYPE';

export type ProjectKeyOrId = ProjectKey | ProjectId;
export type Entity = IssueKey | IssueTypeId;

export type MessageObjProps = {
	text: MessageDescriptor;
	intl: IntlShape;
};
export type IssueRestrictionsContext = {
	baseUrl: string;
	projectId: number;
	cloudId: string;
	projectKey: string;
	projectName: string;
	projectType: ProjectType;
	isAdmin: boolean;
};

export type Role = {
	self?: string;
	id: number;
	name: string;
	description: string;
	admin?: boolean;
	default?: boolean;
	scope?: {
		type: string;
		project: {
			id: string;
		};
	};
	currentUserRole: boolean;
};

export type TransformedRole = {
	self?: string;
	restrictionValue: string;
	name: string;
	description: string;
	admin?: boolean;
	default?: boolean;
	scope?: {
		type: string;
		project: {
			id: string;
		};
	};
	currentUserRole: boolean;
};
export type Restriction = {
	restrictionValue: string;
};

export type AllRestrictions = Restriction[];

export type CurrentRestrictions = Restriction[];

export type OnRoleChanged = (restrictions: TransformedRole[]) => void;

export type UpdateRestrictions = (id: string, roles: Restriction[]) => Promise<void>;

export type OnUpdateClicked = () => void;

export type OnCancelClicked = () => void;

export type OnUpdated = (restrictions: CurrentRestrictions) => void;
