import React, { Component, useCallback, useEffect, useRef, useMemo } from 'react';
import type { Store } from 'redux';
import { AiIssueBreakdownEntryPointContextProvider } from '@atlassian/jira-ai-work-breakdown/src/controllers/context-provider.tsx';
import { IssueBreakdownAiContainer } from '@atlassian/jira-ai-work-breakdown/src/controllers/context.tsx';
import { useAssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/context.tsx';
import AppStyle from '@atlassian/jira-common-components-app-style/src/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { FlagsDispatcher } from '@atlassian/jira-flags';
import {
	useOnIssueKeyChange,
	useIssueKey,
	useProjectKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { OnIssueKeyChangeFn } from '@atlassian/jira-issue-context-service/src/types.tsx';
import { useFieldConfigActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldsValuesActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import AppBase from '@atlassian/jira-issue-view-app-base/src/index.tsx';
import { statusNone } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	CHILDREN_ISSUES,
	EPIC_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
	SUBTASKS,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { useHasExceededChildIssuesLimitAfterLoad } from '@atlassian/jira-issue-view-services/src/child-issues/use-has-exceeded-child-issues-limit/index.tsx';
import { isShallowEqual } from '@atlassian/jira-platform-shallow-equal/src/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectId,
	useProjectType,
	useIsSimplifiedProject,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import {
	toIssueKey,
	toIssueId,
	toProjectId,
	toBaseUrl,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import flagMapper from './flags/index.tsx';
import { ChildIssuesContainer } from './hooks/context.tsx';
import { useSortableIssueIds } from './hooks/main.tsx';
import {
	CLASSIC_PORTFOLIO_CHILDREN,
	CLASSIC_PROJECT_EPIC_CHILDREN,
	CLASSIC_SUBTASKS,
	CHILDREN_ISSUES_PANEL,
} from './model/types.tsx';
import { transformIssueType } from './model/util.tsx';
import rootEpic from './ops/index.tsx';
import {
	setContext,
	setOnChange,
	requiresFullCreateCheckRequest,
} from './state/context/actions.tsx';
import { isWhitelistedPanelForSorting } from './state/context/selectors.tsx';
import { setEntities, fetchDetailsForIssuesRequest } from './state/entities/actions.tsx';
import createStore from './state/index.tsx';
import type { State, Props } from './state/types.tsx';
import { setFetchIssueTypesStatus, showInlineCreate } from './state/ui/actions.tsx';
import { useChildrenIssuesWithData, useChildrenIssuesWithDataOld } from './utils.tsx';
import View from './view/index.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class ChildIssuesPanel extends Component<Props> {
	static defaultProps = {
		issues: [],
		issueTypes: [],
		isIssueViewComplete: false,
	};

	constructor(props: Props) {
		super(props);

		const {
			issues,
			parentKey,
			childIssuesPanelType,
			associatedIssuesContextActions,
			fieldValuesActions,
			fieldConfigActions,
		} = this.props;

		this.store = createStore(
			rootEpic(associatedIssuesContextActions, fieldValuesActions, fieldConfigActions),
			parentKey,
			childIssuesPanelType,
		);

		this.dispatchContext();

		// Dispatch event to make requests to check if full create is required
		this.store.dispatch(requiresFullCreateCheckRequest());

		this.dispatchEntities();

		if (childIssuesPanelType !== CLASSIC_SUBTASKS) {
			if (issues.length > 0) {
				this.store.dispatch(fetchDetailsForIssuesRequest());
			}
		}

		const { fetchIssueTypesStatus } = props;
		if (fetchIssueTypesStatus) {
			this.store.dispatch(setFetchIssueTypesStatus(fetchIssueTypesStatus));
		}
	}

	componentDidMount() {
		const { quickAddClickCount } = this.props;

		if (quickAddClickCount > 0) {
			this.store.dispatch(showInlineCreate());
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (isShallowEqual(prevProps, this.props)) {
			return;
		}

		const { issues, quickAddClickCount, fetchIssueTypesStatus, onChange } = this.props;

		if (quickAddClickCount > prevProps.quickAddClickCount) {
			this.store.dispatch(showInlineCreate());
		}

		if (fetchIssueTypesStatus !== prevProps.fetchIssueTypesStatus) {
			this.store.dispatch(setFetchIssueTypesStatus(fetchIssueTypesStatus || statusNone));
		}

		// realistically we could compare all props read by `dispatchContext` for this if...
		// but at this stage it is safer to compare them one by one and "migrate" as needed :(
		if (prevProps.onChange !== onChange) {
			this.store.dispatch(setOnChange(onChange));
		}

		if (
			issues.length ||
			(prevProps.issues.length > 0 && issues.length === 0) ||
			fetchIssueTypesStatus !== prevProps.fetchIssueTypesStatus
		) {
			this.dispatchEntities();
		}
	}

	dispatchEntities() {
		const { issueTypes, issues, epicLinkFieldKey, issueHierarchyLevel } = this.props;
		this.store.dispatch(
			setEntities({
				issueTypes: issueTypes.map((issueType) => transformIssueType(issueType, epicLinkFieldKey)),
				issues,
				issueHierarchyLevel,
			}),
		);
	}

	dispatchContext() {
		const {
			supportsIssueCreation,
			baseUrl,
			locale,
			user,
			parentKey,
			parentIssueTypeId,
			customFieldIdRank,
			epicLinkFieldKey,
			parentId,
			projectId,
			projectType,
			isSimplifiedProject,
			messages,
			onNavigateToNewIssue,
			onChange,
			childIssuesPanelType,
			isIssueViewComplete,
			onEditAssociatedIssue,
			filterSubtasks,
		} = this.props;

		let application = null;
		let edition = null;

		if (projectType !== null) {
			const { appEditions } = this.props;
			application = getApplicationForProject(projectType);
			edition = getEdition(application, appEditions);
		}

		this.store.dispatch(
			setContext({
				baseUrl,
				locale,
				user,
				projectId,
				parentId,
				parentKey,
				parentIssueTypeId,
				customFieldIdRank,
				epicLinkFieldKey,
				supportsIssueCreation,
				messages,
				onNavigateToNewIssue,
				onChange,
				childIssuesPanelType,
				isIssueViewComplete,
				projectType,
				application,
				edition,
				// Optimistically assume full create not needed
				requiresFullCreate: false,
				filterSubtasks,
				// if the endpoint doesn't return data quick enough, optimistically assume full create not needed
				requiresFullCreateByIssueType: {},
				onEditAssociatedIssue,
				...{
					isSimplifiedProject,
				},
			}),
		);
	}

	store: Store<State>;

	render() {
		const {
			onAddChildClick,
			onIssueBreakdownClick,
			childIssuesPanelType,
			analyticsSource,
			issueHierarchyLevel,
			childIssuesLimitUrl,
			hasExceededIssuesLimitAfterLoad,
			totalChildIssueCount,
		} = this.props;

		return (
			<AppBase store={this.store}>
				<ContextualAnalyticsData
					sourceName="childIssuesPanel"
					sourceType={SCREEN}
					attributes={{
						childIssuesPanelType,
						issueHierarchyLevel: issueHierarchyLevel - 1,
						analyticsSource: `issue-view (${analyticsSource})`,
						location: 'jiraChildIssues',
					}}
				>
					<AppStyle>
						<View
							onAddChildClick={onAddChildClick}
							onIssueBreakdownClick={onIssueBreakdownClick}
							sourceName="childIssuesPanel"
							childIssuesLimitUrl={childIssuesLimitUrl}
							hasExceededIssuesLimitAfterLoad={hasExceededIssuesLimitAfterLoad}
							totalChildIssueCount={totalChildIssueCount}
						/>
						<FlagsDispatcher mapper={flagMapper} />
					</AppStyle>
				</ContextualAnalyticsData>
			</AppBase>
		);
	}
}

const WrappedSortingChildIssuesPanel = (props: Props) => {
	const { issues } = props;

	const initialRenderRef = useRef(true);
	const [, { setSortableIssueIds }] = useSortableIssueIds();

	useEffect(() => {
		if (initialRenderRef.current === true) {
			setSortableIssueIds(issues.map((issue) => toIssueId(issue.id)));
			initialRenderRef.current = false;
		}
	});

	return <ChildIssuesPanel {...props} />;
};

export const CHILD_ISSUES_KEY_MAP = {
	[CHILDREN_ISSUES_PANEL]: CHILDREN_ISSUES,
	[CLASSIC_PROJECT_EPIC_CHILDREN]: EPIC_ISSUES,
	[CLASSIC_SUBTASKS]: SUBTASKS,
	[CLASSIC_PORTFOLIO_CHILDREN]: PORTFOLIO_CHILD_ISSUES,
} as const;

const WrappedStateRefactorChildIssuesPanelOld = (props: Props) => {
	const { childIssuesPanelType, issues: reduxIssues } = props;

	const childIssues = useChildrenIssuesWithDataOld(CHILD_ISSUES_KEY_MAP[childIssuesPanelType]);
	// make a condition to use data from sweet-state that we've managed to get complete data from it
	const validChildIssues = childIssues.every((issue) => Boolean(issue.issueSummary));

	// use collection from global redux by default
	let issues = reduxIssues;
	if (
		childIssues.length > 0 &&
		validChildIssues &&
		reduxIssues.length === childIssues.length &&
		isWhitelistedPanelForSorting(childIssuesPanelType)
	) {
		issues = childIssues.map((issue, idx) => ({
			...issue,
			// it is cumbersome at this stage to get the estimate field details using
			// just hooks so we copy them over from the Redux issues provided for now.
			// NOTE 1: this is only the case because the internals of this component are not
			// being changed, so all data is being handled by the "root" component
			// instead of where they are used/rendered primarily.
			// NOTE 2: it is not possible to use `useFieldValuesForIssues` as each child issue
			// could belong to a different project configuration.
			estimateFieldId: reduxIssues[idx].estimateFieldId,
			estimateValue: reduxIssues[idx].estimateValue,
		}));
	}

	return <WrappedSortingChildIssuesPanel {...props} issues={issues} />;
};

const WrappedStateRefactorChildIssuesPanelNew = (props: Props) => {
	const { childIssuesPanelType } = props;

	const childIssues = useChildrenIssuesWithData(CHILD_ISSUES_KEY_MAP[childIssuesPanelType]);

	return <WrappedSortingChildIssuesPanel {...props} issues={childIssues} />;
};

const WrappedStateRefactorChildIssuesPanel = componentWithCondition(
	() => true,
	WrappedStateRefactorChildIssuesPanelNew,
	WrappedStateRefactorChildIssuesPanelOld,
);

type ChildIssuesPanelWithHooksProps = Omit<
	Props,
	'appEditions' | 'parentKey' | 'projectId' | 'user' | 'baseUrl' | 'associatedIssuesContextActions'
>;

const ChildIssuesPanelWithHooks = (props: ChildIssuesPanelWithHooksProps) => {
	const { onNavigateToNewIssue } = props;
	const onIssueKeyChange = useOnIssueKeyChange();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey();
	const projectId = useProjectId(projectKey);
	const projectType = useProjectType(projectKey);
	const isSimplifiedProject = useIsSimplifiedProject(projectKey);
	const baseUrl = toBaseUrl('');
	const user = useAccountId();
	const locale = useLocale();
	const appEditions = useAppEditions();

	const associatedIssuesContextActions = useAssociatedIssuesContextActions();

	const [, fieldValueActions] = useFieldsValuesActions();

	const [, fieldsConfigActions] = useFieldConfigActions();

	const onNavigateToNewIssueCallback = useCallback<OnIssueKeyChangeFn>(
		(args) => {
			onNavigateToNewIssue(args);
			onIssueKeyChange?.(args);
		},
		[onIssueKeyChange, onNavigateToNewIssue],
	);

	const childIssueFieldKey = CHILD_ISSUES_KEY_MAP[props.childIssuesPanelType];
	const hasExceededIssuesLimitAfterLoad = childIssueFieldKey
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useHasExceededChildIssuesLimitAfterLoad(childIssueFieldKey)
		: props.hasExceededIssuesLimitAfterLoad;

	return (
		<WrappedStateRefactorChildIssuesPanel
			{...props}
			onNavigateToNewIssue={onNavigateToNewIssueCallback}
			parentKey={issueKey || toIssueKey('')}
			projectId={toProjectId(projectId || '')}
			projectType={projectType || null}
			isSimplifiedProject={isSimplifiedProject}
			baseUrl={baseUrl}
			user={user}
			locale={locale}
			appEditions={appEditions}
			associatedIssuesContextActions={associatedIssuesContextActions}
			fieldValuesActions={fieldValueActions}
			fieldConfigActions={fieldsConfigActions}
			hasExceededIssuesLimitAfterLoad={hasExceededIssuesLimitAfterLoad}
		/>
	);
};

const DecoratedComponent = (props: ChildIssuesPanelWithHooksProps) => {
	const { childIssuesPanelType } = props;
	const issueKey = useIssueKey();
	const renderAiIssueBreakdown = useMemo(
		() =>
			fg('jira-ai-issue-view-improve-issues-button') ? (
				<IssueBreakdownAiContainer scope={`${issueKey}-${childIssuesPanelType}-issue-breakdown`}>
					<ChildIssuesPanelWithHooks {...props} />
				</IssueBreakdownAiContainer>
			) : (
				<AiIssueBreakdownEntryPointContextProvider>
					<IssueBreakdownAiContainer scope={`${issueKey}-${childIssuesPanelType}-issue-breakdown`}>
						<ChildIssuesPanelWithHooks {...props} />
					</IssueBreakdownAiContainer>
				</AiIssueBreakdownEntryPointContextProvider>
			),
		[issueKey, childIssuesPanelType, props],
	);

	return (
		<ErrorBoundary id="issue.issue-view.common.child-issues-panel">
			<ReportErrors
				id="common.child-issues-panel"
				packageName="jiraIssueViewCommonViews"
				sendToPrivacyUnsafeSplunk
			>
				<ChildIssuesContainer scope={`${issueKey}-${childIssuesPanelType}`}>
					{renderAiIssueBreakdown}
				</ChildIssuesContainer>
			</ReportErrors>
		</ErrorBoundary>
	);
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	CLASSIC_SUBTASKS,
	CLASSIC_PROJECT_EPIC_CHILDREN,
	CLASSIC_PORTFOLIO_CHILDREN,
	CHILDREN_ISSUES_PANEL,
} from './model/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ChangeEventTypes } from './model/on-change-callback';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	ChildIssueAddedEvent,
	ChangeEvent,
	OnChangeCallback,
} from './model/on-change-callback';

export default DecoratedComponent;
