import React, { useCallback, useRef, useEffect, useState } from 'react';
import { css, styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { EditorActions } from '@atlaskit/editor-core';
import type { MentionProvider } from '@atlaskit/mention';
import type { TaskDecisionProvider } from '@atlaskit/task-decision';
import { token } from '@atlaskit/tokens';
import type { ActivityProvider } from '@atlassian/activity-provider';
import inlineEditMessages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useAcronymHighlighter } from '@atlassian/jira-highlight-actions/src/controllers/use-acronym-highlighter/index.tsx';
import { useOnHighlight } from '@atlassian/jira-highlight-actions/src/ui/utils.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type.tsx';
import InlineEditStateless from '@atlassian/jira-issue-view-common/src/component/inline-edit/index.tsx';
import RendererWithMediaProvider from '@atlassian/jira-issue-view-media-renderer/src/index.tsx';
import { removeNewLineCharactersFromAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import type { ContextIdentifier } from '@atlassian/jira-rich-content/src/index.tsx';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';
import RichTextFieldView from './rich-text-field-view.tsx';

const containerSelectorName = 'jira-issue-view-rich-text-inline-edit-view-container';
const CONTAINER_COMPONENT_SELECTOR = `[data-component-selector="${containerSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	width: '100%',
});

const nonEditableStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[CONTAINER_COMPONENT_SELECTOR]: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		wordBreak: 'break-word',
		boxSizing: 'border-box',
		paddingTop: token('space.025', '2px'),
		paddingBottom: token('space.025', '2px'),
		paddingLeft: token('space.075', '6px'),
		paddingRight: token('space.100', '8px'),
		marginLeft: token('space.negative.100', '-8px'),
		marginTop: token('space.negative.150', '-12px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		minHeight: `${gridSize * 4}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditContainer = styled.div<{ isEditable: boolean }>(
	{
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			marginLeft: token('space.negative.100', '-8px'),
			marginTop: token('space.negative.100', '-8px'),
			display: 'flex',
			alignItems: 'center',
			wordBreak: 'break-word',
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: token('space.075', '6px'),
			paddingRight: token('space.075', '6px'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => (!isEditable ? nonEditableStyles : undefined),
);

export type Props = {
	isEditable?: boolean;
	isEditing?: boolean;
	isMobile?: boolean;
	fieldId: string;
	adfValue?: ADF | undefined;
	portalElement?: HTMLElement;
	baseUrl: BaseUrl;
	mediaContext: MediaContext;
	mentionProvider: MentionProvider;
	contextIdentifier?: ContextIdentifier;
	// eslint-disable-next-line jira/react/handler-naming
	mentionEncoder?: (arg1: string) => string;
	activityProvider: ActivityProvider | null;
	editorTaskDecisionProvider?: TaskDecisionProvider;
	rendererTaskDecisionProvider?: TaskDecisionProvider;
	editorActionsRef?: React.MutableRefObject<EditorActions | null>;
	onEditorExpanded?: () => void;
	externalId: string;
	noValueText: string;
	label?: string;
	onEditRequest: () => void;
	onCancel: () => void;
	onConfirm: () => void;
	onChange: (arg1: ADF | string) => void;
	onBlur: () => void;
	// go/jfe-eslint

	onExpandedFailure: () => void;
	onSaveFailure: () => void;
	onChangeFailure: () => void;
	onExperienceSuccess?: () => void;
	onPaste: (arg1: string) => void;
	onViewRefresh?: () => void;
	onUploadRefresh?: () => void;
	onEditorReady?: (arg1: ADF) => void;
};

export const RichTextInlineEditView = ({
	isEditable = false,
	isEditing = false,
	isMobile = false,
	label = '',
	adfValue = undefined,
	contextIdentifier = undefined,
	onExperienceSuccess = noop,
	fieldId,
	portalElement,
	baseUrl,
	mediaContext,
	mentionProvider,
	mentionEncoder,
	activityProvider,
	editorTaskDecisionProvider,
	rendererTaskDecisionProvider,
	externalId,
	noValueText,
	editorActionsRef,
	onEditRequest,
	onCancel,
	onConfirm,
	onChange,
	onBlur,
	onSaveFailure,
	onChangeFailure,
	onPaste,
	onViewRefresh,
	onUploadRefresh,
	onEditorReady,
}: Props) => {
	const { formatMessage } = useIntl();
	const [readViewRef, setReadViewRef] = useState<React.RefObject<HTMLDivElement>>({
		current: null,
	});
	const readViewRefCallback = useCallback(
		(node: HTMLDivElement) => setReadViewRef({ current: node }),
		[],
	);

	const mouseDownSelection = useRef<unknown>(null);

	const issueKey = useIssueKey();

	const { highlightAcronyms, resetAcronymsData } = useAcronymHighlighter(readViewRef);

	useEffect(() => {
		if (fieldId === 'description') {
			if (readViewRef.current) {
				highlightAcronyms(adfValue);
				return resetAcronymsData;
			}
		}
	}, [adfValue, fieldId, highlightAcronyms, readViewRef, resetAcronymsData]);

	const { register, unregister } = useOnHighlight(readViewRef);

	useEffect(() => {
		if (fieldId === 'description') {
			if (!isEditing) {
				register();
			}
			return unregister;
		}
	}, [unregister, register, isEditing, fieldId]);

	const [onExperienceSuccessInState] = useState(() => onExperienceSuccess);

	useEffect(() => {
		onExperienceSuccessInState();
	}, [onExperienceSuccessInState]);

	const onMouseDownEditView = useCallback(() => {
		// When the user is deselecting text on the screen by clicking,
		// by the time the onclick handler is called the window.getSelection() value will already be cleared.
		// mousedown callback is called before the selection is cleared.

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		mouseDownSelection.current = window?.getSelection()?.toString();
	}, []);

	const onEditRequested = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const selection = window?.getSelection()?.toString();
		const hasSelection = selection && selection.length !== 0;
		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		const hasSelectionMouseDown = mouseDownSelection.current?.length !== 0;

		if (!hasSelection && !hasSelectionMouseDown) {
			onEditRequest();
		}
	}, [onEditRequest]);

	const getEditValue = useCallback(() => adfValue, [adfValue]);

	const addContentButtonLabel = inlineEditMessages.addButtonLabel;
	const editContentButtonLabel = inlineEditMessages.editButtonLabel;

	const [editButtonLabel, setEditButtonLabel] = useState(addContentButtonLabel);

	const updateLabel = useCallback(
		(contentLength?: number) => {
			if (!contentLength) {
				setEditButtonLabel(addContentButtonLabel);
				return;
			}
			if (editButtonLabel === editContentButtonLabel) {
				return;
			}
			setEditButtonLabel(editContentButtonLabel);
		},
		[editButtonLabel, addContentButtonLabel, editContentButtonLabel],
	);

	useEffect(() => {
		updateLabel(getEditValue()?.content?.length);
	}, [getEditValue, updateLabel]);

	const renderContent = useCallback(
		() => (
			<Container data-component-selector={containerSelectorName}>
				<RendererWithMediaProvider
					key={`issue-${issueKey}-field-${fieldId}-renderer-with-media-provider`}
					mediaContext={mediaContext}
					onViewRefresh={onViewRefresh}
					onUploadRefresh={onUploadRefresh}
					mentionProvider={mentionProvider}
					taskDecisionProvider={rendererTaskDecisionProvider}
					contextIdentifier={contextIdentifier}
					adf={removeNewLineCharactersFromAdf(adfValue)}
					disableActions={
						expVal('issue_view_action_items', 'isActionItemsEnabled', false)
							? !isEditable
							: undefined
					}
					placeholder={
						isEditable ? noValueText : formatMessage(messages.nonEditablePlaceholderText)
					}
				/>
			</Container>
		),
		[
			adfValue,
			contextIdentifier,
			fieldId,
			formatMessage,
			issueKey,
			isEditable,
			mediaContext,
			mentionProvider,
			rendererTaskDecisionProvider,
			noValueText,
			onUploadRefresh,
			onViewRefresh,
		],
	);

	const renderReadView = useCallback(
		() => (
			<div
				onMouseDown={onMouseDownEditView}
				role="presentation"
				data-testid={`issue.views.field.rich-text.${fieldId}`}
				{...(fieldId === 'description' ? { ref: readViewRefCallback } : {})}
			>
				<InlineEditContainer isEditable={isEditable}>
					<InlineEditStateless
						readView={renderContent || null}
						isEditing={false}
						isEditable={isEditable}
						editButtonLabel={formatMessage(editButtonLabel, {
							fieldName: label,
						})}
						confirmButtonLabel={formatMessage(inlineEditMessages.confirmButtonLabel, {
							fieldName: label,
						})}
						cancelButtonLabel={formatMessage(inlineEditMessages.cancelButtonLabel, {
							fieldName: label,
						})}
						onEdit={onEditRequested}
						// InlineEdit expects 'editView' to be defined
						// if field is editable otherwise it should be undefined
						editView={isEditable ? () => <div /> : null}
						// These are required props for InlineEdit
						// but we are only interested in the read view and hover+click to edit
						onConfirm={noop}
						onCancel={noop}
						readViewFitContainerWidth={!isMobile}
					/>
				</InlineEditContainer>
			</div>
		),
		[
			editButtonLabel,
			fieldId,
			formatMessage,
			isEditable,
			isMobile,
			label,
			readViewRefCallback,
			onEditRequested,
			onMouseDownEditView,
			renderContent,
		],
	);

	const renderEditView = useCallback(
		() => (
			<RichTextFieldView
				baseUrl={baseUrl}
				mediaContext={mediaContext}
				onViewRefresh={onViewRefresh}
				onUploadRefresh={onUploadRefresh}
				portalElement={portalElement}
				mentionProvider={mentionProvider}
				taskDecisionProvider={editorTaskDecisionProvider}
				mentionEncoder={mentionEncoder}
				contextIdentifier={contextIdentifier}
				activityProvider={activityProvider}
				externalId={externalId}
				// If the field is invalid and there is no message, it means we have
				// most likely reached this scenario: https://jdog.jira-dev.com/browse/BENTO-2012
				// This means we block the editor from being touched until a page refresh
				isDisabled={undefined}
				editorActionsRef={editorActionsRef}
				isExpanded
				shouldFocus
				value={getEditValue()}
				onCancel={onCancel}
				onChange={onChange}
				onBlur={onBlur}
				onSave={onConfirm}
				onSaveFailure={onSaveFailure}
				onChangeFailure={onChangeFailure}
				onPaste={onPaste}
				assistiveLabel={label}
				{...(fg('jira-ai-issue-view-improve-issues-button') ? { onEditorReady } : {})}
			/>
		),
		[
			activityProvider,
			baseUrl,
			contextIdentifier,
			editorActionsRef,
			externalId,
			getEditValue,
			label,
			mediaContext,
			mentionEncoder,
			mentionProvider,
			editorTaskDecisionProvider,
			onBlur,
			onCancel,
			onChange,
			onChangeFailure,
			onConfirm,
			onPaste,
			onSaveFailure,
			onUploadRefresh,
			onViewRefresh,
			portalElement,
			onEditorReady,
		],
	);

	return isEditing ? renderEditView() : renderReadView();
};

export default RichTextInlineEditView;
