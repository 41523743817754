import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	cardHeading: {
		id: 'issue-view-foundation.quick-add.create-confluence-content-menu.issue-view-nudge-experiment.card-heading',
		defaultMessage: 'Create Confluence docs from Jira issues',
		description: 'The content is rendered in the heading for the spotlight card',
	},
	messageEpic: {
		id: 'issue-view-foundation.quick-add.create-confluence-content-menu.issue-view-nudge-experiment.message-epic',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Use templates for project requirements, meeting notes, blogs, and more.',
		description: 'The content is rendered in the body of the spotlight card for epics',
	},
	messageBug: {
		id: 'issue-view-foundation.quick-add.create-confluence-content-menu.issue-view-nudge-experiment.message-bug',
		defaultMessage:
			'Use templates for incident reports, troubleshooting articles, meeting notes, and more.',
		description: 'The content is rendered in the body of the spotlight card for bugs',
	},
	messageStory: {
		id: 'issue-view-foundation.quick-add.create-confluence-content-menu.issue-view-nudge-experiment.message-story',
		defaultMessage: 'Use templates for project requirements, meeting notes, and more.',
		description: 'The content is rendered in the body of the spotlight card for stories',
	},
	messageTask: {
		id: 'issue-view-foundation.quick-add.create-confluence-content-menu.issue-view-nudge-experiment.message-task',
		defaultMessage: 'Use templates for technical specs, how-to articles, reports, and more.',
		description: 'The content is rendered in the body of the spotlight card for tasks',
	},
	messageSubTask: {
		id: 'issue-view-foundation.quick-add.create-confluence-content-menu.issue-view-nudge-experiment.message-sub-task',
		defaultMessage: 'Use templates for technical specs, how-to articles, reports, and more.',
		description: 'The content is rendered in the body of the spotlight card for sub-tasks',
	},
	ok: {
		id: 'issue-view-foundation.quick-add.create-confluence-content-menu.issue-view-nudge-experiment.ok',
		defaultMessage: 'OK',
		description: 'The content is rendered in the spotlight card button.',
	},
});

export default messages;
