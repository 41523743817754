import React from 'react';
import Select from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import { defaultSelectStyles } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/styled.tsx';
import type { IssueLinkType } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import messages from './messages.tsx';

export type SelectItemType = {
	value: string;
	label: string;
	fullObject: IssueLinkType;
};

type Props = {
	selectItems: SelectItemType[];
	selectedItem: SelectItemType;
	onChange: (arg1: SelectItemType) => void;
};

export const IssueLinkTypeSelect = (props: Props) => {
	const { selectItems, selectedItem, onChange } = props;
	const { formatMessage } = useIntl();

	return (
		<Select
			aria-label={formatMessage(messages.typeSelectAriaLabel)}
			options={selectItems}
			defaultValue={selectedItem}
			styles={defaultSelectStyles}
			// @ts-expect-error - TS2322 - Type '(arg1: SelectItemType) => void' is not assignable to type '(value: SelectItemType | null, action: ActionMeta<SelectItemType>) => void'.
			onChange={onChange}
		/>
	);
};

IssueLinkTypeSelect.defaultProps = {
	selectedItem: null,
};
