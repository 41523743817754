export const CONJUNCTION = 'conjunction';
export const DISJUNCTION = 'disjunction';
export const NEGATION = 'negation';
export const INCLUDE_STRING_LIST_PROPERTY = 'includeInStringListProperty';
export const CONSTANT = 'const';

export const TRUE_CONSTANT = {
	template: CONSTANT,
	parameters: { value: true },
} as const;

export const FALSE_CONSTANT = {
	template: CONSTANT,
	parameters: { value: false },
} as const;

export type IncludeInStringListProperty = {
	template: typeof INCLUDE_STRING_LIST_PROPERTY;
	parameters: {
		propertyKey: string;
		values: string[];
	};
};

export type Conjunction = {
	template: typeof CONJUNCTION;
	parameters: {
		filters: RecursiveFilter[];
	};
};

export type Disjunction = {
	template: typeof DISJUNCTION;
	parameters: {
		filters: RecursiveFilter[];
	};
};

export type Negation = {
	template: typeof NEGATION;
	parameters: {
		formula: RecursiveFilter;
	};
};

export type Constant = {
	template: typeof CONSTANT;
	parameters: {
		value: boolean;
	};
};

export type RecursiveFilter =
	| Conjunction
	| Disjunction
	| Negation
	| Constant
	| IncludeInStringListProperty;
