import React, { type FC, useMemo } from 'react';
import Button from '@atlaskit/button';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss, Inline, type Space } from '@atlaskit/primitives';
import { Hide, Show } from '@atlaskit/primitives/responsive';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { useIntl } from '@atlassian/jira-intl';
import { AiIcon } from '../ai-icon/index.tsx';
import { AiInformationIcon } from '../ai-information-icon/index.tsx';
import messages from './messages.tsx';

export type Props = {
	testId?: string;
	attribution?: string;
	disclaimer?: string;
	footerSpace?: Space;
	isBeta?: boolean;
};

export const AiFooter: FC<Props> = ({
	testId,
	footerSpace,
	disclaimer,
	attribution,
	isBeta = true,
}) => {
	const { formatMessage } = useIntl();

	// Render conditions based on media queries
	// empty if <= xs
	// short text if <= sm size
	// long text if >= sm size
	const renderAttributionText = useMemo(
		() => (
			<>
				<Show below="xs" as="span">
					<VisuallyHidden>{formatMessage(messages.longAttributionText)}</VisuallyHidden>
				</Show>
				<Hide below="xs" as="span">
					<Show below="sm" as="span">
						{formatMessage(messages.shortAttributionText)}
					</Show>
					<Hide below="sm" as="span">
						{formatMessage(messages.longAttributionText)}
					</Hide>
				</Hide>
			</>
		),
		[formatMessage],
	);

	return (
		<Inline
			testId={testId}
			xcss={footerStyle}
			spread="space-between"
			alignBlock="center"
			space={footerSpace ?? 'space.050'}
		>
			<AiInformationIcon />
			<Button
				appearance="subtle-link"
				spacing="none"
				href="https://www.atlassian.com/trust/atlassian-intelligence"
				rel="noopener"
				target="_blank"
				testId="atlassian-intelligence.common.ui.ai-footer.disclaimer-text"
			>
				<Box as="span" xcss={linkStyle}>
					{disclaimer ?? formatMessage(messages.disclaimerText)}
				</Box>
			</Button>
			{isBeta ? (
				<Lozenge testId="atlassian-intelligence.common.ui.ai-footer.beta-lozenge" appearance="new">
					{formatMessage(messages.betaLozenge)}
				</Lozenge>
			) : null}

			<Box xcss={spacerStyle} />
			<Box as="span" xcss={textStyle}>
				{attribution ?? renderAttributionText}
			</Box>
			<AiIcon isDisabled size="small" label="" />
		</Inline>
	);
};

const spacerStyle = xcss({
	flexGrow: 1,
});

const footerStyle = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border', colors.N40)}`,
	paddingInline: 'space.150',
	paddingBlock: 'space.150',
});

const textStyle = xcss({
	color: 'color.text.subtlest',
	margin: 'space.0',
	padding: 'space.0',
	display: 'flex',
	alignItems: 'center',
});

const linkStyle = xcss({
	textDecoration: 'none',
	fontWeight: 'normal',
	color: 'color.text.subtlest',
});
