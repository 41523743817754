import uuid from 'uuid/v4';

import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { ExtractInjectionAPI, PMPluginFactoryParams } from '@atlaskit/editor-common/types';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';

import type { AIPlugin } from '../../editor-plugin-ai';
import type { ProactiveAIConfig } from '../../types';

import { aiSpellingGrammarPluginKey } from './ai-spelling-grammar-plugin-key';
import { createTriggerSpellingAndGrammarCheck } from './commands';
import {
	ignoreSuggestionWithAnalytics,
	viewSuggestionWithAnalytics,
} from './commands-with-analytics';
import { DocumentSGChecker } from './document-sg-checker';
import { createInitialState, createPluginState, getPluginState } from './plugin-factory';
import {
	getBlockOrSentenceFromDiffObject,
	getSelectedDiffObject,
	isDiffObjectInPluginState,
} from './utils';

/**
 * Creating instance of documentSGChecker outside of create plugin
 * 	because it gets called multiple times when Editor Loads.
 * And we don't need to re-create documentSGChecker each time.
 * Instead we just need to reset it.
 */
const documentSGChecker = new DocumentSGChecker();
export function createAISpellingGrammarPlugin(options: {
	dispatch: PMPluginFactoryParams['dispatch'];
	getIntl: PMPluginFactoryParams['getIntl'];
	proactiveAIConfig: ProactiveAIConfig;
	api: ExtractInjectionAPI<AIPlugin> | undefined;
}) {
	const proactiveAIConfig = options.proactiveAIConfig;
	const { triggerSpellingAndGrammarCheck, cancelDebouncedAndThrottledSpellingAndGrammarCheck } =
		createTriggerSpellingAndGrammarCheck(proactiveAIConfig.timings);
	const { documentSGChecker: documentSGCheckerConfig } = proactiveAIConfig;
	const documentSGCheckerEnabled = documentSGCheckerConfig?.enabled;

	return new SafePlugin({
		key: aiSpellingGrammarPluginKey,
		state: createPluginState(
			options.dispatch,
			createInitialState({
				proactiveAIApiUrl: options.proactiveAIConfig.apiUrl,
				documentSGChecker: documentSGCheckerEnabled ? documentSGChecker : undefined,
				defaultToggledState: options.proactiveAIConfig.defaultToggledState,
			}),
		),
		view(view: EditorView) {
			const { locale } = options.getIntl();

			if (documentSGCheckerEnabled) {
				// If view updates then reset document checker. That will stop checking for S+G for existing blocks.
				documentSGChecker.reset();
				documentSGChecker.setEditorViewAndLocale(documentSGCheckerConfig, view, locale);
			}

			return {
				update: (view: EditorView, prevState: EditorState) => {
					const { isSpellingAndGrammarEnabled } = getPluginState(view.state);
					if (
						isSpellingAndGrammarEnabled &&
						(!prevState.doc.eq(view.state.doc) || !prevState.selection.eq(view.state.selection))
					) {
						triggerSpellingAndGrammarCheck(view, locale);
					}
				},
				destroy: () => {
					cancelDebouncedAndThrottledSpellingAndGrammarCheck();
					/**
					 * Here we wil reset regardless of documentSGCheckerEnabled or not.
					 * In case if proactiveAIConfig is changed between when view is destroyed
					 * 	and we goes from enabled documentSGChecker to disabled one.
					 */
					documentSGChecker.reset();
				},
			};
		},
		props: {
			decorations: (state): DecorationSet | undefined => {
				const { isSpellingAndGrammarEnabled, decorationSet } = getPluginState(state);
				if (
					!isSpellingAndGrammarEnabled ||
					options.api?.editorViewMode?.sharedState.currentState()?.mode !== 'edit'
				) {
					return DecorationSet.empty;
				}
				return decorationSet;
			},
			attributes: (state: EditorState) => {
				const { isSpellingAndGrammarEnabled } = getPluginState(state);
				return {
					spellcheck: `${!isSpellingAndGrammarEnabled}`,
				};
			},
		},
		appendTransaction: (_tr, oldState, newState) => {
			const oldPluginState = getPluginState(oldState);
			const newPluginState = getPluginState(newState);

			const { decorationSet: oldDeco, selectedID: prevSelectedID } = oldPluginState;
			const { isSpellingAndGrammarEnabled, decorationSet: newDeco } = newPluginState;
			/*
				The following is a workaround to handle the initiate event for the appearance of
				selection of a suggestion. Originally, we'd prefer to show this when the toolbar
				has been initiated however, due to the intense re-render during onMouseEvent, it
				won't be accurate. Hence we moved the logic here.
			*/
			if (isSpellingAndGrammarEnabled && newState.selection.from === newState.selection.to) {
				const selectedDiff = getSelectedDiffObject(newPluginState, newState.selection.to);
				const prevSelectedDiff = getSelectedDiffObject(oldPluginState, oldState.selection.to);

				// Ensuring the selection within the same selectedDiff will not trigger the same event
				const isNotTheSameSelectedDiff = prevSelectedDiff?.id !== selectedDiff?.id;
				const uniqueInteractionID = uuid();

				if (!oldState.selection.eq(newState.selection)) {
					if (selectedDiff && isNotTheSameSelectedDiff) {
						if (!prevSelectedDiff) {
							const tr = viewSuggestionWithAnalytics(uniqueInteractionID, newState);
							tr.setMeta(aiSpellingGrammarPluginKey, { uniqueInteractionID });
							return tr;
						}

						// If there is a previously selected diff - needs to fire select + ignore
						if (prevSelectedDiff && prevSelectedID) {
							const blockOrSentenceFromDiffObject = getBlockOrSentenceFromDiffObject(
								newPluginState,
								prevSelectedDiff,
							);
							const tr = ignoreSuggestionWithAnalytics({
								aiInteractionID: prevSelectedID,
								editorState: newState,
								selectedDiff: prevSelectedDiff,
								blockOrSentenceFromDiffObject,
								transaction: viewSuggestionWithAnalytics(uniqueInteractionID, newState),
							});
							tr.setMeta(aiSpellingGrammarPluginKey, { uniqueInteractionID });
							return tr;
						}
					}

					// If there is a previously selected diff and tapped away
					if (prevSelectedDiff && prevSelectedID) {
						if (!selectedDiff && isDiffObjectInPluginState(newPluginState, prevSelectedDiff)) {
							const blockOrSentenceFromDiffObject = getBlockOrSentenceFromDiffObject(
								newPluginState,
								prevSelectedDiff,
							);
							return ignoreSuggestionWithAnalytics({
								aiInteractionID: prevSelectedID,
								editorState: newState,
								selectedDiff: prevSelectedDiff,
								blockOrSentenceFromDiffObject,
							});
						}
					}
				} else {
					// Ensuring when there is a selected diff and that the decoration has changed
					// For the case when - suggestion toolbar appear right where cursor is located.
					if (isNotTheSameSelectedDiff && selectedDiff && oldDeco !== newDeco) {
						const tr = viewSuggestionWithAnalytics(uniqueInteractionID, newState);
						tr.setMeta(aiSpellingGrammarPluginKey, { uniqueInteractionID });
						return tr;
					}
				}
			}
		},
	});
}
