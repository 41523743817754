import React, { Component } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { WebLink } from '@atlassian/jira-issue-view-common-types/src/web-links-type.tsx';
import WebLinkLineCardGroup from '@atlassian/jira-issue-view-common-views/src/web-link-line-card-group/index.tsx';
import {
	SectionHeading,
	SectionHeadingTitle,
	SectionHeadingIcons,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import AddButton from './add-button/index.tsx';
import WebLinkCreateLinkViewWithSmartCardProviderWithSafeComponent from './create-link/index.tsx';
import { messages } from './messages.tsx';
import type { Props } from './types.tsx';

type ViewProps = Props & {
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
export class WebLinksView extends Component<ViewProps> {
	renderHeading() {
		const {
			intl,
			isCreateWebLinkOpened,
			onAddButtonClicked,
			links,
			canAddWebLinks,
			applicationName,
			applicationId,
		} = this.props;
		const hasWebLinks = Boolean(links && links.length);
		const isGenericSection =
			applicationId === undefined || applicationId === null || applicationId?.trim() === '';

		return isCreateWebLinkOpened || hasWebLinks ? (
			<SectionHeading>
				<SectionHeadingTitle>
					{!isGenericSection ? applicationName : intl.formatMessage(messages.heading)}
				</SectionHeadingTitle>
				<SectionHeadingIcons>
					{canAddWebLinks && isGenericSection && <AddButton onClick={onAddButtonClicked} />}
				</SectionHeadingIcons>
			</SectionHeading>
		) : null;
	}

	renderLineCards() {
		const { links, canAddWebLinks } = this.props;
		const hasLinks = links && links.length > 0;
		let renderedLinks: WebLink[] = links;

		if (hasLinks) {
			const { applicationName } = this.props;
			// HOT-107178 - Remote links need a URL and title. However, the expectation with 1st party products
			// is that we check permissions for the link before exposing any information about the link, like its title.
			// this code works around an older trello integration that persisted the card title to jira's database,
			// thus "exposing" the information to nonprivileged users.
			if (applicationName === 'Trello') {
				// We replace the title with a copy of the sanitised trello card URL.
				renderedLinks = links.map((link) => {
					const newLink = { ...link };
					delete newLink.linkText;
					// keep the card ID, throw away the human-readable slug part of the URL
					newLink.href = newLink.href.replace(/\/c\/([^/]+)\/.+/, '/c/$1');
					return newLink;
				});
			}
		}

		return hasLinks ? (
			<UFOSegment name="issue-web-links">
				<GroupContainer>
					<WebLinkLineCardGroup links={renderedLinks} canDelete={canAddWebLinks} />
				</GroupContainer>
			</UFOSegment>
		) : null;
	}

	renderCreateLinkView() {
		const { applicationId, isCreateWebLinkOpened } = this.props;

		const isGenericSection =
			applicationId === undefined || applicationId === null || applicationId?.trim() === '';
		return isGenericSection && isCreateWebLinkOpened ? (
			<UFOSegment name="issue-web-links-create">
				<WebLinkCreateLinkViewWithSmartCardProviderWithSafeComponent />
			</UFOSegment>
		) : null;
	}

	renderShowMore() {
		const { onShowMore, nextPageCount, intl } = this.props;
		return (
			<ShowMoreButton
				appearance="link"
				onClick={onShowMore}
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				testId="issue.views.issue-base.content.web-links.show-more-link"
			>
				{intl.formatMessage(messages.showMoreLabel, {
					nextPageCount,
				})}
			</ShowMoreButton>
		);
	}

	render() {
		const { applicationId, nextPageCount } = this.props;
		const scopeIdentifier = typeof applicationId === 'string' ? applicationId : 'generic';

		// Extend to support preview mode: BENTO-5005
		return (
			<div
				data-testid={`issue.views.issue-base.content.web-links.top-container.scope-${scopeIdentifier}`}
			>
				{this.renderHeading()}
				{this.renderLineCards()}
				{nextPageCount > 0 && this.renderShowMore()}
				{this.renderCreateLinkView()}
			</div>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupContainer = styled.div({
	margin: `${token('space.100', '8px')} 0`,
	paddingRight: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShowMoreButton = styled(Button)({
	marginTop: token('space.050', '4px'),
	/* sadly we need !important here because the resulting CSS has the same specificity as AK */

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	paddingLeft: '0 !important',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	paddingRight: '0 !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	ShowMoreButton,
};
