import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Pagination from '@atlaskit/pagination';
import { Grid, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { DesignBackfillCard } from '@atlassian/jira-design-backfill-card/src/index.tsx';
import { DesignCard } from '@atlassian/jira-design-card/src/ui/async.tsx';
import { AddDesignForm } from '@atlassian/jira-issue-add-design-form/src/ui/design-form/index.tsx';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { DEFAULT_WORKSPACE } from '@atlassian/jira-issue-design-common/src/constants.tsx';
import { useFigmaProviderId } from '@atlassian/jira-issue-design-common/src/utils.tsx';
import { useDesignPanelStore } from '@atlassian/jira-issue-design-section-store/src/index.tsx';
import { VIEW_DESIGN_SECTION_EXPERIENCE } from '@atlassian/jira-issue-designs-observability/src/services/constants.tsx';
import { IssueDesignsExperienceSuccess } from '@atlassian/jira-issue-designs-observability/src/services/index.tsx';
import {
	ContextualAnalyticsData,
	fireTrackAnalytics,
	fireUIAnalytics,
	SCREEN,
	type UIAnalyticsEvent,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectId, useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { panel_issueDesignSection_DesignPanel_issueViewRelayFragment$key } from '@atlassian/jira-relay/src/__generated__/panel_issueDesignSection_DesignPanel_issueViewRelayFragment.graphql';
import type { panel_issueDesignSection_DesignPanel_rootRelayFragment$key } from '@atlassian/jira-relay/src/__generated__/panel_issueDesignSection_DesignPanel_rootRelayFragment.graphql';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import {
	BACKFILL_CARD_ID,
	DEFAULT_PAGE,
	ITEMS_PER_PAGE,
	ITEMS_PER_PAGE_XS,
} from '../../common/constants.tsx';
import type { BackfillDesign } from '../../common/types.tsx';
import { useIsDesignPanelCompactView } from '../../common/utils.tsx';
import { useFetchLastViewedIssuePropertyService } from '../../services/fetch-last-viewed-issue-property/index.tsx';
import { useSetLastViewedIssuePropertyService } from '../../services/set-last-viewed-issue-property/index.tsx';
import DesignsHeading from './heading/index.tsx';
import { UnlinkDesignConfirmationModal } from './unlink-confirmation-modal/index.tsx';

export type DesignPanelDetailsProps = {
	issueAri: string;
	isExpanded: boolean;
	issueViewRelayFragment: panel_issueDesignSection_DesignPanel_issueViewRelayFragment$key;
	rootRelayFragment: panel_issueDesignSection_DesignPanel_rootRelayFragment$key;
	backfillRequiredDesigns: BackfillDesign[];
	onBackfillComplete: () => void;
};

const DesignPanel = ({
	issueViewRelayFragment,
	isExpanded,
	issueAri,
	backfillRequiredDesigns,
	onBackfillComplete,
	rootRelayFragment,
}: DesignPanelDetailsProps) => {
	const issueData = useFragment<panel_issueDesignSection_DesignPanel_issueViewRelayFragment$key>(
		graphql`
			fragment panel_issueDesignSection_DesignPanel_issueViewRelayFragment on JiraIssue {
				designs @optIn(to: "GraphStoreIssueAssociatedDesign") @required(action: THROW) {
					...heading_issueDesignSection_DesignsHeading_designs
					...designForm_issueAddDesignForm_AddDesignForm_designs
					__id
					edges {
						node {
							... on DevOpsDesign {
								id @required(action: THROW)
								...ui_designCard_DesignCard
								...unlinkConfirmationModal_issueDesignSection_UnlinkDesignConfirmationModal
							}
						}
					}
				}
			}
		`,
		issueViewRelayFragment,
	);

	const devOpsData = useFragment<panel_issueDesignSection_DesignPanel_rootRelayFragment$key>(
		graphql`
			fragment panel_issueDesignSection_DesignPanel_rootRelayFragment on Query {
				devOps @optIn(to: "DevOpsSecurityInJira") {
					providers(id: $siteAri, providerTypes: [DESIGN]) {
						...designForm_issueAddDesignForm_AddDesignForm_providers
						...heading_issueDesignSection_DesignsHeading_providers
						designProviders {
							id
							name
							configState(cloudId: $cloudId) @optIn(to: "Jira-config-state") {
								config {
									nodes {
										status
										workspaceId
									}
								}
							}
							supportedActions {
								getEntityByUrl
								checkAuth
							}
						}
					}
				}
			}
		`,
		rootRelayFragment,
	);

	const figmaProviderId = useFigmaProviderId();

	const fetchLastViewedIssuePropertyService = useFetchLastViewedIssuePropertyService({
		issueAri,
	});

	const setLastViewedIssuePropertyService = useSetLastViewedIssuePropertyService({
		issueAri,
	});

	const currentUserAaid = useAccountId();

	const [, { resetShouldShowAddDesignForm }] = useDesignPanelStore();

	const [currentIssueKey, setCurrentIssueKey] = useState<string | null>(null);
	const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);

	const issueKey = useIssueKey();
	const issueId = Number(useIssueId());
	const projectKey = useProjectKey(issueKey);
	const projectId = useProjectId(projectKey);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	// Effect to reset the design panel state when the issue key changes during SPA transitions
	useEffect(() => {
		setCurrentIssueKey(issueKey);
		if (currentIssueKey && currentIssueKey !== issueKey) {
			resetShouldShowAddDesignForm();
		}
	}, [currentIssueKey, issueKey, resetShouldShowAddDesignForm]);

	useEffect(
		() => () => {
			resetShouldShowAddDesignForm?.();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const [isPanelExpanded, setIsPanelExpanded] = useState<boolean>(isExpanded);
	const [sectionPage, setSectionPage] = useState(DEFAULT_PAGE);
	const [designIdToUnlink, setDesignIdToUnlink] = useState<string | undefined>(undefined);

	const designEdges = useMemo(
		() => (issueData.designs?.edges ?? []).filter(Boolean),
		[issueData.designs?.edges],
	);
	const designData = useMemo(
		() => designEdges.map((edge) => edge.node).filter(Boolean),
		[designEdges],
	);

	const designToUnlink = designIdToUnlink
		? designData.find(({ id }) => id === designIdToUnlink)
		: undefined;

	const hasDesignsRequiringBackfill = backfillRequiredDesigns.length > 0;

	const figmaProvider = useMemo(
		() =>
			devOpsData.devOps?.providers?.designProviders?.find(
				(provider) => provider?.id === figmaProviderId,
			),
		[devOpsData.devOps?.providers?.designProviders, figmaProviderId],
	);
	const isFigmaProviderActionable = Boolean(
		figmaProvider?.supportedActions?.getEntityByUrl && figmaProvider?.supportedActions?.checkAuth,
	);
	const shouldShowBackfillCard = hasDesignsRequiringBackfill && isFigmaProviderActionable;

	type DesignData = (typeof designData)[number];
	interface DesignBackfillCardData {
		id: string;
	}

	const isNotBackfillCard = (obj: DesignData | DesignBackfillCardData): obj is DesignData =>
		obj.id !== BACKFILL_CARD_ID;

	const visibleDesignCards = useMemo(() => {
		// Slice the array of designs to show only the designs for the current page,
		// taking into account that we may need to show a backfill card on the first page.
		const getDesignCardsWithBackfill = () => {
			if (sectionPage === 0) {
				return [{ id: BACKFILL_CARD_ID }, ...designData.slice(0, itemsPerPage - 1)];
			}

			const startIndex = (sectionPage - 1) * itemsPerPage + (itemsPerPage - 1);
			const endIndex = sectionPage * itemsPerPage + (itemsPerPage - 1);
			return designData.slice(startIndex, endIndex);
		};

		const getDesignCardsWithoutBackfill = () => {
			const startIndex = sectionPage * itemsPerPage;
			const endIndex = (sectionPage + 1) * itemsPerPage;
			return designData.slice(startIndex, endIndex);
		};

		return shouldShowBackfillCard ? getDesignCardsWithBackfill() : getDesignCardsWithoutBackfill();
	}, [designData, shouldShowBackfillCard, sectionPage, itemsPerPage]);

	useEffect(() => {
		const handleEmptyLastPage = () => {
			if (visibleDesignCards.length === 0 && sectionPage > 0) {
				setSectionPage(sectionPage - 1);
			}
		};
		handleEmptyLastPage();
	}, [sectionPage, visibleDesignCards]);

	const totalCards = useMemo(
		() => designData.length + (shouldShowBackfillCard ? 1 : 0),
		[designData.length, shouldShowBackfillCard],
	);

	const getPageIndexes = useCallback(() => {
		const totalPages = Math.ceil(totalCards / itemsPerPage);
		return Array.from({ length: totalPages }, (_, i) => i + 1);
	}, [totalCards, itemsPerPage]);

	const columns = useMemo(() => (totalCards === 1 ? 1 : itemsPerPage), [totalCards, itemsPerPage]);

	const handleDesignsPanelExpansion = useCallback(
		(_: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'toggleDesignSection', {
				designSectionExpanded: !isPanelExpanded,
			});

			setIsPanelExpanded(!isPanelExpanded);
		},
		[isPanelExpanded],
	);

	const isConfigured = useMemo(
		() =>
			figmaProvider?.configState?.config?.nodes?.some(
				(node) => node.workspaceId === DEFAULT_WORKSPACE && node.status === 'CONFIGURED',
				// We assume the ideal state in the presence of nulls, i.e that the app is configured
			) ?? true,
		[figmaProvider],
	);

	// Fetch and update last viewed issue property on mount and when new designs are added
	useEffect(() => {
		fetchLastViewedIssuePropertyService.fetch().then((lastViewedIssuePropertyData) => {
			if (fetchLastViewedIssuePropertyService.error || !currentUserAaid || !designData.length) {
				return;
			}

			const timestamp = new Date().toISOString();
			const designIds = designData.map((design) => design.id);
			const lastViewedDesignIds = Object.fromEntries(designIds.map((key) => [key, timestamp]));

			// lastViewedIssuePropertyData is undefined if the issue property does not exist,
			// or if the issue property value is not in the expected shape
			setLastViewedIssuePropertyService.fetch({
				...lastViewedIssuePropertyData,
				[currentUserAaid]: lastViewedDesignIds,
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [designData.length]);

	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'designSection rendered', {
			linkedDesignCount: designData.length,
			pendingBackfillDesignCount: backfillRequiredDesigns?.length,
			issueId,
			projectId,
			isConfigured,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [issueId, projectId, createAnalyticsEvent]);

	const prevDesignsLengthRef = useRef(0);
	const designPanelContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const isNewDesignAdded = designData.length - prevDesignsLengthRef.current === 1;

		if (totalCards > itemsPerPage && isNewDesignAdded) {
			const lastPage = Math.ceil(totalCards / itemsPerPage);
			setSectionPage(lastPage);
		}

		prevDesignsLengthRef.current = designData.length;
	}, [totalCards, designData.length, itemsPerPage]);

	const isCompactView = useIsDesignPanelCompactView(designPanelContainerRef);

	useEffect(() => {
		setItemsPerPage(isCompactView ? ITEMS_PER_PAGE_XS : ITEMS_PER_PAGE);
	}, [isCompactView]);

	return (
		<ContextualAnalyticsData
			sourceName="designSection"
			sourceType={SCREEN}
			attributes={{ issueId, projectId }}
		>
			<DesignsHeading
				providerId={figmaProviderId}
				totalCount={designData.length}
				hasBackfillableDesigns={shouldShowBackfillCard}
				isCompactView={isCompactView}
				isPanelExpanded={isPanelExpanded}
				onDesignsPanelExpansion={handleDesignsPanelExpansion}
				providers={devOpsData.devOps?.providers ?? null}
				designs={issueData.designs ?? null}
			/>
			<DesignsPanelContainer shouldDisplay={isPanelExpanded} ref={designPanelContainerRef}>
				{visibleDesignCards.length === 0 ? (
					<AddDesignForm
						providers={devOpsData.devOps?.providers ?? null}
						designs={issueData.designs ?? null}
					/>
				) : (
					<Grid
						templateColumns={`repeat(${columns}, 1fr)`}
						gap="space.100"
						xcss={designCardWrapperStyles}
					>
						{visibleDesignCards.map((design, idx) => {
							const modalPage =
								shouldShowBackfillCard && sectionPage === 0
									? idx
									: idx + sectionPage * itemsPerPage;

							if (isNotBackfillCard(design)) {
								return (
									<DesignCard
										key={design.id}
										modalPage={modalPage}
										lastViewed={
											design.id
												? fetchLastViewedIssuePropertyService.data?.[currentUserAaid ?? '']?.[
														design.id
													]
												: undefined
										}
										designCard={design}
										onSetDesignToUnlink={() => setDesignIdToUnlink(design.id)}
									/>
								);
							}

							if (shouldShowBackfillCard) {
								return (
									<DesignBackfillCard
										key={design?.id}
										providerId={figmaProviderId}
										providerName={figmaProvider?.name}
										designs={backfillRequiredDesigns}
										designRecordId={issueData.designs.__id}
										onBackfillComplete={onBackfillComplete}
									/>
								);
							}

							return null;
						})}
					</Grid>
				)}

				{totalCards > itemsPerPage && (
					<PaginationWrapper>
						<Pagination
							selectedIndex={sectionPage}
							pages={getPageIndexes()}
							onChange={(_, page) => {
								setSectionPage(page - 1);
							}}
						/>
					</PaginationWrapper>
				)}
			</DesignsPanelContainer>
			{designToUnlink && (
				<UnlinkDesignConfirmationModal
					onClose={() => setDesignIdToUnlink(undefined)}
					designToUnlink={designToUnlink}
					designConnectionId={issueData.designs.__id ?? ''}
				/>
			)}
			<IssueDesignsExperienceSuccess experience={VIEW_DESIGN_SECTION_EXPERIENCE} />
		</ContextualAnalyticsData>
	);
};

export default DesignPanel;

interface DesignsPanelContainerProps {
	shouldDisplay: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DesignsPanelContainer = styled.div<DesignsPanelContainerProps>({
	justifyContent: 'flex-start',
	flexDirection: 'column',
	alignItems: 'stretch',
	gap: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ shouldDisplay }: DesignsPanelContainerProps) => (shouldDisplay ? 'flex' : 'none'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PaginationWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	marginTop: token('space.100', '8px'),
});

const designCardWrapperStyles = xcss({
	marginTop: 'space.100',
});
