import React, { useCallback, useMemo } from 'react';
import isNil from 'lodash/isNil';
import memoizeOne from 'memoize-one';
import { fg } from '@atlassian/jira-feature-gating';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { SelectValueShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import MultiSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import { fetchSuggestionsFactory } from '../../../../../../group-picker/rest/fetch.tsx';
import { useAnalyticsAttributesContext } from '../../../analytics/atrributes-context/index.tsx';
import type { CustomFieldProps } from '../../../types.tsx';
import type { Group } from '../types.tsx';
import messages from './messages.tsx';

const fromSelectOption: (option: SelectValueShape[]) => Group[] = memoizeOne(
	(option: SelectValueShape[]) => option.map((o) => ({ name: String(o.value) })),
);

const toSelectOption: (value: Group[] | null) => SelectValueShape[] = memoizeOne(
	(value: Group[] | null) =>
		!value ? [] : value.map((v) => ({ value: String(v.name), content: String(v.name) })),
);

export const View = ({
	fieldId,
	shouldFireScreenEvent,
	value,
	customReadView,
	onChange,
	renderAppEditView,
	...restProps
}: CustomFieldProps<Group[]>) => {
	const issueKey = useIssueKey();
	const [{ value: fieldConfig }] = useIssueFieldConfig(issueKey);
	const allowEmptyValue = (fieldConfig && !fieldConfig[fieldId]?.isRequired) ?? false;
	const fetchSuggestions = fetchSuggestionsFactory();

	const { formatMessage } = useIntl();

	const onChangeWithTransformation = useCallback(
		(newValue: SelectValueShape[]) => onChange(fromSelectOption(newValue)),
		[onChange],
	);

	const customReadViewWithTransformation = useCallback(
		() => (!isNil(customReadView) ? customReadView(value) : <></>),
		[value, customReadView],
	);

	const optionalCustomReadView = !isNil(customReadView)
		? customReadViewWithTransformation
		: undefined;

	const analyticsAttributes = useAnalyticsAttributesContext();
	const attributes = useMemo(
		() => ({ ...analyticsAttributes, renderMode: 'default' }),
		[analyticsAttributes],
	);

	return (
		<>
			{shouldFireScreenEvent && <ForgeScreenEvent attributes={attributes} />}
			<MultiSelectInlineEditView
				{...restProps}
				fieldId={fieldId}
				allowEmptyValue={allowEmptyValue}
				fetchSuggestions={fetchSuggestions}
				value={toSelectOption(value)}
				customReadView={optionalCustomReadView}
				placeholder={formatMessage(messages.placeholder)}
				noValueText={formatMessage(genericMessages.noValue)}
				onChange={onChangeWithTransformation}
				{...(fg('forge_cf_inline_edit_on_issue_view') ? { renderAppEditView } : {})}
			/>
		</>
	);
};

export default View;
