import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props } from './ui/modal/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const FindFieldModal = lazy(
	() => import(/* webpackChunkName: "async-issue-config-find-field" */ './index'),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<JSErrorBoundary
		id="AsyncFindFieldModal"
		packageName="jiraIssueConfigFindField"
		fallback="flag"
		sendToPrivacyUnsafeSplunk
	>
		<Placeholder name="config-find-field-modal" fallback={<></>}>
			<FindFieldModal {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
