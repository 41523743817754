import React from 'react';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { fieldsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import {
	projectIdSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { canUseServiceDeskAgentFeaturesSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import { ProjectContextProvider } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useIsItsmTemplateProject } from '@atlassian/jira-router-resources-service-desk-is-itsm-project/src/controllers/index.tsx';
import { AsyncChangeManagementTourIssue } from '@atlassian/jira-servicedesk-change-management-tour/src/async.tsx';
import { toProjectId, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

type IssueViewTourProps = {
	isServiceDeskAgent: boolean;
	currentIssueKey: IssueKey;
	fields: FieldsState;
	projectId: number | null;
	projectType: ProjectType | null;
};

export const ChangeManagementTour = ({
	isServiceDeskAgent,
	projectType,
	projectId,
	...otherProps
}: IssueViewTourProps) => {
	const { data: isItsmProject } = useIsItsmTemplateProject();
	if (projectId === null || isItsmProject !== true || isServiceDeskAgent !== true) {
		return null;
	}

	return (
		<JSErrorBoundary
			id="changeManagementTourIssue"
			packageName="jiraServicedeskChangeManagementTourIssue"
			fallback="flag"
		>
			<ProjectContextProvider>
				{({ data }) =>
					data && data.isProjectAdmin === true ? (
						<AsyncChangeManagementTourIssue
							projectId={toProjectId(projectId.toString())}
							{...otherProps}
						/>
					) : null
				}
			</ProjectContextProvider>
		</JSErrorBoundary>
	);
};

type StateProps = {
	isServiceDeskAgent: boolean;
	projectId: number | null;
	projectType: ProjectType | null;
	fields: FieldsState;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any): StateProps => ({
	fields: fieldsSelector(state),
	projectId: projectIdSelector(state),
	projectType: projectTypeSelector(state),
	isServiceDeskAgent: canUseServiceDeskAgentFeaturesSelector(state),
});

export default connect(
	mapStateToProps,
	{},
)((props: IssueViewTourProps) =>
	props.projectType !== SERVICE_DESK_PROJECT ? null : <ChangeManagementTour {...props} />,
);
