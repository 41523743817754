/**
 * @generated SignedSource<<f50cd438fc5d0e53774a1a1f32f2430d>>
 * @relayHash 6af4685042b19466a6f58031d8c6ac92
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ab2a79b7e3570fae04bcc9f031a42dcb0ca01eacb178d8927412c4ba2d41bf42

import type { ConcreteRequest, Query } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
export type assignToMeMutationDataQuery$variables = {
  cloudId: string;
  issueKey: string;
};
export type assignToMeMutationDataQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly assigneeField: {
        readonly fieldConfig: {
          readonly isEditable: boolean | null | undefined;
        } | null | undefined;
        readonly fieldId: string;
        readonly id: string;
        readonly name: string;
        readonly type: string;
        readonly user: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly me: {
    readonly user: {
      readonly accountId: string;
      readonly accountStatus: AccountStatus;
      readonly id: string;
      readonly name: string;
      readonly picture: AGG$URL;
    } | null | undefined;
  };
};
export type assignToMeMutationDataQuery = {
  response: assignToMeMutationDataQuery$data;
  variables: assignToMeMutationDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueKey"
  }
],
v1 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v2 = {
  "kind": "ScalarField",
  "name": "name"
},
v3 = {
  "kind": "ScalarField",
  "name": "picture"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v6 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v8 = {
  "kind": "ScalarField",
  "name": "type"
},
v9 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isEditable"
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "assignToMeMutationDataQuery",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSingleSelectUserPickerField",
                "kind": "LinkedField",
                "name": "assigneeField",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ]
                  },
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "assignToMeMutationDataQuery",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSingleSelectUserPickerField",
                "kind": "LinkedField",
                "name": "assigneeField",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v4/*: any*/)
                    ]
                  },
                  (v9/*: any*/)
                ]
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ab2a79b7e3570fae04bcc9f031a42dcb0ca01eacb178d8927412c4ba2d41bf42",
    "metadata": {},
    "name": "assignToMeMutationDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8ca32bf6a9d4cc764219674b8aca019b";

export default node;
