import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.pin-field-configurability.pin-field-discoverability-banner.heading',
		defaultMessage: 'Quickly access important fields',
		description:
			'Heading for the banner promoting the pin field feature. This message encourages users to use the feature to quickly access important fields in the issue view layout.',
	},
	content: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.pin-field-configurability.pin-field-discoverability-banner.content',
		defaultMessage:
			'We can pin some fields to help you get started.  This modifies only your view, and you can unpin fields at any time. ',
		description:
			"Content of the banner promoting the pin field feature. This message explains that pinning fields only modifies the user's view and that they can unpin fields at any time.",
	},
	dismiss: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.pin-field-configurability.pin-field-discoverability-banner.dismiss',
		defaultMessage: 'Dismiss ',
		description: 'Label for the button that dismisses the banner promoting the pin field feature.',
	},
	pinFields: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.pin-field-configurability.pin-field-discoverability-banner.pin-fields',
		defaultMessage: 'Pin fields for me',
		description:
			'Label for the button that pins fields for the user in the banner promoting the pin field feature.',
	},
	altText: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.pin-field-configurability.pin-field-discoverability-banner.alt-text',
		defaultMessage:
			'To pin a field yourself, select the Pin icon beside the field, including Assignee',
		description:
			'Alt text for the pin icon that appears when the user hovers over the Assignee field in the issue view layout.',
	},
});
