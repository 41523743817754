import { fg } from '@atlassian/jira-feature-gating';
import {
	fetchIssueForgeDataV1,
	fetchIssueForgeDataV2,
} from '@atlassian/jira-issue-fetch-services/src/services/issue-forge-data/index.tsx';
import type { ForgeResponse } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type { CloudId, IssueKey, ActivationId } from '@atlassian/jira-shared-types/src/general.tsx';

export const fetchAllForgeData = async (
	cloudId: CloudId,
	issueKey: IssueKey,
	activationId: ActivationId,
	prefetchedResource?: Promise<ForgeResponse> | null,
	locale?: string,
): Promise<ForgeResponse> =>
	prefetchedResource ||
	(fg('new_graphql_endpoint_for_fetching_forge_modules')
		? fetchIssueForgeDataV2(cloudId, issueKey)
		: fetchIssueForgeDataV1(cloudId, issueKey, activationId, locale));
