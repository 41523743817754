import type { InternalToPublicPropertyTransformer } from '@atlassian/jira-issue-adjustments/src/common/types/index.tsx';
import { baseScopesRequiredPerField } from '@atlassian/jira-issue-adjustments/src/common/utils/filter-based-on-scopes/fields-scopes.tsx';

type PublicValue = string;
type InternalValue = string | undefined | null;

export const validateSingleLineTextValue = (value: unknown): value is PublicValue =>
	typeof value === 'string';

export const transformSingleLineTextToPublicValue: InternalToPublicPropertyTransformer<
	InternalValue,
	PublicValue
> = (internalValue) => internalValue || '';

export const singleLineTextScopes = baseScopesRequiredPerField;
