import { useMemo } from 'react';
import type { KeyType } from 'react-relay/relay-hooks/helpers';
import { ff } from '@atlassian/jira-feature-flagging';
import type { IntlShapeV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import {
	useComponentProps,
	useSoftRefreshCallbacks,
	type SoftRefreshCallbacks,
	type RequiredFieldData,
	type ProvidedProps,
	type WrappedComponentProps,
	type AdditionalProps,
} from './relay-field/use-connect-relay-field.tsx';

type Nullable<T> = T | null | undefined;

export type ConnectedLayoutProps<TFragment extends KeyType = KeyType> = {
	area?: Area;
	fragmentKey: TFragment;
};

export type DataCallback<TFragmentKey extends KeyType, TData extends RequiredFieldData> = (args: {
	props: ConnectedLayoutProps<TFragmentKey>;
}) => TData;

export type PropsCallback<
	TFragmentKey extends KeyType,
	TData extends RequiredFieldData,
	TComponentValue = unknown,
	TAggValue = unknown,
	TAdditionalProps extends AdditionalProps = undefined,
> = (args: {
	props: ConnectedLayoutProps<TFragmentKey>;
	intl: IntlShapeV2;
	issueId: Nullable<string>;
	softRefreshCallbacks: SoftRefreshCallbacks<TAggValue>;
	data: TData;
}) => ProvidedProps<TComponentValue, TAdditionalProps>;

export function useConnectRelayField<
	TFragmentKey extends KeyType,
	TData extends RequiredFieldData,
	TComponentValue = unknown,
	TAggValue = unknown,
	TAdditionalProps extends AdditionalProps = undefined,
>(
	props: ConnectedLayoutProps<TFragmentKey>,
	data: TData,
	useProps: PropsCallback<TFragmentKey, TData, TComponentValue, TAggValue, TAdditionalProps>,
): {
	componentProps: WrappedComponentProps<TComponentValue, TAdditionalProps>;
} {
	const intl = useIntl();

	// TODO: issueId and issueKey are backed by RSS and should be replaced when we have a better solution
	// We query for them at this level because they can likely be provided by the consumer in the future.
	const issueId = useIssueId();
	const issueKey = useIssueKey();

	// Pass the data down so that we can generate soft refresh callbacks for consumers
	const softRefreshCallbacks = useSoftRefreshCallbacks({ issueId, fieldData: data });

	// Get the props from the consumer, and convert them into the props required by the wrapped component
	const providedProps = useProps({
		issueId,
		props,
		softRefreshCallbacks,
		intl,
		data,
	});
	const componentProps = useComponentProps({ issueKey, providedProps });

	// TODO: Can we use { useFieldIsEditingActions } from '@atlassian/jira-issue-field-editing-store';

	const { overriding } = useInlineEditFieldInjections();

	if (ff('relay-migration-issue-fields-issue-multi-line-text_w69oo')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useMemo(
			() => ({
				componentProps: {
					...componentProps,
					label: overriding.overrideLabel(componentProps.label),
					isEditable: overriding.overrideIsEditable(componentProps.isEditable),
					description: overriding.overrideDescription(componentProps.description),
				},
			}),
			[componentProps, overriding],
		);
	}
	componentProps.label = overriding.overrideLabel(componentProps.label);
	componentProps.isEditable = overriding.overrideIsEditable(componentProps.isEditable);
	componentProps.description = overriding.overrideDescription(componentProps.description);
	// end region: UIM overrides

	return {
		componentProps,
	};
}
