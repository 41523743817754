import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { PRIORITY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { PriorityValue } from '../common/types.tsx';
import type { Props, PriorityIdShape, PriorityIdShapeResponse } from './types.tsx';

export const usePriorityField = ({ issueKey, onFailure, onSuccess }: Props) => {
	const [fieldConfig] = useFieldConfig(issueKey, PRIORITY_TYPE);
	const fieldType = get(fieldConfig.value, 'schema.type', 'priority');
	const options = fieldConfig.value?.allowedValues ?? [];

	const [{ value, error }, { saveValue, saveById, resetError }] = useEditField<
		PriorityValue,
		null,
		PriorityIdShapeResponse,
		PriorityIdShape | null
	>({
		fieldKey: PRIORITY_TYPE,
		issueKey,
		fieldType,
		initialValue: null,
		onFailure,
		onSuccess,
	});

	return [
		{
			value,
			error,
			fieldConfig,
			options,
		},
		{ saveValue, saveById, resetError },
	] as const;
};
