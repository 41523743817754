import uuid from 'uuid/v4';

import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { ExtractInjectionAPI, PMPluginFactoryParams } from '@atlaskit/editor-common/types';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';
import { type MentionNameDetails } from '@atlaskit/mention';

import { addAnalytics, createUnifiedAnalyticPayload } from '../../analytics/utils';
import type { AIPlugin } from '../../editor-plugin-ai';
import type { ProactiveAIConfig } from '../../types';

import { aiProactivePluginKey } from './ai-proactive-plugin-key';
import { closeProactiveAISuggestionDisplay, createTriggerProactiveCheck } from './commands';
import { createInitialState, createPluginState, getPluginState } from './plugin-factory';
import { ACTIONS } from './states';
import { getBlockFromRecommendationId } from './utils';

/**
 * Creating instance of documentSGChecker outside of create plugin
 * 	because it gets called multiple times when Editor Loads.
 * And we don't need to re-create documentSGChecker each time.
 * Instead we just need to reset it.
 */
// const documentSGChecker = new DocumentSGChecker();

/**
 * Plugin factory
 */
export function createProactiveAIPlugin(options: {
	dispatch: PMPluginFactoryParams['dispatch'];
	getIntl: PMPluginFactoryParams['getIntl'];
	proactiveAIConfig: ProactiveAIConfig;
	product: string;
	api: ExtractInjectionAPI<AIPlugin> | undefined;
	getMentionNameDetails?: (id: string) => Promise<MentionNameDetails | undefined>;
}) {
	const proactiveAIConfig = options.proactiveAIConfig;
	const { triggerProactiveCheck, cancelDebouncedAndThrottledCheck } = createTriggerProactiveCheck(
		proactiveAIConfig.timings,
	);
	// const { documentSGChecker: documentSGCheckerConfig } = proactiveAIConfig;
	// const documentSGCheckerEnabled = documentSGCheckerConfig?.enabled;

	return new SafePlugin({
		key: aiProactivePluginKey,
		state: createPluginState(
			options.dispatch,
			createInitialState({
				proactiveAIApiUrl: options.proactiveAIConfig.apiUrl,
				// documentSGChecker: documentSGCheckerEnabled ? documentSGChecker : undefined,
				defaultToggledState: options.proactiveAIConfig.defaultToggledState,
				product: options.product,
			}),
		),
		view(view: EditorView) {
			const { locale } = options.getIntl();

			// if (documentSGCheckerEnabled) {
			// 	// If view updates then reset document checker. That will stop checking for S+G for existing blocks.
			// 	documentSGChecker.reset();
			// 	documentSGChecker.setEditorViewAndLocale(documentSGCheckerConfig, view, locale);
			// }

			const unsubscribeViewModeChange = options.api?.editorViewMode?.sharedState.onChange(
				(sharedState) => {
					if (
						sharedState.nextSharedState?.mode !== sharedState.prevSharedState?.mode &&
						sharedState.nextSharedState?.mode !== 'edit'
					) {
						closeProactiveAISuggestionDisplay('viewModeChanged')(view.state, view.dispatch);
					}
				},
			);

			return {
				update: (view: EditorView, prevState: EditorState) => {
					const { isProactiveEnabled } = getPluginState(view.state);
					if (
						isProactiveEnabled &&
						(!prevState.doc.eq(view.state.doc) || !prevState.selection.eq(view.state.selection))
					) {
						triggerProactiveCheck({
							view,
							locale,
							getMentionNameDetails: options.getMentionNameDetails,
						});
					}
				},
				destroy: () => {
					unsubscribeViewModeChange?.();
					cancelDebouncedAndThrottledCheck();
					/**
					 * Here we wil reset regardless of documentSGCheckerEnabled or not.
					 * In case if proactiveAIConfig is changed between when view is destroyed
					 * 	and we goes from enabled documentSGChecker to disabled one.
					 */
					// documentSGChecker.reset();
				},
			};
		},
		props: {
			decorations: (state): DecorationSet | undefined => {
				const {
					isProactiveEnabled,
					decorationSet,
					alwaysDisplayProactiveInlineDecorations,
					isProactiveContextPanelOpen,
				} = getPluginState(state);

				if (
					!isProactiveEnabled ||
					options.api?.editorViewMode?.sharedState.currentState()?.mode !== 'edit'
				) {
					return DecorationSet.empty;
				}

				if (alwaysDisplayProactiveInlineDecorations || isProactiveContextPanelOpen) {
					return decorationSet;
				}

				return DecorationSet.empty;
			},
		},
		appendTransaction: (_, oldState, newState) => {
			const oldPluginState = getPluginState(oldState);
			const newPluginState = getPluginState(newState);

			const {
				selectedRecommendationId: prevSelectedRecommendationId,
				isProactiveContextPanelOpen: prevIsProactiveContextPanelOpen,
			} = oldPluginState;
			const {
				isProactiveEnabled,
				selectedRecommendationId,
				alwaysDisplayProactiveInlineDecorations,
				isProactiveContextPanelOpen,
			} = newPluginState;

			const isOpening =
				isProactiveContextPanelOpen &&
				prevIsProactiveContextPanelOpen !== isProactiveContextPanelOpen;

			const isSelecting =
				!!selectedRecommendationId &&
				prevSelectedRecommendationId !== selectedRecommendationId &&
				!oldState.selection.eq(newState.selection);

			/*
				The following is a workaround to handle the initiate and viewed events for unified analytics.
				The issue is we need to generate an store a new uuid for tracking every time an initiate occurs.
				And this uuid will be shared with all unified anayltic events there after, or until another initiate occurs.
			*/
			if (
				isProactiveEnabled &&
				(isOpening || isSelecting) &&
				!!selectedRecommendationId &&
				(alwaysDisplayProactiveInlineDecorations || isProactiveContextPanelOpen) &&
				newState.selection.from === newState.selection.to
			) {
				const { recommendation } = getBlockFromRecommendationId(
					newPluginState,
					selectedRecommendationId,
				);

				if (recommendation) {
					const analyticsAIInteractionId = uuid();
					return addAnalytics({
						editorState: newState,
						tr: newState.tr,
						payload: createUnifiedAnalyticPayload(
							'initiated',
							analyticsAIInteractionId,
							recommendation.transformAction,
							true,
						),
					}).setMeta(aiProactivePluginKey, {
						type: ACTIONS.UPDATE_PLUGIN_STATE,
						data: {
							analyticsAIInteractionId,
						},
					});
				}
			}
		},
	});
}
