import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const openDialog = createJpdExperience(
	new UFOExperience('jpd.view-permissions.open-dialog', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	}),
);

const loadViewAccess = createJpdExperience(
	new UFOExperience('jpd.view-permissions.load-view-access', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const updateViewAccess = createJpdExperience(
	new UFOExperience('jpd.view-permissions.update-view-access', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const addPrincipals = createJpdExperience(
	new UFOExperience('jpd.view-permissions.add-principals', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const changePrincipalRole = createJpdExperience(
	new UFOExperience('jpd.view-permissions.set-principal-role', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const removePrincipal = createJpdExperience(
	new UFOExperience('jpd.view-permissions.remove-principal', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	openDialog,
	loadViewAccess,
	updateViewAccess,
	addPrincipals,
	changePrincipalRole,
	removePrincipal,
};
