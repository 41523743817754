// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { TenantContext, AppProps, State, BaseProps, ConnectProps } from '../model/types';

export const SHORTCUT_SOURCE = 'shortcut' as const;
export const BUTTON_SOURCE = 'button' as const;
export const DROPDOWN_SOURCE = 'dropdown' as const;
export const SPOTLIGHT_SOURCE = 'watchFirstIssueV1Spotlight' as const;

export type ToggleWatchingSource =
	| typeof BUTTON_SOURCE
	| typeof SHORTCUT_SOURCE
	| typeof DROPDOWN_SOURCE
	| typeof SPOTLIGHT_SOURCE;
