import { useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import { checkAssistanceServiceFreeGenerateFg } from '@atlassian/editor-ai-common/utils/check-assistance-service-fg';

import { type EditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { type LatestPrompt, type PromptTrigger } from '../../get-ai-experience-service';
import { messages as experienceMessages } from '../../messages';

export const useAdditionalFooterText = ({
	configItem,
	promptTrigger,
	latestPrompt,
}: {
	configItem: EditorPluginAIConfigItemMarkdown;
	promptTrigger?: PromptTrigger;
	latestPrompt?: LatestPrompt;
}): string | undefined => {
	const { formatMessage } = useIntl();

	// if convoAI related FF is enabled
	const convoAIBeta =
		checkAssistanceServiceFreeGenerateFg() || fg('platform_editor_ai_interrogate_with_convo_ai');

	const isLinkWarningEnabled = !configItem.agent;

	// if it is generate or interrogate
	const isGenerateOrInterrogatePrompt =
		promptTrigger && ['interrogate', 'generate'].includes(promptTrigger);

	let additionalFooterText;

	if (
		isGenerateOrInterrogatePrompt &&
		convoAIBeta &&
		isLinkWarningEnabled &&
		latestPrompt?.userInput
	) {
		// if the prompt includes a link
		const containsURL =
			latestPrompt.userInput.includes('http://') || latestPrompt.userInput.includes('https://');

		if (containsURL) {
			additionalFooterText = formatMessage(experienceMessages.convoAIBeta);
		}
	}

	return additionalFooterText;
};
