import React, { useMemo, useState, useCallback, useEffect } from 'react';
import Checkbox from '@atlaskit/checkbox';
import { createPromptEditor } from '@atlassian/editor-ai-injected-editors/prompt-editor';
import {
	createJiraEditorPluginAI,
	createJiraEditorPluginAIProvider,
} from '@atlassian/editor-plugin-ai/JiraPrebuilt';
import {
	type ProjectType,
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { ENTRY_POINT_ID_GENERATIVE_AI } from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { useAIOptIn } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in/src/index.tsx';
import {
	useAIUpsellInEditorFree,
	AIUpsellButtonWrapper,
} from '@atlassian/jira-platform-react-hooks-use-ai-upsell-in-editor-free/src/index.tsx';
import type { ExperimentValue } from '@atlassian/jira-platform-react-hooks-use-ai-upsell-in-editor-free/src/types.tsx';
import messages from './messages.tsx';
import {
	type SharedEditorConfig,
	Product,
	type CreateEditorPluginAIProvider,
	type EditorPluginAI,
	type EditorPluginAIConfig,
	type SharedEditorConfigProps,
	type EditorConfig,
	type HandleFeedbackSubmissionArgs,
	type AIPluginConfiguration,
} from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	type SharedEditorConfig,
	type EditorPluginAIConfig,
	type EditorConfig,
	type AIPluginConfiguration,
} from './types';

const FEEDBACK_COLLECTOR_ID = 'fb2a9bff-f1e7-4deb-b396-7bfaae5af671';
const FEEDBACK_CONTEXT_ID = 'customfield_10047';
const LINK_TO_PRIVACY_POLICY = 'https://www.atlassian.com/legal/privacy-policy';
const LINK_TO_RESEARCH_GROUP = 'https://www.atlassian.com/research-group';
const buildAdditionalFields = (
	props: HandleFeedbackSubmissionArgs | null,
	hasUserConsent: boolean,
) => {
	if (!props || !props.getAIExperience) {
		return undefined;
	}

	const { getAIExperience, sentiment, editorAttributes } = props;

	const aiExperience = getAIExperience?.(hasUserConsent) || {};
	let feedbackMetadataStringified = `sentiment: ${sentiment}\n`;

	Object.entries({
		...aiExperience,
		...editorAttributes,
	}).forEach((item) => {
		feedbackMetadataStringified += `${item[0]}: ${item[1]}\n`;
	});
	return [{ id: FEEDBACK_CONTEXT_ID, value: feedbackMetadataStringified }];
};

const CanContactLabel = () => (
	<FormattedMessage
		{...messages.canContactText}
		values={{
			// this is done exactly as documented here: https://developer.atlassian.com/platform/localization/i18n-formatting/avoiding-concatenation/#links
			// @ts-expect-error - https://github.com/formatjs/formatjs/issues/3550 need to updated to v6 intl
			a: (...chunks: string[]) => (
				<a target="_blank" href={LINK_TO_PRIVACY_POLICY}>
					{chunks}
				</a>
			),
		}}
	/>
);

const EnrolLabel = () => (
	<FormattedMessage
		{...messages.improveAtlassianText}
		values={{
			// this is done exactly as documented here: https://developer.atlassian.com/platform/localization/i18n-formatting/avoiding-concatenation/#links
			// @ts-expect-error - https://github.com/formatjs/formatjs/issues/3550 need to updated to v6 intl
			a: (...chunks: string[]) => (
				<a target="_blank" href={LINK_TO_RESEARCH_GROUP}>
					{chunks}
				</a>
			),
		}}
	/>
);

// product is required for v2 generative AI API
// https://hello.atlassian.net/wiki/spaces/CA3/pages/2749958432/Open+question+for+Option+4#How-will-the-API-know-which-product-has-called-the-API%3F
export const getProductForEditorAIPlugin = (
	projectType: ProjectType | undefined,
): Product | null => {
	switch (projectType) {
		case CORE_PROJECT:
			return Product.JWM;
		case SOFTWARE_PROJECT:
			return Product.JSW;
		case SERVICE_DESK_PROJECT:
			return Product.JSM;
		case PRODUCT_DISCOVERY_PROJECT:
			return Product.JPD;
		default:
			return null;
	}
};

export const getOptInStatus = (editorPluginAIConfig: EditorPluginAIConfig) => {
	const isAIOptedIn = editorPluginAIConfig.isAiOptInEnabled;
	return isAIOptedIn ? 'enabled' : 'disabled';
};

/**
 * The assistance service expects an x-product header in order to authorise requests. More info about this can be found using the following links
 *
 * @See: https://developer.atlassian.com/platform/assistance-service/security/entitlements/#approach
 * @See: https://bitbucket.org/atlassian/assistance-service/src/6f639fce8171d17e003c47decba35acabadc69c9/src/schema/base.py#lines-21
 */
export const getXProductHeaderValue = (product: Product | null) => {
	switch (product) {
		case Product.JSW:
			return 'jira-software';
		case Product.JPD:
			return 'jpd';
		case Product.JSM:
			return 'jsm';
		default:
			return 'jira';
	}
};

/**
 * Delete on FF cleanup of issue-view-admin-global-opt-in-ai.
 * A hook used for shared editor configurations, should be used for the AKEditor component from @atlaskit/editor-core
 * @param { SharedEditorConfigProps } - props for the hook, currently just editorPluginAIConfig
 * @example
 *  ```tsx
 *   const sharedEditorConfigProps = { SOME_PROPS }
 *   const sharedEditorConfig = useSharedEditorConfig(sharedEditorConfigProps);
 *   <AkEditor {...sharedEditorConfig}/>
 * ```
 * @deprecated Please use useEditorConfig hook
 */
export const useSharedEditorConfig = ({
	editorPluginAIConfig,
}: SharedEditorConfigProps): SharedEditorConfig => {
	const { openFeedbackCollector } = useFeedbackCollectorActions();

	const PromptEditor = useMemo(
		() =>
			createPromptEditor({
				linking: editorPluginAIConfig?.linking,
				featureFlags: {},
				enableLinks: !!editorPluginAIConfig?.linking,
			}),
		[editorPluginAIConfig?.linking],
	);

	const editorPluginAI: EditorPluginAI | undefined = useMemo(() => {
		if (!editorPluginAIConfig?.product) {
			return undefined;
		}

		const optInStatus = getOptInStatus(editorPluginAIConfig);
		const editorPluginAIProvider: CreateEditorPluginAIProvider = createJiraEditorPluginAIProvider({
			generativeAIApiUrl: '/gateway/api/editor-ai/v1/generative/ai',
			issueId: fg('platform_editor_ai_suggested_response_fg')
				? editorPluginAIConfig?.issueId
				: undefined, // IssueId is only used in JSM Product
			product: editorPluginAIConfig.product,
			allowIssueReformatter:
				editorPluginAIConfig.product === Product.JSW && editorPluginAIConfig?.allowIssueReformatter,
			allowSuggestResponse: editorPluginAIConfig.allowSuggestResponse,
			getAdditionalProps: editorPluginAIConfig?.getAdditionalProps,
			handleFeedbackSubmission: async ({ sentiment, editorAttributes, getAIExperience }) => {
				try {
					openFeedbackCollector({
						entrypointId: ENTRY_POINT_ID_GENERATIVE_AI,
						feedbackCollectorId: FEEDBACK_COLLECTOR_ID,
						feedbackContext: [
							{ id: 'sentiment', value: sentiment },
							...Object.entries(getAIExperience?.() ?? {}).map(([id, value]) => ({
								id,
								value,
							})),
							...Object.entries(editorAttributes).map(([id, value]) => ({
								id,
								value,
							})),
						],
					});
					return { status: 'submitted' };
				} catch (error: unknown) {
					log.safeErrorWithoutCustomerData(
						'platforms.utils.shared-editor-config.entry',
						'Error sending feedback submission',
						error instanceof Error ? error : undefined,
					);
					return { status: 'failed' };
				}
			},
			PromptEditor,
			getChannelVariables: editorPluginAIConfig?.getChannelVariables,
			actionSideEffects: editorPluginAIConfig?.actionSideEffects,
			actionOverrides: editorPluginAIConfig?.actionOverrides,
		});

		return createJiraEditorPluginAI({
			editorPluginAIProvider,
			aiGlobalOptIn: {
				status: optInStatus,
				// Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				triggerOptInFlow: () => {},
			},
		}).editorPluginAI;
	}, [editorPluginAIConfig, openFeedbackCollector, PromptEditor]);

	return {
		dangerouslyAppendPlugins: {
			__plugins: editorPluginAI ? [editorPluginAI] : [],
		},
	};
};

// Extracting this function as it caused instability between renders, as picked up by Cypress
// eslint-disable-next-line @typescript-eslint/no-empty-function
const fireEmptyExperimentExposure = () => {};

/**
 * A hook used for  editor configurations, should be used for the AKEditor component from @atlaskit/editor-core
 * @param { SharedEditorConfigProps } - props for the hook, currently just editorPluginAIConfig
 * @example
 *  ```tsx
 *   const sharedEditorConfigProps = { SOME_PROPS }
 *   const editorConfig = useEditorConfig(sharedEditorConfigProps);
 *   <AkEditor {...editorConfig}/>
 * ```
 */
export const useEditorConfig = ({
	editorPluginAIConfig,
}: SharedEditorConfigProps): EditorConfig => {
	const { openFeedbackCollector, updateCustomRenderComponent, updateAdditionalFields } =
		useFeedbackCollectorActions();
	const { optInStatus, triggerOptInFlow, AIOptInModal } = useAIOptIn({ editorPluginAIConfig });

	// AI Upsell In Editor Experiment
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [config] = UNSAFE_noExposureExp('jira_free_ai_feature_gate');
	const {
		isEnrolledInExperiment: isAIUpsellInEditorFreeEnrolled,
		isExperimentEnabled: isAIUpsellInEditorFreeEnabled,
		fireExperimentExposure: fireAIUpsellInEditorFreeExposure,
	} = config.get<ExperimentValue>('experimentEnabled', undefined) !== undefined
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useAIUpsellInEditorFree(optInStatus, editorPluginAIConfig?.product)
		: {
				isEnrolledInExperiment: false,
				isExperimentEnabled: false,
				fireExperimentExposure: fireEmptyExperimentExposure,
			};

	useEffect(() => {
		if (isAIUpsellInEditorFreeEnrolled) {
			fireAIUpsellInEditorFreeExposure();
		}
	}, [isAIUpsellInEditorFreeEnrolled, fireAIUpsellInEditorFreeExposure]);

	const [hasUserConsent, setHasUserConsent] = useState(false);
	const [additionalFieldsData, setAdditionalFieldsData] =
		useState<HandleFeedbackSubmissionArgs | null>(null);
	const { formatMessage } = useIntl();

	const handleHasUserConsent = useCallback(() => {
		setHasUserConsent(!hasUserConsent);
		updateAdditionalFields(buildAdditionalFields(additionalFieldsData, !hasUserConsent));
	}, [hasUserConsent, additionalFieldsData, updateAdditionalFields]);

	const renderCustomFeedbackContent = useCallback(
		() => (
			<Checkbox
				label={formatMessage(messages.userConsentCheckboxLabel)}
				onChange={handleHasUserConsent}
				isChecked={hasUserConsent}
			/>
		),
		[hasUserConsent, formatMessage, handleHasUserConsent],
	);

	const PromptEditor = useMemo(
		() =>
			createPromptEditor({
				linking: editorPluginAIConfig?.linking,
				featureFlags: {},
				enableLinks: !!editorPluginAIConfig?.linking,
			}),
		[editorPluginAIConfig?.linking],
	);

	useEffect(() => {
		updateCustomRenderComponent(renderCustomFeedbackContent);
	}, [renderCustomFeedbackContent, updateCustomRenderComponent]);

	const AIConfiguration:
		| {
				plugin: EditorPluginAI | undefined;
				AIConfiguration: AIPluginConfiguration;
		  }
		| undefined = useMemo(() => {
		if (!editorPluginAIConfig?.product) {
			return undefined;
		}
		const editorPluginAIProvider: CreateEditorPluginAIProvider = createJiraEditorPluginAIProvider({
			generativeAIApiUrl: '/gateway/api/editor-ai/v1/generative/ai',
			issueId: fg('platform_editor_ai_suggested_response_fg')
				? editorPluginAIConfig?.issueId
				: undefined, // issueId will be required only in JSM product
			product: editorPluginAIConfig.product,
			allowIssueReformatter:
				editorPluginAIConfig.product === Product.JSW && editorPluginAIConfig?.allowIssueReformatter,
			allowSuggestResponse: editorPluginAIConfig.allowSuggestResponse,
			getAdditionalProps: editorPluginAIConfig?.getAdditionalProps,
			handleFeedbackSubmission: async ({
				sentiment,
				editorAttributes,
				getAIExperience,
			}: HandleFeedbackSubmissionArgs) => {
				// @ts-expect-error plugin issue
				const feedbackContextData: HandleFeedbackSubmissionArgs = {
					getAIExperience,
					sentiment,
					editorAttributes,
				};

				const feedbackCollectorProps = {
					entrypointId: ENTRY_POINT_ID_GENERATIVE_AI,
					feedbackTitle: formatMessage(messages.feedBackTitle),
					feedbackCollectorId: FEEDBACK_COLLECTOR_ID,
					renderCustomContent: renderCustomFeedbackContent,
					showTypeField: false,
					summaryPlaceholder: formatMessage(messages.summaryPlaceholder),
					additionalFields: buildAdditionalFields(feedbackContextData, hasUserConsent),
					canBeContactedLabel: <CanContactLabel />,
					enrolInResearchLabel: <EnrolLabel />,
				};

				setAdditionalFieldsData(feedbackContextData);

				try {
					openFeedbackCollector(feedbackCollectorProps);
					return { status: 'submitted' };
				} catch (error: unknown) {
					log.safeErrorWithoutCustomerData(
						'platforms.utils.shared-editor-config.entry',
						'Error sending feedback submission',
						error instanceof Error ? error : undefined,
					);
					return { status: 'failed' };
				}
			},

			PromptEditor,
			AIButtonWrapper: isAIUpsellInEditorFreeEnabled ? AIUpsellButtonWrapper : undefined,
			getChannelVariables: editorPluginAIConfig?.getChannelVariables,
			actionSideEffects: editorPluginAIConfig?.actionSideEffects,
			actionOverrides: editorPluginAIConfig?.actionOverrides,
		});

		return {
			plugin: createJiraEditorPluginAI({
				editorPluginAIProvider,
				aiGlobalOptIn: {
					status: optInStatus,
					triggerOptInFlow,
				},
			}).editorPluginAI,
			AIConfiguration: {
				editorPluginAIProvider,
				aiGlobalOptIn: {
					status: optInStatus,
					triggerOptInFlow,
				},
			},
		};
	}, [
		hasUserConsent,
		optInStatus,
		triggerOptInFlow,
		editorPluginAIConfig,
		openFeedbackCollector,
		formatMessage,
		renderCustomFeedbackContent,
		PromptEditor,
		isAIUpsellInEditorFreeEnabled,
	]);

	return {
		props: {
			dangerouslyAppendPlugins: {
				__plugins: AIConfiguration?.plugin ? [AIConfiguration.plugin] : [],
			},
			isAIUpsellInEditorFreeEnabled,
		},
		AIConfiguration: AIConfiguration?.AIConfiguration,
		components: {
			AIOptInModal,
		},
	};
};
