import React from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { useIssueLayoutContextPanel } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { shareAsComment } from '@atlassian/jira-issue-view-store/src/actions/share-as-comment-actions.tsx';
import { isAddCommentEditorExpandedSelector } from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { KbGlancePayload } from '@atlassian/jira-servicedesk-knowledge-glance-v2/src/common/types.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';

type Props = {
	isPanelOpen: boolean;
	payload: KbGlancePayload;
	contentWidth: number;
	onShareAsComment: (arg1: ADF) => void;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const KbGlancePanelV2 = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "kb-glance-panel-v2" */ '@atlassian/jira-servicedesk-knowledge-glance-v2/src/ui/kb-glance-panel'
		),
	{ ssr: false },
);

const KbGlancePanelContainer = (props: Props) => {
	const issueKey = useIssueKey();
	const [contextPanel] = useIssueLayoutContextPanel(issueKey);
	return (
		<Placeholder name="kb-glance-panel-v2" fallback={null}>
			<KbGlancePanelV2 {...props} isPanelOpen={contextPanel !== undefined} />
		</Placeholder>
	);
};

export default flowWithSafeComponent(
	connect(
		(state) => ({
			isEditorOpen: isAddCommentEditorExpandedSelector(state),
		}),
		{
			onShareAsComment: shareAsComment,
		},
	),
)(KbGlancePanelContainer);
