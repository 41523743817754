import React, { memo, useMemo } from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import {
	useContextState,
	useContextStateIssueAri,
	useReactionsState,
	useReactionsStateIsActive,
} from '../../../state/context.tsx';
import { CommentReactions } from './main.tsx';

const ConnectedCommentReactions = componentWithFG(
	'connected-comment-reactions-optimization',
	memo(() => {
		const [{ issueAri, commentAri }] = useContextStateIssueAri();
		const [{ isActive }] = useReactionsStateIsActive();

		const props = useMemo(
			() => ({
				visible: isActive,
				issueAri,
				commentAri,
			}),
			[isActive, issueAri, commentAri],
		);

		const MemoizedCommentReactions = memo(CommentReactions);

		return <MemoizedCommentReactions {...props} />;
	}),
	() => {
		const [{ issueAri, commentAri }] = useContextState();
		const [{ isActive }] = useReactionsState();

		return <CommentReactions visible={isActive} issueAri={issueAri} commentAri={commentAri} />;
	},
);

export default ConnectedCommentReactions;
