import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression,  @atlaskit/ui-styling-standard/no-styled
const ButtonContentControl = styled.div<{ isActive: boolean; hasSecurityLevel: boolean }>`
	display: flex;
	align-items: center;
	color: ${
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any) => {
			if (props.isActive) {
				return token('color.icon.brand', colors.N0);
			}
			if (props.hasSecurityLevel) {
				return token('color.icon.danger', colors.R400);
			}

			return null;
		}
	};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled
const ButtonContentExperiment = styled2.div<{
	isActive: boolean;
	hasSecurityLevel: boolean;
}>`
	display: flex;
	align-items: center;
	color: ${
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any) => {
			if (props.isActive) {
				return token('color.icon.brand', colors.N0);
			}
			if (props.hasSecurityLevel) {
				return token('color.icon.danger', colors.R400);
			}

			return null;
		}
	};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles
export const ButtonContent = styledComponentWithCondition(
	() => ff('compiled-react-migration-issue-view_u9myz'),
	ButtonContentExperiment,
	ButtonContentControl,
);
