import { defineMessages } from 'react-intl-next';

export default defineMessages({
	cancelLabel: {
		id: 'fabric.editor.ai.components.cancelIconButton.cancelLabel',
		defaultMessage: 'Cancel',
		description: 'Cancel button label',
	},
	cancelTooltip: {
		id: 'fabric.editor.ai.components.cancelIconButton.cancelTooltip',
		defaultMessage: 'Cancel',
		description: 'Cancel button tooltip',
	},
});
