import React, { memo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import {
	MultilineFieldHeading,
	MultilineFieldHeadingTitle,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { useMessageField } from '@atlassian/jira-issue-field-message/src/services/index.tsx';
import MessageFieldView from '@atlassian/jira-issue-field-message/src/ui/view/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import { CONTENT } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import {
	SectionHeading,
	SectionHeadingTitle,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import type { Props } from './types.tsx';

const View = ({ fieldId: fieldKey, area, label }: Props) => {
	const issueKey = useIssueKey();
	const showPinButton = getShowPinButton(area);
	const [{ value, fieldConfig }] = useMessageField({ issueKey, fieldKey });
	const HeadingWrapper = area === CONTENT ? SectionHeading : MultilineFieldHeading;
	const HeadingTitle = area === CONTENT ? SectionHeadingTitle : MultilineFieldHeadingTitle;
	const fieldLabel = fieldConfig?.title || label;

	return (
		<JSErrorBoundary
			id="MessageCustomField"
			packageName="jiraIssueViewBase"
			fallback="unmount"
			sendToPrivacyUnsafeSplunk
		>
			<Box xcss={fieldContainerStyles}>
				<HeadingWrapper>
					<HeadingTitle>{fieldLabel}</HeadingTitle>
					{issueKey !== undefined && fieldKey !== undefined && (
						// @ts-expect-error label is required, please pass it through when working on this code.
						<FieldDescription issueKey={issueKey} fieldKey={fieldKey} />
					)}
					{showPinButton === true && <FieldPin fieldId={fieldKey} label={fieldLabel} />}
				</HeadingWrapper>
				<Box xcss={messageFieldWrapperStyles}>
					<MessageFieldView value={value} />
				</Box>
			</Box>
		</JSErrorBoundary>
	);
};

export default memo<Props>(View);

const messageFieldWrapperStyles = xcss({
	paddingTop: 'space.050',
});

const fieldContainerStyles = xcss({
	marginBottom: 'space.200',
});
