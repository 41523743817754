import React, { useMemo } from 'react';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	ISSUE_ACTIVITY_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import {
	isCustomUIExtension,
	isNativeUiExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension/index.tsx';
import type { IssueActivity as IssueActivityType } from '@atlassian/jira-forge-ui/src/ui/components/issue-activity/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { LazyIssueViewForgeExtension } from '../../common/ui/extension/issue-view/index.tsx';
import { LazyIssueViewIframe } from '../../common/ui/iframe/issue-view/index.tsx';
import { Skeleton } from '../../common/ui/skeleton/main.tsx';
import type { LazyRenderer, LazyComponentProps } from '../../types.tsx';

const skeletonConfig = [{ width: 75 }, { width: 100 }];

export const LazyIssueActivityRenderer: LazyRenderer<typeof IssueActivityType> = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint<LazyRenderer<typeof IssueActivityType>>(
		() =>
			import(
				/* webpackChunkName: "async-forge-ui-issue-activity" */ '@atlassian/jira-forge-ui/src/ui/components/issue-activity'
			),
		{ ssr: false },
	),
);

export const LazyIssueActivity = (props: LazyComponentProps<typeof IssueActivityType>) => {
	const { extension, extensionData } = props;
	const loadingComponent = useMemo(() => <Skeleton skeletonConfig={skeletonConfig} />, []);
	const extensionDataValue = useMemo(
		() => ({
			...extensionData,
			type: ISSUE_ACTIVITY_MODULE,
		}),
		[extensionData],
	);
	const extraProps = useMemo(
		() => ({
			loadingComponent,
			module: ISSUE_ACTIVITY_MODULE,
			extensionData: extensionDataValue,
			contextToken: CONTEXT_TOKEN_TYPE_JIRA,
		}),
		[extensionDataValue, loadingComponent],
	);

	if (isNativeUiExtension(extension) || isCustomUIExtension(extension)) {
		return (
			<Placeholder name="issue-activity" fallback={loadingComponent}>
				<LazyIssueViewForgeExtension {...props} {...extraProps} />
			</Placeholder>
		);
	}

	return (
		<Placeholder name="issue-activity" fallback={loadingComponent}>
			{isCustomUIExtension(extension) ? (
				<LazyIssueViewIframe {...props} {...extraProps} />
			) : (
				<LazyIssueActivityRenderer {...props} {...extraProps} />
			)}
		</Placeholder>
	);
};

export const AsyncIssueActivity = LazyIssueActivity;
