import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	authenticationFailedFlagTitle: {
		id: 'design-provider-auth-check.services.authentication-failed-flag-title',
		defaultMessage: 'Something went wrong',
		description:
			'Title of a flag shown to the user indicating that an issue occurred during an authentication flow',
	},
	authenticationFailedFlagDescription: {
		id: 'design-provider-auth-check.services.authentication-failed-flag-description',
		defaultMessage:
			'Something went wrong while connecting {providerCount, select, 1 {{singleProvider} to Jira. Please try again later.} other {design providers to Jira. Please try again later.}}',
		description:
			'Contents of a flag shown to the user indicating that an issue occurred during an authentication flow',
	},
});
