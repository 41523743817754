import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';

import type { AIGlobalOptIn, EditorPluginAIProvider } from '../../types';
import { createOpenAIModalCommand } from '../decoration/actions';

import { aiEventHubPluginKey } from './ai-event-hub-plugin-key';
import { eventHub } from './event-hub';
import { EventHubPrompt } from './types';
import { firePromptConfigNotFoundError } from './utils/analytic-utils';
import { getConfigItem } from './utils/config-utils';
import { findEditorIdFromView } from './utils/isolated-utils';

export const createAIEventHubPlugin = (options: {
	aiGlobalOptIn: AIGlobalOptIn;
	editorPluginAIProvider: EditorPluginAIProvider;
}) => {
	const { aiGlobalOptIn } = options;

	return new SafePlugin({
		key: aiEventHubPluginKey,

		view: (editorView) => {
			let currentEditorView = editorView;

			const { unsubscribe } = eventHub.subscribe({
				event: 'start prompt',
				callback: (data) => {
					const editorId = findEditorIdFromView(currentEditorView);
					if (editorId === data.targetEditorId) {
						const { state, dispatch } = currentEditorView;
						const configItem = getConfigItem(
							data.prompt,
							options.editorPluginAIProvider,
							data.adjustSelection,
						);

						if (!configItem) {
							firePromptConfigNotFoundError({
								dispatch,
								editorView: currentEditorView,
								prompt: data.prompt,
								promptText:
									data.prompt === EventHubPrompt.CustomPrompt ? data.promptText : undefined,
								adjustSelection: data.adjustSelection,
							});
							return;
						}

						const params = {
							state,
							configItem: configItem,
							lastTriggeredFrom: data.analyticSourceId,
							aiGlobalOptIn,
							adjustSelection: data.adjustSelection,
							initialPrompt:
								data.prompt === EventHubPrompt.CustomPrompt ? data.promptText : undefined,
						};
						const openAIModalCommand = createOpenAIModalCommand(params);
						openAIModalCommand(state, dispatch);
					}
				},
			});

			return {
				update: (newEditorView) => {
					currentEditorView = newEditorView;
				},
				destroy: () => {
					unsubscribe();
				},
			};
		},
	});
};
