import { useCallback } from 'react';
import { useEnabledHiddenFields as useHiddenFieldsByUim } from '@atlassian/jira-issue-adjustments/src/controllers/use-enabled-hidden-fields/index.tsx';
import type { ContainersByType } from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	useIssueLayoutActionsStore,
	useIssueLayoutGlanceStore,
	useIssueLayoutContextPanelStore,
	useIssueLayoutContainersStore,
} from './context.tsx';
import type { Glance, ContextPanelItem } from './types.tsx';
import { filterOutItemsWithProvidedIds } from './utils.tsx';

export const useIssueLayoutContainers = (issueKey: IssueKey) => {
	const [containers] = useIssueLayoutContainersStore(issueKey);
	return [containers] as const;
};

export const useLayoutContainerByType = (issueKey: IssueKey, type: string) => {
	const [containers] = useIssueLayoutContainersStore(issueKey);
	const container = containers.find((innerContainer) => innerContainer.containerType === type);
	// Forge app with UI Modifications module can hide selected fields using
	// UI modifications Field API. We are removing them here from the layout fields
	const fieldsHiddenByUim = useHiddenFieldsByUim();

	return [filterOutItemsWithProvidedIds(container?.items.nodes ?? [], fieldsHiddenByUim)] as const;
};

export const useIssueLayoutGlance = (issueKey: IssueKey) => {
	const [glance] = useIssueLayoutGlanceStore(issueKey);

	return [glance] as const;
};

export const useIssueLayoutContextPanel = (issueKey: IssueKey) => {
	const [contextPanel] = useIssueLayoutContextPanelStore(issueKey);

	return [contextPanel] as const;
};

export const useIssueLayoutActions = () => {
	const [, actions] = useIssueLayoutActionsStore();

	const setIssueViewContainersLayout = useCallback(
		(issueKey: IssueKey, containersByType: ContainersByType) => {
			actions.setIssueViewContainersLayout(issueKey, containersByType);
		},
		[actions],
	);

	const setIssueViewLayoutGlance = useCallback(
		(issueKey: IssueKey, glance?: Glance) => {
			actions.setIssueViewLayoutGlance(issueKey, glance);
		},
		[actions],
	);

	const setIssueViewLayoutContextPanel = useCallback(
		(issueKey: IssueKey, newContextPanel?: ContextPanelItem) => {
			actions.setIssueViewLayoutContextPanel(issueKey, newContextPanel);
		},
		[actions],
	);

	return [
		null,
		{
			setIssueViewContainersLayout,
			setIssueViewLayoutGlance,
			setIssueViewLayoutContextPanel,
		},
	] as const;
};
