import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import type { Dispatch } from 'redux';
import { styled } from '@compiled/react';
import flow from 'lodash/flow';
import memoizeOne from 'memoize-one';
import scrollIntoView from 'scroll-into-view-if-needed';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { EditorActions } from '@atlaskit/editor-core';
import { useDevOpsAppRecommendationsStore } from '@atlassian/jira-dev-ops-app-recommendations/src/controllers/recommendation-panel/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useLinkNewConfluenceContent } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-link-new-confluence-content/index.tsx';
import { useImproveIssueStore } from '@atlassian/jira-issue-improve-issue-dropdown/src/store.tsx';
import RichTextInlineEditView, {
	type Props,
} from '@atlassian/jira-issue-internal-fields/src/rich-text/rich-text-inline-edit-view.tsx';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import { IssueTaskDecisionProvider } from '@atlassian/jira-issue-task-decision-provider/src/controllers/index.tsx';
import { useDescriptionFieldTasksUpdater } from '@atlassian/jira-issue-task-decision-provider/src/controllers/use-description-field-tasks-updater/index.tsx';
import { RICH_CONTENT_FIELD_CONFIRM_ERROR } from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import { ISSUE_DESCRIPTION_EDITOR_ID } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import type { FieldOptions } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { getIssueModalEditorDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { isDevOpsAppRecommenderEnabled } from '@atlassian/jira-issue-view-common/src/feature-flags.tsx';
import { DESCRIPTION } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { withEditExperienceTracker } from '@atlassian/jira-issue-view-experience-tracking/src/edit-experience/index.tsx';
import { mentionProviderSelector } from '@atlassian/jira-issue-view-services/src/mentions/mention-provider-selector.tsx';
import {
	batchCreateConfluencePageLinkRequests,
	batchCreateConfluencePageLinksSuccess,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import {
	editorExpandedFailure,
	editorSaveFailure,
	editorChangeFailure,
} from '@atlassian/jira-issue-view-store/src/actions/editor-actions.tsx';
import { fetchUploadContextRequest } from '@atlassian/jira-issue-view-store/src/common/media/upload-context/upload-context-actions.tsx';
import { fetchViewContextRequest } from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions.tsx';
import { activityProviderSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/activity-provider-selector.tsx';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	fieldEditingAdfValueSelector,
	fieldHtmlValueSelector,
	fieldInvalidSelector,
	fieldInvalidMessageSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector.tsx';
import { confluenceAppLinksSelector } from '@atlassian/jira-issue-view-store/src/selectors/confluence-app-links-selector.tsx';
import { linkedConfluencePagesPagesSelector } from '@atlassian/jira-issue-view-store/src/selectors/confluence-content-selector.tsx';
import { richDescriptionContextIdentifierSelector } from '@atlassian/jira-issue-view-store/src/selectors/description-selector.tsx';
import { canCreateLinkedIssueSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector.tsx';
import { isFieldInTabSelector } from '@atlassian/jira-issue-view-store/src/selectors/tab-selector.tsx';
import { messages } from './messages.tsx';
import { useRegisterInCommandPalette } from './register-command-palette/index.tsx';

export type DescriptionFieldProps = Props & {
	fieldOptions: FieldOptions<ADF>;
	canCreateIssueLink: boolean;
	confluenceAppLinks: ConfluenceAppLink[];
	linkedConfluenceContent: (ConfluencePage | FailedRemoteLink)[] | null | undefined;
	onAutoLinkPagesRequest: () => void;
	onAutoLinkPagesComplete: (confluencePageLink: (ConfluencePage | FailedRemoteLink)[]) => void;
};

const performPut = <ValueType,>(issueKey: string, value: ValueType) => {
	const url = `/rest/api/3/issue/${issueKey}`;
	return performPutRequest(url, {
		body: JSON.stringify({
			fields: {
				description: value,
			},
		}),
	});
};

const SCROLL_MARGIN_TOP = 100;

export const onEditAction = ({
	onEditRequest,
	focusOnEditor,
	editorRef,
}: {
	onEditRequest: () => void;
	focusOnEditor?: () => void;
	editorRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
	onEditRequest();

	// prevent race condition when editor has not finished rendering immediately
	setTimeout(() => {
		focusOnEditor?.();
		if (editorRef?.current) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			if (editorRef.current.offsetHeight > window.innerHeight / 2) {
				editorRef.current.scrollIntoView({
					block: 'end',
				});
			} else {
				scrollIntoView(editorRef.current, {
					block: 'start',
					scrollMode: 'if-needed',
					// this behaves like scrollMarginTop style property
					behavior: (actions) =>
						actions.forEach(({ el, top }) => {
							// eslint-disable-next-line no-param-reassign
							el.scrollTop = top - SCROLL_MARGIN_TOP;
						}),
				});
			}
		}
	});
};

export const focusOnEditorUsingRef = (
	editorActionsRef?: React.MutableRefObject<EditorActions | null>,
) => {
	if (editorActionsRef?.current) {
		editorActionsRef.current.focus();
	}
};

const DefaultDescriptionField = (props: DescriptionFieldProps) => {
	const {
		onEditRequest,
		onConfirm,
		adfValue,
		canCreateIssueLink,
		linkedConfluenceContent,
		confluenceAppLinks,
		onAutoLinkPagesRequest,
		onAutoLinkPagesComplete,
	} = props;
	const [{ recommendationDetails }, linkPasteActions] = useDevOpsAppRecommendationsStore();

	const editorActionsRef = useRef<EditorActions | null>(null);
	const editorRef = useRef<HTMLDivElement | null>(null);
	const focusOnEditor = useCallback(() => {
		if (fg('jira-ai-issue-view-improve-issues-button')) {
			return focusOnEditorUsingRef(editorActionsRef);
		}
		if (editorActionsRef?.current) {
			editorActionsRef.current.focus();
		}
	}, [editorActionsRef]);
	const [{ onEditorReady }, { setOnEditAction: setImproveIssueStoreOnEditAction }] =
		useImproveIssueStore();

	const onEditRequestHandler = useCallback(() => {
		onEditRequest();

		if (fg('one_event_rules_them_all_fg')) {
			getUpdateAnalyticsFlowHelper().setAttributes('description', {
				isCommandPaletteEditing: false,
			});
		}
	}, [onEditRequest]);

	const { linkNewConfluenceContent } = useLinkNewConfluenceContent({
		isLinkIssuesEnabled: canCreateIssueLink,
		confluenceAppLinks: confluenceAppLinks ?? [],
		currentLinkedConfluenceContent: linkedConfluenceContent ?? [],
		onAutoLinkPagesRequest,
		onAutoLinkPagesComplete,
	});

	const onCompalEditRequestHandler = useCallback(() => {
		if (fg('one_event_rules_them_all_fg')) {
			getUpdateAnalyticsFlowHelper().setAttributes('description', {
				isCommandPaletteEditing: true,
			});
		}
		if (fg('jira-ai-issue-view-improve-issues-button')) {
			return onEditAction({ onEditRequest, focusOnEditor, editorRef });
		}
		onEditRequest();

		// prevent race condition when editor has not finished rendering immediately
		setTimeout(() => {
			focusOnEditor?.();
			if (editorRef?.current) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				if (editorRef.current.offsetHeight > window.innerHeight / 2) {
					editorRef.current.scrollIntoView({
						block: 'end',
					});
				} else {
					scrollIntoView(editorRef.current, {
						block: 'start',
						scrollMode: 'if-needed',
						// this behaves like scrollMarginTop style property
						behavior: (actions) =>
							actions.forEach(({ el, top }) => {
								// eslint-disable-next-line no-param-reassign
								el.scrollTop = top - SCROLL_MARGIN_TOP;
							}),
					});
				}
			}
		});
	}, [onEditRequest, focusOnEditor]);

	const onImproveDescriptionRequestHandler = useCallback(
		() => onEditAction({ onEditRequest, focusOnEditor, editorRef }),
		[onEditRequest, focusOnEditor],
	);

	const onConfirmHandler = useCallback(() => {
		onConfirm();
		if (isDevOpsAppRecommenderEnabled() && recommendationDetails) {
			linkPasteActions.setShowRecommendation(true);
		}

		if (fg('jira-issue_auto-link-pages-on-link-paste_gate')) {
			if (adfValue) {
				linkNewConfluenceContent(adfValue);
			}
		}
	}, [onConfirm, recommendationDetails, linkPasteActions, linkNewConfluenceContent, adfValue]);

	useRegisterInCommandPalette({ onEditRequest: onCompalEditRequestHandler });

	useEffect(() => {
		if (fg('jira-ai-issue-view-improve-issues-button')) {
			if (editorRef.current) {
				setImproveIssueStoreOnEditAction(onImproveDescriptionRequestHandler);
			}
		}
	}, [onImproveDescriptionRequestHandler, setImproveIssueStoreOnEditAction]);

	return (
		/**
		 * data-editor-container-id is used to identify that this editor is the description editor
		 * for usage inside jira/src/packages/issue/issue-improve-issue-dropdown/src/index.tsx
		 * to trigger the AI Improve Description feature.
		 */
		<EditorContainer ref={editorRef} data-editor-container-id={ISSUE_DESCRIPTION_EDITOR_ID}>
			<RichTextInlineEditView
				{...props}
				onEditRequest={onEditRequestHandler}
				onConfirm={onConfirmHandler}
				editorActionsRef={editorActionsRef}
				{...(fg('jira-ai-issue-view-improve-issues-button') ? { onEditorReady } : {})}
			/>
		</EditorContainer>
	);
};

const DescriptionFieldWithTaskDecisionProvider = (props: DescriptionFieldProps) => {
	const toggleTask = useDescriptionFieldTasksUpdater(props.fieldOptions);

	// When edit-view is displayed, create an empty `TaskDecisionProvider` since `props.onConfirm` will handle the ADF update
	const editorTaskDecisionProvider = useMemo(() => new IssueTaskDecisionProvider(), []);

	const rendererTaskDecisionProvider = useMemo(
		() => new IssueTaskDecisionProvider(toggleTask),
		[toggleTask],
	);

	return (
		<DefaultDescriptionField
			{...props}
			editorTaskDecisionProvider={editorTaskDecisionProvider}
			rendererTaskDecisionProvider={rendererTaskDecisionProvider}
		/>
	);
};

export const DescriptionField = componentWithCondition(
	() => expVal('issue_view_action_items', 'isActionItemsEnabled', false),
	DescriptionFieldWithTaskDecisionProvider,
	DefaultDescriptionField,
);

const onExpandedFailureFactory = memoizeOne(
	(dispatch: Dispatch) => () => dispatch(editorExpandedFailure()),
);
const onSaveFailureFactory = memoizeOne(
	(dispatch: Dispatch) => () => dispatch(editorSaveFailure()),
);

const onChangeFailureFactory = memoizeOne(
	(dispatch: Dispatch) => () => dispatch(editorChangeFailure()),
);

const onViewRefreshFactory = memoizeOne(
	(dispatch: Dispatch) => () => dispatch(fetchViewContextRequest()),
);

const onUploadRefreshFactory = memoizeOne(
	(dispatch: Dispatch) => () => dispatch(fetchUploadContextRequest()),
);

const onAutoLinkPagesRequestFactory = memoizeOne(
	(dispatch: Dispatch) => () => dispatch(batchCreateConfluencePageLinkRequests()),
);

const onAutoLinkPagesCompleteFactory = memoizeOne(
	(dispatch: Dispatch) => (confluencePageLinks: (ConfluencePage | FailedRemoteLink)[]) =>
		dispatch(batchCreateConfluencePageLinksSuccess(confluencePageLinks)),
);

export default flow(
	withEditExperienceTracker(DESCRIPTION),
	connectField(
		(_, { saveFieldOverride }) => ({
			fieldId: DESCRIPTION,
			shouldSaveDraft: true,
			isOptimistic: true,
			saveField:
				ff('relay-migration-issue-fields-issue-multi-line-text_w69oo') && saveFieldOverride
					? async ({ value }) => saveFieldOverride?.({ value })
					: ({ issueKey, value }) => performPut(issueKey, value),
			canContainMediaContent: true,
			onSaveFailureFlagType: RICH_CONTENT_FIELD_CONFIRM_ERROR,
			// @ts-expect-error - TS2322 - Type '(state: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>, intl: IntlShapeV2) => { ...' is not assignable to type 'AdditionalProps<unknown>'.
			additionalProps: (state, intl) => {
				const baseUrl = baseUrlSelector(state);
				return {
					baseUrl,
					portalElement: isFieldInTabSelector(DESCRIPTION)(state)
						? // Using a portal element here because otherwise dropdowns would get cut off
							// See: https://jdog.jira-dev.com/browse/BENTO-4100

							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							getIssueModalEditorDropdownPortal() || document.body
						: undefined,
					adfValue: fieldEditingAdfValueSelector(DESCRIPTION)(state),
					...(ff('relay-migration-issue-fields-issue-multi-line-text_w69oo')
						? {}
						: {
								htmlValue: fieldHtmlValueSelector(DESCRIPTION)(state),
							}),
					isInvalid: fieldInvalidSelector(DESCRIPTION)(state),
					invalidMessage: fieldInvalidMessageSelector(DESCRIPTION)(state),
					mediaContext: mediaContextSelector(state),
					mentionProvider: mentionProviderSelector(state),
					contextIdentifier: richDescriptionContextIdentifierSelector(state),
					activityProvider: activityProviderSelector(state),
					externalId: 'issue.descriptionHtml',
					noValueText: intl.formatMessage(messages.noValueText),
					canCreateIssueLink: canCreateLinkedIssueSelector(state),
					confluenceAppLinks: confluenceAppLinksSelector(state),
					linkedConfluenceContent: linkedConfluencePagesPagesSelector(state), // currently only pages are targeted to be linked, other content types will be implemented at a later time.
				};
			},
			additionalCallbacks: (dispatch) => ({
				onExpandedFailure: onExpandedFailureFactory(dispatch),
				onSaveFailure: onSaveFailureFactory(dispatch),
				onChangeFailure: onChangeFailureFactory(dispatch),
				onViewRefresh: onViewRefreshFactory(dispatch),
				onUploadRefresh: onUploadRefreshFactory(dispatch),
				onAutoLinkPagesRequest: onAutoLinkPagesRequestFactory(dispatch),
				onAutoLinkPagesComplete: onAutoLinkPagesCompleteFactory(dispatch),
			}),
		}),
		undefined,
		{
			shouldPassProps: true,
		},
	),
)(DescriptionField);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditorContainer = styled.div({
	scrollMarginBottom: '100px',
});
