import React from 'react';
import PopupAttachmentPicker from '../../popup/index.tsx';
import EventHandlersProvider from '../common/event-handlers-provider/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<EventHandlersProvider
		render={(eventHandlers) => <PopupAttachmentPicker eventHandlers={eventHandlers} />}
	/>
);
