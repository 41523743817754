/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useEffect, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Text } from '@atlaskit/primitives';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import VisuallyHidden from '@atlaskit/visually-hidden';

import { AI_MODAL_SCREEN_CLASS } from '../../../utils/constants';
import { FloatingContainer } from '../../components/FloatingContainer/FloatingContainer';
import { scrollMarginStyles } from '../../components/FloatingContainer/styles';
import { useElementBreakpoints } from '../../hooks/useElementBreakpoints';

import messages from './messages';
import {
	buttonContainerStyles,
	getContainerStyles,
	getIconStyles,
	getTextContainerStyles,
	iconColor,
} from './styles';

type Props = {
	onCancel: () => void;
	onDiscard: () => void;
	text: string;
};

export const DiscardScreen = ({ onCancel, onDiscard, text }: Props) => {
	const { formatMessage } = useIntl();
	const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);
	const [setBreakpointsElement, { breakpoint }] = useElementBreakpoints();

	const setRootContainer = (element: HTMLDivElement | null) => {
		setContainerElement(element);
		setBreakpointsElement(element);
	};

	// Scroll the discard screen into view if it's not fully visible
	useEffect(() => {
		if (containerElement) {
			containerElement.scrollIntoView({ block: 'nearest' });
		}
	}, [containerElement]);

	return (
		<FloatingContainer
			ref={setRootContainer}
			data-testid="discard-screen"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={`discard-screen ${AI_MODAL_SCREEN_CLASS}`}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={[getContainerStyles(breakpoint), scrollMarginStyles]}
			paddingAdjustments={{ top: 1, bottom: 1 }}
			rainbowBorder={!editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')}
		>
			<VisuallyHidden>{formatMessage(messages.warningIconLabel)}</VisuallyHidden>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={getTextContainerStyles(breakpoint)}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
				<span css={getIconStyles(breakpoint)}>
					<WarningIcon label={formatMessage(messages.warningIconLabel)} primaryColor={iconColor} />
				</span>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}

				<Text as="p">{text}</Text>
			</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={buttonContainerStyles}>
				<Button
					testId="cancel-button"
					spacing="compact"
					onClick={onCancel}
					type="button"
					appearance="subtle"
				>
					{formatMessage(messages.cancelLabel)}
				</Button>
				<Button
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus
					testId="discard-button"
					spacing="compact"
					onClick={onDiscard}
					type="button"
					appearance="warning"
				>
					{formatMessage(messages.discardLabel)}
				</Button>
			</div>
		</FloatingContainer>
	);
};
