import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { singleLineClamp } from '@atlassian/jira-common-styles/src/mixins.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionWrapper = styled.span({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionTextContainer = styled.span({
	display: 'flex',
	flexDirection: 'column',
	margin: `0 ${token('space.100', '8px')}`,
	padding: `${token('space.025', '2px')} 0`,
	overflow: 'hidden',
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Description = styled.span({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const OptionLabel = styled.span(singleLineClamp, {
	flexGrow: 1,
});
