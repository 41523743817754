import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import { type EditorView } from '@atlaskit/editor-prosemirror/view';

import { addAnalytics } from '../../../analytics/utils';

export const firePromptConfigNotFoundError = ({
	editorView,
	dispatch,
	prompt,
	adjustSelection,
	promptText,
}: {
	editorView: EditorView;
	dispatch: EditorView['dispatch'];
	prompt?: string;
	promptText?: string;
	adjustSelection?: string;
}) => {
	const errorEventTr = addAnalytics({
		editorState: editorView.state,
		tr: editorView.state.tr,
		payload: {
			action: 'error',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'eventHubPlugin',
			attributes: {
				errorType: 'promptConfigItemNotFound',
				prompt: prompt,
				promptText: promptText,
				adjustSelection: adjustSelection,
			},
			eventType: EVENT_TYPE.OPERATIONAL,
		},
	});
	dispatch(errorEventTr);
};
