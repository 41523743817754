import React, {
	type ReactNode,
	useState,
	useEffect,
	useMemo,
	useCallback,
	useRef,
	type ReactElement,
	type ChangeEvent,
} from 'react';
import { Text } from '@atlaskit/primitives';
import messages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import isValidEmailAddress from '@atlassian/jira-common-is-valid-email-address/src/index.tsx';
import isValidUrl from '@atlassian/jira-common-util-is-url/src/index.tsx';
import prefixUrl from '@atlassian/jira-common-util-prefix-url/src/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedNumber } from '@atlassian/jira-intl';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	SideBySideField,
	FieldWrapper,
	FieldHeadingTitle,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import { LazySingleLineSmartLink } from '@atlassian/jira-issue-field-smart-link/src/ui/single-line-smart-link/async.tsx';
import { LazySmartLink } from '@atlassian/jira-issue-field-smart-link/src/ui/smart-link/async.tsx';
import { Ellipsis } from '@atlassian/jira-issue-view-common-styles/src/issue-value.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import validationMessages from './messages.tsx';
import SingleLineTextField, { type RefShape } from './single-line-text-field-view.tsx';
import {
	InlineEditContainer,
	ReadViewContainer,
	readViewContainerSelectorName,
} from './single-line-text-inline-edit-view.styled.tsx';

export type NumberOrString = number | string;
export type FieldType = 'number' | 'url' | 'text' | 'object';

export type Props = {
	isEditable?: boolean;
	isEditing?: boolean;
	isMobile?: boolean;
	issueKey?: IssueKey;
	value: NumberOrString | null;
	label?: string;
	placeholder?: string;
	noValueText?: string | null;
	invalidMessage?: ReactNode;
	type?: FieldType;
	fieldId: string;
	showPinButton?: boolean;
	hideActionButtons?: boolean;
	renderAppEditView?: () => ReactElement;
	// eslint-disable-next-line jira/react/handler-naming
	customReadView?: (value: Props['value']) => ReactElement;
	titleView?: ReactNode;
	// eslint-disable-next-line jira/react/handler-naming
	fieldContentWrapper?: (arg1: { children: ReactNode; topMargin?: number }) => ReactElement;
	onEditRequest: () => void;
	onCancel: () => void;
	onChange: (value: NumberOrString | null) => void;
	onConfirm: () => void;
};

export type PropsWithIntl = Props & {
	intl: IntlShape;
	isEditable: boolean;
	isEditing: boolean;
	isMobile: boolean;
	value: NumberOrString | null;
	noValueText: string | null;
	type: FieldType;
	label: string;
};

// eslint-disable-next-line no-script-url
const forbiddenScheme = 'javascript:';

const formatReadValue = (type: FieldType, value: NumberOrString) => {
	switch (type) {
		case 'number': {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return <FormattedNumber value={parseInt(value as string, 10)} />;
		}

		// The text field tries to be helpful with linking and formatting
		case 'text': {
			if (isValidEmailAddress(value)) {
				if (expValEquals('jsw_perf_format-links-in-custom-fields', 'issue-view', true)) {
					return <LazySmartLink appearance="inline" url={value.toString()} />;
				}
				return <a href={`mailto:${value}`}>{value}</a>;
			}

			// Must come before url check because (e.g.) 42.1 is a valid url
			if (Number(value)) {
				return value;
			}

			if (isValidUrl(value, true) && !value.toString().startsWith(forbiddenScheme)) {
				if (expValEquals('jsw_perf_format-links-in-custom-fields', 'issue-view', true)) {
					return (
						<LazySmartLink
							appearance="inline"
							url={value.toString()}
							analyticsContext={{
								attributes: {
									fieldType: 'text',
								},
							}}
						/>
					);
				}

				const prefixedUrl = prefixUrl(value, '//');

				return (
					<a rel="noopener noreferrer" href={prefixedUrl} target="_blank">
						{value}
					</a>
				);
			}

			return value;
		}

		// The url field is forced to be a link
		case 'url': {
			if (value.toString().startsWith(forbiddenScheme)) {
				return value;
			}
			if (isValidEmailAddress(value)) {
				if (expValEquals('jsw_perf_format-links-in-custom-fields', 'issue-view', true)) {
					return (
						<LazySingleLineSmartLink fieldType="url" text={String(value)} url={`mailto:${value}`} />
					);
				}
				return <a href={`mailto:${value}`}>{value}</a>;
			}

			if (expValEquals('jsw_perf_format-links-in-custom-fields', 'issue-view', true)) {
				return (
					<LazySingleLineSmartLink
						url={prefixUrl(value, '//')}
						anchorTarget="_blank"
						fieldType="url"
					/>
				);
			}

			return (
				<a href={prefixUrl(value, '//')} target="_blank">
					{value}
				</a>
			);
		}
		case 'object':
			return <Text as="p">{JSON.stringify(value)}</Text>;

		default:
			return value;
	}
};

export const SingleLineTextInlineEditView = ({
	isEditable = false,
	isEditing = false,
	isMobile = false,
	value = null,
	noValueText = null,
	invalidMessage: propInvalidMessage = null,
	type = 'text',
	label = '',
	issueKey,
	placeholder,
	fieldId,
	showPinButton,
	customReadView,
	titleView = label,
	fieldContentWrapper: FieldContentWrapper,
	onEditRequest,
	onCancel,
	onChange,
	onConfirm,
	renderAppEditView,
	hideActionButtons,
}: Props) => {
	const { formatMessage } = useIntl();

	const editFieldRef = useRef<RefShape>(null);

	const [invalidMessage, setInvalidMessage] = useState<string | null>(null);
	const [showValidationMessage, setShowValidationMessage] = useState(false);
	const [displayValue, setDisplayValue] = useState<string | null>(null);

	const testId = `issue.views.field.single-line-text-inline-edit.${fieldId}`;

	const previousValue = usePrevious(value);

	const handleOnChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			let lValue;
			if (type === 'number') {
				if (event.target.value === '') {
					lValue = null;
				} else {
					lValue = Number(event.target.value);
					setDisplayValue(event.target.value);
				}
			} else if (type === 'object') {
				try {
					lValue = JSON.parse(event.target.value);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (e: any) {
					lValue = event.target.value;
				}
			} else {
				lValue = event.target.value;
			}

			if (type === 'url') {
				setShowValidationMessage(false);
				let { validationMessage } = event.target;
				if (event.target.value.toString().startsWith(forbiddenScheme)) {
					validationMessage = formatMessage(validationMessages.validUrl);
				}
				setInvalidMessage(validationMessage);
			}

			onChange(lValue);
		},
		[formatMessage, onChange, type],
	);

	const handleOnConfirm = useCallback(() => {
		if (invalidMessage) {
			setShowValidationMessage(true);

			editFieldRef.current?.focus();
		} else {
			onConfirm();
		}
	}, [editFieldRef, invalidMessage, onConfirm]);

	const handleOnCancel = useCallback(() => {
		if (type === 'url') {
			setShowValidationMessage(false);
			setInvalidMessage(null);
		}

		onCancel();
	}, [onCancel, type]);

	useEffect(() => {
		if (previousValue !== undefined && type === 'number' && value !== previousValue) {
			setDisplayValue(null);
		}
	}, [previousValue, type, value]);

	const editView = useMemo(() => {
		if (!isEditable) return null;

		if (renderAppEditView && fg('forge_cf_inline_edit_on_issue_view')) {
			return renderAppEditView;
		}

		let lDisplayValue = '';
		if (value != null) {
			if (type === 'object' && typeof value === 'object') {
				lDisplayValue = JSON.stringify(value);
			} else {
				lDisplayValue = displayValue || value.toString();
			}
		}
		return (
			<SingleLineTextField
				compact
				ref={editFieldRef}
				type={type}
				value={lDisplayValue}
				placeholder={placeholder}
				onChange={handleOnChange}
				invalidMessage={propInvalidMessage || (showValidationMessage ? invalidMessage : null)}
			/>
		);
	}, [
		renderAppEditView,
		displayValue,
		handleOnChange,
		invalidMessage,
		isEditable,
		placeholder,
		propInvalidMessage,
		showValidationMessage,
		type,
		value,
	]);

	const readView = useMemo(() => {
		if (customReadView) {
			return customReadView(value);
		}

		if (value == null || value === '') {
			return <Text color="color.text.subtlest">{noValueText}</Text>;
		}

		const lDisplayValue = formatReadValue(type, value);
		const lTestId = `issue.views.field.single-line-text-inline-edit.read-view.${fieldId}`;

		if (type === 'text') {
			return <div data-testid={lTestId}>{lDisplayValue}</div>;
		}

		return (
			<Ellipsis
				data-testid={lTestId}
				// @ts-expect-error - TS2322 - Type 'Element | NumberOrString' is not assignable to type 'string | undefined'.
				title={lDisplayValue}
			>
				{lDisplayValue}
			</Ellipsis>
		);
	}, [customReadView, fieldId, noValueText, type, value]);

	const readViewWithContentWrapper = useMemo(() => {
		if (FieldContentWrapper) {
			return (
				<FieldContentWrapper>
					<ReadViewContainer data-component-selector={readViewContainerSelectorName}>
						{readView}
					</ReadViewContainer>
				</FieldContentWrapper>
			);
		}
		return (
			<ReadViewContainer data-component-selector={readViewContainerSelectorName}>
				{readView}
			</ReadViewContainer>
		);
	}, [FieldContentWrapper, readView]);

	return (
		<FieldWrapper data-testid={testId}>
			<FieldHeading fieldId={fieldId}>
				<FieldHeadingTitle>{titleView}</FieldHeadingTitle>
				{issueKey !== undefined && fieldId !== undefined && (
					<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={label} />
				)}
				{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
			</FieldHeading>
			<SideBySideField isEditing={isEditing}>
				{/* @ts-expect-error - TS2322 - Type '{ children: Element; isEditing: boolean; isEditable: boolean; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { ...; }, any>, any, any>> & Readonly<...> & Readonly<...>'. */}
				<InlineEditContainer isEditing={isEditing} isEditable={isEditable}>
					<FieldInlineEditStateLess
						isLabelHidden
						label={label}
						readView={readViewWithContentWrapper}
						editView={editView}
						isEditing={isEditing}
						{...(renderAppEditView && fg('forge_cf_inline_edit_on_issue_view')
							? { hideActionButtons }
							: {})}
						isEditable={isEditable}
						onConfirm={handleOnConfirm}
						onCancel={handleOnCancel}
						onEdit={onEditRequest}
						onEscape={handleOnCancel}
						readViewFitContainerWidth={!isMobile}
						editButtonLabel={formatMessage(messages.editButtonLabel, {
							fieldName: label,
						})}
						confirmButtonLabel={formatMessage(messages.confirmButtonLabel, {
							fieldName: label,
						})}
						cancelButtonLabel={formatMessage(messages.cancelButtonLabel, {
							fieldName: label,
						})}
					/>
				</InlineEditContainer>
			</SideBySideField>
		</FieldWrapper>
	);
};

export default SingleLineTextInlineEditView;
