import type { Dispatch } from 'redux';
import flow from 'lodash/flow';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import withFieldValue from '@atlassian/jira-issue-view-common/src/component/with-field-value/view.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { attachmentPickerPopupOpenSuccess } from '@atlassian/jira-issue-view-store/src/actions/attachment-popup-actions.tsx';
import {
	childIssueQuickAddClicked,
	issueInEpicQuickAddClicked,
	subtaskQuickAddClicked,
} from '@atlassian/jira-issue-view-store/src/actions/child-panel-actions.tsx';
import { linkConfluencePageClicked } from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import {
	addContentPanelRequest,
	contentPanelSelected,
} from '@atlassian/jira-issue-view-store/src/actions/ecosystem-content-panel-actions.tsx';
import { fetchConfluenceAppLinks } from '@atlassian/jira-issue-view-store/src/actions/fetch-confluence-app-links-actions.tsx';
import { investigateIncidentClicked } from '@atlassian/jira-issue-view-store/src/actions/investigate-incident-actions.tsx';
import { addIssueLinkClick } from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import { showLinkIdeaInput } from '@atlassian/jira-issue-view-store/src/actions/link-idea-actions.tsx';
import { showInsertLoomLinkForm } from '@atlassian/jira-issue-view-store/src/actions/loom-actions.tsx';
import { showCreateWebLinkForm } from '@atlassian/jira-issue-view-store/src/actions/web-links-actions.tsx';
import {
	issueKeySelector,
	localeSelector,
	isAnonymousSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	canInstallAddonsSelector,
	ecosystemAllPanelsSelector,
} from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector.tsx';
import { quickAddItemsPermissionsSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-actions-group-permissions-selector.tsx';
import { SERVICEDESK_PRACTICES_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { QuickAddItemAllDispatchProps } from '../types.tsx';
import { QuickAddItems } from './view.tsx';

export const actionDispatchToProps = (dispatch: Dispatch<Action>) => ({
	onAddAttachmentClick: () => {
		dispatch(attachmentPickerPopupOpenSuccess());
	},
	onCreateSubtaskClick: () => {
		dispatch(subtaskQuickAddClicked());
	},
	onCreateIssueInEpicClick: () => {
		dispatch(issueInEpicQuickAddClicked());
	},
	onAddIssueLinkClick: () => {
		dispatch(addIssueLinkClick());
	},
	onChildCreateClick: () => {
		dispatch(childIssueQuickAddClicked());
	},
	onLinkConfluencePageClick: () => {
		dispatch(linkConfluencePageClicked());
	},
	onInvestigateIncidentClick: () => {
		dispatch(investigateIncidentClicked());
	},
	onAddWebLinkClick: () => {
		dispatch(showCreateWebLinkForm());
	},
	onLinkIdeaClick: () => {
		dispatch(showLinkIdeaInput());
	},
});

const QuickAddItemswithContainerWidth = withContainerWidth(QuickAddItems);

export default flow(
	withFieldValue({
		fieldKey: SERVICEDESK_PRACTICES_TYPE,
		propName: 'practices',
	}),
	connect(
		(state: State) => ({
			...quickAddItemsPermissionsSelector(state),
			locale: localeSelector(state),
			projectType: projectTypeSelector(state),
			issueKey: issueKeySelector(state),
			isAnonymousUser: isAnonymousSelector(state),
			...(ff('issue_view_quick_actions_revamp_l8mbx') || fg('quick_actions_menu_ga')
				? {
						availableAddons: ecosystemAllPanelsSelector(state),
						canInstallAddons: canInstallAddonsSelector(state),
					}
				: {}),
		}),
		(dispatch: Dispatch<Action>): QuickAddItemAllDispatchProps => ({
			...actionDispatchToProps(dispatch),
			onAddExtension: (extension: string) => {
				dispatch(addContentPanelRequest(extension));
			},
			onSelectContentPanel: (addonModuleKey: string) => {
				dispatch(contentPanelSelected(addonModuleKey));
			},
			fetchConfluenceAppLinks: () => {
				dispatch(fetchConfluenceAppLinks());
			},
			onInsertLoomLinkClick: () => {
				dispatch(showInsertLoomLinkForm());
			},
		}),
	),
)(QuickAddItemswithContainerWidth);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as getItemList } from './item-list';
