// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	triggerTooltipAddEpic: {
		id: 'issue.details.issue-parent-switcher-trigger-tooltip-add-epic',
		defaultMessage: '{issueTypeName} - Add {target}',
		description: 'Tooltip content for issue parent switcher trigger in team-managed projects',
	},
	triggerTooltipChangeEpic: {
		id: 'issue.details.issue-parent-switcher-trigger-tooltip-change-epic',
		defaultMessage: '{issueTypeName} - Change {target}',
		description: 'Tooltip content for issue parent switcher trigger in team-managed projects',
	},
	uneditableParent: {
		id: 'issue.details.issue-parent-switcher-uneditable-parent',
		defaultMessage: "{issueTypeName} - You can't edit the parent of this issue",
		description: 'Tooltip content for changing uneditable parent.',
	},
	triggerTooltipAddParent: {
		id: 'issue.details.issue-parent-switcher-trigger-tooltip-add-parent',
		defaultMessage: '{issueTypeName}Add parent',
		description: 'Tooltip content for issue parent switcher trigger in company-managed projects',
	},
	triggerTooltipChangeParent: {
		id: 'issue.details.issue-parent-switcher-trigger-tooltip-change-parent',
		defaultMessage: '{issueTypeName}Change parent',
		description: 'Tooltip content for issue parent switcher trigger in company-managed projects',
	},
	dropdownEmpty: {
		id: 'issue.details.issue-parent-switcher-dropdown-empty',
		defaultMessage: 'No {target} has been created in this project. Create your first ',
		description:
			'Message for issue parent switcher dropdown when no option is accessible. (The message ends with a space on purpose. There is a link-button with the text {target} after the space in UI which completes the sentence)',
	},
	noEntitlementSpork: {
		id: 'issue.details.issue-parent-switcher-no-entitlement-spork',
		defaultMessage:
			'You can only add or change the parent of issues at this hierarchy level in Jira Premium',
		description:
			'Message for when the user does not have the permission to set the Parent of an issue because they do not have Jira Premium entitlement',
	},
	dropdownTitleEpic: {
		id: 'issue.details.issue-parent-switcher-dropdown-title-epic',
		defaultMessage: 'Recent {target}',
		description: 'Title for issue parent switcher dropdown',
	},
	viewEpics: {
		id: 'issue.details.issue-parent-switcher-view-epics',
		defaultMessage: 'View all {target}',
		description: 'Dropdown item text for view parents option',
	},
	dropdownTitle: {
		id: 'issue.details.issue-parent-switcher-dropdown-title',
		defaultMessage: 'Recent parents',
		description: 'Title for issue parent switcher dropdown',
	},
	unlinkParent: {
		id: 'issue.details.issue-parent-switcher-unlink-parent',
		defaultMessage: 'Unlink parent',
		description: 'Dropdown item text for unlink parent option',
	},
	viewParents: {
		id: 'issue.details.issue-parent-switcher-view-parents',
		defaultMessage: 'View all parents',
		description: 'Dropdown item text for view parents option',
	},
});
