import { creators, OPERATOR_IN, print } from '@atlaskit/jql-ast';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

const quoteProjectKey = (projectKey: ProjectKey): string => `"${projectKey}"`;

export const getJQLForCollection = (projectsKeys: ProjectKey[], _filters: unknown): string => {
	if (!projectsKeys.length) {
		return '';
	}

	const jast = creators.jast(
		creators.query(
			creators.terminalClause(
				creators.field('project'),
				creators.operator(OPERATOR_IN),
				creators.listOperand(projectsKeys.map(quoteProjectKey).map(creators.byText.valueOperand)),
			),
		),
	);

	return print(jast);
};

const projectInRegex = /project\s+in\s+\((.*?)\)/;
const projectKeyRegex = /"(.*?)"/g;

export const extractProjectKeysFromJql = (jql: string) => {
	// Extract the part of the JQL related to `project in (...)`
	const projectInMatch = projectInRegex.exec(jql);

	const projectKeys: string[] = [];

	if (projectInMatch) {
		const projectInClause = projectInMatch[1];
		let match;

		// Extract all project keys inside the quotes within the `project in (...)` clause
		// eslint-disable-next-line no-cond-assign
		while ((match = projectKeyRegex.exec(projectInClause)) !== null) {
			projectKeys.push(match[1]);
		}
	}

	return projectKeys;
};
