import findKey from 'lodash/findKey';
import forEach from 'lodash/forEach';
import set from 'lodash/fp/set';
import isEmpty from 'lodash/isEmpty';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type {
	RemoteIssue,
	RemoteIssueMeta,
} from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { getMetaFromJiraSearchIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/util/index.tsx';
import type { IssueLinkType } from '@atlassian/jira-polaris-remote-issue/src/services/jira/get-issue/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { JPD_CONNECTION_ISSUE_LINK_TYPE } from '../../constants.tsx';
import { createGetConnectionFieldIssueIds } from '../../selectors/connection.tsx';
import { createGetFieldMapping } from '../../selectors/fields.tsx';
import {
	getLocalIssueIdForJiraIssueId,
	getLocalIssueIdToJiraId,
} from '../../selectors/issue-ids.tsx';
import {
	createGetIssueType,
	createGetKeySelector,
	createGetSummary,
	getConnectionProperties,
} from '../../selectors/properties/index.tsx';
import {
	createGetLinkedIssueDataByIssueId,
	getExternalIssueData,
	getExternalIssueDataMapByJiraId,
	getIssueMetadataProperties,
} from '../../selectors/properties/linked-issues/index.tsx';
import type {
	ExternalIssueData,
	ExternalIssueDataMap,
	PropertyMaps,
	Props,
	State,
} from '../../types.tsx';
import { isMatchingConnectionFieldFilter } from '../../utils/connection-field-filters.tsx';
import type { FieldMapping } from '../../utils/field-mapping/types.tsx';
import { generateLocalExternalIssueId } from '../../utils/local-id.tsx';
import { findConnectionIssueLink } from './utils.tsx';

const setIssuesLinkData =
	(issues: RemoteIssue[]): Action<State, Props> =>
	({ getState, setState }, props) => {
		const externalIssueData = getExternalIssueData(getState());
		const newIssuesMetadata: Record<string, RemoteIssueMeta> = {};
		const newExternalIssueData: Record<string, ExternalIssueData> = {};

		issues.forEach((issue) => {
			const localId = getLocalIssueIdForJiraIssueId(issue.id)(getState(), props);
			const issueId = parseInt(issue.id, 10);

			if (localId) {
				newIssuesMetadata[localId] = getMetaFromJiraSearchIssue(
					issue,
					props.polarisIssueLinkType,
					props.hiddenIssueLinkTypes,
				);
			}

			const externalIssueLocalId = findKey(
				externalIssueData,
				(issueData) => issueData.issueId === issueId,
			);

			newExternalIssueData[externalIssueLocalId ?? generateLocalExternalIssueId()] = {
				issueId,
				issueKey: issue.key,
				projectId: issue.fields.project.id,
				summary: issue.fields.summary,
				status: issue.fields.status,
				issueType: issue.fields.issuetype,
				priority: issue.fields.priority,
				childIssues: [],
				isDeliveryIssue: false,
				issueLinkId: '',
			};
		});

		setState({
			externalIssueData: {
				...getState().externalIssueData,
				...newExternalIssueData,
			},
			properties: {
				...getState().properties,
				issueMetadata: {
					...getState().properties.issueMetadata,
					...newIssuesMetadata,
				},
			},
		});
	};

const getNewConnections =
	({
		issuesToConnect = [],
		issuesToDisconnect = [],
		localIssueId,
	}: {
		localIssueId: LocalIssueId;
		issuesToConnect?: ConnectionFieldValue[];
		issuesToDisconnect?: ConnectionFieldValue[];
	}): Action<State, Props, PropertyMaps> =>
	({ getState }, props) => {
		const state = getState();
		const localIssueIdToJiraId = getLocalIssueIdToJiraId(state, props);
		const connectionProperties = getConnectionProperties(state);
		let newProperties = state.properties;

		const update = (
			id1: LocalIssueId,
			id2: string, // Jira issue id
			fieldKey: FieldKey,
			action: 'link' | 'unlink',
		) => {
			const mapping: FieldMapping<ConnectionFieldValue[]> | undefined = createGetFieldMapping(
				fieldKey,
			)(state, props);

			if (!mapping) return;

			const externalIssueData = createGetLinkedIssueDataByIssueId(parseInt(id2, 10))(state);

			if (
				!isMatchingConnectionFieldFilter({
					localIssueId: getLocalIssueIdForJiraIssueId(id2)(state, props),
					fieldKey,
					state,
					props,
					issueType: externalIssueData?.issueType,
				})
			) {
				return;
			}

			newProperties = mapping.modifyImmutableIfMultiValueField(
				newProperties,
				id1,
				action === 'link' ? [{ id: id2 }] : [],
				action === 'unlink' ? [{ id: id2 }] : [],
			);
		};

		const iterate = (id: string, key: string, action: 'link' | 'unlink') => {
			const localIssueId2 = getLocalIssueIdForJiraIssueId(id)(state, props);

			update(localIssueId, id, key, action);

			if (localIssueId2) {
				update(localIssueId2, localIssueIdToJiraId[localIssueId], key, action);
			}
		};

		forEach(connectionProperties, (_, key) => {
			issuesToConnect.forEach(({ id }) => iterate(id, key, 'link'));
			issuesToDisconnect.forEach(({ id }) => iterate(id, key, 'unlink'));
		});

		return newProperties;
	};

type ConnectIssue = {
	id: string;
	issueKey: string;
	summary: string;
	issueType?: IssueTypeFieldValue;
};

const getNewExternalIssueData =
	(
		localIssueId: LocalIssueId,
		issues: ConnectIssue[],
	): Action<State, Props, ExternalIssueDataMap> =>
	({ getState }, props) => {
		const state = getState();
		const localIssueIdToJiraId = getLocalIssueIdToJiraId(state, props);
		const externalIssueDataMap = getExternalIssueDataMapByJiraId(state);
		const optimisticExternalIssueData: Record<string, ExternalIssueData> = {};
		const allIssues = [
			{
				id: localIssueIdToJiraId[localIssueId],
				issueKey: createGetKeySelector(localIssueId)(state),
				issueType: createGetIssueType(localIssueId)(state, props),
				summary: createGetSummary(localIssueId)(state, props),
				isDeliveryIssue: false,
			},
			...issues,
		];

		allIssues.forEach(({ id, issueKey, summary, issueType }) => {
			if (!externalIssueDataMap[id]) {
				// Not ideal to cast type but for connections it's enough to have only these fields,
				// might be improved when we'll know the shape of cross-project issues
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				optimisticExternalIssueData[generateLocalExternalIssueId()] = {
					issueId: parseInt(id, 10),
					issueKey,
					issueType,
					summary,
					isDeliveryIssue: false,
				} as ExternalIssueData;
			}
		});

		if (isEmpty(optimisticExternalIssueData)) {
			return state.externalIssueData;
		}

		return {
			...state.externalIssueData,
			...optimisticExternalIssueData,
		};
	};

const getNewIssueLinks =
	({
		issueLinkType,
		localIssueId,
		issuesToConnect = [],
		issuesToDisconnect = [],
		properties,
	}: {
		localIssueId: LocalIssueId;
		issuesToConnect?: ConnectIssue[];
		issuesToDisconnect?: ConnectionFieldValue[];
		issueLinkType: IssueLinkType;
		properties: PropertyMaps;
	}): Action<State, Props, PropertyMaps> =>
	({ getState }, props) => {
		const state = getState();
		const localIssueIdToJiraId = getLocalIssueIdToJiraId(state, props);
		let newIssueMetadataMap: Record<string, RemoteIssueMeta> = getIssueMetadataProperties(state);

		issuesToConnect.forEach(({ id, issueKey, summary, issueType }) => {
			const issue1 = {
				id: parseInt(localIssueIdToJiraId[localIssueId], 10),
				isArchived: false,
				issueTypeId: issueType?.id || '',
				key: createGetKeySelector(localIssueId)(state),
				summary: createGetSummary(localIssueId)(state, props),
				statusId: '',
				priority: {},
			};
			const issue2 = {
				id: parseInt(id, 10),
				isArchived: false,
				key: issueKey,
				summary,
				issueTypeId: issueType?.id || '',
				statusId: '',
				priority: {},
			};

			const update = (
				localId: LocalIssueId,
				outwardIssue: typeof issue1,
				inwardIssue: typeof issue2,
			) => {
				newIssueMetadataMap = set(
					[localId, 'issueLinks', 'links'],
					[
						...(newIssueMetadataMap[localId]?.issueLinks?.links ?? []),
						{
							id: parseInt(localIssueIdToJiraId[localIssueId], 10),
							outwardIssue,
							inwardIssue,
							typeName: issueLinkType.name,
							typeId: issueLinkType.id,
							typeInward: issueLinkType.inward,
							typeOutward: issueLinkType.outward,
						},
					],
					newIssueMetadataMap,
				);
			};

			update(localIssueId, issue1, issue2);

			const localIssueId2 = getLocalIssueIdForJiraIssueId(id)(state, props);

			if (localIssueId2) {
				update(localIssueId2, issue2, issue1);
			}
		});

		issuesToDisconnect.forEach(({ id }) => {
			const update = (localId: LocalIssueId) => {
				const issueLinks = newIssueMetadataMap[localId]?.issueLinks?.links || [];
				newIssueMetadataMap[localId] = {
					...newIssueMetadataMap[localId],
					issueLinks: {
						...newIssueMetadataMap[localId]?.issueLinks,
						links: issueLinks.filter((issueLink) => !findConnectionIssueLink(id, issueLink)),
					},
				};
			};

			update(localIssueId);

			const localIssueId2 = getLocalIssueIdForJiraIssueId(id)(state, props);

			if (localIssueId2) {
				update(localIssueId2);
			}
		});

		return {
			...properties,
			issueMetadata: newIssueMetadataMap,
		};
	};

export const updateIssueConnections =
	({
		localIssueId,
		issuesToConnect = [],
		issuesToDisconnect = [],
	}: {
		localIssueId: LocalIssueId;
		issuesToConnect?: ConnectIssue[];
		issuesToDisconnect?: ConnectIssue[];
	}): Action<State, Props> =>
	async ({ setState, getState, dispatch }, props) => {
		if (!issuesToConnect.length && !issuesToDisconnect.length) {
			return;
		}

		const { issuesRemote, issueLinkTypes, onIssueUpdateFailed } = props;
		const issueLinkType = issueLinkTypes?.find(
			(linkType) => linkType.name === JPD_CONNECTION_ISSUE_LINK_TYPE,
		);

		if (!issueLinkType) {
			return;
		}

		const localIssueIdToJiraId = getLocalIssueIdToJiraId(getState(), props);
		const issueMetadata = getIssueMetadataProperties(getState());
		const issueLinks = issueMetadata[localIssueId]?.issueLinks?.links || [];
		const failedUnlinkIssues: ConnectIssue[] = [];
		const failedLinkIssues: ConnectIssue[] = [];

		// optimistic update
		const newExternalIssueData = dispatch(getNewExternalIssueData(localIssueId, issuesToConnect));
		let newProperties = dispatch(
			getNewConnections({ issuesToDisconnect, issuesToConnect, localIssueId }),
		);
		newProperties = dispatch(
			getNewIssueLinks({
				localIssueId,
				issuesToConnect,
				issuesToDisconnect,
				properties: newProperties,
				issueLinkType,
			}),
		);

		setState({
			externalIssueData: newExternalIssueData,
			properties: newProperties,
		});

		// bulk unlinking
		const unlinkPromises = issuesToDisconnect
			.map((issue) => {
				const link = issueLinks.find((issueLink) => findConnectionIssueLink(issue.id, issueLink));

				return link
					? issuesRemote
							.deleteIssueLink({
								issueLinkId: String(link.id),
							})
							.then(() => issue.id)
							.catch(() => failedUnlinkIssues.push(issue))
					: null;
			})
			.filter(Boolean);

		// bulk linking
		const linkPromises = issuesToConnect.map((issue) =>
			issuesRemote
				.createIssueLink({
					issueLinkTypeId: issueLinkType.id,
					outwardIssueKey: createGetKeySelector(localIssueId)(getState()),
					inwardIssueKey: issue.issueKey,
				})
				.then(() => issue.id)
				.catch(() => failedLinkIssues.push(issue)),
		);

		const promiseResults = await Promise.allSettled([...linkPromises, ...unlinkPromises]);

		if (failedUnlinkIssues.length || failedLinkIssues.length) {
			// revert optimistic update
			let revertedProperties = dispatch(
				getNewConnections({
					issuesToDisconnect: failedLinkIssues,
					issuesToConnect: failedUnlinkIssues,
					localIssueId,
				}),
			);
			revertedProperties = dispatch(
				getNewIssueLinks({
					localIssueId,
					issuesToConnect: failedUnlinkIssues,
					issuesToDisconnect: failedLinkIssues,
					properties: revertedProperties,
					issueLinkType,
				}),
			);

			setState({
				properties: revertedProperties,
			});

			// TODO: consider improving error handling https://pi-dev-sandbox.atlassian.net/browse/POL-12341
			onIssueUpdateFailed(new Error());
		}

		const successPromises = promiseResults.filter(
			(p): p is PromiseFulfilledResult<string> =>
				p.status === 'fulfilled' && typeof p.value === 'string',
		);

		if (!successPromises.length) {
			return;
		}

		// we need to refetch newly linked issues so we can use linkId for unlinking later
		const response = await issuesRemote.fetch({
			issueIdsOrKeys: [
				localIssueIdToJiraId[localIssueId],
				...successPromises.map(({ value }) => localIssueIdToJiraId[value]),
			],
		});

		dispatch(setIssuesLinkData(response.issues));
	};

export const connectIssuesByIds =
	(localIssueId: string, issues: ConnectionFieldValue[]): Action<State, Props> =>
	({ getState, dispatch }) => {
		const issuesToConnect = issues
			.map(({ id }) => {
				const issueData = createGetLinkedIssueDataByIssueId(parseInt(id, 10))(getState());

				if (!issueData) {
					return null;
				}

				return {
					id: String(issueData?.issueId),
					issueKey: issueData.issueKey,
					summary: issueData.summary,
					issueType: issueData.issueType,
				};
			})
			.filter(Boolean);

		dispatch(updateIssueConnections({ localIssueId, issuesToConnect }));
	};

export const disconnectAllIssuesByConnectionField =
	(localIssueId: string, fieldKey: FieldKey): Action<State, Props> =>
	({ getState, dispatch }) => {
		const state = getState();
		const currentConnections = createGetConnectionFieldIssueIds(localIssueId, fieldKey)(state);

		if (!currentConnections.length) {
			return;
		}

		const issuesToDisconnect = currentConnections
			.map(({ id }) => {
				const issueData = createGetLinkedIssueDataByIssueId(parseInt(id, 10))(state);

				if (!issueData) {
					return null;
				}

				return {
					id,
					issueKey: issueData.issueKey,
					summary: issueData.summary,
					issueType: issueData.issueType,
				};
			})
			.filter(Boolean);

		dispatch(updateIssueConnections({ localIssueId, issuesToDisconnect }));
	};
