import type { StoreActionApi } from '@atlassian/react-sweet-state';

export const SCROLL_TARGET_TYPES = Object.freeze({
	COMMENT: 'COMMENT',
	WORKLOG: 'WORKLOG',
});

export type ScrollTargetType = (typeof SCROLL_TARGET_TYPES)[keyof typeof SCROLL_TARGET_TYPES];

export type ScrollTarget = {
	type: ScrollTargetType;
	id: string;
} | null;

export type IssueScrollData = {
	scrollTarget: ScrollTarget;
};

export type State = IssueScrollData;

export type StoreApi = StoreActionApi<State>;

export type IssueScrollActions = {
	getScrollTargetId: (targetType: ScrollTargetType) => string | null;
	setScrollTarget: (scrollTarget: ScrollTarget) => void;
};
