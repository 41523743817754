import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

export const readViewContainerSelectorName =
	'jira-issue-view-radio-inline-edit-view-read-view-container';
export const READ_VIEW_CONTAINER_COMPONENT_SELECTOR = `[data-component-selector="${readViewContainerSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadViewContainer = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	font: token('font.body'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineEditContainer = styled.div({
	width: '100%',
	marginLeft: token('space.negative.100', '-8px'),
	marginTop: token('space.negative.100', '-8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-read-view-fit-container-width]': {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		minHeight: '32px',
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: token('space.075', '6px'),
		paddingRight: 0,
	},
});
