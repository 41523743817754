/**
 * @generated SignedSource<<8a3186cb842bc01ab5c9dc28a31808ea>>
 * @relayHash f546d8dd94dbb2ad5cc44b05f73fa79b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c384a21d8f996e9bb3f6b294df4b6b3fb614dbd1a002da76e4d38d6eab46da39

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DevOpsDesignStatus = "NONE" | "READY_FOR_DEVELOPMENT" | "UNKNOWN" | "%future added value";
export type DevOpsDesignType = "CANVAS" | "FILE" | "GROUP" | "NODE" | "OTHER" | "PROTOTYPE" | "%future added value";
export type ToolchainAssociateEntitiesErrorCode = "ENTITY_REJECTED" | "ENTITY_URL_INVALID" | "PROVIDER_ENTITY_FETCH_FORBIDDEN" | "PROVIDER_ENTITY_NOT_FOUND" | "PROVIDER_ERROR" | "PROVIDER_INPUT_INVALID" | "%future added value";
export type ToolchainProviderType = "BUILD" | "DEPLOYMENT" | "DESIGN" | "DEVOPS_COMPONENTS" | "DEV_INFO" | "DOCUMENTATION" | "FEATURE_FLAG" | "OPERATIONS" | "REMOTE_LINKS" | "SECURITY" | "%future added value";
export type ToolchainAssociateEntitiesInput = {
  associations: ReadonlyArray<ToolchainAssociateEntityInput>;
  cloudId: string;
  providerId: string;
  providerType?: ToolchainProviderType | null | undefined;
};
export type ToolchainAssociateEntityInput = {
  fromId: string;
  toEntityUrl: AGG$URL;
};
export type designForm_associateDesignEntityMutation$variables = {
  connections: ReadonlyArray<string>;
  input: ToolchainAssociateEntitiesInput;
  siteAri: string;
};
export type designForm_associateDesignEntityMutation$data = {
  readonly devOps: {
    readonly toolchain: {
      readonly associateEntities: {
        readonly associatedEntities: ReadonlyArray<{
          readonly __id?: string;
          readonly displayName?: string | null | undefined;
          readonly id?: string;
          readonly inspectUrl?: AGG$URL | null | undefined;
          readonly lastUpdated?: AGG$DateTime | null | undefined;
          readonly liveEmbedUrl?: AGG$URL | null | undefined;
          readonly provider?: {
            readonly id: string;
            readonly logoUrl: AGG$URL | null | undefined;
            readonly name: string | null | undefined;
          } | null | undefined;
          readonly providerId?: string | null | undefined;
          readonly status?: DevOpsDesignStatus | null | undefined;
          readonly type?: DevOpsDesignType | null | undefined;
          readonly url?: AGG$URL | null | undefined;
        }> | null | undefined;
        readonly errors: ReadonlyArray<{
          readonly extensions: {
            readonly __typename: string;
            readonly errorCode?: ToolchainAssociateEntitiesErrorCode | null | undefined;
            readonly statusCode: number | null | undefined;
          } | null | undefined;
          readonly message: string | null | undefined;
        }> | null | undefined;
        readonly fromEntities: ReadonlyArray<{
          readonly attachedDesignUrlV1: AGG$JSON | null | undefined;
          readonly attachedDesignUrlV2: AGG$JSON | null | undefined;
          readonly id: string;
        } | null | undefined> | null | undefined;
        readonly success: boolean;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type designForm_associateDesignEntityMutation = {
  response: designForm_associateDesignEntityMutation$data;
  variables: designForm_associateDesignEntityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteAri"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "success"
},
v5 = {
  "kind": "ScalarField",
  "name": "message"
},
v6 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "errorCode"
    }
  ],
  "type": "ToolchainAssociateEntitiesErrorExtension"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v11 = {
  "kind": "ScalarField",
  "name": "lastUpdated"
},
v12 = {
  "kind": "ScalarField",
  "name": "providerId"
},
v13 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "siteAri"
  }
],
v14 = {
  "kind": "ScalarField",
  "name": "name"
},
v15 = {
  "kind": "ScalarField",
  "name": "logoUrl"
},
v16 = {
  "kind": "ScalarField",
  "name": "url"
},
v17 = {
  "kind": "ScalarField",
  "name": "liveEmbedUrl"
},
v18 = {
  "kind": "ScalarField",
  "name": "inspectUrl"
},
v19 = {
  "kind": "ScalarField",
  "name": "status"
},
v20 = {
  "kind": "ScalarField",
  "name": "type"
},
v21 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v22 = {
  "concreteType": "JiraIssue",
  "kind": "LinkedField",
  "name": "fromEntities",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": "attachedDesignUrlV1",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "attached-design-url"
        }
      ],
      "kind": "ScalarField",
      "name": "issuePropertyByKey",
      "storageKey": "issuePropertyByKey(key:\"attached-design-url\")"
    },
    {
      "alias": "attachedDesignUrlV2",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "attached-design-url-v2"
        }
      ],
      "kind": "ScalarField",
      "name": "issuePropertyByKey",
      "storageKey": "issuePropertyByKey(key:\"attached-design-url-v2\")"
    }
  ]
},
v23 = [
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "designForm_associateDesignEntityMutation",
    "selections": [
      {
        "concreteType": "DevOpsMutation",
        "kind": "LinkedField",
        "name": "devOps",
        "plural": false,
        "selections": [
          {
            "concreteType": "ToolchainMutation",
            "kind": "LinkedField",
            "name": "toolchain",
            "plural": false,
            "selections": [
              {
                "args": (v3/*: any*/),
                "concreteType": "ToolchainAssociateEntitiesPayload",
                "kind": "LinkedField",
                "name": "associateEntities",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "concreteType": "MutationError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "associatedEntities",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "args": (v13/*: any*/),
                            "kind": "LinkedField",
                            "name": "provider",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/)
                            ]
                          },
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/)
                        ],
                        "type": "DevOpsDesign"
                      }
                    ]
                  },
                  (v22/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "designForm_associateDesignEntityMutation",
    "selections": [
      {
        "concreteType": "DevOpsMutation",
        "kind": "LinkedField",
        "name": "devOps",
        "plural": false,
        "selections": [
          {
            "concreteType": "ToolchainMutation",
            "kind": "LinkedField",
            "name": "toolchain",
            "plural": false,
            "selections": [
              {
                "args": (v3/*: any*/),
                "concreteType": "ToolchainAssociateEntitiesPayload",
                "kind": "LinkedField",
                "name": "associateEntities",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "concreteType": "MutationError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v23/*: any*/),
                            "type": "BulkMutationErrorExtension"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "associatedEntities",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "args": (v13/*: any*/),
                            "kind": "LinkedField",
                            "name": "provider",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v9/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/)
                            ]
                          },
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/)
                        ],
                        "type": "DevOpsDesign"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v23/*: any*/),
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ]
                  },
                  {
                    "handle": "appendNode",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "associatedEntities",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      },
                      {
                        "kind": "Literal",
                        "name": "edgeTypeName",
                        "value": "GraphStoreSimplifiedIssueAssociatedDesignConnection"
                      }
                    ]
                  },
                  (v22/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "c384a21d8f996e9bb3f6b294df4b6b3fb614dbd1a002da76e4d38d6eab46da39",
    "metadata": {},
    "name": "designForm_associateDesignEntityMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "6a01f6414eb34a98158114f84f858bd2";

export default node;
