import { pluginFactory } from '@atlaskit/editor-common/utils';
import type {
	EditorState,
	ReadonlyTransaction,
	Transaction,
} from '@atlaskit/editor-prosemirror/state';

import { rovoAgentsPluginKey } from './plugin-key';
import { reducer } from './reducer';
import type { RovoAgentsPluginState } from './states';

export const handleDocOrSelectionChange = (
	tr: Transaction | ReadonlyTransaction,
	pluginState: RovoAgentsPluginState,
) => {
	const { doc, selection } = tr;
	pluginState.publishDocAndSelection({ doc, selection });
	return pluginState;
};

export const { createPluginState, createCommand } = pluginFactory(rovoAgentsPluginKey, reducer, {
	onDocChanged: handleDocOrSelectionChange,
	onSelectionChanged: handleDocOrSelectionChange,
});

export const getRovoAgentsPluginState = (state: EditorState): RovoAgentsPluginState | undefined => {
	const pluginState = rovoAgentsPluginKey.getState(state);
	return pluginState;
};
