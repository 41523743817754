/**
 * @generated SignedSource<<9c9efeb1487baffbef8ad90c15cb6aed>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueDesignSection_DesignPanelWrapper_rootRelayFragment$data = {
  readonly devOps: {
    readonly providers: {
      readonly designProviders: ReadonlyArray<{
        readonly supportedActions: {
          readonly checkAuth: boolean | null | undefined;
          readonly getEntityByUrl: boolean | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"panel_issueDesignSection_DesignPanel_rootRelayFragment">;
  readonly " $fragmentType": "ui_issueDesignSection_DesignPanelWrapper_rootRelayFragment";
};
export type ui_issueDesignSection_DesignPanelWrapper_rootRelayFragment$key = {
  readonly " $data"?: ui_issueDesignSection_DesignPanelWrapper_rootRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueDesignSection_DesignPanelWrapper_rootRelayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "siteAri"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueDesignSection_DesignPanelWrapper_rootRelayFragment",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "panel_issueDesignSection_DesignPanel_rootRelayFragment"
    },
    {
      "concreteType": "DevOps",
      "kind": "LinkedField",
      "name": "devOps",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "siteAri"
            },
            {
              "kind": "Literal",
              "name": "providerTypes",
              "value": [
                "DESIGN"
              ]
            }
          ],
          "concreteType": "DevOpsProviders",
          "kind": "LinkedField",
          "name": "providers",
          "plural": false,
          "selections": [
            {
              "concreteType": "DevOpsDesignProvider",
              "kind": "LinkedField",
              "name": "designProviders",
              "plural": true,
              "selections": [
                {
                  "concreteType": "DevOpsSupportedActions",
                  "kind": "LinkedField",
                  "name": "supportedActions",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "checkAuth"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "getEntityByUrl"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "dfe12affb82626df6d3f31fe3f91dca8";

export default node;
