import { useMemo } from 'react';
import { LICENSED_PRODUCTS } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import type { UseLoomJSMAgentBannerProps } from './types.tsx';

/**
 * Eligibility criteria for showing the Loom JSM Agent Banner:
 * - Tenant does not have loom
 * - Issue is a JSM issue
 * - Issue does not have resolution (e.g. Done, Won't Do)
 * - Issue has at least one comment or was created more than 24 hours ago
 * - Issue is customer facing (isEditingInternal is false)
 */
export const useLoomJSMAgentBanner = ({
	totalComments,
	isEditingInternal,
	isServiceDesk,
	createdDate,
	resolution,
}: UseLoomJSMAgentBannerProps): boolean => {
	const licensedProducts = useLicensedProducts();
	const tenantHasLoomTCS = Boolean(licensedProducts[LICENSED_PRODUCTS.LOOM]);
	const isSupportedJSMIssue = useMemo(() => {
		return (
			!!createdDate &&
			(totalComments > 0 ||
				new Date().getTime() - new Date(createdDate).getTime() >= 1000 * 60 * 60 * 24) &&
			!resolution &&
			isServiceDesk
		);
	}, [createdDate, isServiceDesk, resolution, totalComments]);

	return !isEditingInternal && !tenantHasLoomTCS && isSupportedJSMIssue;
};
