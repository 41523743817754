import React, { useCallback, type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { TASK_FAIL } from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	useApplication,
	useEdition,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';

type IssueViewLayoutFieldWrapperProps = {
	children: ReactNode;
	fieldType: string;
	packageName: string;
};
export const IssueViewLayoutSafeFieldWrapper = ({
	fieldType,
	packageName,
	children,
}: IssueViewLayoutFieldWrapperProps) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	const onError = useCallback(
		(location: string, error: Error) =>
			sendExperienceAnalytics({
				experience: 'issueViewRelayFieldRender',
				analyticsSource: 'issueViewRelayFieldView',
				action: TASK_FAIL,
				wasExperienceSuccesful: false,
				application: application ?? null,
				edition: edition ?? null,
				additionalAttributes: {
					location,
					errorMessage: error.message,
					fieldType,
				},
			}),
		[application, edition, fieldType],
	);

	return (
		<JSErrorBoundary
			id="issue-view-layout-field-wrapper"
			packageName={packageName}
			teamName="issue-view-nike"
			fallback="unmount"
			attributes={{ fieldType }}
			onError={onError}
		>
			<Placeholder name="issue-view-layout-field-wrapper" fallback="unmount">
				{children}
			</Placeholder>
		</JSErrorBoundary>
	);
};
