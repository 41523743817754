// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	summaryLabel: {
		id: 'issue.views.common.child-issues-panel.inline-create.summary-label',
		defaultMessage: 'Child summary',
		description: 'Label for child summary input field',
	},
	summaryPlaceholder: {
		id: 'issue.views.common.child-issues-panel.inline-create.summary-placeholder',
		defaultMessage: 'What needs to be done?',
		description: 'Placeholder text to prompt users to enter the summary of the issue to be created',
	},
	summaryTextFieldLabel: {
		id: 'issue.views.common.child-issues-panel.inline-create.summary-text-field-label',
		defaultMessage: 'What needs to be done?',
		description: 'Label of textfield to enter the summary of the issue to be created',
	},
	summaryTooLong: {
		id: 'issue.views.common.child-issues-panel.inline-create.summary-too-long',
		defaultMessage: 'Summary must be less than 255 characters.',
		description: 'Inline error text',
	},
	childCreateButton: {
		id: 'issue.views.common.child-issues-panel.inline-create.child-create-button',
		defaultMessage: 'Create',
		description: 'Button label to create child issue',
	},
	childCancelButton: {
		id: 'issue.views.common.child-issues-panel.inline-create.child-cancel-button',
		defaultMessage: 'Cancel',
		description: 'Button label to cancel creation of child issue',
	},
	addExistingIssuePlaceholder: {
		id: 'issue.views.common.child-issues-panel.inline-create.add-existing-issue-placeholder',
		defaultMessage: 'Choose an existing issue',
		description:
			'Placeholder text to prompt users to search for an existing issue to be added as a sub-task',
	},
	cannotCreateChildIssue: {
		id: 'issue.views.common.child-issues-panel.inline-create.cannot-create-child-issue',
		defaultMessage: "We can't create child issues. This project only allows you to create epics.",
		description: 'Error message when user cannot create a child issue',
	},
});
