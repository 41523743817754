import head from 'lodash/head';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const renameOption =
	(fieldKey: FieldKey, jiraOptionId: string, newValue: string) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, projectId, createAnalyticsEvent, fieldRemote, ideaTypes }: Props,
	) => {
		const state = getState();
		const previousOptions = state.fields[fieldKey].options;

		const newOptions = previousOptions?.map((option) => {
			if (option.jiraOptionId === jiraOptionId) {
				return {
					...option,
					value: newValue,
				};
			}
			return option;
		});

		const fields = {
			...state.fields,
			[fieldKey]: {
				...state.fields[fieldKey],
				options: newOptions,
			},
		};

		setState({ fields });

		const issueTypeId = head(ideaTypes)?.jiraIssueTypeId;

		if (projectId !== undefined && issueTypeId !== undefined) {
			fieldRemote
				.renameFieldOption({
					fieldKey,
					newTitle: newValue,
					optionId: +jiraOptionId,
					issueTypeId,
				})
				.then(() => {
					fireCompoundAnalyticsEvent.FieldConfig.optionLabelChanged(createAnalyticsEvent({}), {
						issueFieldKey: fieldKey,
						issueFieldType: fields[fieldKey].type,
						fieldValueId: jiraOptionId,
					});
				})
				.catch(onFieldUpdateFailed);
		}
	};
