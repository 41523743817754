import React from 'react';
import noop from 'lodash/noop';
import FlagIcon from '@atlaskit/icon/core/flag';
import FlagFilledIcon from '@atlaskit/icon/glyph/flag-filled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import AltStatusField from '../alt-status-field/view.tsx';
import { messages } from './messages.tsx';

type Props = {
	externalId?: string;
	onClick?: () => void;
};

const FlaggedField = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();
	const flaggedMsg = formatMessage(messages.flagged);

	return (
		<AltStatusField
			onClick={onClick}
			name={flaggedMsg}
			icon={
				<FlagIcon
					spacing="spacious"
					LEGACY_fallbackIcon={FlagFilledIcon}
					LEGACY_size="medium"
					LEGACY_primaryColor={token('color.icon.danger', colors.R300)}
					color={token('color.icon.danger', colors.R300)}
					label={fg('deployment_issue_sideview_icon_a11y') ? '' : flaggedMsg}
				/>
			}
		/>
	);
};

FlaggedField.defaultProps = {
	onClick: noop,
};

export default FlaggedField;
