import { defineMessages } from 'react-intl-next';

export default defineMessages({
	recommendationPreviewButtonLabel: {
		id: 'fabric.editor.ai.proactive.recommendation.preview.button.non-final',
		defaultMessage: 'Preview',
		description:
			'The preview button label which allows the user to open the preview modal and see the AI recommendation which will be applied when the user inserts',
	},
	recommendationReplaceButtonLabel: {
		id: 'fabric.editor.ai.proactive.recommendation.replace.button.non-final',
		defaultMessage: 'Replace',
		description:
			'The replace button label, when clicked the AI recommendation which immediately be inserted',
	},
	recommendationDismissButtonLabel: {
		id: 'fabric.editor.ai.proactive.recommendation.dismiss.button.non-final',
		defaultMessage: 'Dismiss',
		description:
			'The dismiss button label,when clicked the AI recommendation will be removed from the list and no longer displayed to the user',
	},
	spellingAndGrammarLabel: {
		id: 'fabric.editor.ai.proactive.spellingAndGrammar.label.non-final',
		defaultMessage: 'Spelling and grammar',
		description:
			'The label displayed in the context panel on the spelling and grammar item which display the total spelling and grammar issues found',
	},
	suggestedEditsTitle: {
		id: 'fabric.editor.ai.proactive.suggestedEdits.title.non-final',
		defaultMessage: 'Suggested edits',
		description:
			'The title displayed at the top of the context panel for all recommendations displayed',
	},
	moreActionsLabel: {
		id: 'fabric.editor.ai.proactive.moreActions.label.non-final',
		defaultMessage: 'More actions',
		description: 'This is the text applied to the More icon button label',
	},
	closeButtonLabel: {
		id: 'fabric.editor.ai.proactive.close.label.non-final',
		defaultMessage: 'Close',
		description: 'This is the text applied to the Close icon button label',
	},
	betaLabel: {
		id: 'fabric.editor.ai.proactive.beta.label.non-final',
		defaultMessage: 'BETA',
		description:
			'This is a lozenge displayed in the suggested edits header to signify that this is a beta feature',
	},
	emptyStateTitle: {
		id: 'fabric.editor.ai.proactive.emptyState.title.non-final',
		defaultMessage: 'Your page looks great!',
		description:
			'This is the title of the message displayed in the suggested edits panel when there are no recommendations',
	},
	emptyStateDescription: {
		id: 'fabric.editor.ai.proactive.emptyState.description.non-final',
		defaultMessage: 'Edit your page and find suggested improvements here.',
		description:
			'This is the message displayed in the suggested edits panel when there are no recommendations',
	},
});
