// eslint-disable-next-line jira/restricted/react-component-props
import React, { Component, type ReactElement, type ComponentProps } from 'react';
import { styled } from '@compiled/react';
import omit from 'lodash/omit';

import { SimpleTag as Tag, type TagColor } from '@atlaskit/tag';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { MAP_COLORS_TO_VALUE } from '@atlassian/jira-issue-epic-color/src/common/constants.tsx';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import type { FormatOptionLabelMeta } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/index.tsx';
import type {
	ServerSuggestions,
	Option as OptionType,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import SingleSelectInlineEdit, {
	type Props as SingleSelectInlineEditProps,
} from '@atlassian/jira-issue-internal-field-select/src/single-select-inline-edit/index.tsx';
import { DisabledInlineReadView } from './disabled-read-view/index.tsx';
import Menu from './menu.tsx';
import Option from './option.tsx';

/**
 * The epic read view uses the AK Tag component. Tag expects a string value passed as the color
 * prop, which is internally mapped to an ADG color. The suggestions elemBefore node needs to
 * use the same ADG color so that there isn't inconsistency between the epic read and edit mode.

 * If any component besides the Tag is used for the elemBefore node, there would have to be a
 * mapping of the Tag string color to the ADG color it uses internally. This mapping could then
 * become out of sync if the internals of AK Tag changed.

 * To workaround this the suggestions elemBefore node is an empty Tag, which uses the same string
 * color as the read view. This way the ADG color mappings is handled by AK. Unfortunately there is
 * no way to control the Tag size so the Tags elemBefore is a div the width the Tag should be.
 */
export const getOptionColorElem = (
	tagColor: TagColor,
): ReactElement<ComponentProps<typeof Tag>> => (
	<Tag color={tagColor} elemBefore={<OptionColorSizer />} text="" />
);

export const getOptionColorElemPatch = (
	color: Color,
): ReactElement<ComponentProps<typeof ColorPatch>> => <ColorPatch color={color} />;

export const selectedSearchRegexValues = (query: string, content: string) => {
	const SPECIAL_CHARS_REGEX = /[.*+?^${}()|[\]\\]/g;
	const boundary = SPECIAL_CHARS_REGEX.test(query) ? 'B' : 'b';
	const escapedQuery = query.replace(SPECIAL_CHARS_REGEX, '\\$&');
	const regexWordBoundary = new RegExp(`\\${boundary}${escapedQuery}.*`, 'i');
	const regexInAnyWords = new RegExp(`.*${escapedQuery}.*`, 'i');
	const selectedViaWordBoundarySearch = regexWordBoundary.test(content);
	const selectedViaInAnyWordsSearch = !selectedViaWordBoundarySearch
		? regexInAnyWords.test(content)
		: false;
	return {
		selectedViaWordBoundarySearch,
		selectedViaInAnyWordsSearch,
	};
};

type Params = boolean | string;

export type Props = SingleSelectInlineEditProps & {
	getDataFromCache?: (arg1: boolean) => Promise<ServerSuggestions>;
	fetchSuggestions: (
		query: string,
		params?: Params,
		sessionId?: string,
	) => Promise<ServerSuggestions>;
};

type State = {
	includeDoneEpics: boolean;
	query: string;
	data: ServerSuggestions;
	loading: boolean;
	error: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class EpicInlineEditView extends Component<Props, State> {
	static displayName = 'EpicInlineEditView';

	state = {
		includeDoneEpics: false,
		query: '',
		data: [],
		loading: false,
		error: false,
	};

	componentDidMount = () => {
		this.getDataFromCache();
	};

	sessionId = 'default-session-id'; // uuid represent epic selection session

	getDataFromCache = () => {
		// API accepts exclude done epics param
		this.props.getDataFromCache?.(!this.state.includeDoneEpics).then((data: ServerSuggestions) => {
			this.setState({ data, loading: false });
		});
	};

	renderReadView = () => {
		return <DisabledInlineReadView />;
	};

	fetchSuggestions = (query: string, sessionId?: string) => {
		if (sessionId !== null && sessionId !== undefined && sessionId !== '') {
			this.sessionId = sessionId;
		}
		this.setState({ query });
		// API accepts exclude done epics param
		return this.props
			.fetchSuggestions(query, !this.state.includeDoneEpics, this.sessionId)
			.then((data) => {
				this.setState({ data });
				return data;
			});
	};

	onChangeIncludeDone = async () => {
		const { query, includeDoneEpics } = this.state;
		this.setState(
			{
				includeDoneEpics: !includeDoneEpics,
				loading: true,
			},
			() =>
				this.fetchSuggestions(query)
					.then((data) => {
						this.setState({ data, loading: false, error: false });
					})
					.catch(() => {
						this.setState({ data: [], loading: false, error: true });
					}),
		);
	};

	formatOptionLabel = (option: OptionType, { context }: FormatOptionLabelMeta) => {
		if (context === 'menu') {
			return <Option option={option} />;
		}

		return option.label;
	};

	render() {
		const additionalAnalyticsProps = {
			isEpicLink: true,
		};

		const { data, loading, error } = this.state;
		const initialData = { data, loading, error };
		const componentsProps = {
			isChecked: this.state.includeDoneEpics,
			onChange: this.onChangeIncludeDone,
		};

		return (
			<SingleSelectInlineEdit
				{...omit(this.props, 'getDataFromCache')}
				debounceFetchSuggestionsTime={300}
				initialData={initialData}
				// @ts-expect-error - TS2322 - Type '{ isChecked: boolean; onChange: () => Promise<void>; }' is not assignable to type '{ isChecked: boolean; onChange: () => Promise<undefined> | undefined; } & { isChecked: boolean; onChange: () => Promise<undefined> | undefined; } & { isChecked: boolean; onChange: () => Promise<...> | undefined; }'.
				componentsProps={componentsProps}
				fetchSuggestions={this.fetchSuggestions}
				additionalAnalyticsProps={additionalAnalyticsProps}
				// @ts-expect-error - TS2322 - Type '(props: Props) => JSX.Element' is not assignable to type 'ReactNode'.
				components={{ Menu }}
				renderReadView={this.renderReadView}
				// @ts-expect-error - TS2322 - Type '(option: OptionType, { context }: FormatOptionLabelMeta) => string | JSX.Element' is not assignable to type 'FormatOptionLabel'.
				formatOptionLabel={this.formatOptionLabel}
				hasAutocomplete
				isEditable={false}
			/>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionColorSizer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 1.5}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorPatch = styled.div<{
	color: Color;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2.5}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 2.5}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2.5}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${gridSize / 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: ({ color }) => MAP_COLORS_TO_VALUE[color],
});
