import type { IntlShape, MessageDescriptor } from 'react-intl-next';

import { type IconButtonProps } from '@atlaskit/button/new';
import { type Keymap } from '@atlaskit/editor-common/keymaps';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { Fragment, NodeType } from '@atlaskit/editor-prosemirror/model';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown/serializer';
import type { ContentStatistics } from '@atlassian/ai-model-io/utils/prosemirror-content-statistics';
import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import type { IconShownAt } from '../prebuilt/assets/icons/types';
import type { TranslationLanguageLabels } from '../prebuilt/translations/types';
import type { BackendModel, IntentSchemaId } from '../provider/prompt-requests/types';
import type { EditorPluginAIPromptRequestMarkdown, RovoPublish } from '../types';
import { type MentionMap } from '../utils/mentions/get-mention-map';

import { type CONFIG_ITEM_KEYS } from './config-item-keys';

export type ActionAppearance = 'primary' | 'secondary' | 'cancel';

export type StatusLozengeType = 'new' | 'beta';

/**
 * For empty selections, the start and end will be the same
 */
export type Positions = [start: number, end: number];

export type EditorPluginAIConfigItemMarkdownAction = {
	type: 'markdown';
	/**
	 * Title of action which is presented on the preview screen.
	 */
	title: MessageDescriptor;
	appearance: ActionAppearance;
	isRovoAgentAction?: boolean;
	// Note: as we flesh out the experience -- we'll likely want
	// to either have actions return a Transaction -- or some other type using
	// utils we provide.
	run: (data: {
		editorView: EditorView;
		positions: Positions;
		markdown: string;
		pmFragment: Fragment;
		//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
		triggeredFor?: NodeType;
	}) => void;
};

export type EditorPluginAIConfigItemAgentAction = {
	type: 'agent';
	title: MessageDescriptor;
	appearance: ActionAppearance;
	/** An optional shortcut keymap for action. */
	shortcut?: Keymap;
	run: (data: { channelId?: string; publish?: RovoPublish }) => void;
	// Used when action buttons layout is in 'compact' mode
	icon: IconButtonProps['icon'];
	// Whether to preserve the AI highlight pseudo selection as
	// a true ProseMirror editor selection after the action function
	// executes
	preserveEditorSelectionOnComplete?: boolean;
};

export type ConfigItemInitialContext<SelectionType extends 'range' | 'empty'> =
	SelectionType extends 'range' ? InitialContextSelectionRange : InitialContextSelectionEmpty;

/** A context from analytics. */
export type AnalyticsContext = {
	/**
	 * The unique identifier for the current user journey.
	 *
	 * This identifier is used to track the user journey in the analytics flow.
	 */
	aiSessionId: string;
};

export type InitialContextSelectionEmpty = {
	document: string;
	userLocale: string;
	intentSchemaId: IntentSchemaId;
	contentStatistics?: ContentStatistics;
	targetTranslationLanguage?: TranslationLanguageLabels;
};

type InitialContextSelectionRange = {
	document?: string;
	selection: string;
	userLocale: string;
	intentSchemaId: IntentSchemaId | IntentSchemaId[];
	contentStatistics?: ContentStatistics;
	targetTranslationLanguage?: TranslationLanguageLabels;
};

type triggerPromptRequestOptionsType<T extends 'range' | 'empty'> = {
	/** The analytics context for the current user journey. */
	analyticsContext?: AnalyticsContext;
	initialContext: ConfigItemInitialContext<T>;
	/**
	 * Early iteration of this means no history, we will continue with user
	 * flow of "most recent prompt context, most recent prompt input"
	 */
	latestPromptResponse?: string;
	promptInput?: string;
	promptInputADF?: JSONDocNode;
	formatMessage: IntlShape['formatMessage'];
	editorSchema: EditorView['state']['schema'];
	streamingListener?: (markdown: string, status: string) => void;
};

/**
 * type guard to detect if the initial context is for range selection
 * based on selection prop availability
 */
export function isInitialContextSelectionRange(
	value: ConfigItemInitialContext<any>,
): value is InitialContextSelectionRange {
	return 'selection' in value && typeof value.selection === 'string';
}

/**
 * `EditorPluginAIConfigItem`s are used by EditorPluginAI
 * to provide AI experiences.
 *
 * **This type is not inteded to be used directly by consumers**
 * when building config items. For creating objects which conform
 * to this type:
 * @see {@link createEditorPluginAIConfigItem}
 *
 */
export type EditorPluginAIConfigItemMarkdown<
	SelectionType extends 'range' | 'empty' = 'range' | 'empty',
> = {
	// The key is used to identify the config item type and will be send to identify the item in analytics
	key: CONFIG_ITEM_KEYS;
	/** Appears in;
	 * - when the user searches for ai actions and gets a list (quick insert)
	 * - when the user highlights content and gets a list of ai actions (highlight toolbar)
	 * - when reviewing the generated content
	 */
	title: MessageDescriptor;

	/** Appears in;
	 * - when the user searches for ai actions and gets a list (quick insert)
	 */
	description: MessageDescriptor;

	/**
	 * Used in the generative-ai-modal to group related config items under a single config item
	 */
	nestingConfig?: {
		parentTestId?: string;
		parentTitle: MessageDescriptor;
		shortTitle: MessageDescriptor;
		icon?: ({
			formatMessage,
			shownAt,
		}: {
			formatMessage: IntlShape['formatMessage'];
			shownAt: IconShownAt;
		}) => React.ReactElement;
	};

	/**
	 * If `true`, the config item will be shown in the Refine dropdown in the Preview screen.
	 */
	showInRefineDropdown?: boolean;

	/**
	 * optional icon which is shown;
	 * - in quick insert listing
	 * - and on review screen
	 * - prompt suggestions list
	 */
	icon?: ({
		formatMessage,
		shownAt,
	}: {
		formatMessage: IntlShape['formatMessage'];
		shownAt: IconShownAt;
	}) => React.ReactElement;

	/**
	 * What selection types this config item is valid for
	 */
	selectionType: SelectionType;
	/**
	 * Called when evaluating whether a config item (preset) should be displayed
	 * based on the current editorView state.
	 */
	isVisible: ({
		editorView,
		positions,
	}: {
		editorView: EditorView;
		positions?: Positions;
	}) => boolean;
	/**
	 * Called when running the command to grab initial context to pass to the triggerRequestPrompt
	 */
	getInitialContext?: ({
		editorView,
		positions,
		intl,
	}: {
		editorView: EditorView;
		positions: Positions;
		intl: IntlShape;
		intentSchemaId?: IntentSchemaId;
		/**
		 * This is a map of ids to node details, which is used when mapping markdown back to pm.
		 */
		updateIdMap: ({
			idMap,
			selectionType,
		}: {
			idMap: IDMap;
			/**
			 * This needs to match the selectionType of the config item.
			 */
			selectionType: 'empty' | 'range';
		}) => void;
		/**
		 * This is a map of all the people mentioned in the document
		 * Used by the markdown serializer to map mention ids to mention names
		 */
		mentionMap?: MentionMap;
		additionalIntentSchemaIds?: IntentSchemaId[];
	}) => ConfigItemInitialContext<SelectionType>;
	/**
	 * This label is presented in the custom prompt form,
	 * as a prefix to the prompt input.
	 * If not present -- no user input prompt is collected
	 * for the prompt, and we move it straight into loading
	 * with any initial context it is set up to collect.
	 * (i.e. the 'Brainstorm' refinement tag)
	 */
	promptLabel?: MessageDescriptor;
	/**
	 * The prompt hint is used on the custom prompt screen
	 * to guide the user to expected prompt content.
	 * ie. for a promptLabel of "brainstorm" the promptHint
	 * would be "Tell me the topic...""
	 */
	promptHint?: MessageDescriptor;
	/**
	 * Called with initial context and optional user input fields, and puts the prompt dialog into a loading state.
	 * the handle response is based on the outputType configured on the plugin config item.
	 */

	triggerPromptRequest: (
		options: triggerPromptRequestOptionsType<SelectionType>,
	) => EditorPluginAIPromptRequestMarkdown;

	additionalTriggerPromptRequest?: (
		options: triggerPromptRequestOptionsType<SelectionType>,
	) => EditorPluginAIPromptRequestMarkdown | any;

	/**
	 * List of actions which can be taken with the prompt request response
	 * the propmt output is based on the outputType configured on the plugin config item.
	 */
	actions: (EditorPluginAIConfigItemMarkdownAction | EditorPluginAIConfigItemAgentAction)[];

	/**
	 * List of secondary which can be taken with the prompt request response
	 * (for example with Rovo agents, this might be to 'Continue to Chat').
	 * In 'compact' actionButtonsLayoutMode,these are left-aligned and grouped
	 * with other core secondary actions such as copy, retry, etc.
	 */
	secondaryActions?: EditorPluginAIConfigItemAgentAction[];

	// Quick insert priority
	quickInsertPriority?: number;

	/**
	 * BackendModel to be used for the config item
	 * Useful for running experiments to test different models
	 *
	 * To be passed into triggerPromptRequest - but placed out here to be accessed easily
	 * for other uses e.g. Feedback Collection
	 *
	 * isInterrogate is only passed in so that the interrogate prompt can be
	 * differentiated for turning on / off backend experiments
	 */
	getBackendModel?: (isInterrogate?: boolean) => BackendModel;

	/**
	 * This option allows the product to hide interrogation flow on the preview screen per config item
	 */
	disableInterrogation?: () => boolean;

	/**
	 * This option allows the product to dictate if the config item is new or in beta, or other new status
	 */
	statusLozengeType?: StatusLozengeType;

	/**
	 * The Rovo Agent associated with this config item.
	 */
	agent?: EditorAgent;
};

/**
 * This creates a new EditorPluginAI config item (`EditorPluginAIConfigItem`)
 * for use when setting up an editorPluginAI.
 *
 * @example
 * ```tsx
 * const configItem = createEditorPluginAIConfigItem({...})
 * const editorPluginAI = new EditorPluginAI({
 *   configItems: [configItem],
 *   editorPluginAIProvider,
 * })
 * ```
 */
// Creating via a function like this helps with type inference
export function createEditorPluginAIConfigItemMarkdown<SelectionType extends 'range' | 'empty'>(
	editorPluginAIConfigItem: EditorPluginAIConfigItemMarkdown<SelectionType>,
) {
	return editorPluginAIConfigItem;
}

export function getTranslatedItemTitle(
	configItem: EditorPluginAIConfigItemMarkdown,
	formatMessage: IntlShape['formatMessage'],
) {
	// for agents return the saved agent name in the same language as it was saved
	if (configItem.agent) {
		const presetTitle = `@${configItem.agent.name}`;
		return presetTitle || formatMessage(configItem.title);
	}

	// adding beta label for the translation prompts
	if (configItem.key.startsWith('Translate to')) {
		return formatMessage(configItem.title);
	}

	// for all other items return the i18n-ed title
	return formatMessage(configItem.title);
}

/**
 * use this function get the translated prompt label in the ui components
 */
export function getTranslatedPromptLabel(
	configItem: EditorPluginAIConfigItemMarkdown,
	formatMessage: IntlShape['formatMessage'],
) {
	if (configItem.agent) {
		// for agents return the saved agent name in the same language as it was saved
		return `@${configItem.agent.name}`;
	}

	// intentionally fall back to undefined as this value is checked to verify if we need to show the prompt input
	return configItem.promptLabel ? formatMessage(configItem.promptLabel) : undefined;
}
