import React, { useEffect, useMemo, useState } from 'react';
import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import { JiraSiteAri } from '@atlassian/ari/jira/site';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { designPanelEntryPoint } from '@atlassian/jira-issue-design-section/entrypoint.tsx';
import DesignPanelWrapper from '@atlassian/jira-issue-design-section/src/ui/index.tsx';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export const AsyncDesignPanel = () => {
	const [isReadyToLoad, setIsReadyToLoad] = useState(false);
	const cloudId = useCloudId();
	const issueId = useIssueId();

	const entryPointParams = useMemo(() => {
		if (!issueId) {
			return { siteAri: '', cloudId: '', issueAri: '' };
		}
		const issueAri = JiraIssueAri.create({ issueId, siteId: cloudId }).toString();
		const siteAri = JiraSiteAri.create({ siteId: cloudId }).toString();
		setIsReadyToLoad(true);
		return { siteAri, cloudId, issueAri };
	}, [cloudId, issueId]);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		designPanelEntryPoint,
		entryPointParams,
	);
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isReadyToLoad) {
			entryPointActions.load();
			setIsReadyToLoad(false);
		}
	}, [isReadyToLoad, entryPointActions]);

	const runtimeProps = useMemo(() => ({ isExpanded: true }), []);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="jiraIssueDesignPanel"
			packageName="jiraIssueViewBase"
			errorFallback="unmount"
			teamName="helios-headcoach"
			runtimeProps={runtimeProps}
		/>
	);
};

export type SyncDesignPanelProps = {
	rootRelayFragment: MainIssueAggQueryRelayFragment | null | undefined;
	issueViewRelayFragment: IssueViewRelayFragment | null;
};

export const SyncDesignPanel = ({
	issueViewRelayFragment,
	rootRelayFragment,
}: SyncDesignPanelProps) => {
	const cloudId = useCloudId();
	const issueId = useIssueId();
	const issueAri =
		issueId != null ? JiraIssueAri.create({ issueId, siteId: cloudId }).toString() : '';

	if (issueViewRelayFragment == null || rootRelayFragment == null) {
		return null;
	}

	return (
		<DesignPanelWrapper
			isExpanded
			issueAri={issueAri}
			issueViewRelayFragment={issueViewRelayFragment}
			rootRelayFragment={rootRelayFragment}
		/>
	);
};
