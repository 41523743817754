import React from 'react';
import { styled, keyframes } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { MenuIcon } from '@atlassian/jira-automation-platform/src/common/ui/icon/index.tsx';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export const SkeletonLine = () => (
	<SkeletonContainer>
		<MenuIcon>
			<SkeletonIcon />
		</MenuIcon>
		<SkeletonText />
	</SkeletonContainer>
);

export const Skeleton = () => (
	<>
		<SkeletonLine />
		<SkeletonLine />
		<SkeletonLine />
		<SkeletonLine />
		<SkeletonLine />
	</>
);

export default Skeleton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonContainer = styled.div({
	margin: `${token('space.100', '8px')} ${token('space.250', '20px')}`,
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonIcon = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	display: 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${gridSize * 0.25}px`,
	backgroundColor: 'currentColor',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	border: `${gridSize * 0.25}px solid transparent`,
	opacity: 0.15,
});

const shimmer = keyframes({
	'0%': {
		backgroundPosition: '-300px 0',
	},
	'100%': {
		backgroundPosition: '2000px 0',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonText = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 30}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	marginTop: token('space.050', '4px'),
	marginLeft: token('space.150', '12px'),
	animationDuration: '1s',
	animationFillMode: 'forwards',
	animationIterationCount: 'infinite',
	animationName: shimmer,
	animationTimingFunction: 'linear',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.skeleton', colors.N30),
	backgroundImage: `linear-gradient( to right, ${token(
		'color.skeleton',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		colors.N30,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	)} 10%, ${token('color.skeleton.subtle', colors.N20)} 30%, ${token(
		'color.skeleton',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		colors.N30,
	)} 50% )`,
	backgroundRepeat: 'no-repeat',
});
