import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import KeyboardShortcuts from './main.tsx';
import type { Props } from './types.tsx';

export default connect(null, (dispatch) => ({ dispatch }))((props: Props) => (
	<ErrorBoundary id="keyboard-shortcuts" packageName="issue-view">
		<KeyboardShortcuts {...props} />
	</ErrorBoundary>
));

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	useStatusKeyboardShortcutsStore,
	useIssueKeyboardShortcutsActions,
	IssueKeyboardShortcutsSubscriber,
	IssueKeyboardShortcutsContainer,
} from './services/store.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { SHORTCUT_TRIGGER_ID_CHANGE_STATUS } from './constant';
