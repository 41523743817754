import {
	SET_ACCESS_STATUS,
	SET_ACTIVE_PAGE_DATA,
	SET_ACTIVE_WHITEBOARD_DATA,
	SET_CREATE_CONTENT_RUNTIME_PROPS,
	SET_IS_CREATE_CONTENT_MODAL_OPEN,
	SET_IS_CROSSFLOW_MODAL_OPEN,
	SET_IS_EP_MODAL_LOADING,
	SET_IS_EP_MODAL_OPEN,
	SET_IS_EMBEDDED_WHITEBOARD_MODAL_OPEN,
	SET_IS_DEFER_SPACE_SELECTION,
	SET_PAGE_SPACE_NAME,
} from '../../common/types/actions.tsx';
import {
	CONTENT_TYPE,
	LINK_CREATE_DRAFT_STATUS,
	type CreateMenuActions,
	type CreateMenuState,
} from '../../common/types/types.tsx';
import { ACCESS_REQUEST_CAPABILITIES } from '../utils/index.tsx';

const defaultCreatePayload = {
	url: '',
	objectId: '',
	objectType: '',
};

export const getInitialContentMenuState = (hasConfluenceAccess: boolean): CreateMenuState => ({
	accessStatus: hasConfluenceAccess
		? ACCESS_REQUEST_CAPABILITIES.ACCESS_EXISTS
		: ACCESS_REQUEST_CAPABILITIES.REQUEST_ACCESS,
	activeWhiteboardData: defaultCreatePayload,
	activePageData: defaultCreatePayload,
	createContentRuntimeProps: {
		onCreateBehavior: LINK_CREATE_DRAFT_STATUS,
		isOpen: true,
		contentType: CONTENT_TYPE.PAGE,
	},
	embeddedConfluenceSource: '',
	isCreateContentModalOpen: false,
	isEpModalOpen: false,
	isEpModalLoading: false,
	isEmbeddedWhiteboardModalOpen: false,
	isCrossFlowModalOpen: false,
	isDeferSpaceSelection: false,
	pageSpaceName: '',
	userHasConfluenceAccess: false,
});

export const contentMenuReducer = (
	state: CreateMenuState,
	action: CreateMenuActions,
): CreateMenuState => {
	const { type, payload } = action;

	switch (type) {
		case SET_ACTIVE_PAGE_DATA:
			return {
				...state,
				activePageData: payload,
			};
		case SET_ACTIVE_WHITEBOARD_DATA:
			return {
				...state,
				activeWhiteboardData: payload,
			};
		case SET_ACCESS_STATUS:
			return {
				...state,
				accessStatus: payload,
			};
		case SET_CREATE_CONTENT_RUNTIME_PROPS:
			return {
				...state,
				createContentRuntimeProps: payload,
			};
		case SET_IS_CREATE_CONTENT_MODAL_OPEN:
			return {
				...state,
				isCreateContentModalOpen: payload,
			};
		case SET_IS_CROSSFLOW_MODAL_OPEN:
			return {
				...state,
				isCrossFlowModalOpen: payload,
			};
		case SET_IS_EP_MODAL_LOADING:
			return {
				...state,
				isEpModalLoading: payload,
			};
		case SET_IS_EP_MODAL_OPEN:
			return {
				...state,
				isEpModalOpen: payload,
			};
		case SET_IS_EMBEDDED_WHITEBOARD_MODAL_OPEN:
			return {
				...state,
				isEmbeddedWhiteboardModalOpen: payload,
			};
		case SET_IS_DEFER_SPACE_SELECTION:
			return {
				...state,
				isDeferSpaceSelection: payload,
			};
		case SET_PAGE_SPACE_NAME:
			return {
				...state,
				pageSpaceName: payload,
			};
		default:
			return state;
	}
};
