import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience, createMarkedJpdExperience } from '../../utils/experience/main.tsx';

const applyTemplateMarks = {
	APPLY_TEMPLATE_POLLING_START_MARK: 'apply_template_polling_start',
	APPLY_TEMPLATE_POLLING_STOP_MARK: 'apply_template_polling_stop',
} as const;

const pageLoad = createJpdExperience(
	new UFOExperience('jpd.project', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageLoad,
	}),
);

// Separate experience for rate limit errors
const pageLoadWithRateLimit = createJpdExperience(
	new UFOExperience('jpd.project.rate-limit', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageLoad,
	}),
);

const applyTemplate = createMarkedJpdExperience(
	new UFOExperience('jpd.project.apply-template', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.Custom,
		featureFlags: ['polaris.project.creation.template'],
		timings: [
			{
				key: 'apply_template_polling',
				startMark: applyTemplateMarks.APPLY_TEMPLATE_POLLING_START_MARK,
				endMark: applyTemplateMarks.APPLY_TEMPLATE_POLLING_STOP_MARK,
			},
		],
	}),
	applyTemplateMarks,
);

const create = createJpdExperience(
	new UFOExperience('jpd.project.create', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	pageLoad,
	pageLoadWithRateLimit,
	applyTemplate,
	create,
};
