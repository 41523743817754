import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type ApprovalPanel from './index.tsx';
import type { PanelProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ApprovalPanelComponent = lazyAfterPaint<typeof ApprovalPanel>(
	() => import(/* webpackChunkName: "async-jwm-approval-panel" */ './index'),
	{ ssr: false },
);

const AsyncApprovalPanel = (props: PanelProps) => (
	<JSErrorBoundary
		fallback="unmount"
		id="approvalsPanel"
		packageName="jiraBusinessApprovalsIssueViewPanel"
		teamName="wanjel"
	>
		<Placeholder name="approval-panel-component" fallback={null}>
			<ApprovalPanelComponent {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default AsyncApprovalPanel;
