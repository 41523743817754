import { type StoreActionApi, createStore, createHook } from '@atlassian/react-sweet-state';

type LoomOnboardingStateType = {
	hasSpotlightRendered: boolean;
	isLoomOnboardingEPMessageActive: boolean;
	loomOnboardingEPMessageStop?: () => void;
};

type LoomOnboardingActionType = {
	updateHasSpotlightRendered: (
		hasRendered: boolean,
	) => ({ setState }: StoreActionApi<LoomOnboardingStateType>) => void;
	updateIsLoomOnboardingEPMessageActive: (
		isActive: boolean,
	) => ({ setState }: StoreActionApi<LoomOnboardingStateType>) => void;
	setLoomOnboardingEPMessageStop: (
		stopFunction: () => void,
	) => ({ setState }: StoreActionApi<LoomOnboardingStateType>) => void;
};

const Store = createStore<LoomOnboardingStateType, LoomOnboardingActionType>({
	initialState: {
		hasSpotlightRendered: false,
		isLoomOnboardingEPMessageActive: false,
		loomOnboardingEPMessageStop: undefined,
	},
	actions: {
		updateHasSpotlightRendered:
			(hasRendered) =>
			({ setState }) => {
				setState({
					hasSpotlightRendered: hasRendered,
				});
			},
		updateIsLoomOnboardingEPMessageActive:
			(isActive) =>
			({ setState }) => {
				setState({
					isLoomOnboardingEPMessageActive: isActive,
				});
			},
		setLoomOnboardingEPMessageStop:
			(stopFunction) =>
			({ setState }) => {
				setState({
					loomOnboardingEPMessageStop: stopFunction,
				});
			},
	},
});

export const useLoomOnboardingState = createHook(Store);
