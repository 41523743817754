import React from 'react';
import AlignLeftIcon from '@atlaskit/icon/core/align-left';
import ChildIssuesIcon from '@atlaskit/icon/core/child-issues';
import EditIcon from '@atlaskit/icon/core/edit';
import IssuesIcon from '@atlaskit/icon/core/issues';
import PageIcon from '@atlaskit/icon/core/page';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import {
	SUGGEST_RELATED_RESOURCES_ID,
	IMPROVE_ISSUE_DESCRIPTION_ID,
	SUMMARIZE_COMMENTS_ID,
	SUGGEST_CHILD_ISSUES_ID,
	ADD_ISSUE_LINKS_ID,
} from '@atlassian/jira-issue-view-common-constants/src/quick-add-constants.tsx';
import { JIRA_CORE } from '@atlassian/jira-shared-types/src/application.tsx';
import { messages } from '../messages.tsx';
import type { AiActions, AiActionReturn } from '../types.tsx';

export const getAiActions = ({
	application,
	formatMessage,
	onIssueBreakdownClick,
	issueBreakdownRef,
	isIssueBreakdownEnabled,
	onSmartSummaryClick,
	smartSummaryRef,
	isSmartSummaryEnabled,
	onRelatedResourcesClick,
	isImproveDescriptionEnabled,
	onImproveDescriptionClick,
	isRelatedConfluencePagesEnabled,
	onRelatedIssuesClick,
	isRelatedIssuesEnabled,
}: AiActions): AiActionReturn[] => {
	const relatedResourcesOption: AiActionReturn = {
		id: SUGGEST_RELATED_RESOURCES_ID,
		tooltip: formatMessage(messages.aiSuggestRelatedResourcesLabel),
		label: formatMessage(messages.aiSuggestRelatedResourcesLabel),
		icon: <PageIcon color={token('color.icon')} label="" />,
		onClick: onRelatedResourcesClick,
		itemIndex: 1,
		shouldRender: isRelatedConfluencePagesEnabled,
		disabledMessage: formatMessage(messages.aiSuggestRelatedResourcesDisabledMessage),
		testId:
			'issue.issue-view.views.issue-base.foundation.quick-add.improve-issue.suggest-related-resources',
	};

	const aiItems: AiActionReturn[] = [
		{
			id: IMPROVE_ISSUE_DESCRIPTION_ID,
			tooltip: formatMessage(messages.aiSuggestImproveDescriptionLabel),
			label: formatMessage(messages.aiSuggestImproveDescriptionLabel),
			icon: <EditIcon color={token('color.icon')} label="" />,
			onClick: onImproveDescriptionClick,
			itemIndex: 0,
			shouldRender: isImproveDescriptionEnabled,
			disabledMessage: formatMessage(messages.aiSuggestImproveDescriptionDisabledMessage),
			testId:
				'issue.issue-view.views.issue-base.foundation.quick-add.improve-issue.improve-description',
		},
		...(fg('jira_ai_powered_issue_related_confluence_resources') ? [relatedResourcesOption] : []),
		{
			id: SUMMARIZE_COMMENTS_ID,
			tooltip: formatMessage(messages.aiSuggestSummarizeCommentsLabel),
			label: formatMessage(messages.aiSuggestSummarizeCommentsLabel),
			icon: <AlignLeftIcon color={token('color.icon')} label="" />,
			onClick: onSmartSummaryClick,
			itemIndex: 2,
			shouldRender: isSmartSummaryEnabled,
			disabledMessage: formatMessage(messages.aiSuggestSummarizeCommentsDisabledMessage),
			testId:
				'issue.issue-view.views.issue-base.foundation.quick-add.improve-issue.summarize-comments',
			ref: smartSummaryRef,
		},
		{
			id: SUGGEST_CHILD_ISSUES_ID,
			tooltip: formatMessage(messages.aiSuggestChildIssuesLabel),
			label: formatMessage(messages.aiSuggestChildIssuesLabel),
			icon: <ChildIssuesIcon color={token('color.icon')} label="" />,
			onClick: onIssueBreakdownClick,
			itemIndex: 3,
			shouldRender: isIssueBreakdownEnabled,
			disabledMessage: formatMessage(messages.aiSuggestChildIssuesDisabledState),
			testId:
				'issue.issue-view.views.issue-base.foundation.quick-add.improve-issue.suggest-child-issues',
			ref: issueBreakdownRef,
		},
		{
			id: ADD_ISSUE_LINKS_ID,
			tooltip: formatMessage(messages.aiSuggestRelatedIssuesLabel),
			label: formatMessage(messages.aiSuggestRelatedIssuesLabel),
			icon: <IssuesIcon color={token('color.icon')} label="" />,
			onClick: onRelatedIssuesClick,
			itemIndex: 4,
			shouldRender: isRelatedIssuesEnabled,
			disabledMessage: formatMessage(messages.aiSuggestRelatedIssuesDisabledMessage),
			testId:
				'issue.issue-view.views.issue-base.foundation.quick-add.improve-issue.suggest-related-issues',
		},
	];

	/*
		Business logic GRAVITYAI-1762
	*/
	if (application === JIRA_CORE) {
		return aiItems.filter((item) => item.id !== IMPROVE_ISSUE_DESCRIPTION_ID);
	}

	if (!fg('jira_ai_related_issue_enabled')) {
		return aiItems.filter((item) => item.id !== ADD_ISSUE_LINKS_ID);
	}

	return aiItems;
};
