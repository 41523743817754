/**
 * @generated SignedSource<<d0916ad698e8174dd4e232e032ca917e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type designForm_issueAddDesignForm_AddDesignForm_designs$data = {
  readonly __id: string;
  readonly " $fragmentType": "designForm_issueAddDesignForm_AddDesignForm_designs";
};
export type designForm_issueAddDesignForm_AddDesignForm_designs$key = {
  readonly " $data"?: designForm_issueAddDesignForm_AddDesignForm_designs$data;
  readonly " $fragmentSpreads": FragmentRefs<"designForm_issueAddDesignForm_AddDesignForm_designs">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "designForm_issueAddDesignForm_AddDesignForm_designs",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "GraphStoreSimplifiedIssueAssociatedDesignConnection"
};

(node as any).hash = "4d6af6408ec24aabfcb35cb89a3587d4";

export default node;
