import React from 'react';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ContainerComponent } from '@atlassian/react-sweet-state';
import type { ViewType } from '../common/types/context.tsx';
import type { ViewConfiguration } from '../common/types/index.tsx';
import { UiModificationContextContainer } from './adjustments-context/index.tsx';
import { LoadedUiModificationsContainer } from './issue-adjustments/container/index.tsx';
import { useLoadViewConfiguration } from './view-configuration/store/index.tsx';

type Props = {
	scope: string;
	viewType: ViewType;
	viewConfiguration: new () => ViewConfiguration;
};

export const BaseUiModificationsContainer: ContainerComponent<Props> = ({
	children,
	scope,
	viewType,
	viewConfiguration,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useLoadViewConfiguration(viewType, viewConfiguration);

	return (
		<LoadedUiModificationsContainer
			scope={scope}
			viewType={viewType}
			createAnalyticsEvent={createAnalyticsEvent}
		>
			<UiModificationContextContainer scope={scope}>{children}</UiModificationContextContainer>
		</LoadedUiModificationsContainer>
	);
};
