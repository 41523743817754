import React from 'react';

import Icon from '@atlaskit/icon/core/globe';
import TranslateIcon from '@atlaskit/icon/core/translate';
import { fg } from '@atlaskit/platform-feature-flags';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { token } from '@atlaskit/tokens';

import { type CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import type { EditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../../config-items/slice-or-node-to-markdown';
import { streamConvoAI } from '../../../provider/prompt-requests/convo-ai';
import { streamXPGenAI } from '../../../provider/prompt-requests/xp-gen-ai';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';
import { languageMessages, translateToMessages } from '../../translations/messages';
import {
	getSortedSupportedTranslationLanguages,
	isRefinementSupport,
} from '../../translations/utils';
import { getSlice } from '../utils';

const SELECTION_TYPE = 'range';

const supportedTranslationLanguages = getSortedSupportedTranslationLanguages();

const translationRangeConfigItemsArr = supportedTranslationLanguages
	.map((language) => {
		const lang = languageMessages[language];
		if (!lang) {
			return false;
		}
		const configItem = createEditorPluginAIConfigItemMarkdown({
			key: `Translate to ${language}` as CONFIG_ITEM_KEYS,
			title: translateToMessages[`${language}Title`],
			description: translateToMessages[`${language}Description`],
			selectionType: 'range',
			nestingConfig: {
				parentTitle: editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')
					? languageMessages.nestingParentTitlePostGA
					: languageMessages.nestingParentTitle,
				shortTitle: lang,
			},
			showInRefineDropdown: isRefinementSupport(language),
			getInitialContext: ({ editorView, positions, intl, updateIdMap, mentionMap }) => {
				const selection = sliceOrNodeToMarkdown({
					slice: getSlice(editorView, positions),
					editorView,
					convertTo: 'markdown-plus',
					updateIdMap,
					selectionType: SELECTION_TYPE,
					mentionMap,
				});

				return {
					selection: selection.markdown,
					userLocale: intl.locale,
					intentSchemaId: 'translate_intent_schema.json',
					contentStatistics: selection.contentStatistics,
					targetTranslationLanguage: lang.defaultMessage,
				};
			},
			triggerPromptRequest({
				initialContext,
				editorSchema,
				analyticsContext,
				latestPromptResponse,
			}) {
				if (fg('platform_editor_ai_assistance_service')) {
					return streamConvoAI({
						aiSessionId: analyticsContext?.aiSessionId,
						transformationSubtype: lang.defaultMessage,
						userLocale: initialContext.userLocale,
						intentSchemaId: 'translate_intent_schema.json',
						editorSchema,
						fullDocument: initialContext.document,
						draftSelectedContentOverride: editorExperiment(
							'platform_editor_ai_refine_response_button',
							true,
						)
							? latestPromptResponse
							: undefined,
						currentSelection: initialContext.selection,
					});
				}
				return streamXPGenAI({
					targetTranslationLanguage: lang.defaultMessage,
					userLocale: initialContext.userLocale,
					editorSchema,
					intentSchemaId: 'translate_intent_schema.json',
					contextList: [
						{
							type: 'ADF_MARKDOWN_V1',
							entity: initialContext.selection,
							relationship: 'SELECTION',
						},
					],
				});
			},
			isVisible: ({ editorView, positions }) =>
				isMinLength({ editorView, minLength: 1, positions }),
			icon: () => (
				<TranslateIcon
					label=""
					color="currentColor"
					LEGACY_fallbackIcon={() => (
						<Icon label="" spacing="spacious" color={token('color.icon', '#44546F')} />
					)}
				/>
			),
			actions: [
				createInsertBelow({ appearance: 'secondary' }),
				createReplace({ appearance: 'primary' }),
			],
		});

		return [language, configItem];
	})
	.filter(Boolean) as [string, EditorPluginAIConfigItemMarkdown<'range'>][];

export const translationRangeConfigItemsMap: {
	[k: string]: EditorPluginAIConfigItemMarkdown<'range'>;
} = Object.fromEntries(translationRangeConfigItemsArr.filter(Boolean));

export const translationRangeConfigItems: EditorPluginAIConfigItemMarkdown<'range'>[] =
	Object.values(translationRangeConfigItemsMap);
