// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'issue.portfolio-child-issues.title',
		defaultMessage: 'Child issues',
		description: 'The title of the children issues section used in Classic projects.',
	},
});
