import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const collectionsGlyph = (props: CustomGlyphProps) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="#44546F"
			fill-rule="evenodd"
			d="m10.5 5 2 2H17a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6.584a2 2 0 0 1-1.947-1.542l-1.059-4.5A2.001 2.001 0 0 1 5 10.57V7a2 2 0 0 1 2-2h3.5Zm-4 5.5h7.916a2 2 0 0 1 1.947 1.542l1.137 4.833V9a.5.5 0 0 0-.5-.5h-4.5a1.5 1.5 0 0 1-1.06-.44L9.878 6.5H7a.5.5 0 0 0-.5.5v3.5ZM5.525 12h8.891a.5.5 0 0 1 .487.386l1.203 5.114H6.584a.5.5 0 0 1-.487-.385l-1.059-4.5A.5.5 0 0 1 5.525 12Z"
			clip-rule="evenodd"
		/>
	</svg>
);
