import React, { useState, type MouseEvent } from 'react';

import { styled } from '@compiled/react';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { ItemLineCardGroup } from '@atlassian/jira-issue-item-line-card/src/ui/index.tsx';
import {
	isMiddleClick,
	isCmdOrCtrlClick,
} from '@atlassian/jira-issue-view-common-utils/src/events/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { RelatedIssue } from '../../common/types.tsx';
import { useRelatedIssuesForIssue } from '../../services/context.tsx';
import { useQueryText } from '../../services/use-query-text/index.tsx';
import ErrorFeedback from './error-feedback/index.tsx';
import IssueLineCard from './issue-line-card/index.tsx';
import NoIssuesFeedback from './no-issues-feedback/index.tsx';
import ShowMoreExpander from './show-more/index.tsx';
import SkeletonLoadingContainer from './skeleton/index.tsx';

export const COLLAPSED_DISPLAY_LIMIT = 5;

export type Props = {
	sessionId: string;
};

const IssueLineCardGroup = ({ sessionId }: Props) => {
	const [showMoreExpanded, setShowMoreExpanded] = useState(false);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const queryText = useQueryText();

	const [{ data: relatedIssues, isLoading, error, status }, { fetchRelatedIssuesForIssue }] =
		useRelatedIssuesForIssue();

	const handleClick = (issue: RelatedIssue, event: MouseEvent<HTMLElement>) => {
		if (issue !== null && issue !== undefined) {
			const path = `/browse/${issue.issueKey}`;

			// If onClick/handleClick is fired from a component underneath this component,
			// for example an <a> element, we preventDefault to stop the native link opening
			// behaviour, since that is managed here via window.open
			event.preventDefault();

			if (isMiddleClick(event) || isCmdOrCtrlClick(event)) {
				fireUIAnalytics(createAnalyticsEvent({}), 'relatedIssue clicked', {
					newTabOpened: true,
					position: issue.position,
					relatedIssueId: issue.id,
				});

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(path, '_blank');
			} else {
				// normal click
				fireUIAnalytics(createAnalyticsEvent({}), 'relatedIssue clicked', {
					newTabOpened: false,
					position: issue.position,
					relatedIssueId: issue.id,
				});

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.location.assign(path);
			}
		}
	};

	const handleTryAgain = () => {
		fetchRelatedIssuesForIssue(sessionId, createAnalyticsEvent, queryText);
	};

	const issues = relatedIssues[status];

	const isInitialState = !isLoading && issues === undefined && error === undefined;

	// // when the panel is first opened, there are no issues hence load in this case too
	if (isLoading || isInitialState) return <SkeletonLoadingContainer />;

	if (issues) {
		if (issues.length === 0) return <NoIssuesFeedback />;

		const displayedIssues = showMoreExpanded ? issues : issues.slice(0, COLLAPSED_DISPLAY_LIMIT);

		const renderShowMoreIfIssuesOverflow = () =>
			issues.length > COLLAPSED_DISPLAY_LIMIT && (
				<ShowMoreExpander
					isShowMoreExpanded={showMoreExpanded}
					onExpand={() => setShowMoreExpanded(true)}
					issuesLength={issues.length}
				/>
			);

		return (
			<FlexContainer>
				<CardGroupContainer isExpanded={showMoreExpanded}>
					<ItemLineCardGroup items={displayedIssues} groupId={sessionId} onClick={handleClick}>
						{({ item: issue }) => <IssueLineCard {...issue} />}
					</ItemLineCardGroup>
					{renderShowMoreIfIssuesOverflow()}
				</CardGroupContainer>
			</FlexContainer>
		);
	}

	// if all conditions not met above, return error
	return <ErrorFeedback onTryAgain={handleTryAgain} />;
};

export default IssueLineCardGroup;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexContainer = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardGroupContainer = styled(FlexContainer)<{ isExpanded: boolean }>(
	{
		display: 'block',
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		maxHeight: `${gridSize * 30}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		ul: {
			background: 'none',
			boxShadow: 'none',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'ul div': {
			borderBottom: '0px',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isExpanded }) =>
		isExpanded && {
			overflowY: 'scroll',
		},
);
