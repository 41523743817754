import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	watchingLabel: {
		id: 'issue-view-watchers.watchers.action-button.watching-label',
		defaultMessage:
			'Watch options: You are watching this issue, {watchersCount, plural, one {# person} other {# people}} watching',
		description: 'Indicates that the user is watching the issue',
	},
	notWatchingLabel: {
		id: 'issue-view-watchers.watchers.action-button.not-watching-label',
		defaultMessage:
			'Watch options: You are not watching this issue, {watchersCount, plural, one {# person} other {# people}} watching',
		description: 'Indicates that the user is not watching the issue',
	},
	watchTooltip: {
		id: 'issue-view-watchers.watchers.action-button.watch-tooltip',
		defaultMessage: 'Watch',
		description:
			'Clicking the button associated with this tooltip will result in the user starting to get notifications for the issue',
	},
	unwatchTooltip: {
		id: 'issue-view-watchers.watchers.action-button.unwatch-tooltip',
		defaultMessage: 'Unwatch',
		description:
			'Clicking the button associated with this tooltip will result in the user no longer getting notifications for the issue',
	},
	watchTooltipWithHotkey: {
		id: 'issue-view-watchers.watchers.action-button.watch-tooltip-with-hotkey',
		defaultMessage: 'Watch (w)',
		description:
			'Clicking the button associated with this tooltip will result in the user starting to get notifications for the issue. The (w) is the keyboard shortcut for this feature',
	},
	unwatchTooltipWithHotkey: {
		id: 'issue-view-watchers.watchers.action-button.unwatch-tooltip-with-hotkey',
		defaultMessage: 'Unwatch (w)',
		description:
			'Clicking the button associated with this tooltip will result in the user no longer getting notifications for the issue. The (w) is the keyboard shortcut for this feature',
	},
	watchersOptionsTooltip: {
		id: 'issue-view-watchers.watchers.action-button.watchers-options-tooltip',
		defaultMessage: 'Watch options',
		description:
			'Clicking the button associated with this tooltip will display a dropdown with management actions',
	},
});
