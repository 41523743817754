import { styled } from '@compiled/react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IssueBodyContainer = styled.div({
	height: '100%',
	position: 'relative',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IssueBodyContainerDropzone = styled.div<{ attachmentHover: any }>({
	position: 'absolute',
	height: '100%',
	boxSizing: 'border-box',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: (props) => (props.attachmentHover ? '2px dashed #2684FF' : '2px dashed transparent'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (props) => (props.attachmentHover ? '#deebff' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	mixBlendMode: (props) => (props.attachmentHover ? 'normal' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props) => (props.attachmentHover ? '0.54' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	zIndex: (props) => (props.attachmentHover ? '100' : undefined),
});
