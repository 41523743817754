import type {
	ConnectActivity,
	EcosystemContentPanel,
	EcosystemOperation,
	Glance,
	GlanceStatus,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';

export const glanceAnalyticsData = (
	appKey: string,
	moduleKey: string,
	status: GlanceStatus | null,
) => {
	const statusType = (status && status.type) ?? '';

	return {
		appKey,
		moduleKey,
		statusType,
	};
};

export const contentPanelAnalyticsOnLoad = (
	contentPanels: EcosystemContentPanel[],
	panelsOnPage: string[],
) => {
	let totalOnPage = 0;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const data = contentPanels.reduce<Record<string, any>>((acc, contentPanel) => {
		if (!acc[contentPanel.appKey]) {
			acc[contentPanel.appKey] = {
				total: 0,
				totalOnPage: 0,
			};
		}

		const current = acc[contentPanel.appKey];
		current.total += 1;

		const extId = `${contentPanel.appKey}_${contentPanel.moduleKey}`;
		if (panelsOnPage.includes(extId)) {
			current.totalOnPage += 1;
			totalOnPage += 1;
		}

		return acc;
	}, {});

	const safeData = JSON.stringify(data);

	return {
		totalOnPage,
		total: contentPanels.length,
		data: safeData,
	};
};

export const contentPanelAnalyticsData = (appKey: string, moduleKey: string) => ({
	appKey,
	moduleKey,
});

export const toggleCollapsedStateData = (appKey: string, moduleKey: string, action: string) => ({
	appKey,
	moduleKey,
	event: action,
});

export const activityPanelAnalyticsData = (appKey: string, moduleKey: string) => ({
	appKey,
	moduleKey,
});

const appKeyModKeyNameTransformer = (items: Array<ConnectActivity>) =>
	items.map((item) => ({
		appKey: item.appKey,
		moduleKey: item.moduleKey,
		name: item.name,
	}));

const appKeyModKeyNameTypeTransformer = (items: Array<Glance>) =>
	items.map((item) => ({
		appKey: item.appKey,
		moduleKey: item.moduleKey,
		name: item.name,
		type: item.type,
	}));

const contentPanelTransformer = (items: Array<EcosystemContentPanel>) =>
	items.map((item) => ({
		appKey: item.appKey,
		moduleKey: item.moduleKey,
		name: item.name,
		type: item.type,
		showByDefault: item.showByDefault,
	}));

const operationTransformer = (operations: Array<EcosystemOperation>) =>
	operations.map((operation) => ({
		name: operation.name,
	}));

const legacyAddonFilter = (addon: EcosystemContentPanel | Glance) => addon.type === 'WEB_PANEL';
const newAddonFilter = (addon: EcosystemContentPanel | Glance) => addon.type === 'ISSUE_MODULE';

export const issueEcosystemLoadedData = (
	glances: Glance[] = [],
	contentPanels: EcosystemContentPanel[] = [],
	activityPanels: ConnectActivity[] = [],
	operations: EcosystemOperation[] = [],
	ecosystemCustomFieldsCount = 0,
) => {
	const data = {
		glances: appKeyModKeyNameTypeTransformer(glances),
		contentPanels: contentPanelTransformer(contentPanels),
		activityPanels: appKeyModKeyNameTransformer(activityPanels),
		operations: operationTransformer(operations),
	};
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const totalModules = (Object.keys(data) as (keyof typeof data)[]).reduce(
		(sum, key) => (data[key] && data[key].length ? sum + data[key].length : sum),
		0,
	);
	const dataString = JSON.stringify(data);

	return {
		totalGlances: glances.filter(newAddonFilter).length,
		totalLegacyGlances: glances.filter(legacyAddonFilter).length,
		totalContentPanels: contentPanels.filter(newAddonFilter).length,
		totalLegacyContentPanels: contentPanels.filter(legacyAddonFilter).length,
		totalActivityPanels: activityPanels.length,
		totalOperations: operations.length,
		totalCustomIssueFields: ecosystemCustomFieldsCount,
		totalModules,
		data: dataString,
	};
};
