import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	findSimilarIssues: {
		id: 'ai-related-issues.find-similar-issues',
		defaultMessage: 'Find similar issues',
		description: 'Prompt indicating that the AI is finding similar issues',
	},
	discardSuggestions: {
		id: 'ai-related-issues.discard-suggestions',
		defaultMessage: 'Discard suggestions',
		description: 'Message to indicate that the button can initiate an action to descard suggestion',
	},
	relatedResourcesHeading: {
		id: 'ai-related-issues.related-resources-heading',
		defaultMessage: 'Related Resources',
		description: 'Title for Related resources',
	},
	generatingSuggestions: {
		id: 'ai-related-issues.generating-suggestions',
		defaultMessage: 'Generating suggestions',
		description:
			'Text shown when AI is generating suggestions for issues related to the jira issue',
	},
	pageSuggestionsCouldNotBeGenerated: {
		id: 'ai-related-issues.page-suggestions-could-not-be-generated-non-final',
		defaultMessage:
			"Atlassian Intelligence couldn't suggest confluence pages relevant for this issue ",
		description: 'Message shown when AI cannot suggest pages relevant to the issue',
	},
});
