export const DesignFormLinkState = {
	INVALID_URL: 'invalidUrl',
} as const;

export type DesignFormLinkState = (typeof DesignFormLinkState)[keyof typeof DesignFormLinkState];

export type DesignProviderDomain = 'com.figma';

export const DesignAppConfig: [
	{ providerDomain: DesignProviderDomain; designLinkPlaceholder: string },
] = [
	{
		providerDomain: 'com.figma',
		designLinkPlaceholder: 'https://www.figma.com/file/xxx/my-file?node-id=xxx',
	},
];
