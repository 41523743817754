import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { view_issueViewWatchers_ActionIcon$key } from '@atlassian/jira-relay/src/__generated__/view_issueViewWatchers_ActionIcon.graphql';
import { ActionButtonOld, ActionButtonNew } from '../action-button/index.tsx';
import Dropdown from '../dropdown/index.tsx';

type Props = {
	canViewDropdown: boolean;
	watches: view_issueViewWatchers_ActionIcon$key;
};

const ActionIconOld = (props: Props) => {
	const { canViewDropdown } = props;

	if (!canViewDropdown) {
		return <ActionButtonOld isDropdownOpen={false} />;
	}

	return <Dropdown />;
};

const ActionIcon = ({ canViewDropdown, watches }: Props) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<view_issueViewWatchers_ActionIcon$key>(
		graphql`
			fragment view_issueViewWatchers_ActionIcon on JiraWatchesField {
				...actionButton_issueViewWatchers_ActionButtonWithRelay
				...view_issueViewWatchers_Dropdown
			}
		`,
		watches,
	);

	if (!canViewDropdown) {
		return <ActionButtonNew isDropdownOpen={false} watches={data} />;
	}

	return <Dropdown watches={data} />;
};

export default componentWithCondition(
	() => ff('relay-migration-issue-fields-watchers_iqhn4'),
	ActionIcon,
	ActionIconOld,
);
