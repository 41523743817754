import { continueInRovoChat } from '@atlaskit/editor-common/keymaps';
import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import type {
	ActionAppearance,
	EditorPluginAIConfigItemAgentAction,
} from '../../config-items/config-items';
import { type EditorPluginAIProvider } from '../../types';
import { ChatIcon } from '../assets/icons/chat';

import { messages } from './messages';

type CreateInsertProps = {
	appearance: ActionAppearance;
	agent: EditorAgent;
	product: EditorPluginAIProvider['product'];
	actionSideEffect?: () => void;
	actionOverride?: ({ channelId }: { channelId: string }) => void;
};

export const createContinueInChat = ({
	appearance,
	agent,
	product,
	actionSideEffect,
	actionOverride,
}: CreateInsertProps): EditorPluginAIConfigItemAgentAction => ({
	type: 'agent',
	title: messages.continueInChatLabel,
	shortcut: continueInRovoChat,
	appearance,
	run: ({ channelId, publish }) => {
		if (!channelId || !publish) {
			return;
		}

		if (typeof actionOverride === 'function') {
			actionOverride({ channelId });
			return;
		}

		publish({
			type: 'chat-open',
			product,
			data: {
				channelId,
				agentId: agent.namedId,
			},
			source: 'editor',
		});

		actionSideEffect?.();
	},
	preserveEditorSelectionOnComplete: true,
	icon: ChatIcon,
});
