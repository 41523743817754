import React from 'react';
import { styled } from '@compiled/react';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import StatusField from '@atlassian/jira-issue-view-foundation/src/status/view.tsx';

const FoundationContext = ({
	issueViewRelayFragment,
}: {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
}) => (
	<>
		<FoundationContextWrapper
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.context.status-and-approvals-wrapper.status-and-approval"
		>
			<StatusField key="STATUS" fragmentKey={issueViewRelayFragment} />
		</FoundationContextWrapper>
	</>
);

export default FoundationContext;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FoundationContextWrapper = styled.div({
	marginBottom: 0,
});
FoundationContextWrapper.displayName = 'FoundationContextWrapper';
