// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changeType: {
		id: 'issue.details.change-issue-type',
		defaultMessage: '{issueType} - Change issue type',
		description: '',
	},
	changeTypeGroupName: {
		id: 'issue.details.change-issue-type-groupname',
		defaultMessage: 'Change Issue Type',
		description: '',
	},
});
