import { useEffect } from 'react';
import type { FieldInternalShape } from '@atlassian/jira-issue-adjustments/src/common/types/field.tsx';
import { useFieldIsBusy } from '@atlassian/jira-issue-adjustments/src/controllers/hooks/use-field-is-busy/index.tsx';
import { useOverridesStoreActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/context.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Field } from '../../../../../common/types.tsx';

type Props = {
	field: Field;
	issueKey: IssueKey;
	state: FieldInternalShape;
};

export const useSetOverrides = ({ field, issueKey, state }: Props) => {
	const { setFieldConfigOverridesValue } = useOverridesStoreActions();
	const isBusy = useFieldIsBusy(field.fieldId);

	useEffect(() => {
		if (state.isReadOnly !== undefined) {
			setFieldConfigOverridesValue(issueKey, field.fieldId, {
				isEditable: !state.isReadOnly,
			});
		}
	}, [field.fieldId, issueKey, setFieldConfigOverridesValue, state.isReadOnly]);

	useEffect(() => {
		if (state.fieldName !== undefined) {
			setFieldConfigOverridesValue(issueKey, field.fieldId, {
				title: state.fieldName,
			});
		}
	}, [setFieldConfigOverridesValue, issueKey, field.fieldId, state.fieldName]);

	useEffect(() => {
		if (state.description !== undefined) {
			setFieldConfigOverridesValue(issueKey, field.fieldId, {
				description: state.description,
			});
		}
	}, [setFieldConfigOverridesValue, issueKey, field.fieldId, state.description]);

	useEffect(() => {
		if (state.allowedValuesOverrides !== undefined) {
			setFieldConfigOverridesValue(issueKey, field.fieldId, {
				allowedValuesOverrides: state.allowedValuesOverrides,
			});
		}
	}, [setFieldConfigOverridesValue, issueKey, field.fieldId, state.allowedValuesOverrides]);

	useEffect(() => {
		if (state.fieldOptionsFilter !== undefined) {
			setFieldConfigOverridesValue(issueKey, field.fieldId, {
				optionsFilter: state.fieldOptionsFilter,
			});
		}
	}, [setFieldConfigOverridesValue, issueKey, field.fieldId, state.fieldOptionsFilter]);

	useEffect(() => {
		setFieldConfigOverridesValue(issueKey, field.fieldId, {
			isBusy,
		});
	}, [setFieldConfigOverridesValue, issueKey, field.fieldId, isBusy]);
};
