import React from 'react';
import TickIcon from '@atlaskit/icon/glyph/editor/done';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	OptionLabel,
	OptionTextContainer,
} from '@atlassian/jira-issue-internal-field-select/src/common/custom-format/format-option-label/index.tsx';
import { OptionWrapper } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/styled.tsx';
import type { Option } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import messages from './messages.tsx';
import { OptionDescriptionWrapper, Description } from './styled.tsx';

type Props = {
	option: Option;
};

const renderOptionDescription = (option: Option, doneEpicLabel: string) => (
	<OptionDescriptionWrapper>
		{option.description != null ? <Description>{option.description}</Description> : null}
		{option.isDone === true ? (
			<>
				{}
				{/* @ts-expect-error - TS2769 - No overload matches this call. */}
				<TickIcon primaryColor={token('color.icon.success', colors.G500)} />
				<span>{doneEpicLabel}</span>
			</>
		) : null}
	</OptionDescriptionWrapper>
);

const CustomOption = ({ option }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<OptionWrapper>
			{option.elemBefore != null ? option.elemBefore : null}
			<OptionTextContainer>
				<OptionLabel>{option.label}</OptionLabel>
				{renderOptionDescription(option, formatMessage(messages.doneEpicLabel))}
			</OptionTextContainer>
		</OptionWrapper>
	);
};

export default CustomOption;
