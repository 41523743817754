export const SET_ACTIVE_PAGE_DATA = 'setActivePageData';
export const SET_ACTIVE_WHITEBOARD_DATA = 'setActiveWhiteboardData';
export const SET_ACCESS_STATUS = 'setAccessStatus';
export const SET_CREATE_CONTENT_RUNTIME_PROPS = 'setCreateContentRuntimeProps';
export const SET_IS_CREATE_CONTENT_MODAL_OPEN = 'setIsCreateContentModalOpen';
export const SET_IS_CROSSFLOW_MODAL_OPEN = 'setIsCrossFlowModalOpen';
export const SET_IS_EMBEDDED_WHITEBOARD_MODAL_OPEN = 'setIsEmbeddedWhiteboardModalOpen';
export const SET_IS_EP_MODAL_LOADING = 'setIsEpModalLoading';
export const SET_IS_EP_MODAL_OPEN = 'setIsEpModalOpen';
export const SET_IS_DEFER_SPACE_SELECTION = 'setIsDeferSpaceSelection';
export const SET_PAGE_SPACE_NAME = 'setPageSpaceName';
